import React, { useState, useEffect } from 'react';
import '../ContactsPage/ContactsPage.scss';
import { useTranslation } from 'react-i18next';
import { DataTable } from 'primereact/datatable';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { Card } from 'primereact/card';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Toast } from 'primereact/toast';
import { exportService } from '../../services/ExportService';

const FailedExportsPage = () => {
    const [loading, setLoading] = useState(false);
    const [exports, setExports] = useState([]);
    const { t } = useTranslation();
    const toast = React.useRef(null);

    const paginatorLeft = (
        <Button type="button" icon="pi pi-refresh" className="p-button-text" />
    );
    const paginatorRight = (
        <Button type="button" icon="pi pi-cloud" className="p-button-text" />
    );

    useEffect(() => {
        setLoading(true);
    }, []);

    useEffect(() => {
        fetchExports()
            .then((result) => {
                setExports(Array.isArray(result) ? result : []);
                setLoading(false);
            })
            .catch((error) => {
                console.log(error);
            });
    }, []);

    const fetchExports = async () => {
        const response = await exportService.getFailedExports();
        return response.data;
    };

    if (loading) {
        return (
            <div className="center-spinner">
                <ProgressSpinner strokeWidth="5" animationDuration="1.5s" />
            </div>
        );
    } else {
        return (
            <>
                <Card>
                    <div className="container-contacts-empty">
                        <DataTable
                            value={exports}
                            className="p-datatable-lg"
                            paginator={true}
                            paginatorPosition="bottom"
                            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                            currentPageReportTemplate={`{first} ${t(
                                'bis',
                            )} {last} ${t('of')} {totalRecords}`}
                            rows={50}
                            rowsPerPageOptions={[10, 20, 50]}
                            sortField="createdAt"
                            sortOrder={-1}
                            paginatorLeft={paginatorLeft}
                            paginatorRight={paginatorRight}
                            scrollHeight="700px"
                            selectionMode="single"
                        >
                            <Column
                                sortable
                                className="column-container-second "
                                field="id"
                                header={t('Export Job ID')}
                            />
                            <Column
                                className="column-container"
                                field="export.id"
                                header={t('Export ID')}
                            />
                            <Column
                                className="column-container"
                                field="export.property.id"
                                header={t('Property ID')}
                            />
                            <Column
                                className="column-container"
                                field="export.property.rootId"
                                header={t('Property Root ID')}
                            />
                            <Column
                                className="column-container"
                                field="export.providerKey"
                                header={t('Provider')}
                            />
                            <Column
                                className="column-container"
                                field="createdAt"
                                header={t('Created at')}
                            />
                            <Column
                                className="column-container"
                                field="startedAt"
                                header={t('Started at')}
                            />
                            <Column
                                className="column-container"
                                field="finishedAt"
                                header={t('Finished at')}
                            />
                        </DataTable>
                    </div>
                </Card>
                <Toast ref={toast} position={'top-right'} />
            </>
        );
    }
};

export default FailedExportsPage;

import { Dropdown } from 'primereact/dropdown';
import React, { useState, useEffect } from 'react';
import './UserPage.scss';
import { useTranslation } from 'react-i18next';
import { DataTable } from 'primereact/datatable';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { Card } from 'primereact/card';
import { userService } from '../../services/UsersService';
import UserFormModal from '../../components/UserFormModal/UserFormModal';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Toast } from 'primereact/toast';
import { useApplicationContext } from '../../application/ApplicationContext';
import EditUserModal from '../../components/EditUserModal/EditUserModal';
import { Checkbox } from 'primereact/checkbox';
import PermissionContainer from '../../core/auth/PermissionContainer';
import { Permissions } from '../../core/auth/Permissions';
import { DomHandler } from 'primereact/utils';
import { useStateCallback } from '../../hooks/StateCallbackHook';
import UserActivitiesModal from '../../components/UserActivitiesModal/UserActivitiesModal';

const UserPage = () => {
    const [visible, setVisible] = useStateCallback(false);
    const [users, setUsers] = useState([]);
    const [visibleEditModal, setVisibleEditModal] = useStateCallback(false);
    const [visibleActivitiesModal, setVisibleActivitiesModal] =
        useStateCallback(false);
    const [singleUser, setSingleUsers] = useState([]);
    const [status, setStatus] = useState(0);
    const { t } = useTranslation();
    const toast = React.useRef(null);
    const { appState, addNewUser } = useApplicationContext();

    const paginatorLeft = (
        <Button type="button" icon="pi pi-refresh" className="p-button-text" />
    );
    const paginatorRight = (
        <Button type="button" icon="pi pi-cloud" className="p-button-text" />
    );

    useEffect(() => {
        let isMounted = true;
        userService
            .getMany()
            .then((response) => {
                if (response && response.data && isMounted) {
                    setUsers(response.data);
                }
            })
            .catch((error) => {
                console.log(error);
            });
        return () => {
            isMounted = false;
        };
    }, [addNewUser]);

    const showModal = () => {
        setVisible(!visible, (state) => {
            if (!state) {
                DomHandler.removeClass(document.body, 'p-overflow-hidden');
            }
        });
    };

    const showEditModal = () => {
        setVisibleEditModal(!visibleEditModal, (state) => {
            if (!state) {
                DomHandler.removeClass(document.body, 'p-overflow-hidden');
            }
        });
    };

    const showActivitiesModal = () => {
        setVisibleActivitiesModal(!visibleActivitiesModal, (state) => {
            if (!state) {
                DomHandler.removeClass(document.body, 'p-overflow-hidden');
            }
        });
    };

    const editUser = (rowData) => {
        return (
            <>
                <Button
                    type="button"
                    icon="pi pi-bars"
                    className="view-action-button"
                    onClick={() => {
                        showActivitiesModal();
                        setSingleUsers(rowData);
                    }}
                />
                <Button
                    type="button"
                    icon="pi pi-eye"
                    className="view-action-button"
                    onClick={() => {
                        showEditModal();
                        setSingleUsers(rowData);
                    }}
                />
            </>
        );
    };

    const selectedUsers = (rowData) => {
        return <Checkbox checked={rowData.active ? true : false} />;
    };

    const supervisorName = (rowData) => {};

    useEffect(() => {
        if (status === 200) {
            toast.current.show({
                severity: 'success',
                detail: t(`User has been deleted successfully`),
                life: 3000,
            });
            setStatus(0);
        }
        if (status === 500) {
            toast.current.show({
                severity: 'error',
                detail: t(`internal server error`),
                life: 3000,
            });
            setStatus(0);
        }
    }, [status]);

    if (users.length === 0) {
        return (
            <div className="center-spinner">
                <ProgressSpinner strokeWidth="5" animationDuration="1.5s" />
            </div>
        );
    } else {
        return (
            <>
                <Toast ref={toast} position={'top-right'} />
                <Card className="no-padding">
                    <div className="container-user-empty">
                        {/*<div className="dropdown-container">*/}
                        {/*    <Dropdown className="filter-item" />*/}
                        {/*    <Dropdown className="filter-item" />*/}
                        {/*    <Dropdown className="filter-item" />*/}
                        {/*    <Dropdown*/}
                        {/*        className="filter-item"*/}
                        {/*        placeholder={t('Filter')}*/}
                        {/*    />*/}
                        {/*</div>*/}
                        <div className="button-container">
                            <PermissionContainer
                                hasPermission={Permissions.USER_CREATE}
                                currentUser={appState.currentUser}
                            >
                                <Button
                                    style={{
                                        marginTop: '10px',
                                        marginRight: '10px',
                                    }}
                                    onClick={showModal}
                                    label={t('Add new user')}
                                />
                            </PermissionContainer>
                            {visible ? (
                                <UserFormModal
                                    showModal={showModal}
                                    toast={toast}
                                />
                            ) : null}
                            {visibleEditModal ? (
                                <EditUserModal
                                    showModal={showEditModal}
                                    toast={toast}
                                    singleUser={singleUser}
                                />
                            ) : null}
                            {visibleActivitiesModal ? (
                                <UserActivitiesModal
                                    showModal={showActivitiesModal}
                                    toast={toast}
                                    singleUser={singleUser}
                                />
                            ) : null}
                        </div>
                        <DataTable
                            value={users}
                            className="p-datatable-lg"
                            paginator={true}
                            paginatorPosition="bottom"
                            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                            currentPageReportTemplate={`{first} ${t(
                                'bis',
                            )} {last} ${t('of')} {totalRecords}`}
                            rows={50}
                            rowsPerPageOptions={[10, 20, 50, 100]}
                            sortField="id"
                            sortOrder={-1}
                            paginatorLeft={paginatorLeft}
                            paginatorRight={paginatorRight}
                            scrollHeight="700px"
                        >
                            <Column
                                sortable
                                className="column-container-second"
                                field="id"
                                header={t('ID')}
                            />
                            <Column
                                className="column-container"
                                field="name"
                                header={t('Name')}
                            />
                            <Column
                                className="column-container"
                                field={'role.name'}
                                header={t('Role')}
                            />
                            <Column
                                className="column-container-third"
                                field="superior.name"
                                header={t('Team')}
                            />
                            <Column
                                header={t('Active')}
                                className="column-container"
                                body={selectedUsers}
                            />
                            <Column
                                body={editUser}
                                className="datatable-button-column"
                            />
                            {/* <Column
                                body={deleteActionBodyTemplate}
                                className="datatable-button-column"
                            /> */}
                        </DataTable>
                    </div>
                </Card>
            </>
        );
    }
};

export default UserPage;

import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import CustomObjectInput from '../objects/customObjectInput/CustomObjectInput';
import './NewInquiryActivityFormModal.scss';
import { useTranslation } from 'react-i18next';
import { RadioButton } from 'primereact/radiobutton';
import { INewInquiryActivityModal } from '../../models/NewInquiryActivityModal';
import dateFormat from 'dateformat';
import { Toast } from 'primereact/toast';
import { useApplicationContext } from '../../application/ApplicationContext';
import moment from 'moment';
import { userService } from '../../services/UsersService';

export const NewInquiryActivityFormModal = ({
    showModal,
    typeOfNewActivity,
    visibleNewActivity,
    updateActivitiesData,
    activityData,
    contactId,
    propertyId,
    inquiries,
}: INewInquiryActivityModal) => {
    console.log(inquiries);

    const todayDate = new Date().toISOString();
    const confirmDate = new Date().toISOString();
    const [newInquiryActivityObject, setNewInquiryActivityObject] = useState({
        appointmentDate: moment(inquiries.appointmentDate).format(),
        comment: '',
    });
    const [check, setCheck] = useState(null);
    const [reminderDate, setReminderDate] = useState('');
    const { t } = useTranslation();
    const toast = React.useRef(null);
    const { appState, editNewEvents } = useApplicationContext();
    const [confirmApointmentDate, setConfirmApointmentDate] = useState({
        calendar: moment(inquiries.appointmentDate).format(),
    });

    const [dropdownOption, setDropdownOption] = useState({
        priceType: {
            ownership: false,
            rent: false,
        },
        exclusiveAgreement: {
            exclusive: false,
            regular: false,
        },
        verbalAgreement: {
            verbal: false,
            written: false,
        },
    });

    const categories = [
        { name: t('Unreachable'), key: '1' },
        { name: t('No New Appointment'), key: '2' },
    ];

    const categoriesNotRecorded = [
        { name: t('Der Verkäufer war nicht anwesend'), key: '1' },
        { name: t('Der Makler hat den Vertrag nicht bekommen'), key: '2' },
        {
            name: t(
                'Der Makler will den Verkauf der Immobilie nicht übernehmen',
            ),
            key: '3',
        },
    ];

    const [selectedCategory, setSelectedCategory] = useState(categories[1]);
    const [selectedcategoryNotRecorded, setCategoryNotRecorded] = useState(
        categoriesNotRecorded[0],
    );

    const handleNewInquiryActivity = (prop: string, value: string) => {
        setNewInquiryActivityObject((newInquiryActivityObject) => ({
            ...newInquiryActivityObject,
            [prop]: value,
        }));
    };

    const handleDropdownOption = (prop: string, value: string) => {
        setDropdownOption((newInquiryActivityObject) => ({
            ...newInquiryActivityObject,
            [prop]: value,
        }));
    };

    const handleCheckbox = () => {
        return setCheck(!check);
    };

    const handleReminderDate = (_, date) => {
        const dateFormats = dateFormat(date, 'yyyy-mm-dd') + 'T07:00:00';
        return setReminderDate(dateFormats);
    };

    const handleConfirmApointmentDate = (prop: string, value: string) => {
        setConfirmApointmentDate((newInquiryActivityObject) => ({
            ...newInquiryActivityObject,
            calendar: moment(value).format(),
        }));
    };

    const handleNoNowAppointment = () => {
        if (newInquiryActivityObject.comment.split(' ').length > 4) {
            return (
                updateActivitiesData({
                    newActivity: newInquiryActivityObject,
                    contactNoNewAppointment: true,
                    contactUnreachable: false,
                }),
                showModal()
            );
        } else {
            return toast.current.show({
                severity: 'error',
                detail: t(`comment must have at least 5 words`),
                life: 3000,
            });
        }
    };

    const handleUnreachable = () => {
        if (check) {
            return (
                // editNewEvents(
                //     'Reminder',
                //     newInquiryActivityObject.comment,
                //     reminderDate,
                //     reminderDate,
                //     'First date',
                //     Number(appState.currentUser.id),
                //     contactId,
                //     propertyId,
                //     'ddd'
                // ),
                updateActivitiesData({
                    newActivity: newInquiryActivityObject,
                    contactNoNewAppointment: false,
                    contactUnreachable: true,
                }),
                showModal()
            );
        } else {
            return (
                updateActivitiesData({
                    newActivity: newInquiryActivityObject,
                    contactNoNewAppointment: false,
                    contactUnreachable: true,
                }),
                showModal()
            );
        }
    };

    const handleCalendar = async () => {
        if (confirmApointmentDate.calendar !== null) {
            /// servis za povlacenje jednog calendarId na osnovu property Id-ja
            const calendarId = await userService.getPropertyCalendarId(
                propertyId,
            );

            return (
                //                editNewEvents(
                //                    'FD Bestätigt',
                //                    'First date',
                //                    `${confirmApointmentDate.calendar}T${confirmApointmentDate.time}`,
                //                    `${moment(confirmApointmentDate.calendar).format(
                //                        'YYYY-MM-DD',
                //                    )}T${moment(confirmApointmentDate.time, 'HH:mm')
                //                        .add(1, 'hours')
                //                        .format('HH:mm')}`,
                //                    Number(appState.currentUser.id),
                //                    contactId,
                //                    propertyId,
                //                    newInquiryActivityObject.comment,
                //                    calendarId.data.id,
                //                ), /// edit jednog datuma umesto kreiranja novog
                // editNewEvents(
                //     editcalendarFormModalObject.title,
                //     editcalendarFormModalObject.activityType,
                //     moment(editcalendarFormModalObject.editValidFrom).format(
                //         'YYYY-MM-DDTkk:mm:ss',
                //     ),
                //     moment(editcalendarFormModalObject.editExpiryDate).format(
                //         'YYYY-MM-DDTkk:mm:ss',
                //     ),
                //     editcalendarFormModalObject.id,
                //     editcalendarFormModalObject.contact,
                //     editcalendarFormModalObject.Object,
                //     editcalendarFormModalObject.info,
                //     idEvent,
                // );
                updateActivitiesData({
                    newActivity: {
                        ...newInquiryActivityObject,
                        date: `${confirmApointmentDate.calendar}`,
                        systemComment: `Date and time: ${confirmApointmentDate.calendar}`,
                    },
                    activityType: typeOfNewActivity,
                }),
                showModal()
            );
        }
    };

    const handleNichtAufgenommen = () => {
        if (newInquiryActivityObject.comment.split(' ').length < 5) {
            return toast.current.show({
                severity: 'error',
                detail: t(`comment must have at least 5 words`),
                life: 3000,
            });
        }
        switch (selectedcategoryNotRecorded.key) {
            case '1':
                return (
                    updateActivitiesData({
                        newActivity: newInquiryActivityObject,
                        sellerWasNotThere: true,
                        agentNoContract: false,
                        agendDidNotAccept: false,
                    }),
                    showModal()
                );
            case '2':
                return (
                    updateActivitiesData({
                        newActivity: newInquiryActivityObject,
                        sellerWasNotThere: false,
                        agentNoContract: true,
                        agendDidNotAccept: false,
                    }),
                    showModal()
                );
            case '3':
                return (
                    updateActivitiesData({
                        newActivity: newInquiryActivityObject,
                        sellerWasNotThere: false,
                        agentNoContract: false,
                        agendDidNotAccept: true,
                    }),
                    showModal()
                );
            default:
                return;
        }
    };

    const handleOnHold = () => {
        if (newInquiryActivityObject.comment.split(' ').length > 4) {
            return (
                updateActivitiesData({
                    newActivity: newInquiryActivityObject,
                }),
                showModal()
            );
        } else {
            return toast.current.show({
                severity: 'error',
                detail: t(`comment must have at least 5 words`),
                life: 3000,
            });
        }
    };

    const handleMandatErhalten = () => {
        return (
            updateActivitiesData({
                newActivity: newInquiryActivityObject,
                dropdownOption: dropdownOption,
            }),
            showModal()
        );
    };

    const handleCheckButton = () => {
        if (typeOfNewActivity === 'Mandat erhalten') {
            return handleMandatErhalten();
        }
        if (typeOfNewActivity === 'On Hold') {
            return handleOnHold();
        }
        if (typeOfNewActivity === 'Termin bestat') {
            return handleCalendar();
        }
        if (typeOfNewActivity === 'Nicht aufgenommen') {
            return handleNichtAufgenommen();
        }
        if (typeOfNewActivity === 'Abgesagt') {
            if (selectedCategory.name === 'No New Appointment') {
                return handleNoNowAppointment();
            } else {
                return handleUnreachable();
            }
        }
        return (
            updateActivitiesData({ newActivity: newInquiryActivityObject }),
            showModal()
        );
    };

    const handleResetButton = () => {
        return setNewInquiryActivityObject({
            appointmentDate: todayDate,
            comment: '',
        });
    };

    const renderFooter = () => {
        return (
            <div>
                {/* <Button
                    label={t('Refresh')}
                    icon="pi pi-refresh"
                    onClick={handleResetButton}
                    autoFocus
                /> */}
                <Button
                    label={t('Save')}
                    icon="pi pi-check"
                    onClick={handleCheckButton}
                />
            </div>
        );
    };

    const {
        control,
        formState: { errors },
        register,
    } = useForm({
        mode: 'onBlur',
    });

    return (
        <>
            <Toast ref={toast} position={'top-right'} />
            <Dialog
                header={
                    t('New Activity - ') +
                    ' - ' +
                    (typeOfNewActivity === 'Termin bestat'
                        ? `FD ${t(typeOfNewActivity)}`
                        : t(typeOfNewActivity))
                }
                footer={renderFooter}
                style={{ minWidth: '26vw' }}
                blockScroll
                visible={visibleNewActivity}
                onHide={showModal}
            >
                <div className="modal-body">
                    {/* <div className="modal-input">
                        <CustomObjectInput
                            type="calendar"
                            classes={['date-input']}
                            id="appointmentDate-input"
                            label={t('Appointment date')}
                            dateType={'date'}
                            name="appointmentDate"
                            register={register}
                            stateField={
                                newInquiryActivityObject.appointmentDate
                            }
                            setStateField={handleNewInquiryActivity}
                            errors={errors}
                            control={control}
                        />
                    </div>
                    <div className="modal-input">
                        <CustomObjectInput
                            type="calendar"
                            classes={['time-input']}
                            id="time-input"
                            label={t('Time')}
                            dateType={'time'}
                            name="time"
                            onlyTime={true}
                            stateField={newInquiryActivityObject.time}
                            setStateField={handleNewInquiryActivity}
                            errors={errors}
                            control={control}
                        />
                    </div> */}
                    {typeOfNewActivity === 'Termin bestat' ? (
                        <>
                            {/* <div className="modal-input">
                                <CustomObjectInput
                                    type="calendar"
                                    classes={['date-input']}
                                    id="date-input"
                                    label={t('Reminder')}
                                    name="calendar"
                                    dateType="date"
                                    register={register}
                                    stateField={confirmApointmentDate.calendar}
                                    setStateField={handleConfirmApointmentDate}
                                    errors={errors}
                                    control={control}
                                />
                            </div> */}

                            <div className="modal-input column-span-2">
                                <CustomObjectInput
                                    type="calendar"
                                    classes={['time-input']}
                                    id="time-input"
                                    label={t('Time')}
                                    name="time"
                                    dateType="time"
                                    onlyTime={true}
                                    stateField={inquiries.appointmentDate}
                                    setStateField={handleConfirmApointmentDate}
                                    errors={errors}
                                    control={control}
                                />
                            </div>
                        </>
                    ) : null}
                    {typeOfNewActivity === 'Mandat erhalten' ? (
                        <>
                            <div className="container-dropdown column-span-2">
                                {/* <CustomObjectInput
                                    type="dropdown"
                                    classes={['dropdown-input']}
                                    id="activity-type-input"
                                    label={t('Vertragsart')}
                                    name="priceType"
                                    dropdownOptions={[
                                        {
                                            label: 'Eigentum',
                                            value: {
                                                ownership: true,
                                                rent: false,
                                            },
                                        },
                                        {
                                            label: 'Mieten',
                                            value: {
                                                ownership: false,
                                                rent: true,
                                            },
                                        },
                                    ]}
                                    stateField={dropdownOption.priceType}
                                    setStateField={handleDropdownOption}
                                    errors={errors}
                                    control={control}
                                />{' '} */}
                                {/* <CustomObjectInput
                                    type="dropdown"
                                    classes={['dropdown-input']}
                                    id="activity-type-input"
                                    label={t('')}
                                    name="exclusiveAgreement"
                                    dropdownOptions={[
                                        {
                                            label: 'Exklusiv',
                                            value: {
                                                exclusive: true,
                                                regular: false,
                                            },
                                        },
                                        {
                                            label: 'Normal',
                                            value: {
                                                exclusive: false,
                                                regular: true,
                                            },
                                        },
                                    ]}
                                    stateField={
                                        dropdownOption.exclusiveAgreement
                                    }
                                    setStateField={handleDropdownOption}
                                    errors={errors}
                                    control={control}
                                />{' '}
                                <CustomObjectInput
                                    type="dropdown"
                                    classes={['dropdown-input']}
                                    id="activity-type-input"
                                    label={t('')}
                                    name="verbalAgreement"
                                    dropdownOptions={[
                                        {
                                            label: 'Literarisch',
                                            value: {
                                                verbal: true,
                                                written: false,
                                            },
                                        },
                                        {
                                            label: 'mündliche Vereinbarung',
                                            value: {
                                                verbal: false,
                                                written: true,
                                            },
                                        },
                                    ]}
                                    stateField={dropdownOption.verbalAgreement}
                                    setStateField={handleDropdownOption}
                                    errors={errors}
                                    control={control}
                                /> */}
                            </div>
                        </>
                    ) : null}
                    {typeOfNewActivity === 'Nicht aufgenommen'
                        ? categoriesNotRecorded.map((category) => {
                              return (
                                  <div
                                      key={category.key}
                                      className="field-radiobutton"
                                  >
                                      <RadioButton
                                          inputId={category.key}
                                          name="price_priceType"
                                          value={category}
                                          onChange={(e) => {
                                              setCategoryNotRecorded(e.value);
                                          }}
                                          checked={
                                              selectedcategoryNotRecorded.key ===
                                              category.key
                                          }
                                          disabled={category.key === 'R'}
                                      />
                                      <label
                                          className="radio-button-label"
                                          htmlFor={category.key}
                                      >
                                          {category.name}
                                      </label>
                                  </div>
                              );
                          })
                        : null}
                    {typeOfNewActivity === 'Abgesagt'
                        ? categories.map((category) => {
                              return (
                                  <div
                                      key={category.key}
                                      className="field-radiobutton"
                                  >
                                      <RadioButton
                                          inputId={category.key}
                                          name="price_priceType"
                                          value={category}
                                          onChange={(e) => {
                                              setSelectedCategory(e.value);
                                          }}
                                          checked={
                                              selectedCategory.key ===
                                              category.key
                                          }
                                          disabled={
                                              activityData.length < 5 &&
                                              category.key === '1'
                                          }
                                      />
                                      <label
                                          className="radio-button-label"
                                          htmlFor={category.key}
                                      >
                                          {category.name}
                                      </label>
                                  </div>
                              );
                          })
                        : null}
                    {typeOfNewActivity === 'Abgesagt' &&
                    selectedCategory.name === 'Unreachable' ? (
                        <CustomObjectInput
                            type="checkbox"
                            addonLabel="m²"
                            classes={['checkbox']}
                            id="unfinished-attic"
                            label={t('Erinnerung ?')}
                            name="check"
                            stateField={check}
                            setStateField={handleCheckbox}
                            errors={errors}
                            control={control}
                            rules={{ required: t('This is required') }}
                        />
                    ) : null}
                    {selectedCategory.name === 'Unreachable' && check ? (
                        <div className="date-input-reminder">
                            <CustomObjectInput
                                type="calendar"
                                classes={['date-input-reminder-label']}
                                id="date-input-reminder"
                                label={t('Reminder')}
                                dateType={'date'}
                                name={t('reminder')}
                                stateField={reminderDate}
                                setStateField={handleReminderDate}
                                errors={errors}
                                control={control}
                            />
                        </div>
                    ) : null}
                    <div className="modal-input column-span-2">
                        <CustomObjectInput
                            type="accordion-textarea"
                            classes={['comment-input']}
                            id="comment-input"
                            label={t('Comment')}
                            name="comment"
                            stateField={newInquiryActivityObject.comment}
                            setStateField={handleNewInquiryActivity}
                            errors={errors}
                            control={control}
                        />
                    </div>
                </div>
            </Dialog>
        </>
    );
};

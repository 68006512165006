import { ApplicationState } from './ApplicationState';

export const UPDATE = 'UPDATE';

export interface UpdateAction {
    type: typeof UPDATE;
    payload: Partial<ApplicationState>;
}

export type ApplicationAction = UpdateAction;

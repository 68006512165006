import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import CustomObjectInput from '../objects/customObjectInput/CustomObjectInput';
import './NewMeineInteresentActivityFormModal.scss';
import { useTranslation } from 'react-i18next';
import { useApplicationContext } from '../../application/ApplicationContext';
import { RadioButton } from 'primereact/radiobutton';
import { Toast } from 'primereact/toast';
import moment from 'moment';
import { calendarEventStatus } from '../../models/CalendarFormModal';

export const NewMeineInteresentActivityFormModal = ({
    showModal,
    typeOfNewActivity,
    visibleNewActivity,
    updateActivitiesData,
    contactId,
    propertyId,
    contactName,
    agentName,
    agentPhone,
    title,
}) => {
    const todayDate = new Date().toISOString();
    const [newInquiryActivityObject, setNewInquiryActivityObject] = useState({
        appointmentDate: moment(todayDate).format('YYYY-MM-DD'),
        time: moment(todayDate).format('HH:mm'),
        comment: '',
        systemComment: '',
    });

    const [property, setProperty] = useState({
        'Lage': false,
        'Preis-Leistung': false,
        'Grundriss': false,
        'Lift': false,
        'Lärm': false,
    });
    const [interesent, setInteresent] = useState({
        'Unseriös/Spam': false,
        'Sucht nicht mehr': false,
        'Anforderungen geändert': false,
    });
    const checkboxProperty = [
        'Lage',
        'Preis-Leistung',
        'Grundriss',
        'Lift',
        'Lärm',
    ];
    const checkboxInteresent = [
        'Unseriös/Spam',
        'Sucht nicht mehr',
        'Anforderungen geändert',
    ];

    const { t } = useTranslation();
    const categories = [
        { name: t('Property'), key: '1' },
        { name: t('Interesent'), key: '2' },
    ];

    const categoriesTerminGehalten = [
        { name: t('Interesse'), key: '1' },
        { name: t('Kaufinteresse'), key: '2' },
        { name: t('Kaufabsicht'), key: '3' },
        { name: t('Kein Interesse'), key: '4' },
    ];

    const categoriesZweiteTerminGehalten = [
        { name: t('Nicht interessiert'), key: '1' },
        { name: t('Ein gebot'), key: '2' },
    ];

    const categoriesResult = [
        { name: t('Angebot angenommen'), key: '1' },
        { name: t('Angebot wurde nicht angenommen'), key: '2' },
    ];

    const [reminderDate, setReminderDate] = useState({
        calendar: moment(todayDate).format(),
    });
    const { appState, addNewEvents } = useApplicationContext();
    const [selectedCategory, setSelectedCategory] = useState(categories[1]);
    const [selectedCategoryTerminGehalten, setSelectedCategoryTerminGehalten] =
        useState(categoriesTerminGehalten[0]);
    const [selectedZweiteTerminGehalten, setSelectedZweiteTerminGehalten] =
        useState(categoriesZweiteTerminGehalten[0]);
    const [selectedResult, setSelectedResult] = useState(categoriesResult[0]);
    const toast = React.useRef(null);

    const handleReminderDate = (prop: string, value: string) => {
        setReminderDate((newInquiryActivityObject) => ({
            ...newInquiryActivityObject,
            calendar: moment(value).format(),
        }));
    };

    const mailText = `Sehr geehrte ${contactName},

herzlichen Dank für Ihr Interessean einer Besichtigung von ${
        title ?? '_______'
    }.
Gerne bestätige ich den vereinbarten Besichtigungstermin am ${moment(
        reminderDate.calendar,
    ).format('DD.MM.YYYY, [um] HH:mm')}.

Treffpunkt:`;

    ///// MAIL TEL RR

    const mailTextTelRR = `Betreff: Ihre Anfrage für Objekt ID ${propertyId}, „${
        title ?? '_______'
    }“. \n\nSehr geehrte/r  ${contactName}, danke für Ihr Interesse an der Immobilie „${
        title ?? '_______'
    }“.\n\nLeider haben Sie uns keine Telefonnummer bekanntgegeben.\n\nWenn Sie wünschen, können wir gerne per Mail einen Besichtigungstermin für diese Immobilie vereinbaren.\n\nFür ausführlichere Informationen stehe ich sehr gerne unter ${agentPhone} zur Verfügung.\n\nIn der Anlage senden wir Ihnen das Exposé von „${
        title ?? '_______'
    }“ sowie die Widerrufsbelehrung samt Information zur Datenschutzgrundverordnung\n\n${agentName}, Golden Home Deutschland.`;

    //// MAIL VERSAND

    const mailVersand = `Betreff: Ihre Anfrage für Objekt ID „${propertyId}“, „${
        title ?? '_______'
    }“.\n\nSehr geehrte/r  ${contactName} \nherzlichen Dank für Ihr Interesse an „${
        title ?? '_______'
    }“.\n\nIn der Anlage senden wir Ihnen das Exposé von „${
        title ?? '_______'
    }“, sowie die Widerrufsbelehrung samt Information zur Datenschutzgrundverordnung.\n\nFreundliche Grüße`;

    useEffect(() => {
        if (typeOfNewActivity === 'Appoint. Held') {
            return setNewInquiryActivityObject({
                ...newInquiryActivityObject,
                comment: '',
                systemComment: `${selectedCategoryTerminGehalten.name}`,
            });
        }
    }, [selectedCategoryTerminGehalten]);

    useEffect(() => {
        if (typeOfNewActivity === 'Tel. RR') {
            return setNewInquiryActivityObject({
                ...newInquiryActivityObject,
                comment: mailTextTelRR,
                systemComment: `${selectedCategoryTerminGehalten.name}`,
            });
        }
    }, [selectedCategoryTerminGehalten, reminderDate]);

    useEffect(() => {
        if (typeOfNewActivity === 'Versand') {
            return setNewInquiryActivityObject({
                ...newInquiryActivityObject,
                comment: mailVersand,
                systemComment: `${selectedCategoryTerminGehalten.name}`,
            });
        }
    }, [selectedCategoryTerminGehalten, reminderDate]);

    useEffect(() => {
        if (typeOfNewActivity === 'Conf. App.') {
            return setNewInquiryActivityObject({
                ...newInquiryActivityObject,
                comment: mailText,
                systemComment: `${selectedCategoryTerminGehalten.name}`,
            });
        }
    }, [selectedCategoryTerminGehalten, reminderDate]);

    const handleNewInquiryActivity = (prop: string, value: string) => {
        setNewInquiryActivityObject((newInquiryActivityObject) => ({
            ...newInquiryActivityObject,
            [prop]: value,
        }));
    };

    const handleCheckBoxProperty = (prop: string, value: string) => {
        setProperty((newInquiryActivityObject) => ({
            ...newInquiryActivityObject,
            [prop]: value,
        }));
    };

    const handleCheckBoxInteresent = (prop: string, value: string) => {
        setInteresent((newInquiryActivityObject) => ({
            ...newInquiryActivityObject,
            [prop]: value,
        }));
    };

    const handleCalendar = () => {
        let meilText;

        const index = newInquiryActivityObject.comment.indexOf('Treffpunkt: ');
        if (index !== -1) {
            meilText = newInquiryActivityObject.comment.slice(index + 12);
        } else {
            meilText = '';
        }

        if (reminderDate.calendar !== null) {
            return (
                //                addNewEvents(
                //                    'Besichtigung',
                //                    meilText,
                //                    `${moment(reminderDate.calendar).format(
                //                        'YYYY-MM-DD',
                //                    )}T${moment(reminderDate.time, 'HH:mm').format('HH:mm')}`,
                //                    `${moment(reminderDate.calendar).format(
                //                        'YYYY-MM-DD',
                //                    )}T${moment(reminderDate.time, 'HH:mm')
                //                        .add(30, 'minutes')
                //                        .format('HH:mm')}`,
                //                    calendarEventStatus.Interessent,
                //                    Number(appState.currentUser.id),
                //                    contactId,
                //                    propertyId,
                //                ),
                updateActivitiesData({
                    newActivity: {
                        ...newInquiryActivityObject,
                        comment: meilText,
                        appointmentDate: reminderDate.calendar,
                        time: reminderDate.calendar,
                        systemComment: `Date and time: ${reminderDate.calendar}`,
                    },
                    activityType: typeOfNewActivity,
                }),
                showModal()
            );
        }

        return (
            updateActivitiesData({
                newActivity: newInquiryActivityObject,
            }),
            showModal()
        );
    };

    const handleConfAppCalendar = () => {
        let meilText;

        const index = newInquiryActivityObject.comment.indexOf('Treffpunkt: ');
        if (index !== -1) {
            meilText = newInquiryActivityObject.comment.slice(index + 12);
        } else {
            meilText = '';
        }

        if (reminderDate.calendar !== null) {
            return (
                //                addNewEvents(
                //                    'Besichtigung',
                //                    meilText,
                //                    `${moment(reminderDate.calendar).format(
                //                        'YYYY-MM-DD',
                //                    )}T${moment(reminderDate.time, 'HH:mm').format('HH:mm')}`,
                //                    `${moment(reminderDate.calendar).format(
                //                        'YYYY-MM-DD',
                //                    )}T${moment(reminderDate.time, 'HH:mm')
                //                        .add(30, 'minutes')
                //                        .format('HH:mm')}`,
                //                    calendarEventStatus.Interessent,
                //                    Number(appState.currentUser.id),
                //                    contactId,
                //                    propertyId,
                //                ),
                updateActivitiesData({
                    newActivity: {
                        ...newInquiryActivityObject,
                        comment: meilText,
                        appointmentDate: reminderDate.calendar,
                        time: reminderDate.calendar,
                        systemComment: `Date and time: ${reminderDate.calendar}`,
                    },
                    activityType: typeOfNewActivity,
                }),
                showModal()
            );
        }

        return (
            updateActivitiesData({
                newActivity: newInquiryActivityObject,
            }),
            showModal()
        );
    };

    const handleAbgeschlossen = () => {
        if (selectedCategory.key === '1') {
            const choice = Object.keys(property).filter((key) => property[key]);
            return (
                updateActivitiesData({
                    newActivity: {
                        ...newInquiryActivityObject,
                        comment: `${
                            newInquiryActivityObject.comment !== ''
                                ? newInquiryActivityObject.comment + '\n\n'
                                : ''
                        }${selectedCategory.name}: ${choice.join(', ')}`,
                    },
                }),
                showModal()
            );
        } else {
            const choice = Object.keys(interesent).filter(
                (key) => interesent[key],
            );
            return (
                updateActivitiesData({
                    newActivity: {
                        ...newInquiryActivityObject,
                        comment: `${
                            newInquiryActivityObject.comment !== ''
                                ? newInquiryActivityObject.comment + '\n\n'
                                : ''
                        }${selectedCategory.name}: ${choice.join(', ')}`,
                    },
                }),
                showModal()
            );
        }
    };

    const handleTerminGehalten = () => {
        return (
            updateActivitiesData({
                newActivity: newInquiryActivityObject,
                activityType: typeOfNewActivity,
            }),
            showModal()
        );
    };

    const handleZweiteTerminGehalten = () => {
        if (newInquiryActivityObject.comment.split(' ').length < 5) {
            return toast.current.show({
                severity: 'error',
                detail: t(`comment must have at least 5 words`),
                life: 3000,
            });
        }
        if (selectedZweiteTerminGehalten.name === 'Nicht interessiert') {
            return (
                updateActivitiesData({
                    newActivity: newInquiryActivityObject,
                    activityType: 'Abgeschlossen',
                }),
                showModal()
            );
        } else {
            return (
                updateActivitiesData({
                    newActivity: newInquiryActivityObject,
                    activityType: 'Kaufanbot',
                }),
                showModal()
            );
        }
    };

    const handleResult = () => {
        return (
            updateActivitiesData({
                newActivity: {
                    ...newInquiryActivityObject,
                    systemComment: selectedResult.name,
                },
                activityType: 'Offer',
            }),
            showModal()
        );
    };

    const handleCheckButton = () => {
        if (typeOfNewActivity === 'Result') {
            return handleResult();
        }
        if (typeOfNewActivity === 'Zweite termin gehalten') {
            return handleZweiteTerminGehalten();
        }
        if (typeOfNewActivity === 'Appoint. Held') {
            return handleTerminGehalten();
        }
        if (typeOfNewActivity === 'Abgeschlossen') {
            return handleAbgeschlossen();
        }
        if (typeOfNewActivity === 'Conf. App.') {
            return handleConfAppCalendar();
        }

        if (typeOfNewActivity === 'Sec. App.') {
            return handleCalendar();
        }
        if (typeOfNewActivity === 'Besichtigung') {
            return (
                showModal(),
                updateActivitiesData({
                    newActivity: { ...newInquiryActivityObject },
                })
            );
        }
        return (
            showModal(),
            updateActivitiesData({ newActivity: newInquiryActivityObject })
        );
    };

    const handleResetButton = () => {
        setNewInquiryActivityObject({
            appointmentDate: todayDate,
            time: moment(todayDate).format('HH:mm'),
            comment: '',
            systemComment: '',
        });
    };

    const renderFooter = () => {
        return (
            <div>
                {/* <Button
                    label={t('Refresh')}
                    icon="pi pi-refresh"
                    onClick={handleResetButton}
                    autoFocus
                /> */}
                <Button
                    label={t('Save')}
                    icon="pi pi-check"
                    onClick={handleCheckButton}
                />
            </div>
        );
    };

    const {
        control,
        formState: { errors },
        register,
    } = useForm({
        mode: 'onBlur',
    });

    return (
        <>
            <Toast ref={toast} position={'top-right'} />
            <Dialog
                header={
                    t('New Activity - ') + ' - ' + `${t(typeOfNewActivity)}`
                }
                footer={renderFooter}
                style={{ minWidth: '26vw' }}
                visible={visibleNewActivity}
                blockScroll
                onHide={showModal}
            >
                <div className="modal-body">
                    {typeOfNewActivity === 'Conf. App.' ||
                    typeOfNewActivity === 'Sec. App.' ? (
                        <>
                            {/* <div className="modal-input">
                                <CustomObjectInput
                                    type="calendar"
                                    classes={['date-input']}
                                    id="date-input"
                                    label={t('Reminder')}
                                    name="calendar"
                                    dateType={'date'}
                                    register={register}
                                    stateField={reminderDate.calendar}
                                    setStateField={handleReminderDate}
                                    errors={errors}
                                    control={control}
                                />
                            </div> */}
                            <div className="modal-input column-span-2 ">
                                <CustomObjectInput
                                    type="calendar"
                                    classes={['time-input']}
                                    id="time-input"
                                    label={t('Time')}
                                    name="time"
                                    dateType={'time'}
                                    onlyTime={true}
                                    stateField={reminderDate.calendar}
                                    setStateField={handleReminderDate}
                                    errors={errors}
                                    control={control}
                                />
                            </div>
                        </>
                    ) : null}
                    {typeOfNewActivity === 'Abgeschlossen'
                        ? categories.map((category) => {
                              return (
                                  <div
                                      key={category.key}
                                      className="field-radiobutton"
                                  >
                                      <RadioButton
                                          inputId={category.key}
                                          name="price_priceType"
                                          value={category}
                                          onChange={(e) => {
                                              setSelectedCategory(e.value);
                                          }}
                                          checked={
                                              selectedCategory.key ===
                                              category.key
                                          }
                                          disabled={category.key === 'R'}
                                      />
                                      <label
                                          className="radio-button-label"
                                          htmlFor={category.key}
                                      >
                                          {category.name}
                                      </label>
                                  </div>
                              );
                          })
                        : null}
                    {typeOfNewActivity === 'Abgeschlossen'
                        ? selectedCategory.name === 'Property'
                            ? checkboxProperty.map((checkbox, i) => {
                                  return (
                                      <div
                                          key={i}
                                          className="checkbox-container"
                                      >
                                          <CustomObjectInput
                                              type="checkbox"
                                              containerClassNames={[
                                                  'generic-input',
                                                  'column-span-2',
                                              ]}
                                              classes={['checkbox-middle']}
                                              id="publish-prices"
                                              label={`${checkbox}`}
                                              name={`${checkbox}`}
                                              stateField={property[checkbox]}
                                              setStateField={
                                                  handleCheckBoxProperty
                                              }
                                              errors={errors}
                                              control={control}
                                          />
                                      </div>
                                  );
                              })
                            : checkboxInteresent.map((checkbox, i) => {
                                  return (
                                      <div
                                          key={i}
                                          className="checkbox-container"
                                      >
                                          <CustomObjectInput
                                              type="checkbox"
                                              containerClassNames={[
                                                  'generic-input',
                                                  'column-span-2',
                                              ]}
                                              classes={['checkbox-middle']}
                                              id="publish-prices"
                                              label={`${checkbox}`}
                                              name={`${checkbox}`}
                                              stateField={interesent[checkbox]}
                                              setStateField={
                                                  handleCheckBoxInteresent
                                              }
                                              errors={errors}
                                              control={control}
                                          />
                                      </div>
                                  );
                              })
                        : null}
                    {typeOfNewActivity === 'Appoint. Held'
                        ? categoriesTerminGehalten.map((category) => {
                              return (
                                  <div
                                      key={category.key}
                                      className="field-radiobutton"
                                  >
                                      <RadioButton
                                          inputId={category.key}
                                          name="price_priceType"
                                          value={category}
                                          onChange={(e) => {
                                              setSelectedCategoryTerminGehalten(
                                                  e.value,
                                              );
                                          }}
                                          checked={
                                              selectedCategoryTerminGehalten.key ===
                                              category.key
                                          }
                                          disabled={category.key === 'R'}
                                      />
                                      <label
                                          className="radio-button-label"
                                          htmlFor={category.key}
                                      >
                                          {category.name}
                                      </label>
                                  </div>
                              );
                          })
                        : null}
                    {typeOfNewActivity === 'Zweite termin gehalten'
                        ? categoriesZweiteTerminGehalten.map((category) => {
                              return (
                                  <div
                                      key={category.key}
                                      className="field-radiobutton"
                                  >
                                      <RadioButton
                                          inputId={category.key}
                                          name="price_priceType"
                                          value={category}
                                          onChange={(e) => {
                                              setSelectedZweiteTerminGehalten(
                                                  e.value,
                                              );
                                          }}
                                          checked={
                                              selectedZweiteTerminGehalten.key ===
                                              category.key
                                          }
                                          disabled={category.key === 'R'}
                                      />
                                      <label
                                          className="radio-button-label"
                                          htmlFor={category.key}
                                      >
                                          {category.name}
                                      </label>
                                  </div>
                              );
                          })
                        : null}
                    {typeOfNewActivity === 'Result'
                        ? categoriesResult.map((category) => {
                              return (
                                  <div
                                      key={category.key}
                                      className="field-radiobutton"
                                  >
                                      <RadioButton
                                          inputId={category.key}
                                          name="price_priceType"
                                          value={category}
                                          onChange={(e) => {
                                              setSelectedResult(e.value);
                                          }}
                                          checked={
                                              selectedResult.key ===
                                              category.key
                                          }
                                          disabled={category.key === 'R'}
                                      />
                                      <label
                                          className="radio-button-label"
                                          htmlFor={category.key}
                                      >
                                          {category.name}
                                      </label>
                                  </div>
                              );
                          })
                        : null}
                    <div className="modal-input column-span-2">
                        <CustomObjectInput
                            type="accordion-textarea"
                            classes={['comment-input']}
                            id="comment-input"
                            label={t('Comment')}
                            name="comment"
                            stateField={newInquiryActivityObject.comment}
                            setStateField={handleNewInquiryActivity}
                            errors={errors}
                            control={control}
                        />
                    </div>
                </div>
            </Dialog>
        </>
    );
};

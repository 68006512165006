import { Config } from '../Config';
import { CrudService } from './CrudService';
import { AxiosResponse } from "axios";
import { CreateQueryParams } from '@nestjsx/crud-request';
import { Contact } from '../models/Contact';
import { axiosDefaults } from './AxiosDefaults';

export class ContactsService extends CrudService<Contact, 'Contact'> {
    constructor() {
        super();
    };

    protected getUrlPath(): string {
        return '/contacts';
    };

    public async getOne(id: string | number): Promise<AxiosResponse<Contact>> {
        const contactResponse = await super.getOne(id);
        return new Promise((resolve: (contact) => void, reject: () => void) => {resolve(contactResponse)})
    };

    public async getMany(params?: CreateQueryParams): Promise<AxiosResponse<Contact[]>> {
        const contactsResponse = await super.getMany(params);
        return new Promise((resolve: (contact) => void, reject: () => void) => {resolve(contactsResponse)});
    };

    public async createOne(contact: Contact): Promise<AxiosResponse<Contact>> {
        let body: Contact = {...contact};
        const newBody = JSON.stringify(body);
        return this.axiosInstance.post(
            axiosDefaults.baseURL + this.getUrlPath(),
            newBody,
            {withCredentials: true, headers: {'Content-Type': 'application/json'}},
        );
    };

    public async updateOne(id: string, contact: Contact): Promise<AxiosResponse<Contact>> {
        let body: Contact = {...contact};
        let newBody = await JSON.stringify(body);
        return this.axiosInstance.put(
            axiosDefaults.baseURL + `/contacts/${id}`,
            newBody,
            {withCredentials: true, headers: {'Content-Type': 'application/json'}},
        );
    };

    public deleteOne(id: string | number): Promise<AxiosResponse<Contact>> {
        return this.axiosInstance.delete(axiosDefaults.baseURL + this.getUrlPath() + "/" + id);
    };
};

export const contactsService = new ContactsService();

import { User } from '../models/User';
import { Config } from '../Config';
import { CrudService } from './CrudService';
import { AxiosResponse } from 'axios';
import * as _ from 'lodash';
import { axiosDefaults } from './AxiosDefaults';

export class UsersService extends CrudService<User, 'Users'> {
    constructor() {
        super();
    }

    protected getUrlPath(): string {
        return '/users';
    }

    public async login(username: string, password: string) {
        return this.axiosInstance.post(
            axiosDefaults.baseURL + '/auth/login',
            {
                username,
                password,
                client_id: Config.AUTH_CLIENT_ID,
                client_secret: Config.AUTH_CLIENT_SECRET,
                grant_type: Config.AUTH_GRANT_TYPE_PASS,
            },
            {
                withCredentials: true,
                headers: { 'Content-Type': 'application/json' },
            },
        );
    }

    public async logout() {
        return this.axiosInstance.post(
            axiosDefaults.baseURL + '/auth/logout',
            {
                client_id: Config.AUTH_CLIENT_ID,
                client_secret: Config.AUTH_CLIENT_SECRET,
                grant_type: Config.AUTH_GRANT_TYPE_PASS,
            },
            {
                withCredentials: true,
                headers: { 'Content-Type': 'application/json' },
            },
        );
    }

    public async tokenCheck() {
        return this.axiosInstance.get(axiosDefaults.baseURL + '/auth/check', {
            withCredentials: true,
        });
    }

    public async refreshTokenCheck() {
        return this.axiosInstance.post(
            axiosDefaults.baseURL + '/auth/refresh',
            { withCredentials: true },
        );
    }

    public async register(username: string, password: string) {
        // TODO set register newUser service
        return this.axiosInstance.post(
            axiosDefaults.baseURL + '/user/newUser',
            {
                username,
                password,
                client_id: Config.AUTH_CLIENT_ID,
                client_secret: Config.AUTH_CLIENT_SECRET,
                grant_type: Config.AUTH_GRANT_TYPE_PASS,
            },
            {
                withCredentials: false,
                headers: { 'Content-Type': 'application/json' },
            },
        );
    }

    public async confirmEmail(email: string) {
        // TODO confirm email service
        return this.axiosInstance.post(
            axiosDefaults.baseURL + '/user/newUser/confirmEmail',
            {
                email,
                client_id: Config.AUTH_CLIENT_ID,
                client_secret: Config.AUTH_CLIENT_SECRET,
                grant_type: Config.AUTH_GRANT_TYPE_PASS,
            },
            {
                withCredentials: false,
                headers: { 'Content-Type': 'application/json' },
            },
        );
    }

    public async forgotPassword(email: string) {
        return this.axiosInstance.post(
            axiosDefaults.baseURL + '/auth/reset',
            {
                email,
            },
            {
                withCredentials: false,
                headers: { 'Content-Type': 'application/json' },
            },
        );
    }

    public async resetPassword(
        token: string,
        password: string,
        confirmPassword: string,
    ) {
        return this.axiosInstance.put(
            axiosDefaults.baseURL + '/auth/reset/password',
            {
                token,
                password,
                confirmPassword,
            },
            {
                withCredentials: false,
                headers: { 'Content-Type': 'application/json' },
            },
        );
    }

    public async changePassword(oldPassword: string, newPassword: string) {
        return this.axiosInstance.post(
            axiosDefaults.baseURL + this.getUrlPath() + '/change-password',
            {
                password: oldPassword,
                newPassword,
            },
            {
                withCredentials: false,
                headers: { 'Content-Type': 'application/json' },
            },
        );
    }

    public async addNewFirstDate(
        status: string,
        acquisition: boolean,
        appointmentDate: string,
        importantDate: string,
        propertyId: number,
        sellerId: number,
    ) {
        return this.axiosInstance.post(
            axiosDefaults.baseURL + '/first-dates ',
            {
                status,
                acquisition,
                appointmentDate,
                importantDate,
                propertyId,
                sellerId,
            },
            {
                withCredentials: true,
                headers: { 'Content-Type': 'application/json' },
            },
        );
    }

    public async addNewUser(
        roleId: string,
        name: string,
        email: string,
        password: string,
        phoneNumber: string,
        smtp: any,
        address: any,
        positionTitle: string,
        active: boolean,
        superiorId?: string,
        teamName?: string,
    ) {
        return this.axiosInstance.post(
            axiosDefaults.baseURL + this.getUrlPath(),
            {
                roleId,
                name,
                email,
                password,
                phoneNumber,
                ...smtp,
                ...address,
                positionTitle,
                superiorId,
                active,
                teamName,
            },
            {
                withCredentials: true,
                headers: { 'Content-Type': 'application/json' },
            },
        );
    }

    public async editSingleUser(
        roleId: string,
        name: string,
        email: string,
        password: string,
        phoneNumber: string,
        idUser: any,
        smtp: any,
        address: any,
        positionTitle: string,
        active: boolean,
        superiorId?: string,
        teamName?: string,
    ) {
        return this.axiosInstance.put(
            axiosDefaults.baseURL + `/users/${idUser}`,
            {
                roleId,
                name,
                email,
                password,
                phoneNumber,
                ...smtp,
                ...address,
                positionTitle,
                active,
                superiorId,
                teamName,
            },
            {
                withCredentials: true,
                headers: { 'Content-Type': 'application/json' },
            },
        );
    }

    public async addNewEvent(
        title: string,
        description: string,
        startDateAndTime: number,
        endDateAndTime: number,
        status: string,
        creatorId: number,
        contactId: number,
        propertyId: number,
    ) {
        return this.axiosInstance.post(
            axiosDefaults.baseURL + '/calendar',
            {
                title,
                description,
                startDateAndTime,
                endDateAndTime,
                status,
                creatorId,
                contactId,
                propertyId,
            },
            {
                withCredentials: true,
                headers: { 'Content-Type': 'application/json' },
            },
        );
    }

    public async deleteCalendarEvent(id: number) {
        return this.axiosInstance.delete(
            axiosDefaults.baseURL + `/calendar/${id}`,
        );
    }

    public async getInitialEvents(id: number, show) {
        return this.axiosInstance.get(
            axiosDefaults.baseURL + `/calendar` + '?' + `teamView=${show}`,
            {
                withCredentials: true,
                headers: { 'Content-Type': 'application/json' },
            },
        );
    }

    public async getPropertyCalendarId(id: number) {
        return this.axiosInstance.get(
            axiosDefaults.baseURL + `/calendar/propertyID/${id}`,
            {
                withCredentials: true,
                headers: { 'Content-Type': 'application/json' },
            },
        );
    }

    public async editNewEvent(
        title: string,
        status: string,
        startDateAndTime: string,
        endDateAndTime: string,
        creatorId: number,
        contactId: number,
        propertyId: number,
        description: string,
        idEvent: number,
    ) {
        return this.axiosInstance.put(
            axiosDefaults.baseURL + `/calendar/${idEvent}`,
            {
                title,
                status,
                startDateAndTime,
                endDateAndTime,
                creatorId,
                contactId,
                propertyId,
                description,
            },
            {
                withCredentials: true,
                headers: { 'Content-Type': 'application/json' },
            },
        );
    }

    public async getMany() {
        return this.axiosInstance.get(
            axiosDefaults.baseURL + this.getUrlPath(),
            {
                withCredentials: true,
                headers: { 'Content-Type': 'application/json' },
            },
        );
    }

    public async getOne(id: string | number): Promise<AxiosResponse<User>> {
        const userResponse = await super.getOne(id);
        return new Promise((resolve: (user) => void, reject: () => void) => {
            resolve(userResponse);
        });
    }

    public hasPermission(user: User, permission: string): boolean {
        const userPermissions = user.role.permissionsString.split(';');
        if (userPermissions && userPermissions.includes('*')) {
            return true;
        }
        if (userPermissions.includes(permission)) {
            return true;
        }
        return false;
    }

    public hasAnyPermission(user: User, permissions: string[]): boolean {
        if (this.hasPermission(user, '*')) {
            return true;
        }
        for (let permission of permissions) {
            if (this.hasPermission(user, permission)) {
                return true;
            }
        }
        return false;
    }

    public hasAllPermissions(user: User, permissions: string[]): boolean {
        if (user.isAdmin) {
            return true;
        }
        for (let permission of permissions) {
            if (!this.hasPermission(user, permission)) {
                return false;
            }
        }
        return true;
    }
}

export const userService = new UsersService();

import * as React from 'react';
import { useApplicationContext } from '../../application/ApplicationContext';
import { InputText } from 'primereact/inputtext';
import { Password } from 'primereact/password';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Fieldset } from 'primereact/fieldset';
import 'primereact/resources/themes/arya-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import App from '../../App';
import './Login.scss';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export default function LoginForm() {
    const { appState, login, showPasswordReset, loginGoBack, tokenCheck } =
        useApplicationContext();
    const [username, setUsername] = React.useState(appState.loginAsUsername);
    const [password, setPassword] = React.useState('');
    const { t } = useTranslation();

    const toast = React.useRef(null);
    React.useEffect(() => {
        toast.current.clear();
        if (appState.authenticationError) {
            toast.current.show({
                severity: 'error',
                detail: `${appState.authenticationError}`,
                life: 3000,
            });
        }
    }, [appState.authInProgress, appState.authenticationError]);

    React.useEffect(() => {
        toast.current.clear();
        if (appState.passwordResetUsername) {
            toast.current.show({
                severity: 'success',
                detail: t(`Check your email`),
                life: 3000,
            });
        }
    }, [appState.passwordResetUsername]);

    return (
        <>
            <Toast ref={toast} position={'top-right'} />
            <div className="custom-login-body reset">
                <div className="login-wrapper">
                    <div>
                        <div className="custom-login-form">
                            <div className="login-header">
                                {t('Prijavite se')}
                            </div>
                            <Fieldset>
                                <InputText
                                    className="input-field"
                                    placeholder={t('E-mail addresa')}
                                    id="in"
                                    value={username}
                                    autoComplete="off"
                                    onChange={(
                                        e: React.ChangeEvent<HTMLInputElement>,
                                    ) => setUsername(e.target.value)}
                                    onKeyDown={(
                                        e: React.KeyboardEvent<HTMLInputElement>,
                                    ) =>
                                        e.key === 'Enter' &&
                                        login(username, password)
                                    }
                                />
                            </Fieldset>
                            <Fieldset>
                                <Password
                                    className="input-field"
                                    placeholder={`${t('Password')} *`}
                                    value={password}
                                    feedback={false}
                                    onChange={(
                                        e: React.ChangeEvent<HTMLInputElement>,
                                    ) => setPassword(e.target.value)}
                                    onKeyDown={(
                                        e: React.KeyboardEvent<HTMLInputElement>,
                                    ) =>
                                        e.key === 'Enter' &&
                                        login(username.toLowerCase(), password)
                                    }
                                />
                            </Fieldset>
                            <div className="forgot-password">
                                <Link
                                    to="#"
                                    onClick={(e: React.MouseEvent) =>
                                        showPasswordReset()
                                    }
                                >
                                    {t('Zaboravljena lozinka?')}
                                </Link>
                            </div>
                            <Button
                                className="login-button"
                                label={t('NASTAVI')}
                                onClick={(e: React.MouseEvent) => {
                                    login(username.toLowerCase(), password);
                                }}
                                onKeyDown={(
                                    e: React.KeyboardEvent<HTMLElement>,
                                ) =>
                                    e.key === 'Enter' &&
                                    login(username.toLowerCase(), password)
                                }
                            ></Button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useForm } from 'react-hook-form';
import CustomObjectInput from '../objects/customObjectInput/CustomObjectInput';
import './NewContactModal.scss';
import { calendarEventStatus } from '../../models/CalendarFormModal';
import { contactsService } from '../../services/ContactsService';
import { propertiesService } from '../../services/PropertiesService';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { useTranslation } from 'react-i18next';
import { userService } from '../../services/UsersService';
import { Card } from 'primereact/card';
import CardHeader from '../card/CardHeader';
import { Toast } from 'primereact/toast';
import {
    TypeOfContact,
    SalutationType,
    ContactRoleOptions,
} from '../../constants/ContactConsts';
import { ContactRole } from '../../constants/ContactConsts';
import moment from 'moment';
import { useApplicationContext } from '../../application/ApplicationContext';

const NewContactModal = ({ show, contactRole, toasts, getSellerId }) => {
    const { t } = useTranslation();
    const [status, setStatus] = useState<number>(0);
    const [employeeList, setEmployeeList] = useState([]);
    const { appState } = useApplicationContext();

    const [newContact, setNewContact] = useState({
        id: null,
        userId: null,
        type: null,
        role: contactRole === 'Buyer' ? ContactRole.BUYER : ContactRole.SELLER,
        categoryId: 1,
        assigneeId: null,
        salutation: null,
        prefix: null,
        suffix: null,
        firstName: null,
        lastName: null,
        dateOfBirth: null,
        company: null,
        positionInCompany: null,
        mobilePhone: null,
        houseNumber: null,
        apartmentNumber: null,
        workPhone: null,
        privatePhone: null,
        fax: null,
        address: null,
        postalCode: null,
        city: null,
        county: null,
        country: null,
        emails: [],
    });

    const fetchUsers = async () => {
        const response = await userService.getMany();
        return response.data;
    };

    const employeeOptions = () => {
        let allEmployees = [];
        for (const employee of employeeList) {
            allEmployees.push({
                label: employee.name,
                value: employee.id,
            });
        }
        return allEmployees;
    };

    useEffect(() => {
        userService.refreshTokenCheck().then(() => {
            fetchUsers().then((result) => setEmployeeList(result));
        });
    }, []);

    const updateContact = (property, value) => {
        let newContactChanged = { ...newContact };
        newContactChanged[property] = value;
        setNewContact((newContact) => ({
            ...newContact,
            ...newContactChanged,
        }));
    };

    const toast = useRef(null);
    useEffect(() => {
        if (status === 201) {
            toasts.current.show({
                severity: 'success',
                detail: t(`New contact has been saved successfully`),
                life: 3000,
            });
            show();
            setStatus(0);
        }
        if (status === 400) {
            toast.current.show({
                severity: 'error',
                detail: t(`Email you entered already exists`),
                life: 3000,
            });
            setStatus(0);
        }
        if (status === 500) {
            toast.current.show({
                severity: 'error',
                detail: t(`internal server error`),
                life: 3000,
            });
            setStatus(0);
        }
    }, [status]);

    const onSubmit = () => {
        let correctChanges: boolean = true;
        for (const email of newContact.emails) {
            if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,})+$/.test(email))
                correctChanges = false;
        }
        if (!newContact.assigneeId) {
            newContact.assigneeId = appState.currentUser.id;
        }
        correctChanges
            ? contactsService
                  .createOne(newContact)
                  .then((response) => {
                      getSellerId(response.data.id);
                      setStatus(response.status);
                  })
                  .catch((err) => setStatus(err.response.status))
            : toast.current.show({
                  severity: 'error',
                  detail: t(`Fill the emails field in the correct format`),
                  life: 3000,
              });
    };

    const onError = () => {
        toast.current.show({
            severity: 'error',
            detail: t(`Fill all necessary fields`),
            life: 3000,
        });
    };

    const {
        control,
        formState: { errors },
        handleSubmit,
        register,
    } = useForm({
        mode: 'onBlur',
    });

    const footer = (
        <div>
            <Button
                label={t('Save')}
                icon="pi pi-check"
                onClick={() => {
                    handleSubmit(onSubmit, onError);
                }}
                type="submit"
                form="form"
            />
            {/* <Button
                label={t('Refresh')}
                className="p-button-secondary"
                icon="pi pi-trash"
                onClick={() => {}}
            /> */}
        </div>
    );

    return (
        <>
            <Toast ref={toast} position={'top-right'} />
            <Dialog
                header={t('Verkäufer erfassen')}
                visible={true}
                style={{ width: '80vw' }}
                className="edit-modal"
                draggable={false}
                blockScroll
                footer={footer}
                onHide={show}
            >
                <form id="form" onSubmit={handleSubmit(onSubmit, onError)}>
                    <div className="new-contact-form-wrapper">
                        <div>
                            <Card
                                className="basic-section"
                                header={
                                    <CardHeader
                                        title={t('Allgemein (opšti podaci)')}
                                        className="card-header-background"
                                    />
                                }
                            >
                                <div className="section-row">
                                    <CustomObjectInput
                                        type="dropdown"
                                        classes={['generic-input']}
                                        id="type-input"
                                        label={t('Art (vrsta, tip)')}
                                        name="type"
                                        stateField={newContact.type}
                                        setStateField={updateContact}
                                        errors={errors}
                                        control={control}
                                        //rules={{ required: t('This is required') }}
                                        dropdownOptions={TypeOfContact}
                                    />
                                    <CustomObjectInput
                                        type="dropdown"
                                        classes={['generic-input']}
                                        id="assignee-id-input"
                                        label={t('Supervisor')}
                                        name="assigneeId"
                                        stateField={newContact.assigneeId}
                                        setStateField={updateContact}
                                        dropdownOptions={employeeOptions()}
                                        errors={errors}
                                        control={control}
                                        // rules={{
                                        //     required: t('This is required'),
                                        // }}
                                    />
                                </div>
                                <div className="section-row">
                                    <CustomObjectInput
                                        type="dropdown"
                                        classes={['generic-input']}
                                        id="type-input"
                                        label={t('Contact role') + ' *'}
                                        name="role"
                                        stateField={newContact.role}
                                        setStateField={updateContact}
                                        errors={errors}
                                        control={control}
                                        disabled={true}
                                        dropdownOptions={ContactRoleOptions}
                                    />
                                </div>
                            </Card>
                            <Card
                                className="address-section"
                                header={
                                    <CardHeader
                                        title={t('Adresse')}
                                        className="card-header-background"
                                    />
                                }
                            >
                                <div className="section-row-three">
                                    <CustomObjectInput
                                        type="text"
                                        classes={['generic-input']}
                                        id="address-input"
                                        label={t('Straße / ulica')}
                                        name="address"
                                        stateField={newContact.address}
                                        setStateField={updateContact}
                                        errors={errors}
                                        control={control}
                                        //rules={{ required: t('This is required') }}
                                    />
                                    <CustomObjectInput
                                        type="text"
                                        classes={['generic-input']}
                                        id="postal-code-input"
                                        label={t('Haus Nr.')}
                                        name="houseNumber"
                                        stateField={newContact.houseNumber}
                                        setStateField={updateContact}
                                        errors={errors}
                                        control={control}
                                        //rules={{ required: t('This is required') }}
                                    />
                                    <CustomObjectInput
                                        type="text"
                                        classes={['generic-input']}
                                        id="postal-code-input"
                                        label={t('Wohnungs Nr.')}
                                        name="apartmentNumber"
                                        stateField={newContact.apartmentNumber}
                                        setStateField={updateContact}
                                        errors={errors}
                                        control={control}
                                        //rules={{ required: t('This is required') }}
                                    />
                                </div>
                                <div className="section-row-three">
                                    <CustomObjectInput
                                        type="text"
                                        classes={['generic-input']}
                                        id="postal-code-input"
                                        label={t('poštanski broj')}
                                        name="postalCode"
                                        stateField={newContact.postalCode}
                                        setStateField={updateContact}
                                        errors={errors}
                                        control={control}
                                        //rules={{ required: t('This is required') }}
                                    />
                                    <CustomObjectInput
                                        type="text"
                                        classes={['generic-input']}
                                        id="city-input"
                                        label={t('Mesto')}
                                        name="city"
                                        stateField={newContact.city}
                                        setStateField={updateContact}
                                        errors={errors}
                                        control={control}
                                        //rules={{ required: t('This is required') }}
                                    />
                                    <CustomObjectInput
                                        type="dropdown"
                                        classes={['generic-input']}
                                        id="country-input"
                                        label={t('Land / država')}
                                        name="country"
                                        stateField={newContact.country}
                                        setStateField={updateContact}
                                        errors={errors}
                                        control={control}
                                        //rules={{ required: t('This is required') }}
                                        dropdownOptions={[
                                            t('Austria'),
                                            t('Germany'),
                                            t('Switzerland'),
                                        ]}
                                    />
                                </div>
                            </Card>
                        </div>
                        <div className="box">
                            <Card
                                className="personal-section"
                                header={
                                    <CardHeader
                                        title={t('Personendaten/lični podaci')}
                                        className="card-header-background"
                                    />
                                }
                            >
                                <div className="prefix-suffix-section-row">
                                    <CustomObjectInput
                                        type="dropdown"
                                        classes={['generic-input']}
                                        id="salutation-input"
                                        label={t('Anrede')}
                                        name="salutation"
                                        stateField={newContact.salutation}
                                        setStateField={updateContact}
                                        errors={errors}
                                        control={control}
                                        //rules={{ required: t('This is required') }}
                                        dropdownOptions={SalutationType}
                                    />
                                    <CustomObjectInput
                                        type="text"
                                        classes={['generic-input']}
                                        id="prefix-input"
                                        label={t('Titel / Präfix')}
                                        name="prefix"
                                        stateField={newContact.prefix}
                                        setStateField={updateContact}
                                        errors={errors}
                                        control={control}
                                        //rules={{ required: t('This is required') }}
                                    />
                                    <CustomObjectInput
                                        type="text"
                                        classes={['generic-input']}
                                        id="suffix-input"
                                        label={t('Suffix')}
                                        name="suffix"
                                        stateField={newContact.suffix}
                                        setStateField={updateContact}
                                        errors={errors}
                                        control={control}
                                        //rules={{ required: t('This is required') }}
                                    />
                                </div>
                                <div className="section-row">
                                    <CustomObjectInput
                                        type="text"
                                        classes={['generic-input']}
                                        id="first-name-input"
                                        label={t('Vorname / Ime') + ' *'}
                                        name="firstName"
                                        stateField={newContact.firstName}
                                        setStateField={updateContact}
                                        errors={errors}
                                        control={control}
                                        rules={{
                                            required: t('This is required'),
                                        }}
                                    />
                                    <CustomObjectInput
                                        type="text"
                                        classes={['generic-input']}
                                        id="last-name-input"
                                        label={t('Nachname / prezime') + ' *'}
                                        name="lastName"
                                        stateField={newContact.lastName}
                                        setStateField={updateContact}
                                        errors={errors}
                                        control={control}
                                        rules={{
                                            required: t('This is required'),
                                        }}
                                    />
                                </div>
                                <div className="section-row">
                                    {/* <CustomObjectInput
                                        type="calendar"
                                        classes={['generic-input']}
                                        id="date-of-birth-input"
                                        label={t(
                                            'Geburtsdatum / datum rođenja',
                                        )}
                                        dateType="date"
                                        name="dateOfBirth"
                                        maxDate={'2022-07-19T12:00:00.000Z'}
                                        stateField={newContact.dateOfBirth}
                                        setStateField={updateContact}
                                        errors={errors}
                                        control={control}
                                        //rules={{ required: t('This is required') }}
                                    /> */}
                                    <CustomObjectInput
                                        type="text"
                                        classes={['generic-input']}
                                        id="mobile-phone-input"
                                        label={t('Telefonnummer')}
                                        name="privatePhone"
                                        stateField={newContact.privatePhone}
                                        setStateField={updateContact}
                                        errors={errors}
                                        control={control}
                                        //rules={{ required: t('This is required') }}
                                    />
                                </div>
                                <div className="emails-section-row">
                                    <CustomObjectInput
                                        type="chips"
                                        classes={['generic-input']}
                                        id="email-input"
                                        label={t('Emails') + ' *'}
                                        name="emails"
                                        stateField={newContact.emails}
                                        setStateField={updateContact}
                                        errors={errors}
                                        control={control}
                                        rules={{
                                            required: t('This is required'),
                                            pattern:
                                                /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,})+$/,
                                        }}
                                    />
                                    <p>
                                        {t('Press enter to save E-mail') + ' *'}
                                    </p>
                                </div>
                                <div className="section-row">
                                    <CustomObjectInput
                                        type="text"
                                        classes={['generic-input']}
                                        id="company-input"
                                        label={t('Firma / kompanija')}
                                        name="company"
                                        stateField={newContact.company}
                                        setStateField={updateContact}
                                        errors={errors}
                                        control={control}
                                        //rules={{ required: t('This is required') }}
                                    />
                                    <CustomObjectInput
                                        type="text"
                                        classes={['generic-input']}
                                        id="position-in-company-input"
                                        label={t('Position / položaj')}
                                        name="positionInCompany"
                                        stateField={
                                            newContact.positionInCompany
                                        }
                                        setStateField={updateContact}
                                        errors={errors}
                                        control={control}
                                        //rules={{ required: t('This is required') }}
                                    />
                                </div>
                            </Card>
                        </div>
                    </div>
                </form>
            </Dialog>
        </>
    );
};

export default NewContactModal;

module.exports = {
    "Username or password is wrong": "Username or password is wrong",
    "Unknown error": "Unknown error",
    "Unknown user": "Unknown user",
    "Confirm email": "Confirm email",
    "Password": "Password",
    "Users": "Users",
    "User": "User",
    "Username": "Username",
    "Send forgotten password": "Send forgotten password",
    "Reset password": "Reset password",
    "Sign up": "Sign up",
    "E-mail address": "E-mail address",
    "Back": "Back",
    "Forgotten password?": "Forgotten password?",
    "CONTINUE": "CONTINUE",
    "Forgotten password": "Forgotten password",
    "Password must have min 8 character, 1 uppercase, 1 lowercase, 1 number and 1 special character": "Password must have min 8 character, 1 uppercase, 1 lowercase, 1 number and 1 special character",
    "password dont match!": "password dont match!",
    "Password change successfully!": "Password change successfully!",
    "Password reset": "Password reset",
    "Repeat password": "Repeat password",
    "Reset password error": "Reset password error",
    "Edit": "Edit",
    "Delete": "Delete",
    "Edit Event": "Edit Event",
    "Title": "Title",
    "This is required": "This is required",
    "Activity type": "Activity type",
    "Date and time from": "Date and time from",
    "Date and time to": "Date and time to",
    "Object": "Object",
    "Contact": "Contact",
    "Info": "Info",
    "Users page": "Users page",
    "Check": "Check",
    "Refresh": "Refresh",
    "New Task": "New Task",
    "Check your email": "Check your email",
    "Ok": "Ok",
    "New Activity - ": "New Activity - ",
    "Date": "Date",
    "Time": "Time",
    "Comment": "Comment",
    "Enter a valid email": "Enter a valid email",
    "Address / location": "Address / location",
    "Zemlja": "Country",
    "Savezna država": "State",
    "Ulica": "Street",
    "Kućni broj": "House number",
    "Ulaz": "Entrance",
    "Vrata": "Door",
    "Poštanski broj": "Plz",
    "Mesto": "Place",
    "Strana sveta": "Side of the world",
    "Blizina": "Proximity",
    "Ključne informacije": "Key info",
    "Objavite adresu sa sledećom tačnošću": "Publish the address template",
    "Greška pri resetu šifre": "Password reset error",
    "Nazad": "Back",
    "Prijavite se": "Sign up",
    "E-mail addresa": "E-mail address",
    "Zaboravljena lozinka?": "Forgotten password?",
    "NASTAVI": "Continue",
    "Zaboravljena šifra": "Forgotten password",
    "Resetovanje šifre": "Password reset",
    "Ponovi lozinku": "Repeat password",
    "Površine / Flächen": "Surfaces",
    "Površina": "Land area",
    "Stambena površina": "Living area",
    "Korisna površina": "Usable area",
    "Površina dozv. za gradnju": "Buildable area",
    "Kancelarijski prostor": "Office area",
    "Prodajni prostor": "Sales area",
    "Skladišni prostor": "Storage area",
    "Visina hale": "Hall height",
    "Slobodan prostor": "Free area",
    "Privremena površina": "Temporary area",
    "Ponderisano područje": "Weighted area",
    "Nedovršeno potkrovlje": "Unfinished attic",
    "Površina potkrovlja": "Attic area",
    "Broj soba": "Number of rooms",
    "0.5 soba": "Has half room",
    "Visina sobe": "Room height",
    "Broj vrtova": "Number of gardens",
    "Površina vrtova": "Garden area",
    "Broj podruma": "Number of basements",
    "Površina podruma": "Basement area",
    "Broj balkona": "Number of balconies",
    "Površina balkona": "Balcony area",
    "Broj terasa": "Number of terraces",
    "Površina terasa": "Terrace area",
    "Broj lođa": "Number of loggias",
    "Površina lođa": "Loggias area",
    "Broj garaža": "Number of garages",
    "Površina garaža": "Garage area",
    "Broj parking mesta": "Number of parking spaces",
    "Površina parkinga": "Parking area",
    "Broj toaleta": "Number of toilets",
    "Broj kupatila": "Number of bathooms",
    "Broj ostava": "Number of storages",
    "Spratnost / Etagen": "Floors",
    "Sprat": "Floor",
    "Sprat - Dodatni opis": "Floor description",
    "Broj spratova": "Number of floors",
    "Broj potkrovlja": "Number of attic",
    "Medjusprat": "Has mezzanine",
    "Energetski sertifikat / Enegieausweis": "Energy certificate",
    "HWB": "HWB",
    "Klasse": "Class",
    "fGEE": "fgee",
    "Važi od": "Valid from",
    "Automaticher Disclaimer": "Automatic disclaimer",
    "Supervisor": "Supervisor",
    "Opšte / Allgemein": "General",
    "Označavanje": "Marking",
    "Bez prepreka": "No obsticle",
    "Tip nekretnine": "Property type",
    "Građevinsko zemljište": "Is building plot",
    "Tlocrt": "Floor plan",
    "Prikladno u turističke svrhe": "Also vacation property",
    "Vrste upotrebe objekata / Nutzungsart": "Type of use",
    "Stanovanje": "Living",
    "Poslovna": "Business",
    "Investicija": "Investment",
    "Prodavac objekta / Objektabgeber": "The seller of the object",
    "Kontakt": "Contact",
    "Vlasnički udeo": "Equity",
    "Ukloniti": "Remove",
    "Abgeber hinzufügen": "Add to favorites",
    "Neuen Abgeber anlegen": "Rent new items",
    "Nalog za posredovanje": "Mediation order",
    "Tip": "Type",
    "Dogovor": "Agreement",
    "Datum isteka": "Expiry date",
    "Zemljišne knjige": "Land registry",
    "Broj priloška": "Deposit number",
    "Katastarska zajednica": "Cadastre number",
    "Broj parcele": "Lot number",
    "Miete / Kirija": "For rent",
    "Maximum rental period": "Maximum rental period",
    "Unlimited rental period": "Unlimited rental period",
    "Waiver of termination": "Waiver of termination",
    "Sonstiges / Drugo": "Other",
    "Status": "Status",
    "Stanje kuće": "House condition",
    "Godina izgradnje": "Construction year",
    "Poslednje saniranje": "Last renovation",
    "Poslednje renoviranje": "Last general renovation",
    "Oblik svojine": "Form of ownership",
    "Opremanje": "Furnishing",
    "Procena situacije": "Situation assesment",
    "Nivo buke": "Noise level",
    "Razvoj": "Development",
    "Procena stanja": "Condition assesment",
    "Ključ u ruke": "Key in hand",
    "Spreman za pokrivanje": "Ready for covering",
    "Slike / Bilder": "Images",
    "Planovi / Plane": "Plans",
    "Video snimci / videos": "Videos",
    "Dokumenta / Dokumente": "Documents",
    "360 pogled / 360 Ansichten": "360 view",
    "Dateien & Links": "Files and links",
    "Documente werden exportiert / veroffentlicht\"": "Exported / published documents",
    "Alle": "All",
    "Expose": "Expose",
    "Intern": "Intern",
    "Loschen": "Clear",
    "Planovi/Plane": "Plans",
    "Video snimci/videos": "Videos",
    "Dokumenta/Dokumente": "Documents",
    "360 pogled/360 Ansichten": "360 view",
    "Opremljenost": "Furnishing",
    "Interno": "Intern",
    "Verkauf am": "Sale on",
    "Provision Verkaufer absolut": "Commission seller absolutely",
    "Provision Kaufer absolut": "Commission buyers absolutely",
    "Kaufer": "Buyer",
    "Rechnung gestelit am": "Invoice made on",
    "Preis it. Kaufanbot": "Price it. purchase offer",
    "Zahlungseinggang Datum": "Date of payment",
    "Izvoz interfejsa (Exportschnittstellen)": "Interface export",
    "Platforma": "Platform",
    "Broj objavljenih nekretnina": "Number of published real estate",
    "Maksimalni broj nekretnina": "Maximum number of real estate",
    "Vermarktungsart / Tip marketinga*": "Type of marketing",
    "Kupovina": "For purchase",
    "Kirija": "For rent",
    "Zakup": "For lease",
    "Preise / cene": "Prices",
    "Kaufpreis": "Purchase price",
    "Netto": "Net price",
    "USt.": "USt.",
    "Brutto": "Gross price",
    "Publish prices": "Publish price",
    "Mesecne naknade": "Monthly fees",
    "Parking": "Parking",
    "Optional": "Optional",
    "Garaža": "Garage",
    "Operativni troškovi": "Operational costs",
    "Troškovi grejanja": "Heating",
    "Vruća voda": "Hot water",
    "Troškovi hlađenja": "Cooling",
    "Fond popravki": "Repair costs",
    "Ostalo": "Other",
    "Održavanje lifta": "Elevator costs",
    "Total": "Total",
    "Preise pro m² / Cena po m2": "Prices per m²",
    "m² - price from": "m² - price from",
    "Purchase price / m²": "Purchase price / m²",
    "Operational costs / m²": "Operational costs / m²",
    "Interested party": "Interested party",
    "Output": "Output",
    "Autofill": "Autofill",
    "Surrender": "Surrender",
    "Notes": "Notes",
    "Text": "Text",
    "Titel (wird exportiert / veröffentlicht) / Naslov (biće izvezen / objavljen)": "Title (will be exported / published)",
    "Beschreibung Internet (wird exportiert / veröffentlicht) / Opis internet (izvozi se / objavljuje)": "Description internet (will be exported / published)",
    "Lage (wird exportiert / veröffentlicht) / Lokacija (biće izvezena / objavljena)": "Location (will be exported / published)",
    "Schulen (Skole)": "Schools",
    "Offentliche Verkehrsmittel (Javni prevoz)": "Public transportation",
    "Einkaufen (Kupovina)": "Shopping",
    "Sonstige Angaben Internet (wird exportiert / veröffentlicht) / Ostale informacije Internet  (izvozi se / objavljuje)": "Purchasing other information Internet (is exported / published)",
    "Interne Beschreibung (wird nicht bei externen Freigaben angezeigt) / Interni opis (nije prikazan za spoljne deljene datoteke)": "Internal description (is not displayed for external shares)",
    "Kurzbeschreibung Exposé (wird im Standard Exposé Auslage verwendet) / Kratki opis Expose (koristi se u standardnom eskpozeu) Prikaz": "Short description exposé (used in the standard expose display)",
    "Beschreibung Exposé / Opis Expose": "Description expose",
    "Base data": "Base data",
    "Prices": "Prices",
    "Furnishing": "Furnishing",
    "Marketing": "Marketing",
    "Aufgaben & Aktivitäten": "Tasks & Activities",
    "Internal": "Internal",
    "Aufgaben & Aktivitaten": "Tasks & Activities",
    "Meine interessent": "My prospect",
    "Overdue": "Overdue",
    "Name": "Name",
    "UNTOUCHED": "UNTOUCHED",
    "ID": "ID",
    "Meine Objekte": "My objects",
    "Total portfolio volume 8.500.000 eur": "Total portfolio volume 8.500.000 eur",
    "IN PROGRESS": "IN PROGRESS",
    "Meine First Dates": "My first dates",
    "In Bearbietung": "In processing",
    "Bestatigt": "Confirmed",
    "Tel Wiedervorl": "Tel resubmission",
    "On Hold": "On Hold",
    "Meine Servise Termine": "My service appointments",
    "This month": "This month",
    "Meine Preisduktion": "My price reduction",
    "Target 5% from my real estate portfolio value": "Target 5% from my real estate portfolio value",
    "RESPONSE TIME": "RESPONSE TIME",
    "Untouched to in Progress": "Untouched to in Progress",
    "Average Mon-Sut": "Average Mon-Sut",
    "Meine First Datest": "My first dates",
    "RESULTS": "RESULTS",
    "This Months": "This Months",
    "Last 3 Months": "Last 3 Months",
    "Absage": "Cancellation",
    "Mandat erhalten": "Received a mandate",
    "List view": "List view",
    "Calendar view": "Calendar view",
    "Please enter a new title for your event": "Please enter a new title for your event",
    "Empty Page": "Empty Page",
    "Use this page to start from scratch and place your custom content.": "Use this page to start from scratch and place your custom content.",
    "Sunday": "Sunday",
    "Monday": "Monday",
    "Tuesday": "Tuesday",
    "Wednesday": "Wednesday",
    "Thursday": "Thursday",
    "Friday": "Friday",
    "Saturday": "Saturday",
    "Untouched": "Untouched",
    "In Bearbeitung": "In processing",
    "Calendar": "Calendar",
    "Neu": "New",
    "Wiedervorlage": "Follow-up",
    "Abgesagt": "Cancelled",
    "On-hold": "On-hold",
    "Mandat-Erhalten": "Mandate-received",
    "Nicht-Aufgenommen": "Not-recorded",
    "akviza": "akviza",
    "Ime": "Name",
    "Lokacija": "Location",
    "Cena": "Price",
    "Termindatum": "Appointment date",
    "Importantdatum": "Important date",
    "Telefon": "Phone",
    "Aktiv": "Active",
    "Passiv": "Passive",
    "NE": "NE",
    "Mail": "Mail",
    "Versendet": "Sent",
    "Empfangen": "Received",
    "Welocome": "Welcome",
    "Personlich": "Personally",
    "Kalender": "Calendar",
    "Geshprachsnotiz": "Conversation note",
    "FD Status": "FD status",
    "Nicht erreichbar": "Not reachable",
    "Bestätigt": "Confirmed",
    "Nicht aufgenommen": "Not recorded",
    "Zurück an CC": "Back to CC",
    "Wiedervorlage/WV": "Resubmission/WV",
    "Kontakt podaci/Kontaktdaten": "Contact details",
    "Saglasnost za ponude: NE": "Consent to bids: no",
    "Add mail": "Add mail",
    "Prikazi kontakt": "Show contact",
    "Nekretnina": "Real estate",
    "Alle Objekte": "All objects",
    "Stambena povrsina": "Living area",
    "Prikazi objekat": "Show real estate",
    "Protokol/Status": "Protocol/Status",
    "Dodeljeno": "Assigned",
    "Dodeljeno putem: Sistem": "Assigned via: System",
    "Prvo pregledavanje": "First review",
    "Poslednja aktivnost": "Last activity",
    "Termin": "Termin",
    "Nezainteresovanost": "Disinterest",
    "Aktivnosti/Aktivitäten": "Activity",
    "Kommunikation": "Communication",
    "Korisnik": "User",
    "Akcija": "Action",
    "Komentar": "Comment",
    "Erneuern": "Renew",
    "Duplizieren": "Duplicate",
    "Drucken": "Print",
    "Mandat Art": "Mandate Art",
    "Angebot": "Offer",
    "Aktivität": "Activity",
    "Aufgabe": "Task",
    "Objekt duplizieren": "Duplicate object",
    "Abg. Nachweis": "Dep. proof",
    "In Vorbereitung": "In preparation",
    "Inactive": "Inactive",
    "VerkaufGH": "SaleGH",
    "Abgeschlossen": "Completed",
    "No objects found": "No objects found",
    "Records per page": "Records per page",
    "Naslovna slika": "Image",
    "Tip Objekta": "Object type",
    "PTT Broj": "PTT number",
    "Adresa": "Address",
    "Prodavac": "Seller",
    "Vrsta ugovora": "Type of contract",
    "Ugovor sklopljen": "Contract concluded",
    "Kreirana nekretnina": "Created property",
    "Exportovano": "Exported",
    "Ažurirano": "Updated",
    "Ubersicht": "Overview",
    "Preis": "Price",
    "Ausstattung": "Furnishing",
    "Texte": "Text",
    "Multimedia": "Multimedia",
    "Freigaben": "Releases",
    "Interessenten": "Interested persons",
    "Matching": "Matching",
    "Abgeschlossesn": "Completed",
    "Gesamt": "In total",
    "Besichtigungen": "Viewing",
    "Titelbild": "Cover photo",
    "Eckdaten (osnovni podaci)": "Main data",
    "Vrsta upotrebe": "Type of use",
    "Tip marketinga": "Type of marketing",
    "Povrsina": "Area",
    "Adresa / Prodavac": "Address / Seller",
    "Broj": "Number",
    "PTT broj": "PTT number",
    "Hard Facts": "Hard facts",
    "Objekat kreiran": "Object created",
    "Broj dana u prodaji": "Number of days on sale",
    "Broj redukcija cene": "Number of price reductions",
    "Ukupan iznos redukcija cene": "Total amount price reductions",
    "Poslednja promena u objektu": "Last change in the object",
    "Poslednja promena cene": "Last price change",
    "Poslednja promena oglasa": "Last ad change",
    "Datum poslednjeg sastanka": "Date of last meeting",
    "Checkliste Information": "Checklist information",
    "Naslov": "Title",
    "Opis interneta": "Description of the Internet",
    "Kompletna adresa": "Complete address",
    "Tip objekta": "Object type",
    "Checkliste Dokumente": "Checklist documents",
    "Plan stana": "Apartment plan",
    "Vazi samo za AT verziju": "Applies to AT version only",
    "Kupoprodajni ugovor (Vazi samo za AT verziju)": "Purchase agreement (Only valid for AT version)",
    "Energetski sertifikat": "Energy certificate",
    "Akontacija zajednickih": "Operating cost description",
    "Obracun zajednickih troskova": "Calculation of common costs",
    "Protokol sastanka": "Protocol of the meeting",
    "Resenje o nacinu obracuna": "Solution on the method of calculation",
    "Polje za interne informacije": "Internal information field",
    "Broj telefona": "Phone number",
    "Makler / Team, Ime, broj tel. i mail adresa agenta": "Agent / Team, name, phone and agent's mail address",
    "Mail adresa agenta": "Agent's mail address",
    "Internet": "Internet",
    "Aktivitaten": "Activities",
    "Dashboard": "Dashboard",
    "First date": "First date",
    "Meine Interesent": "My prospects",
    "Reports": "Reports",
    "Objects": "Objects",
    "New object": "New object",
    "Contacts": "Contacts",
    "Verkaufer Kommunikation": "Seller communication",
    "My Objects": "My Objects",
    "New Object": "New Objects",
    "First Date": "First date",
    "Single View": "Single view",
    "broj": "number",
    "Activity": "Activity",
    "New Sale": "New Sale",
    "Withdrawal Initiated": "Withdrawal Initiated",
    "Question Received": "Question Received",
    "Comment Received": "Comment Received",
    "Quick Withdraw": "Quick Withdraw",
    "Amount": "Amount",
    "Confirm": "Confirm",
    "Shipment Tracking": "Shipment Tracking",
    "Track your ongoing shipments to customers": "Track your ongoing shipments to customers",
    "Search": "Search",
    "New Order": "New Order",
    "You have": "You have",
    "new orders": "new orders",
    "Withdrawn Completed": "Withdrawn Completed",
    "Funds are on their way": "Funds are on their way",
    "Monthly Reports": "Monthly Reports",
    "New reports are ready": "New reports are ready",
    "Comments": "Comments",
    "new comments": "new comments",
    "Chargeback Request": "Chargeback Request",
    "to review": "to review",
    "Profile": "Profile",
    "Settings": "Settings",
    "Inbox": "Inbox",
    "Logout": "Logout",
    "North": "North",
    "East": "East",
    "South": "South",
    "West": "West",
    "Don't export": "Don't export",
    "PLZ & Ort": "PLZ & Ort",
    "ZIP code, place & area": "ZIP code, place & area",
    "ZIP code, city, street number, stairs, apartment number": "ZIP code, city, street number, stairs, apartment number",
    "Office": "Office",
    "Retail trade": "Retail trade",
    "Commercial leisure property": "Commercial leisure property",
    "Plot of land": "Plot of land",
    "House": "House",
    "Agriculture and Forestry": "Agriculture and Forestry",
    "Other": "Other",
    "Apartment": "Apartment",
    "Room": "Room",
    "Apartment building": "Apartment building",
    "Exclusive right to sell": "Exclusive right to sell",
    "Co-exclusive right to sell": "Co-exclusive right to sell",
    "Simple mediation": "Simple mediation",
    "Verbal": "Verbal",
    "Written": "Written",
    "Written (both parties)": "Written (both parties)",
    "Old contruction": "Old contruction",
    "New contruction": "New contruction",
    "Object for demolition": "Object for demolition",
    "Dilapitated object": "Dilapitated object",
    "Load-bearing structure": "Load-bearing structure",
    "First occupancy": "First occupancy",
    "Maintained": "Maintained",
    "Modernized": "Modernized",
    "By arrangement": "By arrangement",
    "As good as new": "As good as new",
    "Projected": "Projected",
    "In need of renovation": "In need of renovation",
    "Shell construction": "Shell construction",
    "Partially renovated": "Partially renovated",
    "Partially refurbished": "Partially refurbished",
    "Fully renovated": "Fully renovated",
    "Fully refurbished": "Fully refurbished",
    "Building constructed on land not owned by the landowner": "Building constructed on land not owned by the landowner",
    "Single owner": "Single owner",
    "Co-ownership": "Co-ownership",
    "Co-ownership with usage rights": "Co-ownership with usage rights",
    "Home ownership": "Home ownership",
    "Home ownership planned": "Home ownership planned",
    "Ownership of home in development": "Ownership of home in development",
    "Building law": "Building law",
    "Bathroom": "Bathroom",
    "Business facility": "Business facility",
    "Kitchenette": "Kitchenette",
    "Kitchen": "Kitchen",
    "Furnished": "Furnished",
    "Unfurnished": "Unfurnished",
    "Partially furnished": "Partially furnished",
    "Very good": "Very good",
    "Good": "Good",
    "Average": "Average",
    "Bad": "Bad",
    "Absolute silent location": "Absolute silent location",
    "Possible silence impairment": "Possible silence impairment",
    "Average silence impairment": "Average silence impairment",
    "Small silence impairment": "Small silence impairment",
    "Peaceful location": "Peaceful location",
    "Partially closed": "Partially closed",
    "Undeveloped": "Undeveloped",
    "Fully developed": "Fully developed",
    "% of purchase price": "% of purchase price",
    "Centrally accessible": "Centrally accessible",
    "Partly centrally accessible": "Partly centrally accessible",
    "Circular walkable": "Circular walkable",
    "Loft": "Loft",
    "Bauweise / Konstrukcija": "Construction",
    "Prefabricated part": "Prefabricated part",
    "Wooden": "Wooden",
    "Solid": "Solid",
    "Reinforced concrete": "Reinforced concrete",
    "Brick": "Brick",
    "Boden / Pod": "Floor",
    "Floorboards": "Floorboards",
    "Raised floor": "Raised floor",
    "Screed": "Screed",
    "Prefabricated parquet": "Prefabricated parquet",
    "Tiles": "Tiles",
    "Granite": "Granite",
    "Plastic floor": "Plastic floor",
    "Laminate": "Laminate",
    "Linoleum": "Linoleum",
    "Marble": "Marble",
    "Parquet": "Parquet",
    "Stone floor": "Stone floor",
    "Carpet": "Carpet",
    "Carpet tiles": "Carpet tiles",
    "Terracotta": "Terracotta",
    "Verwarming / Grejanje": "Heating",
    "Floor Heating": "Floor Heating",
    "District Heating": "District Heating",
    "Underfloor Heating": "Underfloor Heating",
    "Stove": "Stove",
    "Fireplace": "Fireplace",
    "Central Heating": "Central Heating",
    "Heizungsart / Tip grejanja": "Heating type",
    "Alternative": "Alternative",
    "Block type thermal power station": "Block type thermal power station",
    "Electric": "Electric",
    "Geothermal energy": "Geothermal energy",
    "District heating": "District heating",
    "Liquified gas": "Liquified gas",
    "Gas": "Gas",
    "Coal": "Coal",
    "Air heat pump": "Air heat pump",
    "Oil": "Oil",
    "Pellets": "Pellets",
    "Solar energy": "Solar energy",
    "Water electric": "Water electric",
    "Ausblick / Pogled": "View",
    "Mountain view": "Mountain view",
    "Distant view": "Distant view",
    "Green view": "Green view",
    "Sea view": "Sea view",
    "Lake view": "Lake view",
    "City view": "City view",
    "Fenster / Prozor": "Window",
    "External sun protection": "External sun protection",
    "Anti-glare protection": "Anti-glare protection",
    "Double or multiple glazing": "Double or multiple glazing",
    "Internal sun protection": "Internal sun protection",
    "Plastic windows": "Plastic windows",
    "Openable windows": "Openable windows",
    "Shutters": "Shutters",
    "Soundproof windows": "Soundproof windows",
    "Fernsehen / Televizija": "Television",
    "DVBT": "DVBT",
    "Cable or satellite TV": "Cable or satellite TV",
    "Küche / Kuhinja": "Kitchen",
    "Equipped kitchen": "Equipped kitchen",
    "Tea kitchen": "Tea kitchen",
    "Open kitchen": "Open kitchen",
    "Extras / Dodaci": "Extra",
    "Storage room": "Storage room",
    "Alarm system": "Alarm system",
    "Outdoor shower": "Outdoor shower",
    "Library": "Library",
    "Steam bath": "Steam bath",
    "Bike room": "Bike room",
    "Gym": "Gym",
    "Use of garden": "Use of garden",
    "Barbecue area": "Barbecue area",
    "Lifting platform": "Lifting platform",
    "Children playground": "Children playground",
    "Crane": "Crane",
    "Ramp": "Ramp",
    "Wheelchair accessible": "Wheelchair accessible",
    "Sauna": "Sauna",
    "Lake access": "Lake access",
    "Suitable for seniors": "Suitable for seniors",
    "Security camera": "Security camera",
    "Sports facilities": "Sports facilities",
    "Swimming pool": "Swimming pool",
    "Close to subway": "Close to subway",
    "changingRooms": "changing rooms",
    "Underground car park": "Underground car park",
    "Units reception": "Units reception",
    "Washing drying room": "Washing drying room",
    "Wine cellar": "Wine cellar",
    "Wellness Area": "Wellness Area",
    "Jacuzzi": "Jacuzzi",
    "Conservatory": "Conservatory",
    "Dachform / Oblik krova": "Roof shape",
    "Flat roof": "Flat roof",
    "Half hip roof": "Half hip roof",
    "Mansard roof": "Mansard roof",
    "Monopitch roof": "Monopitch roof",
    "Pyramid roof": "Pyramid roof",
    "Gable roof": "Gable roof",
    "Fahrstuhl / Lift": "Elevator",
    "Freight elevator": "Freight elevator",
    "Elevator": "Elevator",
    "Belüftung / Ventilacija": "Ventilation",
    "Fan coil unit": "Fan coil unit",
    "Air conditioner": "Air conditioner",
    "Controlled ventilation": "Controlled ventilation",
    "Cooling": "Cooling",
    "Mechanical ventialtion": "Mechanical ventialtion",
    "Balkon / Balkon": "Balcony",
    "North balcony": "North balcony",
    "North east balcony": "North east balcony",
    "North west balcony": "North west balcony",
    "East balcony": "East balcony",
    "South east balcony": "South east balcony",
    "South west balcony": "South west balcony",
    "West balcony": "West balcony",
    "Beleuchtung / Osvetljenje": "Lighting",
    "Suspended mirror louvre lights": "Suspended mirror louvre lights",
    "Lighting suitable for workplace": "Lighting suitable for workplace",
    "Ceiling lights": "Ceiling lights",
    "Floor lamps": "Floor lamps",
    "WCs / Toaleti": "Toilets",
    "Guest toilet": "Guest toilet",
    "Separate toilets": "Separate toilets",
    "Urinal": "Urinal",
    "Toilet": "Toilet",
    "Gastronomie / Gastronomija": "Gastronomie",
    "On site bar": "On site bar",
    "On site hotel restaurant": "On site hotel restaurant",
    "Brewery connection": "Brewery connection",
    "Guest terrace": "Guest terrace",
    "Canteen or cafeteria": "Canteen or cafeteria",
    "Services / Usluge": "Services",
    "Serviced apartments": "Serviced apartments",
    "Catering": "Catering",
    "Shopping service": "Shopping service",
    "Cleaning service": "Cleaning service",
    "Guards service": "Guards service",
    "Expansion stage / *": "Expansion stage",
    "Bare bone house": "Bare bone house",
    "Kit house": "Kit house",
    "Key in hand with baseplate": "Key in hand with baseplate",
    "Key in hand with cellar": "Key in hand with cellar",
    "Key in hand without baseplate": "Key in hand without baseplate",
    "Energietyp / Tip energije": "Energy type",
    "House efficiency 40": "House efficiency 40",
    "House efficiency 55": "House efficiency 55",
    "House efficiency 60": "House efficiency 60",
    "House efficiency 70": "House efficiency 70",
    "Minergie construction": "Minergie construction",
    "Minergie certified": "Minergie certified",
    "New building standard": "New building standard",
    "Low energy house": "Low energy house",
    "Passive house": "Passive house",
    "Räume / Spaces": "Spaces",
    "Double doors": "Double doors",
    "Rooms can be changed": "Rooms can be changed",
    "Soundproof panels": "Soundproof panels",
    "Plaster": "Plaster",
    "Plaster board partitions": "Plaster board partitions",
    "Glass partitions": "Glass partitions",
    "Student friendly apartment": "Student friendly apartment",
    "Elektrik / Električna energija": "Electricity",
    "Floor boxes": "Floor boxes",
    "DVEDP cabling": "DVEDP cabling",
    "Fiber optic connection": "Fiber optic connection",
    "IT cabling Cat 5": "IT cabling Cat 5",
    "IT cabling Cat 6": "IT cabling Cat 6",
    "IT cabling Cat 7": "IT cabling Cat 7",
    "Cable ducts along parapet": "Cable ducts along parapet",
    "Cable ducts along plaster": "Cable ducts along plaster",
    "Flush mounted cable ducts": "Flush mounted cable ducts",
    "Bad / Kupatilo": "Bathroom",
    "Bathroom with window": "Bathroom with window",
    "Bathroom with toilet": "Bathroom with toilet",
    "Bathtub": "Bathtub",
    "Bidet": "Bidet",
    "Shower": "Shower",
    "Stellplatzart / Vrsta parking mesta": "Parking type",
    "Carport": "Carport",
    "Private garage": "Private garage",
    "Shared garage": "Shared garage",
    "Parking spot": "Parking spot",
    "Duplex garage": "Duplex garage",
    "Urkunden / Sertifikati": "Certificates",
    "Building permit": "Building permit",
    "Deed of ownership": "Deed of ownership",
    "Approval decision": "Approval decision",
    "Richard Jones has purchased a blue t-shirt for": "Richard Jones has purchased a blue t-shirt for",
    "Your request for withdrawal of": "Your request for withdrawal of",
    "Jane Davis has posted a": "Jane Davis has posted a",
    "new question": "new question",
    "about your product": "about your product",
    "Claire Smith has upvoted your store along with a": "Claire Smith has upvoted your store along with a",
    "comment": "comment",
    "New": "New",
    "Days 01-45": "Days 01-45",
    "Days 46-90": "Days 46-90",
    "Days 91-180": "Days 91-180",
    "Days >180": "Days >180",
    "My First dataset": "My First dataset",
    "My Second dataset": "My Second dataset",
    "My Third dataset": "My Third dataset",
    "Incoming phone call": "Incoming phone call",
    "Outgoing phone call": "Outgoing phone call",
    "Incoming mail": "Incoming mail",
    "Outgoing mail": "Outgoing mail",
    "Viewing scheduled": "Viewing scheduled",
    "Status changed": "Status changed",
    "Not implemented": "Not implemented",
    "UNEXPECTED ERROR": "UNEXPECTED ERROR",
    "Filter": "Filter",
    "Login": "Login",
    "Already have an account": "Already have an account",
    "Full Name": "Full Name",
    "Email": "Email",
    "A problem": "A problem",
    "Click here": "Click here",
    "and let us help you": "and let us help you",
    "Access to your": "Access to your",
    "Diamond": "Diamond",
    "Account": "Account",
    "SAVE": "SAVE",
    "Invalid badge type!": "Invalid badge type!",
    "Interessent communication": "Interested communication",
    "Interessent Communication": "Interested Communication",
    "PR": "PR",
    "Versand": "Shipment",
    "Anzeigen": "Show",
    "Besichtigung": "Sightseeing",
    "Termin bestat": "Confirm appointment",
    "Schein Feedback": "Mock feedback",
    "Telefon Aktiv": "Phone Active",
    "Telefon Passiv": "Phone Passive",
    "Telefon NE": "Phone NE",
    "Mail Aktiv": "Mail Active",
    "Mail Passiv": "Mail Passive",
    "RR": "RR",
    "RR Aktiv": "RR Active",
    "RR Passiv": "RR Passive",
    "Meine aktiven interesenten": "My active prospects",
    "interessent communication": "interessent communication",
    "single view": "single view",
    "In-Bearbeitung": "In-progress",
    "Interesse": "Interest",
    "Kaufinteresse": "Interest-in-buying",
    "Kaufabsicht": "Purchase-intent",
    "Verkauf-GHome": "Sale",
    "Alle-Interesnente": "All-interested-parties",
    "ID Interesent": "Prospect ID",
    "E-mail": "E-mail",
    "Objekat ID": "Object ID",
    "Podaci o nekretnini": "Real estate data",
    "Datum upita": "Query date",
    "Posl. promene": "Last changes",
    "Bes. Termin": "Bes. Termin",
    "Kaufverhandlung": "Purchase-negotiation",
    "Meine Statistik": "My Statistics",
    "has been initiated": "has been initiated",
    "Broj FD termina": "Number of FD appointments",
    "od toga preporuke": "of which recommendations",
    "Broj ugovora sa prodavcem": "Number of contracts with the seller",
    "od toga po preporuci": "of which by recommendation",
    "FD pozitivni %": "FD positive %",
    "FD u postupku = Statusi NEU, WV, Bestatigt, On hold": "FD in the process = Status New, Confirmed, On hold",
    "FD u postupku %": "FD in the process %",
    "FD otkazani": "FD cancelled",
    "Broj objekata": "Number of objects",
    "od toga": "of which",
    "sa provizijom": "with commission",
    "Broj objekata prema tipu ugovora": "Number of objects by type of contract",
    "Standardni ugovor": "Standard contract",
    "Ekskluzivni ugovor": "Exclusive contract",
    "Usmeni ugovor": "Verbal contract",
    "Broj servis termina": "Number of service term",
    "Tekuci mesec": "Current month",
    "Od pocetka godine do danas": "From the beginning of the year until today",
    "Broj interesenata inkl Matching": "Number of interested inkl Matching",
    "od toga Matching interesenti": "of which Matching interested",
    "Broj termina sa interesentima": "Number of appointments with interested",
    "Broj termina u odnosu na broj upita": "Number of appointments in relation to the number of inquiries",
    "Ukupan broj svih termina": "Total number of all appointments",
    "Broj prodatkih nekretnina": "Number of real estate sales",
    "Vrednost prodatih nekretnina": "Value of real estate sold",
    "Provizija ukupno": "Commission total",
    "FD": "FD",
    "Month": "Month",
    "Objekte": "Object",
    "od pocetka godine do": "from the beginning of the year to",
    "od pocetka godine do danas": "from the beginning of the year to the present day",
    "Umsatz": "Turnover",
    "Aufschaltungen": "Intrusions",
    "Kaufanbote": "Purchase offers",
    "Ineressentenanfragen": "Prospect Inquiries",
    "Besichtigungsquote": "Attendance rate",
    "FD Anzahl": "FD number",
    "FD Quote": "FD rate",
    "Objektbestand": "Property inventory",
    "Provisionseingange": "Commission receipts",
    "Kundigungen": "Terminations",
    "Kundigungsquote": "Cancellation rate",
    "Land AT": "Land AT",
    "Erstellt am": "Created on",
    "Lorem": "Lorem",
    "Land De": "Land De",
    "Land GB": "Land GB",
    "Clear": "Clear",
    "Nur GH Web Site": "Only GH Website",
    "Nur Immo Portale": "Only Immo Portal",
    "God. izgradnje": "Construction year",
    "objekat kreiran": "object created",
    "broj dana u prodaji": "number of days on sale",
    "broj redukcija cene": "number of price reductions",
    "ukupan iznos redukcija cene": "total amount price reductions",
    "poslednja promena u objektu": "last change in the building",
    "poslednja promena cene": "last price change",
    "poslednja promena oglasa": "last ad change",
    "datum poslednjeg sastanka": "date of last meeting",
    "Naslovna Slika": "Cover photo",
    "Opis Interneta": "Internet description",
    "Kompletna Adresa": "Complete Address",
    "Plan Stana": "Apartment plan",
    "Vazi samo za AT Verziju": "Applies to AT Version only",
    "Kupoprodajni Ugovor (Vazi samo za AT Verziju)": "Purchase Agreement (Applies to AT Version only)",
    "Energ. Certif.": "Energy certificate",
    "Akontacija Zajednickih": "Advance payment",
    "Obracun Zajednickih troskova": "Calculation of common costs",
    "Protokol Sastanka": "Protocol of the meeting",
    "Resenje O Nacinu Obracuna": "Decision on the Method of Calculation",
    "Polje Za Interne Informacije": "Internal Information Field",
    "Broj Tel.": "Phone number",
    "Team Ime, Broj Tel. E-mail, Adresa agenta": "Team, name, phone number, e-mail, agent address",
    "broj Tel.": "phone number",
    "Mail Adresa Agenta": "Mail Address of the Agent",
    "sklopljen ugovor": "concluded contract",
    "Potvdjen": "Confirmed",
    "Email anbieten": "Email offer",
    "Suchprofil erstellen": "Create profile",
    "Aktivität erfassen": "Capture activity",
    "Agent": "Agent",
    "Allgemein (opšti podaci)": "General",
    "Kategorien / kategorija": "Category",
    "Art (vrsta, tip)": "Type",
    "Betreuer id / Nadležno lice id": "Supervisor ID",
    "Adresse": "Address",
    "Straße / ulica": "Street",
    "Land / država": "Country",
    "Personendaten/lični podaci": "Personal data",
    "Anrede": "Salutation",
    "Titel / Präfix": "Title",
    "Suffix": "Suffix",
    "Vorname / Ime": "Name",
    "Nachname / prezime": "Surname",
    "Geburtsdatum / datum rođenja": "Date of birth",
    "Handy / mobilni telefon": "Mobile",
    "Firma / kompanija": "Company",
    "Position / položaj": "Position",
    "poštanski broj": "Post code",
    "New First Date": "New First Date",
    "First date - dodela agentu": "First date - assignment to agent",
    "Retail": "Retail",
    "Hospitality": "Hospitality",
    "Plot": "Plot",
    "Industry": "Industry",
    "Agriculture and forestry": "Agriculture and forestry",
    "Park": "Park",
    "CC ID:": "CC ID:",
    "Cena:": "Price:",
    "Broj soba :": "Number of rooms:",
    "Datum :": "Date:",
    "Dodeljeno putem:": "Assigned by:",
    "Lorem ipsum :": "Lorem ipsum:",
    "Termin :": "Term:",
    "Nezainteresovanost :": "Disinterest:",
    "Markel": "Mark",
    "Anzahl": "Number",
    "Save": "Save",
    "Stambena povrsina :": "Living area:",
    "Sun": "Sun",
    "Mon": "Mon",
    "Tue": "Tue",
    "Wed": "Wed",
    "Thu": "Thu",
    "Fri": "Fri",
    "Sat": "Sat",
    "Set as cover": "Set as cover",
    "Kaufanbot": "Purchase offer",
    "NEU": "NEW",
    "AKTIV": "ACTIVE",
    "Update": "Update",
    "Austria": "Austria",
    "Germany": "Germany",
    "Switzerland": "Switzerland",
    "Vienna": "Vienna",
    "Lower Austria": "Lower Austria",
    "Upper Austria": "Upper Austria",
    "Tyrol": "Tyrol",
    "Carinthia": "Carinthia",
    "Salzburg": "Salzburg",
    "Vorarlberg": "Vorarlberg",
    "Burgenland": "Burgenland",
    "Baden-Württemberg": "Baden-Württemberg",
    "Bavaria": "Bavaria",
    "Berlin": "Berlin",
    "Hesse": "Hesse",
    "Lower Saxony": "Lower Saxony",
    "Mecklenburg-Western Pomerania": "Mecklenburg-Western Pomerania",
    "North Rhine-Westphalia": "North Rhine-Westphalia",
    "Rhineland-Palatinate": "Rhineland-Palatinate",
    "Saxony-Anhalt": "Saxony-Anhalt",
    "Schleswig-Holstein": "Schleswig-Holstein",
    "Thuringia": "Thuringia",
    "Zürich": "Zürich",
    "Bern": "Bern",
    "Luzern": "Luzern",
    "Uri": "Uri",
    "Schwyz": "Schwyz",
    "Unterwalden": "Unterwalden",
    "Glarus": "Glarus",
    "Zug": "Zug",
    "Freiburg": "Freiburg",
    "Solothurn": "Solothurn",
    "Schaffhausen": "Schaffhausen",
    "Appenzell": "Appenzell",
    "Sankt Gallen": "Sankt Gallen",
    "Graubünden": "Grisons",
    "Aargau": "Aargau",
    "Thurgau": "Thurgau",
    "Ticino": "Ticino",
    "Vaud": "Vaud",
    "Valais": "Valais",
    "Neuchâtel": "Neuchatel",
    "Genève": "Geneve",
    "Jura": "Jura",
    "Upload": "Upload",
    "Property has been saved successfully": "Property has been saved successfully",
    "fill all required fields!": "fill all required fields!",
    "internal server error": "internal server error",
    "Property has been updated successfully": "Property has been updated successfully",
    "The event has been successfully edited": "The event has been successfully edited",
    "Importdatum": "Importdatum",
    "Inquiry has been saved successfully": "Inquiry has been saved successfully",
    "Rent costs": "Rent costs",
    "Purchase": "Purchase",
    "Rent": "Rent",
    "Lease": "Lease",
    "Monthly": "Monthly",
    "Bruto": "Bruto",
    "Total for rent": "Total for rent",
    "Without heating cost": "Without heating cost",
    "Inquiry status": "Status"
};
import React, { useState, useEffect } from 'react';
import './FirstDateFormModal.scss';
import CustomObjectInput from '../objects/customObjectInput/CustomObjectInput';
import { useForm } from 'react-hook-form';
import { contactsService } from '../../services/ContactsService';
import { propertiesService } from '../../services/PropertiesService';
import { ProgressSpinner } from 'primereact/progressspinner';
import { useApplicationContext } from '../../application/ApplicationContext';
import dateFormat from 'dateformat';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { useTranslation } from 'react-i18next';
import { IFirstDateFormModal } from '../../models/FirstDateFormModal';
import moment from 'moment';

const FirstDateFormModal = ({ showModal, toast }) => {
    const [properties, setProperties] = useState([]);
    const [spinner, setSpinner] = useState(false);
    const { appState, addNewFirstDate, addNewEvents } = useApplicationContext();
    const { t } = useTranslation();

    const [firstDateFormModalObject, setFirstDateFormModalObject] =
        useState<IFirstDateFormModal>({
            status: '',
            acquisition: false,
            appointmentDate: new Date(),
            importantDate: null,
            propertyId: null,
            sellerId: null,
        });

    useEffect(() => {
        fetchProperties().then((result) =>
            setProperties(result.filter((x) => !x.firstDate)),
        );
    }, []);

    useEffect(() => {
        properties.map((property) => {
            if (property.rootId === firstDateFormModalObject.propertyId) {
                setFirstDateFormModalObject({
                    ...firstDateFormModalObject,
                    sellerId: property?.seller?.id,
                });
            }
        });
    }, [firstDateFormModalObject.propertyId]);

    const handleResetModal = () => {
        setFirstDateFormModalObject({
            status: '',
            acquisition: false,
            appointmentDate: null,
            importantDate: null,
            propertyId: null,
            sellerId: null,
        });
    };

    const handleFirstDateForm = (prop, value) => {
        setFirstDateFormModalObject((FirstDateFormModalObject) => ({
            ...FirstDateFormModalObject,
            [prop]: value,
        }));
    };

    const fetchProperties = async () => {
        const response = await propertiesService.getMany();
        return response.data;
    };

    const formatPropertyText = (property) => {
        let result = '';

        result += property.rootId;
        result += ', ';
        result += property.place;

        if (property.street) {
            result += ' ';
            result += property.street;
        }

        if (property.houseNumber) {
            result += ' ';
            result += property.houseNumber;
        }

        if (property.seller) {
            result += ', ';
            result += property.seller.firstName;
            result += ' ';
            result += property.seller.lastName;
        }

        return result;
    };

    const propertiesOptions = () => {
        const allProperties = properties.map((property) => ({
            label: formatPropertyText(property),
            value: property.rootId,
        }));
        return allProperties.sort((a: any, b: any) => {
            const aId = parseInt(a.label.split(',')[0]);
            const bId = parseInt(b.label.split(',')[0]);

            return aId - bId;
        });
    };

    const {
        control,
        formState: { errors, isValid },
        register,
    } = useForm({
        mode: 'all',
    });

    const addCalendarEvent = () => {
        return addNewEvents(
            'FD termin',
            '',
            moment(firstDateFormModalObject.appointmentDate).format(
                'YYYY-MM-DDTkk:mm:ss',
            ),
            `${moment(firstDateFormModalObject.appointmentDate).format(
                'YYYY-MM-DD',
            )}T${moment(firstDateFormModalObject.appointmentDate, 'HH:mm')
                .add(1, 'hours')
                .format('HH:mm')}`,
            'First date',
            Number(appState.currentUser.id),
            firstDateFormModalObject?.sellerId, /// sellerID
            firstDateFormModalObject.propertyId,
        );
    };

    const handleButtonCheck = () => {
        setSpinner(true);
        addNewFirstDate(
            firstDateFormModalObject.status === ''
                ? 'Neu bold'
                : firstDateFormModalObject.status,
            firstDateFormModalObject.acquisition,
            moment(firstDateFormModalObject.appointmentDate).format(),
            dateFormat(firstDateFormModalObject.importantDate, 'yyyy-mm-dd') +
                'T10:00:00',
            firstDateFormModalObject.propertyId,
            firstDateFormModalObject.sellerId,
        ).then(() => {
            setSpinner(false);
            //            addCalendarEvent();
            showModal();
            handleResetModal();
            toast.current.show({
                severity: 'success',
                detail: t(`Added a new first date`),
                life: 3000,
            });
        });
    };

    const footer = (
        <div className="footer-buttons">
            <Button
                label={t('Save')}
                icon="pi pi-check"
                disabled={!isValid}
                onClick={handleButtonCheck}
            />
            {/* <Button
                label={t('Refresh')}
                className="p-button-secondary"
                icon="pi pi-refresh"
                onClick={handleResetModal}
            /> */}
        </div>
    );

    return (
        <>
            <Dialog
                header={t('Neues First Date')}
                visible={true}
                className="first-date-modal"
                blockScroll
                footer={spinner ? null : footer}
                draggable={false}
                onHide={showModal}
            >
                {spinner ? (
                    <div className="center-spinner column-span-2">
                        <ProgressSpinner
                            strokeWidth="4"
                            animationDuration="1.5s"
                        />
                    </div>
                ) : (
                    <>
                        <div className="modal-input column-span-2">
                            <div className="dropdown-checkbox">
                                <div className={'dropdown'}>
                                    <CustomObjectInput
                                        type="calendar"
                                        classes={[
                                            'valid-from-input',
                                            'generic-input',
                                        ]}
                                        id="Date-and-time-from-input"
                                        label={t('Appointment date')}
                                        name="appointmentDate"
                                        register={register}
                                        timeShow={true}
                                        stateField={
                                            firstDateFormModalObject.appointmentDate !==
                                            null
                                                ? firstDateFormModalObject.appointmentDate
                                                : new Date()
                                        }
                                        setStateField={handleFirstDateForm}
                                        errors={errors}
                                        control={control}
                                        // rules={{ required: t('This is required') }}
                                    />
                                </div>
                                <div className={'checkbox'}>
                                    <CustomObjectInput
                                        type="checkbox"
                                        classes={['country-input']}
                                        id="id-input"
                                        label={t('Acquisition')}
                                        name="acquisition"
                                        stateField={
                                            firstDateFormModalObject.acquisition
                                        }
                                        setStateField={handleFirstDateForm}
                                        errors={errors}
                                        control={control}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="modal-input column-span-2">
                            <CustomObjectInput
                                type="dropdown"
                                classes={['country-input']}
                                id="Object-input"
                                label={t('Property')}
                                name="propertyId"
                                register={register}
                                filter={true}
                                dropdownOptions={propertiesOptions()}
                                stateField={firstDateFormModalObject.propertyId}
                                setStateField={handleFirstDateForm}
                                errors={errors}
                                control={control}
                                rules={{ required: t('This is required') }}
                            />
                        </div>{' '}
                    </>
                )}
            </Dialog>
        </>
    );
};

export default FirstDateFormModal;

import {
    constructionType,
    roofShape,
    expansionStage,
    floorType,
    elevator,
    heating,
    heatingType,
    ventilation,
    energyType,
    view,
    balcony,
    space,
    window,
    lighting,
    electricEnergy,
    tv,
    toilet,
    bathroom,
    kitchen,
    gastronomy,
    parkingSpaceType,
    extras,
    services,
    certificates,
    development,
    noiseLevel,
    conditionAssesment,
    situationAssesment,
    furnishing,
    formOfOwnership,
    houseCondition,
    status,
    type,
    energyClass,
    agreementType,
    mediationOrderType,
    floor,
    publishTheAddressTemplate,
    floorPlan,
    propertyType,
    EnergyCertificateType,
    energyCertificateYear,
    //Currency
} from '../constants/ObjectConsts';

import { EnergySources, HeatingType } from '../models/ObjectBaseDataFormModal';

export interface FileMeta {
    file: any;
    isCover: any;
}

export enum objectStatus {
    IN_VORBEREITUNG = 'In Vorbereitung',
    AKTIV = 'Aktiv',
    INACTIVE = 'Inactive',
    VERKAUFGH = 'VerkaufGH',
    ABGESCHLOSSEN = 'Abgeschlossen',
}

export interface RealEstateObject {
    //object base data form
    id?: number;
    rootId: number;
    // countryId: number;
    stateId: number;
    readyForExport: boolean;
    propertyTypeId: number;
    propertySubtypeId: number;
    existingContact: boolean;
    status?: objectStatus;
    sellerId?: number;
    seller?: {
        firstName: string;
        lastName: string;
        emails: string[];
        assigneeId: number;
    };
    firstDate?: any;
    latitude?: number;
    longitude?: number;
    acquisitionType?: any;
    street?: string;
    houseNumber?: string;
    entrance?: string;
    door?: string;
    plz?: string;
    place?: string;
    sideOfTheWorld?: string;
    proximity?: string;
    keyInfo?: string;
    publishTheAddressTemplate?: publishTheAddressTemplate;
    floor?: floor;
    floorDescription?: string;
    floorNumber?: number;
    numberOfAtticFloors?: number;
    hasMezzanine?: boolean;
    energyCertificateAvailable?: boolean;
    hwb?: number;
    class?: energyClass;
    fgee?: string;
    fgeeClass?: energyClass;
    energyCertificateDateOfExpiry?: Date;
    energyCertificateYear?: string;
    automaticDisclaimer?: boolean;
    energyCertificateValidFrom?: Date;
    energyCertificateType?: EnergyCertificateType;
    requirementCertificate?: string;
    consumptionCertificate?: string;
    heatingType?: HeatingType;
    energySources?: EnergySources;
    employeeId: number;
    barrierFree?: boolean;
    note?: string;
    labelling?: string;
    propertyType?: propertyType;
    isBuildingPlot?: boolean;
    floorPlan?: floorPlan;
    alsoVacationProperty?: boolean;
    typeOfUseLiving?: boolean;
    typeOfUseBusiness?: boolean;
    typeOfUseInvestment?: boolean;
    agreement?: agreementType;
    mediationOrderType?: mediationOrderType;
    validFrom?: Date;
    dateOfExpiry?: Date;
    depositNumber?: string;
    cadastreNumber?: string;
    lotNumber?: string;
    landRegisterSheet?: string;
    district?: string;
    corridor?: string;
    parcel?: string;
    maximumRentalPeriodYears?: number;
    maximumRentalPeriodMonths?: number;
    unlimitedRentalPeriod?: boolean;
    waiverOfTerminationYears?: number;
    waiverOfTerminationMonths?: number;
    contact1?: string; //no such field in DTO
    contact2?: string; //no such field in DTO
    equity1?: number; //no such field in DTO
    equity2?: number; //no such field in DTO
    toRemove1?: boolean; //no such field in DTO
    toRemove2?: boolean; //no such field in DTO
    publishPrices?: boolean; //no such field in DTO
    parkingOptional?: boolean; //no such field in DTO
    garageOptional?: boolean; //no such field in DTO
    operationalCostOptional?: boolean; //no such field in DTO
    elevatorCostOptional?: boolean; //no such field in DTO
    pricePerSquare: boolean; //no such field in DTO
    priceCalculation: string; //no such field in DTO
    interestedPartyInput: number; //no such field in DTO
    ust: number; //no such field in DTO
    output1: number; //no such field in DTO
    autofill: boolean; //no such field in DTO
    surrenderInput: number; //no such field in DTO
    vat1: number; //no such field in DTO
    output2: number; //no such field in DTO
    sourceLink?: string;

    //object prices form
    price: RealEstatePriceObject;
    //object surface form
    surface: RealEstateSurfaceObject;
    //object text form
    text: RealEstateTextObject;
    //furnishing form
    furnishing: PropertyFurnishingDto;
    //other form
    other: RealEstateOtherObject;
    //internal form
    internal: PropertyInternalDto;

    exportState: { [key: string]: boolean };

    files: {
        images: FileMeta[];
        plans: FileMeta[];
        videos: FileMeta[];
        documents: FileMeta[];
        view360: FileMeta[];
    };
}

export interface RealEstatePriceObject {
    priceType: any;
    marketingTypePurchase?: boolean;
    marketingTypeRental?: boolean;
    marketingTypeLeasehold?: boolean;
    marketingTypeLease?: boolean;
    //currency?: Currency;
    netPurchasePrice?: number;
    purchasePriceTax?: number;
    grossPurchasePrice?: number;
    netParkingCost?: number;
    parkingCostTax?: number;
    grossParkingCost?: number;
    netGarageCost?: number;
    garageCostTax?: number;
    grossGarageCost?: number;
    netOperatingCost?: number;
    operatingCostTax?: number;
    grossOperatingCost?: number;
    netExtraCost?: number;
    extraCostTax?: number;
    netHeatingCost?: number;
    heatingCostTax?: number;
    grossHeatingCost?: number;
    grossExtraCost?: number;
    netExtraPrice?: number;
    extraPriceTax?: number;
    grossExtraPrice?: number;
    netWarmWaterCost?: number;
    warmWaterCostTax?: number;
    grossWarmWaterCost?: number;
    netCoolingCost?: number;
    coolingCostTax?: number;
    grossCoolingCost?: number;
    netMaintenanceCost?: number;
    maintenanceCostTax?: number;
    grossMaintenanceCost?: number;
    netOtherCost?: number;
    otherCostTax?: number;
    grossOtherCost?: number;
    netElevatorCost?: number;
    elevatorCostTax?: number;
    grossElevatorCost?: number;
    totalCost?: number;
    netRentCost?: number;
    rentCostTax?: number;
    grossRentCost?: number;
    purchasePricePerSquareMeter?: number;
    operatingCostsPerSquareMeter: number;

    // PROVISION
    interessentProvision?: number;
    interessentProvisionType?: string;
    interessentProvisionTax?: number;
    derstellung?: string;
    derstellungAutomatic?: boolean;
    verkauferProvision?: number;
    verkauferProvisionType?: string;
    verkauferProvisionTax?: number;
    ergebnis?: string;

    publishPrices: boolean;
}

export interface RealEstateSurfaceObject {
    plotArea?: number;
    livingArea?: number;
    usableArea?: number;
    buildableArea?: number;
    totalArea?: number;
    officeArea?: number;
    salesArea?: number;
    storageArea?: number;
    hallheight?: number;
    freeArea?: number;
    temporaryArea?: number;
    weightedArea?: number;
    atticArea?: number;
    unfinishedAttic?: boolean;
    unfinishedAtticArea?: number;
    numberOfRooms?: number;
    hasHalfRoom?: boolean;
    roomHeight?: number;
    numberOfGardens?: number;
    gardenArea?: number;
    numberOfBasements?: number;
    basementArea?: number;
    numberOfBalconies?: number;
    balconyArea?: number;
    numberOfTerraces?: number;
    terraceArea?: number;
    numberOfLoggias?: number;
    loggiasArea?: number;
    numberOfGarages?: number;
    garageArea?: number;
    numberOfParkingSpaces?: number;
    parkingArea?: number;
    numberOfBedrooms?: number;
    numberOfLivingRooms?: number;
    numberOfToilets?: number;
    numberOfBathrooms?: number;
    numberOfStorageRooms?: number;
}

export interface RealEstateTextObject {
    title: string;
    descriptionForTheInternet: string;
    location: string;
    shortDescription: string;
    publicTransportDescription: string;
    shoppingDescription: string;
    schoolDescription: string;
    description: string;
    otherInfoForTheInternet: string;
    text1: string;
    text2: string;
    text3: string;
}

export interface RealEstateOtherObject {
    type?: type;
    positionInBuildingLeft?: boolean;
    positionInBuildingRight?: boolean;
    positionInBuildingFront?: boolean;
    positionInBuildingBack?: boolean;
    status?: status;
    houseCondition?: houseCondition;
    constructionYear?: number;
    lastRenovationYear?: number;
    lastGeneralRenovation?: number;
    formOfOwnership?: formOfOwnership;
    furnishing?: furnishing;
    relatable?: string;
    situationAssesment?: situationAssesment;
    conditionAssesment: conditionAssesment;
    noiseLevel?: noiseLevel;
    development?: development;
    keyInHand?: boolean;
    readyForCovering?: boolean;
}

export class PropertyDto {
    employeeId?: number;
    sellerId?: number;
    seller?: {
        id?: number;
        firstName?: string;
        lastName?: string;
        emails?: {
            email: string;
        }[];
        assigneeId?: number;
        role?: 'BUYER' | 'SELLER';
    };
    readyForExport: boolean;
    propertyTypeId: number;
    propertySubtypeId: number;
    // countryId: number;
    stateId: number;
    latitude?: number;
    longitude?: number;
    acquisitionType?: any;
    textId: number;
    furnishingId: number;
    entrance?: string;
    door?: string;
    sideOfTheWorld?: string;
    street?: string;
    houseNumber?: string;
    stairs?: string; //unused
    kind?: string; //unused
    plz?: string;
    place?: string;
    alignment?: string; //unused
    proximity?: string;
    keyInfo?: string;
    publishTheAddressTemplate?: publishTheAddressTemplate;
    exportStairsAndTops?: boolean; //unused
    labelling?: string;
    propertyType?: propertyType;
    objectSubtype?: string; //unused
    floorPlan?: floorPlan;
    barrierFree?: boolean;
    isBuildingPlot?: boolean;
    alsoVacationProperty?: boolean;
    typeOfUseLiving?: boolean;
    typeOfUseBusiness?: boolean;
    typeOfUseInvestment?: boolean;
    floor?: floor;
    floorDescription?: string;
    note?: string;
    floorNumber?: number;
    numberOfAtticFloors?: number;
    hasMezzanine?: boolean;
    mediationOrderType?: mediationOrderType;
    validFrom?: Date;
    agreement?: agreementType;
    dateOfExpiry?: Date;
    depositNumber?: string;
    inTheCadastre?: boolean; //unused
    cadastreNumber: string;
    lotNumber?: string;
    maximumRentalPeriodYears?: number;
    maximumRentalPeriodMonths?: number;
    unlimitedRentalPeriod?: boolean;
    waiverOfTerminationYears?: number;
    waiverOfTerminationMonths?: number;
    energyCertificateAvailable?: boolean;
    hwb?: number;
    class?: energyClass;
    fgee?: string;
    fgeeClass?: energyClass;
    energyCertificateDateOfExpiry?: Date;
    energyCertificateYear?: string;
    landRegisterSheet?: string;
    district?: string;
    corridor?: string;
    parcel?: string;
    automaticDisclaimer?: boolean;
    energyCertificateValidFrom?: Date;
    energyCertificateType?: EnergyCertificateType;
    requirementCertificate?: string;
    consumptionCertificate?: string;
    heatingType?: HeatingType;
    energySources?: EnergySources;
    sourceLink?: string;
    surface: PropertySurfaceDto;
    price: PropertyPriceDto;
    other: PropertyOtherDto;
    furnishing: PropertyFurnishingDto;
    text: PropertyTextDto;
    internal: PropertyInternalDto;

    exportState: { [key: string]: boolean };

    files: {
        images: { id: number; visibility: number; isCover: boolean }[];
        plans: { id: number; visibility: number }[];
        videos: { id: number; visibility: number }[];
        documents: { id: number; visibility: number }[];
        view360: { id: number; visibility: number }[];
    };
}

export class PropertySurfaceDto {
    plotArea?: number;
    livingArea?: number;
    usableArea?: number;
    buildableArea?: number;
    salesArea?: number;
    storageArea?: number;
    hallheight?: number;
    freeArea?: number;
    temporaryArea?: number;
    officeArea?: number;
    totalArea?: number;
    weightedArea?: number;
    atticArea?: number;
    unfinishedAttic?: boolean;
    unfinishedAtticArea?: number;
    numberOfRooms?: number;
    roomHeight?: number;
    hasHalfRoom?: boolean;
    numberOfGardens?: number;
    gardenArea?: number;
    numberOfBasements?: number;
    basementArea?: number;
    numberOfBalconies?: number;
    balconyArea?: number;
    numberOfTerraces?: number;
    terraceArea?: number;
    numberOfLoggias?: number;
    loggiasArea?: number;
    numberOfGarages?: number;
    garageArea?: number;
    numberOfParkingSpaces?: number;
    parkingArea?: number;
    numberOfBedrooms?: number;
    numberOfLivingRooms?: number;
    numberOfToilets?: number;
    numberOfBathrooms?: number;
    numberOfStorageRooms?: number;
}

export class PropertyPriceDto {
    priceType: any;
    marketingTypePurchase?: boolean;
    marketingTypeRental?: boolean;
    marketingTypeLeasehold?: boolean;
    marketingTypeLease?: boolean;
    //currency?: Currency;
    netPurchasePrice?: number;
    purchasePriceTax?: number;
    grossPurchasePrice?: number;
    netParkingCost?: number;
    parkingCostTax?: number;
    grossParkingCost?: number;
    netGarageCost?: number;
    garageCostTax?: number;
    grossGarageCost?: number;
    netHeatingCost?: number;
    heatingCostTax?: number;
    grossHeatingCost?: number;
    grossExtraCost?: number;
    netWarmWaterCost?: number;
    warmWaterCostTax?: number;
    grossWarmWaterCost?: number;
    netCoolingCost?: number;
    coolingCostTax?: number;
    grossCoolingCost?: number;
    netMaintenanceCost?: number;
    maintenanceCostTax?: number;
    grossMaintenanceCost?: number;
    netElevatorCost?: number;
    elevatorCostTax?: number;
    grossElevatorCost?: number;
    netOperatingCost?: number;
    operatingCostTax?: number;
    grossOperatingCost?: number;
    netExtraPrice?: number;
    netExtraCost?: number;
    extraCostTax?: number;
    extraPriceTax?: number;
    grossExtraPrice?: number;
    netOtherCost?: number;
    otherCostTax?: number;
    grossOtherCost?: number;
    totalCost?: number;
    netRentCost?: number;
    rentCostTax?: number;
    grossRentCost?: number;
    purchasePricePerSquareMeter?: number;
    operatingCostsPerSquareMeter?: number;
    publishPrices?: boolean;
}

export class PropertyOtherDto {
    type?: type;
    positionInBuildingLeft?: boolean;
    positionInBuildingRight?: boolean;
    positionInBuildingFront?: boolean;
    positionInBuildingBack?: boolean;
    status?: status;
    houseCondition?: houseCondition;
    constructionYear?: number;
    lastRenovationYear?: number;
    lastGeneralRenovation?: number;
    formOfOwnership?: formOfOwnership;
    furnishing?: furnishing;
    relatable?: string;
    situationAssesment?: situationAssesment;
    conditionAssesment?: conditionAssesment;
    noiseLevel?: noiseLevel;
    development?: development;
    keyInHand?: boolean;
    readyForCovering?: boolean;
}

export class PropertyInternalDto {
    saleDate: Date;
    sellerId: number;
    offerPrice: number;
    contractPrice: number;
    absoluteSellerCommissionAmmount: number;
    invoicingDateSeller: Date;
    sellerCashInflowDate: Date;
    absoluteBuyerCommissionAmmount: number;
    invoicingDateBuyer: Date;
    buyerCashInflowDate: Date;
}

export class PropertyFurnishingDto {
    constructionType = constructionType;
    roofShape = roofShape;
    expansionStage = expansionStage;
    floor = floorType;
    elevator = elevator;
    heating = heating;
    heatingType = heatingType;
    ventilation = ventilation;
    energyType = energyType;
    view = view;
    balcony = balcony;
    space = space;
    window = window;
    lighting = lighting;
    electricEnergy = electricEnergy;
    tv = tv;
    toilet = toilet;
    bathroom = bathroom;
    kitchen = kitchen;
    gastronomy = gastronomy;
    parkingSpaceType = parkingSpaceType;
    extras = extras;
    services = services;
    certificates = certificates;
}

export class PropertyTextDto {
    title: string;
    descriptionForTheInternet: string;
    location: string;
    shortDescription: string;
    publicTransportDescription: string;
    shoppingDescription: string;
    schoolDescription: string;
    description: string;
    otherInfoForTheInternet: string;
    text1: string;
    text2: string;
    text3: string;
}

export enum AcquisitionType {
    ACQUISITION = 'Acquisition',
    NON_ACQUISITION = 'Non-aquisition',
}

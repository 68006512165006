import { Card } from 'primereact/card';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import React, { useEffect, useState } from 'react';
import CardHeader from '../../card/CardHeader';
import './ObjectMarketingForm.scss';
import { useTranslation } from 'react-i18next';
import { Button } from 'primereact/button';
import { exportService } from '../../../services/ExportService';
import { Checkbox } from 'primereact/checkbox';
import { Badge } from 'primereact/badge';
import moment from 'moment';

const ObjectMarketingForm = ({ realEstateObject, updateRealEstateObject }) => {
    const { t } = useTranslation();
    const rootId = realEstateObject.rootId;
    const [exports, setExports] = useState<any[]>([]);

    useEffect(() => {
        exportService
            .getExportsForProperty(realEstateObject.rootId)
            .then((response) => {
                setExports(response.data);
            });
    }, []);
    // }, [realEstateObject]);

    const renderActivateColumn = (rowData) => {
        return (
            <Checkbox
                inputId={`${rowData.providerKey}_select`}
                checked={realEstateObject.exportState[rowData.providerKey]}
                onChange={(e) => {
                    updateRealEstateObject(
                        `exports_${rowData.providerKey}`,
                        e.checked,
                    );
                }}
            />
        );
    };

    const renderPlatformColumn = (rowData) => {
        return (
            <>
                <div>
                    <strong>{rowData.provider.key}</strong>
                    <Badge
                        style={{ marginLeft: '5px', opacity: '.7' }}
                        className="gray-badge"
                        value={rowData.provider.type}
                        severity={'info'}
                    />
                </div>
                <div
                    style={{
                        opacity: '.5',
                        fontSize: '10px',
                        fontFamily: 'monospace',
                    }}
                >
                    {rowData.id}
                </div>
            </>
        );
    };

    const renderLimitsColumn = (rowData) => {
        return <>{rowData.provider.limit ?? '∞'}</>;
    };

    const renderLastExportColumn = (rowData) => {
        if (rowData.jobs.length <= 0) {
            return <div style={{ opacity: '.5' }}>Unavailable</div>;
        }

        const sortedJobs = rowData.jobs.sort((a, b) =>
            moment(b.createdAt).diff(moment(a.createdAt)),
        );
        const lastJob = sortedJobs[0];

        if (!lastJob.finishedAt) {
            return <div style={{ opacity: '.5' }}>Unavailable</div>;
        }

        return (
            <div>{moment(lastJob.finishedAt).format('DD.MM.YYYY HH:mm')}</div>
        );
    };

    const renderStatusColumn = (rowData) => {
        if (rowData.jobs.length <= 0) {
            return <div style={{ opacity: '.5' }}>Unavailable</div>;
        }

        const sortedJobs = rowData.jobs.sort((a, b) =>
            moment(b.createdAt).diff(moment(a.createdAt)),
        );
        const lastJob = sortedJobs[0];

        const severityMap = {
            success: 'success',
            fail: 'danger',
            pending: 'warning',
            cancel: 'secondary',
        };

        const textMap = {
            success: t(`Successful ${lastJob.action}`),
            fail: t(`Failed ${lastJob.action}`),
            pending: t(`Pending ${lastJob.action}`),
            cancel: t(`Canceled ${lastJob.action}`),
        };

        return (
            <Badge
                value={textMap[lastJob.status]}
                severity={severityMap[lastJob.status]}
            />
        );
    };

    const renderPreviewColumn = (rowData) => {
        if (rowData.status !== 'active') {
            return <div style={{ opacity: '.5' }}>Unavailable</div>;
        }

        return rowData.previewLink ? (
            <a href={rowData.previewLink} target="_blank" rel="noreferrer">
                Preview
            </a>
        ) : (
            <div style={{ opacity: '.5' }}>Unavailable</div>
        );
    };

    return (
        <Card
            className="object-marketing-wrapper no-padding"
            header={
                <CardHeader
                    title={t('Izvoz interfejsa (Exportschnittstellen)')}
                />
            }
        >
            <DataTable value={exports.sort()} className="p-datatable-lg">
                <Column body={renderActivateColumn} />
                <Column body={renderPlatformColumn} header={t('Platforma')} />
                <Column body={renderLimitsColumn} header={t('Limits')} />
                <Column
                    body={renderLastExportColumn}
                    header={t('Last export')}
                />
                <Column body={renderStatusColumn} header={t('Status')} />
                <Column body={renderPreviewColumn} header={t('Preview')} />
            </DataTable>
        </Card>
    );
};

export default ObjectMarketingForm;

import { TabMenu } from 'primereact/tabmenu';
import React, { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Redirect } from 'react-router-dom';
import ObjectBaseDataForm from '../../components/objects/objectBaseDataForm/ObjectBaseDataForm';
import ObjectDataAndLinksForm from '../../components/objects/objectDataAndLinksForm/ObjectDataAndLinksForm';
import ObjectFurnishingForm from '../../components/objects/objectFurnishingForm/ObjectFurnishingForm';
import ObjectMarketingForm from '../../components/objects/objectMarketingForm/ObjectMarketingForm';
import ObjectPricesForm from '../../components/objects/objectPricesForm/ObjectPricesForm';
import ObjectTextForm from '../../components/objects/objectTextForm/ObjectTextForm';
import { RealEstateObject } from '../../models/RealEstateObject';
import { userService } from '../../services/UsersService';
import { propertiesService } from '../../services/PropertiesService';
import { contactsService } from '../../services/ContactsService';
import './NewObject.scss';
import { ProgressSpinner } from 'primereact/progressspinner';
import ObjectInternForm from '../../components/objects/ObjectInternForm/ObjectInternForm';
import { useTranslation } from 'react-i18next';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import {
    constructionType,
    roofShape,
    expansionStage,
    floorType,
    elevator,
    heating,
    heatingType,
    ventilation,
    energyType,
    view,
    balcony,
    space,
    window,
    lighting,
    electricEnergy,
    tv,
    toilet,
    bathroom,
    kitchen,
    gastronomy,
    parkingSpaceType,
    extras,
    services,
    certificates,
    publishTheAddressTemplate,
    priceCalculationOptions,
    energyCertificateYear,
} from '../../constants/ObjectConsts';
import { useApplicationContext } from '../../application/ApplicationContext';
import { DomHandler } from 'primereact/utils';
import { useStateCallback } from '../../hooks/StateCallbackHook';
import { indexOf } from 'lodash';
import moment from 'moment';
import { Permissions } from '../../core/auth/Permissions';

export const NewObject = () => {
    //* Change for default tabmenu index
    //* Default should be 0;
    const [loading, setLoading] = useState(false);
    const [activeIndex, setActiveIndex] = useState<number>(0);
    const { t } = useTranslation();
    const { appState, setSingleViewInformation } = useApplicationContext();
    const [employeeList, setEmployeeList] = useState([]);
    const [contactList, setContactList] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [status, setStatus] = useState(0);
    const [propertyId, setPropertyId] = useState(0);
    const appContext = useApplicationContext();

    const [showMewContactModal, setShowMewContactModal] =
        useStateCallback(false);

    const [realEstateObject, setRealEstateObject] = useState<RealEstateObject>({
        id: null,
        rootId: null,
        // countryId: null,
        stateId: null,
        readyForExport: false,
        propertyTypeId: null,
        propertySubtypeId: null,
        existingContact: true,
        sellerId: null,
        seller: {
            firstName: '',
            lastName: '',
            emails: [],
            assigneeId: appState.currentUser.id,
        },
        latitude: null,
        longitude: null,
        street: '',
        houseNumber: null,
        entrance: '',
        door: '',
        plz: '',
        place: '',
        sideOfTheWorld: '',
        proximity: '',
        keyInfo: '',
        publishTheAddressTemplate: publishTheAddressTemplate.PLZ_AND_ORT,
        floor: null,
        floorDescription: '',
        floorNumber: null,
        numberOfAtticFloors: null,
        hasMezzanine: false,
        energyCertificateAvailable: false,
        hwb: null,
        class: null,
        fgee: '',
        fgeeClass: null,
        energyCertificateDateOfExpiry: null,
        energyCertificateYear: null,
        automaticDisclaimer: false,
        energyCertificateValidFrom: null,
        energyCertificateType: null,
        requirementCertificate: '',
        consumptionCertificate: '',
        heatingType: null,
        energySources: null,
        employeeId: null,
        acquisitionType: 'Non-aquisition',
        barrierFree: false,
        note: '',
        propertyType: null,
        labelling: '',
        isBuildingPlot: false,
        floorPlan: null,
        alsoVacationProperty: null,
        typeOfUseLiving: null,
        typeOfUseBusiness: null,
        typeOfUseInvestment: null,
        contact1: '',
        contact2: '',
        equity1: null,
        equity2: null,
        toRemove1: false,
        toRemove2: false,
        agreement: null,
        mediationOrderType: null,
        validFrom: new Date(),
        dateOfExpiry: new Date(),
        depositNumber: '',
        cadastreNumber: '',
        lotNumber: '',
        landRegisterSheet: '',
        district: '',
        corridor: '',
        parcel: '',
        maximumRentalPeriodYears: null,
        maximumRentalPeriodMonths: null,
        unlimitedRentalPeriod: null,
        waiverOfTerminationYears: null,
        waiverOfTerminationMonths: null,
        priceCalculation: '',
        publishPrices: null,
        parkingOptional: null,
        garageOptional: null,
        operationalCostOptional: null,
        elevatorCostOptional: null,
        pricePerSquare: null,
        interestedPartyInput: null,
        ust: null,
        output1: null,
        autofill: null,
        surrenderInput: null,
        vat1: null,
        output2: null,
        sourceLink: null,
        price: {
            priceType: 'Purchase',
            marketingTypePurchase: true,
            marketingTypeRental: false,
            marketingTypeLeasehold: false,
            marketingTypeLease: null,
            //currency: null,
            netPurchasePrice: null,
            purchasePriceTax: null,
            grossPurchasePrice: null,
            netParkingCost: null,
            parkingCostTax: null,
            grossParkingCost: null,
            netGarageCost: null,
            garageCostTax: null,
            grossGarageCost: null,
            netOperatingCost: null,
            operatingCostTax: null,
            grossOperatingCost: null,
            netExtraCost: null,
            extraCostTax: null,
            netHeatingCost: null,
            heatingCostTax: null,
            grossHeatingCost: null,
            grossExtraCost: null,
            netWarmWaterCost: null,
            warmWaterCostTax: null,
            grossWarmWaterCost: null,
            netCoolingCost: null,
            coolingCostTax: null,
            grossCoolingCost: null,
            netMaintenanceCost: null,
            maintenanceCostTax: null,
            grossMaintenanceCost: null,
            netOtherCost: null,
            otherCostTax: null,
            grossOtherCost: null,
            netElevatorCost: null,
            elevatorCostTax: null,
            grossElevatorCost: null,
            totalCost: null,
            netRentCost: null,
            rentCostTax: null,
            grossRentCost: null,
            purchasePricePerSquareMeter: null,
            operatingCostsPerSquareMeter: null,

            interessentProvision: 3,
            interessentProvisionType: priceCalculationOptions[0].value,
            interessentProvisionTax: 19,
            derstellung: '3.57% inkl. 19% MwSt.',
            derstellungAutomatic: true,
            verkauferProvision: 0,
            verkauferProvisionType: priceCalculationOptions[0].value,
            verkauferProvisionTax: 19,
            ergebnis: null,

            publishPrices: false,
        },
        surface: {
            plotArea: null,
            livingArea: null,
            usableArea: null,
            buildableArea: null,
            officeArea: null,
            salesArea: null,
            storageArea: null,
            hallheight: null,
            freeArea: null,
            temporaryArea: null,
            weightedArea: null,
            atticArea: null,
            unfinishedAttic: false,
            unfinishedAtticArea: null,
            numberOfRooms: null,
            hasHalfRoom: false,
            roomHeight: null,
            numberOfGardens: null,
            gardenArea: null,
            numberOfBasements: null,
            basementArea: null,
            numberOfBalconies: null,
            balconyArea: null,
            numberOfTerraces: null,
            terraceArea: null,
            numberOfLoggias: null,
            loggiasArea: null,
            numberOfGarages: null,
            garageArea: null,
            numberOfParkingSpaces: null,
            parkingArea: null,
            numberOfBedrooms: null,
            numberOfLivingRooms: null,
            numberOfToilets: null,
            numberOfBathrooms: null,
            numberOfStorageRooms: null,
        },
        text: {
            title: '',
            descriptionForTheInternet: '',
            location: '',
            shortDescription: '',
            publicTransportDescription: '',
            shoppingDescription: '',
            schoolDescription: '',
            description: '',
            otherInfoForTheInternet: '',
            text1: '',
            text2: '',
            text3: '',
        },
        furnishing: {
            constructionType: constructionType,
            roofShape: roofShape,
            expansionStage: expansionStage,
            floor: floorType,
            elevator: elevator,
            heating: heating,
            heatingType: heatingType,
            ventilation: ventilation,
            energyType: energyType,
            view: view,
            balcony: balcony,
            space: space,
            window: window,
            lighting: lighting,
            electricEnergy: electricEnergy,
            tv: tv,
            toilet: toilet,
            bathroom: bathroom,
            kitchen: kitchen,
            gastronomy: gastronomy,
            parkingSpaceType: parkingSpaceType,
            extras: extras,
            services: services,
            certificates: certificates,
        },
        other: {
            type: null,
            positionInBuildingLeft: null,
            positionInBuildingRight: null,
            positionInBuildingFront: null,
            positionInBuildingBack: null,
            status: null,
            houseCondition: null,
            formOfOwnership: null,
            furnishing: null,
            situationAssesment: null,
            conditionAssesment: null,
            noiseLevel: null,
            development: null,
            relatable: '',
            constructionYear: null,
            lastRenovationYear: null,
            lastGeneralRenovation: null,
            keyInHand: null,
            readyForCovering: null,
        },
        internal: {
            saleDate: null,
            sellerId: null,
            offerPrice: null,
            contractPrice: null,
            absoluteSellerCommissionAmmount: null,
            invoicingDateSeller: null,
            sellerCashInflowDate: null,
            absoluteBuyerCommissionAmmount: null,
            invoicingDateBuyer: null,
            buyerCashInflowDate: null,
        },
        exportState: {},
        files: {
            images: [],
            plans: [],
            videos: [],
            documents: [],
            view360: [],
        },
    });

    const handleShowNewContactModal = () => {
        setShowMewContactModal(!showMewContactModal, (state) => {
            if (!state) {
                DomHandler.removeClass(document.body, 'p-overflow-hidden');
            }
        });
    };

    useEffect(() => {
        setLoading(true);
    }, []);

    useEffect(() => {
        let isMounted = true;
        userService.refreshTokenCheck().then(() => {
            if (isMounted) {
                fetchUsers().then((result) => setEmployeeList(result));
                fetchContacts().then((result) => setContactList(result));
                setLoading(false);
            }
        });
        return () => {
            isMounted = false;
        };
    }, [showMewContactModal]);

    useEffect(() => {
        setSingleViewInformation(propertyId, realEstateObject.street);
    }, [propertyId]);

    useEffect(() => {
        let newRealEstateSellerObject = { ...realEstateObject };
        if (newRealEstateSellerObject.files.images.length === 1) {
            newRealEstateSellerObject.files.images[0] = {
                file: newRealEstateSellerObject.files.images[0].file,
                isCover: true,
            };
        }

        if (activeIndex === 7) {
            setIsLoading(true);
            propertiesService
                .createOne(newRealEstateSellerObject)
                .then((response) => {
                    setPropertyId(response.data.id);
                    setStatus(response.status);
                    setIsLoading(false);
                })
                .catch((err) => {
                    console.error(err);
                    setStatus(err.response?.status);
                });
            setActiveIndex(0);
        }
    }, [activeIndex]);

    const toast = useRef(null);
    useEffect(() => {
        if (status === 201) {
            toast.current.show({
                severity: 'success',
                detail: t(`Property has been saved successfully`),
                life: 3000,
            });
            setStatus(0);
        }
        if (status === 400) {
            toast.current.show({
                severity: 'error',
                detail: t(`fill all required fields!`),
                life: 3000,
            });
            setStatus(0);
        }
        if (status === 500) {
            toast.current.show({
                severity: 'error',
                detail: t(`internal server error`),
                life: 3000,
            });
            setStatus(0);
        }
    }, [status]);

    const fetchUsers = async () => {
        const response = await userService.getMany();
        return response.data;
    };

    const fetchContacts = async () => {
        const response = await contactsService.getMany();
        return response.data;
    };

    const employeeOptions = () => {
        const allEmployees = employeeList.map((employee) => ({
            label: employee.name,
            value: employee.id,
        }));
        return allEmployees;
    };

    const formatContactText = (contact) => {
        let result = '';

        result += contact.id;
        result += '. ';

        if (contact?.firstName) {
            result += ' ';
            result += contact?.firstName;
        }

        if (contact.lastName) {
            result += ' ';
            result += contact.lastName;
        }

        if (contact.postalCode) {
            result += ', ';
            result += contact.postalCode;
        }

        if (contact.city) {
            result += ', ';
            result += contact.city;
        }

        if (contact.address) {
            result += ', ';
            result += contact.address;
        }

        return result;
    };

    const contactOptions = () => {
        if (contactList) {
            const allContacts = contactList
                .filter((x) => x.role === 'Seller')
                .map((contact) => ({
                    label: formatContactText(contact),
                    value: contact.id,
                    role: contact.role,
                }));
            return allContacts.sort((a: any, b: any) => {
                const aId = parseInt(a.label.split(',')[0]);
                const bId = parseInt(b.label.split(',')[0]);

                return aId - bId;
            });
        } else return [];
    };

    const {
        control,
        formState: { errors },
        register,
        handleSubmit,
        trigger,
    } = useForm({
        mode: 'all',
        reValidateMode: 'onChange',
        defaultValues: realEstateObject,
    });

    useEffect(() => {
        trigger('publishTheAddressTemplate');
    }, [trigger, realEstateObject]);

    const onSubmit = () => {
        setActiveIndex(7);
    };

    const onError = () => {
        toast.current.show({
            severity: 'error',
            detail: t(`Fill all necessary fields`),
            life: 3000,
        });
    };

    const items = [
        { label: t('Base data') },
        { label: t('Prices') },
        // { label: t('Furnishing') },
        { label: t('Text') },
        // { label: t('Marketing') },
        { label: t('Dateien & Links') },
        {
            label: t('Internal'),
            style: {
                display: `${
                    userService.hasPermission(
                        appContext.appState.currentUser,
                        Permissions.USER_CREATE,
                    )
                        ? 'block'
                        : 'none'
                }`,
            },
        },
    ];

    const handleUpdatePrice = (value) => {
        setRealEstateObject((realEstateObject) => ({
            ...realEstateObject,
            price: {
                ...realEstateObject.price,
                priceType: value,
            },
        }));
    };

    const updateAcquisition = (value) => {
        setRealEstateObject((realEstateObject) => ({
            ...realEstateObject,
            acquisitionType: value,
        }));
    };

    const handleRealEstateState = (prop, value) => {
        const subObject: string[] = prop.split('_');
        const property: string =
            subObject[1] === undefined ? subObject[0] : subObject[1];
        switch (subObject[0]) {
            case 'seller':
                let newRealEstateSellerObject = { ...realEstateObject };
                newRealEstateSellerObject.seller[property] = value;
                setRealEstateObject((realEstateObject) => ({
                    ...realEstateObject,
                    newRealEstateSellerObject,
                }));
                break;
            case 'text': //text tab fields
                let newRealEstateTextObject = { ...realEstateObject };
                newRealEstateTextObject.text[property] = value;
                setRealEstateObject((realEstateObject) => ({
                    ...realEstateObject,
                    newRealEstateTextObject,
                }));
                break;
            case 'surface': //surface fields
                let newRealEstateSurfaceObject = { ...realEstateObject };
                newRealEstateSurfaceObject.surface[property] = value;
                setRealEstateObject((realEstateObject) => ({
                    ...realEstateObject,
                    newRealEstateSurfaceObject,
                }));
                break;
            case 'price': //price fields
                let newRealEstatePriceObject = { ...realEstateObject };
                newRealEstatePriceObject.price[property] = value;
                switch (
                    property //set gross fields
                ) {
                    case 'verkauferProvision':
                    case 'verkauferProvisionTax':
                        const provision =
                            newRealEstatePriceObject.price['netPurchasePrice'] *
                            (newRealEstatePriceObject.price[
                                'verkauferProvision'
                            ] /
                                100);
                        const provisionTax =
                            provision *
                            (newRealEstatePriceObject.price[
                                'verkauferProvisionTax'
                            ] /
                                100);
                        const provisionWithTax = provision + provisionTax;

                        newRealEstatePriceObject.price[
                            'ergebnis'
                        ] = `${provisionWithTax.toFixed(2)}`;
                        break;

                    case 'interessentProvision':
                    case 'interessentProvisionTax':
                    case 'derstellungAutomatic':
                        if (
                            newRealEstatePriceObject.price[
                                'derstellungAutomatic'
                            ]
                        ) {
                            const provision =
                                (newRealEstatePriceObject.price[
                                    'interessentProvisionTax'
                                ] /
                                    100) *
                                    newRealEstatePriceObject.price[
                                        'interessentProvision'
                                    ] +
                                newRealEstatePriceObject.price[
                                    'interessentProvision'
                                ];

                            newRealEstatePriceObject.price[
                                'derstellung'
                            ] = `${provision.toFixed(2)}% inkl. ${
                                newRealEstatePriceObject.price[
                                    'interessentProvisionTax'
                                ]
                            }% MwSt.`;
                        }
                        break;
                    case 'netParkingCost':
                        newRealEstatePriceObject.price['grossParkingCost'] =
                            parseFloat(
                                (
                                    (value *
                                        (100 +
                                            realEstateObject.price
                                                .parkingCostTax)) /
                                    100
                                ).toFixed(2),
                            );
                        break;
                    case 'parkingCostTax':
                        if (realEstateObject.price.netParkingCost !== null)
                            newRealEstatePriceObject.price['grossParkingCost'] =
                                parseFloat(
                                    (
                                        (realEstateObject.price.netParkingCost *
                                            (100 + value)) /
                                        100
                                    ).toFixed(2),
                                );
                        break;
                    case 'netGarageCost':
                        newRealEstatePriceObject.price['grossGarageCost'] =
                            parseFloat(
                                (
                                    (value *
                                        (100 +
                                            realEstateObject.price
                                                .garageCostTax)) /
                                    100
                                ).toFixed(2),
                            );
                        break;
                    case 'garageCostTax':
                        if (realEstateObject.price.netGarageCost !== null)
                            newRealEstatePriceObject.price['grossGarageCost'] =
                                parseFloat(
                                    (
                                        (realEstateObject.price.netGarageCost *
                                            (100 + value)) /
                                        100
                                    ).toFixed(2),
                                );
                        break;
                    case 'netOperatingCost':
                        newRealEstatePriceObject.price['grossOperatingCost'] =
                            parseFloat(
                                (
                                    (value *
                                        (100 +
                                            realEstateObject.price
                                                .operatingCostTax)) /
                                    100
                                ).toFixed(2),
                            );
                        break;
                    case 'operatingCostTax':
                        if (realEstateObject.price.netOperatingCost !== null)
                            newRealEstatePriceObject.price[
                                'grossOperatingCost'
                            ] = parseFloat(
                                (
                                    (realEstateObject.price.netOperatingCost *
                                        (100 + value)) /
                                    100
                                ).toFixed(2),
                            );
                        break;
                    case 'netExtraCost':
                        newRealEstatePriceObject.price['grossExtraCost'] =
                            parseFloat(
                                (
                                    (value *
                                        (100 +
                                            realEstateObject.price
                                                .extraCostTax)) /
                                    100
                                ).toFixed(2),
                            );
                        break;
                    case 'extraCostTax':
                        if (realEstateObject.price.netExtraCost !== null)
                            newRealEstatePriceObject.price['grossExtraCost'] =
                                parseFloat(
                                    (
                                        (realEstateObject.price.netExtraCost *
                                            (100 + value)) /
                                        100
                                    ).toFixed(2),
                                );
                        break;
                    case 'netHeatingCost':
                        newRealEstatePriceObject.price['grossHeatingCost'] =
                            parseFloat(
                                (
                                    (value *
                                        (100 +
                                            realEstateObject.price
                                                .heatingCostTax)) /
                                    100
                                ).toFixed(2),
                            );
                        break;
                    case 'heatingCostTax':
                        if (realEstateObject.price.netHeatingCost !== null)
                            newRealEstatePriceObject.price['grossHeatingCost'] =
                                parseFloat(
                                    (
                                        (realEstateObject.price.netHeatingCost *
                                            (100 + value)) /
                                        100
                                    ).toFixed(2),
                                );
                        break;
                    case 'netWarmWaterCost':
                        newRealEstatePriceObject.price['grossWarmWaterCost'] =
                            parseFloat(
                                (
                                    (value *
                                        (100 +
                                            realEstateObject.price
                                                .warmWaterCostTax)) /
                                    100
                                ).toFixed(2),
                            );
                        break;
                    case 'warmWaterCostTax':
                        if (realEstateObject.price.netWarmWaterCost !== null)
                            newRealEstatePriceObject.price[
                                'grossWarmWaterCost'
                            ] = parseFloat(
                                (
                                    (realEstateObject.price.netWarmWaterCost *
                                        (100 + value)) /
                                    100
                                ).toFixed(2),
                            );
                        break;
                    case 'netCoolingCost':
                        newRealEstatePriceObject.price['grossCoolingCost'] =
                            parseFloat(
                                (
                                    (value *
                                        (100 +
                                            realEstateObject.price
                                                .coolingCostTax)) /
                                    100
                                ).toFixed(2),
                            );
                        break;
                    case 'coolingCostTax':
                        if (realEstateObject.price.netCoolingCost !== null)
                            newRealEstatePriceObject.price['grossCoolingCost'] =
                                parseFloat(
                                    (
                                        (realEstateObject.price.netCoolingCost *
                                            (100 + value)) /
                                        100
                                    ).toFixed(2),
                                );
                        break;
                    case 'netMaintenanceCost':
                        newRealEstatePriceObject.price['grossMaintenanceCost'] =
                            parseFloat(
                                (
                                    (value *
                                        (100 +
                                            realEstateObject.price
                                                .maintenanceCostTax)) /
                                    100
                                ).toFixed(2),
                            );
                        break;
                    case 'maintenanceCostTax':
                        if (realEstateObject.price.netMaintenanceCost !== null)
                            newRealEstatePriceObject.price[
                                'grossMaintenanceCost'
                            ] = parseFloat(
                                (
                                    (realEstateObject.price.netMaintenanceCost *
                                        (100 + value)) /
                                    100
                                ).toFixed(2),
                            );
                        break;
                    case 'netOtherCost':
                        newRealEstatePriceObject.price['grossOtherCost'] =
                            parseFloat(
                                (
                                    (value *
                                        (100 +
                                            realEstateObject.price
                                                .otherCostTax)) /
                                    100
                                ).toFixed(2),
                            );
                        break;
                    case 'otherCostTax':
                        if (realEstateObject.price.netOtherCost !== null)
                            newRealEstatePriceObject.price['grossOtherCost'] =
                                parseFloat(
                                    (
                                        (realEstateObject.price.netOtherCost *
                                            (100 + value)) /
                                        100
                                    ).toFixed(2),
                                );
                        break;
                    case 'netElevatorCost':
                        newRealEstatePriceObject.price['grossElevatorCost'] =
                            parseFloat(
                                (
                                    (value *
                                        (100 +
                                            realEstateObject.price
                                                .elevatorCostTax)) /
                                    100
                                ).toFixed(2),
                            );
                        break;
                    case 'elevatorCostTax':
                        if (realEstateObject.price.netElevatorCost !== null)
                            newRealEstatePriceObject.price[
                                'grossElevatorCost'
                            ] = parseFloat(
                                (
                                    (realEstateObject.price.netElevatorCost *
                                        (100 + value)) /
                                    100
                                ).toFixed(2),
                            );
                        break;
                    case 'netRentCost':
                        newRealEstatePriceObject.price['grossRentCost'] =
                            parseFloat(
                                (
                                    (value *
                                        (100 +
                                            realEstateObject.price
                                                .rentCostTax)) /
                                    100
                                ).toFixed(2),
                            );
                        break;
                    case 'rentCostTax':
                        if (realEstateObject.price.netRentCost !== null)
                            newRealEstatePriceObject.price['grossRentCost'] =
                                parseFloat(
                                    (
                                        (realEstateObject.price.netRentCost *
                                            (100 + value)) /
                                        100
                                    ).toFixed(2),
                                );
                        break;
                    case 'netPurchasePrice':
                        newRealEstatePriceObject.price['grossPurchasePrice'] =
                            parseFloat(
                                (
                                    (value *
                                        (100 +
                                            realEstateObject.price
                                                .purchasePriceTax)) /
                                    100
                                ).toFixed(2),
                            );
                        break;
                    case 'purchasePriceTax':
                        if (realEstateObject.price.netPurchasePrice !== null)
                            newRealEstatePriceObject.price[
                                'grossPurchasePrice'
                            ] = parseFloat(
                                (
                                    (realEstateObject.price.netPurchasePrice *
                                        (100 + value)) /
                                    100
                                ).toFixed(2),
                            );
                        break;
                } //set totalCost field
                newRealEstatePriceObject.price['totalCost'] =
                    (realEstateObject.parkingOptional === true
                        ? 0
                        : newRealEstatePriceObject.price['grossParkingCost']) +
                    (realEstateObject.garageOptional === true
                        ? 0
                        : newRealEstatePriceObject.price['grossGarageCost']) +
                    (realEstateObject.operationalCostOptional === true
                        ? 0
                        : newRealEstatePriceObject.price[
                              'grossOperatingCost'
                          ]) +
                    newRealEstatePriceObject?.price['grossExtraCost'] +
                    newRealEstatePriceObject.price['grossHeatingCost'] +
                    newRealEstatePriceObject.price['grossWarmWaterCost'] +
                    newRealEstatePriceObject.price['grossCoolingCost'] +
                    newRealEstatePriceObject.price['grossMaintenanceCost'] +
                    newRealEstatePriceObject.price['grossOtherCost'] +
                    (realEstateObject.elevatorCostOptional === true
                        ? 0
                        : newRealEstatePriceObject.price['grossElevatorCost']);
                setRealEstateObject((realEstateObject) => ({
                    ...realEstateObject,
                    newRealEstatePriceObject,
                }));
                break;
            case 'other': //other fields
                let newRealEstateOtherObject = { ...realEstateObject };
                newRealEstateOtherObject.other[property] = value;
                setRealEstateObject((realEstateObject) => ({
                    ...realEstateObject,
                    newRealEstateOtherObject,
                }));
                break;
            case 'internal': //internal fields
                let newRealEstateInternObject = { ...realEstateObject };
                newRealEstateInternObject.internal[property] = value;
                setRealEstateObject((realEstateObject) => ({
                    ...realEstateObject,
                    newRealEstateInternObject,
                }));
                break;
            default:
                let newRealEstateObject = { ...realEstateObject };

                console.log(prop, property, value);

                switch (prop) {
                    case 'energyCertificateValidFrom':
                        newRealEstateObject['energyCertificateDateOfExpiry'] =
                            moment(value).add(10, 'year').toDate();
                        break;
                }

                setRealEstateObject((realEstateObject) => ({
                    ...realEstateObject,
                    ...newRealEstateObject,
                    [prop]: value,
                }));
        }
    };

    const handleFurnishingState = (furnishingStateClone) => {
        let newRealEstateFurnishingObject = { ...realEstateObject };

        const flatFurnishing = furnishingStateClone.flat(1);
        const result = {};

        for (const furnishingCategory of flatFurnishing) {
            result[furnishingCategory.attribute] = {};

            for (const furnishingField of furnishingCategory.fields) {
                result[furnishingCategory.attribute][
                    furnishingField.attribute
                ] = furnishingField.value;
            }
        }
        newRealEstateFurnishingObject.furnishing = {
            ...newRealEstateFurnishingObject.furnishing,
            ...result,
        };

        setRealEstateObject(newRealEstateFurnishingObject);
    };

    const handleDataAndLinksState = (dataAndLinksStateClone) => {
        let newRealEstateFurnishingObject = { ...realEstateObject };

        newRealEstateFurnishingObject.files = {
            ...newRealEstateFurnishingObject.files,
            ...dataAndLinksStateClone,
        };

        setRealEstateObject(newRealEstateFurnishingObject);
    };

    if (loading) {
        return (
            <div className="center-spinner">
                <ProgressSpinner strokeWidth="5" animationDuration="1.5s" />
            </div>
        );
    } else {
        return (
            <div className="new-object-container">
                <Toast ref={toast} position={'top-right'} />
                <div>
                    <TabMenu
                        className="new-object-tab-menu"
                        model={items}
                        activeIndex={activeIndex}
                        onTabChange={(e) => setActiveIndex(e.index)}
                    />
                    <Button
                        className="save-button1"
                        disabled={isLoading}
                        label={t('Save')}
                        onClick={handleSubmit(onSubmit, onError)}
                    />
                </div>
                <div>
                    <div
                        className={
                            activeIndex ===
                            items.findIndex((x) => x.label == t('Base data'))
                                ? 'block'
                                : 'hidden'
                        }
                    >
                        <ObjectBaseDataForm
                            realEstateObject={realEstateObject}
                            updateRealEstateObject={handleRealEstateState}
                            handleUpdateAcquisition={updateAcquisition}
                            control={control}
                            errors={errors}
                            employeeOptions={employeeOptions}
                            contactOptions={contactOptions}
                            showMewContactModal={showMewContactModal}
                            handleShowNewContactModal={
                                handleShowNewContactModal
                            }
                            trigger
                        />
                    </div>
                    <div
                        className={
                            activeIndex ===
                            items.findIndex((x) => x.label == t('Prices'))
                                ? 'block'
                                : 'hidden'
                        }
                    >
                        <ObjectPricesForm
                            realEstateObject={realEstateObject}
                            updateRealEstateObject={handleRealEstateState}
                            handleUpdatePrice={handleUpdatePrice}
                            control={control}
                            errors={errors}
                        />
                    </div>
                    <div
                        className={
                            activeIndex ===
                            items.findIndex((x) => x.label == t('Furnishing'))
                                ? 'block'
                                : 'hidden'
                        }
                    >
                        <ObjectFurnishingForm
                            furnishingObject={realEstateObject.furnishing}
                            updateFurnishingObject={handleFurnishingState}
                        />
                    </div>
                    <div
                        className={
                            activeIndex ===
                            items.findIndex((x) => x.label == t('Text'))
                                ? 'block'
                                : 'hidden'
                        }
                    >
                        <ObjectTextForm
                            realEstateObject={realEstateObject}
                            updateRealEstateObject={handleRealEstateState}
                            control={control}
                            register={register}
                            errors={errors}
                        />
                    </div>
                    {/* <div className={activeIndex === items.findIndex(x => x.label == t('Marketing')) ? 'block' : 'hidden'}>
                        <ObjectMarketingForm
                            realEstateObject={realEstateObject}
                            updateRealEstateObject={handleRealEstateState}
                        />
                    </div> */}
                    <div
                        className={
                            activeIndex ===
                            items.findIndex(
                                (x) => x.label == t('Dateien & Links'),
                            )
                                ? 'block'
                                : 'hidden'
                        }
                    >
                        <ObjectDataAndLinksForm
                            dataAndLinksObject={realEstateObject.files}
                            updateDataAndLinksObject={handleDataAndLinksState}
                        />
                    </div>
                    <div
                        className={
                            activeIndex ===
                            items.findIndex((x) => x.label == t('Internal'))
                                ? 'block'
                                : 'hidden'
                        }
                    >
                        <ObjectInternForm
                            realEstateObject={realEstateObject}
                            updateRealEstateObject={handleRealEstateState}
                            control={control}
                            register={register}
                            errors={errors}
                            contact={contactOptions()}
                        />
                    </div>
                </div>
                <Button
                    className="save-button2"
                    label={t('Save')}
                    disabled={isLoading}
                    onClick={handleSubmit(onSubmit, onError)}
                />
                {status === 201 && <Redirect to={`/object/${propertyId}`} />}
            </div>
        );
    }
};

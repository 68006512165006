import { CreateQueryParams } from "@nestjsx/crud-request";
import { AxiosResponse } from "axios";
import { BaseService } from './BaseService';
import { CrudService } from "./CrudService";

export class DashboardService extends CrudService {
    protected getUrlPath(): string {
        return '/dashboard';
    }

    public async get(params?: CreateQueryParams): Promise<AxiosResponse> {
        // const config = ids ? {
        //     params: ids.map((x) => x.toString())
        // } : {};

        // return this.axiosInstance.get(this.getUrlPath(), config);

        
        const dashboardResponse = await super.getMany(params);
        return new Promise((resolve: (dashboard) => void, reject: () => void) => {resolve(dashboardResponse)})
    }
}

export const dashboardService = new DashboardService();

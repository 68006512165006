import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import './ImageModal.scss';
import { ProgressSpinner } from 'primereact/progressspinner';
import React, { useState } from 'react';
import { filesService } from '../../services/FilesService';

const ImageModal = (props) => {
    const [loading, setLoading] = useState(true);

    const onLoad = () => {
        setLoading(false);
    };

    return (
        <>
            <Dialog
                header={props.image.name}
                visible={true}
                style={{ width: '60vw', height: '60vw' }}
                className="show-image"
                blockScroll
                footer={false}
                draggable={false}
                onHide={props.onHide}
            >
                {loading && (
                    <div className="center-spinner">
                        <ProgressSpinner
                            strokeWidth="5"
                            animationDuration="1.5s"
                        />
                    </div>
                )}
                <Button onClick={props.handleLeftSlide} className="btn-left">
                    <i className="pi pi-arrow-left" />
                </Button>
                <Button onClick={props.handleRightSlide} className="btn-right">
                    <i className="pi pi-arrow-right" />
                </Button>
                {props.image.mimeType !== 'application/pdf' && (
                    <img
                        style={{ width: '100%', height: '100%' }}
                        src={filesService.getFilePath(props.image.id)}
                        alt={props.image.name}
                        onLoad={onLoad}
                    />
                )}
                {props.image.mimeType === 'application/pdf' && (
                    <object
                        width="100%"
                        height="100%"
                        onLoad={onLoad}
                        data={filesService.getFilePath(props.image.id)}
                        type="application/pdf"
                    ></object>
                )}
            </Dialog>
        </>
    );
};

export default ImageModal;

import { Config } from '../Config';
import { CrudService } from './CrudService';
import { AxiosResponse } from "axios";
import { CreateQueryParams } from '@nestjsx/crud-request';
import { Inquiry } from '../models/Inquiry';
import { BaseService } from './BaseService';
import { axiosDefaults } from './AxiosDefaults';

export class FilesService extends BaseService {
    protected getUrlPath(): string {
        return '/files';
    }

    public async upload(files: any[], category: string): Promise<AxiosResponse> {
        const formData = new FormData();

        for (const file of files) {
            formData.append(`${category}`, file, file.name);
        }

        return this.axiosInstance.post(this.getUrlPath() + '/upload', formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
    }

    public getFilePath(id: number): string {
        return `${axiosDefaults.baseURL}${this.getUrlPath()}/${id}`;
    }
}

export const filesService = new FilesService();

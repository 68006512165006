import * as React from 'react';
import './RainbowIndicator.scss';

type RainbowIndicatorProps = {
    min?: number;
    max?: number;
    value?: number;
    show: boolean;
    secondValue?: number;
};

const RainbowIndicator: React.FunctionComponent<RainbowIndicatorProps> = ({
    min = 0,
    max = 100,
    value,
    show,
    secondValue,
}) => {
    const [val, setVal] = React.useState(show ? secondValue : value);
    const [style, setStyle] = React.useState({});
    const [middle, setMiddle] = React.useState((max + min) / 2);

    React.useEffect(() => {
        if (value < min) setVal(min);
        if (value > max) setVal(max);

        setStyle({
            '--line-offset': `${Math.max(2, Math.min(98, ((val - min) / (max - min)) * 100))}%`,
        });
        setMiddle(!show ? (max + min) / 2 : Math.floor((max + min) / 2));
    }, [min, max, value, val]);

    return (
        <div className="rainbow-indicator" style={style as React.CSSProperties}>
            <div className="indicator-bar">
                <div className="indicator-line">
                    <div className="indicator-value">{val}</div>
                </div>
            </div>
            <div className="indicator-ticks">
                <span>{min}</span>
                <span>{middle}</span>
                <span>{max}</span>
            </div>
        </div>
    );
};

export default RainbowIndicator;

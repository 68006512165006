import React, { useState, useRef, useEffect } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import './ViewObject.scss';
import { ActionMenu } from '../../components/actionMenu/ActionMenu';
import { TabMenu } from '../../components/_overrides/TabMenu';
import ObjectStatusBadge from '../../components/objects/objectStatusBadge/ObjectStatusBadge';
import ObjectSingleView from '../../components/objects/objectSingleView/ObjectSingleView';
import ObjectBaseDataForm from '../../components/objects/objectBaseDataForm/ObjectBaseDataForm';
import ObjectPricesForm from '../../components/objects/objectPricesForm/ObjectPricesForm';
import ObjectFurnishingForm from '../../components/objects/objectFurnishingForm/ObjectFurnishingForm';
import ObjectTextForm from '../../components/objects/objectTextForm/ObjectTextForm';
import ObjectMarketingForm from '../../components/objects/objectMarketingForm/ObjectMarketingForm';
import ObjectDataAndLinksForm from '../../components/objects/objectDataAndLinksForm/ObjectDataAndLinksForm';
import { useForm } from 'react-hook-form';
import { userService } from '../../services/UsersService';
import { contactsService } from '../../services/ContactsService';
import { RealEstateObject } from '../../models/RealEstateObject';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { useTranslation } from 'react-i18next';
import { propertiesService } from '../../services/PropertiesService';
import _, { indexOf } from 'lodash';
import ObjectInternForm from '../../components/objects/ObjectInternForm/ObjectInternForm';
import { useApplicationContext } from '../../application/ApplicationContext';
import { confirmDialog, ConfirmDialog } from 'primereact/confirmdialog';
import { exportService } from '../../services/ExportService';
import { Permissions } from '../../core/auth/Permissions';
import { useStateCallback } from '../../hooks/StateCallbackHook';
import { classNames, DomHandler } from 'primereact/utils';
import MeineInteresentPage from '../MeineInteresentPage/MeineInteresentPage';
import { energyCertificateYear } from '../../constants/ObjectConsts';
import moment from 'moment/moment';
import { Card } from 'primereact/card';
import { Checkbox } from 'primereact/checkbox';
import ConfirmDuplicateModal from '../../components/ConfirmDuplicateModal/ConfirmDuplicateModal';

export const ViewObject = () => {
    const location = useLocation();
    const appContext = useApplicationContext();
    const { t } = useTranslation();
    const [activeIndex, setActiveIndex] = useState<number>(0);
    const { id } = useParams<{ id: string }>();
    const [data, setData] = useState(undefined);
    const [employeeList, setEmployeeList] = useState([]);
    const [contactList, setContactList] = useState([]);
    const [status, setStatus] = useState(0);
    const [coverPhoto, setCoverPhoto] = useState(undefined);
    const [prevActiveIndex, setPrevActiveIndex] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [files, setFiles] = useState({
        images: [],
        plans: [],
        videos: [],
        documents: [],
        view360: [],
    });
    const [showRealEstateObject, setShowRealEstateObject] =
        useState<RealEstateObject>(undefined);
    const [duplicateModalVisible, setDuplicateModalVisible] =
        useStateCallback(false);

    const showDuplicateModal = () => {
        setDuplicateModalVisible(!duplicateModalVisible, (state) => {
            if (!state) {
                DomHandler.removeClass(document.body, 'p-overflow-hidden');
            }
        });
    };

    const fetchProperty = async () => {
        const response = await propertiesService.getOne(id);
        return response.data;
    };

    useEffect(() => {
        userService.refreshTokenCheck().then(() => {
            fetchProperty().then((result) => {
                setData(result);
                appContext.setSingleViewInformation(
                    result.rootId,
                    result.street,
                );
            });
        });
    }, []);

    useEffect(() => {
        if (activeIndex !== 8) {
            setPrevActiveIndex(activeIndex);
        }
    }, [activeIndex]);

    useEffect(() => {
        if (status === 200 || status === 22 || status === 23)
            userService.refreshTokenCheck().then(() => {
                fetchProperty().then((result) => {
                    setData(result);
                });
            });
    }, [status]);

    useEffect(() => {
        if (!data) return;

        const filesClone = _.cloneDeep(files);

        for (const fileCategory of Object.keys(filesClone)) {
            filesClone[fileCategory] = data[fileCategory]
                .map((x) => ({
                    file: x,
                    isCover: false,
                }))
                .sort((a, b) => a.file.order - b.file.order);
        }
        if (data.coverPhoto) {
            filesClone.images.unshift({
                file: data.coverPhoto,
                isCover: true,
            });
        }

        // if (filesClone.images.length === 1 && !data.coverPhoto) {
        //     filesClone.images = [{
        //         file:filesClone.images[0].file,
        //         isCover: true,
        //     }];
        //     propertiesService
        //         .updateOne(`${data.rootId}`, {...showRealEstateObject, coverPhoto: {filesClone.images[0].file}})
        //     setCoverPhoto(filesClone.images[0].file)
        // }

        setFiles(filesClone);
    }, [data]);

    useEffect(() => {
        if (!data) return;

        const exportState = {};
        for (const exportEntity of data.exports) {
            exportState[exportEntity.providerKey] =
                exportEntity.status === 'active';
        }

        setShowRealEstateObject({
            rootId: data.rootId,
            readyForExport: data.readyForExport,
            // countryId: data.countryId,
            stateId: data.stateId,
            propertyTypeId: data.propertyTypeId,
            propertySubtypeId: data.propertySubtypeId,
            existingContact: true,
            sellerId: data.sellerId,
            seller: {
                firstName: '',
                lastName: '',
                emails: [],
                assigneeId: appContext.appState.currentUser.id,
            },
            latitude: data.latitude,
            longitude: data.longitude,
            street: data.street,
            status: data.status,
            houseNumber: data.houseNumber,
            entrance: data.entrance,
            door: data.door,
            plz: data.plz,
            place: data.place,
            sideOfTheWorld: data.sideOfTheWorld,
            proximity: data.proximity,
            keyInfo: data.keyInfo,
            publishTheAddressTemplate: data.publishTheAddressTemplate,
            floor: data.floor,
            floorDescription: data.floorDescription,
            floorNumber: data.floorNumber,
            acquisitionType: data.acquisitionType,
            numberOfAtticFloors: data.numberOfAtticFloors,
            hasMezzanine: data.hasMezzanine,
            energyCertificateAvailable: data.energyCertificateAvailable,
            hwb: data.hwb,
            class: data.class,
            fgee: data.fgee,
            fgeeClass: data.fgeeClass,
            energyCertificateDateOfExpiry: new Date(
                data.energyCertificateDateOfExpiry,
            ),
            energyCertificateYear: data.energyCertificateYear,
            automaticDisclaimer: data.automaticDisclaimer,
            energyCertificateValidFrom: new Date(
                data.energyCertificateValidFrom,
            ),
            energyCertificateType: data.energyCertificateType,
            requirementCertificate: data.requirementCertificate,
            consumptionCertificate: data.consumptionCertificate,
            heatingType: data.heatingType,
            energySources: data.energySources,
            employeeId: data.employeeId,
            barrierFree: data.barrierFree,
            note: data.note,
            labelling: data.labelling,
            propertyType: data.propertyType,
            isBuildingPlot: data.isBuildingPlot,
            floorPlan: data.floorPlan,
            alsoVacationProperty: data.alsoVacationProperty,
            typeOfUseLiving: data.typeOfUseLiving,
            typeOfUseBusiness: data.typeOfUseBusiness,
            typeOfUseInvestment: data.typeOfUseInvestment,
            contact1: data.contact1,
            contact2: data.contact2,
            equity1: data.equity1,
            equity2: data.equity2,
            toRemove1: data.toRemove1,
            toRemove2: data.toRemove2,
            agreement: data.agreement,
            mediationOrderType: data.mediationOrderType,
            validFrom: data.validFrom,
            dateOfExpiry: new Date(data.dateOfExpiry),
            depositNumber: data.depositNumber,
            cadastreNumber: data.cadastreNumber,
            lotNumber: data.lotNumber,
            landRegisterSheet: data.landRegisterSheet,
            district: data.district,
            corridor: data.corridor,
            parcel: data.parcel,
            maximumRentalPeriodYears: data.maximumRentalPeriodYears,
            maximumRentalPeriodMonths: data.maximumRentalPeriodMonths,
            unlimitedRentalPeriod: data.unlimitedRentalPeriod,
            waiverOfTerminationYears: data.waiverOfTerminationYears,
            waiverOfTerminationMonths: data.waiverOfTerminationMonths,
            priceCalculation: data.priceCalculation,
            publishPrices: data.publishPrices,
            parkingOptional: data.parkingOptional,
            garageOptional: data.garageOptional,
            operationalCostOptional: data.operationalCostOptional,
            elevatorCostOptional: data.elevatorCostOptional,
            pricePerSquare: data.pricePerSquare,
            interestedPartyInput: data.interestedPartyInput,
            ust: data.ust,
            output1: data.output1,
            autofill: data.autofill,
            surrenderInput: data.surrenderInput,
            vat1: data.vat1,
            output2: data.output2,
            sourceLink: data.sourceLink,
            price: {
                priceType: data.price.priceType,
                marketingTypePurchase: data.price.marketingTypePurchase,
                marketingTypeRental: data.price.marketingTypeRental,
                marketingTypeLeasehold: data.price.marketingTypeLeasehold,
                marketingTypeLease: data.price.marketingTypeLease,
                netPurchasePrice: data.price.netPurchasePrice,
                purchasePriceTax: data.price.purchasePriceTax,
                grossPurchasePrice: data.price.grossPurchasePrice,
                netParkingCost: data.price.netParkingCost,
                parkingCostTax: data.price.parkingCostTax,
                grossParkingCost: data.price.grossParkingCost,
                netGarageCost: data.price.netGarageCost,
                garageCostTax: data.price.garageCostTax,
                grossGarageCost: data.price.grossGarageCost,
                netOperatingCost: data.price.netOperatingCost,
                operatingCostTax: data.price.operatingCostTax,
                grossOperatingCost: data.price.grossOperatingCost,
                netExtraCost: data.price.netExtraCost,
                extraCostTax: data.price.extraCostTax,
                netHeatingCost: data.price.netHeatingCost,
                heatingCostTax: data.price.heatingCostTax,
                grossHeatingCost: data.price.grossHeatingCost,
                grossExtraCost: data.price.grossExtraCost,
                netWarmWaterCost: data.price.netWarmWaterCost,
                warmWaterCostTax: data.price.warmWaterCostTax,
                grossWarmWaterCost: data.price.grossWarmWaterCost,
                netCoolingCost: data.price.netCoolingCost,
                coolingCostTax: data.price.coolingCostTax,
                grossCoolingCost: data.price.grossCoolingCost,
                netMaintenanceCost: data.price.netMaintenanceCost,
                maintenanceCostTax: data.price.maintenanceCostTax,
                grossMaintenanceCost: data.price.grossMaintenanceCost,
                netOtherCost: data.price.netOtherCost,
                otherCostTax: data.price.otherCostTax,
                grossOtherCost: data.price.grossOtherCost,
                totalCost: data.price.totalCost,
                netElevatorCost: data.price.netElevatorCost,
                elevatorCostTax: data.price.elevatorCostTax,
                grossElevatorCost: data.price.grossElevatorCost,
                netRentCost: data.price.netRentCost,
                rentCostTax: data.price.rentCostTax,
                grossRentCost: data.price.grossRentCost,
                purchasePricePerSquareMeter:
                    data.price.purchasePricePerSquareMeter,
                operatingCostsPerSquareMeter:
                    data.price.operatingCostsPerSquareMeter,

                interessentProvision: data.price.interessentProvision,
                interessentProvisionType: data.price.interessentProvisionType,
                interessentProvisionTax: data.price.interessentProvisionTax,
                derstellung: data.price.derstellung,
                verkauferProvision: data.price.verkauferProvision,
                verkauferProvisionType: data.price.verkauferProvisionType,
                verkauferProvisionTax: data.price.verkauferProvisionTax,
                ergebnis: data.price.ergebnis,
                publishPrices: data.price.publishPrices,
            },
            surface: {
                plotArea: data.surface.plotArea,
                livingArea: data.surface.livingArea,
                usableArea: data.surface.usableArea,
                buildableArea: data.surface.buildableArea,
                officeArea: data.surface.officeArea,
                salesArea: data.surface.salesArea,
                storageArea: data.surface.storageArea,
                hallheight: data.surface.hallheight,
                freeArea: data.surface.freeArea,
                temporaryArea: data.surface.temporaryArea,
                weightedArea: data.surface.weightedArea,
                atticArea: data.surface.atticArea,
                unfinishedAttic: data.surface.unfinishedAttic,
                unfinishedAtticArea: data.surface.unfinishedAtticArea,
                numberOfRooms: data.surface.numberOfRooms,
                hasHalfRoom: data.surface.hasHalfRoom,
                roomHeight: data.surface.roomHeight,
                numberOfGardens: data.surface.numberOfGardens,
                gardenArea: data.surface.gardenArea,
                numberOfBasements: data.surface.numberOfBasements,
                basementArea: data.surface.basementArea,
                numberOfBalconies: data.surface.numberOfBalconies,
                balconyArea: data.surface.balconyArea,
                numberOfTerraces: data.surface.numberOfTerraces,
                terraceArea: data.surface.terraceArea,
                numberOfLoggias: data.surface.numberOfLoggias,
                loggiasArea: data.surface.loggiasArea,
                numberOfGarages: data.surface.numberOfGarages,
                garageArea: data.surface.garageArea,
                numberOfParkingSpaces: data.surface.numberOfParkingSpaces,
                parkingArea: data.surface.parkingArea,
                numberOfBedrooms: data.surface.numberOfBedrooms,
                numberOfLivingRooms: data.surface.numberOfLivingRooms,
                numberOfToilets: data.surface.numberOfToilets,
                numberOfBathrooms: data.surface.numberOfBathrooms,
                numberOfStorageRooms: data.surface.numberOfStorageRooms,
            },
            text: {
                title: data.text.title,
                descriptionForTheInternet: data.text.descriptionForTheInternet,
                location: data.text.location,
                shortDescription: data.text.shortDescription,
                publicTransportDescription:
                    data.text.publicTransportDescription,
                shoppingDescription: data.text.shoppingDescription,
                description: data.text.description,
                schoolDescription: data.text.schoolDescription,
                otherInfoForTheInternet: data.text.otherInfoForTheInternet,
                text1: data.text.text1,
                text2: data.text.text2,
                text3: data.text.text3,
            },
            furnishing: data.furnishing,
            other: {
                type: data.other.type,
                positionInBuildingLeft: data.other.positionInBuildingLeft,
                positionInBuildingRight: data.other.positionInBuildingRight,
                positionInBuildingFront: data.other.positionInBuildingFront,
                positionInBuildingBack: data.other.positionInBuildingBack,
                status: data.other.status,
                houseCondition: data.other.houseCondition,
                formOfOwnership: data.other.formOfOwnership,
                furnishing: data.other.furnishing,
                situationAssesment: data.other.situationAssesment,
                conditionAssesment: data.other.conditionAssesment,
                noiseLevel: data.other.noiseLevel,
                development: data.other.development,
                relatable: data.other.relatable,
                constructionYear: data.other.constructionYear,
                lastRenovationYear: data.other.lastRenovationYear,
                lastGeneralRenovation: data.other.lastGeneralRenovation,
                keyInHand: data.other.keyInHand,
                readyForCovering: data.other.readyForCovering,
            },
            internal: {
                saleDate: data.internal
                    ? new Date(data.internal.saleDate)
                    : null,
                sellerId: data.internal ? data.internal.sellerId : null,
                offerPrice: data.internal ? data.internal.offerPrice : null,
                contractPrice: data.internal
                    ? data.internal.contractPrice
                    : null,
                absoluteSellerCommissionAmmount: data.internal
                    ? data.internal.absoluteSellerCommissionAmmount
                    : null,
                invoicingDateSeller: data.internal
                    ? new Date(data.internal.invoicingDateSeller)
                    : null,
                sellerCashInflowDate: data.internal
                    ? new Date(data.internal.sellerCashInflowDate)
                    : null,
                absoluteBuyerCommissionAmmount: data.internal
                    ? data.internal.absoluteBuyerCommissionAmmount
                    : null,
                invoicingDateBuyer: data.internal
                    ? new Date(data.internal.invoicingDateBuyer)
                    : null,
                buyerCashInflowDate: data.internal
                    ? new Date(data.internal.buyerCashInflowDate)
                    : null,
            },
            exportState: exportState,
            files: files,
        });
    }, [files]);

    const [showMewContactModal, setShowMewContactModal] =
        useStateCallback(false);

    const handleShowNewContactModal = () => {
        setShowMewContactModal(!showMewContactModal, (state) => {
            if (!state) {
                DomHandler.removeClass(document.body, 'p-overflow-hidden');
            }
        });
    };

    useEffect(() => {
        let isMounted = true;
        userService.refreshTokenCheck().then(() => {
            if (isMounted) {
                fetchUsers().then((result) => setEmployeeList(result));
                fetchContacts().then((result) => setContactList(result));
            }
        });
        return () => {
            isMounted = false;
        };
    }, [showMewContactModal]);

    const history = useHistory();
    useEffect(() => {
        // if (activeIndex === 6) {
        //     history.push({
        //         pathname: `/inquiries`,
        //         search: `?propertyId=${data.rootId}`,
        //     });
        //     return;
        // }

        let newRealEstateObject = { ...showRealEstateObject };
        if (
            newRealEstateObject &&
            newRealEstateObject?.files?.images.length === 1 &&
            activeIndex === 8
        ) {
            newRealEstateObject.files.images[0] = {
                file: newRealEstateObject.files.images[0].file,
                isCover: true,
            };
        }

        if (activeIndex === 8) {
            setIsLoading(true);
            propertiesService
                .updateOne(`${data.rootId}`, newRealEstateObject)
                .then((response) => {
                    setStatus(response?.status);
                    setIsLoading(false);
                })
                .catch((err) => {
                    setStatus(err.response.status);
                    setIsLoading(false);
                });

            setActiveIndex(prevActiveIndex);
            return;
        }
    }, [activeIndex]);

    const toast = useRef(null);
    useEffect(() => {
        if (status === 200 || status === 201) {
            toast.current.show({
                severity: 'success',
                detail: t(`Property has been updated successfully`),
                life: 3000,
            });
            setStatus(0);
        }
        if (status === 400) {
            toast.current.show({
                severity: 'error',
                detail: t(`fill all required fields!`),
                life: 3000,
            });
            setStatus(0);
        }
        if (status === 500) {
            toast.current.show({
                severity: 'error',
                detail: t(`internal server error`),
                life: 3000,
            });
            setStatus(0);
        }
        if (status === 22) {
            toast.current.show({
                severity: 'success',
                detail: t('Property labeled for export'),
                life: 3000,
            });
            setStatus(0);
        }
        if (status === 23) {
            toast.current.show({
                severity: 'success',
                detail: t('Property unlabeled for export'),
                life: 3000,
            });
            setStatus(0);
        }
    }, [status]);

    const fetchUsers = async () => {
        const response = await userService.getMany();
        return response.data;
    };

    const fetchContacts = async () => {
        const response = await contactsService.getMany();
        return response.data;
    };

    const employeeOptions = () => {
        const allEmployees = employeeList.map((employee) => ({
            label: employee.name,
            value: employee.id,
        }));
        return allEmployees;
    };

    const formatContactText = (contact) => {
        let result = '';

        result += contact.id;
        result += '. ';

        if (contact.firstName) {
            result += ' ';
            result += contact.firstName;
        }

        if (contact.lastName) {
            result += ' ';
            result += contact.lastName;
        }

        if (contact.postalCode) {
            result += ', ';
            result += contact.postalCode;
        }

        if (contact.city) {
            result += ', ';
            result += contact.city;
        }

        if (contact.address) {
            result += ', ';
            result += contact.address;
        }

        return result;
    };

    const contactOptions = () => {
        const allContacts = contactList.map((contact) => ({
            label: formatContactText(contact),
            value: contact.id,
            role: contact.role,
        }));
        return allContacts.sort((a: any, b: any) => {
            const aId = parseInt(a.label.split(',')[0]);
            const bId = parseInt(b.label.split(',')[0]);

            return aId - bId;
        });
    };

    const {
        control,
        formState: { errors },
        register,
        trigger,
    } = useForm({
        mode: 'all',
        reValidateMode: 'onChange',
    });

    const items = [
        { label: t('Ubersicht') },
        { label: t('Base data') },
        { label: t('Prices') },
        // { label: t('Furnishing') },
        { label: t('Text') },
        {
            label: t('Marketing'),
            style: {
                display: `${
                    userService.hasPermission(
                        appContext.appState.currentUser,
                        Permissions.PROPERTY_MARKETING,
                    )
                        ? 'block'
                        : 'none'
                }`,
            },
        },
        { label: t('Interessenten') },
        { label: t('Dateien & Links') },
        {
            label: t('Intern'),
            style: {
                display: `${
                    userService.hasPermission(
                        appContext.appState.currentUser,
                        Permissions.USER_CREATE,
                    )
                        ? 'block'
                        : 'none'
                }`,
            },
        },
        {
            label: t('Save'),
            disabled: isLoading,
            className: 'save-button',
        },
    ];

    const statusBodyTemplate = () => {
        return (
            <ObjectStatusBadge
                type="new-table-with-text"
                status={`${data.status}`}
            />
        );
    };

    const confirmExportDialog = (): void => {
        confirmDialog({
            message: !showRealEstateObject.readyForExport
                ? t('Are you sure you want to label this property for export?')
                : t(
                      'Are you sure you want to unlabel this property for export?',
                  ),
            header: t('Warning'),
            icon: 'pi pi-exclamation-triangle',
            accept: () => {
                showRealEstateObject.readyForExport =
                    !showRealEstateObject.readyForExport;
                for (const property in showRealEstateObject.exportState) {
                    showRealEstateObject.exportState[property] = false;
                }
                propertiesService
                    .updateOne(`${data.rootId}`, showRealEstateObject)
                    .then((response) => {
                        if (response.data.readyForExport) {
                            setStatus(22);
                        } else {
                            setStatus(23);
                        }
                        // response.data.readyForExport
                        //     ? setStatus(22)
                        //     : setStatus(0);
                    })
                    .catch((error) => {
                        console.error(error);
                        setStatus(500);
                    });
            },
            reject: () => {},
        });
    };

    const confirmDuplicateDialog = (): void => {
        confirmDialog({
            acceptLabel: 'OK',
            rejectLabel: 'Abbrechen',
            message: (
                <div style={{ marginLeft: '-1rem' }}>
                    <p>Folgende Daten werden übernommen:</p>
                    <div
                        className={'p-inputgroup'}
                        style={{ marginBottom: '8px' }}
                    >
                        <Checkbox
                            id={'duplicate_transferFirstDate'}
                            checked={
                                appContext.appState.duplicateTransferFirstDate
                            }
                            onChange={(e) => {
                                console.log(e);
                                appContext.setDuplicateTransferFirstDate(
                                    e.checked,
                                );
                            }}
                        />
                        <label
                            htmlFor={'duplicate_transferFirstDate'}
                            style={{ marginLeft: '8px' }}
                        >
                            {t('Verkäufer Kommunikation')}
                        </label>
                    </div>
                    <div className={'p-inputgroup'}>
                        <Checkbox
                            id={'duplicate_transferInquiries'}
                            checked={
                                appContext.appState.duplicateTransferInquiries
                            }
                            onChange={(e) => {
                                console.log(e);
                                appContext.setDuplicateTransferInquiries(
                                    e.checked,
                                );
                            }}
                        />
                        <label
                            htmlFor={'duplicate_transferInquiries'}
                            style={{ marginLeft: '8px' }}
                        >
                            {t('Alle Interessenten')}
                        </label>
                    </div>
                </div>
            ),
            header: t('Duplizieren'),
            accept: () => {
                propertiesService
                    .duplicate(
                        `${data.rootId}`,
                        appContext.appState.duplicateTransferFirstDate,
                        appContext.appState.duplicateTransferInquiries,
                    )
                    .then((response) => {
                        toast.current.show({
                            severity: 'success',
                            detail: t('Eigenschaft erfolgreich dupliziert'),
                            life: 3000,
                        });
                        appContext.setDuplicateTransferFirstDate(true);
                        appContext.setDuplicateTransferInquiries(true);
                    })
                    .catch((error) => {
                        console.error(error);
                        appContext.setDuplicateTransferFirstDate(true);
                        appContext.setDuplicateTransferInquiries(true);
                    });
            },
            reject: () => {},
        });
    };

    const statusBadge = () => {
        return <div className="status-bagde">EA</div>;
    };

    const handleRealEstateState = (prop, value) => {
        const subObject: string[] = prop.split('_');
        const property: string =
            subObject[1] === undefined ? subObject[0] : subObject[1];
        switch (subObject[0]) {
            case 'exports':
                let newRealEstateExportsObject = {
                    ...showRealEstateObject,
                };
                newRealEstateExportsObject.exportState[property] = value;
                setShowRealEstateObject((realEstateObject) => ({
                    ...realEstateObject,
                    newRealEstateExportsObject,
                }));
                break;
            case 'seller':
                let newRealEstateSellerObject = { ...showRealEstateObject };
                newRealEstateSellerObject.seller[property] = value;
                setShowRealEstateObject((realEstateObject) => ({
                    ...realEstateObject,
                    newRealEstateSellerObject,
                }));
                break;
            case 'text': //text tab fields
                let newRealEstateTextObject = { ...showRealEstateObject };
                newRealEstateTextObject.text[property] = value;
                setShowRealEstateObject((realEstateObject) => ({
                    ...realEstateObject,
                    newRealEstateTextObject,
                }));
                break;
            case 'surface': //surface fields
                let newRealEstateSurfaceObject = { ...showRealEstateObject };
                newRealEstateSurfaceObject.surface[property] = value;
                setShowRealEstateObject((realEstateObject) => ({
                    ...realEstateObject,
                    newRealEstateSurfaceObject,
                }));
                break;
            case 'price': //price fields
                const newRealEstatePriceObject = { ...showRealEstateObject };
                newRealEstatePriceObject.price[property] = value;
                switch (
                    property // set gross fields
                ) {
                    case 'verkauferProvision':
                    case 'verkauferProvisionTax':
                        const provision =
                            newRealEstatePriceObject.price['netPurchasePrice'] *
                            (newRealEstatePriceObject.price[
                                'verkauferProvision'
                            ] /
                                100);
                        const provisionTax =
                            provision *
                            (newRealEstatePriceObject.price[
                                'verkauferProvisionTax'
                            ] /
                                100);
                        const provisionWithTax = provision + provisionTax;

                        newRealEstatePriceObject.price[
                            'ergebnis'
                        ] = `${provisionWithTax.toFixed(2)}`;
                        break;

                    case 'interessentProvision':
                    case 'interessentProvisionTax':
                    case 'derstellungAutomatic':
                        if (
                            newRealEstatePriceObject.price[
                                'derstellungAutomatic'
                            ]
                        ) {
                            const provision =
                                (newRealEstatePriceObject.price[
                                    'interessentProvisionTax'
                                ] /
                                    100) *
                                    newRealEstatePriceObject.price[
                                        'interessentProvision'
                                    ] +
                                newRealEstatePriceObject.price[
                                    'interessentProvision'
                                ];

                            newRealEstatePriceObject.price[
                                'derstellung'
                            ] = `${provision.toFixed(2)}% inkl. ${
                                newRealEstatePriceObject.price[
                                    'interessentProvisionTax'
                                ]
                            }% MwSt.`;
                        }
                        break;
                    case 'netParkingCost':
                        newRealEstatePriceObject.price['grossParkingCost'] =
                            parseFloat(
                                (
                                    (value *
                                        (100 +
                                            showRealEstateObject.price
                                                .parkingCostTax)) /
                                    100
                                ).toFixed(2),
                            );
                        break;
                    case 'parkingCostTax':
                        if (showRealEstateObject.price.netParkingCost !== null)
                            newRealEstatePriceObject.price['grossParkingCost'] =
                                parseFloat(
                                    (
                                        (showRealEstateObject.price
                                            .netParkingCost *
                                            (100 + value)) /
                                        100
                                    ).toFixed(2),
                                );
                        break;
                    case 'netGarageCost':
                        newRealEstatePriceObject.price['grossGarageCost'] =
                            parseFloat(
                                (
                                    (value *
                                        (100 +
                                            showRealEstateObject.price
                                                .garageCostTax)) /
                                    100
                                ).toFixed(2),
                            );
                        break;
                    case 'garageCostTax':
                        if (showRealEstateObject.price.netGarageCost !== null)
                            newRealEstatePriceObject.price['grossGarageCost'] =
                                parseFloat(
                                    (
                                        (showRealEstateObject.price
                                            .netGarageCost *
                                            (100 + value)) /
                                        100
                                    ).toFixed(2),
                                );
                        break;
                    case 'netOperatingCost':
                        newRealEstatePriceObject.price['grossOperatingCost'] =
                            parseFloat(
                                (
                                    (value *
                                        (100 +
                                            showRealEstateObject.price
                                                .operatingCostTax)) /
                                    100
                                ).toFixed(2),
                            );
                        break;
                    case 'operatingCostTax':
                        if (
                            showRealEstateObject.price.netOperatingCost !== null
                        )
                            newRealEstatePriceObject.price[
                                'grossOperatingCost'
                            ] = parseFloat(
                                (
                                    (showRealEstateObject.price
                                        .netOperatingCost *
                                        (100 + value)) /
                                    100
                                ).toFixed(2),
                            );
                        break;
                    case 'netExtraCost':
                        newRealEstatePriceObject.price['grossExtraCost'] =
                            parseFloat(
                                (
                                    (value *
                                        (100 +
                                            showRealEstateObject.price
                                                .extraCostTax)) /
                                    100
                                ).toFixed(2),
                            );
                        break;
                    case 'extraCostTax':
                        if (showRealEstateObject.price.netExtraCost !== null)
                            newRealEstatePriceObject.price['grossExtraCost'] =
                                parseFloat(
                                    (
                                        (showRealEstateObject.price
                                            .netExtraCost *
                                            (100 + value)) /
                                        100
                                    ).toFixed(2),
                                );
                        break;
                    case 'netHeatingCost':
                        newRealEstatePriceObject.price['grossHeatingCost'] =
                            parseFloat(
                                (
                                    (value *
                                        (100 +
                                            showRealEstateObject.price
                                                .heatingCostTax)) /
                                    100
                                ).toFixed(2),
                            );
                        break;
                    case 'heatingCostTax':
                        if (showRealEstateObject.price.netHeatingCost !== null)
                            newRealEstatePriceObject.price['grossHeatingCost'] =
                                parseFloat(
                                    (
                                        (showRealEstateObject.price
                                            .netHeatingCost *
                                            (100 + value)) /
                                        100
                                    ).toFixed(2),
                                );
                        break;
                    case 'netWarmWaterCost':
                        newRealEstatePriceObject.price['grossWarmWaterCost'] =
                            parseFloat(
                                (
                                    (value *
                                        (100 +
                                            showRealEstateObject.price
                                                .warmWaterCostTax)) /
                                    100
                                ).toFixed(2),
                            );
                        break;
                    case 'warmWaterCostTax':
                        if (
                            showRealEstateObject.price.netWarmWaterCost !== null
                        )
                            newRealEstatePriceObject.price[
                                'grossWarmWaterCost'
                            ] = parseFloat(
                                (
                                    (showRealEstateObject.price
                                        .netWarmWaterCost *
                                        (100 + value)) /
                                    100
                                ).toFixed(2),
                            );
                        break;
                    case 'netCoolingCost':
                        newRealEstatePriceObject.price['grossCoolingCost'] =
                            parseFloat(
                                (
                                    (value *
                                        (100 +
                                            showRealEstateObject.price
                                                .coolingCostTax)) /
                                    100
                                ).toFixed(2),
                            );
                        break;
                    case 'coolingCostTax':
                        if (showRealEstateObject.price.netCoolingCost !== null)
                            newRealEstatePriceObject.price['grossCoolingCost'] =
                                parseFloat(
                                    (
                                        (showRealEstateObject.price
                                            .netCoolingCost *
                                            (100 + value)) /
                                        100
                                    ).toFixed(2),
                                );
                        break;
                    case 'netMaintenanceCost':
                        newRealEstatePriceObject.price['grossMaintenanceCost'] =
                            parseFloat(
                                (
                                    (value *
                                        (100 +
                                            showRealEstateObject.price
                                                .maintenanceCostTax)) /
                                    100
                                ).toFixed(2),
                            );
                        break;
                    case 'maintenanceCostTax':
                        if (
                            showRealEstateObject.price.netMaintenanceCost !==
                            null
                        )
                            newRealEstatePriceObject.price[
                                'grossMaintenanceCost'
                            ] = parseFloat(
                                (
                                    (showRealEstateObject.price
                                        .netMaintenanceCost *
                                        (100 + value)) /
                                    100
                                ).toFixed(2),
                            );
                        break;
                    case 'netOtherCost':
                        newRealEstatePriceObject.price['grossOtherCost'] =
                            parseFloat(
                                (
                                    (value *
                                        (100 +
                                            showRealEstateObject.price
                                                .otherCostTax)) /
                                    100
                                ).toFixed(2),
                            );
                        break;
                    case 'otherCostTax':
                        if (showRealEstateObject.price.netOtherCost !== null)
                            newRealEstatePriceObject.price['grossOtherCost'] =
                                parseFloat(
                                    (
                                        (showRealEstateObject.price
                                            .netOtherCost *
                                            (100 + value)) /
                                        100
                                    ).toFixed(2),
                                );
                        break;
                    case 'netElevatorCost':
                        newRealEstatePriceObject.price['grossElevatorCost'] =
                            parseFloat(
                                (
                                    (value *
                                        (100 +
                                            showRealEstateObject.price
                                                .elevatorCostTax)) /
                                    100
                                ).toFixed(2),
                            );
                        break;
                    case 'elevatorCostTax':
                        if (showRealEstateObject.price.netElevatorCost !== null)
                            newRealEstatePriceObject.price[
                                'grossElevatorCost'
                            ] = parseFloat(
                                (
                                    (showRealEstateObject.price
                                        .netElevatorCost *
                                        (100 + value)) /
                                    100
                                ).toFixed(2),
                            );
                        break;
                    case 'netRentCost':
                        newRealEstatePriceObject.price['grossRentCost'] =
                            parseFloat(
                                (
                                    (value *
                                        (100 +
                                            showRealEstateObject.price
                                                .rentCostTax)) /
                                    100
                                ).toFixed(2),
                            );
                        break;
                    case 'rentCostTax':
                        if (showRealEstateObject.price.netRentCost !== null)
                            newRealEstatePriceObject.price['grossRentCost'] =
                                parseFloat(
                                    (
                                        (showRealEstateObject.price
                                            .netRentCost *
                                            (100 + value)) /
                                        100
                                    ).toFixed(2),
                                );
                        break;
                    case 'netPurchasePrice':
                        newRealEstatePriceObject.price['grossPurchasePrice'] =
                            parseFloat(
                                (
                                    (value *
                                        (100 +
                                            showRealEstateObject.price
                                                .purchasePriceTax)) /
                                    100
                                ).toFixed(2),
                            );
                        break;
                    case 'purchasePriceTax':
                        if (
                            showRealEstateObject.price.netPurchasePrice !== null
                        )
                            newRealEstatePriceObject.price[
                                'grossPurchasePrice'
                            ] = parseFloat(
                                (
                                    (showRealEstateObject.price
                                        .netPurchasePrice *
                                        (100 + value)) /
                                    100
                                ).toFixed(2),
                            );
                        break;
                } //set totalCost field
                newRealEstatePriceObject.price['totalCost'] =
                    (showRealEstateObject.parkingOptional === true
                        ? 0
                        : newRealEstatePriceObject.price['grossParkingCost']) +
                    (showRealEstateObject.garageOptional === true
                        ? 0
                        : newRealEstatePriceObject.price['grossGarageCost']) +
                    (showRealEstateObject.operationalCostOptional === true
                        ? 0
                        : newRealEstatePriceObject.price[
                              'grossOperatingCost'
                          ]) +
                    newRealEstatePriceObject?.price['grossExtraCost'] +
                    newRealEstatePriceObject.price['grossHeatingCost'] +
                    newRealEstatePriceObject.price['grossWarmWaterCost'] +
                    newRealEstatePriceObject.price['grossCoolingCost'] +
                    newRealEstatePriceObject.price['grossMaintenanceCost'] +
                    newRealEstatePriceObject.price['grossOtherCost'] +
                    (showRealEstateObject.elevatorCostOptional === true
                        ? 0
                        : newRealEstatePriceObject.price['grossElevatorCost']);
                setShowRealEstateObject((realEstateObject) => ({
                    ...realEstateObject,
                    newRealEstatePriceObject,
                }));
                break;
            case 'other': //other fields
                let newRealEstateOtherObject = { ...showRealEstateObject };
                newRealEstateOtherObject.other[property] = value;
                setShowRealEstateObject((realEstateObject) => ({
                    ...realEstateObject,
                    newRealEstateOtherObject,
                }));
                break;
            case 'internal': //internal fields
                let newRealEstateInternObject = { ...showRealEstateObject };
                newRealEstateInternObject.internal[property] = value;
                setShowRealEstateObject((realEstateObject) => ({
                    ...realEstateObject,
                    newRealEstateInternObject,
                }));
                break;
            default:
                let newRealEstateObject = { ...showRealEstateObject };

                console.log(prop, property, value);

                switch (prop) {
                    case 'energyCertificateValidFrom':
                        console.log('aaaa');
                        newRealEstateObject['energyCertificateDateOfExpiry'] =
                            moment(value).add(10, 'year').toDate();
                        console.log(newRealEstateObject);
                        break;
                }

                setShowRealEstateObject((realEstateObject) => ({
                    ...realEstateObject,
                    ...newRealEstateObject,
                    [prop]: value,
                }));
        }
    };

    const handleUpdatePrice = (value) => {
        setShowRealEstateObject((realEstateObject) => ({
            ...realEstateObject,
            price: {
                ...realEstateObject.price,
                priceType: value,
            },
        }));
    };

    const updateAcquisition = (value) => {
        setShowRealEstateObject((realEstateObject) => ({
            ...realEstateObject,
            acquisitionType: value,
        }));
    };

    const handleFurnishingState = (furnishingStateClone) => {
        let newRealEstateFurnishingObject = { ...showRealEstateObject };

        const flatFurnishing = furnishingStateClone.flat(1);
        const result = {};

        for (const furnishingCategory of flatFurnishing) {
            result[furnishingCategory.attribute] = {};

            for (const furnishingField of furnishingCategory.fields) {
                result[furnishingCategory.attribute][
                    furnishingField.attribute
                ] = furnishingField.value;
            }
        }
        newRealEstateFurnishingObject.furnishing = {
            ...newRealEstateFurnishingObject.furnishing,
            ...result,
        };

        setShowRealEstateObject(newRealEstateFurnishingObject);
    };

    const handleDataAndLinksState = (dataAndLinksStateClone) => {
        let newRealEstateDataAndLinksObject = { ...showRealEstateObject };

        newRealEstateDataAndLinksObject.files = {
            ...newRealEstateDataAndLinksObject.files,
            ...dataAndLinksStateClone,
        };

        setShowRealEstateObject(newRealEstateDataAndLinksObject);
    };

    if (
        employeeList.length === 0 ||
        // contactList.length === 0 ||
        data === undefined ||
        showRealEstateObject === undefined
    ) {
        return (
            <div className="center-spinner">
                <ProgressSpinner strokeWidth="5" animationDuration="1.5s" />
            </div>
        );
    } else {
        return (
            <div className="view-object-container">
                <Toast ref={toast} position={'top-right'} />
                {duplicateModalVisible && (
                    <ConfirmDuplicateModal
                        showModal={showDuplicateModal}
                        toast={toast}
                        propertyId={showRealEstateObject.rootId}
                    />
                )}
                <ConfirmDialog />
                <div className="action-menu">
                    <ActionMenu
                        menu={[
                            {
                                icon: 'pi pi-check-square',
                                label: t('Authorize Export'),
                                className: `${
                                    showRealEstateObject.readyForExport
                                        ? 'property-export-ready'
                                        : ''
                                }`,
                                command: () => {
                                    confirmExportDialog();
                                },
                            },
                            // {
                            //     icon: 'pi pi-sync',
                            //     label: t('Erneuern'),
                            //     command: () => {
                            //         exportService
                            //             .exportOpenimmo(data.rootId)
                            //             .then()
                            //             .catch((err) => console.log(err));
                            //
                            //         exportService
                            //             .exportImmoscout(data.rootId)
                            //             .then()
                            //             .catch((err) => console.log(err));
                            //     },
                            // },
                            {
                                icon: 'pi pi-clone',
                                label: t('Duplizieren'),
                                command: () => {
                                    showDuplicateModal();
                                }, // TODO: implement
                            },
                            {
                                icon: 'pi pi-print',
                                label: t('Drucken'),
                                command: () => {}, // TODO: implement
                            },
                            // {
                            //     label: t('Status'),
                            //     items: [
                            //         {
                            //             label: 'Edit',
                            //             icon: 'pi pi-fw pi-pencil',
                            //         },
                            //     ],
                            //     command: () => {}, // TODO: implement
                            // },
                        ]}
                        menuEnd={[
                            {
                                icon: `${
                                    showRealEstateObject?.mediationOrderType !==
                                        null && 'pi pi-briefcase'
                                }`,
                                disabled: true,
                                label: t(
                                    `${
                                        showRealEstateObject?.mediationOrderType !==
                                        null
                                            ? showRealEstateObject?.mediationOrderType
                                            : ''
                                    }`,
                                ),
                            },
                            {
                                label: statusBadge(),
                                disabled: true,
                                className:
                                    data.acquisitionType !== 'Acquisition' &&
                                    'show',
                            },
                            {
                                label: statusBodyTemplate(),
                                disabled: true,
                            },
                        ]}
                    />
                </div>
                <Card className="tab-menu-view-object no-padding">
                    <TabMenu
                        className="tab-menu-items"
                        model={items}
                        activeIndex={activeIndex}
                        onTabChange={(e) => setActiveIndex(e.index)}
                    />
                </Card>
                <div>
                    <div
                        className={
                            activeIndex ===
                            items.findIndex((x) => x.label == t('Ubersicht'))
                                ? 'block'
                                : 'hidden'
                        }
                    >
                        <ObjectSingleView
                            data={data}
                            control={control}
                            errors={errors}
                        />
                    </div>
                    <div
                        className={
                            activeIndex ===
                            items.findIndex((x) => x.label == t('Base data'))
                                ? 'block'
                                : 'hidden'
                        }
                    >
                        <ObjectBaseDataForm
                            realEstateObject={showRealEstateObject}
                            handleUpdateAcquisition={updateAcquisition}
                            updateRealEstateObject={handleRealEstateState}
                            control={control}
                            trigger={trigger}
                            errors={errors}
                            employeeOptions={employeeOptions}
                            contactOptions={contactOptions}
                            showMewContactModal={showMewContactModal}
                            handleShowNewContactModal={
                                handleShowNewContactModal
                            }
                        />
                    </div>
                    <div
                        className={
                            activeIndex ===
                            items.findIndex((x) => x.label == t('Prices'))
                                ? 'block'
                                : 'hidden'
                        }
                    >
                        <ObjectPricesForm
                            realEstateObject={showRealEstateObject}
                            updateRealEstateObject={handleRealEstateState}
                            handleUpdatePrice={handleUpdatePrice}
                            control={control}
                            errors={errors}
                        />
                    </div>
                    <div
                        className={
                            activeIndex ===
                            items.findIndex((x) => x.label == t('Furnishing'))
                                ? 'block'
                                : 'hidden'
                        }
                    >
                        <ObjectFurnishingForm
                            furnishingObject={showRealEstateObject.furnishing}
                            updateFurnishingObject={handleFurnishingState}
                        />
                    </div>
                    <div
                        className={
                            activeIndex ===
                            items.findIndex((x) => x.label == t('Text'))
                                ? 'block'
                                : 'hidden'
                        }
                    >
                        <ObjectTextForm
                            realEstateObject={showRealEstateObject}
                            updateRealEstateObject={handleRealEstateState}
                            control={control}
                            register={register}
                            errors={errors}
                        />
                    </div>
                    <div
                        className={
                            activeIndex ===
                            items.findIndex((x) => x.label == t('Marketing'))
                                ? 'block'
                                : 'hidden'
                        }
                    >
                        <ObjectMarketingForm
                            realEstateObject={showRealEstateObject}
                            updateRealEstateObject={handleRealEstateState}
                        />
                    </div>

                    <div
                        className={
                            activeIndex ===
                            items.findIndex(
                                (x) => x.label == t('Interessenten'),
                            )
                                ? 'block'
                                : 'hidden'
                        }
                    >
                        <MeineInteresentPage rootId={data.rootId} />
                    </div>
                    <div
                        className={
                            activeIndex ===
                            items.findIndex(
                                (x) => x.label == t('Dateien & Links'),
                            )
                                ? 'block'
                                : 'hidden'
                        }
                    >
                        <ObjectDataAndLinksForm
                            dataAndLinksObject={showRealEstateObject.files}
                            updateDataAndLinksObject={handleDataAndLinksState}
                        />
                    </div>
                    <div
                        className={
                            activeIndex ===
                            items.findIndex((x) => x.label == t('Internal'))
                                ? 'block'
                                : 'hidden'
                        }
                    >
                        <ObjectInternForm
                            realEstateObject={showRealEstateObject}
                            updateRealEstateObject={handleRealEstateState}
                            control={control}
                            register={register}
                            errors={errors}
                            contact={contactOptions()}
                        />
                    </div>
                </div>
                <Button
                    className="save-button2"
                    label={t('Save')}
                    disabled={isLoading}
                    onClick={() => setActiveIndex(8)}
                />
            </div>
        );
    }
};

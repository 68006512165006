import './../resources/scss/index.scss';
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import 'react-app-polyfill/ie11';
import './i18n/index';
import { Util } from './util/Util';
import ApplicationWrapper from './application/ApplicationWrapper';
import { MainWrapper } from './application/main/MainWrapper';
import moment from 'moment';

moment.updateLocale('en', {
    week: {
        dow : 1, // Monday is the first day of the week.
    }
});

const init = (): void => {
    const appContainerNode = Util.getAppContainerNode();
    if (!appContainerNode) {
        return;
    }

    ReactDOM.render(
        <ApplicationWrapper></ApplicationWrapper>,
        // <ApplicationWrapper>
        //     <MainWrapper />
        // </ApplicationWrapper>,
        appContainerNode
    );
};

init();

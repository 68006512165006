import React, { useState, useEffect, useRef } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import { Card } from 'primereact/card';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Menubar } from 'primereact/menubar';
import { Toast } from 'primereact/toast';
import Icon from '@mdi/react';
import {
    mdiFlag,
    mdiPhone,
    mdiEmailOutline,
    mdiOfficeBuildingOutline,
    mdiAccount,
} from '@mdi/js';
import CardHeader from '../../components/card/CardHeader';
import { inquiryActivityService } from '../../services/InquiryActivityService';
import { filesService } from '../../services/FilesService';
import { TypeOfInquiryActivity } from '../../models/InquiryActivity';
import './InteressentCommunication.scss';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import dateFormat from 'dateformat';
import { NewMeineInteresentActivityFormModal } from '../../components/NewMeineInteresentActivityFormModal/NewMeineInteresentActivityFormModal';
import { userService } from '../../services/UsersService';
import {
    InquiriesService,
    inquiriesService,
} from '../../services/InquiriesService';
import { IMeineInteresentActivity } from '../../models/MeineInteresentActivity';
import { ExposeModal } from '../../components/ExposeModal/ExposeModal';
import noCoverPhoto from '../../../public/assets/images/no-cover-photo.png';
import { useApplicationContext } from '../../application/ApplicationContext';
import PermissionContainer from '../../core/auth/PermissionContainer';
import { Permissions } from '../../core/auth/Permissions';
import { DomHandler } from 'primereact/utils';
import { useStateCallback } from '../../hooks/StateCallbackHook';
import moment from 'moment';
import { ProgressSpinner } from 'primereact/progressspinner';
import { AcquisitionType } from '../../models/RealEstateObject';
import { TypeOfFirstDateActivity } from '../../models/FirstDateActivity';

export const InteressentCommunication = () => {
    const { t } = useTranslation();

    const location = useLocation();
    const [inquiryActivitiesTableData, setInquiryActivitiesTableData] =
        useState([]);
    const [visibleNewActivity, setVisibleNewActivity] = useStateCallback(false);
    const [visibleExpose, setVisibleExpose] = useStateCallback(false);
    const [typeOfNewActivity, setTypeOfNewActivity] =
        useState<TypeOfInquiryActivity>();
    const [status, setStatus] = useState<number>(0);
    const [actualStatus, setActualStatus] = useState<string>();
    const { appState } = useApplicationContext();
    const [users, setUsers] = useState([]);

    const { id } = useParams<{ id: string }>();
    const [inquiry, setInquiry] = useState(undefined);

    const fetchInquiry = async () => {
        const response = await inquiriesService.getOne(id);
        return response.data;
    };

    const currentUser = users.filter((el) => {
        if (el.id === appState.currentUser.id) return el;
    });

    const termin = inquiryActivitiesTableData.filter(
        (activity) => activity.type === 'Besichtigung',
    );

    const [checkType, setCheckType] = useState('');
    useEffect(() => {
        if (inquiry?.property.propertyTypeId === 6) {
            setCheckType('Wohnflache|Grundstucksflach');
        } else {
            const combinationWonhnflache = [1, 2, 3, 4, 11, 12, 13];
            if (
                !combinationWonhnflache.includes(
                    inquiry?.property.propertyTypeId,
                )
            ) {
                setCheckType('Grundstucksflach');
            } else {
                setCheckType('Wohnflache');
            }
        }
    }, [inquiry?.property.propertyTypeId]);

    useEffect(() => {
        if (inquiry) {
            if (
                inquiry?.status === 'Neu Bold' &&
                appState.currentUser.id === inquiry.property.employee.id
            )
                inquiriesService.updateOne(inquiry.id, t('Neu'));
        }
    }, [actualStatus]);

    useEffect(() => {
        userService
            .getMany()
            .then((response) => {
                if (response && response.data) {
                    setUsers(response.data);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }, []);

    useEffect(() => {
        userService.refreshTokenCheck().then(() => {
            fetchInquiry().then((result) => {
                setInquiry(result);
                //setActualStatus(result.status);
            });
        });
    }, [status]);

    useEffect(() => {
        userService.refreshTokenCheck().then(() => {
            fetchInquiry().then((result) => {
                setActualStatus(result.status);
            });
        });
    }, []);

    useEffect(() => {
        formatTableData();
    }, [inquiry, actualStatus]);

    const formatTableData = () => {
        const newTableData = [];
        if (inquiry) {
            for (const item of inquiry.inquiryActivities.reverse()) {
                newTableData.push({
                    createdAt: new Date(item.createdAt).getTime(),
                    date: moment(item.appointmentDate).format(
                        'DD.MM.YY, HH:mm',
                    ),
                    user: item.user.name,
                    type: t(`${item.type}`),
                    comment:
                        item.type === 'Erstelt'
                            ? inquiry?.message
                            : item.comment,
                    systemComment: item.systemComment,
                });
                setInquiryActivitiesTableData(newTableData);
            }
        }
    };

    const updateActivitiesData = ({
        newActivity,
        activityType,
        cancellationReasonContact,
        cancellationReasonProperty,
    }: IMeineInteresentActivity) => {
        const currentDate = new Date();
        const year = currentDate.getFullYear().toString().substring(2, 4);
        let month = (currentDate.getMonth() + 1).toString();
        let day = currentDate.getDate().toString();
        let hour = currentDate.getHours().toString();
        let minutes = currentDate.getMinutes().toString();
        if (month.length === 1) {
            month = '0' + month;
        }
        if (day.length === 1) {
            day = '0' + day;
        }
        if (hour.length === 1) {
            hour = '0' + hour;
        }
        if (minutes.length === 1) {
            minutes = '0' + minutes;
        }

        if (
            activityType === 'Besichtigung' ||
            activityType === 'Interesse' ||
            activityType === 'Kaufinteresse' ||
            activityType === 'Kaufabsicht' ||
            activityType === 'Reservierung' ||
            activityType === 'Abgeschlossen' ||
            activityType === 'Kaufanbot' ||
            activityType === 'Verkauft GHome'
        ) {
            setActualStatus(activityType);
        }

        let newActivityDate =
            day + '/' + month + '/' + year + ', ' + hour + ':' + minutes;

        const newTableData = inquiryActivitiesTableData;

        const systemComment = newActivity.systemComment ?? '';

        if (newActivity) {
            newTableData.unshift({
                createdAt: currentDate,
                date: `${moment(newActivity.appointmentDate).format(
                    'DD.MM.YY, ',
                )}${newActivity.time}`,
                user: currentUser[0]?.name,
                type: t(
                    `${
                        activityType === 'Besichtigung'
                            ? 'Besichtigung'
                            : typeOfNewActivity
                    }`,
                ),
                comment: newActivity.comment,
                systemComment: systemComment,
            });
            const newInquiryActivity = {
                appointmentDate: `${newActivity.appointmentDate}`,
                date:
                    newActivity.date ??
                    moment(newActivity.date).format('DD/MM/YY, HH:mm'),
                type:
                    activityType !== undefined
                        ? activityType
                        : typeOfNewActivity,
                systemComment: systemComment,
                comment: newActivity.comment,
                cancellationReasonContact: cancellationReasonContact,
                cancellationReasonProperty: cancellationReasonProperty,
            };

            if (newInquiryActivity.type === 'Tel. RR') {
                setActualStatus('Abgeschlossen');
            } else if (newInquiryActivity.type === 'Conf. App.') {
                setActualStatus('Besichtigung');
            } else if (newInquiryActivity.type === 'Appoint. Held') {
                setActualStatus(newInquiryActivity.systemComment);
            }

            setInquiryActivitiesTableData(newTableData); //update inquiryActivitiesTableData
            inquiryActivityService
                .updateOne(inquiry.id, newInquiryActivity)
                .then((response) => {
                    setStatus(response.status);
                })
                .catch((err) => setStatus(err.response.status));
        }
    };

    const toast = useRef(null);
    useEffect(() => {
        if (status === 201) {
            toast.current.show({
                severity: 'success',
                detail: t(`Activity has been saved successfully`),
                life: 3000,
            });
            setStatus(0);
        }
        if (status === 400) {
            toast.current.show({
                severity: 'error',
                detail: t(`fill all required fields!`),
                life: 3000,
            });
            setStatus(0);
        }
        if (status === 500) {
            toast.current.show({
                severity: 'error',
                detail: t(`internal server error`),
                life: 3000,
            });
            setStatus(0);
        }
    }, [status]);

    const showModal = () => {
        setVisibleNewActivity(!visibleNewActivity, (state) => {
            if (!state) {
                DomHandler.removeClass(document.body, 'p-overflow-hidden');
            }
        });
    };

    const showExposeModal = () => {
        setVisibleExpose(!visibleExpose, (state) => {
            if (!state) {
                DomHandler.removeClass(document.body, 'p-overflow-hidden');
            }
        });
    };

    const activityMenuItems = [
        {
            label: t('Communications'),
            items: [
                {
                    icon: 'bi bi-telephone-inbound-fill',
                    label: t('Tel. Inc.'),
                    command: () => {
                        setTypeOfNewActivity(
                            TypeOfInquiryActivity.TELEPHONE_PASSIVE,
                        );
                        showModal();
                    },
                },
                {
                    icon: 'bi bi-telephone-outbound-fill',
                    label: t('Tel. Out'),
                    command: () => {
                        setTypeOfNewActivity(
                            TypeOfInquiryActivity.TELEPHONE_ACTIVE,
                        );
                        showModal();
                    },
                },
                {
                    icon: 'bi bi-telephone-x-fill',
                    label: t('Tel. NE'),
                    command: () => {
                        setTypeOfNewActivity(
                            TypeOfInquiryActivity.TELEPHONE_NE,
                        );
                        showModal();
                    },
                },
                {
                    icon: 'bi bi-envelope-fill',
                    label: t('Mail RR'), //Keine Tel. Nr. - Mail
                    command: () => {
                        setTypeOfNewActivity(
                            TypeOfInquiryActivity.TELEPHONE_RR,
                        );
                        showModal();
                    },
                },
                {
                    icon: 'bi bi-envelope-fill',
                    label: t('Mail Inc.'),
                    command: () => {
                        setTypeOfNewActivity(
                            TypeOfInquiryActivity.MAIL_PASSIVE,
                        );
                        showModal();
                    },
                },
                {
                    icon: 'bi bi-envelope-fill',
                    label: t('Mail Out'),
                    command: () => {
                        setTypeOfNewActivity(TypeOfInquiryActivity.MAIL_ACTIVE);
                        showModal();
                    },
                },
                {
                    icon: 'bi bi-chat-left-dots-fill',
                    label: t('Comment'),
                    command: () => {
                        setTypeOfNewActivity(TypeOfInquiryActivity.COMMENT);
                        showModal();
                    },
                },
            ],
        },
        {
            label: t('Expose'),
            items: [
                {
                    icon: 'bi bi-send-fill',
                    label: t('Versand'),
                    command: () => {
                        setTypeOfNewActivity(
                            TypeOfInquiryActivity.EXPOSE_SHIPMENT,
                        );
                        showModal();
                    },
                },
                {
                    icon: 'bi bi-file-richtext-fill',
                    label: t('Anzeigen'),
                    command: () => {
                        setTypeOfNewActivity(TypeOfInquiryActivity.EXPOSE_SHOW);
                        showExposeModal();
                    },
                },
            ],
        },
        {
            label: t('Besichtigung'),
            items: [
                {
                    icon: 'bi bi-calendar-plus-fill',
                    label: t('Conf. App.'),
                    command: () => {
                        setTypeOfNewActivity(
                            TypeOfInquiryActivity.CONFIRM_APPOINTMENT,
                        );
                        showModal();
                    },
                },
                // {
                //     label: t('Print Visit. Form'),
                //     command: () => {
                //         setTypeOfNewActivity(
                //             TypeOfInquiryActivity.PRINT_VISITING_FORM,
                //         );
                //         showModal();
                //     },
                // },
                {
                    icon: 'bi bi-calendar-check-fill',
                    label: t('Appoint. Held'),
                    command: () => {
                        setTypeOfNewActivity(TypeOfInquiryActivity.TERMIN_HELD);
                        showModal();
                    },
                },
                {
                    icon: 'bi bi-calendar-x-fill',
                    label: t('App. Not held'),
                    command: () => {
                        setTypeOfNewActivity(
                            TypeOfInquiryActivity.TERMIN_NOT_HELD,
                        );
                        showModal();
                    },
                },
                {
                    icon: 'bi bi-chat-left-dots-fill',
                    label: t('Feedback to Seller'),
                    command: () => {
                        setTypeOfNewActivity(
                            TypeOfInquiryActivity.FEEDBACK_TO_SELLER,
                        );
                        showModal();
                    },
                },
                {
                    icon: 'bi bi-calendar-plus-fill',
                    label: t('Sec. App.'),
                    command: () => {
                        setTypeOfNewActivity(
                            TypeOfInquiryActivity.SECOND_APPOINTMENT,
                        );
                        showModal();
                    },
                },
            ],
        },
        {
            label: t('Offer'),
            items: [
                {
                    icon: 'bi bi-graph-up-arrow',
                    label: t('Result'),
                    command: () => {
                        setTypeOfNewActivity(TypeOfInquiryActivity.RESULT);
                        showModal();
                    },
                },
            ],
        },
    ];

    const splitStatus = !visibleNewActivity
        ? typeOfNewActivity?.split(' ')
        : null;

    const splitActualStatus = !visibleNewActivity
        ? t(actualStatus)?.split(' ')
        : null;

    const adminStatuses = [];

    if (appState.currentUser.role.name.toLowerCase().includes('admin')) {
        adminStatuses.push({
            label: 'Besichtigung',
            command: () => {
                setTypeOfNewActivity(TypeOfInquiryActivity.FD_SIGHTSEEING);
                setActualStatus(TypeOfInquiryActivity.FD_SIGHTSEEING);
                showModal();
            },
        });
    }

    const activityMenuEndItem = [
        {
            label: `${'Status'} - ${
                actualStatus === 'Neu Bold' ? 'Brandneu' : t(actualStatus)
            }`,
            className: `${
                splitActualStatus?.join('-') || splitStatus?.join('-')
            }`,
            items: [
                {
                    label: t('Neu'),
                    command: () => {
                        setTypeOfNewActivity(TypeOfInquiryActivity.FD_NEW);
                        setActualStatus(TypeOfInquiryActivity.FD_NEW);
                        showModal();
                    },
                },
                {
                    label: t('In Bearbeitung'),
                    command: () => {
                        setTypeOfNewActivity(
                            TypeOfInquiryActivity.FD_IN_PROCESSING,
                        );
                        setActualStatus(TypeOfInquiryActivity.FD_IN_PROCESSING);
                        showModal();
                    },
                },
                {
                    label: 'Interesse',
                    command: () => {
                        setTypeOfNewActivity(
                            TypeOfInquiryActivity.FD_INTERESTED,
                        );
                        setActualStatus(TypeOfInquiryActivity.FD_INTERESTED);
                        showModal();
                    },
                },
                {
                    label: 'Kaufinteresse',
                    command: () => {
                        setTypeOfNewActivity(
                            TypeOfInquiryActivity.FD_INTERESTED_IN_BUYING,
                        );
                        setActualStatus(
                            TypeOfInquiryActivity.FD_INTERESTED_IN_BUYING,
                        );
                        showModal();
                    },
                },
                {
                    label: 'Kaufabsicht',
                    command: () => {
                        setTypeOfNewActivity(
                            TypeOfInquiryActivity.FD_PURCHASE_INTENT,
                        );
                        setActualStatus(
                            TypeOfInquiryActivity.FD_PURCHASE_INTENT,
                        );
                        showModal();
                    },
                },
                {
                    label: 'Reservierung',
                    command: () => {
                        setTypeOfNewActivity(
                            TypeOfInquiryActivity.FD_RESERVATION,
                        );
                        setActualStatus(TypeOfInquiryActivity.FD_RESERVATION);
                        showModal();
                    },
                },
                {
                    label: 'Kauf bei GH',
                    command: () => {
                        setTypeOfNewActivity(TypeOfInquiryActivity.FD_SALE_GH);
                        setActualStatus(TypeOfInquiryActivity.FD_SALE_GH);
                        showModal();
                    },
                },
                {
                    label: t('Abgeschlossen'),
                    command: () => {
                        setTypeOfNewActivity(TypeOfInquiryActivity.FD_CLOSED);
                        setActualStatus(TypeOfInquiryActivity.FD_CLOSED);
                        showModal();
                    },
                },
                ...adminStatuses,
            ],
        },
    ];

    const history = useHistory();
    const openAllRelatedProperties = () => {
        history.push({
            pathname: `/objects`,
            search: `?buyerId=${inquiry?.contact?.id}`,
            state: inquiry?.contact?.inquiries,
        });
        return;
    };

    ////// SORT DATE IN COLUMN

    const [sortedData, setSortedData] = useState<any>([]);

    useEffect(() => {
        const filtered = inquiryActivitiesTableData.sort((a, b) =>
            moment(a.createdAt, 'DD.MM.YYYY, hh:mm:ss').diff(
                moment(b.createdAt, 'DD.MM.YYYY, hh:mm:ss'),
            ),
        );

        setSortedData(filtered);
    }, [inquiryActivitiesTableData]);

    const renderSystemComment = (rowData) => {
        if (
            (rowData.type === 'Terminbestätigung' ||
                rowData.type === 'Zweitbesichtigung') &&
            rowData.systemComment
        ) {
            const parts = rowData.systemComment.split(': ');
            return `${parts[0]}: ${moment(`${parts[1]}`).format(
                'DD.MM.YYYY HH:mm',
            )}`;
        }

        return rowData.systemComment;
    };

    return inquiry ? (
        <>
            <div className="contact-property-wrapper">
                <Toast ref={toast} position={'top-right'} />
                <div className="three-col">
                    <Card
                        header={
                            <CardHeader
                                title={t('Interessent')}
                                className={'card-header-background'}
                            />
                        }
                    >
                        <div className="contact-section">
                            <div className="center-text">
                                <span>
                                    <strong>{t('ID')}:</strong>
                                    &nbsp;
                                    {inquiry.contact?.id}
                                </span>
                            </div>
                            <div className="center-text">
                                <span>
                                    <strong>{t('Name')}:</strong>
                                    &nbsp;
                                    {t(inquiry.contact?.salutation)}
                                    &nbsp;
                                    {inquiry.contact?.firstName +
                                        ' ' +
                                        inquiry.contact?.lastName}
                                </span>
                            </div>
                            {/* <div>
                                <span className="vertical-align">
                                    <Icon path={mdiNoteOutline} size={1} />
                                </span>
                                &nbsp;&nbsp;
                                {t('Saglasnost za ponude: NE')}
                            </div> */}
                            <div className="center-text">
                                <strong>{t('Telefonnummer')}:</strong>
                                &nbsp;
                                {inquiry.contact?.privatePhone}
                            </div>
                            <div className="center-text">
                                <strong>{t('Emails')}:</strong>
                                &nbsp;
                                {inquiry.contact?.emails.length !== 0
                                    ? inquiry.contact?.emails[0].email
                                    : ''}
                            </div>

                            <div className="center-text">
                                <strong>{t('Firma / kompanija')}:</strong>
                                &nbsp;
                                {inquiry.contact?.company}
                            </div>
                            <div className="center-text">
                                <strong>{t('Adresa')}:</strong>
                                &nbsp;
                                {`${
                                    (inquiry.contact?.address ?? '') +
                                    (' ' + inquiry.contact?.houseNumber ?? '') +
                                    (inquiry.contact?.apartmentNumber
                                        ? '/' + inquiry.contact?.apartmentNumber
                                        : '') +
                                    (', ' + inquiry.contact?.postalCode ?? '') +
                                    (' ' + inquiry.contact?.city ?? '')
                                }`}
                            </div>
                            {/* <div>
                                <span className="vertical-align">
                                    <Icon path={mdiEmailOutline} size={1} />
                                </span>
                                &nbsp;&nbsp;
                                {t('Add mail')}
                            </div> */}
                        </div>
                        <div className="show-link-contact">
                            <Link
                                to={{
                                    pathname: `/contact/${inquiry.contact?.id}`,
                                    state: { firstDate: id },
                                }}
                            >
                                {t('Prikazi kontakt')}
                            </Link>
                        </div>
                    </Card>
                    <Card
                        header={
                            <CardHeader
                                title={t('Nekretnina')}
                                // titleRight={
                                //     <button
                                //         aria-label="Alle Objekte"
                                //         className="p-button p-component new-first-date-button"
                                //         style={{ margin: '-8px 0' }}
                                //     >
                                //         <span className="p-button-label p-c">
                                //             {t('Alle Objekte') +
                                //                 ' ' +
                                //                 inquiries?.seller?.properties.filter(
                                //                     (x) => x.childId === null,
                                //                 ).length}
                                //         </span>
                                //     </button>
                                // }
                                // onClick={(e) => openAllRelatedProperties()}
                                className={'card-header-background'}
                            />
                        }
                    >
                        <div className="property-section">
                            <span className="property-image">
                                <img
                                    className="cover-photo"
                                    alt={`cover-${inquiry?.property?.rootId}`}
                                    src={
                                        inquiry.property.coverPhoto === null
                                            ? noCoverPhoto
                                            : filesService.getFilePath(
                                                  inquiry.property.coverPhoto
                                                      .id,
                                              )
                                    }
                                />
                                <div
                                    className="center-text"
                                    style={{ marginTop: '5px' }}
                                >
                                    <span className="vertical-align">
                                        <Icon path={mdiAccount} size={1} />
                                    </span>
                                    &nbsp;
                                    {inquiry.property.employee?.name}
                                </div>
                            </span>
                            <div className="property-data">
                                <div>
                                    <span>
                                        <strong>{t('ID')}:</strong>
                                        &nbsp;
                                        {inquiry?.property?.rootId}
                                    </span>
                                </div>
                                <div>
                                    <span>
                                        <strong>{t('Adresa')}:</strong>
                                        &nbsp;
                                        {`${
                                            (inquiry.property.street ?? '') +
                                            ' ' +
                                            (inquiry.property.houseNumber ??
                                                '') +
                                            ' ' +
                                            (inquiry.property.floor ?? '') +
                                            ' ' +
                                            (inquiry.property.place ?? '')
                                        }`}
                                    </span>
                                </div>{' '}
                                <div>
                                    <strong>{`${t('Tip nekretnine')}:`}</strong>
                                    &nbsp;
                                    {inquiry.property.category?.name}
                                </div>
                                <div>
                                    <strong>{`${t('Cena')}:`}</strong>
                                    &nbsp;
                                    {
                                        `${inquiry?.property?.price?.netPurchasePrice?.toLocaleString()} EUR` /* + ' ' + inquiries.property.price?.currency*/
                                    }
                                </div>
                                <div>
                                    <strong>{`${t('Broj soba')}:`}</strong>
                                    &nbsp;
                                    {inquiry.property.surface?.numberOfRooms ??
                                        ' /'}
                                </div>
                                {checkType.includes('Wohnflache') ? (
                                    <div>
                                        <strong>
                                            {t('Stambena povrsina')}:
                                        </strong>
                                        &nbsp;
                                        {inquiry.property.surface?.livingArea
                                            ? `${inquiry.property.surface?.livingArea} m²`
                                            : '/'}
                                    </div>
                                ) : null}
                                {checkType.includes('Grundstucksflach') ? (
                                    <div>
                                        <strong>
                                            {t('Grundstücksfläche')}:
                                        </strong>
                                        &nbsp;
                                        {inquiry.property.surface?.plotArea
                                            ? `${inquiry.property.surface?.plotArea} m²`
                                            : '/'}
                                    </div>
                                ) : null}
                            </div>
                        </div>
                        <div className="show-link">
                            <Link
                                to={{
                                    pathname: `/object/${inquiry?.property?.rootId}`,
                                    state: {
                                        data: [inquiry.property],
                                    },
                                }}
                            >
                                {t('Prikazi objekat')}
                            </Link>
                        </div>
                    </Card>
                    <Card
                        header={
                            <CardHeader
                                title={t('Protokol/Status')}
                                className={'card-header-background'}
                            />
                        }
                    >
                        <div className="protocol">
                            <div className={'contact-section'}>
                                <div>
                                    <strong>{`${t('Dodeljeno')}:`}</strong>
                                    &nbsp;
                                    {dateFormat(
                                        inquiry.property.createdAt,
                                        'dd.mm.yyyy, HH:MM',
                                    ) ?? '/'}
                                </div>
                                <div>
                                    <strong>{t('Zugewiesen von: ')}</strong>
                                    &nbsp;
                                    {inquiry.property.acquisitionType ===
                                    AcquisitionType.ACQUISITION
                                        ? 'Eigenakquise'
                                        : 'Golden Home Akquise'}
                                </div>
                                <div>
                                    <strong>{`${t(
                                        'Prvo pregledavanje',
                                    )}:`}</strong>
                                    &nbsp;
                                    {dateFormat(
                                        inquiry.property.exportedAt,
                                        'dd.mm.yyyy, HH:MM',
                                    ) ?? '/'}
                                </div>
                                <div>
                                    <strong>{`${t(
                                        'Poslednja aktivnost',
                                    )}:`}</strong>
                                    &nbsp;
                                    {/* {inquiries.firstDateActivities.length > 0
                                    ? dateFormat(
                                          inquiries.firstDateActivities[
                                              inquiries.firstDateActivities
                                                  .length - 1
                                          ].createdAt,
                                          'dd.mm.yyyy, HH:MM',
                                      ) ?? '/'
                                    : dateFormat(
                                          inquiries.property.updatedAt,
                                          'dd.mm.yyyy, HH:MM',
                                      ) ?? '/'} */}
                                    {inquiryActivitiesTableData[0]?.date.replaceAll(
                                        '/',
                                        '.',
                                    )}
                                </div>
                                <div>
                                    <strong>{`${t('Status')}:`}</strong>
                                    &nbsp;
                                    {actualStatus ?? ''}
                                </div>
                                <div>
                                    <strong>{`${t('Termin')}:`}</strong>
                                    &nbsp;
                                    {moment(inquiry.appointmentDate).format(
                                        'DD.MM.YYYY, HH:mm',
                                    ) ?? ''}
                                </div>
                            </div>
                        </div>
                    </Card>
                </div>
            </div>
            {/* <div className="status-section">
                <Card
                    header={
                        <CardHeader
                            title={t('Protokol/Status')}
                            className={'card-header-background'}
                        />
                    }
                >
                    <div className="status-data">
                        <div>
                            <div className="status-data-field">
                                {`${t('Dodeljeno')}:`}&nbsp;
                                {dateFormat(
                                    inquiry?.property?.createdAt,
                                    'dd.mm.yyyy, HH:MM',
                                ) ?? '/'}
                            </div>
                            <div>{t('Dodeljeno putem: Sistem')}</div>
                        </div>
                        <div>
                            <div className="status-data-field">
                                {`${t('Prvo pregledavanje')}:`}&nbsp;
                                {dateFormat(
                                    inquiry?.property?.exportedAt,
                                    'dd.mm.yyyy, HH:MM',
                                ) ?? '/'}
                            </div>
                            <div>
                                {`${t('Poslednja aktivnost')}:`}&nbsp;
                                {inquiry.inquiryActivities.length > 0
                                    ? dateFormat(
                                          inquiry.inquiryActivities[
                                              inquiry.inquiryActivities.length -
                                                  1
                                          ].appointmentDate,
                                          'dd.mm.yyyy, HH:MM',
                                      ) ?? '/'
                                    : dateFormat(
                                          inquiry.property.updatedAt,
                                          'dd.mm.yyyy, HH:MM',
                                      ) ?? '/'}
                            </div>
                        </div>
                        <div>
                            <div className="status-data-field">
                                {`${'Status'}:`}&nbsp;
                                {actualStatus ?? ''}
                            </div>
                        </div>
                    </div>
                </Card>
            </div> */}
            <div className="activity-section">
                <Card
                    className={'no-padding'}
                    header={
                        <CardHeader
                            title={t('Aktivnosti/Aktivitäten')}
                            className={'card-header-background'}
                        />
                    }
                >
                    <PermissionContainer
                        hasPermission={Permissions.INQUIRY_CREATE_ACTIVITY}
                        currentUser={appState.currentUser}
                    >
                        <div className="activity-menu-container">
                            <Menubar
                                model={activityMenuItems}
                                end={<Menubar model={activityMenuEndItem} />}
                            />
                        </div>
                    </PermissionContainer>
                    <DataTable
                        columnResizeMode="fit"
                        value={inquiryActivitiesTableData}
                        sortField="createdAt"
                        sortOrder={-1}
                        className="p-datatable-lg"
                    >
                        <Column
                            field="createdAt"
                            header={t('Date')}
                            sortable
                            body={(rowData) => {
                                return dateFormat(
                                    rowData.createdAt,
                                    'dd.mm.yyyy, HH:MM:ss',
                                );
                            }}
                        />
                        <Column field="user" header={t('Korisnik')} />
                        <Column field="type" header={t('Akcija')} />
                        <Column
                            header={t('Komentar')}
                            style={{
                                whiteSpace: 'pre-wrap',
                                width: '30%',
                            }}
                            body={(rowData) => (
                                <>
                                    {rowData.comment ===
                                    'Automatische Statusänderung' ? (
                                        <span
                                            style={{
                                                opacity: '0.5',
                                                fontStyle: 'italic',
                                            }}
                                        >
                                            {rowData.comment}
                                        </span>
                                    ) : (
                                        rowData.comment
                                    )}
                                </>
                            )}
                        />
                        <Column
                            body={renderSystemComment}
                            header={''}
                            style={{ whiteSpace: 'pre-wrap' }}
                        />
                    </DataTable>
                    {visibleNewActivity ? (
                        <>
                            <NewMeineInteresentActivityFormModal
                                showModal={showModal}
                                typeOfNewActivity={typeOfNewActivity}
                                visibleNewActivity={visibleNewActivity}
                                updateActivitiesData={updateActivitiesData}
                                contactId={inquiry?.contact?.id}
                                propertyId={inquiry?.property?.rootId}
                                contactName={`${inquiry?.contact?.firstName} ${inquiry?.contact?.lastName}`}
                                title={inquiry?.property.text?.title}
                                agentName={inquiry?.property?.employee.name}
                                agentPhone={
                                    inquiry?.property?.employee.phoneNumber
                                }
                            />
                        </>
                    ) : null}
                    {visibleExpose ? (
                        <>
                            <ExposeModal
                                showModal={showExposeModal}
                                visibleModal={visibleExpose}
                                propertyId={inquiry.property.rootId}
                            />
                        </>
                    ) : null}
                </Card>
            </div>
        </>
    ) : (
        <div className="center-spinner">
            <ProgressSpinner strokeWidth="5" animationDuration="1.5s" />
        </div>
    );
};

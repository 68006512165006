import { Config } from '../Config';
import { CrudService } from './CrudService';
import { AxiosResponse } from "axios";
import { CreateQueryParams } from '@nestjsx/crud-request';
import { IStatistics } from '../models/Statistics';
import { axiosDefaults } from './AxiosDefaults';

export class StatisticsService extends CrudService<IStatistics, 'Statistics'> {

    protected getUrlPath(): string {
        return '/statistics';
    };

    public async getOne(id: string | number): Promise<AxiosResponse<IStatistics>> {
        const inquiryResponse = await super.getOne(id);
        return new Promise((resolve: (inquiry) => void, reject: () => void) => {resolve(inquiryResponse)})
    };

    public async getMany(params?: CreateQueryParams): Promise<AxiosResponse<any[]>> {
        const inquiriesResponse = await super.getMany(params);
        return new Promise((resolve: (inquiry) => void, reject: () => void) => {resolve(inquiriesResponse)})
    };

    public async createOne(Statistics: IStatistics): Promise<AxiosResponse<IStatistics>> {
        let body/*:FirstDate*/ = {/*TODO*/};
        let newBody = await JSON.stringify(body);
        return this.axiosInstance.post(
            axiosDefaults.baseURL + this.getUrlPath(),
            newBody,
            {withCredentials: true, headers: {'Content-Type': 'application/json'}},
        );
    };

    public async updateOne(id: string, status: IStatistics): Promise<AxiosResponse<IStatistics>> {
        let body = {status};
        let newBody = await JSON.stringify(body);
        return this.axiosInstance.put(
            axiosDefaults.baseURL + this.getUrlPath() + '/' + id,
            newBody,
            {withCredentials: true, headers: {'Content-Type': 'application/json'}},
        );
    };

    public deleteOne(id: string | number): Promise<AxiosResponse<IStatistics>> {
        return this.axiosInstance.delete(axiosDefaults.baseURL + this.getUrlPath() + "/" + id);
    };
};

export const StatisticService = new StatisticsService();

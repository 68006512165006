import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import CustomObjectInput from '../objects/customObjectInput/CustomObjectInput';
import './CalendarEventModal.scss';
import { calendarEventStatus } from '../../models/CalendarFormModal';
import { contactsService } from '../../services/ContactsService';
import { propertiesService } from '../../services/PropertiesService';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { useTranslation } from 'react-i18next';
import PermissionContainer from '../../core/auth/PermissionContainer';
import { Permissions } from '../../core/auth/Permissions';
import { useApplicationContext } from '../../application/ApplicationContext';
import moment from 'moment';

import 'flatpickr/dist/themes/material_blue.css';
import Flatpickr from 'react-flatpickr';
import flatpickr from 'flatpickr';
import locale from 'flatpickr/dist/l10n/de';
import classNames from 'classnames';
import { useHistory } from 'react-router-dom';
import { DomHandler } from 'primereact/utils';

flatpickr.localize(locale.de);

const CalendarEventModal = ({
    showEvent,
    event,
    deleteEvent,
    editCalendarForm,
    editcalendarFormModalObject,
    editEvent,
    toast,
}) => {
    const { appState } = useApplicationContext();
    const [contactList, setContactList] = useState([]);
    const [properties, setProperties] = useState([]);
    const { t } = useTranslation();

    useEffect(() => {
        fetchContacts().then((result) => setContactList(result));
        fetchProperties().then((result) => setProperties(result));
    }, []);

    const fetchProperties = async () => {
        const response = await propertiesService.getMany();
        return response.data;
    };

    const fetchContacts = async () => {
        const response = await contactsService.getMany();
        return response.data;
    };

    const formatPropertyText = (property) => {
        let result = '';

        result += property.rootId;
        result += ', ';
        result += property.place;

        if (property.street) {
            result += ' ';
            result += property.street;
        }

        if (property.houseNumber) {
            result += ' ';
            result += property.houseNumber;
        }

        if (property.seller) {
            result += ', ';
            result += property.seller.firstName;
            result += ' ';
            result += property.seller.lastName;
        }

        return result;
    };

    const propertiesOptions = () => {
        const allProperties = properties.map((property) => ({
            label: formatPropertyText(property),
            value: property.rootId,
        }));
        return allProperties;
    };

    const formatContactText = (contact) => {
        let result = '';

        result += contact.id;
        result += '. ';

        if (contact.firstName) {
            result += ' ';
            result += contact.firstName;
        }

        if (contact.lastName) {
            result += ' ';
            result += contact.lastName;
        }

        if (contact.postalCode) {
            result += ', ';
            result += contact.postalCode;
        }

        if (contact.city) {
            result += ', ';
            result += contact.city;
        }

        if (contact.address) {
            result += ', ';
            result += contact.address;
        }

        return result;
    };

    const contactOptions = () => {
        const allContacts = contactList.map((contact) => ({
            label: formatContactText(contact),
            value: contact.id,
        }));
        return allContacts.sort((a: any, b: any) => {
            const aId = parseInt(a.label.split(',')[0]);
            const bId = parseInt(b.label.split(',')[0]);

            return aId - bId;
        });
    };

    const {
        control,
        formState: { errors },
        register,
    } = useForm({
        mode: 'onBlur',
    });

    const history = useHistory();

    const footer = (
        <div>
            <Button
                label={t('View Contact')}
                className="p-button-info"
                onClick={() => {
                    DomHandler.removeClass(document.body, 'p-overflow-hidden');
                    history.push({
                        pathname: `/contact/${event[0].contact.id}`,
                    });
                    return;
                }}
            />
            <Button
                label={t('View Object')}
                className="p-button-info"
                onClick={() => {
                    DomHandler.removeClass(document.body, 'p-overflow-hidden');
                    history.push({
                        pathname: `/object/${event[0].property.rootId}`,
                    });
                    return;
                }}
            />
            <PermissionContainer
                hasPermission={Permissions.CALENDAR_UPDATE}
                currentUser={appState.currentUser}
            >
                <Button
                    label={t('Speichern')}
                    className="p-button-success"
                    icon="pi pi-pencil"
                    onClick={() => {
                        editEvent(event[0].id);
                        toast.current.show({
                            severity: 'success',
                            detail: t(`The event has been successfully edited`),
                            life: 3000,
                        });
                    }}
                />
            </PermissionContainer>
            {/*<PermissionContainer*/}
            {/*    hasPermission={Permissions.CALENDAR_DELETE}*/}
            {/*    currentUser={appState.currentUser}*/}
            {/*>*/}
            {/*    <Button*/}
            {/*        label={t('Delete')}*/}
            {/*        className="p-button-danger"*/}
            {/*        icon="pi pi-trash"*/}
            {/*        onClick={() => {*/}
            {/*            deleteEvent(event[0].id);*/}
            {/*            toast.current.show({*/}
            {/*                severity: 'success',*/}
            {/*                detail: t(*/}
            {/*                    `The event has been successfully deleted`,*/}
            {/*                ),*/}
            {/*                life: 3000,*/}
            {/*            });*/}
            {/*        }}*/}
            {/*    />*/}
            {/*</PermissionContainer>*/}
        </div>
    );
    //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    const [currentChangeInput, setCurrentChangeInput] = useState('');

    const [editValidFrom, setEditValidFrom] = useState<any>(
        new Date(editcalendarFormModalObject.editValidFrom),
    );

    const [editExpiryDate, setEditExpiryDate] = useState<any>(
        new Date(editcalendarFormModalObject.editExpiryDate),
    );

    useEffect(() => {
        const expiryDate = new Date(editcalendarFormModalObject.editExpiryDate);
        const validFrom = new Date(editcalendarFormModalObject.editValidFrom);

        if (expiryDate < validFrom) {
            if (currentChangeInput == 'editValidFrom') {
                const incrementedDate = moment(validFrom)
                    .add(1, 'hour')
                    .toISOString();

                setEditExpiryDate(incrementedDate);
                editCalendarForm('editExpiryDate', incrementedDate);
            }
            // if (currentChangeInput == 'editExpiryDate') {
            //     const decrementedDateTime = moment(expiryDate)
            //         .subtract(1, 'hour')
            //         .toISOString();
            //     setEditValidFrom(decrementedDateTime);
            //     editCalendarForm('editValidFrom', decrementedDateTime);
            // }
        } else if (expiryDate > validFrom) {
        } else {
            if (currentChangeInput == 'editValidFrom') {
                const incrementedDate = moment(validFrom)
                    .add(1, 'hour')
                    .toISOString();

                setEditExpiryDate(incrementedDate);
                editCalendarForm('editExpiryDate', incrementedDate);
            }
            // if (currentChangeInput == 'editExpiryDate') {
            //     const decrementedDateTime = moment(expiryDate)
            //         .subtract(1, 'hour')
            //         .toISOString();
            //     setEditValidFrom(decrementedDateTime);
            //     editCalendarForm('editValidFrom', decrementedDateTime);
            // }
        }
    }, [editcalendarFormModalObject]);

    const [initialDiffTime, setInitialDiffTime] = useState<any>();

    useEffect(() => {
        if (editValidFrom !== undefined) {
            const date1 = moment(editValidFrom);
            const date2 = moment(editExpiryDate);
            const difference = moment.duration(date2.diff(date1));
            setInitialDiffTime(difference);
        }
    }, []);

    useEffect(() => {
        if (initialDiffTime !== undefined) {
            const resultDate = moment(editValidFrom).add(
                initialDiffTime._milliseconds,
                'milliseconds',
            );
            setEditExpiryDate(resultDate.toISOString());
            editCalendarForm('editExpiryDate', resultDate.toISOString());
        }
    }, [editValidFrom]);

    return (
        <Dialog
            header={t('Termin Details')}
            visible={true}
            style={{ width: '40vw' }}
            className="edit-calendar-modal"
            draggable={false}
            blockScroll
            footer={footer}
            onHide={showEvent}
        >
            <div className="modal-input column-span-2">
                <CustomObjectInput
                    type="text"
                    classes={['country-input']}
                    id="id-input"
                    label={t('Title')}
                    name="title"
                    stateField={editcalendarFormModalObject.title}
                    setStateField={editCalendarForm}
                    errors={errors}
                    control={control}
                    rules={{ required: t('This is required') }}
                />
            </div>
            <div className="modal-input column-span-2">
                <CustomObjectInput
                    type="dropdown"
                    classes={['country-input']}
                    id="activity-type-input"
                    label={t('Event type')}
                    name="activityType"
                    dropdownOptions={[
                        {
                            label: 'First date',
                            value: calendarEventStatus.FIRST_DATE,
                        },
                        {
                            label: 'Interessent',
                            value: calendarEventStatus.Interessent,
                        },
                        // {
                        //     label: 'Anfrage',
                        //     value: calendarEventStatus.INQUIRY,
                        // },
                        {
                            label: 'Privat',
                            value: calendarEventStatus.PRIVATE,
                        },
                        {
                            label: 'Besichtigung',
                            value: calendarEventStatus.Besichtigung,
                        },
                        {
                            label: 'Zweitbesichtigung',
                            value: calendarEventStatus.Zweitbesichtigung,
                        },
                        {
                            label: 'Notar',
                            value: calendarEventStatus.Notar,
                        },
                        {
                            label: 'Servicetermin',
                            value: calendarEventStatus.Servicetermin,
                        },
                        {
                            label: 'Servicetelefonat',
                            value: calendarEventStatus.Servicetelefonat,
                        },
                        {
                            label: 'Büro',
                            value: calendarEventStatus.Buro,
                        },
                        {
                            label: 'Fototermin',
                            value: calendarEventStatus.Fototermin,
                        },
                        {
                            label: 'Sonstige',
                            value: calendarEventStatus.Sonstige,
                        },
                        {
                            label: 'Nachfassen',
                            value: calendarEventStatus.Nachfassen,
                        },
                        {
                            label: 'Telefonat',
                            value: calendarEventStatus.Telefonat,
                        },
                    ]}
                    stateField={editcalendarFormModalObject.activityType}
                    setStateField={editCalendarForm}
                    errors={errors}
                    control={control}
                    rules={{ required: t('This is required') }}
                />
            </div>
            <div className="modal-input column-span-3">
                {/* <CustomObjectInput
                    type="calendar"
                    classes={['valid-from-input', 'generic-input']}
                    id="Edit-date-and-time-from-input"
                    label={t('Date and time from')}
                    name="editValidFrom"
                    timeShow={true}
                    stateField={editValidFrom}
                    setStateField={editCalendarForm}
                    errors={errors}
                    control={control}
                    rules={{ required: t('This is required') }}
                /> */}

                <div className="text-input-label">
                    <label
                        className={classNames({
                            'p-error': errors[t('Date and time from')],
                        })}
                    >
                        {t('Date and time from')}
                    </label>
                </div>

                <div className="data-and-time-flatpicker">
                    <span className="p-button-icon p-c pi pi-calendar"></span>

                    <Flatpickr
                        options={{
                            enableTime: true,
                            time_24hr: true,
                            defaultDate:
                                editValidFrom !== 'Invalid date'
                                    ? editValidFrom
                                    : new Date(),
                            format: 'd.m.Y H:i',
                            altFormat: 'd.m.Y H:i',
                            altInput: true,
                        }}
                        value={
                            editValidFrom !== 'Invalid date'
                                ? editValidFrom
                                : ''
                        }
                        onChange={([e]) => {
                            setCurrentChangeInput('editValidFrom');
                            editCalendarForm('editValidFrom', e);
                            setEditValidFrom(e);
                        }}
                    />
                </div>
            </div>
            <div className="modal-input column-span-3">
                {/* <CustomObjectInput
                    type="calendar"
                    classes={['expiry-date-input', 'generic-input']}
                    id="Edit-date-and-time-to-input"
                    label={t('Date and time to')}
                    name="editExpiryDate"
                    timeShow={true}
                    stateField={editExpiryDate}
                    setStateField={editCalendarForm}
                    errors={errors}
                    control={control}
                    rules={{ required: t('This is required') }}
                /> */}

                <div className="text-input-label">
                    <label
                        className={classNames({
                            'p-error': errors[t('Date and time to')],
                        })}
                    >
                        {t('Date and time to')}
                    </label>
                </div>

                <div className="data-and-time-flatpicker">
                    <span className="p-button-icon p-c pi pi-calendar"></span>
                    <Flatpickr
                        // options={{
                        //     enableTime: true,
                        //     time_24hr: true,
                        //     defaultDate:
                        //         editExpiryDate !== 'Invalid date'
                        //             ? editExpiryDate
                        //             : new Date(),
                        //     format: 'd.m.Y H:i',
                        //     altFormat: 'd.m.Y H:i',
                        //     altInput: true,
                        // }}
                        options={{
                            enableTime: true,
                            time_24hr: true,
                            defaultDate:
                                editValidFrom !== 'Invalid date'
                                    ? editValidFrom
                                    : new Date(),
                            format: 'd.m.Y H:i',
                            altFormat: 'd.m.Y H:i',
                            altInput: true,
                            minDate: editValidFrom,
                        }}
                        value={
                            editExpiryDate !== 'Invalid date'
                                ? editExpiryDate
                                : ''
                        }
                        onChange={([e]) => {
                            setCurrentChangeInput('editExpiryDate');
                            editCalendarForm('editExpiryDate', e);
                        }}
                    />
                </div>
            </div>
            <div className="modal-input column-span-2">
                <CustomObjectInput
                    type="dropdown"
                    classes={['country-input']}
                    id="Object-input"
                    label={t('Object')}
                    name="Object"
                    filter={true}
                    dropdownOptions={propertiesOptions()}
                    stateField={editcalendarFormModalObject.Object}
                    setStateField={editCalendarForm}
                    errors={errors}
                    control={control}
                    // rules={{ required: t('This is required') }}
                />
            </div>
            <div className="modal-input column-span-2">
                <CustomObjectInput
                    type="dropdown"
                    classes={['country-input']}
                    id="contact-input"
                    label={t('Contact')}
                    name="contact"
                    filter={true}
                    dropdownOptions={contactOptions()}
                    stateField={editcalendarFormModalObject.contact}
                    setStateField={editCalendarForm}
                    errors={errors}
                    control={control}
                    // rules={{ required: t('This is required') }}
                />
            </div>
            <div className="modal-input column-span-2">
                <CustomObjectInput
                    type="accordion-textarea"
                    classes={['info-input']}
                    id="info-input"
                    label={t('Info')}
                    name="info"
                    stateField={editcalendarFormModalObject.info}
                    setStateField={editCalendarForm}
                    errors={errors}
                    control={control}
                    // rules={{ required: t('This is required') }}
                />
            </div>
        </Dialog>
    );
};

export default CalendarEventModal;

import { Card } from 'primereact/card';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dropdown } from 'primereact/dropdown';
import React, { useState, useEffect } from 'react';
import CardHeader from '../../components/card/CardHeader';
import { useTranslation } from 'react-i18next';
import './ReportPage.scss';
import moment from 'moment';
import { ReportDto } from '../../models/Reports';
import { reportsService, ReportsService } from '../../services/ReportsService';
import { ProgressSpinner } from 'primereact/progressspinner';
import * as _ from 'lodash';

const ReportPage = () => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState<boolean>(false);
    const [data, setData] = useState<ReportDto>({
        daily: {
            exported: 0,
            purchaseOffers: 0,
            newInquiries: 0,
            scheduledAndHeldAppointments: 0,
            inquiryToAppointmentRatio: 0,
            newAndNewBoldFirstDates: 0,
            newAndNewBoldToAgreementMadeRatio: 0,
            activeProperties: 0,
            paidAmount: 0,
            cancelledProperties: 0,
            activeToCancelledRatio: 0,
        },
        monthly: {
            exported: 0,
            purchaseOffers: 0,
            newInquiries: 0,
            scheduledAndHeldAppointments: 0,
            inquiryToAppointmentRatio: 0,
            newAndNewBoldFirstDates: 0,
            newAndNewBoldToAgreementMadeRatio: 0,
            activeProperties: 0,
            paidAmount: 0,
            cancelledProperties: 0,
            activeToCancelledRatio: 0,
        },
    });

    useEffect(() => {
        setLoading(true);
        fetchReports()
            .then((result) => {
                if (!_.isEmpty(result.daily) && !_.isEmpty(result.monthly)) {
                    setData({
                        daily: result.daily,
                        monthly: result.monthly,
                    });
                    setLoading(false);
                } else {
                    setLoading(false);
                }
            })
            .catch((error) => {
                setLoading(false);
                console.error(error);
            });
    }, []);

    const fetchReports = async () => {
        const response = await reportsService.getReports();
        return response.data;
    };

    const customerData = [
        {
            description: t('Aufschaltung'),
            de_daily: data.daily.exported.toString(),
            de_monthly: data.monthly.exported.toString(),
        },
        {
            description: t('Kaufangebote'),
            de_daily: data.daily.purchaseOffers.toString(),
            de_monthly: data.monthly.purchaseOffers.toString(),
        },
        {
            description: t('Interessentenanfragen'),
            de_daily: data.daily.newInquiries.toString(),
            de_monthly: data.monthly.newInquiries.toString(),
        },
        {
            description: t('Besichtigungen'),
            de_daily: data.daily.scheduledAndHeldAppointments.toString(),
            de_monthly: data.monthly.scheduledAndHeldAppointments.toString(),
        },
        // {
        //     description: t('Besichtigungsquote'),
        //     de_daily: data.daily.inquiryToAppointmentRatio
        //         ? data.daily.inquiryToAppointmentRatio.toFixed() + '%'
        //         : t('No data'),
        //     de_monthly: data.monthly.inquiryToAppointmentRatio
        //         ? data.monthly.inquiryToAppointmentRatio.toFixed() + '%'
        //         : t('No data'),
        // },
        {
            description: t('FD Anzahl'),
            de_daily: data.daily.newAndNewBoldFirstDates.toString(),
            de_monthly: data.monthly.newAndNewBoldFirstDates.toString(),
        },
        {
            description: t('FD Mandat'),
            de_daily: data.daily.newAndNewBoldToAgreementMadeRatio.toString(),
            de_monthly:
                data.monthly.newAndNewBoldToAgreementMadeRatio.toString(),
        },
        {
            description: t('Objektbestand'),
            de_daily: data.daily.activeProperties.toString(),
            de_monthly: data.monthly.activeProperties.toString(),
        },
        {
            description: t('Provisionseingänge'),
            de_daily: data.daily.paidAmount.toLocaleString() + ' EUR',
            de_monthly: data.monthly.paidAmount.toLocaleString() + ' EUR',
        },
        // {
        //     description: t('Kündigungen'),
        //     de_daily: data.daily.cancelledProperties.toString(),
        //     de_monthly: data.monthly.cancelledProperties.toString(),
        // },
        // {
        //     description: t('Kündigungsquote'),
        //     de_daily: data.daily.activeToCancelledRatio
        //         ? data.daily.activeToCancelledRatio.toFixed() + '%'
        //         : t('No data'),
        //     de_monthly: data.monthly.activeToCancelledRatio
        //         ? data.monthly.activeToCancelledRatio.toFixed() + '%'
        //         : t('No data'),
        // },
    ];

    return loading ? (
        <div className="center-spinner">
            <ProgressSpinner strokeWidth="5" />
        </div>
    ) : (
        <div className="report-page">
            <Card>
                <div className="filter-container">
                    <Dropdown className="filter-item" />
                    <Dropdown className="filter-item" />
                    <Dropdown className="filter-item" />
                </div>
                <div className="filter-container">
                    <Dropdown className="filter-item" />
                    <Dropdown className="filter-item" />
                    <Dropdown className="filter-item" />
                </div>
                <div className="card-wraper">
                    <Card
                        className="location-section card"
                        header={<CardHeader title={t('Land AT')} />}
                    >
                        <DataTable
                            value={customerData}
                            className="p-datatable-lg"
                        >
                            <Column
                                className="erstellt"
                                field="description"
                                header={t('Description')}
                            />
                            <Column
                                className="lorem-first-column"
                                field="at_daily"
                                header={t('Day')}
                            />
                            <Column
                                className="lorem-second-column"
                                field="at_monthly"
                                header={t('Month')}
                            />
                        </DataTable>
                    </Card>
                    <Card
                        className="location-section card"
                        header={<CardHeader title={t('Land DE')} />}
                    >
                        <DataTable
                            value={customerData}
                            className="p-datatable-lg"
                        >
                            <Column
                                className="erstellt"
                                field="description"
                                header={t('Description')}
                            />
                            <Column
                                className="lorem-first-column"
                                field="de_daily"
                                header={t('Day')}
                            />
                            <Column
                                className="lorem-second-column"
                                field="de_monthly"
                                header={t('Month')}
                            />
                        </DataTable>
                    </Card>
                    <Card
                        className="location-section card"
                        header={<CardHeader title={t('Land GB')} />}
                    >
                        <DataTable
                            value={customerData}
                            className="p-datatable-lg"
                        >
                            <Column
                                className="erstellt"
                                field="description"
                                header={t('Description')}
                            />
                            <Column
                                className="lorem-first-column"
                                field="gb_daily"
                                header={t('Day')}
                            />
                            <Column
                                className="lorem-second-column"
                                field="gb_monthly"
                                header={t('Month')}
                            />
                        </DataTable>
                    </Card>
                </div>
            </Card>
        </div>
    );
};

export default ReportPage;

import { Checkbox } from 'primereact/checkbox';
import React from 'react';

interface CheckboxProps {
    attribute: string;
    label: string;
    value: boolean;
    onChange?: (value: boolean) => void;
}

const FurnishingCheckbox = (props: CheckboxProps) => {
    return (
        <div className="p-field-checkbox">
            <Checkbox inputId={props.attribute} value={props.attribute} checked={props.value} onChange={(event) => props.onChange(event.checked)} />
            <label htmlFor={props.attribute}>{props.label}</label>
        </div>
    );
};

export default FurnishingCheckbox;

import { Card } from 'primereact/card';
import { Accordion, AccordionTab } from 'primereact/accordion';
import React from 'react';
import CardHeader from '../../card/CardHeader';
import './ObjectTextForm.scss';
import CustomObjectInput from '../customObjectInput/CustomObjectInput';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

const ObjectTextForm = ({
    realEstateObject,
    updateRealEstateObject,
    control,
    register,
    errors,
}) => {
    const { t } = useTranslation();
    // const handleErrors = (inputName) => {
    //     setTimeout(() => {
    //         const element = document.getElementById(inputName);
    //         if (errors[inputName]) {
    //             element.classList.add('p-invalid');
    //         } else {
    //             element.classList.remove('p-invalid');
    //         }
    //     }, 50)
    // };

    return (
        <Card
            className="object-text-wrapper"
            header={<CardHeader title={t('Text')} />}
        >
            <div className="section-row">
                <CustomObjectInput
                    type="text"
                    id="titel-input"
                    classes={['text-container']}
                    label={t(
                        'Titel (wird exportiert / veröffentlicht) / Naslov (biće izvezen / objavljen)',
                    )}
                    name="text_title"
                    stateField={realEstateObject.text.title}
                    setStateField={updateRealEstateObject}
                    errors={errors}
                    control={control}
                    // rules={{ required: t('This is required') }}
                />
            </div>
            <div className="section-row">
                <CustomObjectInput
                    type="accordion-textarea"
                    id="internet-description-input"
                    styles={{ height: '280px' }}
                    classes={['text-area-container']}
                    label={t(
                        'Beschreibung Internet (wird exportiert / veröffentlicht) / Opis internet (izvozi se / objavljuje)',
                    )}
                    name="text_descriptionForTheInternet"
                    stateField={realEstateObject.text.descriptionForTheInternet}
                    setStateField={updateRealEstateObject}
                    errors={errors}
                    control={control}
                    // rules={{ required: t('This is required') }}
                />
            </div>
            <div className="section-row">
                <CustomObjectInput
                    type="accordion-textarea"
                    id="lage-input"
                    styles={{ height: '140px' }}
                    classes={['text-area-container']}
                    label={t(
                        'Lage (wird exportiert / veröffentlicht) / Lokacija (biće izvezena / objavljena)',
                    )}
                    name="text_location"
                    stateField={realEstateObject.text.location}
                    setStateField={updateRealEstateObject}
                    errors={errors}
                    control={control}
                    // rules={{ required: t('This is required') }}
                />
            </div>
            <div className="section-row">
                <CustomObjectInput
                    type="accordion-textarea"
                    id="school-input"
                    styles={{ height: '140px' }}
                    classes={['text-area-container']}
                    label={t('Schulen (Skole)')}
                    name="text_shortDescription"
                    stateField={realEstateObject.text.shortDescription}
                    setStateField={updateRealEstateObject}
                    errors={errors}
                    control={control}
                    // rules={{ required: t('This is required') }}
                />
            </div>
            <div className="section-row">
                <CustomObjectInput
                    type="accordion-textarea"
                    id="public-transport-input"
                    styles={{ height: '140px' }}
                    classes={['text-area-container']}
                    label={t('Offentliche Verkehrsmittel (Javni prevoz)')}
                    name="text_publicTransportDescription"
                    stateField={
                        realEstateObject.text.publicTransportDescription
                    }
                    setStateField={updateRealEstateObject}
                    errors={errors}
                    control={control}
                    // rules={{ required: t('This is required') }}
                />
            </div>

            <div className="section-row">
                <CustomObjectInput
                    type="accordion-textarea"
                    id="shopping-input"
                    styles={{ height: '140px' }}
                    classes={['text-area-container']}
                    label={t('Einkaufen (Kupovina)')}
                    name="text_shoppingDescription"
                    stateField={realEstateObject.text.shoppingDescription}
                    setStateField={updateRealEstateObject}
                    errors={errors}
                    control={control}
                    // rules={{ required: t('This is required') }}
                />
            </div>
            <div className="section-row">
                <Accordion
                    onTabOpen={(e) => {
                        // handleErrors('other-info-input');
                        // errors.otherInfoInput ? document.getElementById('other-info-input').classList.add('p-invalid') : null
                    }}
                >
                    <AccordionTab
                        headerClassName={classNames({
                            'accordion-error': errors.otherInfoInput,
                        })}
                        // contentClassName={classNames({'accordion-error': errors.otherInfoInput})}
                        header={t(
                            'Sonstige Angaben Internet (wird exportiert / veröffentlicht) / Ostale informacije Internet  (izvozi se / objavljuje)',
                        )}
                    >
                        <CustomObjectInput
                            type="accordion-textarea"
                            id="other-info-input"
                            classes={['text-area-container']}
                            name="text_otherInfoForTheInternet"
                            stateField={
                                realEstateObject.text.otherInfoForTheInternet
                            }
                            setStateField={updateRealEstateObject}
                            errors={errors}
                            control={control}
                            // rules={{ required: t('This is required') }}
                        />
                    </AccordionTab>
                </Accordion>
            </div>
            <div className="section-row">
                <Accordion>
                    <AccordionTab
                        header={t(
                            'Interne Beschreibung (wird nicht bei externen Freigaben angezeigt) / Interni opis (nije prikazan za spoljne deljene datoteke)',
                        )}
                        headerClassName={classNames({
                            'accordion-error': errors.internalDescriptionInput,
                        })}
                    >
                        <CustomObjectInput
                            type="accordion-textarea"
                            id="internal-description-input"
                            classes={['text-area-container']}
                            name="text_text1"
                            stateField={realEstateObject.text.text1}
                            setStateField={updateRealEstateObject}
                            errors={errors}
                            control={control}
                            // rules={{ required: t('This is required') }}
                        />
                    </AccordionTab>
                </Accordion>
            </div>
            <div className="section-row">
                <Accordion>
                    <AccordionTab
                        header={t(
                            'Kurzbeschreibung Exposé (wird im Standard Exposé Auslage verwendet) / Kratki opis Expose (koristi se u standardnom eskpozeu) Prikaz',
                        )}
                        headerClassName={classNames({
                            'accordion-error': errors.shortDescriptionInput,
                        })}
                    >
                        <CustomObjectInput
                            type="accordion-textarea"
                            id="short-description-input"
                            classes={['text-area-container']}
                            name="text_text2"
                            stateField={realEstateObject.text.text2}
                            setStateField={updateRealEstateObject}
                            errors={errors}
                            control={control}
                            // rules={{ required: t('This is required') }}
                        />
                    </AccordionTab>
                </Accordion>
            </div>
            <div className="section-row">
                <Accordion>
                    <AccordionTab
                        header={t('Beschreibung Exposé / Opis Expose')}
                        headerClassName={classNames({
                            'accordion-error': errors.exposeDescriptionInput,
                        })}
                    >
                        <CustomObjectInput
                            type="accordion-textarea"
                            id="expose-description-input"
                            classes={['text-area-container']}
                            name="text_text3"
                            stateField={realEstateObject.text.text3}
                            setStateField={updateRealEstateObject}
                            errors={errors}
                            control={control}
                            // rules={{ required: t('This is required') }}
                        />
                    </AccordionTab>
                </Accordion>
            </div>
        </Card>
    );
};

export default ObjectTextForm;

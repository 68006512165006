import React, { useState, useEffect, useRef } from 'react';
import { Link, useHistory, useLocation, useParams } from 'react-router-dom';
import { Card } from 'primereact/card';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Menubar } from 'primereact/menubar';
import { Toast } from 'primereact/toast';
import Icon from '@mdi/react';
import {
    mdiFlag,
    mdiPhone,
    mdiEmailOutline,
    mdiNoteOutline,
    mdiOfficeBuildingOutline,
    mdiAccount,
    mdiPencilOutline,
} from '@mdi/js';
import CardHeader from '../../components/card/CardHeader';
import { NewInquiryActivityFormModal } from '../../components/newInquiryActivityFormModal/NewInquiryActivityFormModal';
import { TypeOfFirstDateActivity } from '../../models/FirstDateActivity';
import { firstDateActivityService } from '../../services/FirstDateActivityService';
import { firstDateService } from '../../services/FirstDateService';
import { filesService } from '../../services/FilesService';
import './Inquiry.scss';
import { useTranslation } from 'react-i18next';
import dateFormat from 'dateformat';
import { IupdateActivitiesData } from '../../models/NewInquiryActivityModal';
import { userService } from '../../services/UsersService';
import noCoverPhoto from '../../../public/assets/images/no-cover-photo.png';
import { useApplicationContext } from '../../application/ApplicationContext';
import moment from 'moment';
import PermissionContainer from '../../core/auth/PermissionContainer';
import { Permissions } from '../../core/auth/Permissions';
import { DomHandler } from 'primereact/utils';
import { useStateCallback } from '../../hooks/StateCallbackHook';
import { AcquisitionType } from '../../models/RealEstateObject';

//TODO: First Date instead of Inquiry - change in the nearest future
export const Inquiry = () => {
    const location = useLocation();
    // const { data }: any = location.state;
    // const [data, setData] = useState(undefined);

    const [firstDateActivitiesTableData, setFirstDateActivitiesTableData] =
        useState([]);
    const [visibleNewActivity, setVisibleNewActivity] = useStateCallback(false);
    const [typeOfNewActivity, setTypeOfNewActivity] =
        useState<TypeOfFirstDateActivity>();
    const [actualStatus, setActualStatus] = useState<string>();
    const [status, setStatus] = useState<number>(0);
    const [users, setUsers] = useState([]);
    const { appState } = useApplicationContext();

    const { t } = useTranslation();

    const { id } = useParams<{ id: string }>();
    const [inquiries, setInquiries] = useState(undefined);

    const fetchFirstDate = async () => {
        const response = await firstDateService.getOne(id);
        return response.data;
    };

    const currentUser = users.filter((el) => {
        if (el.id === appState.currentUser.id) return el;
    });

    const termin = firstDateActivitiesTableData.filter(
        (activity) => activity.type === 'Bestätigt',
    );

    const [checkType, setCheckType] = useState('');
    useEffect(() => {
        if (inquiries?.property.propertyTypeId === 6) {
            setCheckType('Wohnflache|Grundstucksflach');
        } else {
            const combinationWonhnflache = [1, 2, 3, 4, 11, 12, 13];
            if (
                !combinationWonhnflache.includes(
                    inquiries?.property.propertyTypeId,
                )
            ) {
                setCheckType('Grundstucksflach');
            } else {
                setCheckType('Wohnflache');
            }
        }
    }, [inquiries?.property.propertyTypeId]);

    useEffect(() => {
        userService
            .getMany()
            .then((response) => {
                if (response && response.data) {
                    setUsers(response.data);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }, []);

    useEffect(() => {
        userService.refreshTokenCheck().then(() => {
            fetchFirstDate().then((result) => {
                setInquiries(result);
                setActualStatus(result.status);
            });
        });
    }, [status]);

    useEffect(() => {
        if (inquiries) {
            if (
                inquiries?.status === 'Neu bold' &&
                appState.currentUser.id === inquiries.property.employee.id
            )
                firstDateService.updateOne(inquiries.id, t('Neu'));
        }
    }, [inquiries]);

    useEffect(() => {
        formatTableData();
    }, [inquiries]);

    const formatTableData = () => {
        const newTableData = [];
        if (inquiries) {
            for (const item of inquiries.firstDateActivities.reverse()) {
                newTableData.push({
                    createdAt: new Date(item.createdAt).getTime(),
                    date: moment(item.date).format('DD.MM.YYYY, HH:mm'),
                    user: item.user?.name,
                    type: t(`${item.type}`),
                    comment: item.comment,
                    systemComment: item.systemComment,
                });
            }
            setFirstDateActivitiesTableData(newTableData);
        }
    };

    const updateActivitiesData = ({
        newActivity,
        activityType,
        contactNoNewAppointment,
        contactUnreachable,
        sellerWasNotThere,
        agentNoContract,
        agendDidNotAccept,
        dropdownOption,
    }: IupdateActivitiesData) => {
        const currentDate = new Date();
        const year = currentDate.getFullYear().toString().substring(2, 4);
        let month = (currentDate.getMonth() + 1).toString();
        let day = currentDate.getDate().toString();
        let hour = currentDate.getHours().toString();
        let minutes = currentDate.getMinutes().toString();
        if (month.length === 1) {
            month = '0' + month;
        }
        if (day.length === 1) {
            day = '0' + day;
        }
        if (hour.length === 1) {
            hour = '0' + hour;
        }
        if (minutes.length === 1) {
            minutes = '0' + minutes;
        }
        let newActivityDate =
            day + '/' + month + '/' + year + ', ' + hour + ':' + minutes;

        const newTableData = firstDateActivitiesTableData;

        if (activityType === 'Bestätigt') {
            setActualStatus(activityType);
        }

        const systemComment = newActivity.systemComment ?? '';

        if (newActivity) {
            newTableData.unshift({
                createdAt: currentDate,
                date: newActivity.date
                    ? moment(newActivity.date).format('DD.MM.YYYY, HH:mm')
                    : newActivityDate,
                user: currentUser[0]?.name,
                type: t(
                    `${
                        activityType === 'Bestätigt'
                            ? 'Bestätigt'
                            : typeOfNewActivity
                    }`,
                ),
                comment: newActivity.comment,
                systemComment: systemComment,
            });
            let newInquiryActivity = {
                appointmentDate: `${newActivity.appointmentDate}`,
                date:
                    newActivity.date ??
                    moment(newActivity.date).format('YYYY/MM/DD, HH:mm'),
                type:
                    activityType !== undefined
                        ? activityType
                        : typeOfNewActivity,
                comment: newActivity.comment,
                contactNoNewAppointment: contactNoNewAppointment,
                contactUnreachable: contactUnreachable,
                sellerWasNotThere: sellerWasNotThere,
                agentNoContract: agentNoContract,
                agendDidNotAccept: agendDidNotAccept,
                contractType: dropdownOption,
                systemComment,
            };

            setFirstDateActivitiesTableData(newTableData);
            firstDateActivityService
                .updateOne(inquiries.id, newInquiryActivity)
                .then((response) => {
                    setStatus(response?.status);
                })
                .catch((err) => setStatus(err.response?.status));
        }
    };

    const toast = useRef(null);
    useEffect(() => {
        if (status === 201) {
            toast.current.show({
                severity: 'success',
                detail: t(`Activity has been saved successfully`),
                life: 3000,
            });
            setStatus(0);
        }
        if (status === 400) {
            toast.current.show({
                severity: 'error',
                detail: t(`fill all required fields!`),
                life: 3000,
            });
            setStatus(0);
        }
        if (status === 500) {
            toast.current.show({
                severity: 'error',
                detail: t(`internal server error`),
                life: 3000,
            });
            setStatus(0);
        }
    }, [status]);

    const showModal = () => {
        setVisibleNewActivity(!visibleNewActivity, (state) => {
            if (!state) {
                DomHandler.removeClass(document.body, 'p-overflow-hidden');
            }
        });
    };

    const activityMenuItems = [
        {
            label: t('Communications'),
            items: [
                {
                    icon: 'bi bi-telephone-inbound-fill',
                    label: t('Tel. Inc.'),
                    command: () => {
                        setTypeOfNewActivity(
                            TypeOfFirstDateActivity.TELEPHONE_PASSIVE,
                        );
                        showModal();
                    },
                },
                {
                    icon: 'bi bi-telephone-outbound-fill',
                    label: t('Tel. Out'),
                    command: () => {
                        setTypeOfNewActivity(
                            TypeOfFirstDateActivity.TELEPHONE_ACTIVE,
                        );
                        showModal();
                    },
                },
                {
                    icon: 'bi bi-telephone-x-fill',
                    label: t('Tel. NE'),
                    command: () => {
                        setTypeOfNewActivity(
                            TypeOfFirstDateActivity.TELEPHONE_NE,
                        );
                        showModal();
                    },
                },
                // {
                //     label: t('Keine Tel. Nr. - Mail'),
                //     command: () => {
                //         setTypeOfNewActivity(
                //             TypeOfFirstDateActivity.TELEPHONE_RR,
                //         );
                //         showModal();
                //     },
                // },
                {
                    icon: 'bi bi-envelope-fill',
                    label: t('Mail Inc.'),
                    command: () => {
                        setTypeOfNewActivity(
                            TypeOfFirstDateActivity.MAIL_PASSIVE,
                        );
                        showModal();
                    },
                },
                {
                    icon: 'bi bi-envelope-fill',
                    label: t('Mail Out'),
                    command: () => {
                        setTypeOfNewActivity(
                            TypeOfFirstDateActivity.MAIL_ACTIVE,
                        );
                        showModal();
                    },
                },
                {
                    icon: 'bi bi-chat-left-dots-fill',
                    label: t('Comment'),
                    command: () => {
                        setTypeOfNewActivity(TypeOfFirstDateActivity.COMMENT);
                        showModal();
                    },
                },
            ],
        },
        {
            label: 'FD ' + t('Termin bestat'),
            command: () => {
                setTypeOfNewActivity(
                    TypeOfFirstDateActivity.CONFIRM_APPOINTMENT,
                );
                showModal();
            },
        },
        {
            label: t('Servicetermin'),
            command: () => {
                setTypeOfNewActivity(
                    TypeOfFirstDateActivity.SERVICE_APPOINTMENT,
                );
                showModal();
            },
        },
    ];

    const splitStatus = !visibleNewActivity
        ? typeOfNewActivity?.split(' ')
        : null;

    const adminStatuses = [];

    if (appState.currentUser.role.name.toLowerCase().includes('admin')) {
        adminStatuses.push(
            {
                label: t('Bestätigt'),
                command: () => {
                    setTypeOfNewActivity(TypeOfFirstDateActivity.FD_APPROVED);
                    setActualStatus(TypeOfFirstDateActivity.FD_APPROVED);
                    showModal();
                },
            },
            {
                label: t('Zurück an CC'),
                command: () => {
                    setTypeOfNewActivity(TypeOfFirstDateActivity.FD_BACK_TO_CC);
                    setActualStatus(TypeOfFirstDateActivity.FD_BACK_TO_CC);
                    showModal();
                },
            },

            {
                label: t('Inaktiv'),
                command: () => {
                    setTypeOfNewActivity(TypeOfFirstDateActivity.FD_INACTIVE);
                    setActualStatus(TypeOfFirstDateActivity.FD_INACTIVE);
                    showModal();
                },
            },
            {
                label: t('Abgeschlossen'),
                command: () => {
                    setTypeOfNewActivity(TypeOfFirstDateActivity.FD_CLOSED);
                    setActualStatus(TypeOfFirstDateActivity.FD_CLOSED);
                    showModal();
                },
            },
        );
    }

    const activityMenuEndItem = [
        {
            // label: t(
            //     `Status ${
            //         typeOfNewActivity !== undefined
            //             ? `- ${typeOfNewActivity}`
            //             : ''
            //     }`,
            // ),
            label: `${'Status'} - ${
                actualStatus === 'Neu bold' ? 'Brandneu' : actualStatus
            }`,
            className: `${splitStatus?.join('-')}`,

            items: [
                {
                    label: t('Neu'),
                    command: () => {
                        setTypeOfNewActivity(TypeOfFirstDateActivity.FD_NEW);
                        setActualStatus(TypeOfFirstDateActivity.FD_NEW);
                        showModal();
                    },
                },
                {
                    label: t('Wiedervorlage'),
                    command: () => {
                        setTypeOfNewActivity(
                            TypeOfFirstDateActivity.FD_FOLLOW_UP,
                        );
                        setActualStatus(TypeOfFirstDateActivity.FD_FOLLOW_UP);
                        showModal();
                    },
                },
                {
                    label: t('Abgesagt'),
                    command: () => {
                        setTypeOfNewActivity(
                            TypeOfFirstDateActivity.FD_CALLED_OFF,
                        );
                        setActualStatus(TypeOfFirstDateActivity.FD_CALLED_OFF);
                        showModal();
                    },
                },
                {
                    label: t('On Hold'),
                    command: () => {
                        setTypeOfNewActivity(
                            TypeOfFirstDateActivity.FD_ON_HOLD,
                        );
                        setActualStatus(TypeOfFirstDateActivity.FD_ON_HOLD);
                        showModal();
                    },
                },
                {
                    label: t('Mandat erhalten'),
                    command: () => {
                        setTypeOfNewActivity(
                            TypeOfFirstDateActivity.FD_RECEIVED_A_MANDATE,
                        );
                        setActualStatus(
                            TypeOfFirstDateActivity.FD_RECEIVED_A_MANDATE,
                        );
                        showModal();
                    },
                },
                {
                    label: t('Nicht aufgenommen'),
                    command: () => {
                        setTypeOfNewActivity(
                            TypeOfFirstDateActivity.FD_NOT_RECORDED,
                        );
                        setActualStatus(
                            TypeOfFirstDateActivity.FD_NOT_RECORDED,
                        );
                        showModal();
                    },
                },
                ...adminStatuses,
            ],
        },
    ];

    const history = useHistory();
    const openAllRelatedProperties = () => {
        history.push({
            pathname: `/objects`,
            search: `?sellerId=${inquiries.seller?.id}`,
        });
        return;
    };

    const renderSystemComment = (rowData) => {
        if (rowData.type === 'bestätigen' && rowData.systemComment) {
            const parts = rowData.systemComment.split(': ');
            return `${parts[0]}: ${moment(`${parts[1]}`).format(
                'DD.MM.YYYY HH:mm',
            )}`;
        }

        return rowData.systemComment;
    };

    return inquiries ? (
        <>
            <div className="contact-property-wrapper">
                <Toast ref={toast} position={'top-right'} />
                <div className="three-col">
                    <Card
                        header={
                            <CardHeader
                                title={t('Verkäufer')}
                                className={'card-header-background'}
                            />
                        }
                    >
                        <div className="contact-section">
                            <div className="center-text">
                                <span>
                                    <strong>{t('ID')}:</strong>
                                    &nbsp;
                                    {inquiries.seller?.id}
                                </span>
                            </div>
                            <div className="center-text">
                                <span>
                                    <strong>{t('Name')}:</strong>
                                    &nbsp;
                                    {t(inquiries.seller?.salutation)}
                                    &nbsp;
                                    {inquiries.seller?.firstName +
                                        ' ' +
                                        inquiries.seller?.lastName}
                                </span>
                            </div>
                            {/* <div>
                                <span className="vertical-align">
                                    <Icon path={mdiNoteOutline} size={1} />
                                </span>
                                &nbsp;&nbsp;
                                {t('Saglasnost za ponude: NE')}
                            </div> */}
                            <div className="center-text">
                                <strong>{t('Telefonnummer')}:</strong>
                                &nbsp;
                                {inquiries.seller?.privatePhone}
                            </div>
                            <div className="center-text">
                                <strong>{t('Emails')}:</strong>
                                &nbsp;
                                {inquiries.seller?.emails.length !== 0
                                    ? inquiries.seller?.emails[0].email
                                    : ''}
                            </div>

                            <div className="center-text">
                                <strong>{t('Firma / kompanija')}:</strong>
                                &nbsp;
                                {inquiries.seller?.company}
                            </div>
                            <div className="center-text">
                                <strong>{t('Adresa')}:</strong>
                                &nbsp;
                                {`${
                                    (inquiries.seller?.address ?? '') +
                                    (' ' + inquiries.seller?.houseNumber ??
                                        '') +
                                    (inquiries.seller?.apartmentNumber
                                        ? '/' +
                                          inquiries.seller?.apartmentNumber
                                        : '') +
                                    (', ' + inquiries.seller?.postalCode ??
                                        '') +
                                    (' ' + inquiries.seller?.city ?? '')
                                }`}
                            </div>
                            {/* <div>
                                <span className="vertical-align">
                                    <Icon path={mdiEmailOutline} size={1} />
                                </span>
                                &nbsp;&nbsp;
                                {t('Add mail')}
                            </div> */}
                        </div>
                        <div className="show-link-contact">
                            <Link
                                to={{
                                    pathname: `/contact/${inquiries.seller?.id}`,
                                    state: { firstDate: id },
                                }}
                            >
                                {t('Prikazi kontakt')}
                            </Link>
                        </div>
                    </Card>
                    <Card
                        header={
                            <CardHeader
                                title={t('Nekretnina')}
                                // titleRight={
                                //     <button
                                //         aria-label="Alle Objekte"
                                //         className="p-button p-component new-first-date-button"
                                //         style={{ margin: '-8px 0' }}
                                //     >
                                //         <span className="p-button-label p-c">
                                //             {t('Alle Objekte') +
                                //                 ' ' +
                                //                 inquiries?.seller?.properties.filter(
                                //                     (x) => x.childId === null,
                                //                 ).length}
                                //         </span>
                                //     </button>
                                // }
                                // onClick={(e) => openAllRelatedProperties()}
                                className={'card-header-background'}
                            />
                        }
                    >
                        <div className="property-section">
                            <span className="property-image">
                                <img
                                    className="cover-photo"
                                    alt={`cover-${inquiries?.property?.rootId}`}
                                    src={
                                        inquiries.property.coverPhoto === null
                                            ? noCoverPhoto
                                            : filesService.getFilePath(
                                                  inquiries.property.coverPhoto
                                                      .id,
                                              )
                                    }
                                />
                                <div
                                    className="center-text"
                                    style={{ marginTop: '5px' }}
                                >
                                    <span className="vertical-align">
                                        <Icon path={mdiAccount} size={1} />
                                    </span>
                                    &nbsp;
                                    {inquiries.property.employee?.name}
                                </div>
                            </span>
                            <div className="property-data">
                                <div>
                                    <span>
                                        <strong>{t('ID')}:</strong>
                                        &nbsp;
                                        {inquiries?.property?.rootId}
                                    </span>
                                </div>
                                <div>
                                    <span>
                                        <strong>{t('Adresa')}:</strong>
                                        &nbsp;
                                        {`${
                                            (inquiries.property.street ?? '') +
                                            ' ' +
                                            (inquiries.property.houseNumber ??
                                                '') +
                                            ' ' +
                                            (inquiries.property.floor ?? '') +
                                            ' ' +
                                            (inquiries.property.place ?? '')
                                        }`}
                                    </span>
                                </div>{' '}
                                <div>
                                    <strong>{`${t('Tip nekretnine')}:`}</strong>
                                    &nbsp;
                                    {inquiries.property.category?.name}
                                </div>
                                <div>
                                    <strong>{`${t('Cena')}:`}</strong>
                                    &nbsp;
                                    {
                                        `${inquiries?.property?.price?.netPurchasePrice?.toLocaleString()} EUR` /* + ' ' + inquiries.property.price?.currency*/
                                    }
                                </div>
                                <div>
                                    <strong>{`${t('Broj soba')}:`}</strong>
                                    &nbsp;
                                    {inquiries.property.surface
                                        ?.numberOfRooms ?? ' /'}
                                </div>
                                {checkType.includes('Wohnflache') ? (
                                    <div>
                                        <strong>
                                            {t('Stambena povrsina')}:
                                        </strong>
                                        &nbsp;
                                        {inquiries?.property.surface?.livingArea
                                            ? `${inquiries?.property.surface?.livingArea} m²`
                                            : '/'}
                                    </div>
                                ) : null}
                                {checkType.includes('Grundstucksflach') ? (
                                    <div>
                                        <strong>
                                            {t('Grundstücksfläche')}:
                                        </strong>
                                        &nbsp;
                                        {inquiries?.property.surface?.plotArea
                                            ? `${inquiries?.property.surface?.plotArea} m²`
                                            : '/'}
                                    </div>
                                ) : null}
                            </div>
                        </div>
                        <div className="show-link">
                            <Link
                                to={{
                                    pathname: `/object/${inquiries?.property?.rootId}`,
                                    state: {
                                        data: [inquiries.property],
                                    },
                                }}
                            >
                                {t('Prikazi objekat')}
                            </Link>
                        </div>
                    </Card>
                    <Card
                        header={
                            <CardHeader
                                title={t('Protokol/Status')}
                                className={'card-header-background'}
                            />
                        }
                    >
                        <div className="protocol">
                            <div className={'contact-section'}>
                                <div>
                                    <strong>{`${t('Dodeljeno')}:`}</strong>
                                    &nbsp;
                                    {dateFormat(
                                        inquiries.property.createdAt,
                                        'dd.mm.yyyy, HH:MM',
                                    ) ?? '/'}
                                </div>
                                <div>
                                    <strong>{t('Zugewiesen von: ')}</strong>
                                    &nbsp;
                                    {inquiries.property.acquisitionType ===
                                    AcquisitionType.ACQUISITION
                                        ? 'Eigenakquise'
                                        : 'Golden Home Akquise'}
                                </div>
                                <div>
                                    <strong>{`${t(
                                        'Prvo pregledavanje',
                                    )}:`}</strong>
                                    &nbsp;
                                    {dateFormat(
                                        inquiries.property.exportedAt,
                                        'dd.mm.yyyy, HH:MM',
                                    ) ?? '/'}
                                </div>
                                <div>
                                    <strong>{`${t(
                                        'Poslednja aktivnost',
                                    )}:`}</strong>
                                    &nbsp;
                                    {/* {inquiries.firstDateActivities.length > 0
                                    ? dateFormat(
                                          inquiries.firstDateActivities[
                                              inquiries.firstDateActivities
                                                  .length - 1
                                          ].createdAt,
                                          'dd.mm.yyyy, HH:MM',
                                      ) ?? '/'
                                    : dateFormat(
                                          inquiries.property.updatedAt,
                                          'dd.mm.yyyy, HH:MM',
                                      ) ?? '/'} */}
                                    {firstDateActivitiesTableData[0]?.date.replaceAll(
                                        '/',
                                        '.',
                                    )}
                                </div>
                                <div>
                                    <strong>{`${t('Status')}:`}</strong>
                                    &nbsp;
                                    {actualStatus ?? ''}
                                </div>
                                <div>
                                    <strong>{`${t('Termin')}:`}</strong>
                                    &nbsp;
                                    {moment(inquiries.appointmentDate).format(
                                        'DD.MM.YYYY, HH:mm',
                                    ) ?? ''}
                                </div>
                            </div>
                        </div>
                    </Card>
                </div>
            </div>
            <div className="activity-section">
                <Card
                    className={'no-padding'}
                    header={
                        <CardHeader
                            title={t('Aktivnosti/Aktivitäten')}
                            className={'card-header-background'}
                        />
                    }
                >
                    <PermissionContainer
                        hasPermission={Permissions.FIRST_DATE_CREATE_ACTIVITY}
                        currentUser={appState.currentUser}
                    >
                        <div className="activity-menu-container">
                            <Menubar
                                model={activityMenuItems}
                                // start={t('Kommunikation')}
                                end={<Menubar model={activityMenuEndItem} />}
                            />
                        </div>
                    </PermissionContainer>
                    <DataTable
                        columnResizeMode="fit"
                        value={firstDateActivitiesTableData}
                        sortField="createdAt"
                        sortOrder={-1}
                        className="p-datatable-lg"
                    >
                        <Column
                            className="column-date"
                            field="createdAt"
                            header={t('Date')}
                            sortable
                            body={(rowData) => {
                                return dateFormat(
                                    rowData.createdAt,
                                    'dd.mm.yyyy, HH:MM:ss',
                                );
                            }}
                        />
                        <Column
                            className="column-date"
                            field="user"
                            header={t('Korisnik')}
                        />
                        <Column
                            className="column-date"
                            field="type"
                            header={t('Akcija')}
                        />
                        <Column
                            header={t('Komentar')}
                            className="column-date"
                            style={{
                                whiteSpace: 'pre-wrap',
                                width: '30%',
                            }}
                            body={(rowData) => (
                                <>
                                    {rowData.comment ===
                                    'Automatische Statusänderung' ? (
                                        <span
                                            style={{
                                                opacity: '0.5',
                                                fontStyle: 'italic',
                                            }}
                                        >
                                            {rowData.comment}
                                        </span>
                                    ) : (
                                        rowData.comment
                                    )}
                                </>
                            )}
                        />
                        <Column
                            body={renderSystemComment}
                            header={''}
                            style={{ whiteSpace: 'pre-wrap' }}
                        />
                    </DataTable>
                    {visibleNewActivity ? (
                        <>
                            <NewInquiryActivityFormModal
                                showModal={showModal}
                                inquiries={inquiries}
                                typeOfNewActivity={typeOfNewActivity}
                                visibleNewActivity={visibleNewActivity}
                                updateActivitiesData={updateActivitiesData}
                                contactId={inquiries?.seller?.id}
                                propertyId={inquiries?.property?.rootId}
                                activityData={firstDateActivitiesTableData.filter(
                                    (el) => {
                                        if (
                                            el.type ===
                                                TypeOfFirstDateActivity.TELEPHONE_ACTIVE ||
                                            el.type ===
                                                TypeOfFirstDateActivity.TELEPHONE_PASSIVE ||
                                            el.type ===
                                                TypeOfFirstDateActivity.TELEPHONE_NE ||
                                            el.type ===
                                                TypeOfFirstDateActivity.TELEPHONE_RR
                                        )
                                            return el;
                                    },
                                )}
                            />
                        </>
                    ) : null}
                </Card>
            </div>
        </>
    ) : (
        <div className="center-spinner">
            <ProgressSpinner strokeWidth="5" animationDuration="1.5s" />
        </div>
    );
};

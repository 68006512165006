module.exports = {
    "Username or password is wrong": "Benutzername oder Passwort ist falsch",
    "Unknown error": "Unbekannter Fehler",
    "Unknown user": "Unbekannter Benutzer",
    "Confirm email": "E-Mail Adresse bestätigen",
    "Password": "Passwort",
    "Users": "Benutzer",
    "User": "Nutzer",
    "Username": "Benutzername",
    "Send forgotten password": "Neues Passwort senden",
    "Reset password": "Passwort zurücksetzen",
    "Sign up": "Registrieren",
    "E-mail address": "E-Mail-Adresse",
    "Back": "Zurück",
    "Forgotten password?": "Passwort vergessen?",
    "CONTINUE": "FORTSETZEN",
    "Forgotten password": "Passwort vergessen",
    "Password must have min 8 character, 1 uppercase, 1 lowercase, 1 number and 1 special character": "Das Passwort muss mindestens 8 Zeichen, 1 Großbuchstaben, 1 Kleinbuchstaben, 1 Zahl und 1 Sonderzeichen haben",
    "password dont match!": "Passwort stimmt nicht überein!",
    "Password change successfully!": "Passwortänderung erfolgreich!",
    "Password reset": "Passwort zurücksetzen",
    "Repeat password": "Passwort wiederholen",
    "Reset password error": "Fehler bei Passwort Zurücksetzen",
    "Edit": "Bearbeiten",
    "Delete": "Löschen",
    "Edit Event": "?????????",
    "Title": "Titel",
    "This is required": "Diese Eingabe ist erforderlich",
    "Activity type": "Aktivität Art",
    "Date and time from": "Datum und Zeit von",
    "Date and time to": "Datum und Zeit bis",
    "Object": "Objekt",
    "Contact": "Kontakt",
    "Info": "Info",
    "Users page": "??????",
    "Check": "??????",
    "Refresh": "Neuladen",
    "New Task": "Neue Aufgabe",
    "Check your email": "Mail abrufen",
    "Ok": "OK",
    "New Activity - ": "Neue Aktivität",
    "Date": "Datum",
    "Time": "Uhrzeit",
    "Comment": "Kommentar",
    "Enter a valid email": "Bitte gültige Email Adresse eingeben",
    "Address / location": "Adresse",
    "Zemlja": "Land",
    "Savezna država": "Bundesland",
    "Ulica": "Straße",
    "Kućni broj": "Haus Nr.",
    "Ulaz": "Stiege",
    "Vrata": "Tür Nr.",
    "Poštanski broj": "PLZ",
    "Mesto": "Ort",
    "Strana sveta": "Ausrichtung",
    "Blizina": "Nähe",
    "Ključne informacije": "Schlüssel Information",
    "Objavite adresu sa sledećom tačnošću": "Adresse mit folgender Genauigkeit veröffentlichen",
    "Greška pri resetu šifre": "Fehler bei Passwort zurücksetzen",
    "Nazad": "Zurück",
    "Prijavite se": "Anmelden",
    "E-mail addresa": "Email Adresse",
    "Zaboravljena lozinka?": "Passwort vergessen?",
    "NASTAVI": "FORTSETZEN",
    "Zaboravljena šifra": "Passwort vergessen?",
    "Resetovanje šifre": "Passwort zurücksetzen",
    "Ponovi lozinku": "Passwort wiederholen",
    "Površine / Flächen": "Fläche",
    "Površina": "Fläche",
    "Stambena površina": "Wohnfläche",
    "Korisna površina": "Nutzfläche",
    "Površina dozv. za gradnju": "Verbaubare Fläche",
    "Kancelarijski prostor": "Bürofläche",
    "Prodajni prostor": "Verkaufsfläche",
    "Skladišni prostor": "Lagerfläche",
    "Visina hale": "Hallenhöhe",
    "Slobodan prostor": "Freie Fläche",
    "Privremena površina": "Befristete Fläche",
    "Ponderisano područje": "Gewichtete Fläche",
    "Nedovršeno potkrovlje": "Rohdachboden",
    "Površina potkrovlja": "Dachbodenfläche",
    "Broj soba": "Anzahl Zimmer",
    "0.5 soba": "+ 0,5 Zimmer",
    "Visina sobe": "Raumhöhe",
    "Broj vrtova": "Anzahl Gärten",
    "Površina vrtova": "Gartenfläche",
    "Broj podruma": "Anzahl Keller",
    "Površina podruma": "Kellerfläche",
    "Broj balkona": "Anzahl Balkone",
    "Površina balkona": "Balkonfläche",
    "Broj terasa": "Anzahl Terrassen",
    "Površina terasa": "Terrassenfläche",
    "Broj lođa": "Anzahl Loggias",
    "Površina lođa": "Loggiafläche",
    "Broj garaža": "Anzahl Garagen",
    "Površina garaža": "Garagenfläche",
    "Broj parking mesta": "Anzahl Stellplätze",
    "Površina parkinga": "Stellplatzfläche",
    "Broj toaleta": "Anzahl WC",
    "Broj kupatila": "Anzahl Bäder",
    "Broj ostava": "Anzahl Abstellräume",
    "Spratnost / Etagen": "Etage",
    "Sprat": "Etage",
    "Sprat - Dodatni opis": "Etage (Beschreibung)",
    "Broj spratova": "Anzahl Etagen",
    "Broj potkrovlja": "Anzahl Dachgeschoße",
    "Medjusprat": "Mezzanin",
    "Energetski sertifikat / Enegieausweis": "Energieausweis",
    "HWB": "HWB",
    "Klasse": "Klasse",
    "fGEE": "fGEE",
    "Važi od": "Gültig bis",
    "Automaticher Disclaimer": "Automatischer Disclaimer",
    "Supervisor": "Betreuer",
    "Opšte / Allgemein": "Allgemein",
    "Označavanje": "Kennzeichnung",
    "Bez prepreka": "Barrierefrei",
    "Tip nekretnine": "Objektart",
    "Građevinsko zemljište": "Baugrund",
    "Tlocrt": "Grundriss",
    "Prikladno u turističke svrhe": "Ferienimmobilie",
    "Vrste upotrebe objekata / Nutzungsart": "Nutzungsart",
    "Stanovanje": "Wohnen",
    "Poslovna": "Gewerbe",
    "Investicija": "Anlage",
    "Prodavac objekta / Objektabgeber": "Verkäufer",
    "Kontakt": "Kontakt",
    "Vlasnički udeo": "Eigentum Anteil",
    "Ukloniti": "Löschen",
    "Abgeber hinzufügen": "Verkäufer hinzufügen",
    "Neuen Abgeber anlegen": "Neuen Verkäufer anlegen",
    "Nalog za posredovanje": "Vermittlungsvertrag",
    "Tip": "Art",
    "Dogovor": "Vereinbarung",
    "Datum isteka": "Gültig bis",
    "Zemljišne knjige": "Grundbuch",
    "Broj priloška": "Einlagezahl",
    "Katastarska zajednica": "Katastralgemeinde",
    "Broj parcele": "Parzellen Nr. ",
    "Miete / Kirija": "Miete",
    "Maximum rental period": "Maximale Mietdauer",
    "Unlimited rental period": "Unbefristete Mietdauer",
    "Waiver of termination": "Kündigungsverzicht",
    "Sonstiges / Drugo": "Sonstiges",
    "Status": "Zustand",
    "Stanje kuće": "Hauszustand",
    "Godina izgradnje": "Baujahr",
    "Poslednje saniranje": "Letzte Sanierung",
    "Poslednje renoviranje": "Letzte Generalsanierung",
    "Oblik svojine": "Eigentumsform",
    "Opremanje": "Möblierung",
    "Procena situacije": "Lagebewertung",
    "Nivo buke": "Lärmbelästigung",
    "Razvoj": "Erschließung",
    "Procena stanja": "Zustandsbewertung",
    "Ključ u ruke": "Schlüsselfertig",
    "Spreman za pokrivanje": "Belagsfertig",
    "Slike / Bilder": "Bilder",
    "Planovi / Plane": "Pläne",
    "Video snimci / videos": "Video",
    "Dokumenta / Dokumente": "Dokumente",
    "360 pogled / 360 Ansichten": "360 Grad Ansicht",
    "Dateien & Links": "Dateien & Links",
    "Documente werden exportiert / veroffentlicht\"": "Dokumente werden exportiert / veröffentlicht",
    "Alle": "Alle",
    "Expose": "Exposé",
    "Intern": "Intern",
    "Loschen": "Löschen",
    "Planovi/Plane": "Pläne",
    "Video snimci/videos": "Video",
    "Dokumenta/Dokumente": "Dokumente",
    "360 pogled/360 Ansichten": "360 Grad Ansicht",
    "Opremljenost": "Ausstattung",
    "Interno": "Intern",
    "Verkauf am": "Verkauft am",
    "Provision Verkaufer absolut": "Provision Verkäufer Betrag",
    "Provision Kaufer absolut": "Provision Käufer Betrag",
    "Kaufer": "Käufer",
    "Rechnung gestelit am": "Rechnung gestellt am",
    "Preis it. Kaufanbot": "Preis lt. Kaufanbot",
    "Zahlungseinggang Datum": "Zahlungseingang Datum",
    "Izvoz interfejsa (Exportschnittstellen)": "Exportschnittstellen",
    "Platforma": "Plattform",
    "Broj objavljenih nekretnina": "Anzahl exportierte Objekte",
    "Maksimalni broj nekretnina": "Maximalanzahl Export",
    "Vermarktungsart / Tip marketinga*": "Vermarktungsart",
    "Kupovina": "Eigentum",
    "Kirija": "Miete",
    "Zakup": "Pacht",
    "Preise / cene": "Preise",
    "Kaufpreis": "Kaufpreis",
    "Netto": "Netto",
    "USt.": "USt.",
    "Brutto": "Brutto",
    "Publish prices": "Preis auf Anfrage",
    "Mesecne naknade": "Monatliche Kosten",
    "Parking": "Parkplatz",
    "Optional": "Optional",
    "Garaža": "Garage",
    "Operativni troškovi": "Betriebskosten",
    "Troškovi grejanja": "Heizkosten",
    "Vruća voda": "Warmwasser",
    "Troškovi hlađenja": "Kühlung",
    "Fond popravki": "Reparaturrücklage",
    "Ostalo": "Sonstiges",
    "Održavanje lifta": "Liftkosten",
    "Total": "Gesamt",
    "Preise pro m² / Cena po m2": "Preis / m² ",
    "m² - price from": "m² Preis von",
    "Purchase price / m²": "Kaufpreis / m²",
    "Operational costs / m²": "Betriebskosten / m²",
    "Interested party": "Kaufinteressent",
    "Output": "Ausgang",
    "Autofill": "automatisch ausfüllen",
    "Surrender": "Aufgeben",
    "Notes": "Kommentare",
    "Text": "Text",
    "Titel (wird exportiert / veröffentlicht) / Naslov (biće izvezen / objavljen)": "Titel (wird exportiert / veröffentlicht) ",
    "Beschreibung Internet (wird exportiert / veröffentlicht) / Opis internet (izvozi se / objavljuje)": "Beschreibung Internet (wird exportiert / veröffentlicht) ",
    "Lage (wird exportiert / veröffentlicht) / Lokacija (biće izvezena / objavljena)": "Lage (wird exportiert / veröffentlicht) ",
    "Schulen (Skole)": "Schulen",
    "Offentliche Verkehrsmittel (Javni prevoz)": "Offentliche Verkehrsmittel ",
    "Einkaufen (Kupovina)": "Einkaufen",
    "Sonstige Angaben Internet (wird exportiert / veröffentlicht) / Ostale informacije Internet  (izvozi se / objavljuje)": "Sonstige Angaben Internet (wird exportiert / veröffentlicht)",
    "Interne Beschreibung (wird nicht bei externen Freigaben angezeigt) / Interni opis (nije prikazan za spoljne deljene datoteke)": "Interne Beschreibung (wird nicht bei externen Freigaben angezeigt)",
    "Kurzbeschreibung Exposé (wird im Standard Exposé Auslage verwendet) / Kratki opis Expose (koristi se u standardnom eskpozeu) Prikaz": "Kurzbeschreibung Exposé (wird im Standard Exposé Auslage verwendet)",
    "Beschreibung Exposé / Opis Expose": "Beschreibung Exposé",
    "Base data": "Eckdaten",
    "Prices": "Preise",
    "Furnishing": "Ausstattung",
    "Marketing": "Marketing",
    "Aufgaben & Aktivitäten": "Aufgaben & Aktivitäten",
    "Internal": "Intern",
    "Aufgaben & Aktivitaten": "Aufgaben & Aktivitaten",
    "Meine interessent": "Meine Interessenten",
    "Overdue": "Überfällig",
    "Name": "Name",
    "UNTOUCHED": "NICHT BEARBEITET",
    "ID": "ID",
    "Meine Objekte": "Meine Objekte",
    "Total portfolio volume 8.500.000 eur": "Objektbestand Volumen",
    "IN PROGRESS": "IN BEARBEITUNG",
    "Meine First Dates": "Meine First Dates",
    "In Bearbietung": "In Bearbietung",
    "Bestatigt": "Bestatigt",
    "Tel Wiedervorl": "Tel. WV",
    "On Hold": "On Hold",
    "Meine Servise Termine": "Meine Service Termine",
    "This month": "Monat laufend",
    "Meine Preisduktion": "Meine Preisreduktion",
    "Target 5% from my real estate portfolio value": "Ziel: 5%  vom Objektbestand Volumen",
    "RESPONSE TIME": "ANTWORTZEIT",
    "Average Mon-Sut": "Durchschnitt Mon - Sam",
    "Meine First Datest": "Meine First Dates",
    "RESULTS": "RESULTAT",
    "This Months": "Monat laufend",
    "Last 3 Months": "Letzte 3 Monate",
    "Absage": "Absage",
    "Mandat erhalten": "Mandat erhalten",
    "List view": "Listenansicht",
    "Calendar view": "Kalenderansicht",
    "Please enter a new title for your event": "Neue Überschrift einfügen",
    "Empty Page": "Leere Seite",
    "Sunday": "Sonntag",
    "Monday": "Montag",
    "Tuesday": "Dienstag",
    "Wednesday": "Mittwoch",
    "Thursday": "Donnerstag",
    "Friday": "Freitag",
    "Saturday": "Samstag",
    "Untouched": "Nicht bearbeitet",
    "In Bearbeitung": "In Bearbeitung",
    "Calendar": "Kalender ",
    "Neu": "Neu",
    "Wiedervorlage": "Wiedervorlage",
    "Abgesagt": "Abgesagt",
    "On-hold": "On Hold",
    "Mandat-Erhalten": "Mandat erhalten",
    "Nicht-Aufgenommen": "Nicht aufgenommen",
    "akviza": "Akquise",
    "Ime": "Name",
    "Lokacija": "Standort",
    "Cena": "Preis",
    "Termindatum": "Termindatum",
    "Importantdatum": "Importantdatum",
    "Telefon": "Telefon",
    "Aktiv": "Aktiv",
    "Passiv": "Passiv ",
    "NE": "Nicht Erreicht",
    "Mail": "Mail",
    "Versendet": "Versendet",
    "Empfangen": "Empfangen",
    "Welocome": "Willkomen",
    "Personlich": "Persönlich",
    "Kalender": "Kallender",
    "Geshprachsnotiz": "Geschprächsnotiz",
    "FD Status": "FD Status",
    "Nicht erreichbar": "Nicht Erreicht",
    "Bestätigt": "Bestätigt",
    "Nicht aufgenommen": "Nicht Aufgenommen",
    "Zurück an CC": "Zurück an CC",
    "Wiedervorlage/WV": "Zurück an CC",
    "Kontakt podaci/Kontaktdaten": "Kontaktdaten",
    "Saglasnost za ponude: NE": "Opt-In",
    "Add mail": "Mail hinzufügen",
    "Prikazi kontakt": "Kontakt anzeigen",
    "Nekretnina": "Immobilie",
    "Alle Objekte": "Alle Objekte",
    "Tip nekretnine: ": "Objektart",
    "Cena: ": "Preis",
    "Broj soba: ": "Zimmeranzahl",
    "Stambena povrsina ": "Wohnfläche",
    "Prikazi objekat": "Objekt anzeigen",
    "Protokol/Status": "Protokoll",
    "Dodeljeno: ": "Zugeteilt",
    "Dodeljeno putem: Sistem": "Zugeteilt System",
    "Prvo pregledavanje: ": "Erstmalig angesehen",
    "Poslednja aktivnost: ": "Letzte Aktivität",
    "Status: ": "Status",
    "Termin: ": "Termin",
    "Nezainteresovanost: ": "Kein Interesse",
    "Aktivnosti/Aktivitäten": "Aktivität",
    "Kommunikation": "Kommunikation",
    "Korisnik": "Benutzer",
    "Komentar": "Kommentar",
    "Erneuern": "Erneuern",
    "Duplizieren": "Duplizieren ",
    "Drucken": "Drucken",
    "Mandat Art": "Mandatart",
    "Stambena povrsina": "Wohnfläche",
    "Angebot": "Angebot",
    "Aktivität": "Angebot",
    "Aufgabe": "Angebot",
    "Objekt duplizieren": "Angebot",
    "Abg. Nachweis": "Verkäufer Nachweis",
    "In Vorbereitung": "In Vorbereitung",
    "Inactive": "Inaktive",
    "VerkaufGH": "Verkauf-GH",
    "Abgeschlossen": "Abgeschlossen",
    "No objects found": "Kein Objekt gefunden",
    "Records per page": "Einträge pro Seite",
    "Naslovna slika": "Titelfoto",
    "Tip Objekta": "Objekart",
    "PTT Broj": "PLZ",
    "Adresa": "Adresse",
    "Prodavac": "Verkäufer",
    "Vrsta ugovora": "Mandatart",
    "Ugovor sklopljen": "Mandat abgeschlossen",
    "Exportovano": "Exportiert",
    "Ažurirano": "Aktualisiert ",
    "Dodeljeno": "Zugeteilt",
    "Prvo pregledavanje": "Gelesen erstmals",
    "Poslednja aktivnost": "Letzte Aktivität",
    "Termin": "Termin",
    "Nezainteresovanost": "Kein Interesse",
    "Ubersicht": "Übersicht",
    "Preis": "Preis",
    "Ausstattung": "Ausstattung",
    "Texte": "Texte",
    "Multimedia": "Multimedia",
    "Freigaben": "Freigaben",
    "Interessenten": "Interessenten",
    "Matching": "Matching",
    "Abgeschlossesn": "Abgeschlossen",
    "Gesamt": "Gesamt",
    "Besichtigungen": "Besichtigungen",
    "Titelbild": "Titelbild",
    "Eckdaten (osnovni podaci)": "Eckdaten",
    "Vrsta upotrebe": "Nutzungsart",
    "Tip marketinga": "Vermarktungsart",
    "Povrsina": "Fläche",
    "Adresa / Prodavac": "Verkäufer Adresse",
    "broj": "Nummer",
    "PTT broj": "PLZ",
    "Hard Facts": "Hard Facts",
    "objekat kreiran": "Objekt erstellt",
    "broj dana u prodaji": "Tage im Verkauf",
    "broj redukcija cene": "Preisreduktionen",
    "ukupan iznos redukcija cene": "Preisreduktion Betrag",
    "poslednja promena u objektu": "Letzte Änderung ",
    "poslednja promena cene": "Letzte Preisänderung",
    "poslednja promena oglasa": "Letzte Inseratänderung",
    "datum poslednjeg sastanka": "Letztes Verkäufer PG",
    "Checkliste Information": "Checkliste",
    "Naslov": "Titel",
    "Opis interneta": "Beschreibung Internet",
    "Kompletna adresa": "Volle Adresse",
    "Tip objekta": "Objektart",
    "Checkliste Dokumente": "Checkliste Dokumente",
    "Plan stana": "Grundriss",
    "Vazi samo za AT verziju": "Gilt nur für AT",
    "Kupoprodajni ugovor (Vazi samo za AT verziju)": "Kaufanbot (Gilt nur für AT)",
    "Energetski sertifikat": "Energieausweis",
    "Protokol sastanka": "Protokoll Eigentümerversammlung",
    "Polje za interne informacije": "Interne Kommentare",
    "Broj telefona": "Tel. Nr.",
    "Mail adresa agenta": "Makler Mail Adresse",
    "Aktivitaten": "Aktivitaten",
    "Dashboard": "Dashboard",
    "First date": "First Date",
    "Meine Interesent": "Meine Interessenten",
    "Reports": "Berichte",
    "Objects": "Objekte",
    "New object": "Neues Objekt",
    "Contacts": "Kontakt",
    "Verkaufer Kommunikation": "Verkäufer ",
    "My Objects": "Meine Objekte",
    "New Object": "Neues Objekt",
    "First Date": "First Date",
    "Single View": "Einzelansicht",
    "Objekat kreiran": "Objekt erstellt",
    "Broj dana u prodaji": "Tage im Verkauf",
    "Broj redukcija cene": "Preisreduktion Anzahl",
    "Ukupan iznos redukcija cene": "Preisreduktion Gesamtbetrag",
    "Poslednja promena u objektu": "Letzte Änderung Objekt",
    "Poslednja promena cene": "Letzte Änderung Preis",
    "Poslednja promena oglasa": "Letzte Änderung Inserat",
    "Datum poslednjeg sastanka": "Letztes PG Verkäufer",
    "Broj": "Nummer",
    "Activity": "Aktivität",
    "New Sale": "Neuer Verkauf",
    "Amount": "Betrag",
    "Confirm": "Bestätigen",
    "Search": "Suche",
    "Monthly Reports": "Monatlicher Bericht",
    "Settings": "Einstellungen",
    "Inbox": "Posteingang",
    "Logout": "Logout",
    "North": "Nord",
    "East": "Ost",
    "South": "Süd",
    "West": "West",
    "Don't export": "Nicht exportieren",
    "PLZ & Ort": "PLZ & Ort",
    "ZIP code, place & area": "PLZ, Ort & Gebiet",
    "ZIP code, city, street number, stairs, apartment number": "Postleitzahl, Ort, Hausnummer, Treppe, Wohnungsnummer",
    "Office": "Büro / Praxis",
    "Retail trade": "Einzelhandel",
    "Commercial leisure property": "Freizeitimmobilie gewerblich",
    "Plot of land": "Grundstück",
    "House": "Haus",
    "Agriculture and Forestry": "Land und Forstwirtschaft",
    "Other": "Sonstige / Sonderobjekte",
    "Apartment": "Wohnung",
    "Room": "Zimmer",
    "Apartment building": "Zinshaus / Renditeobjekt",
    "Exclusive right to sell": "Mandat Exklusiv",
    "Co-exclusive right to sell": "--------------------",
    "Simple mediation": "Mandat Basic",
    "Verbal": "Mündlich",
    "Written": "Schriftlich",
    "Written (both parties)": "---------------------",
    "Old contruction": "Altbau",
    "New contruction": "Neubau",
    "Object for demolition": "Abrissobjekt",
    "Dilapitated object": "Baufällig",
    "Load-bearing structure": "Entkernt",
    "First occupancy": "Erstbezug",
    "Maintained": "Gepflegt",
    "Modernized": "Modernisiert",
    "By arrangement": "Nach Vereinbarung",
    "As good as new": "Neuwertig",
    "Projected": "Projektiert",
    "In need of renovation": "Renovierungsbedürftig",
    "Shell construction": "Rohbau",
    "Partially renovated": "Teilrenoviert",
    "Partially refurbished": "Teilsaniert",
    "Fully renovated": "Vollrenoviert",
    "Fully refurbished": "Vollsaniert",
    "Building constructed on land not owned by the landowner": "Gebäude, das auf Grundstücken errichtet wurde, die nicht dem gehören",
    "Single owner": "Alleineigentum",
    "Co-ownership": "Miteigentum",
    "Co-ownership with usage rights": "Miteigentum mit Benutzungsregelung",
    "Home ownership": "Wohnungseigentum",
    "Home ownership planned": "Wohnungseigentum geplant",
    "Ownership of home in development": "Wohnungseigentum in Entstehung",
    "Building law": "Baurecht",
    "Bathroom": "Bad",
    "Business facility": "Geschäftseinrichtung",
    "Kitchenette": "Kochnische",
    "Kitchen": "Küche",
    "Furnished": "Möbliert",
    "Unfurnished": "Nicht möbliert",
    "Partially furnished": "Teilmöblierung",
    "Very good": "Sehr gut",
    "Good": "Gut",
    "Average": "Durchschnittlich",
    "Bad": "Schlecht",
    "Absolute silent location": "Absolute Ruhelage",
    "Possible silence impairment": "Beeinträchtigung gegeben",
    "Average silence impairment": "Durchschnittliche Beeinträchtigung",
    "Small silence impairment": "Geringe Beeiträchtigung",
    "Peaceful location": "Ruhelage",
    "Partially closed": "Teilerschlossen",
    "Undeveloped": "Unterschlossen",
    "Fully developed": "Vollerschlossen",
    "% of purchase price": "% vom Kaufpreis",
    "Centrally accessible": "Zentral begehbar",
    "Partly centrally accessible": "Teilweise zentral begehbar",
    "Circular walkable": "Kreisförmig begehbar",
    "Loft": "Loft",
    "Bauweise / Konstrukcija": "Bauweise",
    "Prefabricated part": "Fertigteil",
    "Wooden": "Holz",
    "Solid": "Massiv",
    "Reinforced concrete": "Stahlbeton",
    "Brick": "Ziegel",
    "Boden / Pod": "Boden",
    "Floorboards": "Dielen",
    "Raised floor": "Doppelboden",
    "Screed": "Estrich",
    "Prefabricated parquet": "Fertigparkett",
    "Tiles": "Fließen",
    "Granite": "Granit",
    "Plastic floor": "Kunsstoffboden",
    "Laminate": "Laminat",
    "Linoleum": "Linoleum",
    "Marble": "Marmor",
    "Parquet": "Parkett",
    "Stone floor": "Steinboden",
    "Carpet": "Tepichboden",
    "Carpet tiles": "Tepichfließen",
    "Terracotta": "Terracotta",
    "Verwarming / Grejanje": "Heizung",
    "Floor Heating": "Etagenheizung",
    "District Heating": "Fernwärme",
    "Underfloor Heating": "Fußbodenheizung",
    "Stove": "Heizofen",
    "Fireplace": "Kamin",
    "Central Heating": "Zentralheizung",
    "Heizungsart / Tip grejanja": "Heizungsart",
    "Alternative": "Alternativ",
    "Block type thermal power station": "Blockheizkraftwerk",
    "Electric": "Elektro",
    "Geothermal energy": "Erdwärme",
    "District heating": "Fernwärme",
    "Liquified gas": "Flüssiggas",
    "Gas": "Gas",
    "Coal": "Kohle",
    "Air heat pump": "Luftwärmepumple",
    "Oil": "Heizöl",
    "Pellets": "Pellets",
    "Solar energy": "Solarenergie",
    "Water electric": "Wasser-Elektro",
    "Ausblick / Pogled": "Ausblick",
    "Mountain view": "Bergblick",
    "Distant view": "Fernblick",
    "Green view": "Grünblick",
    "Sea view": "Meerblick",
    "Lake view": "Seeblick",
    "City view": "Stadtblick",
    "Fenster / Prozor": "Fenster",
    "External sun protection": "Außen Sonnenschutz",
    "Anti-glare protection": "Blendschutz",
    "Double or multiple glazing": "Doppelt oder Mehrfachverglasung",
    "Internal sun protection": "Innen Sonnenschutz",
    "Plastic windows": "Kunststofffenster",
    "Openable windows": "Öffenbare Fenster",
    "Shutters": "Rollladen",
    "Soundproof windows": "Schallschutzfenster",
    "Fernsehen / Televizija": "TV",
    "DVBT": "DVBT",
    "Cable or satellite TV": "Kabel / Satellit",
    "Küche / Kuhinja": "Küche ",
    "Equipped kitchen": "Einbauküche",
    "Tea kitchen": "Teeküche",
    "Open kitchen": "Offene Küche",
    "Extras / Dodaci": "Extras",
    "Storage room": "Abstellraum",
    "Alarm system": "Alarmanlage",
    "Outdoor shower": "Außendusche",
    "Library": "Bibliothek",
    "Steam bath": "Dampfbad",
    "Bike room": "Fahrradraum",
    "Gym": "Fitnessraum",
    "Use of garden": "Gartennutzung",
    "Barbecue area": "Grillbereich",
    "Lifting platform": "Hebebühne",
    "Children playground": "Kinderspielplatz",
    "Crane": "Kran",
    "Ramp": "Rampe",
    "Wheelchair accessible": "Rollstuhlgerecht",
    "Sauna": "Sauna",
    "Lake access": "Seezugang",
    "Suitable for seniors": "Seniorengerecht",
    "Security camera": "Sicherheitskamera",
    "Sports facilities": "Sporteinrichtungen",
    "Swimming pool": "Schwimmbecken ",
    "Close to subway": "U-Bahn Nähe",
    "changingRooms": "Umkleideraum",
    "Underground car park": "Tiefgarage",
    "Units reception": "Rezeption / Empfang",
    "Washing drying room": "Wasch / Trockenraum",
    "Wine cellar": "Weinkeller",
    "Wellness Area": "Wellness Bereich",
    "Jacuzzi": "Jacuzzi",
    "Dachform / Oblik krova": "Dachform",
    "Flat roof": "Flachdach",
    "Half hip roof": "Krüppelwalmdach",
    "Mansard roof": "Mansarddach",
    "Monopitch roof": "Pultdach",
    "Pyramid roof": "Pyramidendach",
    "Gable roof": "Satteldach",
    "Fahrstuhl / Lift": "Aufzug",
    "Freight elevator": "Lastenaufzug",
    "Elevator": "Lift",
    "Belüftung / Ventilacija": "Belüftung",
    "Fan coil unit": "Gebläsekonvektor (Fan Coil)",
    "Air conditioner": "Klimaanlage",
    "Controlled ventilation": "Kontrollierte Wohnraumbelüftung",
    "Cooling": "Kühlung",
    "Mechanical ventialtion": "Mechanische Be- und Entlüftung",
    "Balkon / Balkon": "Balkon",
    "North balcony": "Nordseitig",
    "North east balcony": "Nord-Ostseitig",
    "North west balcony": "Nord-Westseitig",
    "East balcony": "Ostseitig",
    "South east balcony": "Süd-Ostseitig",
    "South west balcony": "Süd-Westseitig",
    "West balcony": "Westseitig",
    "Beleuchtung / Osvetljenje": "Beleuchtung",
    "Suspended mirror louvre lights": "Abgependelte Spiegelrasterleuchten",
    "Lighting suitable for workplace": "Arbeitsplatzgerechte Beleuchtung",
    "Ceiling lights": "Deckenbeleuchtung",
    "Floor lamps": "Stehleuchten",
    "WCs / Toaleti": "WC`s",
    "Guest toilet": "Gäste WC",
    "Separate toilets": "Getrenntes WC",
    "Urinal": "Pissoir",
    "Toilet": "Toilette",
    "Gastronomie / Gastronomija": "Gastronomie",
    "On site bar": "Angeschlossene Bar",
    "On site hotel restaurant": "Angeschlossenes Hotelrestaurant",
    "Brewery connection": "Brauereianbindung",
    "Guest terrace": "Gästeterrasse",
    "Canteen or cafeteria": "Kantine / Cafeteria",
    "Services / Usluge": "Serviceleistungen",
    "Serviced apartments": "Betreutes Wohnen",
    "Catering": "Catering",
    "Shopping service": "Einkaufsservice",
    "Cleaning service": "Reinigungsservice",
    "Guards service": "Wachdienst",
    "Expansion stage / *": "Ausbaustufe",
    "Bare bone house": "Ausbauhaus",
    "Kit house": "Bausatzhaus",
    "Key in hand with baseplate": "Schlüsselfertig mit Bodenplatte",
    "Key in hand with cellar": "Schlüsselfertig mit Keller",
    "Key in hand without baseplate": "Schlüsselfertig ohne Bodenplatte",
    "Energietyp / Tip energije": "Energietyp",
    "House efficiency 40": "KfW Effizienzhaus 40",
    "House efficiency 55": "KfW Effizienzhaus 55",
    "House efficiency 60": "KfW Effizienzhaus 60",
    "House efficiency 70": "KfW Effizienzhaus 70",
    "Minergie construction": "Minergie Bauweise",
    "Minergie certified": "Minergie zertifiziert",
    "New building standard": "Neubaustandard",
    "Low energy house": "Niedrigenergiehaus",
    "Passive house": "Passiv Haus",
    "Räume / Spaces": "Räume",
    "Double doors": "Flügeltüren",
    "Rooms can be changed": "Räume veränderbar",
    "Soundproof panels": "Schallschutz-Paneele",
    "Plaster": "Stuck",
    "Plaster board partitions": "Trennwände Gipskarton",
    "Glass partitions": "Trennwände Glas",
    "Student friendly apartment": "WG geeignet",
    "Elektrik / Električna energija": "Elektrik",
    "Floor boxes": "Bodendosen",
    "DVEDP cabling": "DV / EDV Verkabelung",
    "Fiber optic connection": "Glasfaseranbindung",
    "IT cabling Cat 5": "IT Verkabelung Cat 5",
    "IT cabling Cat 6": "IT Verkabelung Cat 6",
    "IT cabling Cat 7": "IT Verkabelung Cat 7",
    "Cable ducts along parapet": "Kabelkanäle entlang Parapet",
    "Cable ducts along plaster": "Kabelkanäle Oberputz",
    "Flush mounted cable ducts": "Kabelkanäle Unterputz",
    "Bad / Kupatilo": "Bad",
    "Bathroom with window": "Bad mit Fenster",
    "Bathroom with toilet": "Bad mit WC",
    "Bathtub": "Badewanne",
    "Bidet": "Bidet",
    "Shower": "Dusche",
    "Stellplatzart / Vrsta parking mesta": "Autostellplatz",
    "Carport": "Carport",
    "Private garage": "Garage",
    "Shared garage": "Parkhaus",
    "Parking spot": "Parkplatz",
    "Duplex garage": "Doppelgarage ",
    "Urkunden / Sertifikati": "Urkunden",
    "Building permit": "Baubewilligung",
    "Deed of ownership": "Besitzurkunde",
    "Approval decision": "Zulassungsbescheid",
    "New": "Neu",
    "Days 01-45": "Tage 01-45",
    "Days 46-90": "Tage 46-90",
    "Days 91-180": "Tage 91-180",
    "Days >180": "Tage >180",
    "My First dataset": "Meine First Dates",
    "Incoming phone call": "Telefon eingehend",
    "Outgoing phone call": "Telefon ausgehend",
    "Incoming mail": "Mail eingehend",
    "Outgoing mail": "Mail ausgehend",
    "Status changed": "Status geändert",
    "UNEXPECTED ERROR": "UNEXPECTED ERROR",
    "Filter": "Filter",
    "Login": "Login",
    "Already have an account": "Benutzer bereits vorhanden",
    "Full Name": "Voller Name",
    "Email": "Email",
    "A problem": "Problem",
    "Click here": "Hier klicken",
    "SAVE": "SPEICHERN",
    "Besichtigung": "Besichtigung",
    "Kaufinteresse": "Kaufinteresse",
    "Kaufanbot": "Kaufanbot",
    "NEU": "NEU",
    "AKTIV": "AKTIV",
    "Retail": "Einzelhandel",
    "Hospitality": "Gastfreundschaft",
    "Plot": "Parzelle",
    "Industry": "Industrie",
    "Agriculture and forestry": "Land-und Forstwirtschaft",
    "Park": "Park",
    "Kaufverhandlung": "Kaufverhandlung",
    "In-Bearbeitung": "In-Bearbeitung",
    "Interesse": "Interesse",
    "Kaufabsicht": "Kaufabsicht",
    "Save": "Speichern",
    "Kaufanbote": "Kaufanbote",
    "Besichtigungsquote": "Besichtigungsquote",
    "Kundigungen": "Kundigungen",
    "Clear": "Frei",
    "Austria": "Österreich",
    "Germany": "Deutschland",
    "Switzerland": "Schweiz",
    "Vienna": "Wien",
    "Lower Austria": "Niederösterreich",
    "Upper Austria": "Oberösterreich",
    "Tyrol": "Tirol",
    "Carinthia": "Kärnten",
    "Salzburg": "Salzburg",
    "Vorarlberg": "Vorarlberg",
    "Burgenland": "Burgenland",
    "Baden-Württemberg": "Badenwürttemberg",
    "Bavaria": "Bavaria",
    "Berlin": "Berlin",
    "Hesse": "Hessen",
    "Lower Saxony": "Niedersachsen",
    "Mecklenburg-Western Pomerania": "Mecklenburg-Vorpommern",
    "North Rhine-Westphalia": "Nordrhein-Westfalen",
    "Rhineland-Palatinate": "Rheinland-Pfalz",
    "Saxony-Anhalt": "Sachsen-Anhalt",
    "Schleswig-Holstein": "Schleswig-Holstein",
    "Thuringia": "Thüringen",
    "Zürich": "Zürich",
    "Bern": "Bern",
    "Luzern": "Luzern",
    "Uri": "Uri",
    "Schwyz": "Schwyz",
    "Unterwalden": "Unterwalden",
    "Glarus": "Glarus",
    "Zug": "Zug",
    "Freiburg": "Freiburg",
    "Solothurn": "Solothurn",
    "Schaffhausen": "Schaffhausen",
    "Appenzell": "Appenzell",
    "Sankt Gallen": "Sankt Gallen",
    "Graubünden": "Graubünden",
    "Aargau": "Aargau",
    "Thurgau": "Thurgau",
    "Ticino": "Tessin",
    "Vaud": "Waadt",
    "Valais": "Wallisellen",
    "Neuchâtel": "Neuenburgersee",
    "Genève": "Genf",
    "Jura": "Jura",
    "ID Interesent": "ID Interesent",
    "E-mail": "Email",
    "Objekat ID": "Objekt Id",
    "Podaci o nekretnini": "Immobiliendaten",
    "Datum upita": "Abfragedatum",
    "Posl. promene": "Letzte Bearbeitung",
    "Upload": "Laden",
    "Property has been saved successfully": "Eigentum wurde erfolgreich gespeichert",
    "fill all required fields!": "Füllen Sie alle Pflichtfelder aus!",
    "internal server error": "interner Serverfehler",
    "Property has been updated successfully": "Die Eigenschaft wurde erfolgreich aktualisiert",
    "Inquiry status": "Status",
    "Please select type": "Untertyp wählen",
    "Mail RR": "Keine Tel.Nr."
};
import React from 'react';
import './ChartLabel.scss';

const ChartLabel = (props) => {
    if (props.color) {
        return (
            <div className="p-grid noBorder spacing">
                <span className="p-col-2 chart-label-icon" style={{backgroundColor:`${props.color}`}}></span>
                <span className="p-col-8 name">{props.name}</span>
                <span className="p-col-2 value">{props.value}</span>
            </div>
        )
    } else {
        return (
            <div className="p-grid noBorder">
                <span className="p-col-8 name">{props.name}</span>
                <span className="p-col-2 value">{props.value}</span>
            </div>
        )   
    }
}

export default ChartLabel;
import i18next from '../i18n';

export const sideOfTheWorldOptions = [
    { label: i18next.t('North'), value: 'North' },
    { label: i18next.t('East'), value: 'East' },
    { label: i18next.t('South'), value: 'South' },
    { label: i18next.t('West'), value: 'West' },
];

export const publishAddressTemplateOptions = [
    {
        label: i18next.t("Don't export"),
        value: "Don't show/export",
    },
    {
        label: i18next.t('PLZ & Ort'),
        value: 'PLZ & Ort',
    },
    {
        label: i18next.t('ZIP code, place & area'),
        value: 'ZIP code, place & area',
    },
    {
        label: i18next.t(
            'ZIP code, city, street number, stairs, apartment number',
        ),
        value: 'ZIP code, city, street number, stairs, apartment number',
    },
];

// export const countries = [
//     {
//         label: i18next.t('Austria'),
//         value: 1,
//     },
//     {
//         label: i18next.t('Germany'),
//         value: 2,
//     },
//     {
//         label: i18next.t('Switzerland'),
//         value: 3,
//     },
// ];

export const states = [
    {
        label: i18next.t('Austria'),
        code: 'AT',
        items: [
            { label: i18next.t('Vienna'), value: 1 },
            { label: i18next.t('Lower Austria'), value: 2 },
            { label: i18next.t('Upper Austria'), value: 3 },
            { label: i18next.t('Tyrol'), value: 4 },
            { label: i18next.t('Carinthia'), value: 5 },
            { label: i18next.t('Salzburg'), value: 6 },
            { label: i18next.t('Vorarlberg'), value: 7 },
            { label: i18next.t('Burgenland'), value: 8 },
            { label: i18next.t('Steiermark'), value: 9 },
        ],
    },
    {
        label: i18next.t('Germany'),
        code: 'DE',
        items: [
            { label: i18next.t('Baden-Württemberg'), value: 10 },
            { label: i18next.t('Bayern'), value: 11 },
            { label: i18next.t('Berlin'), value: 12 },
            { label: i18next.t('Brandenburg'), value: 13 },
            { label: i18next.t('Bremen'), value: 14 },
            { label: i18next.t('Hamburg'), value: 15 },
            { label: i18next.t('Hessen'), value: 16 },
            { label: i18next.t('Mecklenburg-Vorpommern'), value: 17 },
            { label: i18next.t('Niedersachsen'), value: 18 },
            { label: i18next.t('Nordrhein-Westfalen'), value: 19 },
            { label: i18next.t('Rheinland-Pfalz'), value: 20 },
            { label: i18next.t('Saarland'), value: 21 },
            { label: i18next.t('Sachsen-Anhalt'), value: 22 },
            { label: i18next.t('Sachsen'), value: 23 },
            { label: i18next.t('Schleswig-Holstein'), value: 24 },
            { label: i18next.t('Thüringen'), value: 25 },
        ],
    },
    {
        label: i18next.t('Switzerland'),
        code: 'CH',
        items: [
            { label: i18next.t('Thuringia'), value: 26 },
            { label: i18next.t('Zürich'), value: 27 },
            { label: i18next.t('Bern'), value: 28 },
            { label: i18next.t('Luzern'), value: 29 },
            { label: i18next.t('Uri'), value: 30 },
            { label: i18next.t('Schwyz'), value: 31 },
            { label: i18next.t('Unterwalden'), value: 32 },
            { label: i18next.t('Glarus'), value: 33 },
            { label: i18next.t('Zug'), value: 34 },
            { label: i18next.t('Freiburg'), value: 35 },
            { label: i18next.t('Solothurn'), value: 36 },
            { label: i18next.t('Schaffhausen'), value: 37 },
            { label: i18next.t('Appenzell'), value: 38 },
            { label: i18next.t('Sankt Gallen'), value: 39 },
            { label: i18next.t('Graubünden'), value: 40 },
            { label: i18next.t('Aargau'), value: 41 },
            { label: i18next.t('Thurgau'), value: 42 },
            { label: i18next.t('Ticino'), value: 43 },
            { label: i18next.t('Vaud'), value: 44 },
            { label: i18next.t('Valais'), value: 45 },
            { label: i18next.t('Neuchâtel'), value: 46 },
            { label: i18next.t('Genève'), value: 47 },
            { label: i18next.t('Jura'), value: 48 },
        ],
    },
];

export const floorOptions = [
    { label: '4.KG', value: '4.KG' },
    { label: '3.KG', value: '3.KG' },
    { label: '2.KG', value: '2.KG' },
    { label: '1.KG', value: '1.KG' },
    { label: 'EG', value: 'EG' },
    { label: '1.Etage', value: '1.Etage' },
    { label: '2.Etage', value: '2.Etage' },
    { label: '3.Etage', value: '3.Etage' },
    { label: '4.Etage', value: '4.Etage' },
    { label: '5.Etage', value: '5.Etage' },
    { label: '6.Etage', value: '6.Etage' },
    { label: '7.Etage', value: '7.Etage' },
    { label: '8.Etage', value: '8.Etage' },
    { label: '9.Etage', value: '9.Etage' },
    { label: '10.Etage', value: '10.Etage' },
    { label: '11.Etage', value: '11.Etage' },
    { label: '12.Etage', value: '12.Etage' },
    { label: '13.Etage', value: '13.Etage' },
    { label: '14.Etage', value: '14.Etage' },
    { label: '15.Etage', value: '15.Etage' },
    { label: '16.Etage', value: '16.Etage' },
    { label: '17.Etage', value: '17.Etage' },
    { label: '17-20 Etage', value: '17-20 Etage' },
    { label: '21-25 Etage', value: '21-25 Etage' },
    { label: '26-30 Etage', value: '17-20 Etage' },
    { label: '31-35 Etage', value: '31-35 Etage' },
    { label: '36-40 Etage', value: '36-40 Etage' },
    { label: '40+ Etage', value: '40+ Etage' },
];

export const klasseOptions = [
    // { label: 'A++', value: 'A++' },
    { label: 'A+', value: 'A+' },
    { label: 'A', value: 'A' },
    { label: 'B', value: 'B' },
    { label: 'C', value: 'C' },
    { label: 'D', value: 'D' },
    { label: 'E', value: 'E' },
    { label: 'F', value: 'F' },
    { label: 'G', value: 'G' },
    { label: 'H', value: 'H' },
];

export const energyCertificateYear = [
    { label: 'bis 30. April 2014 (EnEV 2009)', value: '2008' },
    { label: 'ab 1. Mai 2014 (EnEV 2014)', value: '2014' },
];

export enum EnergyCertificateType {
    REQUIREMENT_CERTIFICATE = 'Bedarfsausweis',
    CONSUMPTION_CERTIFICATE = 'Verbrauchsausweis',
}

export const energyCertificateTypeOptions = [
    {
        label: 'Bedarfsausweis',
        value: EnergyCertificateType.REQUIREMENT_CERTIFICATE,
    },
    {
        label: 'Verbrauchsausweis',
        value: EnergyCertificateType.CONSUMPTION_CERTIFICATE,
    },
];

export const mediationOrderTypeOptions = [
    {
        label: i18next.t('Exclusive right to sell'),
        value: 'Exclusive right to sell',
    },
    {
        label: i18next.t('Co-exclusive right to sell'),
        value: 'Co-exclusive right to sell',
    },
    // { label: i18next.t('Simple mediation'), value: 'Simple mediation' },
];

export const agreementTypeOptions = [
    { label: i18next.t('Verbal'), value: 'Verbal' },
    { label: i18next.t('Written'), value: 'Written' },
    {
        label: i18next.t('Written (both parties)'),
        value: 'Written (both parties)',
    },
];

export const typeOptions = [
    { label: i18next.t('Old contruction'), value: 'Old contruction' },
    { label: i18next.t('New contruction'), value: 'New contruction' },
];

export const statusOptions = [
    {
        label: i18next.t('Object for demolition'),
        value: i18next.t('Object for demolition'),
    },
    {
        label: i18next.t('Dilapitated object'),
        value: i18next.t('Dilapitated object'),
    },
    {
        label: i18next.t('Load-bearing structure'),
        value: i18next.t('Load-bearing structure'),
    },
    {
        label: i18next.t('First occupancy'),
        value: i18next.t('First occupancy'),
    },
    { label: i18next.t('Maintained'), value: i18next.t('Maintained') },
    { label: i18next.t('Modernized'), value: i18next.t('Modernized') },
    { label: i18next.t('By arrangement'), value: i18next.t('By arrangement') },
    { label: i18next.t('As good as new'), value: i18next.t('As good as new') },
    { label: i18next.t('Projected'), value: i18next.t('Projected') },
    {
        label: i18next.t('In need of renovation'),
        value: i18next.t('In need of renovation'),
    },
    {
        label: i18next.t('Shell construction'),
        value: i18next.t('Shell construction'),
    },
    {
        label: i18next.t('Partially renovated'),
        value: i18next.t('Partially renovated'),
    },
    {
        label: i18next.t('Partially refurbished'),
        value: i18next.t('Partially refurbished'),
    },
    {
        label: i18next.t('Fully renovated'),
        value: i18next.t('Fully renovated'),
    },
    {
        label: i18next.t('Fully refurbished'),
        value: i18next.t('Fully refurbished'),
    },
];

export const houseConditionOptions = [
    {
        label: i18next.t('Object for demolition'),
        value: 'Object for demolition',
    },
    { label: i18next.t('Dilapitated object'), value: 'Dilapitated object' },
    {
        label: i18next.t('Load-bearing structure'),
        value: 'Load-bearing structure',
    },
    { label: i18next.t('First occupancy'), value: 'First occupancy' },
    { label: i18next.t('Maintained'), value: 'Maintained' },
    { label: i18next.t('Modernized'), value: 'Modernized' },
    { label: i18next.t('By arrangement'), value: 'By arrangement' },
    { label: i18next.t('As good as new'), value: 'As good as new' },
    { label: i18next.t('Projected'), value: 'Projected' },
    {
        label: i18next.t('In need of renovation'),
        value: 'In need of renovation',
    },
    { label: i18next.t('Shell construction'), value: 'Shell construction' },
    { label: i18next.t('Partially renovated'), value: 'Partially renovated' },
    {
        label: i18next.t('Partially refurbished'),
        value: 'Partially refurbished',
    },
    { label: i18next.t('Fully renovated'), value: 'Fully renovated' },
    { label: i18next.t('Fully refurbished'), value: 'Fully refurbished' },
    {
        label: i18next.t(
            'Building constructed on land not owned by the landowner',
        ),
        value: 'Building constructed on land not owned by the landowner',
    },
];

export const formOfOwnershipOptions = [
    { label: i18next.t('Single owner'), value: 'Single owner' },
    { label: i18next.t('Co-ownership'), value: 'Co-ownership' },
    {
        label: i18next.t('Co-ownership with usage rights'),
        value: 'Co-ownership with usage rights',
    },
    { label: i18next.t('Home ownership'), value: 'Home ownership' },
    {
        label: i18next.t('Home ownership planned'),
        value: 'Home ownership planned',
    },
    {
        label: i18next.t('Ownership of home in development'),
        value: 'Ownership of home in development',
    },
    { label: i18next.t('Building law'), value: 'Building law' },
    {
        label: i18next.t(
            'Building constructed on land not owned by the landowner',
        ),
        value: 'Building constructed on land not owned by the landowner',
    },
];

export const furnishingOptions = [
    { label: i18next.t('Bathroom'), value: 'Bathroom' },
    { label: i18next.t('Business facility'), value: 'Business facility' },
    { label: i18next.t('Kitchenette'), value: 'Kitchenette' },
    { label: i18next.t('Kitchen'), value: 'Kitchen' },
    { label: i18next.t('Furnished'), value: 'Furnished' },
    { label: i18next.t('Unfurnished'), value: 'Unfurnished' },
    { label: i18next.t('Furnished'), value: 'Furnished' },
    { label: i18next.t('Optional'), value: 'Optional' },
    { label: i18next.t('Partially furnished'), value: 'Partially furnished' },
];

export const situationAssesmentOptions = [
    { label: i18next.t('Very good'), value: 'Very good' },
    { label: i18next.t('Good'), value: 'Good' },
    { label: i18next.t('Average'), value: 'Average' },
    { label: i18next.t('Bad'), value: 'Bad' },
];

export const conditionAssesmentOptions = [
    { label: i18next.t('Very good'), value: 'Very good' },
    { label: i18next.t('Good'), value: 'Good' },
    { label: i18next.t('Average'), value: 'Average' },
    { label: i18next.t('Bad'), value: 'Bad' },
];

export const noiseLevelOptions = [
    {
        label: i18next.t('Absolute silent location'),
        value: 'Absolute silent location',
    },
    {
        label: i18next.t('Possible silence impairment'),
        value: 'Possible silence impairment',
    },
    {
        label: i18next.t('Average silence impairment'),
        value: 'Average silence impairment',
    },
    {
        label: i18next.t('Small silence impairment'),
        value: 'Small silence impairment',
    },
    { label: i18next.t('Peaceful location'), value: 'Peaceful location' },
];

export const developmentOptions = [
    { label: i18next.t('Partially closed'), value: 'Partially closed' },
    { label: i18next.t('Undeveloped'), value: 'Undeveloped' },
    { label: i18next.t('Fully developed'), value: 'Fully developed' },
];

export const priceCalculationOptions = [
    { label: i18next.t('% of purchase price'), value: '% of purchase price' },
];

export const floorPlanOptions = [
    { label: i18next.t('Centrally accessible'), value: 'Centrally accessible' },
    {
        label: i18next.t('Partly centrally accessible'),
        value: 'Partly centrally accessible',
    },
    { label: i18next.t('Circular walkable'), value: 'Circular walkable' },
    { label: i18next.t('Loft'), value: 'Loft' },
];

export const propertyTypeOptions = [
    { label: i18next.t('Apartment'), value: 11 },
    { label: i18next.t('House'), value: 6 },
    { label: i18next.t('Grundstück'), value: 5 },
    { label: i18next.t('Apartment building'), value: 13 },
    // { label: i18next.t('Plot'), value: 14 },
    { label: i18next.t('Office'), value: 1 },
    { label: i18next.t('Retail'), value: 2 },
    { label: i18next.t('Gastwirtschaft'), value: 4 }, /// Hospitality
    { label: i18next.t('Commercial leisure property'), value: 3 },
    { label: i18next.t('Industry'), value: 7 },
    { label: i18next.t('Agriculture and forestry'), value: 8 },
    { label: i18next.t('Room'), value: 12 },
    { label: i18next.t('Park'), value: 9 },
    { label: i18next.t('Other'), value: 10 },
];

export const propertySubtypeOptions = [
    {
        label: i18next.t('Office'),
        id: 1,
        items: [
            { label: i18next.t('Ausstellungsfläche'), value: 1 },
            { label: i18next.t('Bürofläche'), value: 2 },
            { label: i18next.t('Bürohaus'), value: 3 },
            { label: i18next.t('Bürozentrum'), value: 4 },
            { label: i18next.t('Coworking Space'), value: 5 },
            { label: i18next.t('Loft / Atelier'), value: 6 },
            { label: i18next.t('Praxis'), value: 7 },
            { label: i18next.t('Praxisfläche'), value: 8 },
            { label: i18next.t('Praxishaus'), value: 9 },
            { label: i18next.t('Shared Office'), value: 10 },
        ],
    },
    {
        label: i18next.t('Retail'),
        id: 2,
        items: [
            { label: i18next.t('Ausstellungsfläche'), value: 11 },
            { label: i18next.t('Einkaufszentrum'), value: 12 },
            { label: i18next.t('Einzelhandelsladen'), value: 13 },
            { label: i18next.t('Factory outlet'), value: 14 },
            { label: i18next.t('Kaufhaus'), value: 15 },
            { label: i18next.t('Kiosk'), value: 16 },
            { label: i18next.t('Ladenlokal'), value: 17 },
            { label: i18next.t('Verbrauchermarkt'), value: 18 },
            { label: i18next.t('Verkaufsfläche'), value: 19 },
        ],
    },
    {
        label: i18next.t('Commercial leisure property'),
        id: 3,
        items: [
            { label: i18next.t('Freizeitanlage'), value: 20 },
            { label: i18next.t('Sportanlagen'), value: 21 },
            { label: i18next.t('Vergnügungsparks / -center'), value: 22 },
        ],
    },
    {
        label: i18next.t('Hospitality'),
        id: 4,
        items: [
            { label: i18next.t('Bar'), value: 23 },
            { label: i18next.t('Café'), value: 24 },
            { label: i18next.t('Discothek'), value: 25 },
            { label: i18next.t('Gastronomie und Wohnung'), value: 26 },
            { label: i18next.t('Gastronomie/Restaurant'), value: 27 },
            { label: i18next.t('Hotels'), value: 28 },
            { label: i18next.t('Pensionen'), value: 29 },
            { label: i18next.t('weitere Beherbergungsbetriebe'), value: 30 },
        ],
    },
    {
        label: i18next.t('Plot'),
        id: 5,
        items: [
            { label: i18next.t('Freizeit'), value: 31 },
            { label: i18next.t('Gemischt'), value: 32 },
            { label: i18next.t('Gewerbepark'), value: 33 },
            { label: i18next.t('Großvolumiger Wohnbau'), value: 34 },
            { label: i18next.t('Industriebaugrund'), value: 35 },
            { label: i18next.t('Land- und Forstwirtschaft'), value: 36 },
            { label: i18next.t('Seeliegenschaft'), value: 37 },
            { label: i18next.t('Sondernutzung'), value: 38 },
            { label: i18next.t('Wohn- und Geschäftsbaugrund'), value: 39 },
        ],
    },
    {
        label: i18next.t('House'),
        id: 6,
        items: [
            { label: i18next.t('Apartmenthaus'), value: 40 },
            { label: i18next.t('Bauernhaus'), value: 41 },
            { label: i18next.t('Berghütte'), value: 42 },
            { label: i18next.t('Bungalow'), value: 43 },
            { label: i18next.t('Burg'), value: 44 },
            { label: i18next.t('Chalet'), value: 45 },
            { label: i18next.t('Doppelhaushälfte'), value: 46 },
            { label: i18next.t('Einfamilienhaus'), value: 47 },
            { label: i18next.t('Ferienhaus'), value: 48 },
            { label: i18next.t('Fertighaus'), value: 49 },
            { label: i18next.t('Finca'), value: 50 },
            { label: i18next.t('Herrenhaus'), value: 51 },
            { label: i18next.t('Landhaus'), value: 52 },
            { label: i18next.t('Laube-Datsche-Gartenhaus'), value: 53 },
            { label: i18next.t('Mehrfamilienhaus'), value: 54 },
            { label: i18next.t('Reiheneckhaus'), value: 55 },
            { label: i18next.t('Reihenendhaus'), value: 56 },
            { label: i18next.t('Reihenhaus'), value: 57 },
            { label: i18next.t('Reihenmittelhaus'), value: 58 },
            { label: i18next.t('Resthof'), value: 59 },
            { label: i18next.t('Schloss'), value: 60 },
            { label: i18next.t('Stadthaus'), value: 61 },
            { label: i18next.t('Strandhaus'), value: 62 },
            { label: i18next.t('Villa'), value: 63 },
            { label: i18next.t('Zweifamilienhaus'), value: 64 },
        ],
    },
    {
        label: i18next.t('Industry'),
        id: 7,
        items: [
            { label: i18next.t('Freiflächen'), value: 65 },
            { label: i18next.t('Halle'), value: 66 },
            { label: i18next.t('Hochregallager'), value: 67 },
            { label: i18next.t('Industriehalle'), value: 68 },
            { label: i18next.t('Kühlhaus'), value: 69 },
            { label: i18next.t('Lager'), value: 70 },
            { label: i18next.t('Lager mit Freifläche'), value: 71 },
            { label: i18next.t('Lagerflächen'), value: 72 },
            { label: i18next.t('Produktion / Betriebsobjekt'), value: 73 },
            { label: i18next.t('Service'), value: 74 },
            { label: i18next.t('Speditionslager'), value: 75 },
            { label: i18next.t('Werkstatt'), value: 76 },
        ],
    },
    {
        label: i18next.t('Agriculture and forestry'),
        id: 8,
        items: [
            { label: i18next.t('Ackerbau'), value: 77 },
            { label: i18next.t('Anwesen'), value: 78 },
            { label: i18next.t('Aussiedlerhof'), value: 79 },
            { label: i18next.t('Bauernhof'), value: 80 },
            { label: i18next.t('Gartenbau'), value: 81 },
            { label: i18next.t('Jagd und Forstwirtschaft'), value: 82 },
            { label: i18next.t('Jagdrevier'), value: 83 },
            { label: i18next.t('Landwirtschaftliche Betriebe'), value: 84 },
            { label: i18next.t('Reiterhof'), value: 85 },
            { label: i18next.t('Scheune'), value: 86 },
            {
                label: i18next.t('Sonstige Landwirtschaftsimmobilien'),
                value: 87,
            },
            { label: i18next.t('Teich / Fischzucht'), value: 88 },
            { label: i18next.t('Viehwirtschaft'), value: 89 },
            { label: i18next.t('Weinbau'), value: 90 },
        ],
    },
    {
        label: i18next.t('Park'),
        id: 9,
        items: [
            { label: i18next.t('Bootsliegeplatz'), value: 91 },
            { label: i18next.t('Carport'), value: 92 },
            { label: i18next.t('Doppelgarage'), value: 93 },
            { label: i18next.t('Duplex'), value: 94 },
            { label: i18next.t('Einzelgarage'), value: 95 },
            { label: i18next.t('Parkhaus'), value: 96 },
            { label: i18next.t('Parkplatz mit Ladestation'), value: 97 },
            { label: i18next.t('Stellplatz'), value: 98 },
            { label: i18next.t('Tiefgarage'), value: 99 },
            { label: i18next.t('Tiefgaragenstellplatz'), value: 100 },
        ],
    },
    {
        label: i18next.t('Other'),
        id: 10,
        items: [
            { label: i18next.t('Krankenhaus'), value: 101 },
            { label: i18next.t('Parkhaus'), value: 102 },
            { label: i18next.t('Sonstige'), value: 103 },
            { label: i18next.t('Tankstelle'), value: 104 },
        ],
    },
    {
        label: i18next.t('Apartment'),
        id: 11,
        items: [
            { label: i18next.t('Apartment'), value: 105 },
            { label: i18next.t('Dachgeschoß'), value: 106 },
            { label: i18next.t('Erdgeschoß'), value: 107 },
            { label: i18next.t('Etagenwohnung'), value: 108 },
            { label: i18next.t('Ferienwohnung'), value: 109 },
            { label: i18next.t('Galeriewohnung'), value: 110 },
            { label: i18next.t('Loft-Studio-Atelier'), value: 111 },
            { label: i18next.t('Maisonette'), value: 112 },
            { label: i18next.t('Penthouse'), value: 113 },
            { label: i18next.t('Rohdachboden'), value: 114 },
            { label: i18next.t('Souterrain'), value: 115 },
            { label: i18next.t('Terrassenwohnung'), value: 116 },
        ],
    },
    {
        label: i18next.t('Zimmer'),
        id: 12,
        items: [{ label: i18next.t('Zimmer'), value: 117 }],
    },
    {
        label: i18next.t('Apartment building'),
        id: 13,
        items: [
            { label: i18next.t('Betreutes Wohnen'), value: 118 },
            { label: i18next.t('Bürogebäude'), value: 119 },
            { label: i18next.t('Einkaufszentren'), value: 120 },
            { label: i18next.t('Geschäftshaus'), value: 121 },
            { label: i18next.t('Industrieanlagen'), value: 122 },
            { label: i18next.t('Mehrfamilienhaus'), value: 123 },
            { label: i18next.t('Pflegeheim'), value: 124 },
            { label: i18next.t('Sanatorium'), value: 125 },
            { label: i18next.t('Seniorenheim'), value: 126 },
            { label: i18next.t('Verbrauchermärkte'), value: 127 },
            { label: i18next.t('Wohn- und Geschäftshaus'), value: 128 },
            { label: i18next.t('Wohnanlagen'), value: 129 },
        ],
    },
];

export type FurnishingField = {
    attribute: string;
    label: string;
    value: boolean;
};

type FurnishingFieldsCollection = {
    label: string;
    attribute: string;
    fields: FurnishingField[];
};

export const constructionType = {
    prefabricatedPart: false,
    wooden: false,
    solid: false,
    reinforcedConcrete: false,
    brick: false,
};

export const roofShape = {
    flatRoof: false,
    halfHipRoof: false,
    mansardRoof: false,
    monopitchRoof: false,
    pyramidRoof: false,
    gableRoof: false,
    hipRoof: false,
};

export const expansionStage = {
    bareBoneHouse: false,
    kitHouse: false,
    keyInHandWithBaseplate: false,
    keyInHandWithCellar: false,
    keyInHandWithoutBaseplate: false,
};

export const floorType = {
    floorboards: false,
    raisedFloor: false,
    screed: false,
    prefabricatedParquet: false,
    tiles: false,
    granite: false,
    plasticFloor: false,
    laminate: false,
    linoleum: false,
    marble: false,
    parquet: false,
    stoneFloor: false,
    carpet: false,
    carpetTiles: false,
    terracotta: false,
};

export const elevator = {
    freightElevator: false,
    elevator: false,
};

export const heating = {
    alternative: false,
    blockTypeThermalPowerStation: false,
    electric: false,
    geothermalEnergy: false,
    districtHeating: false,
    liquifiedGas: false, //* LPG
    gas: false,
    wooden: false,
    coal: false,
    airHeatPump: false,
    oil: false,
    pellets: false,
    solarEnergy: false,
    waterElectric: false,
};

export const heatingType = {
    floorHeating: false,
    districtHeating: false,
    underfloorHeating: false,
    stove: false,
    fireplace: false,
    centralHeating: false,
};

export const ventilation = {
    fanCoilUnit: false,
    airCondtitioner: false,
    controlledVentilation: false,
    cooling: false,
    mechanicalVentialtion: false,
};

export const energyType = {
    houseEfficiency40: false,
    houseEfficiency55: false,
    houseEfficiency60: false,
    houseEfficiency70: false,
    minergieConstruction: false,
    minergieCertified: false,
    newBuildingStandard: false,
    lowEnergyHouse: false,
    passiveHouse: false,
};

export const view = {
    mountainView: false,
    distantView: false,
    greenView: false,
    seaView: false,
    lakeView: false,
    cityView: false,
};

export const balcony = {
    northBalcony: false,
    northEastBalcony: false,
    northWestBalcony: false,
    eastBalcony: false,
    southBalcony: false,
    southEastBalcony: false,
    southWestBalcony: false,
    westBalcony: false,
};

export const space = {
    doubleDoors: false,
    roomsCanBeChanged: false,
    soundproofPanels: false,
    plaster: false,
    plasterBoardPartitions: false,
    glassPartitions: false,
    studentFriendlyApartment: false,
};

export const window = {
    externalSunProtection: false,
    antiGlareProtection: false,
    doubleOrMultipleGlazing: false,
    internalSunProtection: false,
    plasticWindows: false,
    openableWindows: false,
    shutters: false,
    soundproofWindows: false,
};

export const lighting = {
    suspendedMirrorLouvreLights: false,
    lightingSuitableForWorkplace: false,
    ceilingLights: false,
    floorLamps: false,
};

export const electricEnergy = {
    floorBoxes: false,
    DvEdpCabling: false,
    fiberOpticConnection: false,
    ItCablingCat5: false,
    ItCablingCat6: false,
    ItCablingCat7: false,
    cableDuctsAlongParapet: false,
    cableDuctsAlongPlaster: false,
    flushMountedCableDucts: false,
};

export const tv = {
    dvbt: false,
    cableOrSatelliteTv: false,
};

export const toilet = {
    guestToilet: false,
    separateToilets: false,
    urinal: false,
    toilet: false,
};

export const bathroom = {
    bathroomWithWindow: false,
    bathroomWithToilet: false,
    bathtub: false,
    bidet: false,
    shower: false,
};

export const kitchen = {
    equippedKitchen: false,
    kitchenette: false,
    teaKitchen: false,
    bidet: false,
    shower: false,
    openKitchen: false,
};

export const gastronomy = {
    onSiteBar: false,
    onSiteHotelRestaurant: false,
    breweryConnection: false,
    guestTerrace: false,
    canteenOrCafeteria: false,
    openKitchen: false,
};

export const parkingSpaceType = {
    carport: false,
    privateGarage: false,
    sharedGarage: false,
    parkingSpot: false,
    duplexGarage: false,
    undergroundCarPark: false,
};

export const extras = {
    storageRoom: false,
    alarmSystem: false,
    outdoorShower: false,
    library: false,
    steamBath: false,
    bikeRoom: false,
    gym: false,
    useOfGarden: false,
    barbecueArea: false,
    liftingPlatform: false,
    childrenPlayground: false,
    crane: false,
    policeCallSystem: false,
    ramp: false,
    wheelchairAccessible: false,
    sauna: false,
    lakeAccess: false,
    suitableForSeniors: false,
    securityCamera: false,
    sportsFacilities: false,
    swimmingPool: false,
    closeToSubway: false,
    changingRooms: false,
    undergroundCarPark: false,
    umtsReception: false,
    washingDryingRoom: false,
    wineCellar: false,
    wellnessArea: false,
    jacuzzi: false,
    conservatory: false,
};

export const services = {
    servicedApartments: false,
    catering: false,
    shoppingService: false,
    cleaningService: false,
    guardsService: false,
};

export const certificates = {
    buildingPermit: false,
    deedOfOwnership: false,
    approvalDecision: false,
};

export const enum development {
    PARTIALLY_CLOSED = 'Partially closed',
    UNDEVELOPED = 'Undeveloped',
    FULLY_DEVELOPED = 'Fully developed',
}

export const enum noiseLevel {
    ABSOLUTE_SILENT_LOCATION = 'Absolute silent location',
    POSSIBLE_SILENCE_IMPAIRMENT = 'Possible silence impairment',
    AVERAGE_SILENCE_IMPAIRMENT = 'Average silence impairment',
    SMALL_SILENCE_IMPAIRMENT = 'Small silence impairment',
    PEACEFUL_LOCATION = 'Peaceful location',
}

export const enum conditionAssesment {
    VERY_GOOD = 'Very good',
    GOOD = 'Good',
    AVERAGE = 'Average',
    BAD = 'Bad',
}

export const enum situationAssesment {
    VERY_GOOD = 'Very good',
    GOOD = 'Good',
    AVERAGE = 'Average',
    BAD = 'Bad',
}

export const enum furnishing {
    BATHROOM = 'Bathroom',
    BUSINESS_FACILITY = 'Business facility',
    KITCHENETTE = 'Kitchenette',
    KITCHEN = 'Kitchen',
    FURNISHED = 'Furnished',
    UNFURNISHED = 'Unfurnished',
    OPTIONAL = 'Optional',
    PATIALLY_FURNISHED = 'Partially furnished',
}

export const enum formOfOwnership {
    SINGLE_OWNER = 'Single owner',
    CO_OWNERSHIP = 'Co-ownership',
    CO_OWNERSHIP_WITH_USAGE_RIGHTS = 'Co-ownership with usage rights',
    HOME_OWNERSHIP = 'Home ownership',
    HOME_OWNERSHIP_PLANNED = 'Home ownership planned',
    HOME_OWNERSHIP_IN_DEVELOPMENT = 'Ownership of home in development',
    BUILDING_LAW = 'Building law',
    BUILDING_NOT_ON_OWNERS_LOT = 'Building constructed on land not owned by the landowner', //! Found this to be accurate SUPERCERTIFICATE translation
}

export const enum houseCondition {
    OBJECT_FOR_DEMOLITION = 'Object for demolition',
    DILAPITATED_OBJECT = 'Dilapitated object',
    LOADBEARING_STRUCTURE = 'Load-bearing structure',
    FIRST_OCCUPANCY = 'First occupancy',
    MAINTAINED = 'Maintained',
    MODERNIZED = 'Modernized',
    BY_ARRANGEMENT = 'By arrangement',
    AS_GOOD_AS_NEW = 'As good as new',
    PROJECTED = 'Projected',
    NEED_RENOVATION = 'In need of renovation', //! As per word docs, there are two types that are translated exactly the same
    SHELL_CONSTRUCTION = 'Shell construction',
    PARTIALLY_RENOVATED = 'Partially renovated',
    PARTIALLY_REFURBISHED = 'Partially refurbished',
    FULLY_RENOVATED = 'Fully renovated',
    FULLY_REFURBISHED = 'Fully refurbished',
    BUILDING_NOT_ON_OWNERS_LOT = 'Building constructed on land not owned by the landowner', //! Found this to be accurate SUPERCERTIFICATE translation
}

export const enum status {
    OBJECT_FOR_DEMOLITION = 'Object for demolition',
    DILAPITATED_OBJECT = 'Dilapitated object',
    LOADBEARING_STRUCTURE = 'Load-bearing structure',
    FIRST_OCCUPANCY = 'First occupancy',
    MAINTAINED = 'Maintained',
    MODERNIZED = 'Modernized',
    BY_ARRANGEMENT = 'By arrangement',
    AS_GOOD_AS_NEW = 'As good as new',
    PROJECTED = 'Projected',
    NEED_RENOVATION = 'In need of renovation', //! As per word docs, there are two types that are translated exactly the same
    SHELL_CONSTRUCTION = 'Shell construction',
    PARTIALLY_RENOVATED = 'Partially renovated',
    PARTIALLY_REFURBISHED = 'Partially refurbished',
    FULLY_RENOVATED = 'Fully renovated',
    FULLY_REFURBISHED = 'Fully refurbished',
}

export const enum type {
    OLD_CONTRUCTION = 'Old contruction',
    NEW_CONSTRUCTION = 'New construction',
}

export const enum marketingType {
    PURCHASE = 'Purchase',
    RENTAL = 'Rental',
    LEASE = 'Lease',
}

export const enum energyClass {
    AAA = 'A++',
    AA = 'A+',
    A = 'A',
    B = 'B',
    C = 'C',
    D = 'D',
    E = 'E',
    F = 'F',
    G = 'G',
    H = 'H',
}

export const enum agreementType {
    VERBAL = 'Verbal',
    WRITTEN = 'Written',
    WRITTEN_BOTH_PARTIES = 'Written (both parties)',
}

export const enum mediationOrderType {
    EXCLUSIVE_RIGHT_TO_SELL = 'Exclusive right to sell',
    CO_EXCLUSIVE_RIGHT_TO_SELL = 'Co-exclusive right to sell',
    SIMPLE_MEDIATION = 'Simple mediation',
}

export const enum floor {
    FOURTH_UNDERGROUND_LEVEL = '4.KG',
    THIRD_UNDERGROUND_LEVEL = '3.KG',
    SECOND_UNDERGROUND_LEVEL = '2.KG',
    FIRST_UNDERGROUND_LEVEL = '1.KG',
    GROUND_FLOOR = 'EG',
    FIRST_FLOOR = '1.Etage',
    SECOND_FLOOR = '2.Etage',
    THIRD_FLOOR = '3.Etage',
    FOURTH_FLOOR = '4.Etage',
    FIFTH_FLOOR = '5.Etage',
    SIXTH_FLOOR = '6.Etage',
    SEVENTH_FLOOR = '7.Etage',
    EIGTH_FLOOR = '8.Etage',
    NINETH_FLOOR = '9.Etage',
    TENTH_FLOOR = '10.Etage',
    ELEVENTH_FLOOR = '11.Etage',
    TWELFTH_FLOOR = '12.Etage',
    THIRTEENTH_FLOOR = '13.Etage',
    FOURTEENTH_FLOOR = '14.Etage',
    FIFTEENTH_FLOOR = '15.Etage',
    SIXTEENTH_FLOOR = '16.Etage',
    SEVENTEENTH_FLOOR = '17.Etage',
    SEVENTEEN_TO_TWENTY = '17-20 Etage',
    TWENTY_ONE_TO_TWENTY_FIVE = '21-25 Etage',
    TWENTY_SIX_TO_THIRTY = '26-30 Etage',
    THIRTY_ONE_TO_THIRTY_FIVE = '26-35 Etage',
    THIRTY_SIX_TO_FOURTY = '36-40 Etage',
    OVER_FOURTY = '40+ Etage',
}

export const enum publishTheAddressTemplate {
    NOSHOW = "Don't show/export",
    PLZ_AND_ORT = 'PLZ & Ort',
    ZIP_PLACE_AREA = 'ZIP code, place & area',
    ZIP_CITY_STREET = 'ZIP code, city & street',
    ZIP_CITY_STREET_NUMBER_STAIRS_APARTMENT_NUMBER = 'ZIP code, city, street number, stairs, apartment number',
}

export const enum floorPlan {
    CENTRALLY_ACCESSIBLE = 'Centrally accessible',
    PARTLY_CENTRALLY_ACCESSIBLE = 'Partly centrally accessible',
    CIRCULAR_WALKABLE = 'Circular walkable',
    LOFT = 'Loft',
}

export const enum propertyType {
    OFFICE = 'BÜRO',
    RETAIL = 'EINZELHANDEL',
    COMMERCIAL_LEISURE_PROPERTY = 'GEWERBLICHES FREIZEIT-IMMOBILIE',
    HOSPITALITY = 'GASTFREUNDSCHAFT',
    PLOT = 'GRUNDSTÜCK',
    HOUSE = 'HAUS',
    INDUSTRY = 'Industry business',
    AGRICULTURE_AND_FORESTRY = 'LAND-UND FORSTWIRTSCHAFT',
    PARK = 'Park',
    OTHER = 'ANDERE',
    APARTMENT = 'WOHNUNG',
    ROOM = 'ZIMMER',
    APARTMENT_BUILDING = 'WOHNGEBÄUDE',
}

export enum Currency {
    EUR = 'EUR',
    USD = 'USD',
    CHF = 'CHF',
    HUF = 'HUF',
    PLN = 'PLN',
    RON = 'RON',
    AED = 'AED',
}

export const furnishingFields: FurnishingFieldsCollection[] = [
    {
        label: i18next.t('Bauweise / Konstrukcija'),
        attribute: 'constructionType',
        fields: [
            {
                attribute: 'prefabricatedPart',
                label: i18next.t('Prefabricated part'),
                value: false,
            },
            {
                attribute: 'wooden',
                label: i18next.t('Wooden'),
                value: false,
            },
            {
                attribute: 'solid',
                label: i18next.t('Solid'),
                value: false,
            },
            {
                attribute: 'reinforcedConcrete',
                label: i18next.t('Reinforced concrete'),
                value: false,
            },
            {
                attribute: 'brick',
                label: i18next.t('Brick'),
                value: false,
            },
        ],
    },
    {
        label: i18next.t('Boden / Pod'),
        attribute: 'floor',
        fields: [
            {
                attribute: 'floorBoards',
                label: i18next.t('Floorboards'),
                value: false,
            },
            {
                attribute: 'raisedFloor',
                label: i18next.t('Raised floor'),
                value: false,
            },
            {
                attribute: 'screed',
                label: i18next.t('Screed'),
                value: false,
            },
            {
                attribute: 'prefabricatedParquet',
                label: i18next.t('Prefabricated parquet'),
                value: false,
            },
            {
                attribute: 'tiles',
                label: i18next.t('Tiles'),
                value: false,
            },
            {
                attribute: 'granite',
                label: i18next.t('Granite'),
                value: false,
            },
            {
                attribute: 'plasticFloor',
                label: i18next.t('Plastic floor'),
                value: false,
            },
            {
                attribute: 'laminate',
                label: i18next.t('Laminate'),
                value: false,
            },
            {
                attribute: 'linoleum',
                label: i18next.t('Linoleum'),
                value: false,
            },
            {
                attribute: 'marble',
                label: i18next.t('Marble'),
                value: false,
            },
            {
                attribute: 'parquet',
                label: i18next.t('Parquet'),
                value: false,
            },
            {
                attribute: 'stoneFloor',
                label: i18next.t('Stone floor'),
                value: false,
            },
            {
                attribute: 'carpet',
                label: i18next.t('Carpet'),
                value: false,
            },
            {
                attribute: 'carpetTiles',
                label: i18next.t('Carpet tiles'),
                value: false,
            },
            {
                attribute: 'terracotta',
                label: i18next.t('Terracotta'),
                value: false,
            },
        ],
    },
    {
        label: i18next.t('Verwarming / Grejanje'),
        attribute: 'heatingType',
        fields: [
            {
                attribute: 'floorHeating',
                label: i18next.t('Floor Heating'),
                value: false,
            },
            {
                attribute: 'districtHeating',
                label: i18next.t('District Heating'),
                value: false,
            },
            {
                attribute: 'underfloorHeating',
                label: i18next.t('Underfloor Heating'),
                value: false,
            },
            {
                attribute: 'stove',
                label: i18next.t('Stove'),
                value: false,
            },
            {
                attribute: 'fireplace',
                label: i18next.t('Fireplace'),
                value: false,
            },
            {
                attribute: 'centralHeating',
                label: i18next.t('Central Heating'),
                value: false,
            },
        ],
    },
    {
        label: i18next.t('Heizungsart / Tip grejanja'),
        attribute: 'heating',
        fields: [
            {
                attribute: 'alternative',
                label: i18next.t('Alternative'),
                value: false,
            },
            {
                attribute: 'blockTypeThermalPowerStation',
                label: i18next.t('Block type thermal power station'),
                value: false,
            },
            {
                attribute: 'electric',
                label: i18next.t('Electric'),
                value: false,
            },
            {
                attribute: 'geothermalEnergy',
                label: i18next.t('Geothermal energy'),
                value: false,
            },
            {
                attribute: 'districtHeating',
                label: i18next.t('District heating'),
                value: false,
            },
            {
                attribute: 'liquifiedGas',
                label: i18next.t('Liquified gas'),
                value: false,
            },
            {
                attribute: 'gas',
                label: i18next.t('Gas'),
                value: false,
            },
            {
                attribute: 'wooden',
                label: i18next.t('Wooden'),
                value: false,
            },
            {
                attribute: 'coal',
                label: i18next.t('Coal'),
                value: false,
            },
            {
                attribute: 'airHeatPump',
                label: i18next.t('Air heat pump'),
                value: false,
            },
            {
                attribute: 'oil',
                label: i18next.t('Oil'),
                value: false,
            },
            {
                attribute: 'pellets',
                label: i18next.t('Pellets'),
                value: false,
            },
            {
                attribute: 'solarEnergy',
                label: i18next.t('Solar energy'),
                value: false,
            },
            {
                attribute: 'waterElectric',
                label: i18next.t('Water electric'),
                value: false,
            },
        ],
    },
    {
        label: i18next.t('Ausblick / Pogled'),
        attribute: 'view',
        fields: [
            {
                attribute: 'mountainView',
                label: i18next.t('Mountain view'),
                value: false,
            },
            {
                attribute: 'distantView',
                label: i18next.t('Distant view'),
                value: false,
            },
            {
                attribute: 'greenView',
                label: i18next.t('Green view'),
                value: false,
            },
            {
                attribute: 'seaView',
                label: i18next.t('Sea view'),
                value: false,
            },
            {
                attribute: 'lakeView',
                label: i18next.t('Lake view'),
                value: false,
            },
            {
                attribute: 'cityView',
                label: i18next.t('City view'),
                value: false,
            },
        ],
    },
    {
        label: i18next.t('Fenster / Prozor'),
        attribute: 'window',
        fields: [
            {
                attribute: 'externalSunProtection',
                label: i18next.t('External sun protection'),
                value: false,
            },
            {
                attribute: 'antiGlareProtection',
                label: i18next.t('Anti-glare protection'),
                value: false,
            },
            {
                attribute: 'doubleOrMultipleGlazing',
                label: i18next.t('Double or multiple glazing'),
                value: false,
            },
            {
                attribute: 'internalSunProtection',
                label: i18next.t('Internal sun protection'),
                value: false,
            },
            {
                attribute: 'plasticWindows',
                label: i18next.t('Plastic windows'),
                value: false,
            },
            {
                attribute: 'openableWindows',
                label: i18next.t('Openable windows'),
                value: false,
            },
            {
                attribute: 'shutters',
                label: i18next.t('Shutters'),
                value: false,
            },
            {
                attribute: 'soundproofWindows',
                label: i18next.t('Soundproof windows'),
                value: false,
            },
            {
                attribute: 'soundproofWindows',
                label: i18next.t('Soundproof windows'),
                value: false,
            },
        ],
    },
    {
        label: i18next.t('Fernsehen / Televizija'),
        attribute: 'tv',
        fields: [
            {
                attribute: 'dvbt',
                label: i18next.t('DVBT'),
                value: false,
            },
            {
                attribute: 'cableOrSatelliteTv',
                label: i18next.t('Cable or satellite TV'),
                value: false,
            },
        ],
    },
    {
        label: i18next.t('Küche / Kuhinja'),
        attribute: 'kitchen',
        fields: [
            {
                attribute: 'equippedKitchen',
                label: i18next.t('Equipped kitchen'),
                value: false,
            },
            {
                attribute: 'kitchenette',
                label: i18next.t('Kitchenette'),
                value: false,
            },
            {
                attribute: 'teaKitchen',
                label: i18next.t('Tea kitchen'),
                value: false,
            },
            {
                attribute: 'openKitchen',
                label: i18next.t('Open kitchen'),
                value: false,
            },
        ],
    },
    {
        label: i18next.t('Extras / Dodaci'),
        attribute: 'extras',
        fields: [
            {
                attribute: 'storageRoom',
                label: i18next.t('Storage room'),
                value: false,
            },
            {
                attribute: 'alarmSystem',
                label: i18next.t('Alarm system'),
                value: false,
            },
            {
                attribute: 'outdoorShower',
                label: i18next.t('Outdoor shower'),
                value: false,
            },
            {
                attribute: 'library',
                label: i18next.t('Library'),
                value: false,
            },
            {
                attribute: 'steamBath',
                label: i18next.t('Steam bath'),
                value: false,
            },
            {
                attribute: 'bikeRoom',
                label: i18next.t('Bike room'),
                value: false,
            },
            {
                attribute: 'gym',
                label: i18next.t('Gym'),
                value: false,
            },
            {
                attribute: 'useOfGarden',
                label: i18next.t('Use of garden'),
                value: false,
            },
            {
                attribute: 'barbecueArea',
                label: i18next.t('Barbecue area'),
                value: false,
            },
            {
                attribute: 'liftingPlatform',
                label: i18next.t('Lifting platform'),
                value: false,
            },
            {
                attribute: 'childrenPlayground',
                label: i18next.t('Children playground'),
                value: false,
            },
            {
                attribute: 'crane',
                label: i18next.t('Crane'),
                value: false,
            },
            {
                attribute: 'ramp',
                label: i18next.t('Ramp'),
                value: false,
            },
            {
                attribute: 'wheelchairAccessible',
                label: i18next.t('Wheelchair accessible'),
                value: false,
            },
            {
                attribute: 'sauna',
                label: i18next.t('Sauna'),
                value: false,
            },
            {
                attribute: 'lakeAccess',
                label: i18next.t('Lake access'),
                value: false,
            },
            {
                attribute: 'suitableForSeniors',
                label: i18next.t('Suitable for seniors'),
                value: false,
            },
            {
                attribute: 'securityCamera',
                label: i18next.t('Security camera'),
                value: false,
            },
            {
                attribute: 'sportsFacilities',
                label: i18next.t('Sports facilities'),
                value: false,
            },
            {
                attribute: 'swimmingPool',
                label: i18next.t('Swimming pool'),
                value: false,
            },
            {
                attribute: 'closeToSubway',
                label: i18next.t('Close to subway'),
                value: false,
            },
            {
                attribute: 'changingRooms',
                label: i18next.t('changingRooms'),
                value: false,
            },
            {
                attribute: 'undergroundCarPark',
                label: i18next.t('Underground car park'),
                value: false,
            },
            {
                attribute: 'unitsReception',
                label: i18next.t('Units reception'),
                value: false,
            },
            {
                attribute: 'washingDryingRoom',
                label: i18next.t('Washing drying room'),
                value: false,
            },
            {
                attribute: 'wineCellar',
                label: i18next.t('Wine cellar'),
                value: false,
            },
            {
                attribute: 'wellnessArea',
                label: i18next.t('Wellness Area'),
                value: false,
            },
            {
                attribute: 'jacuzzi',
                label: i18next.t('Jacuzzi'),
                value: false,
            },
            {
                attribute: 'conservatory',
                label: i18next.t('Conservatory'),
                value: false,
            },
        ],
    },
    {
        label: i18next.t('Dachform / Oblik krova'),
        attribute: 'roofShape',
        fields: [
            {
                attribute: 'flatRoof',
                label: i18next.t('Flat roof'),
                value: false,
            },
            {
                attribute: 'halfHipRoof',
                label: i18next.t('Half hip roof'),
                value: false,
            },
            {
                attribute: 'mansardRoof',
                label: i18next.t('Mansard roof'),
                value: false,
            },
            {
                attribute: 'monopitchRoof',
                label: i18next.t('Monopitch roof'),
                value: false,
            },
            {
                attribute: 'pyramidRoof',
                label: i18next.t('Pyramid roof'),
                value: false,
            },
            {
                attribute: 'gableRoof',
                label: i18next.t('Gable roof'),
                value: false,
            },
        ],
    },
    {
        label: i18next.t('Fahrstuhl / Lift'),
        attribute: 'elevator',
        fields: [
            {
                attribute: 'freightElevator',
                label: i18next.t('Freight elevator'),
                value: false,
            },
            {
                attribute: 'elevator',
                label: i18next.t('Elevator'),
                value: false,
            },
        ],
    },
    {
        label: i18next.t('Belüftung / Ventilacija'),
        attribute: 'ventilation',
        fields: [
            {
                attribute: 'fanCoilUnit',
                label: i18next.t('Fan coil unit'),
                value: false,
            },
            {
                attribute: 'airCondtitioner',
                label: i18next.t('Air conditioner'),
                value: false,
            },
            {
                attribute: 'controlledVentilation',
                label: i18next.t('Controlled ventilation'),
                value: false,
            },
            {
                attribute: 'cooling',
                label: i18next.t('Cooling'),
                value: false,
            },
            {
                attribute: 'mechanicalVentialtion',
                label: i18next.t('Mechanical ventialtion'),
                value: false,
            },
        ],
    },
    {
        label: i18next.t('Balkon / Balkon'),
        attribute: 'balcony',
        fields: [
            {
                attribute: 'northBalcony',
                label: i18next.t('North balcony'),
                value: false,
            },
            {
                attribute: 'northEastBalcony',
                label: i18next.t('North east balcony'),
                value: false,
            },
            {
                attribute: 'northWestBalcony',
                label: i18next.t('North west balcony'),
                value: false,
            },
            {
                attribute: 'eastBalcony',
                label: i18next.t('East balcony'),
                value: false,
            },
            {
                attribute: 'southEastBalcony',
                label: i18next.t('South east balcony'),
                value: false,
            },
            {
                attribute: 'southWestBalcony',
                label: i18next.t('South west balcony'),
                value: false,
            },
            {
                attribute: 'westBalcony',
                label: i18next.t('West balcony'),
                value: false,
            },
        ],
    },
    {
        label: i18next.t('Beleuchtung / Osvetljenje'),
        attribute: 'lighting',
        fields: [
            {
                attribute: 'suspendedMirrorLouvreLights',
                label: i18next.t('Suspended mirror louvre lights'),
                value: false,
            },
            {
                attribute: 'lightingSuitableForWorkplace',
                label: i18next.t('Lighting suitable for workplace'),
                value: false,
            },
            {
                attribute: 'ceilingLights',
                label: i18next.t('Ceiling lights'),
                value: false,
            },
            {
                attribute: 'floorLamps',
                label: i18next.t('Floor lamps'),
                value: false,
            },
        ],
    },
    {
        label: i18next.t('WCs / Toaleti'),
        attribute: 'toilet',
        fields: [
            {
                attribute: 'guestToilet',
                label: i18next.t('Guest toilet'),
                value: false,
            },
            {
                attribute: 'separateToilets',
                label: i18next.t('Separate toilets'),
                value: false,
            },
            {
                attribute: 'urinal',
                label: i18next.t('Urinal'),
                value: false,
            },
            {
                attribute: 'toilet',
                label: i18next.t('Toilet'),
                value: false,
            },
        ],
    },
    {
        label: i18next.t('Gastronomie / Gastronomija'),
        attribute: 'gastronomy',
        fields: [
            {
                attribute: 'onSiteBar',
                label: i18next.t('On site bar'),
                value: false,
            },
            {
                attribute: 'onSiteHotelRestaurant',
                label: i18next.t('On site hotel restaurant'),
                value: false,
            },
            {
                attribute: 'breweryConnection',
                label: i18next.t('Brewery connection'),
                value: false,
            },
            {
                attribute: 'guestTerrace',
                label: i18next.t('Guest terrace'),
                value: false,
            },
            {
                attribute: 'canteenOrCafeteria',
                label: i18next.t('Canteen or cafeteria'),
                value: false,
            },
            {
                attribute: 'openKitchen',
                label: i18next.t('Open kitchen'),
                value: false,
            },
        ],
    },
    {
        label: i18next.t('Services / Usluge'),
        attribute: 'services',
        fields: [
            {
                attribute: 'servicedApartments',
                label: i18next.t('Serviced apartments'),
                value: false,
            },
            {
                attribute: 'catering',
                label: i18next.t('Catering'),
                value: false,
            },
            {
                attribute: 'shoppingService',
                label: i18next.t('Shopping service'),
                value: false,
            },
            {
                attribute: 'cleaningService',
                label: i18next.t('Cleaning service'),
                value: false,
            },
            {
                attribute: 'guardsService',
                label: i18next.t('Guards service'),
                value: false,
            },
        ],
    },
    {
        label: i18next.t('Expansion stage / *'),
        attribute: 'expansionStage',
        fields: [
            {
                attribute: 'bareBoneHouse',
                label: i18next.t('Bare bone house'),
                value: false,
            },
            {
                attribute: 'kitHouse',
                label: i18next.t('Kit house'),
                value: false,
            },
            {
                attribute: 'keyInHandWithBaseplate',
                label: i18next.t('Key in hand with baseplate'),
                value: false,
            },
            {
                attribute: 'keyInHandWithCellar',
                label: i18next.t('Key in hand with cellar'),
                value: false,
            },
            {
                attribute: 'keyInHandWithoutBaseplate',
                label: i18next.t('Key in hand without baseplate'),
                value: false,
            },
        ],
    },
    {
        label: i18next.t('Energietyp / Tip energije'),
        attribute: 'energyType',
        fields: [
            {
                attribute: 'houseEfficiency40',
                label: i18next.t('House efficiency 40'),
                value: false,
            },
            {
                attribute: 'houseEfficiency55',
                label: i18next.t('House efficiency 55'),
                value: false,
            },
            {
                attribute: 'houseEfficiency60',
                label: i18next.t('House efficiency 60'),
                value: false,
            },
            {
                attribute: 'houseEfficiency70',
                label: i18next.t('House efficiency 70'),
                value: false,
            },
            {
                attribute: 'minergieConstruction',
                label: i18next.t('Minergie construction'),
                value: false,
            },
            {
                attribute: 'minergieCertified',
                label: i18next.t('Minergie certified'),
                value: false,
            },
            {
                attribute: 'newBuildingStandard',
                label: i18next.t('New building standard'),
                value: false,
            },
            {
                attribute: 'lowEnergyHouse',
                label: i18next.t('Low energy house'),
                value: false,
            },
            {
                attribute: 'passiveHouse',
                label: i18next.t('Passive house'),
                value: false,
            },
        ],
    },
    {
        label: i18next.t('Räume / Spaces'),
        attribute: 'space',
        fields: [
            {
                attribute: 'doubleDoors',
                label: i18next.t('Double doors'),
                value: false,
            },
            {
                attribute: 'roomsCanBeChanged',
                label: i18next.t('Rooms can be changed'),
                value: false,
            },
            {
                attribute: 'soundproofPanels',
                label: i18next.t('Soundproof panels'),
                value: false,
            },
            {
                attribute: 'plaster',
                label: i18next.t('Plaster'),
                value: false,
            },
            {
                attribute: 'plasterBoardPartitions',
                label: i18next.t('Plaster board partitions'),
                value: false,
            },
            {
                attribute: 'glassPartitions',
                label: i18next.t('Glass partitions'),
                value: false,
            },
            {
                attribute: 'studentFriendlyApartment',
                label: i18next.t('Student friendly apartment'),
                value: false,
            },
        ],
    },
    {
        label: i18next.t('Elektrik / Električna energija'),
        attribute: 'electricEnergy',
        fields: [
            {
                attribute: 'floorBoxes',
                label: i18next.t('Floor boxes'),
                value: false,
            },
            {
                attribute: 'DvEdpCabling',
                label: i18next.t('DVEDP cabling'),
                value: false,
            },
            {
                attribute: 'fiberOpticConnection',
                label: i18next.t('Fiber optic connection'),
                value: false,
            },
            {
                attribute: 'ItCablingCat5',
                label: i18next.t('IT cabling Cat 5'),
                value: false,
            },
            {
                attribute: 'ItCablingCat6',
                label: i18next.t('IT cabling Cat 6'),
                value: false,
            },
            {
                attribute: 'ItCablingCat7',
                label: i18next.t('IT cabling Cat 7'),
                value: false,
            },
            {
                attribute: 'cableDuctsAlongParapet',
                label: i18next.t('Cable ducts along parapet'),
                value: false,
            },
            {
                attribute: 'cableDuctsAlongPlaster',
                label: i18next.t('Cable ducts along plaster'),
                value: false,
            },
            {
                attribute: 'flushMountedCableDucts',
                label: i18next.t('Flush mounted cable ducts'),
                value: false,
            },
        ],
    },
    {
        label: i18next.t('Bad / Kupatilo'),
        attribute: 'bathroom',
        fields: [
            {
                attribute: 'bathroomWithWindow',
                label: i18next.t('Bathroom with window'),
                value: false,
            },
            {
                attribute: 'bathroomWithToilet',
                label: i18next.t('Bathroom with toilet'),
                value: false,
            },
            {
                attribute: 'bathtub',
                label: i18next.t('Bathtub'),
                value: false,
            },
            {
                attribute: 'bidet',
                label: i18next.t('Bidet'),
                value: false,
            },
            {
                attribute: 'shower',
                label: i18next.t('Shower'),
                value: false,
            },
        ],
    },
    {
        label: i18next.t('Stellplatzart / Vrsta parking mesta'),
        attribute: 'parkingSpaceType',
        fields: [
            {
                attribute: 'carport',
                label: i18next.t('Carport'),
                value: false,
            },
            {
                attribute: 'privateGarage',
                label: i18next.t('Private garage'),
                value: false,
            },
            {
                attribute: 'sharedGarage',
                label: i18next.t('Shared garage'),
                value: false,
            },
            {
                attribute: 'parkingSpot',
                label: i18next.t('Parking spot'),
                value: false,
            },
            {
                attribute: 'duplexGarage',
                label: i18next.t('Duplex garage'),
                value: false,
            },
            {
                attribute: 'undergroundCarPark',
                label: i18next.t('Underground car park'),
                value: false,
            },
        ],
    },
    {
        label: i18next.t('Urkunden / Sertifikati'),
        attribute: 'certificates',
        fields: [
            {
                attribute: 'buildingPermit',
                label: i18next.t('Building permit'),
                value: false,
            },
            {
                attribute: 'deedOfOwnership',
                label: i18next.t('Deed of ownership'),
                value: false,
            },
            {
                attribute: 'approvalDecision',
                label: i18next.t('Approval decision'),
                value: false,
            },
        ],
    },
];

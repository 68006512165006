import { CrudService } from './CrudService';
import { AxiosResponse } from 'axios';
import { axiosDefaults } from './AxiosDefaults';
import { ApplicationMetadata } from '../models/Metadata';

export class MetaService extends CrudService<ApplicationMetadata, 'Metadata'> {
    protected getUrlPath(): string {
        return '/metadata';
    }

    public async getApplicationMetadata(): Promise<AxiosResponse<ApplicationMetadata>> {
        return this.axiosInstance.get(
            axiosDefaults.baseURL + this.getUrlPath(),
            {
                withCredentials: false,
                headers: { 'Content-Type': 'application/json' },
            },
        );
    }
}

export const metaService = new MetaService();

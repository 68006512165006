import * as React from 'react';
import { getApplicationState } from '../application/ApplicationContext';

export default function Loader() {
    const applicationState = getApplicationState();

    return applicationState.loading ? (
        <div className="loader-overlay">
            <div className="spinner">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
        </div>
    ) : (
        <></>
    );
}

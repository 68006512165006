import { Dropdown } from 'primereact/dropdown';
import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import './ContactsPage.scss';
import { useTranslation } from 'react-i18next';
import { DataTable } from 'primereact/datatable';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { Card } from 'primereact/card';
import { contactsService } from '../../services/ContactsService';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Toast } from 'primereact/toast';
import { useLocation } from 'react-router-dom';
import { useApplicationContext } from '../../application/ApplicationContext';
import PermissionContainer from '../../core/auth/PermissionContainer';
import { Permissions } from '../../core/auth/Permissions';
import { userService } from '../../services/UsersService';
import { ContactRole, ContactType } from '../../constants/ContactConsts';

const ContactsPage = () => {
    const [loading, setLoading] = useState(false);
    const location = useLocation();
    const statusSingleView: any = location.state;
    const [contacts, setContacts] = useState([]);
    const [status, setStatus] = useState(statusSingleView);
    const { t } = useTranslation();
    const toast = React.useRef(null);
    const history = useHistory();
    const { appState, setNewInquiry } = useApplicationContext();

    const paginatorLeft = (
        <Button type="button" icon="pi pi-refresh" className="p-button-text" />
    );
    const paginatorRight = (
        <Button type="button" icon="pi pi-cloud" className="p-button-text" />
    );

    useEffect(() => {
        setLoading(true);
    }, []);

    //TEAM VIEW
    useEffect(() => {
        fetchContact(appState.teamView)
            .then((result) => {
                setContacts(
                    Array.isArray(result)
                        ? result.filter((x) => x.role === ContactRole.SELLER)
                        : [],
                );
                setLoading(false);
            })
            .catch((error) => {
                console.log(error);
            });
        setNewInquiry(false);
    }, [status, appState.showNewData, appState.teamView]);

    //TEAM VIEW
    const fetchContact = async (show) => {
        const response = await contactsService.getMany(show);
        return response.data;
    };

    const viewAction = (rowData) => {
        history.push({
            pathname: `/contact/${rowData.id}`,
            state: {
                data: contacts.filter((el) =>
                    el.id === rowData.id ? el : null,
                ),
            },
        });
    };

    const deleteActionBodyTemplate = (rowData) => {
        return (
            <Button
                type="button"
                icon="pi pi-trash"
                className="delete-action-button"
                onClick={() => {
                    contactsService
                        .deleteOne(rowData.id)
                        .then((response) => setStatus(response.status))
                        .catch((err) => setStatus(err.response.status));
                }}
            />
        );
    };

    useEffect(() => {
        if (status === 'updated') {
            toast.current.show({
                severity: 'success',
                detail: t(`Contact has been successfully updated`),
                life: 3000,
            });
            setStatus(0);
        }
        if (status === 201) {
            toast.current.show({
                severity: 'success',
                detail: t(`Contact has been successfully added`),
                life: 3000,
            });
            setStatus(0);
        }
        if (status === 200) {
            toast.current.show({
                severity: 'success',
                detail: t(`Contact has been deleted successfully`),
                life: 3000,
            });
            setStatus(0);
        }
        if (status === 500) {
            toast.current.show({
                severity: 'error',
                detail: t(`Internal server error`),
                life: 3000,
            });
            setStatus(0);
        }
    }, [status, statusSingleView]);

    const contactEmailBodyTemplate = (rowData) => {
        return <p>{rowData.emails[0]?.email}</p>;
    };

    const filterContactFunc = (contacts) => {
        let currentUser = appState.currentUser;
        let filter = contacts.filter(
            (contact) => contact?.assignee?.id == currentUser?.id,
        );
        return filter;
    };

    if (loading) {
        return (
            <div className="center-spinner">
                <ProgressSpinner strokeWidth="5" animationDuration="1.5s" />
            </div>
        );
    } else {
        return (
            <>
                {/*<Card style={{ marginBottom: '20px' }}>*/}
                {/*    <div className="container-contacts-empty">*/}
                {/*        <div className="dropdown-container">*/}
                {/*            <Dropdown*/}
                {/*                className="filter-item"*/}
                {/*                placeholder={t('Under development')}*/}
                {/*            />*/}
                {/*            <Dropdown*/}
                {/*                className="filter-item"*/}
                {/*                placeholder={t('Under development')}*/}
                {/*            />*/}
                {/*            <Dropdown*/}
                {/*                className="filter-item"*/}
                {/*                placeholder={t('Under development')}*/}
                {/*            />*/}
                {/*            <Dropdown*/}
                {/*                className="filter-item"*/}
                {/*                // placeholder={t('Filter')}*/}
                {/*                placeholder={t('Under development')}*/}
                {/*            />*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*</Card>*/}
                <Card className="no-padding">
                    <div className="container-contacts-empty">
                        {/*<PermissionContainer*/}
                        {/*    hasPermission={Permissions.CONTACT_CREATE}*/}
                        {/*    currentUser={appState.currentUser}*/}
                        {/*>*/}
                        {/*    <div className="button-container">*/}
                        {/*        <Link to="/contacts/new">*/}
                        {/*            <Button*/}
                        {/*                label="Add new contact"*/}
                        {/*                title={t('Add new contact')}*/}
                        {/*            />*/}
                        {/*        </Link>*/}
                        {/*    </div>*/}
                        {/*</PermissionContainer>*/}
                        <DataTable
                            value={contacts}
                            className="p-datatable-lg"
                            paginator={true}
                            paginatorPosition="bottom"
                            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                            currentPageReportTemplate={`{first} ${t(
                                'bis',
                            )} {last} ${t('of')} {totalRecords}`}
                            rows={50}
                            rowsPerPageOptions={[10, 20, 50, 100]}
                            sortField="id"
                            sortOrder={-1}
                            paginatorLeft={paginatorLeft}
                            paginatorRight={paginatorRight}
                            scrollHeight="700px"
                            selectionMode="single"
                            onSelectionChange={(e) => viewAction(e.value)}
                        >
                            <Column
                                sortable
                                className="column-container-second "
                                field="id"
                                header={t('ID')}
                            />
                            <Column
                                className="column-container"
                                field="firstName"
                                header={t('First name')}
                            />
                            <Column
                                className="column-container"
                                field="lastName"
                                header={t('Last name')}
                            />
                            <Column
                                className="column-container"
                                field="role"
                                header={t('Role')}
                            />
                            <Column
                                className="column-container"
                                field="privatePhone"
                                header={t('Phone')}
                            />
                            <Column
                                className="column-container-third"
                                body={contactEmailBodyTemplate}
                                header={t('Email')}
                            />
                            {/*<Column*/}
                            {/*    body={deleteActionBodyTemplate}*/}
                            {/*    className="datatable-button-column"*/}
                            {/*    hidden={*/}
                            {/*        !userService.hasPermission(*/}
                            {/*            appState.currentUser,*/}
                            {/*            Permissions.PROPERTY_DELETE,*/}
                            {/*        )*/}
                            {/*    }*/}
                            {/*/>*/}
                        </DataTable>
                    </div>
                </Card>
                <Toast ref={toast} position={'top-right'} />
            </>
        );
    }
};

export default ContactsPage;

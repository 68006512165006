import { ChartDataset, ChartOptions } from 'chart.js';
import { Card } from 'primereact/card';
import { Chart } from 'primereact/chart';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import React, { useState, useEffect } from 'react';
import CardHeader from '../../card/CardHeader';
import './ObjectSingleView.scss';
import { useTranslation } from 'react-i18next';
import { filesService } from '../../../services/FilesService';
import dateFormat from 'dateformat';
import noCoverPhoto from '../../../../public/assets/images/no-cover-photo.png';
import { Button } from 'primereact/button';
import { useHistory } from 'react-router-dom';
import { propertyNameMap } from '../../../services/PropertyName';
import { exposeService } from '../../../services/ExposeService';
import _ from 'lodash';
import DocumentCheckListModal from './DocumentChecklistModal/DocumentChecklistModal';
import { useStateCallback } from '../../../hooks/StateCallbackHook';
import { DomHandler } from 'primereact/utils';

const ObjectSingleView = ({ data, control, errors }) => {
    const { t } = useTranslation();
    // const [propertyActivities, setPropertyActivities] = useState([]);
    const [showDocumentChecklistModal, setShowDocumentChecklistModal] =
        useStateCallback(false);
    const newDate = new Date();
    const diff = Math.floor(
        (Date.parse(String(newDate)) - Date.parse(data.createdAt)) / 86400000,
    );

    const toggleDocumentChecklistModal = (): void => {
        setShowDocumentChecklistModal(!showDocumentChecklistModal, (state) => {
            if (!state) {
                DomHandler.removeClass(document.body, 'p-overflow-hidden');
            }
        });
    };

    const priceReductions = data.priceChanges.filter(
        (priceChange) => priceChange.type === 'decrease',
    );
    const decreaseValue =
        priceReductions[0]?.oldPrice -
        priceReductions[priceReductions.length - 1]?.newPrice;

    const price = (decreaseValue / priceReductions[0]?.oldPrice) * 100;

    const propertyNames = propertyNameMap(t);

    const [checkType, setCheckType] = useState('');
    useEffect(() => {
        if (data.propertyTypeId === 6) {
            setCheckType('Wohnflache|Grundstucksflach');
        } else {
            const combinationWonhnflache = [1, 2, 3, 4, 11, 12, 13];
            if (!combinationWonhnflache.includes(data.propertyTypeId)) {
                setCheckType('Grundstucksflach');
            } else {
                setCheckType('Wohnflache');
            }
        }
    }, [data.propertyTypeId]);

    useEffect(() => {
        // const propertyActivitiesMapped = data.propertyActivities.map(
        //     (activity) => {
        //         if (activity.comment) {
        //             activity.comment = activity.comment
        //                 .split('\n')
        //                 .map((line) => {
        //                     return line.replace(
        //                         /\%(.*?)\%/,
        //                         function (match, group, value) {
        //                             if (propertyNames[group]) {
        //                                 return propertyNames[group];
        //                             }
        //
        //                             return '[DELETE]';
        //                         },
        //                     );
        //                 })
        //                 .filter((comment) => !comment.includes('[DELETE]'))
        //                 .join('\n');
        //         }
        //
        //         return activity;
        //     },
        // );
        //
        // setPropertyActivities(propertyActivitiesMapped);

        const interessentChartClone = _.cloneDeep(interessentChart);
        interessentChartClone.datasets[0].data = [
            (data.inquiryCounts['Neu'] ?? 0) +
                (data.inquiryCounts['Neu Bold'] ?? 0),
            data.inquiryCounts['In Bearbeitung'] ?? 0,
            data.inquiryCounts['Besichtigung'] ?? 0,
            data.inquiryCounts['Interesse'] ?? 0,
            data.inquiryCounts['Kaufinteresse'] ?? 0,
            data.inquiryCounts['Kaufabsicht'] ?? 0,
            data.inquiryCounts['Verkauft GHome'] ?? 0,
            data.inquiryCounts['Abgeschlossen'] ?? 0,
        ];
        setInteressentChart(interessentChartClone);
    }, [data]);

    const createdAtDateFormat = (rowData) => {
        return dateFormat(rowData.createdAt, 'dd.mm.yyyy HH:MM:ss');
    };

    const [interessentChart, setInteressentChart] = useState({
        labels: [
            t('Neu'),
            t('In Bearbeitung'),
            t('Besichtigung'),
            t('Interesse'),
            t('Kaufinteresse'),
            t('Kaufabsicht'),
            t('Kauf bei GH'),
            t('Abgeschlossen'),
        ],
        datasets: [
            {
                data: [4, 7, 3, 1, 5, 6],
                backgroundColor: ['#bfa165', '#616161', '#828282', '#CCCCCC'],
            },
        ],
        options: {
            scales: {
                xAxes: [
                    {
                        gridLines: {
                            display: false,
                        },
                    },
                ],
                yAxes: [
                    {
                        gridLines: {
                            display: false,
                        },
                    },
                ],
            },
        },
    });

    const interessentChartOptions: ChartOptions | any = {
        type: 'bar',
        indexAxis: 'y',
        scales: {
            x: {
                grid: {
                    display: false,
                },
            },
            y: {
                grid: {
                    display: false,
                },
            },
        },
        plugins: {
            datalabels: {
                display: false,
            },
            labels: {
                display: false,
            },
            legend: {
                display: false,
            },
        },
    };

    const history = useHistory();
    const showFirstDate = (firstDate) => {
        history.push({
            pathname: `/first-dates/${firstDate.id}`,
            state: {
                data: firstDate,
            },
        });
    };

    const openInNewTab = (url) => {
        const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
        if (newWindow) newWindow.opener = null;
    };

    const handleDocumentChecklistModal = (
        propertyType: string,
        propertySubtype: string,
        checklist: any,
    ) => (
        <DocumentCheckListModal
            propertyType={propertyType}
            propertySubtype={propertySubtype}
            checklist={checklist}
            visible={toggleDocumentChecklistModal}
        />
    );

    return (
        <div className="grid-container">
            {showDocumentChecklistModal
                ? handleDocumentChecklistModal(
                      data.category?.name,
                      data.subCategory?.name,
                      data?.checklist,
                  )
                : null}
            <Card
                className="location-section titlebild"
                header={<CardHeader title={t('Titelbild')} />}
            >
                <div
                    className="img"
                    style={{
                        backgroundImage: `url('${
                            data.coverPhoto === null
                                ? noCoverPhoto
                                : filesService.getFilePath(data?.coverPhoto?.id)
                        }')`,
                    }}
                ></div>
            </Card>
            <Card
                className="location-section eckdaten-data"
                header={<CardHeader title={t('Eckdaten (osnovni podaci)')} />}
            >
                <div className="container">
                    <div className="box">
                        <p>{t('Vrsta upotrebe')}</p>
                        <p>{t('Tip nekretnine')}</p>
                        <p>{t('Tip marketinga')}</p>
                        <p>{t('Cena')}</p>
                        {checkType.includes('Wohnflache') ? (
                            <p>{t('Stambena površina')}</p>
                        ) : null}
                        {checkType.includes('Grundstucksflach') ? (
                            <p>{t('Grundstücksfläche')}</p>
                        ) : null}
                        <p>{t('Broj soba')}</p>
                        <p>{t('Sprat')}</p>
                        <p>{t('God. izgradnje')}</p>
                    </div>
                    <div className="data">
                        <p>
                            {data.typeOfUseBusiness
                                ? 'Geschäft'
                                : data.typeOfUseInvestment
                                ? 'Anlage'
                                : data.typeOfUseLiving
                                ? 'Wohnen'
                                : '/'}
                        </p>
                        <p>
                            {data?.category?.name?.slice(0, 18)}{' '}
                            {data?.category?.name?.toString()?.length > 18
                                ? '...'
                                : ''}
                        </p>
                        <p>
                            {data.price.priceType === null
                                ? 'Eigentum'
                                : t(`${data.price.priceType}`)}
                        </p>
                        <p>
                            {data.price?.netPurchasePrice
                                ? `${data.price?.netPurchasePrice.toLocaleString()} EUR` /*+ ' ' + data.price?.currency*/
                                : '/'}{' '}
                        </p>
                        {checkType.includes('Wohnflache') ? (
                            <p>
                                {data.surface?.livingArea
                                    ? `${data.surface?.livingArea} m²`
                                    : '/'}
                            </p>
                        ) : null}
                        {checkType.includes('Grundstucksflach') ? (
                            <p>
                                {data.surface?.plotArea
                                    ? `${data.surface?.plotArea} m²`
                                    : '/'}
                            </p>
                        ) : null}
                        <p>{data.surface?.numberOfRooms ?? '/'}</p>
                        <p>{data.floorNumber ?? '/'}</p>
                        <p>{data.other?.constructionYear ?? '/'}</p>
                    </div>
                </div>
            </Card>
            <Card
                className="location-section adress"
                header={
                    <CardHeader
                        title={t('Prodavac')}
                        titleRight={
                            data.firstDate ? (
                                <Button
                                    style={{ marginTop: '-4px' }}
                                    onClick={(e) =>
                                        showFirstDate(data.firstDate)
                                    }
                                    className="p-button-sm"
                                >
                                    Kommunikation
                                </Button>
                            ) : (
                                ''
                            )
                        }
                    />
                }
            >
                <div className="main-container">
                    <div className="container">
                        <div className="box">
                            <p>{t('Anrede')}</p>
                            <p>{t('Ime')}</p>
                            <p>{t('Prezime')}</p>
                            <p>{t('Br. Tel')}</p>
                            <p>{t('Email adresa')}</p>
                            <p>{t('Ulica')}</p>
                            <p>{t('Haus Nr.')}</p>
                            <p>{t('PTT broj')}</p>
                            <p>{t('Mesto')}</p>
                        </div>
                        <div className="data">
                            <p>
                                {data?.seller?.salutation
                                    ? t(data?.seller?.salutation)
                                    : '/'}
                            </p>
                            <p>
                                {data?.seller?.firstName
                                    ? data?.seller?.firstName
                                    : '/'}
                            </p>
                            <p>
                                {data?.seller?.lastName
                                    ? data?.seller?.lastName
                                    : '/'}
                            </p>
                            <p>
                                {data?.seller?.privatePhone
                                    ? data?.seller?.privatePhone
                                    : '/'}
                            </p>
                            <p>
                                {data?.seller?.emails[0]?.email
                                    ? data?.seller?.emails[0]?.email
                                    : '/'}
                            </p>
                            <p>
                                {data?.seller?.address
                                    ? data?.seller?.address
                                    : '/'}
                            </p>
                            <p>
                                {data?.seller?.houseNumber
                                    ? data?.seller?.houseNumber
                                    : '/'}
                            </p>
                            <p>
                                {data?.seller?.postalCode
                                    ? data?.seller?.postalCode
                                    : '/'}
                            </p>
                            <p>
                                {data?.seller?.city ? data?.seller?.city : '/'}
                            </p>
                        </div>
                    </div>
                </div>
            </Card>
            <Card
                className="location-section hard-facts"
                header={<CardHeader title={t('Hard Facts')} />}
            >
                <div className="container">
                    <div className="box">
                        <p>{t('Objekat kreiran')}</p>
                        <p>{t('Broj dana u prodaji')}</p>
                        <p>{t('Broj redukcija cene')}</p>
                        <p>{t('Ukupan iznos redukcija cene')}</p>
                        <p>{t('Poslednja promena u objektu')}</p>
                        <p>{t('Poslednja promena cene')}</p>
                        <p>{t('Poslednja promena oglasa')}</p>
                        <p>{t('Datum poslednjeg sastanka')}</p>
                    </div>
                    <div className="data">
                        <p>
                            {dateFormat(data.createdAt, 'dd-mm-yyyy HH:MM:ss')}
                        </p>
                        <p>{diff}</p>
                        <p>{priceReductions.length}</p>
                        <p>
                            {priceReductions.length !== 0
                                ? `${Math.abs(price).toFixed(
                                      2,
                                  )}% (${decreaseValue.toLocaleString()} EUR)`
                                : `0% (0 EUR)`}
                        </p>
                        <p>
                            {dateFormat(data.updatedAt, 'dd-mm-yyyy HH:MM:ss')}
                        </p>
                        <p>
                            {priceReductions[priceReductions.length - 1]
                                ?.timestamp
                                ? dateFormat(
                                      priceReductions[
                                          priceReductions.length - 1
                                      ]?.timestamp,
                                      'dd-mm-yyyy HH:MM:ss',
                                  )
                                : '/'}
                        </p>
                        <p>
                            {dateFormat(data.exportedAt, 'dd-mm-yyyy HH:MM:ss')}
                        </p>
                        <p>/</p>
                    </div>
                </div>
            </Card>
            <Card
                className="location-section eckdaten"
                header={<CardHeader title={t('Interessenten')} />}
            >
                <Chart
                    type="bar"
                    id="interessent-chart"
                    data={interessentChart}
                    options={interessentChartOptions}
                ></Chart>
            </Card>
            <Card
                className="location-section checkliste-information"
                header={
                    <CardHeader
                        title={t('Checkliste Information')}
                        titleRight={
                            <Button
                                style={{ marginTop: '-4px' }}
                                onClick={(e) =>
                                    setShowDocumentChecklistModal(true)
                                }
                                className="p-button-sm"
                            >
                                Dokumente
                            </Button>
                        }
                    />
                }
            >
                <div className="check-list-container">
                    <div className="data">
                        <p>
                            <i
                                className={`pi ${
                                    data.checklist?.title
                                        ? 'pi-check'
                                        : 'pi-times'
                                }`}
                            />
                        </p>
                        <p>
                            <i
                                className={`pi ${
                                    data.checklist?.coverPhoto
                                        ? 'pi-check'
                                        : 'pi-times'
                                }`}
                            />
                        </p>
                        <p>
                            <i
                                className={`pi ${
                                    data.checklist?.internetDescription
                                        ? 'pi-check'
                                        : 'pi-times'
                                }`}
                            />
                        </p>
                        <p>
                            <i
                                className={`pi ${
                                    data.checklist?.completeAddress
                                        ? 'pi-check'
                                        : 'pi-times'
                                }`}
                            />
                        </p>
                        <p>
                            <i
                                className={`pi ${
                                    data.checklist?.objectType
                                        ? 'pi-check'
                                        : 'pi-times'
                                }`}
                            />
                        </p>
                        <p>
                            <i
                                className={`pi ${
                                    data.checklist?.price
                                        ? 'pi-check'
                                        : 'pi-times'
                                }`}
                            />
                        </p>
                        <p>
                            <i
                                className={`pi ${
                                    data.checklist?.furnishing
                                        ? 'pi-check'
                                        : 'pi-times'
                                }`}
                            />
                        </p>
                        <p>
                            <i
                                className={`pi ${
                                    data.checklist?.area
                                        ? 'pi-check'
                                        : 'pi-times'
                                }`}
                            />
                        </p>
                        <p>
                            <i
                                className={`pi ${
                                    data.checklist?.energyCertificate
                                        ? 'pi-check'
                                        : 'pi-times'
                                }`}
                            />
                        </p>
                    </div>
                    <div className="box">
                        <p>{t('Naslov')}</p>
                        <p>{t('Naslovna Slika')}</p>
                        <p>{t('Opis Interneta')}</p>
                        <p>{t('Kompletna Adresa')}</p>
                        <p>{t('Tip Objekta')}</p>
                        <p>{t('Cena')}</p>
                        <p>{t('Opremljenost')}</p>
                        <p>{t('Povrsina')}</p>
                        <p>{t('Energy certificate')}</p>
                    </div>
                </div>
            </Card>
            <Card
                className="location-section internet-informacije"
                header={
                    <CardHeader title={t('Polje Za Interne Informacije')} />
                }
            >
                <div>
                    <div>
                        <h5>{data?.text?.title}</h5>
                    </div>
                </div>

                <div className="text">
                    <p style={{ fontSize: '12pt' }}>
                        {data.text?.descriptionForTheInternet?.slice(0, 600)}
                        {data.text?.descriptionForTheInternet?.toString()
                            ?.length > 600
                            ? '...'
                            : ''}
                    </p>
                </div>
            </Card>
            <Card
                className="location-section team"
                header={<CardHeader title={t('Makler')} />}
            >
                <div className="container-makler">
                    <div className="box">
                        <p>{t('Name')}</p>
                        <p>{t('Tel. Nr.')}</p>
                        <p>{t('Mail')}</p>
                    </div>
                    <div className="data">
                        <p>{data.employee?.name ?? '/'}</p>
                        <p>{data.employee?.phoneNumber ?? '/'}</p>
                        <p>{data.employee?.email ?? '/'}</p>
                    </div>

                    {/* <div className="main-container-right">
                        <div className="left-container">
                            <p>{t('Ime')}</p>
                            <p>{t('Broj Tel.')}</p>
                            <p>{t('Mail Adresa Agenta')}</p>
                        </div>
                        <div className="right-container">
                            <p>{data.employee?.supervisor?.name ?? '/'}</p>
                            <p>
                                {data.employee?.supervisor?.phoneNumber ?? '/'}
                            </p>
                            <p>{data.employee?.supervisor?.email ?? '/'}</p>
                        </div>
                    </div> */}
                </div>
            </Card>
            <Card
                className="location-section marketing"
                header={
                    <CardHeader
                        title={t('Marketing')}
                        titleRight={
                            data.sourceLink ? (
                                <Button
                                    style={{ marginTop: '-4px' }}
                                    onClick={(e) => {
                                        openInNewTab(data.sourceLink);
                                    }}
                                    label="Link Inserat Verkäufer"
                                    className="p-button-sm"
                                    icon="pi pi-external-link"
                                />
                            ) : null
                        }
                    />
                }
            >
                <div className="marketing-container">
                    <div className="box-title">
                        <p>{t('Expose')}</p>
                        <p>{t('Internet')}</p>
                    </div>
                    <div className="data">
                        <div
                            onClick={() =>
                                openInNewTab(
                                    exposeService.getExposePath(data.rootId),
                                )
                            }
                            className="link"
                        >
                            <p>
                                {data.text?.title?.slice(0, 50)}
                                {data.text?.title?.toString().length > 50
                                    ? '...'
                                    : ''}
                            </p>
                            <p>
                                {data.text?.descriptionForTheInternet?.slice(
                                    0,
                                    50,
                                )}
                                {data.text?.descriptionForTheInternet?.toString()
                                    .length > 50
                                    ? '...'
                                    : ''}
                            </p>
                        </div>
                    </div>
                </div>
            </Card>
            <Card
                className="location-section protokol no-padding"
                header={<CardHeader title={t('Aktivitaten')} />}
            >
                <div className="protocol-box">
                    <DataTable
                        columnResizeMode="fit"
                        sortField="createdAt"
                        sortOrder={-1}
                        // value={propertyActivities}
                        className="datatable"
                    >
                        <Column
                            sortable
                            className={'date-column'}
                            header={t('Date')}
                            body={createdAtDateFormat}
                            field="createdAt"
                        />
                        <Column
                            className={'date-column'}
                            header={t('Korisnik')}
                            field="user.name"
                        />
                        <Column
                            className={'date-column'}
                            header={t('Status')}
                            field="type"
                        />
                        <Column
                            className={'comment-column'}
                            header={t('Komentar')}
                            field="comment"
                            style={{ whiteSpace: 'pre-wrap' }}
                        />
                    </DataTable>
                </div>
            </Card>
        </div>
    );
};

export default ObjectSingleView;

import { Card } from 'primereact/card';
import React, { useState, useEffect } from 'react';
import CardHeader from '../../card/CardHeader';
import './ObjectBaseDataForm.scss';
import {
    agreementTypeOptions,
    conditionAssesmentOptions,
    developmentOptions,
    energyCertificateTypeOptions,
    energyCertificateYear,
    floorOptions,
    floorPlanOptions,
    formOfOwnershipOptions,
    furnishingOptions,
    houseConditionOptions,
    klasseOptions,
    mediationOrderTypeOptions,
    noiseLevelOptions,
    propertySubtypeOptions,
    propertyTypeOptions,
    publishAddressTemplateOptions,
    publishTheAddressTemplate,
    sideOfTheWorldOptions,
    situationAssesmentOptions,
    states,
    statusOptions,
    typeOptions,
} from '../../../constants/ObjectConsts';
import { Button } from 'primereact/button';
import {
    energySourcesOptions,
    HeatingType,
    heatingTypeOptions,
} from '../../../models/ObjectBaseDataFormModal';
import CustomObjectInput from '../customObjectInput/CustomObjectInput';
import { useTranslation } from 'react-i18next';
import { RadioButton } from 'primereact/radiobutton';
import { AcquisitionType } from '../../../models/RealEstateObject';
import NewContactModal from '../../../components/NewContactModal/NewContactModal';
import { Toast } from 'primereact/toast';
import moment from 'moment';
import { useApplicationContext } from '../../../application/ApplicationContext';
import { Permissions } from '../../../core/auth/Permissions';
import PermissionContainer from '../../../core/auth/PermissionContainer';

const ObjectBaseDataForm = ({
    realEstateObject,
    updateRealEstateObject,
    handleUpdateAcquisition,
    control,
    errors,
    employeeOptions,
    contactOptions,
    handleShowNewContactModal,
    showMewContactModal,
    trigger,
}) => {
    const { appState } = useApplicationContext();
    const [searchChar, setSearchContact] = useState(null);
    const { t } = useTranslation();
    const toasts = React.useRef(null);

    const yearOptions = Array.from({ length: 200 }).map((_, i) => ({
        label: new Date().getFullYear() - i,
        value: new Date().getFullYear() - i,
    }));

    const categories = [
        { name: AcquisitionType.ACQUISITION, key: '1' },
        { name: AcquisitionType.NON_ACQUISITION, key: '2' },
    ];

    const active = categories.filter(
        (el) => el.name === realEstateObject.acquisitionType,
    );

    const [selectedCategory, setSelectedCategory] = useState(
        active.length === 0 ? categories[1] : active[0],
    );

    const getSellerId = (id) => {
        updateRealEstateObject('sellerId', id);
        setSearchContact(3);
    };

    const [country, setCountry] = useState<any>([]);

    useEffect(() => {
        let copyCountry = [...states];
        let filterC = copyCountry?.find((country) => country.code === 'DE');
        setCountry(filterC?.items);
    }, []);

    const [publishAddressTemplateOption, setPublishAddressTemplateOption] =
        useState<any>([]);

    useEffect(() => {
        let copyData = [...publishAddressTemplateOptions];
        let filterC = copyData?.filter((file) => file.value == 'PLZ & Ort');
        setPublishAddressTemplateOption(filterC);
    }, []);

    const [showHint, setShowHint] = useState(false);

    useEffect(() => {
        if (
            realEstateObject?.propertyTypeId == null ||
            realEstateObject?.propertySubtypeId == null
        ) {
            setShowHint(true);
        } else {
            setShowHint(false);
        }
    }, [realEstateObject]);

    const [checkType, setCheckType] = useState('');

    useEffect(() => {
        if (realEstateObject.propertyTypeId === 6) {
            setCheckType('Wohnflache|Grundstucksflach');
        } else {
            let combinationWonhnflache = [1, 2, 3, 4, 11, 12, 13];
            if (
                !combinationWonhnflache.includes(
                    realEstateObject.propertyTypeId,
                )
            ) {
                setCheckType('Grundstucksflach');
            } else {
                setCheckType('Wohnflache');
            }
        }
    }, [realEstateObject.propertyTypeId]);

    return (
        <>
            <Toast ref={toasts} position={'top-right'} />
            <div className="object-form-wrapper">
                <div className="object-form-column">
                    <Card
                        className="location-section"
                        header={<CardHeader title={t('Address / location')} />}
                    >
                        <div className="section-row">
                            {/* <div className="country-input-container column-span-2">
                                <CustomObjectInput
                                    type="dropdown"
                                    classes={['country-input']}
                                    id="country-input"
                                    label={t('Zemlja') + ' *'}
                                    name="countryId"
                                    stateField={realEstateObject.countryId}
                                    setStateField={updateRealEstateObject}
                                    errors={errors}
                                    control={control}
                                    rules={{ required: t('This is required') }}
                                    dropdownOptions={countries}
                                />
                            </div> */}
                            <div className="state-input-container column-span-4">
                                <CustomObjectInput
                                    type="dropdown"
                                    classes={['state-input']}
                                    id="state-input"
                                    label={t('Savezna država') + ' *'}
                                    name="stateId"
                                    stateField={realEstateObject.stateId}
                                    setStateField={updateRealEstateObject}
                                    errors={errors}
                                    control={control}
                                    rules={{ required: t('This is required') }}
                                    dropdownOptions={country}
                                    // dropdownOptionsGroupLabel="label"
                                    // dropdownOptionsGroupChildren="items"
                                    // disabled={
                                    //     realEstateObject.countryId === null
                                    // }
                                />
                            </div>
                        </div>
                        <div className="section-row">
                            <CustomObjectInput
                                type="text"
                                classes={['street-input', 'generic-input']}
                                id="street-input"
                                label={t('Ulica')}
                                name="street"
                                stateField={realEstateObject.street}
                                setStateField={updateRealEstateObject}
                                errors={errors}
                                control={control}
                                // rules={{ required: t('This is required') }}
                            />
                            <CustomObjectInput
                                type="text"
                                classes={[
                                    'house-number-input',
                                    'generic-input',
                                ]}
                                id="house-number-input"
                                label={t('Kućni broj')}
                                name="houseNumber"
                                stateField={realEstateObject.houseNumber}
                                setStateField={updateRealEstateObject}
                                errors={errors}
                                control={control}
                                // rules={{ required: t('This is required') }}
                            />
                            <CustomObjectInput
                                type="text"
                                classes={['entrance-input', 'generic-input']}
                                id="entrance-input"
                                label={t('Ulaz')}
                                name="entrance"
                                stateField={realEstateObject.entrance}
                                setStateField={updateRealEstateObject}
                                errors={errors}
                                control={control}
                                // rules={{ required: t('This is required') }}
                            />
                            <CustomObjectInput
                                type="text"
                                classes={['door-input', 'generic-input']}
                                id="door-input"
                                label={t('Vrata')}
                                name="door"
                                stateField={realEstateObject.door}
                                setStateField={updateRealEstateObject}
                                errors={errors}
                                control={control}
                                // rules={{ required: t('This is required') }}
                            />
                        </div>
                        <div className="section-row">
                            <CustomObjectInput
                                type="text"
                                classes={['plz-input', 'generic-input']}
                                id="plz-input"
                                label={t('Poštanski broj') + ' *'}
                                name="plz"
                                stateField={realEstateObject.plz}
                                setStateField={updateRealEstateObject}
                                errors={errors}
                                control={control}
                                rules={{ required: t('This is required') }}
                            />
                            <div className="column-span-2">
                                <CustomObjectInput
                                    type="text"
                                    classes={['place-input', 'generic-input']}
                                    id="place-input"
                                    label={t('Mesto') + ' *'}
                                    name="place"
                                    stateField={realEstateObject.place}
                                    setStateField={updateRealEstateObject}
                                    errors={errors}
                                    control={control}
                                    rules={{ required: t('This is required') }}
                                />
                            </div>
                            <CustomObjectInput
                                type="dropdown"
                                classes={['world-side-input', 'generic-input']}
                                id="world-side-input"
                                label={t('Strana sveta')}
                                name="sideOfTheWorld"
                                stateField={realEstateObject.sideOfTheWorld}
                                setStateField={updateRealEstateObject}
                                errors={errors}
                                control={control}
                                // rules={{ required: t('This is required') }}
                                dropdownOptions={sideOfTheWorldOptions}
                            />
                        </div>
                        <div className="section-row">
                            <div className="column-span-2">
                                <CustomObjectInput
                                    type="text"
                                    classes={[
                                        'proximity-input',
                                        'generic-input',
                                    ]}
                                    id="proximity-input"
                                    label={t('Blizina')}
                                    name="proximity"
                                    stateField={realEstateObject.proximity}
                                    setStateField={updateRealEstateObject}
                                    errors={errors}
                                    control={control}
                                    // rules={{ required: t('This is required') }}
                                />
                            </div>
                            <div className="column-span-2">
                                <CustomObjectInput
                                    type="text"
                                    classes={[
                                        'key-info-input',
                                        'generic-input',
                                    ]}
                                    id="key-info-input"
                                    label={t('Ključne informacije')}
                                    name="keyInfo"
                                    stateField={realEstateObject.keyInfo}
                                    setStateField={updateRealEstateObject}
                                    errors={errors}
                                    control={control}
                                    // rules={{ required: t('This is required') }}
                                />
                            </div>
                        </div>
                        <div className="section-row">
                            <div className="column-span-4">
                                <CustomObjectInput
                                    type="dropdown"
                                    classes={[
                                        'publish-address-input',
                                        'generic-input',
                                    ]}
                                    id="publish-address-input"
                                    label={
                                        t(
                                            'Objavite adresu sa sledećom tačnošću',
                                        ) + ' *'
                                    }
                                    name="publishTheAddressTemplate"
                                    stateField={
                                        realEstateObject.publishTheAddressTemplate ==
                                        null
                                            ? publishTheAddressTemplate.PLZ_AND_ORT
                                            : realEstateObject.publishTheAddressTemplate
                                    }
                                    setStateField={updateRealEstateObject}
                                    errors={errors}
                                    control={control}
                                    rules={{ required: t('This is required') }}
                                    dropdownOptions={
                                        publishAddressTemplateOption
                                    }
                                />
                            </div>
                        </div>
                    </Card>
                    <Card
                        className="surfaces-section"
                        header={<CardHeader title={t('Površine / Flächen')} />}
                    >
                        <div className="section-row">
                            <CustomObjectInput
                                type="number-with-addon"
                                addonLabel="m²"
                                classes={[
                                    'living-area-input',
                                    'generic-input',
                                    'no-border-radius',
                                ]}
                                id="living-area-input"
                                label={
                                    t('Stambena površina') +
                                    `${
                                        checkType.includes('Wohnflache')
                                            ? '*'
                                            : ''
                                    }`
                                }
                                name="surface_livingArea"
                                stateField={realEstateObject.surface.livingArea}
                                setStateField={updateRealEstateObject}
                                errors={errors}
                                control={control}
                                rules={
                                    checkType.includes('Wohnflache')
                                        ? { required: t('This is required') }
                                        : { required: false }
                                }
                                decimal={true}
                            />

                            <CustomObjectInput
                                type="number-with-addon"
                                addonLabel="m²"
                                classes={[
                                    'land-area-input',
                                    'generic-input',
                                    'no-border-radius',
                                ]}
                                id="land-area-input"
                                label={
                                    t('Grundstücksfläche') +
                                    `${
                                        checkType.includes('Grundstucksflach')
                                            ? '*'
                                            : ''
                                    }`
                                }
                                name="surface_plotArea"
                                stateField={realEstateObject.surface.plotArea}
                                setStateField={updateRealEstateObject}
                                errors={errors}
                                control={control}
                                rules={
                                    checkType.includes('Grundstucksflach')
                                        ? { required: t('This is required') }
                                        : { required: false }
                                }
                                decimal={true}
                            />

                            <CustomObjectInput
                                type="number-with-addon"
                                addonLabel="m²"
                                classes={[
                                    'usable-area-input',
                                    'generic-input',
                                    'no-border-radius',
                                ]}
                                id="usable-area-input"
                                label={t('Korisna površina')}
                                name="surface_usableArea"
                                stateField={realEstateObject.surface.usableArea}
                                setStateField={updateRealEstateObject}
                                errors={errors}
                                control={control}
                                // rules={{ required: t('This is required') }}
                                decimal={true}
                            />
                        </div>
                        <div className="section-row">
                            <CustomObjectInput
                                type="number-with-addon"
                                addonLabel="m²"
                                classes={[
                                    'buildable-area-input',
                                    'generic-input',
                                    'no-border-radius',
                                ]}
                                id="buildable-area-input"
                                label={t('Površina dozv. za gradnju')}
                                name="surface_buildableArea"
                                stateField={
                                    realEstateObject.surface.buildableArea
                                }
                                setStateField={updateRealEstateObject}
                                errors={errors}
                                control={control}
                                // rules={{ required: t('This is required') }}
                                decimal={true}
                            />
                            <CustomObjectInput
                                type="number-with-addon"
                                addonLabel="m²"
                                classes={[
                                    'office-area-input',
                                    'generic-input',
                                    'no-border-radius',
                                ]}
                                id="office-area-input"
                                label={t('Kancelarijski prostor')}
                                name="surface_officeArea"
                                stateField={realEstateObject.surface.officeArea}
                                setStateField={updateRealEstateObject}
                                errors={errors}
                                control={control}
                                // rules={{ required: t('This is required') }}
                                decimal={true}
                            />
                            <CustomObjectInput
                                type="number-with-addon"
                                addonLabel="m²"
                                classes={[
                                    'sales-area-input',
                                    'generic-input',
                                    'no-border-radius',
                                ]}
                                id="sales-area-input"
                                label={t('Prodajni prostor')}
                                name="surface_salesArea"
                                stateField={realEstateObject.surface.salesArea}
                                setStateField={updateRealEstateObject}
                                errors={errors}
                                control={control}
                                // rules={{ required: t('This is required') }}
                                decimal={true}
                            />
                        </div>
                        <div className="section-row">
                            <CustomObjectInput
                                type="number-with-addon"
                                addonLabel="m²"
                                classes={[
                                    'storage-area-input',
                                    'generic-input',
                                    'no-border-radius',
                                ]}
                                id="storage-area-input"
                                label={t('Skladišni prostor')}
                                name="surface_storageArea"
                                stateField={
                                    realEstateObject.surface.storageArea
                                }
                                setStateField={updateRealEstateObject}
                                errors={errors}
                                control={control}
                                // rules={{ required: t('This is required') }}
                                decimal={true}
                            />
                            <CustomObjectInput
                                type="number-with-addon"
                                addonLabel="m"
                                classes={[
                                    'hall-height-input',
                                    'generic-input',
                                    'no-border-radius',
                                ]}
                                id="hall-height-input"
                                label={t('Visina hale')}
                                name="surface_hallheight"
                                stateField={realEstateObject.surface.hallheight}
                                setStateField={updateRealEstateObject}
                                errors={errors}
                                control={control}
                                // rules={{ required: t('This is required') }}
                                decimal={true}
                            />
                            <CustomObjectInput
                                type="number-with-addon"
                                addonLabel="m²"
                                classes={[
                                    'free-area-input',
                                    'generic-input',
                                    'no-border-radius',
                                ]}
                                id="free-area-input"
                                label={t('Slobodan prostor')}
                                name="surface_freeArea"
                                stateField={realEstateObject.surface.freeArea}
                                setStateField={updateRealEstateObject}
                                errors={errors}
                                control={control}
                                // rules={{ required: t('This is required') }}
                                decimal={true}
                            />
                        </div>
                        <div className="section-row">
                            <CustomObjectInput
                                type="number-with-addon"
                                addonLabel="m²"
                                classes={[
                                    'temporary-area-input',
                                    'generic-input',
                                    'no-border-radius',
                                ]}
                                id="temporary-area-input"
                                label={t('Privremena površina')}
                                name="surface_temporaryArea"
                                stateField={
                                    realEstateObject.surface.temporaryArea
                                }
                                setStateField={updateRealEstateObject}
                                errors={errors}
                                control={control}
                                // rules={{ required: t('This is required') }}
                                decimal={true}
                            />
                            <CustomObjectInput
                                type="number-with-addon"
                                addonLabel="m²"
                                classes={[
                                    'weighted-area-input',
                                    'generic-input',
                                    'no-border-radius',
                                ]}
                                id="weighted-area-input"
                                label={t('Ponderisano područje')}
                                name="surface_weightedArea"
                                stateField={
                                    realEstateObject.surface.weightedArea
                                }
                                setStateField={updateRealEstateObject}
                                errors={errors}
                                control={control}
                                // rules={{ required: t('This is required') }}
                                decimal={true}
                            />
                        </div>
                        <div className="section-row custom-section">
                            <CustomObjectInput
                                type="checkbox"
                                addonLabel="m²"
                                id="unfinished-attic"
                                label={t('Nedovršeno potkrovlje')}
                                name="surface_unfinishedAttic"
                                stateField={
                                    realEstateObject.surface.unfinishedAttic
                                }
                                setStateField={updateRealEstateObject}
                                errors={errors}
                                control={control}
                                //rules={{ required: t('This is required') }}
                            />
                            <CustomObjectInput
                                type="number-with-addon"
                                addonLabel="m²"
                                classes={[
                                    'attic-input',
                                    'generic-input',
                                    'no-border-radius',
                                ]}
                                id="attic-input"
                                label={t('Površina potkrovlja')}
                                name="surface_unfinishedAtticArea"
                                stateField={
                                    realEstateObject.surface.unfinishedAtticArea
                                }
                                setStateField={updateRealEstateObject}
                                errors={errors}
                                control={control}
                                // rules={{ required: t('This is required') }}
                                decimal={true}
                            />
                        </div>
                        <div className="Fläche-card">
                            <CustomObjectInput
                                type="number"
                                classes={[
                                    'room-number-input',
                                    'generic-input',
                                    'no-border-radius',
                                ]}
                                id="room-number-input"
                                label={t('Broj soba') + ' *'}
                                name="surface_numberOfRooms"
                                stateField={
                                    realEstateObject.surface.numberOfRooms
                                }
                                setStateField={updateRealEstateObject}
                                errors={errors}
                                control={control}
                                rules={{ required: t('This is required') }}
                                decimal={false}
                            />

                            <CustomObjectInput
                                type="checkbox"
                                classes={['checkbox-middle']}
                                id="has-half-room"
                                label={t('0.5 soba')}
                                name="surface_hasHalfRoom"
                                stateField={
                                    realEstateObject.surface.hasHalfRoom
                                }
                                setStateField={updateRealEstateObject}
                                errors={errors}
                                control={control}
                                //rules={{ required: t('This is required') }}
                            />
                            <CustomObjectInput
                                type="number-with-addon"
                                addonLabel="m"
                                classes={[
                                    'room-height-input',
                                    'generic-input',
                                    'no-border-radius',
                                ]}
                                id="room-height-input"
                                label={t('Visina sobe')}
                                name="surface_roomHeight"
                                // rules={{ required: t('This is required') }}
                                setStateField={updateRealEstateObject}
                                errors={errors}
                                control={control}
                                decimal={true}
                            />
                        </div>
                        <div className="section-row">
                            <CustomObjectInput
                                type="number"
                                classes={[
                                    'garden-number-input',
                                    'generic-input',
                                    'no-border-radius',
                                ]}
                                id="garden-number-input"
                                label={t('Broj vrtova')}
                                name="surface_numberOfGardens"
                                stateField={
                                    realEstateObject.surface.numberOfGardens
                                }
                                setStateField={updateRealEstateObject}
                                errors={errors}
                                control={control}
                                // rules={{ required: t('This is required') }}
                                decimal={false}
                            />
                            <CustomObjectInput
                                type="number-with-addon"
                                addonLabel="m²"
                                classes={[
                                    'garden-area-input',
                                    'generic-input',
                                    'no-border-radius',
                                ]}
                                id="garden-area-input"
                                label={t('Površina vrtova')}
                                name="surface_gardenArea"
                                stateField={realEstateObject.surface.gardenArea}
                                setStateField={updateRealEstateObject}
                                errors={errors}
                                control={control}
                                // rules={{ required: t('This is required') }}
                                decimal={true}
                            />
                        </div>
                        <div className="section-row">
                            <CustomObjectInput
                                type="number"
                                classes={[
                                    'basement-number-input',
                                    'generic-input',
                                    'no-border-radius',
                                ]}
                                id="basement-number-input"
                                label={t('Broj podruma')}
                                name="surface_numberOfBasements"
                                stateField={
                                    realEstateObject.surface.numberOfBasements
                                }
                                setStateField={updateRealEstateObject}
                                errors={errors}
                                control={control}
                                // rules={{ required: t('This is required') }}
                                decimal={false}
                            />
                            <CustomObjectInput
                                type="number-with-addon"
                                addonLabel="m²"
                                classes={[
                                    'basement-area-input',
                                    'generic-input',
                                    'no-border-radius',
                                ]}
                                id="basement-area-input"
                                label={t('Površina podruma')}
                                name="surface_basementArea"
                                stateField={
                                    realEstateObject.surface.basementArea
                                }
                                setStateField={updateRealEstateObject}
                                errors={errors}
                                control={control}
                                // rules={{ required: t('This is required') }}
                                decimal={true}
                            />
                        </div>
                        <div className="section-row">
                            <CustomObjectInput
                                type="number"
                                classes={[
                                    'balcony-number-input',
                                    'generic-input',
                                    'no-border-radius',
                                ]}
                                id="balcony-number-input"
                                label={t('Broj balkona')}
                                name="surface_numberOfBalconies"
                                stateField={
                                    realEstateObject.surface.numberOfBalconies
                                }
                                setStateField={updateRealEstateObject}
                                errors={errors}
                                control={control}
                                // rules={{ required: t('This is required') }}
                                decimal={false}
                            />
                            <CustomObjectInput
                                type="number-with-addon"
                                addonLabel="m²"
                                classes={[
                                    'balcony-area-input',
                                    'generic-input',
                                    'no-border-radius',
                                ]}
                                id="balcony-area-input"
                                label={t('Površina balkona')}
                                name="surface_balconyArea"
                                stateField={
                                    realEstateObject.surface.balconyArea
                                }
                                setStateField={updateRealEstateObject}
                                errors={errors}
                                control={control}
                                // rules={{ required: t('This is required') }}
                                decimal={true}
                            />
                        </div>
                        <div className="section-row">
                            <CustomObjectInput
                                type="number"
                                classes={[
                                    'terrace-number-input',
                                    'generic-input',
                                    'no-border-radius',
                                ]}
                                id="terrace-number-input"
                                label={t('Broj terasa')}
                                name="surface_numberOfTerraces"
                                stateField={
                                    realEstateObject.surface.numberOfTerraces
                                }
                                setStateField={updateRealEstateObject}
                                errors={errors}
                                control={control}
                                // rules={{ required: t('This is required') }}
                                decimal={false}
                            />
                            <CustomObjectInput
                                type="number-with-addon"
                                addonLabel="m²"
                                classes={[
                                    'terrace-area-input',
                                    'generic-input',
                                    'no-border-radius',
                                ]}
                                id="terrace-area-input"
                                label={t('Površina terasa')}
                                name="surface_terraceArea"
                                stateField={
                                    realEstateObject.surface.terraceArea
                                }
                                setStateField={updateRealEstateObject}
                                errors={errors}
                                control={control}
                                // rules={{ required: t('This is required') }}
                                decimal={true}
                            />
                        </div>
                        <div className="section-row">
                            <CustomObjectInput
                                type="number"
                                classes={[
                                    'loggias-number-input',
                                    'generic-input',
                                    'no-border-radius',
                                ]}
                                id="loggias-number-input"
                                label={t('Broj lođa')}
                                name="surface_numberOfLoggias"
                                stateField={
                                    realEstateObject.surface.numberOfLoggias
                                }
                                setStateField={updateRealEstateObject}
                                errors={errors}
                                control={control}
                                // rules={{ required: t('This is required') }}
                                decimal={false}
                            />
                            <CustomObjectInput
                                type="number-with-addon"
                                addonLabel="m²"
                                classes={[
                                    'loggias-area-input',
                                    'generic-input',
                                    'no-border-radius',
                                ]}
                                id="loggias-area-input"
                                label={t('Površina lođa')}
                                name="surface_loggiasArea"
                                stateField={
                                    realEstateObject.surface.loggiasArea
                                }
                                setStateField={updateRealEstateObject}
                                errors={errors}
                                control={control}
                                // rules={{ required: t('This is required') }}
                                decimal={true}
                            />
                        </div>
                        <div className="section-row">
                            <CustomObjectInput
                                type="number"
                                classes={[
                                    'garage-number-input',
                                    'generic-input',
                                    'no-border-radius',
                                ]}
                                id="garage-number-input"
                                label={t('Broj garaža')}
                                name="surface_numberOfGarages"
                                stateField={
                                    realEstateObject.surface.numberOfGarages
                                }
                                setStateField={updateRealEstateObject}
                                errors={errors}
                                control={control}
                                // rules={{ required: t('This is required') }}
                                decimal={false}
                            />
                            <CustomObjectInput
                                type="number-with-addon"
                                addonLabel="m²"
                                classes={[
                                    'garage-area-input',
                                    'generic-input',
                                    'no-border-radius',
                                ]}
                                id="garage-area-input"
                                label={t('Površina garaža')}
                                name="surface_garageArea"
                                stateField={realEstateObject.surface.garageArea}
                                setStateField={updateRealEstateObject}
                                errors={errors}
                                control={control}
                                // rules={{ required: t('This is required') }}
                                decimal={true}
                            />
                        </div>
                        <div className="section-row">
                            <CustomObjectInput
                                type="number"
                                classes={[
                                    'parking-number-input',
                                    'generic-input',
                                    'no-border-radius',
                                ]}
                                id="parking-number-input"
                                label={t('Broj parking mesta')}
                                name="surface_numberOfParkingSpaces"
                                stateField={
                                    realEstateObject.surface
                                        .numberOfParkingSpaces
                                }
                                setStateField={updateRealEstateObject}
                                errors={errors}
                                control={control}
                                // rules={{ required: t('This is required') }}
                                decimal={false}
                            />
                            <CustomObjectInput
                                type="number-with-addon"
                                addonLabel="m²"
                                classes={[
                                    'parking-area-input',
                                    'generic-input',
                                    'no-border-radius',
                                ]}
                                id="parking-area-input"
                                label={t('Površina parkinga')}
                                name="surface_parkingArea"
                                stateField={
                                    realEstateObject.surface.parkingArea
                                }
                                setStateField={updateRealEstateObject}
                                errors={errors}
                                control={control}
                                // rules={{ required: t('This is required') }}
                                decimal={true}
                            />
                        </div>
                        <div className="Fläche-card-bottom">
                            <CustomObjectInput
                                type="number"
                                classes={[
                                    'toilet-number-input',
                                    'generic-input',
                                    'no-border-radius',
                                ]}
                                id="toilet-number-input"
                                label={t('Broj toaleta')}
                                name="surface_numberOfToilets"
                                stateField={
                                    realEstateObject.surface.numberOfToilets
                                }
                                setStateField={updateRealEstateObject}
                                errors={errors}
                                control={control}
                                // rules={{ required: t('This is required') }}
                                decimal={false}
                            />
                            <CustomObjectInput
                                type="number"
                                classes={[
                                    'bathroom-number-input',
                                    'generic-input',
                                    'no-border-radius',
                                ]}
                                id="bathroom-number-input"
                                label={t('Broj kupatila')}
                                name="surface_numberOfBathrooms"
                                stateField={
                                    realEstateObject.surface.numberOfBathrooms
                                }
                                setStateField={updateRealEstateObject}
                                errors={errors}
                                control={control}
                                // rules={{ required: t('This is required') }}
                                decimal={false}
                            />
                            <CustomObjectInput
                                type="number"
                                classes={[
                                    'storage-number-input',
                                    'generic-input',
                                    'no-border-radius',
                                ]}
                                id="storage-number-input"
                                label={t('Broj ostava')}
                                name="surface_numberOfStorageRooms"
                                stateField={
                                    realEstateObject.surface
                                        .numberOfStorageRooms
                                }
                                setStateField={updateRealEstateObject}
                                errors={errors}
                                control={control}
                                // rules={{ required: t('This is required') }}
                                decimal={false}
                            />
                        </div>
                    </Card>
                    <Card
                        className="floor-section"
                        header={<CardHeader title={t('Spratnost / Etagen')} />}
                    >
                        <div className="section-row">
                            <CustomObjectInput
                                type="number"
                                classes={['generic-input']}
                                id="floor-number-input"
                                label={t('Sprat')}
                                name="floorNumber"
                                stateField={realEstateObject.floorNumber}
                                setStateField={updateRealEstateObject}
                                errors={errors}
                                control={control}
                                // rules={{ required: t('This is required') }}
                            />
                            <CustomObjectInput
                                type="text"
                                classes={[
                                    'floor-description-input',
                                    'generic-input',
                                    'input-margin',
                                ]}
                                id="floor-description-input"
                                label={t('Sprat - Dodatni opis')}
                                name="floorDescription"
                                stateField={realEstateObject.floorDescription}
                                setStateField={updateRealEstateObject}
                                errors={errors}
                                control={control}
                                // rules={{ required: t('This is required') }}
                            />
                        </div>
                        <div className="etage-card">
                            <CustomObjectInput
                                type="dropdown"
                                classes={[
                                    'floor-number-input',
                                    'generic-input',
                                    'input-margin',
                                ]}
                                id="floor-input"
                                label={t('Broj spratova')}
                                name="floor"
                                stateField={realEstateObject.floor}
                                setStateField={updateRealEstateObject}
                                errors={errors}
                                control={control}
                                // rules={{ required: t('This is required') }}
                                dropdownOptions={floorOptions}
                            />
                            <CustomObjectInput
                                type="number"
                                classes={[
                                    'attic-number-input',
                                    'generic-input',
                                    'input-margin',
                                ]}
                                id="attic-number-input"
                                label={t('Broj potkrovlja')}
                                name="numberOfAtticFloors"
                                stateField={
                                    realEstateObject.numberOfAtticFloors
                                }
                                setStateField={updateRealEstateObject}
                                errors={errors}
                                control={control}
                                // rules={{ required: t('This is required') }}
                            />
                            <CustomObjectInput
                                type="checkbox"
                                classes={['checkbox-middle', 'generic-input']}
                                id="has-mezzanine"
                                label={t('Medjusprat')}
                                name="hasMezzanine"
                                stateField={realEstateObject.hasMezzanine}
                                setStateField={updateRealEstateObject}
                                errors={errors}
                                control={control}
                                //rules={{ required: t('This is required') }}
                            />
                        </div>
                    </Card>
                    <Card
                        className="energy-section"
                        header={
                            <CardHeader
                                title={t(
                                    'Energetski sertifikat / Enegieausweis',
                                )}
                            />
                        }
                    >
                        <div className="section-row">
                            <CustomObjectInput
                                type="checkbox"
                                id="energyCertificateAvailable"
                                label={t('Enegieausweis verfügbar')}
                                classes={['checkbox-middle']}
                                name="energyCertificateAvailable"
                                stateField={
                                    realEstateObject.energyCertificateAvailable
                                }
                                setStateField={updateRealEstateObject}
                                errors={errors}
                                control={control}
                                //rules={{ required: t('This is required') }}
                            />
                            <CustomObjectInput
                                type="dropdown"
                                classes={[
                                    'energyCertificateYear-input',
                                    'generic-input',
                                ]}
                                id="klasse-input"
                                label={t('Erstellungsdatum')}
                                name="energyCertificateYear"
                                stateField={
                                    realEstateObject.energyCertificateYear
                                }
                                setStateField={updateRealEstateObject}
                                dropdownOptions={energyCertificateYear}
                                errors={errors}
                                control={control}
                                disabled={
                                    !realEstateObject.energyCertificateAvailable
                                }
                                // rules={{ required: t('This is required') }}
                            />
                        </div>
                        <div className="section-row">
                            <CustomObjectInput
                                type="number-with-addon"
                                addonLabel=" kWh / m²a"
                                addonClassNames={['hwb-addon']}
                                classes={[
                                    'hwb-input',
                                    'no-border-radius',
                                    'generic-input',
                                    'input-margin',
                                ]}
                                id="hwb-input"
                                label={
                                    appState.metadata?.country === 'de'
                                        ? 'Energiekennwert'
                                        : 'HWB'
                                }
                                name="hwb"
                                stateField={realEstateObject.hwb}
                                setStateField={updateRealEstateObject}
                                errors={errors}
                                control={control}
                                disabled={
                                    !realEstateObject.energyCertificateAvailable
                                }
                                // rules={{ required: t('This is required') }}
                                decimal={true}
                            />
                            <CustomObjectInput
                                type="dropdown"
                                classes={['klasse-input', 'generic-input']}
                                id="klasse-input"
                                label={
                                    appState.metadata?.country === 'de'
                                        ? 'Energieeffizienzklasse'
                                        : 'Klasse'
                                }
                                name="class"
                                stateField={realEstateObject.class}
                                setStateField={updateRealEstateObject}
                                dropdownOptions={klasseOptions}
                                errors={errors}
                                control={control}
                                disabled={
                                    !realEstateObject.energyCertificateAvailable
                                }
                                // rules={{ required: t('This is required') }}
                            />
                            {appState.metadata?.country === 'at' && (
                                <>
                                    <CustomObjectInput
                                        type="text"
                                        classes={[
                                            'fgee-input',
                                            'generic-input',
                                        ]}
                                        id="fgee-input"
                                        label={t('fGEE')}
                                        name="fgee"
                                        stateField={realEstateObject.fgee}
                                        setStateField={updateRealEstateObject}
                                        errors={errors}
                                        control={control}
                                        disabled={
                                            !realEstateObject.energyCertificateAvailable
                                        }
                                        // rules={{ required: t('This is required') }}
                                    />
                                    <CustomObjectInput
                                        type="dropdown"
                                        classes={[
                                            'fgee-klasse-input',
                                            'generic-input',
                                        ]}
                                        id="fgee-klasse-input"
                                        label={t('Klasse')}
                                        name="fgeeClass"
                                        stateField={realEstateObject.fgeeClass}
                                        setStateField={updateRealEstateObject}
                                        dropdownOptions={klasseOptions}
                                        errors={errors}
                                        control={control}
                                        disabled={
                                            !realEstateObject.energyCertificateAvailable
                                        }
                                        // rules={{ required: t('This is required') }}
                                    />
                                </>
                            )}
                        </div>
                        <div className="section-row">
                            {appState.metadata?.country === 'de' && (
                                <>
                                    <CustomObjectInput
                                        type="calendar"
                                        classes={[
                                            'valid-from-input',
                                            'generic-input',
                                        ]}
                                        id="valid-from-input"
                                        dateType={'date'}
                                        label={'Erstellt am'}
                                        name="energyCertificateValidFrom"
                                        stateField={moment(
                                            realEstateObject.energyCertificateValidFrom,
                                        ).format('YYYY-MM-DD')}
                                        setStateField={updateRealEstateObject}
                                        errors={errors}
                                        control={control}
                                        disabled={
                                            !realEstateObject.energyCertificateAvailable
                                        }
                                        // rules={{ required: t('This is required') }}
                                    />
                                    <CustomObjectInput
                                        type="calendar"
                                        classes={[
                                            'valid-from-input',
                                            'generic-input',
                                        ]}
                                        id="valid-from-input"
                                        dateType={'date'}
                                        label={t('Gültig bis')}
                                        name="energyCertificateDateOfExpiry"
                                        stateField={moment(
                                            realEstateObject.energyCertificateDateOfExpiry,
                                        ).format('YYYY-MM-DD')}
                                        setStateField={updateRealEstateObject}
                                        errors={errors}
                                        control={control}
                                        disabled={
                                            !realEstateObject.energyCertificateAvailable
                                        }
                                        // rules={{ required: t('This is required') }}
                                    />
                                </>
                            )}
                            {appState.metadata?.country === 'at' && (
                                <div className="column-span-2">
                                    <CustomObjectInput
                                        type="calendar"
                                        classes={[
                                            'valid-from-input',
                                            'generic-input',
                                        ]}
                                        id="valid-from-input"
                                        dateType={'date'}
                                        label={t('Važi od')}
                                        name="energyCertificateDateOfExpiry"
                                        stateField={
                                            moment(
                                                realEstateObject.energyCertificateDateOfExpiry,
                                            ).format('YYYY') === '1970'
                                                ? ''
                                                : realEstateObject.energyCertificateDateOfExpiry
                                        }
                                        setStateField={updateRealEstateObject}
                                        errors={errors}
                                        control={control}
                                        disabled={
                                            !realEstateObject.energyCertificateAvailable
                                        }
                                        // rules={{ required: t('This is required') }}
                                    />
                                </div>
                            )}

                            {appState.metadata?.country === 'at' && (
                                <CustomObjectInput
                                    type="checkbox"
                                    classes={[
                                        'checkbox-middle',
                                        'generic-input',
                                    ]}
                                    id="automatic-disclaimer"
                                    label={t('Automaticher Disclaimer')}
                                    name="automaticDisclaimer"
                                    stateField={
                                        realEstateObject.automaticDisclaimer
                                    }
                                    setStateField={updateRealEstateObject}
                                    errors={errors}
                                    control={control}
                                    disabled={
                                        !realEstateObject.energyCertificateAvailable
                                    }
                                    //rules={{ required: 'This is required' }}
                                />
                            )}
                        </div>
                        {appState.metadata?.country === 'de' && (
                            <>
                                <div className="section-row">
                                    <CustomObjectInput
                                        type="dropdown"
                                        classes={['generic-input']}
                                        id="deposit-number"
                                        label={t('Energieausweistyp ')}
                                        name="energyCertificateType"
                                        stateField={
                                            realEstateObject.energyCertificateType
                                        }
                                        setStateField={updateRealEstateObject}
                                        dropdownOptions={
                                            energyCertificateTypeOptions
                                        }
                                        errors={errors}
                                        control={control}
                                        disabled={
                                            !realEstateObject.energyCertificateAvailable
                                        }
                                        // rules={{ required: t('This is required') }}
                                    />
                                    {/* <CustomObjectInput
                                        type="text"
                                        classes={['generic-input']}
                                        id="cadastre-input"
                                        label={t('Bedarfsausweis ')}
                                        name="requirementCertificate"
                                        stateField={
                                            realEstateObject.requirementCertificate
                                        }
                                        setStateField={updateRealEstateObject}
                                        errors={errors}
                                        control={control}
                                        // rules={{ required: t('This is required') }}
                                    />
                                    <CustomObjectInput
                                        type="text"
                                        classes={['generic-input']}
                                        id="lot-number-input"
                                        label={t('Verbrauchsausweis ')}
                                        name="consumptionCertificate"
                                        stateField={
                                            realEstateObject.consumptionCertificate
                                        }
                                        setStateField={updateRealEstateObject}
                                        errors={errors}
                                        control={control}
                                        // rules={{ required: t('This is required') }}
                                    /> */}
                                </div>
                                <div className="section-row">
                                    <CustomObjectInput
                                        type="dropdown"
                                        classes={[
                                            'supervisor-input',
                                            'generic-input',
                                        ]}
                                        id="supervisor-input"
                                        label={t('Heizungsart')}
                                        name="heatingType"
                                        stateField={
                                            realEstateObject.heatingType
                                        }
                                        setStateField={updateRealEstateObject}
                                        dropdownOptions={heatingTypeOptions}
                                        errors={errors}
                                        control={control}
                                        disabled={
                                            !realEstateObject.energyCertificateAvailable
                                        }
                                        // rules={{ required: t('This is required') }}
                                    />
                                    <CustomObjectInput
                                        type="dropdown"
                                        classes={[
                                            'supervisor-input',
                                            'generic-input',
                                        ]}
                                        id="energy-input"
                                        label={t('Energieträger')}
                                        name="energySources"
                                        stateField={
                                            realEstateObject.energySources
                                        }
                                        setStateField={updateRealEstateObject}
                                        dropdownOptions={energySourcesOptions}
                                        errors={errors}
                                        control={control}
                                        disabled={
                                            !realEstateObject.energyCertificateAvailable
                                        }
                                        // rules={{ required: t('This is required') }}
                                    />
                                </div>
                            </>
                        )}
                    </Card>
                    <PermissionContainer
                        hasPermission={Permissions.USER_DELETE}
                        currentUser={appState.currentUser}
                    >
                        <Card
                            className="supervisor-section"
                            header={<CardHeader title={t('Supervisor')} />}
                        >
                            <CustomObjectInput
                                type="dropdown"
                                classes={['supervisor-input', 'generic-input']}
                                id="supervisor-input"
                                label={t('Supervisor')}
                                name="employeeId"
                                stateField={realEstateObject.employeeId}
                                setStateField={updateRealEstateObject}
                                dropdownOptions={employeeOptions()}
                                errors={errors}
                                control={control}
                                // rules={{ required: t('This is required') }}
                            />
                        </Card>
                    </PermissionContainer>
                </div>
                <div className="object-form-column">
                    <Card
                        className="general-section"
                        header={<CardHeader title={t('Opšte / Allgemein')} />}
                    >
                        <div className="section-row">
                            <CustomObjectInput
                                type="text"
                                classes={['marking-input', 'generic-input']}
                                id="labelling"
                                label={t('Označavanje')}
                                name="labelling"
                                stateField={realEstateObject.labelling}
                                setStateField={updateRealEstateObject}
                                errors={errors}
                                control={control}
                                // rules={{ required: t('This is required') }}
                            />
                            <CustomObjectInput
                                type="checkbox"
                                classes={['checkbox-middle', 'generic-input']}
                                id="no-obsticle"
                                label={t('Bez prepreka')}
                                name="barrierFree"
                                stateField={realEstateObject.barrierFree}
                                setStateField={updateRealEstateObject}
                                errors={errors}
                                control={control}
                                //rules={{ required: t('This is required') }}
                            />
                        </div>
                        <div className="section-row">
                            <CustomObjectInput
                                type="dropdown"
                                classes={[
                                    'object-category-input',
                                    'generic-input',
                                ]}
                                id="object-category-input"
                                label={t('Tip nekretnine') + ' *'}
                                name="propertyTypeId"
                                stateField={realEstateObject.propertyTypeId}
                                setStateField={updateRealEstateObject}
                                errors={errors}
                                control={control}
                                rules={{ required: t('This is required') }}
                                dropdownOptions={propertyTypeOptions}
                            />

                            <span className="absolute-span">
                                <CustomObjectInput
                                    type="dropdown"
                                    classes={['state-input']}
                                    id="property-subtype-id"
                                    label={t('Untertyp' + ' *')}
                                    name="propertySubtypeId"
                                    stateField={
                                        realEstateObject.propertySubtypeId
                                    }
                                    setStateField={updateRealEstateObject}
                                    errors={errors}
                                    control={control}
                                    rules={{ required: t('This is required') }}
                                    dropdownOptions={
                                        realEstateObject.propertyTypeId !== null
                                            ? propertySubtypeOptions.find(
                                                  (x) =>
                                                      x.id ===
                                                      realEstateObject.propertyTypeId,
                                              ).items
                                            : []
                                    }
                                    // disabled={
                                    //     realEstateObject.propertyTypeId ===
                                    //         null ||
                                    //     realEstateObject.propertyTypeId === 12
                                    // }
                                />
                                {showHint && <p>{t('Please select type')}</p>}
                            </span>
                        </div>
                        <div className="section-row">
                            <CustomObjectInput
                                type="dropdown"
                                classes={[
                                    'object-category-input',
                                    'generic-input',
                                ]}
                                id="floor-plan-input"
                                label={t('Tlocrt')}
                                name="floorPlan"
                                stateField={realEstateObject.floorPlan}
                                setStateField={updateRealEstateObject}
                                dropdownOptions={floorPlanOptions}
                                errors={errors}
                                control={control}
                                // rules={{ required: t('This is required') }}
                            />
                            <CustomObjectInput
                                type="checkbox"
                                classes={['checkbox-middle', 'generic-input']}
                                id="also-vacation-property"
                                label={t('Prikladno u turističke svrhe')}
                                name="alsoVacationProperty"
                                stateField={
                                    realEstateObject.alsoVacationProperty
                                }
                                setStateField={updateRealEstateObject}
                                errors={errors}
                                control={control}
                                //rules={{ required: t('This is required') }}
                            />
                        </div>
                    </Card>
                    <Card
                        className="object-use-section"
                        header={
                            <CardHeader
                                title={t(
                                    'Vrste upotrebe objekata / Nutzungsart',
                                )}
                            />
                        }
                    >
                        <div className="type-of-property">
                            <CustomObjectInput
                                type="checkbox"
                                containerClassNames={['lowered']}
                                classes={['checkbox-middle', 'generic-input']}
                                id="type-of-use-living"
                                label={t('Stanovanje')}
                                name="typeOfUseLiving"
                                stateField={realEstateObject.typeOfUseLiving}
                                setStateField={updateRealEstateObject}
                                errors={errors}
                                control={control}
                                //rules={{ required: t('This is required') }}
                            />
                            <CustomObjectInput
                                type="checkbox"
                                containerClassNames={['lowered']}
                                classes={['checkbox-middle', 'generic-input']}
                                id="type-of-use-business"
                                label={t('Poslovna')}
                                name="typeOfUseBusiness"
                                stateField={realEstateObject.typeOfUseBusiness}
                                setStateField={updateRealEstateObject}
                                errors={errors}
                                control={control}
                                //rules={{ required: t('This is required') }}
                            />
                            <CustomObjectInput
                                type="checkbox"
                                containerClassNames={['lowered']}
                                classes={['checkbox-middle', 'generic-input']}
                                id="type-of-use-investment"
                                label={t('Investicija')}
                                name="typeOfUseInvestment"
                                stateField={
                                    realEstateObject.typeOfUseInvestment
                                }
                                setStateField={updateRealEstateObject}
                                errors={errors}
                                control={control}
                                //rules={{ required: t('This is required') }}
                            />
                        </div>
                    </Card>
                    {showMewContactModal ? (
                        <NewContactModal
                            getSellerId={getSellerId}
                            contactRole={'Seller'}
                            show={handleShowNewContactModal}
                            toasts={toasts}
                        />
                    ) : null}
                    <Card
                        className="seller-section"
                        header={
                            <CardHeader
                                title={t('Prodavac objekta / Objektabgeber')}
                                titleRight={
                                    <Button
                                        style={{ marginTop: '-4px' }}
                                        onClick={handleShowNewContactModal}
                                        className="p-button-sm"
                                    >
                                        {t('Verkäufer erfassen')}
                                    </Button>
                                }
                            />
                        }
                    >
                        <div className="section-row">
                            <CustomObjectInput
                                type="dropdown"
                                classes={['contact-input', 'generic-input']}
                                id="contact-input-base-data"
                                label={t('Kontakt') + ' *'}
                                name="sellerId"
                                stateField={realEstateObject.sellerId}
                                setStateField={updateRealEstateObject}
                                onFilter={(e) => {
                                    setSearchContact(e.filter.length);
                                    updateRealEstateObject('sellerId', null);
                                }}
                                emptyFilterMessage={'Search contacts...'}
                                dropdownOptions={
                                    realEstateObject.sellerId || searchChar > 2
                                        ? contactOptions()
                                        : [
                                              {
                                                  label: 'Search contacts...',
                                                  disabled: true,
                                              },
                                          ]
                                }
                                errors={errors}
                                filter={true}
                                control={control}
                                disabled={realEstateObject.toRemove1}
                                rules={
                                    realEstateObject.sellerId === null
                                        ? { required: t('This is required') }
                                        : { required: false }
                                }
                            />
                        </div>
                    </Card>
                    <Card
                        className="mediation-section"
                        header={
                            <CardHeader title={t('Nalog za posredovanje')} />
                        }
                    >
                        <div className="section-row">
                            <CustomObjectInput
                                type="dropdown"
                                classes={[
                                    'mediation-order-type-input',
                                    'generic-input',
                                ]}
                                id="mediation-order-type-input"
                                label={t('Tip')}
                                name="mediationOrderType"
                                stateField={realEstateObject.mediationOrderType}
                                setStateField={updateRealEstateObject}
                                dropdownOptions={mediationOrderTypeOptions}
                                errors={errors}
                                control={control}
                                // rules={{ required: t('This is required') }}
                            />
                        </div>
                        <div className="section-row">
                            <CustomObjectInput
                                type="dropdown"
                                classes={[
                                    'agreement-type-input',
                                    'generic-input',
                                ]}
                                id="agreement-type-input"
                                label={t('Dogovor')}
                                name="agreement"
                                stateField={realEstateObject.agreement}
                                setStateField={updateRealEstateObject}
                                dropdownOptions={agreementTypeOptions}
                                errors={errors}
                                control={control}
                                // rules={{ required: t('This is required') }}
                            />
                        </div>
                        <div className="section-row">
                            <CustomObjectInput
                                type="calendar"
                                classes={[
                                    'agreement-valid-from-input',
                                    'generic-input',
                                ]}
                                id="mediation-valid-from"
                                label={t('Gültig von')}
                                name="validFrom"
                                dateType={'date'}
                                stateField={realEstateObject.validFrom}
                                setStateField={updateRealEstateObject}
                                errors={errors}
                                control={control}
                                // rules={{ required: t('This is required') }}
                            />
                            <CustomObjectInput
                                type="calendar"
                                classes={['expiry-date-input', 'generic-input']}
                                id="expiry-date-input"
                                label={t('Datum isteka')}
                                name="dateOfExpiry"
                                dateType={'date'}
                                stateField={realEstateObject.dateOfExpiry}
                                setStateField={updateRealEstateObject}
                                errors={errors}
                                control={control}
                                // rules={{ required: t('This is required') }}
                            />
                        </div>
                    </Card>
                    <Card
                        className="cadastre-section"
                        header={<CardHeader title={t('Zemljišne knjige')} />}
                    >
                        {appState.metadata?.country === 'de' ? (
                            <div className="section-row">
                                <CustomObjectInput
                                    type="text"
                                    classes={['generic-input']}
                                    id="deposit-number"
                                    label={t('Grundbuchblatt')}
                                    name="landRegisterSheet"
                                    stateField={
                                        realEstateObject.landRegisterSheet
                                    }
                                    setStateField={updateRealEstateObject}
                                    errors={errors}
                                    control={control}
                                    // rules={{ required: t('This is required') }}
                                />
                                <CustomObjectInput
                                    type="text"
                                    classes={['generic-input']}
                                    id="cadastre-input"
                                    label={t('Gemarkung')}
                                    name="district"
                                    stateField={realEstateObject.district}
                                    setStateField={updateRealEstateObject}
                                    errors={errors}
                                    control={control}
                                    // rules={{ required: t('This is required') }}
                                />
                                <CustomObjectInput
                                    type="text"
                                    classes={['generic-input']}
                                    id="lot-number-input"
                                    label={t('Flur')}
                                    name="corridor"
                                    stateField={realEstateObject.corridor}
                                    setStateField={updateRealEstateObject}
                                    errors={errors}
                                    control={control}
                                    // rules={{ required: t('This is required') }}
                                />
                                <CustomObjectInput
                                    type="text"
                                    classes={['generic-input']}
                                    id="lot-number-input"
                                    label={t('Flurstück')}
                                    name="parcel"
                                    stateField={realEstateObject.parcel}
                                    setStateField={updateRealEstateObject}
                                    errors={errors}
                                    control={control}
                                    // rules={{ required: t('This is required') }}
                                />
                            </div>
                        ) : (
                            <div className="section-row">
                                <CustomObjectInput
                                    type="text"
                                    classes={['generic-input']}
                                    id="deposit-number"
                                    label={t('Broj priloška')}
                                    name="depositNumber"
                                    stateField={realEstateObject.depositNumber}
                                    setStateField={updateRealEstateObject}
                                    errors={errors}
                                    control={control}
                                    // rules={{ required: t('This is required') }}
                                />
                                <CustomObjectInput
                                    type="text"
                                    classes={['generic-input']}
                                    id="cadastre-input"
                                    label={t('Katastarska zajednica')}
                                    name="cadastreNumber"
                                    stateField={realEstateObject.cadastreNumber}
                                    setStateField={updateRealEstateObject}
                                    errors={errors}
                                    control={control}
                                    // rules={{ required: t('This is required') }}
                                />
                                <CustomObjectInput
                                    type="text"
                                    classes={['generic-input']}
                                    id="lot-number-input"
                                    label={t('Broj parcele')}
                                    name="lotNumber"
                                    stateField={realEstateObject.lotNumber}
                                    setStateField={updateRealEstateObject}
                                    errors={errors}
                                    control={control}
                                    // rules={{ required: t('This is required') }}
                                />
                            </div>
                        )}
                    </Card>
                    <Card
                        className="rent-section"
                        header={<CardHeader title={t('Miete / Kirija')} />}
                    >
                        <div className="section-row">
                            <CustomObjectInput
                                type="number-with-addon"
                                addonLabel={t('years')}
                                classes={['no-border-radius']}
                                id="maximum-rental-period-year"
                                label={t('Maximum rental period')}
                                name="maximumRentalPeriodYears"
                                stateField={
                                    realEstateObject.maximumRentalPeriodYears
                                }
                                setStateField={updateRealEstateObject}
                                errors={errors}
                                control={control}
                                // rules={{ required: t('This is required') }}
                                decimal={true}
                                disabled={
                                    realEstateObject.unlimitedRentalPeriod
                                        ? true
                                        : null
                                }
                            />
                            <CustomObjectInput
                                type="number-with-addon"
                                containerClassNames={[
                                    'rent-element-margin-top',
                                ]}
                                addonLabel={t('months')}
                                classes={['no-border-radius']}
                                id="maximum-rental-period-month"
                                name="maximumRentalPeriodMonths"
                                stateField={
                                    realEstateObject.maximumRentalPeriodMonths
                                }
                                setStateField={updateRealEstateObject}
                                errors={errors}
                                control={control}
                                // rules={{ required: t('This is required') }}
                                decimal={true}
                                disabled={
                                    realEstateObject.unlimitedRentalPeriod
                                        ? true
                                        : null
                                }
                            />
                            <CustomObjectInput
                                type="checkbox"
                                id="unlimited-rental-period"
                                label={t('Unlimited rental period')}
                                name="unlimitedRentalPeriod"
                                classes={['checkbox-middle']}
                                stateField={
                                    realEstateObject.unlimitedRentalPeriod
                                }
                                setStateField={updateRealEstateObject}
                                errors={errors}
                                control={control}
                                //rules={{ required: t('This is required') }}
                            />
                        </div>
                        <div className="section-row">
                            <CustomObjectInput
                                type="number-with-addon"
                                addonLabel={t('years')}
                                classes={['no-border-radius']}
                                id="waiver-of-termination-year"
                                // rules={{ required: t('This is required') }}
                                name="waiverOfTerminationYears"
                                stateField={
                                    realEstateObject.waiverOfTerminationYears
                                }
                                setStateField={updateRealEstateObject}
                                errors={errors}
                                control={control}
                                decimal={true}
                            />
                            <CustomObjectInput
                                type="number-with-addon"
                                addonLabel={t('months')}
                                containerClassNames={['rent-element']}
                                classes={['no-border-radius']}
                                id="waiver-of-termination-month"
                                name="waiverOfTerminationMonths"
                                stateField={
                                    realEstateObject.waiverOfTerminationMonths
                                }
                                setStateField={updateRealEstateObject}
                                errors={errors}
                                control={control}
                                // rules={{ required: t('This is required') }}
                                decimal={true}
                            />
                        </div>
                    </Card>
                    <Card
                        className="other-section"
                        header={<CardHeader title={t('Sonstiges / Drugo')} />}
                    >
                        <div className="section-row">
                            <CustomObjectInput
                                type="dropdown"
                                classes={['generic-input']}
                                id="type-input"
                                label={t('Tip')}
                                name="other_type"
                                stateField={realEstateObject.other.type}
                                setStateField={updateRealEstateObject}
                                errors={errors}
                                control={control}
                                // rules={{ required: t('This is required') }}
                                dropdownOptions={typeOptions}
                            />
                            <CustomObjectInput
                                type="dropdown"
                                classes={['generic-input']}
                                id="status-input"
                                label={t('Status')}
                                name="other_status"
                                stateField={realEstateObject.other.status}
                                setStateField={updateRealEstateObject}
                                errors={errors}
                                control={control}
                                // rules={{ required: t('This is required') }}
                                dropdownOptions={statusOptions}
                            />
                            <CustomObjectInput
                                type="dropdown"
                                classes={['generic-input']}
                                id="house-condition-input"
                                label={t('Stanje kuće')}
                                name="other_houseCondition"
                                stateField={
                                    realEstateObject.other.houseCondition
                                }
                                setStateField={updateRealEstateObject}
                                errors={errors}
                                control={control}
                                // rules={{ required: t('This is required') }}
                                dropdownOptions={houseConditionOptions}
                            />
                        </div>
                        <div className="section-row">
                            <CustomObjectInput
                                type="dropdown"
                                classes={['generic-input']}
                                id="construction-year"
                                label={t('Godina izgradnje')}
                                name="other_constructionYear"
                                stateField={
                                    realEstateObject.other.constructionYear
                                }
                                setStateField={updateRealEstateObject}
                                filter={true}
                                errors={errors}
                                control={control}
                                // rules={{ required: t('This is required') }}
                                dropdownOptions={yearOptions}
                            />
                            <CustomObjectInput
                                type="dropdown"
                                classes={['generic-input']}
                                id="last-renovaiton-year"
                                label={t('Poslednje saniranje')}
                                name="other_lastRenovationYear"
                                stateField={
                                    realEstateObject.other.lastRenovationYear
                                }
                                setStateField={updateRealEstateObject}
                                filter={true}
                                errors={errors}
                                control={control}
                                // rules={{ required: t('This is required') }}
                                dropdownOptions={yearOptions}
                            />
                            <CustomObjectInput
                                type="dropdown"
                                classes={['generic-input']}
                                id="last-general-renovation"
                                label={t('Poslednje renoviranje')}
                                name="other_lastGeneralRenovation"
                                stateField={
                                    realEstateObject.other.lastGeneralRenovation
                                }
                                setStateField={updateRealEstateObject}
                                filter={true}
                                errors={errors}
                                control={control}
                                // rules={{ required: t('This is required') }}
                                dropdownOptions={yearOptions}
                            />
                        </div>
                        <div className="section-row">
                            <CustomObjectInput
                                type="dropdown"
                                classes={['generic-input']}
                                id="form-of-ownership-input"
                                label={t('Oblik svojine')}
                                name="other_formOfOwnership"
                                stateField={
                                    realEstateObject.other.formOfOwnership
                                }
                                setStateField={updateRealEstateObject}
                                errors={errors}
                                control={control}
                                // rules={{ required: t('This is required') }}
                                dropdownOptions={formOfOwnershipOptions}
                            />
                            <CustomObjectInput
                                type="dropdown"
                                classes={['generic-input']}
                                id="furnishing-input"
                                label={t('Opremanje')}
                                name="other_furnishing"
                                stateField={realEstateObject.other.furnishing}
                                setStateField={updateRealEstateObject}
                                errors={errors}
                                control={control}
                                // rules={{ required: t('This is required') }}
                                dropdownOptions={furnishingOptions}
                            />
                        </div>
                        <div className="section-row">
                            <CustomObjectInput
                                type="dropdown"
                                classes={['generic-input']}
                                id="situation-assesment-input"
                                label={t('Procena situacije')}
                                name="other_situationAssesment"
                                stateField={
                                    realEstateObject.other.situationAssesment
                                }
                                setStateField={updateRealEstateObject}
                                errors={errors}
                                control={control}
                                // rules={{ required: t('This is required') }}
                                dropdownOptions={situationAssesmentOptions}
                            />
                            <CustomObjectInput
                                type="dropdown"
                                classes={['generic-input']}
                                id="noise-level-input"
                                label={t('Nivo buke')}
                                name="other_noiseLevel"
                                stateField={realEstateObject.other.noiseLevel}
                                setStateField={updateRealEstateObject}
                                errors={errors}
                                control={control}
                                // rules={{ required: t('This is required') }}
                                dropdownOptions={noiseLevelOptions}
                            />
                            <CustomObjectInput
                                type="dropdown"
                                classes={['generic-input']}
                                id="development-input"
                                label={t('Razvoj')}
                                name="other_development"
                                stateField={realEstateObject.other.development}
                                setStateField={updateRealEstateObject}
                                errors={errors}
                                control={control}
                                // rules={{ required: t('This is required') }}
                                dropdownOptions={developmentOptions}
                            />
                        </div>
                        <div className="section-row">
                            <CustomObjectInput
                                type="dropdown"
                                classes={['generic-input']}
                                id="condition-assesment-input"
                                label={t('Procena stanja')}
                                name="other_conditionAssesment"
                                stateField={
                                    realEstateObject.other.conditionAssesment
                                }
                                setStateField={updateRealEstateObject}
                                errors={errors}
                                control={control}
                                // rules={{ required: t('This is required') }}
                                dropdownOptions={conditionAssesmentOptions}
                            />
                            <div className="sonstiges-card">
                                <CustomObjectInput
                                    type="checkbox"
                                    id="key-in-hand"
                                    label={t('Ključ u ruke')}
                                    name="other_keyInHand"
                                    containerClassNames={[
                                        'checkbox-custom-margin',
                                    ]}
                                    classes={['checkbox-middle']}
                                    stateField={
                                        realEstateObject.other.keyInHand
                                    }
                                    setStateField={updateRealEstateObject}
                                    errors={errors}
                                    control={control}
                                    //rules={{ required: t('This is required') }}
                                />
                                <CustomObjectInput
                                    type="checkbox"
                                    id="ready-for-covering"
                                    label={t('Spreman za pokrivanje')}
                                    name="other_readyForCovering"
                                    containerClassNames={[
                                        'checkbox-custom-margin',
                                    ]}
                                    classes={['checkbox-middle']}
                                    stateField={
                                        realEstateObject.other.readyForCovering
                                    }
                                    setStateField={updateRealEstateObject}
                                    errors={errors}
                                    control={control}
                                    //rules={{ required: t('This is required') }}
                                />
                            </div>
                        </div>
                    </Card>
                    <Card
                        className="acquisition-section"
                        header={<CardHeader title={t('Source')} />}
                    >
                        <div className="section-row">
                            {categories.map((category) => {
                                return (
                                    <div
                                        key={category.key}
                                        className="field-radiobutton"
                                    >
                                        <RadioButton
                                            inputId={category.key}
                                            name="acquisition"
                                            value={category}
                                            onChange={(e) => {
                                                setSelectedCategory(e.value);
                                                handleUpdateAcquisition(
                                                    e.value.name,
                                                );
                                            }}
                                            checked={
                                                selectedCategory.key ===
                                                category.key
                                            }
                                            disabled={category.key === 'R'}
                                        />
                                        <label
                                            className="radio-button-label"
                                            htmlFor={category.key}
                                        >
                                            {category.name ===
                                            AcquisitionType.ACQUISITION
                                                ? 'Eigenakquise'
                                                : 'Golden Home Akquise'}
                                        </label>
                                    </div>
                                );
                            })}
                        </div>
                    </Card>
                    <Card
                        className="supervisor-section"
                        header={<CardHeader title={t('Source Link')} />}
                    >
                        <CustomObjectInput
                            type="text"
                            classes={['marking-input', 'generic-input']}
                            id="sourceLink"
                            label={t('Source Link')}
                            name="sourceLink"
                            stateField={realEstateObject.sourceLink}
                            setStateField={updateRealEstateObject}
                            errors={errors}
                            control={control}
                            // rules={{ required: t('This is required') }}
                        />
                    </Card>
                </div>
            </div>
        </>
    );
};

export default ObjectBaseDataForm;

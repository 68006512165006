import * as React from 'react';
import { useApplicationContext } from '../../../application/ApplicationContext';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Fieldset } from 'primereact/fieldset';
import './ForgetPasswordForm.scss';
import { Toast } from 'primereact/toast';
import { useTranslation } from 'react-i18next';

export default function ForgotPasswordForm() {
    const { appState, requestPasswordReset, loginGoBack } =
        useApplicationContext();
    const [username, setUsername] = React.useState(
        appState.loginAsUsername || '',
    );
    const { t } = useTranslation();

    const [errorMessage, seterrorMessage] = React.useState('');

    const EmailRegex =
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    const toast = React.useRef(null);
    React.useEffect(() => {
        toast.current.clear();
        if (errorMessage !== '') {
            toast.current.show({
                severity: 'error',
                detail: `${errorMessage}`,
                life: 3000,
            });
        }
    }, [errorMessage]);

    const submitForm = () => {
        EmailRegex.test(username)
            ? requestPasswordReset(username)
            : seterrorMessage(t('Enter a valid email'));
    };

    return (
        <>
            <Toast ref={toast} position={'top-right'} />
            <div className="forget-password-box">
                <div className="title">{t('Zaboravljena šifra')}</div>
                <div>
                    <Fieldset>
                        <InputText
                            className="input"
                            placeholder={t('Username')}
                            id="in"
                            value={username}
                            autoComplete="off"
                            onChange={(
                                e: React.ChangeEvent<HTMLInputElement>,
                            ) => {
                                setUsername(e.target.value);
                                seterrorMessage('');
                            }}
                        />
                    </Fieldset>
                </div>

                <Button
                    className="send-forgot-password"
                    label={t('Send forgotten password')}
                    onClick={submitForm}
                    onKeyDown={(e: React.KeyboardEvent<HTMLElement>) =>
                        e.key === 'Enter' && submitForm()
                    }
                />
            </div>
        </>
    );
}

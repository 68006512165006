import React from 'react';
import { useApplicationContext } from '../../application/ApplicationContext';
import ResetPasswordForm from '../../components/login/ResetPasswordForm/ResetPasswordForm';
import './ResetPasswordPage.scss';
import { useParams, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const ResetPasswordPage = () => {
    const token = useParams();
    const { t } = useTranslation();

    return (
        <div className="login-page">
            <div id="login-logo-column" className="login-logo-column">
                <div className="login-logo-container">
                    <img
                        src="../../../public/assets/layout/images/golden_home_logo_login.svg"
                        className="login-logo"
                        alt="golden-home-logo"
                    />
                </div>
            </div>
            <div className="password-form-container">
                <ResetPasswordForm token={token} />
                <Link className="close-button" to={`/login`}>
                    {t('Nazad')}
                </Link>
            </div>
        </div>
    );
};

export default ResetPasswordPage;

import React, { useEffect, useState } from 'react';
import { Chart } from 'primereact/chart';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Calendar } from 'primereact/calendar';
import { Card } from 'primereact/card';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import CardHeaderWithBadge from './card/CardHeaderWithBadge';
import CardHeader from './card/CardHeader';
import { ChartDataset, ChartOptions } from 'chart.js';
import ChartLabel from './chartLabel/ChartLabel';
import RainbowIndicator from './RainbowIndicator';
import './Dashboard.scss';
import { useApplicationContext } from '../application/ApplicationContext';
import {
    Overview,
    InteresentData,
    MeineFirstDatesData,
    ObjekteChart,
    InteresentChart,
    PieChart,
    ResponseChart,
    FirstDateResultsData,
    RainbowIndicatorData,
} from '../constants/DashboardConsts';
import { useTranslation } from 'react-i18next';
import PermissionContainer from '../core/auth/PermissionContainer';
import { Permissions } from '../core/auth/Permissions';
import { Util } from '../util/Util';
import { userService } from '../services/UsersService';
import { dashboardService } from '../services/DashboardService';
import moment from 'moment';
import _ from 'lodash';
import { Redirect, useHistory } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { classNames } from 'primereact/utils';

const objekteChartOptions: ChartOptions | any = {
    type: 'bar',
    indexAxis: 'y',
    scales: {
        x: {
            grid: {
                display: false,
            },
        },
        y: {
            grid: {
                display: false,
            },
        },
    },
    plugins: {
        datalabels: {
            display: false,
        },
        labels: {
            display: false,
        },
        legend: {
            display: false,
        },
    },
};

function rowClass(data) {
    return {
        'row-error': parseInt(data.time.split(':')[0]) >= 24,
    };
}

export const Dashboard = () => {
    const [dashboardData, setDashboardData] = useState(undefined);
    const { appState } = useApplicationContext();
    const { t } = useTranslation();
    const [total, setTotal] = useState(0);
    const [firstDateResult, setFirstDateResult] = useState(undefined);
    const [singleDate, setSingleDate] = useState(null);
    const [dateId, setDateId] = useState(null);
    const [date, setDate] = useState([]);
    const [interessentAktiv, setInteressentAktiv] = useState(null);
    const [firstDateAktiv, setFirstDateAktiv] = useState(null);
    const [selectDataWidget, setSelectDataWidget] = useState(null);

    ////// IMPLEMENTATION CLICK CHART

    const pieChartOptions: ChartOptions | any = {
        scales: {
            x: {
                display: false,
            },
            y: {
                display: false,
            },
        },
        type: 'pie',
        onClick: (e, elements) => {
            setFirstDateAktiv(elements[0]?.index);
        },
        plugins: {
            datalabels: {
                display: false,
            },
            labels: {
                display: false,
            },
            legend: {
                display: false,
            },
        },
        responsive: true,
        hover: {
            mode: 'index',
        },
    };

    const interessentChartOptions: ChartOptions | any = {
        type: 'bar',
        indexAxis: 'y',
        scales: {
            x: {
                grid: {
                    display: false,
                },
            },
            y: {
                grid: {
                    display: false,
                },
            },
        },
        onClick: (e, elements) => {
            setInteressentAktiv(elements[0]?.index);
        },
        plugins: {
            datalabels: {
                display: false,
            },
            labels: {
                display: false,
            },
            legend: {
                display: false,
            },
        },
    };

    useEffect(() => {
        switch (firstDateAktiv) {
            case 0:
                history.push({
                    pathname: `/first-dates`,
                    state: { status: 'Neu' },
                });
                break;
            case 1:
                history.push({
                    pathname: `/first-dates`,
                    state: { status: 'Bestätigt' },
                });
                break;
            case 2:
                history.push({
                    pathname: `/first-dates`,
                    state: { status: 'Wiedervorlage' },
                });
                break;
            case 3:
                history.push({
                    pathname: `/first-dates`,
                    state: { status: 'On Hold' },
                });
                break;
            case 4:
                history.push({
                    pathname: `/first-dates`,
                    state: { status: 'Mandat erhalten' },
                });
                break;
            case 5:
                history.push({
                    pathname: `/first-dates`,
                    state: { status: 'Abgesagt' },
                });
                break;
            case 6:
                history.push({
                    pathname: `/first-dates`,
                    state: { status: 'Nicht aufgenommen' },
                });
                break;
            default:
            //
        }
    }, [firstDateAktiv]);

    useEffect(() => {
        switch (interessentAktiv) {
            case 0:
                history.push({
                    pathname: `/inquiries`,
                    state: { status: 'Neu' },
                });
                break;
            case 1:
                history.push({
                    pathname: `/inquiries`,
                    state: { status: 'Besichtigung' },
                });
                break;
            case 2:
                history.push({
                    pathname: `/inquiries`,
                    state: { status: 'Kaufinteresse' },
                });
                break;
            case 3:
                history.push({
                    pathname: `/inquiries`,
                    state: { status: 'Kaufabsicht' },
                });
                break;
            default:
            //
        }
    }, [interessentAktiv]);

    //////////////////////////////

    useEffect(() => {
        let isMounted = true;
        userService.refreshTokenCheck().then(() => {
            fetchData(appState.teamView).then((result) => {
                if (isMounted) {
                    setDashboardData(result);
                }
            });
        });
        return () => {
            isMounted = false;
        };
    }, [appState.teamView]);

    const fetchData = async (params) => {
        const response = await dashboardService.get(params);
        return response.data;
    };

    const [pieChart, setPieChart] = useState<ChartDataset | any>({
        options: {
            scales: {
                yAxes: [
                    {
                        gridLines: {
                            drawOnChartArea: false,
                            drawBorder: false,
                        },
                    },
                ],
                xAxes: [
                    {
                        gridLines: {
                            drawOnChartArea: false,
                            drawBorder: false,
                        },
                    },
                ],
            },
        },
        labels: [t('Neu'), t('Bestätigt'), t('Wiedervorlage'), t('On Hold')],
        datasets: [
            {
                data: [2, 1, 3, 4],
                backgroundColor: ['#828282', '#000000', '#DDDDDD', '#bfa165'],
            },
        ],
    });

    const [objekteChart, setObjekteChart] = useState<ChartDataset | any>({
        options: {
            scales: {
                yAxes: [
                    {
                        gridLines: {
                            drawOnChartArea: false,
                            drawBorder: false,
                        },
                    },
                ],
                xAxes: [
                    {
                        gridLines: {
                            drawOnChartArea: false,
                            drawBorder: false,
                        },
                    },
                ],
            },
        },
        labels: [
            t('Days 01-45'),
            t('Days 46-90'),
            t('Days 91-180'),
            t('Days >180'),
        ],
        datasets: [
            {
                data: [7, 2, 5, 8],
                backgroundColor: ['#bfa165', '#616161', '#828282', '#CCCCCC'],
            },
        ],
    });

    const addMonths = (numOfMonths, date = new Date()) => {
        date.setMonth(date.getMonth() - numOfMonths);

        return date;
    };

    const currentMonth = addMonths(0);
    const nextMonth = addMonths(1);
    const thirdMonth = addMonths(2);
    const longCurrentMonth = currentMonth.toLocaleString('en-us', {
        month: 'short',
    });
    const longNextMonth = nextMonth.toLocaleString('en-us', { month: 'short' });
    const longThirdMonth = thirdMonth.toLocaleString('en-us', {
        month: 'short',
    });

    const [firstDateResultsData, setFirstDateResultsData] = useState({
        labels: [longCurrentMonth, longNextMonth, longThirdMonth],
        datasets: [
            {
                label: t('My First dataset'),
                backgroundColor: '#92969F',
                data: [25, 10, 30],
            },
            {
                label: t('My Second dataset'),
                backgroundColor: '#616161',
                data: [10, 42, 11],
            },
            {
                label: t('My Third dataset'),
                backgroundColor: '#bfa165',
                data: [22, 26, 9],
            },
        ],
    });

    const [interessentChart, setInteressentChart] = useState<
        ChartDataset | any
    >({
        labels: [
            t('Neu'),
            t('Besichtigung'),
            t('Kaufinteresse'),
            t('Kaufabsicht'),
        ],
        datasets: [
            {
                data: [4, 7, 3, 1],
                backgroundColor: ['#bfa165', '#616161', '#828282', '#CCCCCC'],
            },
        ],
        options: {
            scales: {
                xAxes: [
                    {
                        gridLines: {
                            display: false,
                        },
                    },
                ],
                yAxes: [
                    {
                        gridLines: {
                            display: false,
                        },
                    },
                ],
            },
        },
    });

    const [responseTimeChart, setResponseTimeChart] = useState({
        labels: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],

        datasets: [
            {
                label: 'New',
                data: [10, 3, 10, 3, 19, 12],
                backgroundColor: ['rgba(224, 244, 253, 0.904)'],
                borderColor: ['#1993FB'],
                borderWidth: 2,
                fill: true,
            },
        ],
    });

    const responseTimeChartOptions = {
        legend: {
            display: false,
        },
        responsive: true,
        hover: {
            mode: 'index',
        },
        scales: {
            yAxes: {
                ticks: {
                    precision: 0,
                },
            },
        },
        plugins: {
            legend: {
                display: false,
            },
        },
    };

    const firstDateChartOptions: ChartOptions | any = {
        scales: {
            yAxes: {
                gridLines: {
                    drawOnChartArea: false,
                    drawBorder: false,
                },
                ticks: {
                    precision: 0,
                },
            },
            xAxes: {
                gridLines: {
                    drawOnChartArea: false,
                    drawBorder: false,
                },
            },
        },
        type: 'pie',
        plugins: {
            datalabels: {
                display: false,
            },
            labels: {
                display: false,
            },
            legend: {
                display: false,
            },
        },
        responsive: true,
        hover: {
            mode: 'index',
        },
    };

    const [averageResponseTime, setAverageResponseTime] = useState(undefined);
    const [weeklyResponseTime, setWeeklyResponseTime] = useState(undefined);

    const dateTemplate = (e: any) => {
        for (let i = 0; i < date.length; i++) {
            if (
                e.day === date[i].day &&
                e.month + 1 === date[i].month &&
                e.year === date[i].year
            ) {
                return (
                    <div
                        id={date[i].id}
                        key={date[i].id}
                        className={'date-event'}
                    >
                        {e.day}
                    </div>
                );
            }
        }
        return e.day;
    };

    useEffect(() => {
        if (dashboardData === undefined) return;
        if (dashboardData === {}) return;
        if (!dashboardData.inquiries) return;
        if (!dashboardData.firstDates) return;

        const weeklyData = {
            0: [], // monday
            1: [], // tuesday
            2: [], // wednesday
            3: [], // thursday
            4: [], // friday
            5: [], // saturday
            6: [], // sunday
        };

        let responseTimeSum = 0;
        let responseTimeCount = 0;

        for (const inquiry of dashboardData.inquiries) {
            const orderedActivities = _.orderBy(
                inquiry.inquiryActivities,
                (o: any) => {
                    return moment(o.createdAt);
                },
                ['asc'],
            );

            if (orderedActivities.length <= 1) continue;

            const weekday =
                moment(orderedActivities[1].createdAt).isoWeekday() - 1;
            const responseTime = moment(orderedActivities[1].createdAt).diff(
                moment(inquiry.createdAt),
                'milliseconds',
            );

            weeklyData[weekday].push({
                inquiry,
                responseTime,
            });

            responseTimeSum += responseTime;
            responseTimeCount++;
        }

        const totalMinutes = moment
            .duration(responseTimeSum / responseTimeCount)
            .asMinutes();
        const hours = Math.floor(totalMinutes / 60);
        const minutes = Math.round(totalMinutes % 60);

        setAverageResponseTime(
            `${hours.toString().padStart(2, '0')}:${minutes
                .toString()
                .padStart(2, '0')}`,
        );
        setWeeklyResponseTime(weeklyData);

        /**
         * Average Response Time
         *
         * [order activities from oldest to newest]
         * inquiry.inquiryActivities[0].createdAt - inquiry.createdAt
         *
         * [calculate average]
         * sum(inquiry.inquiryActivities[0].createdAt - inquiry.createdAt) / inquiries.length
         */

        const newInteressentChartData = _.cloneDeep(interessentChart);
        const firstDatePieChartData = _.cloneDeep(pieChart);
        const responseTimeChartData = _.cloneDeep(responseTimeChart);
        const meineObjekteDate = _.cloneDeep(objekteChart);
        const meineFirstDatesResults = _.cloneDeep(firstDateResultsData);

        meineObjekteDate.datasets[0].data = [
            dashboardData.properties.filter((x) => {
                const todayDate = new Date();
                const date = new Date(x.createdAt);
                const differenceInTime = todayDate.getTime() - date.getTime();

                const differenceInDays = differenceInTime / (1000 * 3600 * 24);

                if (differenceInDays < 45) {
                    return differenceInDays;
                }
            }).length,

            dashboardData.properties.filter((x) => {
                const todayDate = new Date();
                const date = new Date(x.createdAt);
                const differenceInTime = todayDate.getTime() - date.getTime();

                const differenceInDays = differenceInTime / (1000 * 3600 * 24);
                if (differenceInDays > 45 && differenceInDays < 90) {
                    return differenceInDays;
                }
            }).length,

            dashboardData.properties.filter((x) => {
                const todayDate = new Date();
                const date = new Date(x.createdAt);
                const differenceInTime = todayDate.getTime() - date.getTime();

                const differenceInDays = differenceInTime / (1000 * 3600 * 24);
                if (differenceInDays > 90 && differenceInDays < 180) {
                    return differenceInDays;
                }
            }).length,

            dashboardData.properties.filter((x) => {
                const todayDate = new Date();
                const date = new Date(x.createdAt);
                const differenceInTime = todayDate.getTime() - date.getTime();

                const differenceInDays = differenceInTime / (1000 * 3600 * 24);
                if (differenceInDays > 180) {
                    return differenceInDays;
                }
            }).length,
        ];

        newInteressentChartData.datasets[0].data = [
            dashboardData.inquiries.filter(
                (x) => x.status === 'Neu Bold' || x.status === 'Neu',
            ).length,
            dashboardData.inquiries.filter((x) => x.status === 'Besichtigung')
                .length,
            dashboardData.inquiries.filter((x) => x.status === 'Kaufinteresse')
                .length,
            dashboardData.inquiries.filter((x) => x.status === 'Kaufabsicht')
                .length,
        ];

        firstDatePieChartData.datasets[0].data = [
            dashboardData.firstDates.filter(
                (x) => x.status === 'Neu bold' || x.status === 'Neu',
            ).length,
            dashboardData.firstDates.filter((x) => x.status === 'Bestätigt')
                .length,
            dashboardData.firstDates.filter((x) => x.status === 'Wiedervorlage')
                .length,
            dashboardData.firstDates.filter((x) => x.status === 'On Hold')
                .length,
        ];

        responseTimeChartData.datasets[0].data = [
            weeklyData[0].length,
            weeklyData[1].length,
            weeklyData[2].length,
            weeklyData[3].length,
            weeklyData[4].length,
            weeklyData[5].length,
        ];

        let portfolioVolume = 0;
        dashboardData.properties.map((property) => {
            portfolioVolume += property.price
                ? property.price.netPurchasePrice ?? 0
                : 0;
        });

        meineFirstDatesResults.datasets[0].label = t('Mandat erhalten');
        meineFirstDatesResults.datasets[0].data = [
            dashboardData.firstDateResults.lastMonth.agreementMade,
            dashboardData.firstDateResults.secondLastMonth.agreementMade,
            dashboardData.firstDateResults.thirdLastMonth.agreementMade,
        ];

        meineFirstDatesResults.datasets[1].label = t('Abgesagt');
        meineFirstDatesResults.datasets[1].data = [
            dashboardData.firstDateResults.lastMonth.cancelled,
            dashboardData.firstDateResults.secondLastMonth.cancelled,
            dashboardData.firstDateResults.thirdLastMonth.cancelled,
        ];

        meineFirstDatesResults.datasets[2].label = t('Nicht aufgenommen');
        meineFirstDatesResults.datasets[2].data = [
            dashboardData.firstDateResults.lastMonth.notReachable,
            dashboardData.firstDateResults.secondLastMonth.notReachable,
            dashboardData.firstDateResults.thirdLastMonth.notReachable,
        ];

        const eventArray = dashboardData.calendarEvents.map((event) => {
            const dateObj = new Date(event.startDateAndTime);
            const month = dateObj.getUTCMonth() + 1;
            const day = dateObj.getUTCDate();
            const year = dateObj.getUTCFullYear();
            const time = dateObj.getHours();
            return {
                day: day,
                month: month,
                year: year,
                id: event.id,
            };
        });

        setDate([...date, ...eventArray]);
        setTotal(portfolioVolume);
        setResponseTimeChart(responseTimeChartData);
        setInteressentChart(newInteressentChartData);
        setPieChart(firstDatePieChartData);
        setObjekteChart(meineObjekteDate);
        setFirstDateResult({ ...dashboardData.firstDateResults.currentMonth });
        setFirstDateResultsData(meineFirstDatesResults);
    }, [dashboardData]);

    useEffect(() => {
        setSelectDataWidget(singleDate);
        const calendarDate = new Date(singleDate);
        const convertDate = new Date(calendarDate);

        const convertMonth = calendarDate.getMonth() + 1;
        const convertDay = calendarDate.getDate();
        const convertYear = calendarDate.getUTCFullYear();

        const element = date.find(({ day, month, year, id }) => {
            if (
                day === convertDay &&
                month === convertMonth &&
                year === convertYear
            ) {
                return id;
            }
        });
        const id = element ? element.id : null;
        setDateId(id);
    }, [singleDate]);

    ///// IMPLEMENTATION LINK IN TABLE

    const history = useHistory();

    //// Interesent

    const [interesent, setInteresent] = useState({ inquiryId: null });

    useEffect(() => {
        if (interesent.inquiryId === null) return;
        history.push({
            pathname: `/inquiries/${interesent?.inquiryId}`,
        });
    }, [interesent]);

    ////// First date

    const [firstDateSelection, setFirstDateSelection] = useState({
        firstDateId: null,
    });

    useEffect(() => {
        if (firstDateSelection.firstDateId === null) return;
        history.push({
            pathname: `/first-dates/${firstDateSelection.firstDateId}`,
        });
    }, [firstDateSelection]);

    if (
        appState.authInProgress ||
        dashboardData === undefined ||
        dashboardData === {} ||
        !dashboardData.inquiries ||
        !dashboardData.firstDates
    ) {
        return (
            <div className="center-spinner">
                <ProgressSpinner strokeWidth="5" animationDuration="1.5s" />
            </div>
        );
    } else {
        return (
            <div className="layout-dashboard">
                <div className="content-wrapper">
                    <div className="column">
                        <div className="column-item">
                            {/*<PermissionContainer hasAnyPermission={[Permissions.USERS_READ, Permissions.USERS_EDIT]} currentUser={currentUserTest}>*/}
                            {/*    <div>Testing permissions</div>*/}
                            {/*</PermissionContainer>*/}
                            <Card
                                // <CardHeader
                                //     title={t('Meine interessent')}
                                //     status={t('NEU')}
                                // />
                                header={
                                    <div className="header-widget">
                                        <div className="header-widget-first">
                                            <CardHeaderWithBadge
                                                title={t('Meine interessent')}
                                                value={[
                                                    dashboardData.inquiries.filter(
                                                        (inquiry) => {
                                                            return (
                                                                inquiry
                                                                    .inquiryActivities
                                                                    .length <= 1
                                                            );
                                                        },
                                                    ).length,
                                                ]}
                                                severity="info"
                                                size="xlarge"
                                                status={t('NEU')}
                                            />
                                        </div>
                                        <div className="header-widget-second">
                                            <CardHeaderWithBadge
                                                title={''}
                                                value={
                                                    dashboardData.inquiries.filter(
                                                        (inquiry) => {
                                                            const start =
                                                                moment(
                                                                    inquiry.createdAt,
                                                                );
                                                            const end =
                                                                moment();
                                                            const totalMinutes =
                                                                moment
                                                                    .duration(
                                                                        end.diff(
                                                                            start,
                                                                        ),
                                                                    )
                                                                    .asMinutes();
                                                            const hours =
                                                                Math.floor(
                                                                    totalMinutes /
                                                                        60,
                                                                );

                                                            return (
                                                                inquiry
                                                                    .inquiryActivities
                                                                    .length <=
                                                                    1 &&
                                                                hours >= 24
                                                            );
                                                        },
                                                    ).length
                                                }
                                                severity="danger"
                                                size="xlarge"
                                                status={''}
                                            />
                                        </div>
                                    </div>
                                }
                                className="card-content custom-padding"
                            >
                                <DataTable
                                    selectionMode="single"
                                    selection={interesent}
                                    onSelectionChange={(e) =>
                                        setInteresent(e.value)
                                    }
                                    dataKey="id"
                                    value={_.orderBy(
                                        dashboardData.inquiries
                                            .filter((inquiry) => {
                                                return (
                                                    inquiry.status === 'Neu' ||
                                                    inquiry.status ===
                                                        'Neu Bold'
                                                );
                                            })
                                            .map((inquiry) => {
                                                const start = moment(
                                                    inquiry.createdAt,
                                                );
                                                const end = moment();
                                                const totalMinutes = moment
                                                    .duration(end.diff(start))
                                                    .asMinutes();
                                                const hours = Math.floor(
                                                    totalMinutes / 60,
                                                );
                                                const minutes = Math.round(
                                                    totalMinutes % 60,
                                                );

                                                return {
                                                    id: inquiry.contact?.id,
                                                    name: `${inquiry.contact?.firstName} ${inquiry.contact?.lastName}`,
                                                    time: `${hours
                                                        .toString()
                                                        .padStart(
                                                            2,
                                                            '0',
                                                        )}:${minutes
                                                        .toString()
                                                        .padStart(2, '0')}`,
                                                    createdAt:
                                                        inquiry.createdAt,
                                                    inquiryId: inquiry.id,
                                                };
                                            }),
                                        (o: any) => {
                                            return moment(o.createdAt);
                                        },
                                        ['asc'],
                                    ).slice(0, 4)}
                                    className="p-datatable-customers dashboard-datatable custom-padding"
                                    rowClassName={rowClass}
                                >
                                    {/* <Link to="/contacts" className="link-i"> */}

                                    <Column field="id" header="ID" />
                                    <Column
                                        className={
                                            'meine-first-dates-name-column'
                                        }
                                        field="name"
                                        header={t('Name')}
                                    />
                                    <Column
                                        field="time"
                                        header={t('Wartezeit')}
                                    />
                                    {/* </Link> */}
                                </DataTable>
                                <div className="button-container">
                                    <Link
                                        to="/inquiries"
                                        className="btn-interessent"
                                    >
                                        {`${t('See all')}...`}
                                    </Link>
                                </div>
                            </Card>
                        </div>
                        <div className="column-item">
                            <Card
                                className="card-content custom-padding"
                                header={
                                    <CardHeaderWithBadge
                                        title={t('Meine First Dates')}
                                        value={
                                            dashboardData.firstDates
                                                .filter((firstDate) => {
                                                    if (
                                                        firstDate.status ===
                                                            'Neu' ||
                                                        firstDate.status ===
                                                            'Neu bold'
                                                    ) {
                                                        return firstDate;
                                                    }
                                                })
                                                .map((firstDate) => {
                                                    const start = moment(
                                                        firstDate.createdAt,
                                                    );
                                                    const end = moment();
                                                    const totalMinutes = moment
                                                        .duration(
                                                            end.diff(start),
                                                        )
                                                        .asMinutes();
                                                    const hours = Math.floor(
                                                        totalMinutes / 60,
                                                    );
                                                    const minutes = Math.round(
                                                        totalMinutes % 60,
                                                    );

                                                    return {
                                                        id: firstDate.seller
                                                            ?.id,
                                                        name: `${firstDate.seller?.firstName} ${firstDate.seller?.lastName}`,
                                                        time: `${hours}:${minutes}`,
                                                        createdAt:
                                                            firstDate.createdAt,
                                                    };
                                                }).length
                                        }
                                        severity="danger"
                                        size="xlarge"
                                        status={t('NEU')}
                                        className="custom-padding"
                                    />
                                }
                            >
                                <DataTable
                                    selectionMode="single"
                                    selection={firstDateSelection}
                                    onSelectionChange={(e) =>
                                        setFirstDateSelection(e.value)
                                    }
                                    value={_.orderBy(
                                        dashboardData.firstDates
                                            .filter((firstDate) => {
                                                if (
                                                    firstDate.status ===
                                                        'Neu' ||
                                                    firstDate.status ===
                                                        'Neu bold'
                                                ) {
                                                    return firstDate;
                                                }
                                            })
                                            .map((firstDate) => {
                                                const start = moment(
                                                    firstDate.createdAt,
                                                );
                                                const end = moment();
                                                const totalMinutes = moment
                                                    .duration(end.diff(start))
                                                    .asMinutes();
                                                const hours = Math.floor(
                                                    totalMinutes / 60,
                                                );
                                                const minutes = Math.round(
                                                    totalMinutes % 60,
                                                );
                                                return {
                                                    id: firstDate?.seller?.id,
                                                    name: `${firstDate.seller?.firstName} ${firstDate.seller?.lastName}`,
                                                    time: `${hours
                                                        .toString()
                                                        .padStart(
                                                            2,
                                                            '0',
                                                        )}:${minutes
                                                        .toString()
                                                        .padStart(2, '0')}`,
                                                    createdAt:
                                                        firstDate.createdAt,
                                                    firstDateId: firstDate.id,
                                                };
                                            }),
                                        (o: any) => {
                                            return moment(o.createdAt);
                                        },
                                        ['asc'],
                                    ).slice(0, 4)}
                                    className="p-datatable-customers dashboard-datatable custom-padding"
                                    dataKey="id"
                                    rowClassName={rowClass}
                                >
                                    <Column field="id" header={t('ID')} />
                                    <Column
                                        className={
                                            'meine-first-dates-name-column'
                                        }
                                        field="name"
                                        header={t('Name')}
                                    />
                                    <Column field="number" header="" />
                                    <Column
                                        className={
                                            'meine-first-dates-time-column'
                                        }
                                        field="time"
                                        header={t('Wartezeit')}
                                    />
                                </DataTable>
                                <div className="button-container">
                                    <Link
                                        to="/first-dates"
                                        className="btn-interessent"
                                    >
                                        {`${t('See all')}...`}
                                    </Link>
                                </div>
                            </Card>
                        </div>
                        <div className="column-item">
                            <Card
                                className="card-content"
                                header={
                                    <CardHeaderWithBadge
                                        title={t('Meine Objekte')}
                                        status={
                                            t('Total portfolio volume: ') +
                                            ': ' +
                                            total
                                        }
                                        severity="info"
                                        value={dashboardData?.properties.length}
                                        size="xlarge"
                                    />
                                }
                            >
                                <div className="dashboard-meine-objekte ">
                                    <Chart
                                        type="bar"
                                        id="interessent-chart"
                                        data={objekteChart}
                                        options={objekteChartOptions}
                                    />
                                </div>
                            </Card>
                        </div>
                    </div>
                    <div className="column">
                        <div className="column-item">
                            <Card
                                className="card-content"
                                header={
                                    <CardHeaderWithBadge
                                        title={t('Meine interessent')}
                                        value={interessentChart.datasets[0].data.reduce(
                                            (partialSum, a) => partialSum + a,
                                            0,
                                        )}
                                        severity="info"
                                        size="xlarge"
                                        status={t('AKTIV')}
                                    />
                                }
                            >
                                <div className="dashboard-meine-objekte ">
                                    <Chart
                                        type="bar"
                                        id="interessent-chart"
                                        data={interessentChart}
                                        options={interessentChartOptions}
                                    />
                                </div>
                            </Card>
                        </div>
                        <div className="column-item">
                            <Card
                                className="card-content"
                                header={
                                    <CardHeaderWithBadge
                                        title={t('Meine First Dates')}
                                        status={t('AKTIV')}
                                        severity="info"
                                        value={pieChart.datasets[0].data.reduce(
                                            (partialSum, a) => partialSum + a,
                                            0,
                                        )}
                                        size="xlarge"
                                    />
                                }
                            >
                                <div
                                    className="p-grid"
                                    style={{ fontSize: '1rem' }}
                                >
                                    <div className="chart-pie-left-side">
                                        <div
                                            onClick={() => setFirstDateAktiv(0)}
                                            style={{ cursor: 'pointer' }}
                                        >
                                            <ChartLabel
                                                color="#828282"
                                                name={t('Neu')}
                                                value={
                                                    pieChart.datasets[0].data[0]
                                                }
                                            />
                                        </div>
                                        <div
                                            onClick={() => setFirstDateAktiv(1)}
                                            style={{ cursor: 'pointer' }}
                                        >
                                            <ChartLabel
                                                color="#000000"
                                                name={t('Bestätigt')}
                                                value={
                                                    pieChart.datasets[0].data[1]
                                                }
                                            />
                                        </div>
                                        <div
                                            onClick={() => setFirstDateAktiv(2)}
                                            style={{ cursor: 'pointer' }}
                                        >
                                            <ChartLabel
                                                color="#DDDDDD"
                                                name={t('Wiedervorlage')}
                                                value={
                                                    pieChart.datasets[0].data[2]
                                                }
                                            />
                                        </div>
                                        <div
                                            onClick={() => setFirstDateAktiv(3)}
                                            style={{ cursor: 'pointer' }}
                                        >
                                            <ChartLabel
                                                color="#bfa165"
                                                name={t('On Hold')}
                                                value={
                                                    pieChart.datasets[0].data[3]
                                                }
                                            />
                                        </div>
                                    </div>
                                    <div className="p-col-6">
                                        <div className="dashboard-chart-pie">
                                            <Chart
                                                type="pie"
                                                id="revenue-chart"
                                                data={pieChart}
                                                options={pieChartOptions}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </Card>
                        </div>
                        <div className="column-item">
                            <Card
                                className="card-content"
                                header={
                                    <CardHeader
                                        title={t('Meine Servise Termine')}
                                    />
                                }
                            >
                                <p className="bigger-font">{t('This month')}</p>
                                <RainbowIndicator
                                    min={0}
                                    show={false}
                                    max={dashboardData.properties.length}
                                    value={
                                        dashboardData.serviceAppointmentsThisMonth
                                    }
                                />
                                <h5>{t('Meine Preisduktion')}</h5>
                                <p className="bigger-font">{t('This month')}</p>
                                <p className="bigger-font">
                                    {t(
                                        'Target 5% from my real estate portfolio value',
                                    )}
                                </p>
                                <RainbowIndicator
                                    min={0}
                                    show={true}
                                    max={Math.floor(
                                        dashboardData.portfolioPriceMonthStart *
                                            0.05,
                                    )}
                                    secondValue={
                                        dashboardData.portfolioPriceDifference
                                    }
                                />
                            </Card>
                        </div>
                    </div>
                    <div className="column">
                        <div className="column-item">
                            <Card
                                className="card-content"
                                header={
                                    <div className="response-time">
                                        <CardHeaderWithBadge
                                            title={t('Meine interessent')}
                                            value={
                                                /^\d{2}:\d{2}$/.test(
                                                    averageResponseTime,
                                                )
                                                    ? averageResponseTime
                                                    : '00:00'
                                            }
                                            severity="danger"
                                            size="xlarge"
                                            status={t('RESPONSE TIME')}
                                        />
                                    </div>
                                }
                            >
                                <div className="p-grid">
                                    <div
                                        className="p-col-8"
                                        style={{ textAlign: 'left' }}
                                    >
                                        <div>
                                            <p>
                                                {t('Untouched to in Progress')}
                                            </p>
                                        </div>
                                        <div>
                                            <p>{t('Average Mon-Sut')}</p>
                                        </div>
                                    </div>
                                    {/* <div
                                        className="p-col-4"
                                        style={{
                                            paddingTop: '25px',
                                            color: 'red',
                                            fontWeight: 'bold',
                                            fontSize: '1.4rem',
                                        }}
                                    >
                                        {averageResponseTime}
                                    </div> */}
                                </div>
                                <div className="p-grid meine-interessent-chart">
                                    <div className="overview-chart">
                                        <Chart
                                            type="line"
                                            data={responseTimeChart}
                                            options={responseTimeChartOptions}
                                            id="order-chart"
                                        />
                                    </div>
                                </div>
                            </Card>
                        </div>
                        <div className="column-item">
                            <Card
                                className="card-content"
                                header={
                                    <CardHeader
                                        title={t('Meine First Datest')}
                                        status={t('RESULTS')}
                                    />
                                }
                            >
                                <div className="p-grid">
                                    <div className="p-col-6 bold">
                                        {t('This Months')}
                                    </div>
                                    <div className="p-col-6 bold">
                                        {t('Last 3 Months')}
                                    </div>
                                </div>
                                <div className="p-grid">
                                    <div className="p-col-6">
                                        <div
                                            onClick={() => setFirstDateAktiv(4)}
                                            style={{ cursor: 'pointer' }}
                                        >
                                            <ChartLabel
                                                name={t('Mandat erhalten')}
                                                value={
                                                    firstDateResult?.agreementMade
                                                }
                                            />
                                        </div>
                                        <div
                                            onClick={() => setFirstDateAktiv(5)}
                                            style={{ cursor: 'pointer' }}
                                        >
                                            <ChartLabel
                                                name={t('Abgesagt')}
                                                value={
                                                    firstDateResult?.cancelled
                                                }
                                            />
                                        </div>
                                        <div
                                            onClick={() => setFirstDateAktiv(6)}
                                            style={{ cursor: 'pointer' }}
                                        >
                                            <ChartLabel
                                                name={t('Nicht aufgenommen')}
                                                value={
                                                    firstDateResult?.notReachable
                                                }
                                            />
                                        </div>
                                    </div>
                                    <div className="p-col-6">
                                        <div className="dashboard-chart-months">
                                            <Chart
                                                type="bar"
                                                data={firstDateResultsData}
                                                options={firstDateChartOptions}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </Card>
                        </div>
                        <div className="column-item">
                            <Card className="card-content no-padding dashboard-calendar-card">
                                <Calendar
                                    style={{ border: 0 }}
                                    className="dashboard-calendar"
                                    dateFormat="DD.MM.YYYY"
                                    dateTemplate={(e) => dateTemplate(e)}
                                    onChange={(e) => setSingleDate(e.value)}
                                    inline
                                />
                            </Card>
                        </div>
                    </div>
                </div>
                {dateId !== null || selectDataWidget !== null ? (
                    <Redirect
                        to={{
                            pathname: '/calendar',
                            state: {
                                dataId: dateId,
                                selectDataWidget: selectDataWidget,
                            },
                        }}
                    />
                ) : null}
            </div>
        );
    }
};

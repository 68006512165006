import React from 'react';
import { useTranslation } from 'react-i18next';

export const EmptyPage = () => {
    const { t } = useTranslation();

    return (
        <div className="p-grid">
            <div className="p-col-12">
                <div className="card">
                    <h5>{t('Empty Page')}</h5>
                    <p>{t('Use this page to start from scratch and place your custom content.')}</p>
                </div>
            </div>
        </div>
    );
}

import { BaseService } from './BaseService';
import { axiosDefaults } from './AxiosDefaults';
import { AxiosResponse } from 'axios';

export class ExportService extends BaseService {
    protected getUrlPath(): string {
        return '/exports';
    }

    public async getFailedExports(): Promise<AxiosResponse> {
        return this.axiosInstance.get(
            `${axiosDefaults.baseURL}${this.getUrlPath()}/failed`
        );
    }

    public async getExportsForProperty(propertyId: number): Promise<AxiosResponse> {
        return this.axiosInstance.get(
            `${axiosDefaults.baseURL}${this.getUrlPath()}/property/${propertyId}`,
        );
    }

    public async exportOpenimmo(id: number): Promise<AxiosResponse> {
        return this.axiosInstance.post(
            `${axiosDefaults.baseURL}${this.getUrlPath()}/openimmo/${id}`,
        );
    }

    public async exportImmoscout(id: number): Promise<AxiosResponse> {
        return this.axiosInstance.post(
            `${axiosDefaults.baseURL}${this.getUrlPath()}/immoscout/${id}`,
        );
    }

    public async exportSite(
        platform: string,
        id: number,
    ): Promise<AxiosResponse> {
        return this.axiosInstance.post(
            `${
                axiosDefaults.baseURL
            }${this.getUrlPath()}/${platform}/${id}/`,
        );
    }
}

export const exportService = new ExportService();

import * as React from 'react';
import { ProgressSpinner } from 'primereact/progressspinner';
import LoginView from './LoginView';
import {
    BrowserRouter,
    Redirect,
    Route,
    Switch,
    useHistory,
} from 'react-router-dom';
import App from '../App';
import {
    ApplicationProvider,
    getApplicationState,
    useApplicationContext,
} from './ApplicationContext';
import GuestRoute from './router/GuestRoute';
import ResetPasswordPage from '../pages/ResetPasswordPage/ResetPasswordPage';
import { metaService } from '../services/MetaService';
import { ApplicationMetadata } from '../models/Metadata';

export default function Application(props) {
    const { appState, isAuthenticatedCheck, getApplicationMetadata } =
        useApplicationContext();
    const history = useHistory();
    React.useEffect(() => {
        isAuthenticatedCheck();
        getApplicationMetadata();
    }, []);

    const showLogin =
        appState.authenticated === false ||
        (appState.tokenExpiration && appState.tokenExpiration < Date.now());

    return (
        <BrowserRouter>
            <Switch>
                {appState.authenticated === null ? null : (
                    <>
                        {appState.authenticated && <App />}
                        <GuestRoute
                            key={`router-reset`}
                            path={'/reset-password/*'}
                            component={ResetPasswordPage}
                        />
                        <GuestRoute
                            key={`router-login`}
                            path={'*'}
                            component={LoginView}
                        />{' '}
                    </>
                )}
            </Switch>
        </BrowserRouter>
    );
}

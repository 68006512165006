import { Config } from '../Config';
import { CrudService } from './CrudService';
import { AxiosResponse } from 'axios';
import { CreateQueryParams } from '@nestjsx/crud-request';
import { axiosDefaults } from './AxiosDefaults';
import { ReportDto } from '../models/Reports';

export class ReportsService extends CrudService<ReportDto, 'Reports'> {
    protected getUrlPath(): string {
        return '/reports';
    }

    public async getReports(
        params?: CreateQueryParams,
    ): Promise<AxiosResponse<ReportDto>> {
        return this.axiosInstance.get(axiosDefaults.baseURL + '/reports');
    }
}

export const reportsService = new ReportsService();

export interface ICalendarFormModal {
    title: string;
    activityType: string;
    taskValidFrom: string;
    taskExpiryDate: string;
    id: number;
    Object: number;
    contact: number;
    info: string;
}

export interface IEditCalendarFormModal {
    title: string;
    activityType: string;
    editValidFrom: string;
    editExpiryDate: string;
    id: number;
    Object: number;
    contact: number;
    info: string;
}

export enum calendarEventStatus {
    FIRST_DATE = 'First date',
    INQUIRY = 'Inquiry',
    PRIVATE = 'Privat',
    Interessent = 'Interessent',
    Besichtigung = 'Besichtigung',
    Zweitbesichtigung = 'Zweitbesichtigung',
    Notar = 'Notar',
    Servicetermin = 'Servicetermin',
    Servicetelefonat = 'Servicetelefonat',
    Buro = 'Büro',
    Fototermin = 'Fototermin',
    Sonstige = 'Sonstige',
    Nachfassen = 'Nachfassen',
    Telefonat = 'Telefonat',
}

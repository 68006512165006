import { AxiosResponse } from 'axios';
import { BaseService } from './BaseService';
import { LookupDataRow } from './LookupDataRow';
import { CrudServiceConfig } from './CrudServiceConfig';
import { Util } from '../util/Util';
import { RequestQueryBuilder, CreateQueryParams } from '@nestjsx/crud-request';
import { ServiceUtil } from './ServiceUtil';
import { getApplicationState } from '../application/ApplicationContext';

export abstract class CrudService<T = any, LDK = any> extends BaseService {
    private config: CrudServiceConfig<LDK> = null;

    constructor(config?: CrudServiceConfig<LDK>) {
        super();
        this.config = Util.assign({ cacheLookupData: false }, config);
        if (this.config.cacheLookupData) {
            this.axiosInstance.interceptors.response.use(
                (response) => {
                    if (
                        response.config.method !== 'get' &&
                        (response.status === 200 || response.status === 201)
                    ) {
                        setTimeout(() => {
                            this.fetchLookupData();
                            this.additionalFetchLookupData();
                        }, 1);
                    }
                    return response;
                },
                (error) => {
                    console.error('Interceptor error', error);
                    return Promise.reject(error);
                },
            );
            BaseService.lookupDataPromises.push(this.fetchLookupData);
        }
    }

    public getMany(params?: CreateQueryParams): Promise<AxiosResponse<T[]>> {
        const queryString = RequestQueryBuilder.create(params).query();

        return this.axiosInstance.get(this.getUrlPath() + '?' + `teamView=${params}`);
    }

    public getOne(id: string | number): Promise<AxiosResponse<T>> {
        return this.axiosInstance.get(this.getUrlPath() + '/' + id, {});
    }

    public createOne(item: T): Promise<AxiosResponse<T>> {
        this.onCreateBefore(item);
        return this.axiosInstance.post(this.getUrlPath(), item);
    }

    public createMany(items: T[]): Promise<AxiosResponse<T[]>> {
        items.map((item: T) => this.onCreateBefore(item));
        return this.axiosInstance.post(this.getUrlPath(), items);
    }

    public updateOne(id: string, item: T): Promise<AxiosResponse<T>> {
        this.onUpdateBefore(item);
        return this.axiosInstance.put(this.getUrlPath() + '/' + id, item);
    }

    public replaceOne(id: string | number, item: T): Promise<AxiosResponse<T>> {
        this.onReplaceBefore(item);
        return this.axiosInstance.patch(this.getUrlPath() + '/' + id, item);
    }

    public deleteOne(id: string | number): Promise<AxiosResponse<T>> {
        return this.axiosInstance.delete(this.getUrlPath() + '/' + id);
    }

    public getLookupData = (key: LDK): LookupDataRow[] =>
        this.lookupDataMap[key as any] || [];

    public fetchLookupData = (): Promise<void> => {
        return new Promise((resolve: () => void, reject: () => void) => {
            const {
                cacheLookupData,
                lookupDataValueField: lookupDataIdField,
                lookupDataLabelField: lookupDataTextField,
                lookupDataKey,
                lookupDataAdditionalFields,
                userTypesScope,
                filterByCompanyId,
            } = this.config;
            const applicationState = getApplicationState();
            // Do nothing if lookup table data is not cached
            if (!cacheLookupData) {
                resolve();
            }
            // Do not fetch lookup table data for user type that is not necessary
            // if (userTypesScope !== "any" && userTypesScope !== applicationState.userType) {
            //     resolve();
            // }
            // Filter data by the companyId that is equal to the current user
            const filter = null;
            // if (filterByCompanyId) {
            //     filter = {
            //         field: "companyId",
            //         operator: "$eq",
            //         value: applicationState.userId
            //     };
            // }
            // Create query
            const queryString = RequestQueryBuilder.create({
                fields: [lookupDataIdField, lookupDataTextField].concat(
                    lookupDataAdditionalFields
                        ? lookupDataAdditionalFields
                        : [],
                ),
                sort: [{ field: lookupDataTextField, order: 'ASC' }],
                filter,
                resetCache: true,
            }).query();
            // Get the data
            this.getMany(queryString as any).then((response: AxiosResponse) => {
                if (ServiceUtil.isSuccessResponse(response)) {
                    const lookupDataValue: LookupDataRow[] = Util.map(
                        response.data,
                        (dataRow) => {
                            const additionalFields: any = {};
                            if (lookupDataAdditionalFields) {
                                for (const field of lookupDataAdditionalFields) {
                                    additionalFields[field] = dataRow[field];
                                }
                            }
                            return {
                                value: dataRow[lookupDataIdField],
                                label: dataRow[lookupDataTextField],
                                data: additionalFields,
                            };
                        },
                    );
                    this.lookupDataMap[lookupDataKey as any] = lookupDataValue;
                    resolve();
                } else {
                    reject();
                }
            });
        });
    };

    protected abstract getUrlPath(): string;

    protected onCreateBefore(item: T): void {
        // Do nothing by default
    }

    protected onUpdateBefore(item: T): void {
        // Do nothing by default
    }

    protected onReplaceBefore(item: T): void {
        // Do nothing by default
    }

    protected additionalFetchLookupData(): void {
        // Do nothing by default
    }
}

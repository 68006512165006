import React, { useState } from 'react';
import { filesService } from '../../../services/FilesService';
import './LoadingImage.scss';
import documentPlaceholder from '../../../../public/assets/layout/images/document_placeholder.png';
import { ProgressSpinner } from 'primereact/progressspinner';

const LoadingImage = (props) => {
    const [loading, setLoading] = useState(true);

    const onLoad = () => {
        setLoading(false);
    };

    return (
        <>
            {loading && (
                <div className="img-card">
                    <ProgressSpinner strokeWidth="5" animationDuration="1.5s" />
                </div>
            )}
            {props.card.file.mimeType.startsWith('image') ? (
                <img
                    style={{ display: !loading ? 'block' : 'none' }}
                    src={filesService.getFilePath(props.card.file.id)}
                    alt={props.card.file.name}
                    onLoad={onLoad}
                />
            ) : (
                <img
                    style={{ display: !loading ? 'block' : 'none' }}
                    className="contain"
                    src={documentPlaceholder}
                    alt={props.card.file.name}
                    onLoad={onLoad}
                />
            )}
        </>
    );
};

export default LoadingImage;

import React from 'react';
import './UserActivitiesModal.scss';
import { Dialog } from 'primereact/dialog';
import { useTranslation } from 'react-i18next';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import dateFormat from 'dateformat';

const UserActivitiesModal = ({ showModal, toast, singleUser }) => {
    const { t } = useTranslation();

    return (
        <>
            <Dialog
                header={t('User Activities')}
                visible={true}
                style={{ width: '50vw' }}
                className="user-modal"
                blockScroll
                draggable={false}
                onHide={showModal}
            >
                <DataTable
                    columnResizeMode="fit"
                    value={singleUser.userActivities}
                    sortField="createdAt"
                    sortOrder={-1}
                    className="p-datatable-lg"
                >
                    <Column
                        field="createdAt"
                        header={t('Date')}
                        sortable
                        body={(rowData) => {
                            return dateFormat(
                                rowData.createdAt,
                                'dd/mm/yy, HH:MM',
                            );
                        }}
                    />
                    <Column field="type" header={t('Akcija')} />
                    <Column
                        field="comment"
                        header={t('Details')}
                        style={{ whiteSpace: 'pre-wrap' }}
                    />
                </DataTable>
            </Dialog>
        </>
    );
};

export default UserActivitiesModal;

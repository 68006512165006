import * as _ from 'lodash';
import { Messages } from 'primereact/messages';
import { LookupDataRow } from '../services/LookupDataRow';
import XDate from 'xdate';
import { Config } from '../Config';

export class Util {
    public static Messages: Messages = null;

    public static cloneDeep = _.cloneDeep;
    public static isEqual = _.isEqual;
    public static map = _.map;
    public static reduce = _.reduce;
    public static assign = _.assign;
    public static isArray = _.isArray;
    public static isString = _.isString;
    public static isFunction = _.isFunction;
    public static range = _.range;

    public static getAppContainerNode(): Element {
        const nodes = document.getElementsByClassName(Config.APP_CLASS_NAME);
        if (!nodes || nodes.length < 1) {
            return null;
        }
        return nodes[0];
    }

    public static dataArrayToMap(arr: any[]): any {
        return Util.reduce(
            arr,
            (acc: any, row: any) => {
                acc[row.id] = row;
                return acc;
            },
            {},
        );
    }

    public static lookupTableAraayToMap(arr: LookupDataRow[]): {
        [key: number]: LookupDataRow;
    } {
        return Util.reduce(
            arr,
            (acc: any, row: LookupDataRow) => {
                acc[row.value] = row;
                return acc;
            },
            {},
        );
    }

    public static showSuccessMessage(summary: string, detail: string): void {
        Util.Messages.show({
            severity: 'success',
            closable: false,
            summary,
            detail,
        });
    }

    public static showErrorMessage(summary: string, detail: string): void {
        Util.Messages.show({
            severity: 'error',
            closable: false,
            summary,
            detail,
        });
    }

    public static arrToCsv(arr: any): string {
        if (!arr || arr.length === 0) {
            return '';
        }
        const res = Util.reduce(
            arr,
            (acc: string, elem: any) => {
                acc += elem + ',';
                return acc;
            },
            '',
        );
        return res.substring(0, res.length - 1);
    }

    public static csvToArr(str: string): any[] {
        if (!str) {
            return [];
        }
        return str.split(',');
    }

    public static timestampToString(t: any, showTime: boolean = true): string {
        if (!t) {
            return '';
        }
        try {
            const d = new Date(Util.isString(t) ? parseInt(t, 10) : t);
            if (showTime) {
                const day = d.getDate();
                const month = d.getMonth() + 1;
                const hours = d.getHours();
                const minutes = d.getMinutes();
                return `${day < 10 ? '0' + day : day}-${
                    month < 10 ? '0' + month : month
                }-${d.getFullYear()} ${hours < 10 ? '0' + hours : hours}:${
                    minutes < 10 ? '0' + minutes : minutes
                }`;
            } else {
                const day = d.getDate();
                const month = d.getMonth() + 1;
                return `${day < 10 ? '0' + day : day}-${
                    month < 10 ? '0' + month : month
                }-${d.getFullYear()}`;
            }
        } catch (err) {
            console.error(err);
            return '';
        }
    }

    public static dateToString(d: Date, showTime: boolean = true): string {
        if (!d) {
            return '';
        }
        return Util.timestampToString(d.getTime(), showTime);
    }

    public static showArray(arr: string[]): string {
        if (!arr || arr.length === 0) {
            return '';
        }
        let s = arr[0];
        for (let i = 1, l = arr.length; i < l; i++) {
            s += ', ' + arr[i];
        }
        return s;
    }

    public static showSmsRule(smsRule: string): string {
        if (!smsRule) {
            return '';
        }
        const arr = [];
        for (let i = 0, l = smsRule.length; i < l; i++) {
            switch (smsRule[i]) {
                case '5':
                    arr.push('UNDELIVERED');
                    break;
                case '6':
                    arr.push('EXPIRED');
                    break;
                case '8':
                    arr.push('BLOCKED');
                    break;
            }
        }
        return Util.showArray(arr);
    }

    public static roundTwoDecimals(num: number) {
        return (Math.round(num * 100) / 100).toFixed(2);
    }

    public static addThousandSeparator(num: number, separator: string = ' ') {
        const t = '' + num;
        const numberOfInserts = Math.floor((t.length - 1) / 3);
        if (numberOfInserts === 0) {
            return t;
        }
        let res = '';
        let cnt = 0;
        for (let i = t.length - 1; i >= 0; i--) {
            res = t[i] + res;
            cnt++;
            if (cnt % 3 === 0) {
                res = separator + res;
            }
        }
        return res;
    }

    public static getFirstDayOfCurrentMonth(): Date {
        const now = new Date();
        return new Date(now.getFullYear(), now.getMonth(), 1);
    }

    public static getLastDayOfCurrentMonth(): Date {
        const now = new Date();
        return new Date(now.getFullYear(), now.getMonth() + 1, 0);
    }

    public static getEndOfCurrentDay(): Date {
        const now = new Date();
        return new Date(
            now.getFullYear(),
            now.getMonth(),
            now.getDate(),
            23,
            59,
            59,
            999,
        );
    }

    public static compareForSorting(key, order: string = 'asc') {
        return function innerSort(a, b) {
            if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
                return 0;
            }

            const varA =
                typeof a[key] === 'string' ? a[key].toUpperCase() : a[key];
            const varB =
                typeof b[key] === 'string' ? b[key].toUpperCase() : b[key];

            let comparison = 0;
            if (varA > varB) {
                comparison = 1;
            } else if (varA < varB) {
                comparison = -1;
            }

            return order === 'desc' ? comparison * -1 : comparison;
        };
    }

    public static percentage(num: number, of: number) {
        return (num * 100) / of;
    }

    public static sumArrayByObjectProperty(array: any[], property: string) {
        let result = 0;

        for (const item of array) {
            if (item.hasOwnProperty(property) && !isNaN(item[property])) {
                result += item[property];
            }
        }

        return result;
    }

    public static getNameInitials(name: string): string {
        if (!name) return '';

        const arr = name.split(' ').map((char) => char[0].toUpperCase());
        if (arr.length > 2) {
            arr.length = 2;
        }

        return arr.join('');
    }

    public static getDateFromNumber(epochMillis: number): XDate {
        const date = new XDate(epochMillis);
        date.setUTCMode(true, false);
        return date;
    }

    public static getStringDateFromNumber(epochMillis: number): string {
        const date = new XDate(epochMillis);
        date.setUTCMode(true, false);
        return date.toString('dd.MM.yyyy');
    }

    public static getStringDateAndTimeFromNumber(epochMillis: number): string {
        const date = new XDate(epochMillis);
        // date.setUTCMode(true, false);
        return date.toString('dd.MM.yyyy HH:mm:ss');
    }

    public static convertMinsToHrsMins(mins): string {
        let h: string | number = Math.floor(mins / 60);
        let m: string | number = mins % 60;
        h = h < 10 ? '0' + h : h;
        m = m < 10 ? '0' + m : m;

        return `${h}:${m}`;
    }

    public static capitalizeFirstLetter(text: string) {
        return text.charAt(0).toUpperCase() + text.toLowerCase().slice(1);
    }

    public static isFlagged(flags: number, flagValue: number): boolean {
        return (flags & flagValue) === flagValue;
    }
}

import React, { PropsWithChildren } from 'react';
import './ObjectStatusBadge.scss';
import { useTranslation } from 'react-i18next';

interface StatusBadgeProps {
    type?: string;
    status?: string;
}

const ObjectStatusBadge = (props: PropsWithChildren<StatusBadgeProps>) => {
    const { t } = useTranslation();
    if (props.type === 'table') {
        return (
            <span
                className={`object-badge status-${props.status
                    .replace(/ /g, '-')
                    .toLowerCase()}`}
            >
                {props.children ? (
                    <div className={`meta`}>{props.children}</div>
                ) : null}
                {props.status}
            </span>
        );
    } else if (props.type === 'new-table-with-text') {
        return (
            <span
                className={`new-table-badge object-badge status-${props.status
                    .replace(/ /g, '-')
                    .toLowerCase()}`}
            >
                {props.children ? (
                    <div className={`meta`}>{props.children}</div>
                ) : null}
                {props.status}
            </span>
        );
    } else if (props.type === 'table-no-text') {
        return (
            <span
                className={`object-badge status-${props.status
                    .replace(/ /g, '-')
                    .toLowerCase()}`}
            ></span>
        );
    } else if (props.type === 'legend') {
        return (
            <span
                className={`object-badge legend status-${props.status
                    .replace(/ /g, '-')
                    .toLowerCase()}`}
            >
                {props.children ? (
                    <div className={`meta`}>{props.children}</div>
                ) : null}
                {props.status}
            </span>
        );
    } else if (props.type === 'legend-wide') {
        return (
            <span
                className={`object-badge legend-wide status-${props.status
                    .replace(/ /g, '-')
                    .toLowerCase()}`}
            >
                {props.children ? (
                    <div className={`meta`}>{props.children}</div>
                ) : null}
                {props.status}
            </span>
        );
    } else if (props.type === 'legend-color-change') {
        return (
            <span
                className={`object-badge legend status-change-${props.status
                    .replace(/ /g, '-')
                    .toLowerCase()}`}
            >
                {props.children ? (
                    <div className={`meta`}>{props.children}</div>
                ) : null}
                {props.status}
            </span>
        );
    } else {
        return <span>ddd{t('Invalid badge type!')}</span>;
    }
};

export default ObjectStatusBadge;

export const roleData = [
    {
        id: 1,
        name: 'Agent',
    },
    {
        id: 2,
        name: 'Team Leader',
    },
    {
        id: 3,
        name: 'Assistant',
    },
    {
        id: 4,
        name: 'Management',
    },
    {
        id: 5,
        name: 'Admin',
    },
    // {
    //     id: 3,
    //     name: 'Manager Sales',
    // },
    // {
    //     id: 4,
    //     name: 'Assistant 1',
    // },
    // {
    //     id: 5,
    //     name: 'Bookkeeping',
    // },
    // {
    //     id: 6,
    //     name: 'Marketing',
    // },
    // {
    //     id: 7,
    //     name: 'Admin Basic',
    // },
    // {
    //     id: 8,
    //     name: 'Admin General',
    // },
    // {
    //     id: 9,
    //     name: 'Admin SuperUser',
    // },
    // {
    //     id: 10,
    //     name: 'Director Agent',
    // },
    // {
    //     id: 11,
    //     name: 'Director Admin',
    // },
];

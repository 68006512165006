import React, { MouseEventHandler } from 'react';
import './CardHeader.scss';
import { Icon } from '@mdi/react';

interface CardHeaderProps {
    title: string;
    titleRight?: string | React.ReactNode;
    status?: string;
    className?: string;
    icon?: any;
    onClick?: MouseEventHandler<HTMLDivElement>;
}

const CardHeader = (props: CardHeaderProps) => {
    return (
        <div onClick={props.onClick} className={`${'card-wrapper' + ' ' + props.className + ' ' + (props.onClick ? 'clickable' : '')}`}>
            <div className="card-text">
                <h5>
                    {props.icon !== undefined ? (
                        <Icon
                            path={props?.icon}
                            className="icon"
                            size="20px"
                            style={{ marginRight: '.5rem' }}
                        />
                    ) : null}
                    {props.title}
                </h5>
                <h6>{props.status}</h6>
            </div>
            {props.titleRight ? (
                <div className="card-text">
                    {(typeof props.titleRight === 'string' || props.titleRight instanceof String) ? <h5>{props.titleRight}</h5> : props.titleRight}
                </div>
            ) : (
                <></>
            )}
        </div>
    );
};

export default CardHeader;

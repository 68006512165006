import * as React from 'react';
import { useApplicationContext } from '../../../application/ApplicationContext';
import { Button } from 'primereact/button';
import './ResetPasswordForm.scss';
import { Password } from 'primereact/password';
import { Fieldset } from 'primereact/fieldset';
import { Toast } from 'primereact/toast';
import { useTranslation } from 'react-i18next';

export default function ResetPasswordForm(props) {
    const { appState, resetPassword, loginGoBack } = useApplicationContext();
    const [password, setPassword] = React.useState('');
    const [confirmPassword, setConfirmPassword] = React.useState('');
    const [message, setMessage] = React.useState('');
    const { t } = useTranslation();

    const regNumber =
        /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/;

    const sendResetPasswordPequest = () => {
        return regNumber.test(password) && password === confirmPassword
            ? resetPassword(props.token[0], password, confirmPassword)
            : setMessage(
                    !regNumber.test(password)
                        ? t('Password must have min 8 character, 1 uppercase, 1 lowercase, 1 number and 1 special character')
                        : t('password dont match!'),
                );
    };

    const submitForm = () => {
        sendResetPasswordPequest()
            ? setMessage(t('Password change successfully!'))
            : null;
    };

    const toast = React.useRef(null);
    React.useEffect(() => {
        toast.current.clear();
        if (message !== '') {
            toast.current.show({
                severity: 'error',
                detail: `${message}`,
                life: 3000,
            });
        }
        if (message === t('Password change successfully!')) {
            toast.current.show({
                severity: 'success',
                detail: `${message}`,
                life: 3000,
            });
        }
    }, [message]);

    return (
        <>
            <Toast ref={toast} position={'top-right'} />
            <div className="reset-password-form">
                <div className="reset-password-title">{t('Resetovanje šifre')}</div>
                <Fieldset>
                    <Password
                        className="input-field"
                        placeholder={t('Password')}
                        value={password}
                        feedback={false}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            setPassword(e.target.value);
                            setMessage('');
                        }}
                    />
                </Fieldset>
                <Fieldset>
                    <Password
                        className="input-field"
                        placeholder={t(`Ponovi lozinku`)}
                        value={confirmPassword}
                        feedback={false}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            setConfirmPassword(e.target.value);
                            setMessage('');
                        }}
                    />
                </Fieldset>
                <Button
                    className="reset-button"
                    label={t('Reset password')}
                    onClick={submitForm}
                    onKeyDown={(e: React.KeyboardEvent<HTMLElement>) =>
                        e.key === 'Enter' && submitForm()
                    }
                />
            </div>
        </>
    );
}

import React, { useState, useEffect } from 'react';
import './NewInquiryFormModal.scss';
import CustomObjectInput from '../objects/customObjectInput/CustomObjectInput';
import { useForm } from 'react-hook-form';
import { contactsService } from '../../services/ContactsService';
import { propertiesService } from '../../services/PropertiesService';
import { inquiriesService } from '../../services/InquiriesService';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { useTranslation } from 'react-i18next';
import { INewInquiryFormModal } from '../../models/NewInquiryFormModal';
import { RadioButton } from 'primereact/radiobutton';
import { ProgressSpinner } from 'primereact/progressspinner';

const NewInquiryFormModal = ({ showModal, toast }) => {
    const [searchProperty, setSearchProperty] = useState(null);
    const [searchContact, setSearchContact] = useState(null);
    const [spinner, setSpinner] = useState(true);
    const [contactList, setContactList] = useState([]);
    const [properties, setProperties] = useState([]);
    const { t } = useTranslation();
    const categories = [
        { name: t('choose exist contact'), key: '1' },
        { name: t('Add new contact'), key: '2' },
    ];
    const [selectedCategory, setSelectedCategory] = useState(categories[0]);

    const [newInquiryFormModalObject, setNewInquiryFormModalObject] =
        useState<INewInquiryFormModal>({
            status: 'Neu Bold',
            date: new Date(),
            message: '',
            propertyId: null,
            contactId: null,
            contact: {
                firstName: '',
                lastName: '',
                emails: '',
                privatePhone: null,
                address: '',
                city: '',
                role: 'Buyer',
                type: 'Person',
                assigneeId: 1,
            },
        });

    useEffect(() => {
        fetchContacts().then((contactsResult) => {
            setContactList(contactsResult.filter((x) => x.role === 'Buyer'));
            fetchProperties().then((propertiesResult) => {
                setProperties(propertiesResult);
                setSpinner(false);
            });
        });
    }, []);

    const handleResetModal = () => {
        setNewInquiryFormModalObject({
            status: null,
            date: null,
            message: '',
            propertyId: null,
            contactId: null,
            contact: {
                firstName: '',
                lastName: '',
                emails: '',
                privatePhone: null,
                address: '',
                city: '',
                role: 'Buyer',
                type: 'Person',
                assigneeId: 1,
            },
        });
    };

    const handleNewInquiryForm = (prop, value) => {
        setNewInquiryFormModalObject((NewInquiryFormModalObject) => ({
            ...NewInquiryFormModalObject,
            [prop]: value,
        }));
    };

    const handleNewConact = (prop, value) => {
        setNewInquiryFormModalObject((NewInquiryFormModalObject) => ({
            ...NewInquiryFormModalObject,
            contact: { ...NewInquiryFormModalObject.contact, [prop]: value },
        }));
    };

    const fetchProperties = async () => {
        const response = await propertiesService.getMany();
        return response.data;
    };

    const fetchContacts = async () => {
        const response = await contactsService.getMany();
        return response.data;
    };

    const formatPropertyText = (property) => {
        let result = '';

        result += property.rootId;
        result += ', ';
        result += property.place;

        if (property.street) {
            result += ' ';
            result += property.street;
        }

        if (property.houseNumber) {
            result += ' ';
            result += property.houseNumber;
        }

        if (property.seller) {
            result += ', ';
            result += property.seller.firstName;
            result += ' ';
            result += property.seller.lastName;
        }

        return result;
    };

    const formatContactText = (contact) => {
        let result = '';

        result += contact.id;
        result += '. ';

        if (contact.firstName) {
            result += ' ';
            result += contact.firstName;
        }

        if (contact.lastName) {
            result += ' ';
            result += contact.lastName;
        }

        if (contact.postalCode) {
            result += ', ';
            result += contact.postalCode;
        }

        if (contact.city) {
            result += ', ';
            result += contact.city;
        }

        if (contact.address) {
            result += ', ';
            result += contact.address;
        }

        return result;
    };

    const contactOptions = () => {
        const allContacts = contactList.map((contact) => ({
            label: formatContactText(contact),
            value: contact.id,
        }));
        return allContacts.sort((a: any, b: any) => {
            const aId = parseInt(a.label.split(',')[0]);
            const bId = parseInt(b.label.split(',')[0]);

            return aId - bId;
        });
    };

    const propertiesOptions = () => {
        const allProperties = properties.map((property) => ({
            label: formatPropertyText(property),
            value: property.rootId,
        }));
        return allProperties.sort((a: any, b: any) => {
            const aId = parseInt(a.label.split(',')[0]);
            const bId = parseInt(b.label.split(',')[0]);

            return aId - bId;
        });
    };

    const {
        control,
        formState: { errors, isValid },
        register,
    } = useForm({
        mode: 'all',
    });

    const handleButtonCheck = () => {
        let newArray = { ...newInquiryFormModalObject };

        if (newArray.contact.emails.length !== 0) {
            const emailArray = newArray?.contact.emails.map((el) => {
                return { email: el };
            });

            newArray.contact.emails = emailArray;
        }
        setSpinner(true);

        inquiriesService.createOne(newArray).then(() => {
            setSpinner(true);
            showModal();
            handleResetModal();
            toast.current.show({
                severity: 'success',
                detail: t(`Added a new inquiry`),
                life: 3000,
            });
        });
    };

    const footer = (
        <div className="footer-buttons">
            <Button
                label={t('Save')}
                icon="pi pi-check"
                disabled={!isValid}
                onClick={handleButtonCheck}
            />
            {/* <Button
                label={t('Refresh')}
                className="p-button-secondary"
                icon="pi pi-refresh"
                onClick={handleResetModal}
            /> */}
        </div>
    );

    return (
        <>
            <Dialog
                header={t('New Inquiry')}
                visible={true}
                style={{ minWidth: '45%' }}
                className="new-inquiry-modal"
                footer={spinner ? null : footer}
                blockScroll
                draggable={false}
                onHide={showModal}
            >
                {spinner ? (
                    <div className="center-spinner">
                        <ProgressSpinner
                            strokeWidth="5"
                            animationDuration="1.5s"
                        />
                    </div>
                ) : (
                    <>
                        {/* <div className="modal-input">
                            <div className={'dropdown'}>
                                <CustomObjectInput
                                    type="dropdown"
                                    classes={['country-input']}
                                    id="id-input"
                                    label={t('Inquiry status')}
                                    name="status"
                                    dropdownOptions={[
                                        InquiryStatus.NEW_BOLD,
                                        InquiryStatus.NEW,
                                        InquiryStatus.IN_PROCESSING,
                                        InquiryStatus.SIGHTSEEING,
                                        InquiryStatus.INTERESTED,
                                        InquiryStatus.INTERESTED_IN_BUYING,
                                        InquiryStatus.PURCHASE_INTENT,
                                        InquiryStatus.OFFER,
                                        InquiryStatus.SALE_GH,
                                        InquiryStatus.CLOSED,
                                    ]}
                                    stateField={
                                        newInquiryFormModalObject.status
                                    }
                                    setStateField={handleNewInquiryForm}
                                    errors={errors}
                                    control={control}
                                />
                            </div>
                        </div> */}
                        {/* <div className="modal-input">
                            <CustomObjectInput
                                type="calendar"
                                classes={['valid-from-input']}
                                id="Date-and-time-from-input"
                                label={t('Date')}
                                name="date"
                                register={register}
                                timeShow={true}
                                stateField={
                                    newInquiryFormModalObject.date !== null
                                        ? newInquiryFormModalObject.date
                                        : new Date()
                                }
                                setStateField={handleNewInquiryForm}
                                errors={errors}
                                control={control}
                                rules={
                                    !newInquiryFormModalObject.date && {
                                        required: t('This is required'),
                                    }
                                }
                            />
                        </div> */}
                        <div className="modal-input">
                            <CustomObjectInput
                                type="dropdown"
                                classes={['country-input']}
                                id="Object-input"
                                label={t('Property')}
                                name="propertyId"
                                register={register}
                                filter={true}
                                onFilter={(e) =>
                                    setSearchProperty(e.filter.length)
                                }
                                emptyFilterMessage={'Search property...'}
                                dropdownOptions={
                                    searchProperty > 2
                                        ? propertiesOptions()
                                        : [
                                              {
                                                  label: 'Search property...',
                                                  disabled: true,
                                              },
                                          ]
                                }
                                stateField={
                                    newInquiryFormModalObject.propertyId
                                }
                                setStateField={handleNewInquiryForm}
                                errors={errors}
                                control={control}
                                rules={{ required: t('This is required') }}
                            />
                        </div>
                        <div className="radio-buttons">
                            {categories.map((category) => {
                                return (
                                    <div
                                        key={category.key}
                                        className="field-radiobutton"
                                    >
                                        <RadioButton
                                            inputId={category.key}
                                            name="price_priceType"
                                            value={category}
                                            onChange={(e) => {
                                                setSelectedCategory(e.value);
                                            }}
                                            checked={
                                                selectedCategory.key ===
                                                category.key
                                            }
                                            disabled={category.key === 'R'}
                                        />
                                        <label
                                            className="radio-button-label"
                                            htmlFor={category.key}
                                        >
                                            {category.name}
                                        </label>
                                    </div>
                                );
                            })}
                        </div>
                        {selectedCategory.name === t('Add new contact') ? (
                            <div>
                                <div className="modal-radio-button">
                                    <CustomObjectInput
                                        type="text"
                                        classes={[
                                            'generic-input',
                                            'add-new-contact',
                                        ]}
                                        id="first-name-input"
                                        label={t('Vorname / Ime') + ' *'}
                                        name="firstName"
                                        stateField={
                                            newInquiryFormModalObject.contact
                                                .firstName
                                        }
                                        setStateField={handleNewConact}
                                        errors={errors}
                                        control={control}
                                        rules={{
                                            required: t('This is required'),
                                        }}
                                    />

                                    <CustomObjectInput
                                        type="text"
                                        classes={[
                                            'generic-input',
                                            'add-new-contact',
                                        ]}
                                        id="last-name-input"
                                        label={t('Nachname / prezime') + ' *'}
                                        name="lastName"
                                        stateField={
                                            newInquiryFormModalObject.contact
                                                .lastName
                                        }
                                        setStateField={handleNewConact}
                                        errors={errors}
                                        control={control}
                                        rules={{
                                            required: t('This is required'),
                                        }}
                                    />
                                    <span className="enter-emails-input">
                                        <CustomObjectInput
                                            type="chips"
                                            classes={[
                                                'generic-input',
                                                'add-new-contact-email',
                                            ]}
                                            id="email-input"
                                            label={t('Emails') + '*'}
                                            name="emails"
                                            stateField={
                                                newInquiryFormModalObject
                                                    .contact.emails
                                            }
                                            setStateField={handleNewConact}
                                            errors={errors}
                                            control={control}
                                            rules={{
                                                required: t('This is required'),
                                                pattern:
                                                    /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,})+$/,
                                            }}
                                        />
                                        <p>{`${t(
                                            'Press enter to save E-mail',
                                        )}*`}</p>
                                    </span>
                                </div>
                                <div className="modal-radio-button">
                                    <CustomObjectInput
                                        type="text"
                                        classes={[
                                            'generic-input',
                                            'add-new-contact',
                                        ]}
                                        id="first-name-input"
                                        label={t('Mobile')}
                                        name="privatePhone"
                                        stateField={
                                            newInquiryFormModalObject.contact
                                                .privatePhone
                                        }
                                        setStateField={handleNewConact}
                                        errors={errors}
                                        control={control}
                                        // rules={{
                                        //     required: t('This is required'),
                                        // }}
                                    />

                                    <CustomObjectInput
                                        type="text"
                                        classes={[
                                            'generic-input',
                                            'add-new-contact',
                                        ]}
                                        id="last-name-input"
                                        label={t('Address')}
                                        name="address"
                                        stateField={
                                            newInquiryFormModalObject.contact
                                                .address
                                        }
                                        setStateField={handleNewConact}
                                        errors={errors}
                                        control={control}
                                        // rules={{
                                        //     required: t('This is required'),
                                        // }}
                                    />

                                    <CustomObjectInput
                                        type="text"
                                        classes={[
                                            'generic-input',
                                            'add-new-contact-email',
                                        ]}
                                        id="email-input"
                                        label={t('City')}
                                        name="city"
                                        stateField={
                                            newInquiryFormModalObject.contact
                                                .city
                                        }
                                        setStateField={handleNewConact}
                                        errors={errors}
                                        control={control}
                                        // rules={{
                                        //     required: t('This is required'),
                                        // }}
                                    />
                                </div>
                            </div>
                        ) : null}
                        {selectedCategory.name === t('choose exist contact') ? (
                            <div className="modal-input">
                                <CustomObjectInput
                                    type="dropdown"
                                    classes={['country-input']}
                                    id="Object-input"
                                    label={t('Contact')}
                                    name="contactId"
                                    register={register}
                                    filter={true}
                                    onFilter={(e) =>
                                        setSearchContact(e.filter.length)
                                    }
                                    emptyFilterMessage={'Search property...'}
                                    dropdownOptions={
                                        searchContact > 2
                                            ? contactOptions()
                                            : [
                                                  {
                                                      label: 'Search contacts...',
                                                      disabled: true,
                                                  },
                                              ]
                                    }
                                    stateField={
                                        newInquiryFormModalObject.contactId
                                    }
                                    setStateField={handleNewInquiryForm}
                                    errors={errors}
                                    control={control}
                                    rules={{ required: t('This is required') }}
                                />
                            </div>
                        ) : null}
                        <div className="modal-input">
                            <CustomObjectInput
                                type="accordion-textarea"
                                classes={['message-input']}
                                id="message-input"
                                label={t('Message')}
                                name="message"
                                stateField={newInquiryFormModalObject.message}
                                setStateField={handleNewInquiryForm}
                                errors={errors}
                                control={control}
                            />
                        </div>
                    </>
                )}
            </Dialog>
        </>
    );
};

export default NewInquiryFormModal;

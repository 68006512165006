import React from 'react';

export interface CheckListItemProps {
    primaryLabel: string;
    secondaryLabel: string;
    checklist: any;
    //TODO: Add single checklist item once the document upload criteria is defined
}

const DocumentChecklistItem = (props: CheckListItemProps) => {
    return (
        <div className="item-row">
            <div className="label-container">
                <strong>{props.primaryLabel}</strong>
                <small>{props.secondaryLabel}</small>
            </div>
            {/*<div className="icon-container">*/}
            {/*    <i*/}
            {/*        className={`pi ${*/}
            {/*            props.checklist?.apartmentPlan ? 'pi-check' : 'pi-times'*/}
            {/*        }`}*/}
            {/*    />*/}
            {/*</div>*/}
        </div>
    )
}

export default DocumentChecklistItem;

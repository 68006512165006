import * as React from 'react';
import { Route, Redirect, RouteProps, Link } from 'react-router-dom';
import { useApplicationContext } from '../ApplicationContext';

interface GuestRouteProps extends RouteProps {
    // tslint:disable-next-line:no-any
}

const GuestRoute = (props: GuestRouteProps) => {
    const { children, ...rest } = props;
    const { appState } = useApplicationContext();

    if (!appState.authenticated) {
        return (
            <Route {...rest} exact>
                {children}
            </Route>
        );
    } else {
        return (
            <Link
                to={{
                    pathname: '/',
                    state: { from: props.location },
                }}
            />
        );
    }
};

export default GuestRoute;

import { t } from 'i18next';
import { Dialog } from 'primereact/dialog';
import React from 'react';
import DocumentChecklistItem from './DocumentChecklistItem';
import './DocumentChecklistModal.scss';

const DocumentCheckListModal = ({
    propertyType,
    propertySubtype,
    checklist,
    visible,
}) => {
    switch (propertyType) {
        case 'Haus':
            return (
                <Dialog
                    header="Dokumente Checkliste"
                    visible={true}
                    onHide={visible}
                    style={{ width: '25vw' }}
                    draggable={true}
                    blockScroll
                >
                    <div className="checklist-container">
                        {/*<DocumentChecklistItem*/}
                        {/*    primaryLabel="GBA Aufschaltung"*/}
                        {/*    secondaryLabel="Grundbuchsauszug Aufschaltung"*/}
                        {/*    checklist={checklist}*/}
                        {/*/>*/}
                        {/*<DocumentChecklistItem*/}
                        {/*    primaryLabel="GBA Kaufanbot"*/}
                        {/*    secondaryLabel="Grundbuchsauszug Kaufanbot"*/}
                        {/*    checklist={checklist}*/}
                        {/*/>*/}
                        <DocumentChecklistItem
                            primaryLabel="Energieausweis"
                            secondaryLabel="wenn nicht vorhanden, bitte bei Ihrem Schornsteinfeger beantragen"
                            checklist={checklist}
                        />
                        <DocumentChecklistItem
                            primaryLabel="Grundbuchauszug vollständig"
                            secondaryLabel="den aktuellen Auszug erhalten Sie bei Ihrem Grundbuchamt"
                            checklist={checklist}
                        />
                        <DocumentChecklistItem
                            primaryLabel="aktuelle Grundrisse Ihres Hauses"
                            secondaryLabel="aktuelle Grundrisse Ihres Hauses"
                            checklist={checklist}
                        />
                        <DocumentChecklistItem
                            primaryLabel="Aufstellung über die Nebenkosten pro Jahr"
                            secondaryLabel="Wasser, Müllabfuhr, Internet, Strom etc."
                            checklist={checklist}
                        />
                        <DocumentChecklistItem
                            primaryLabel="Übersicht über erfolgte Renovierungen"
                            secondaryLabel="Übersicht über erfolgte Renovierungen"
                            checklist={checklist}
                        />
                        <DocumentChecklistItem
                            primaryLabel="Baupläne des Hauses"
                            secondaryLabel="wenn vorhanden"
                            checklist={checklist}
                        />
                        <DocumentChecklistItem
                            primaryLabel="Katasterplan, Flurkarte"
                            secondaryLabel="wenn vorhanden"
                            checklist={checklist}
                        />
                        <DocumentChecklistItem
                            primaryLabel="Baulastenauskunft/Baulastenverzeichnis"
                            secondaryLabel="wenn nicht vorhanden, bitte bei Ihrer örtlichen Baufaufstichtsbehörde einholen"
                            checklist={checklist}
                        />
                        <DocumentChecklistItem
                            primaryLabel="Ursprünglicher Kaufvertrag"
                            secondaryLabel="Ursprünglicher Kaufvertrag"
                            checklist={checklist}
                        />
                        {/*{propertySubtype === 'Reihenhaus' ? (*/}
                        {/*    <>*/}
                        {/*        <DocumentChecklistItem*/}
                        {/*            primaryLabel="NWGA"*/}
                        {/*            secondaryLabel="Nutzwertgutachten"*/}
                        {/*            checklist={checklist}*/}
                        {/*        />*/}
                        {/*        <DocumentChecklistItem*/}
                        {/*            primaryLabel="Reparaturrücklage"*/}
                        {/*            secondaryLabel="Reparaturrücklage"*/}
                        {/*            checklist={checklist}*/}
                        {/*        />*/}
                        {/*        <DocumentChecklistItem*/}
                        {/*            primaryLabel="Vorausschau HV"*/}
                        {/*            secondaryLabel="Aktuelle Vorausschau HV"*/}
                        {/*            checklist={checklist}*/}
                        {/*        />*/}
                        {/*        <DocumentChecklistItem*/}
                        {/*            primaryLabel="Protokoll letzte EV"*/}
                        {/*            secondaryLabel="Protokoll der letzten Eigentümerversammlung"*/}
                        {/*            checklist={checklist}*/}
                        {/*        />*/}
                        {/*        <DocumentChecklistItem*/}
                        {/*            primaryLabel="Vorschreibung HV"*/}
                        {/*            secondaryLabel="Vorschreibung der HV"*/}
                        {/*            checklist={checklist}*/}
                        {/*        />*/}
                        {/*        <DocumentChecklistItem*/}
                        {/*            primaryLabel="WEV"*/}
                        {/*            secondaryLabel="Wohnungseigentumsvertrag"*/}
                        {/*            checklist={checklist}*/}
                        {/*        />*/}
                        {/*    </>*/}
                        {/*) : null}*/}
                        {/*<DocumentChecklistItem*/}
                        {/*    primaryLabel="Ausweis Verkäufer"*/}
                        {/*    secondaryLabel="Ausweis Verkäufer"*/}
                        {/*    checklist={checklist}*/}
                        {/*/>*/}
                        {/*<DocumentChecklistItem*/}
                        {/*    primaryLabel="Ausweis Käufer"*/}
                        {/*    secondaryLabel="Ausweis Käufer"*/}
                        {/*    checklist={checklist}*/}
                        {/*/>*/}
                    </div>
                </Dialog>
            );
        case 'Wohnung':
            return (
                <Dialog
                    header="Document checklist"
                    visible={true}
                    onHide={visible}
                    style={{ width: '25vw' }}
                    draggable={true}
                    blockScroll
                >
                    <div className="checklist-container">
                        <DocumentChecklistItem
                            primaryLabel="Energieausweis"
                            secondaryLabel="wenn nicht vorhanden, bitte bei Hausverwaltung nachfragen oder eventuell bei Schornsteinfeger beantragen"
                            checklist={checklist}
                        />
                        <DocumentChecklistItem
                            primaryLabel="Aktueller Wirtschaftsplan"
                            secondaryLabel="Aktueller Wirtschaftsplan"
                            checklist={checklist}
                        />
                        <DocumentChecklistItem
                            primaryLabel="Renovationen"
                            secondaryLabel="Haus/Wohnung/Garage/Allgemeinflächen"
                            checklist={checklist}
                        />
                        <DocumentChecklistItem
                            primaryLabel="Modernisierungskalender"
                            secondaryLabel="Modernisierungskalender"
                            checklist={checklist}
                        />
                        <DocumentChecklistItem
                            primaryLabel="Protokolle der Eigentümerversammlungen der letzten beiden Jahre"
                            secondaryLabel="Protokolle der Eigentümerversammlungen der letzten beiden Jahre"
                            checklist={checklist}
                        />
                        <DocumentChecklistItem
                            primaryLabel="Nebenkosten pro Jahr"
                            secondaryLabel="Haus/Wohnung/Garage/Allgemeinflächen"
                            checklist={checklist}
                        />
                        <DocumentChecklistItem
                            primaryLabel="Hausgeld"
                            secondaryLabel="Stand und Einlage pro Jahr"
                            checklist={checklist}
                        />
                        <DocumentChecklistItem
                            primaryLabel="Instandhaltungsrücklage bzw. Gesamtwirtschaftsplan"
                            secondaryLabel="Instandhaltungsrücklage bzw. Gesamtwirtschaftsplan"
                            checklist={checklist}
                        />
                        <DocumentChecklistItem
                            primaryLabel="Teilungserklärung"
                            secondaryLabel="Teilungserklärung"
                            checklist={checklist}
                        />
                        <DocumentChecklistItem
                            primaryLabel="Grundbuchauszug"
                            secondaryLabel="bitte an Ihrem Grundbuchamt einholen"
                            checklist={checklist}
                        />
                        <DocumentChecklistItem
                            primaryLabel="Katasterplan, Flurkarte"
                            secondaryLabel="wenn vorhanden, ansonsten bitte bei Gemeinde einholen"
                            checklist={checklist}
                        />
                        <DocumentChecklistItem
                            primaryLabel="Grundrisse"
                            secondaryLabel="Haus/Wohnung/Garage"
                            checklist={checklist}
                        />
                        <DocumentChecklistItem
                            primaryLabel="Unterlagen zur Erbpacht"
                            secondaryLabel="betrifft nur Objekte in Erbpacht"
                            checklist={checklist}
                        />
                        <DocumentChecklistItem
                            primaryLabel="Ursprünglicher Kaufvertrag"
                            secondaryLabel="Ursprünglicher Kaufvertrag"
                            checklist={checklist}
                        />
                        <DocumentChecklistItem
                            primaryLabel="Mietverträge"
                            secondaryLabel="wenn Ihre Wohnung vermietet ist"
                            checklist={checklist}
                        />
                    </div>
                </Dialog>
            );
        case 'Grundstück':
            return (
                <Dialog
                    header="Document checklist"
                    visible={true}
                    onHide={visible}
                    style={{ width: '25vw' }}
                    draggable={true}
                    blockScroll
                >
                    <div className="checklist-container">
                        <DocumentChecklistItem
                            primaryLabel="Grundbuchauszug"
                            secondaryLabel="wenn nicht vorhanden, bitte bei Ihrem Grundbuchamt einholen"
                            checklist={checklist}
                        />
                        <DocumentChecklistItem
                            primaryLabel="Katasterplan, Flurkarte und genaue Maße des Grundstücks"
                            secondaryLabel="wenn nicht vorhanden, bitte bei Ihrem Grundbuchamt einholen"
                            checklist={checklist}
                        />
                        <DocumentChecklistItem
                            primaryLabel="Bauordnung, maximale Bauhöhe, maximale Größe"
                            secondaryLabel="GFZ, GRZ"
                            checklist={checklist}
                        />
                        <DocumentChecklistItem
                            primaryLabel="Baulastenauskunft/Baulastenverzeichnis"
                            secondaryLabel="wenn nicht vorhanden, bitte bei Ihrer örtlichen Baufaufstichtsbehörde einholen"
                            checklist={checklist}
                        />
                        <DocumentChecklistItem
                            primaryLabel="Bodengutachten, Altlastenauskunft"
                            secondaryLabel="Bodengutachten, Altlastenauskunft"
                            checklist={checklist}
                        />
                    </div>
                </Dialog>
            );
        default:
            return (
                <Dialog
                    header="Information"
                    visible={true}
                    onHide={visible}
                    style={{ width: '20vw' }}
                    draggable={true}
                    blockScroll
                >
                    <div className="checklist-info-container">
                        <p>
                            {t(
                                'There is no checklist for this type of property!',
                            )}
                        </p>
                    </div>
                </Dialog>
            );
    }
};

export default DocumentCheckListModal;

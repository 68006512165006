import React, { useState, useEffect, useRef } from 'react';
import classNames from 'classnames';
import { Redirect, Route, withRouter } from 'react-router-dom';

import AppTopBar from './AppTopbar';
//import AppConfig from './AppConfig';
//import AppMenu from './AppMenu';

import { Dashboard } from './components/Dashboard';
import { EmptyPage } from './pages/EmptyPage/EmptyPage';
import CalendarPage from './pages/CalendarPage/CalendarPage';
import { NewObject } from './pages/NewObject/NewObject';
import { ViewObject } from './pages/ViewObject/ViewObject';
import { Inquiry } from './pages/Inquiry/Inquiry';
import { Objects } from './pages/Objects/Objects';
import ReportPage from './pages/ReportPage/ReportPage';
import NewFirstDatePage from './pages/NewFirstDatePage/NewFirstDatePage';
import FirstDatePage from './pages/FirstDatePage/FirstDatePage';
import MeineInteresentPage from './pages/MeineInteresentPage/MeineInteresentPage';
import MyStatisticsPage from './pages/MyStatisticsPage/MyStatisticsPage';
import { InteressentCommunication } from './pages/InteressentCommunicationPage/InteressentCommunication';
import AgentAssignmentPage from './pages/AgentAssignmentPage/AgentAssignmentPage';
import UsersPage from './pages/UserPage/UserPage';
import ContactsPage from './pages/ContactsPage/ContactsPage';
import { ViewContact } from './pages/ViewContact/ViewContact';
import { Toast } from 'primereact/toast';

import PrimeReact from 'primereact/api';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import './App.scss';
import {
    mdiCalendarMonth,
    mdiStarShootingOutline,
    mdiCommentPlusOutline,
    mdiSignalCellularOutline,
    mdiViewDashboardVariantOutline,
    mdiHomeCityOutline,
    mdiContactsOutline,
    mdiPlus,
    mdiChartLine,
    mdiArrowCollapse,
    mdiUploadMultiple,
    mdiUpload,
} from '@mdi/js';
import PrivateRoute from './application/router/PrivateRoute';
import { NewContact } from './pages/NewContactPage/NewContact';
import { useApplicationContext } from './application/ApplicationContext';
import '../public/assets/theme/blue/theme-light.css';
import '../public/assets/layout/css/layout-light.css';
import { useTranslation } from 'react-i18next';

import '../public/assets/theme/blue/theme-light.css';
import '../public/assets/layout/css/layout-light.css';
import { userService } from './services/UsersService';
import { Permissions } from './core/auth/Permissions';
import FirstDateFormModal from './components/FirstDateFormModal/FirstDateFormModal';
import NewInquiryFormModal from './components/NewInquiryFormModal/NewInquiryFormModal';
import NewContactModal from './components/NewContactModal/NewContactModal';
import { DomHandler } from 'primereact/utils';
import { useStateCallback } from './hooks/StateCallbackHook';
import FailedExportsPage from './pages/FailedExportsPage/FailedExportsPage';
import { inquiriesService } from './services/InquiriesService';

const App = ({ history }) => {
    const { t } = useTranslation();
    const [menuMode, setMenuMode] = useState('static');
    const [colorScheme, setColorScheme] = useState('light');
    const [menuTheme, setMenuTheme] = useState('layout-sidebar-darkgray');
    const [overlayMenuActive, setOverlayMenuActive] = useState(false);
    //    const [staticMenuDesktopInactive, setStaticMenuDesktopInactive] =
    useState(false);
    const [staticMenuMobileActive, setStaticMenuMobileActive] = useState(false);
    const [topbarUserMenuActive, setTopbarUserMenuActive] = useState(false);
    const [configActive, setConfigActive] = useState(false);
    const [inputStyle, setInputStyle] = useState('outlined');
    const [ripple, setRipple] = useState(false);
    const [showFirstDateModal, setShowFirstDateModal] = useStateCallback(false);
    const [showContactModal, setShowContactModal] = useStateCallback(false);
    const [showInteressentModal, setShowInteressentModal] =
        useStateCallback(false);
    const toast = React.useRef(null);
    //    const [logoColor, setLogoColor] = useState('white');
    //    const [componentTheme, setComponentTheme] = useState('blue');
    //    const [logoUrl, setLogoUrl] = useState(
    //        '../public/assets/layout/images/logo-dark.svg',
    //    );

    let menuClick = false;
    let configClick = false;

    const redirectDefault = () => {
        // return <Redirect to="/" />;
    };

    const { appState, tokenCheck, setNewInquiry } = useApplicationContext();

    // todo: ne treba menu da bude u ovom fajlu
    // todo: potpuni izostanak tipova!
    const menu = [
        {
            items: [
                {
                    label: t('Dashboard'),
                    icon: mdiViewDashboardVariantOutline,
                    to: '/',
                    visible: () => {
                        return userService.hasPermission(
                            appState.currentUser,
                            Permissions.DASHBOARD_GET,
                        );
                    },
                },
            ],
        },
        {
            label: userService.hasAnyPermission(appState.currentUser, [
                Permissions.FIRST_DATE_GET_ALL,
                Permissions.FIRST_DATE_CREATE,
                Permissions.FIRST_DATE_GET_ALL_UNASSIGNED,
            ])
                ? t('First Dates')
                : '',
            items: [
                {
                    label: <b>{t('Meine First Dates')}</b>,
                    icon: mdiStarShootingOutline,
                    to: '/first-dates',
                    visible: () => {
                        return userService.hasPermission(
                            appState.currentUser,
                            Permissions.FIRST_DATE_GET_ALL,
                        );
                    },
                },
                {
                    label: t('Add new first date'),
                    icon: mdiPlus,
                    to: '',
                    visible: () => {
                        return userService.hasPermission(
                            appState.currentUser,
                            Permissions.FIRST_DATE_CREATE,
                        );
                    },
                },
                // {
                //     label: t('Matching agent'),
                //     icon: mdiArrowCollapse,
                //     to: '/matching',
                //     visible: () => {
                //         return userService.hasPermission(
                //             appState.currentUser,
                //             Permissions.FIRST_DATE_GET_ALL_UNASSIGNED,
                //         );
                //     },
                // },
            ],
        },
        {
            label: userService.hasAnyPermission(appState.currentUser, [
                Permissions.CONTACT_GET_ALL,
                Permissions.CONTACT_CREATE,
            ])
                ? t('Verkäufer')
                : '',
            items: [
                {
                    label: <b>{t('Meine Verkäufer')}</b>,
                    icon: mdiContactsOutline,
                    to: '/contacts',
                    visible: () => {
                        return userService.hasPermission(
                            appState.currentUser,
                            Permissions.CONTACT_GET_ALL,
                        );
                    },
                },
                {
                    label: t('Add new seller'),
                    icon: mdiPlus,
                    to: '',
                    visible: () => {
                        return userService.hasPermission(
                            appState.currentUser,
                            Permissions.CONTACT_CREATE,
                        );
                    },
                },
            ],
        },
        {
            label: userService.hasAnyPermission(appState.currentUser, [
                Permissions.PROPERTY_CREATE,
                Permissions.PROPERTY_GET_ALL,
            ])
                ? t('Objects')
                : '',
            items: [
                {
                    label: <b>{t('Meine Objekte')}</b>,
                    icon: mdiHomeCityOutline,
                    to: '/objects',
                    visible: () => {
                        return userService.hasPermission(
                            appState.currentUser,
                            Permissions.PROPERTY_GET_ALL,
                        );
                    },
                },
                {
                    label: t(' Objekt erfassen'),
                    icon: mdiPlus,
                    to: '/objects/new',
                    visible: () => {
                        return userService.hasPermission(
                            appState.currentUser,
                            Permissions.PROPERTY_CREATE,
                        );
                    },
                },
            ],
        },
        {
            label: userService.hasAnyPermission(appState.currentUser, [
                Permissions.INQUIRY_GET_ALL,
                Permissions.INQUIRY_CREATE,
            ])
                ? t('Interessenten')
                : '',
            items: [
                {
                    label: <b>{t('Meine Interessenten')}</b>,
                    icon: mdiCommentPlusOutline,
                    to: '/inquiries',
                    visible: () => {
                        return userService.hasPermission(
                            appState.currentUser,
                            Permissions.INQUIRY_GET_ALL,
                        );
                    },
                },
                {
                    label: t('Add new Inquiry'),
                    icon: mdiPlus,
                    to: '',
                    visible: () => {
                        return userService.hasPermission(
                            appState.currentUser,
                            Permissions.INQUIRY_CREATE,
                        );
                    },
                },
                {
                    label: t('Import Inquiry'),
                    icon: mdiUpload,
                    to: '',
                    visible: () => {
                        return userService.hasPermission(
                            appState.currentUser,
                            Permissions.USER_CREATE,
                        );
                    },
                },
            ],
        },
        // {
        //     label: userService.hasAnyPermission(appState.currentUser, [
        //         Permissions.REPORT_GET,
        //         Permissions.STATISTICS_GET,
        //     ])
        //         ? t('Statistik')
        //         : '',
        //     items: [
        //         {
        //             label: <b>{t('Reports')}</b>,
        //             icon: mdiSignalCellularOutline,
        //             to: '/reports',
        //             visible: () => {
        //                 return userService.hasPermission(
        //                     appState.currentUser,
        //                     Permissions.REPORT_GET,
        //                 );
        //             },
        //         },
        //         {
        //             label: t('Statistics'),
        //             icon: mdiChartLine,
        //             to: '/statistics',
        //             visible: () => {
        //                 return userService.hasPermission(
        //                     appState.currentUser,
        //                     Permissions.STATISTICS_GET,
        //                 );
        //             },
        //         },
        //     ],
        // },
        {
            label: userService.hasAnyPermission(appState.currentUser, [
                Permissions.CALENDAR_GET_ALL,
                Permissions.CALENDAR_GET_ALL_BY_USER,
            ])
                ? t('Kalender')
                : '',
            items: [
                {
                    label: <b>{t('Calendar')}</b>,
                    icon: mdiCalendarMonth,
                    to: '/calendar',
                    visible: () => {
                        return userService.hasAnyPermission(
                            appState.currentUser,
                            [
                                Permissions.CALENDAR_GET_ALL,
                                Permissions.CALENDAR_GET_ALL_BY_USER,
                            ],
                        );
                    },
                },
            ],
        },
        {
            label: userService.hasPermission(
                appState.currentUser,
                Permissions.USER_GET_LIST,
            )
                ? t('Users')
                : '',
            items: [
                {
                    label: <b>{t('Users')}</b>,
                    icon: mdiPlus,
                    to: '/users',
                    visible: () => {
                        return userService.hasPermission(
                            appState.currentUser,
                            Permissions.USER_GET_LIST,
                        );
                    },
                },
            ],
        },
        // { separator: true },
        // {
        //     label: t('Contacts'),
        //     items: [
        //         { label: 'New contact', to: '/contacts/new' },
        //     ],
        // },
    ];

    const routers = [
        {
            private: true,
            path: '/',
            component: Dashboard,
            exact: true,
            meta: {
                breadcrumb: [{ parent: 'Dashboard', label: t('Dashboard') }],
            },
        },
        {
            private: true,
            path: '/failed-exports',
            component: FailedExportsPage,
            exact: true,
            meta: {
                breadcrumb: [
                    { parent: 'Failed Exports', label: t('Failed Exports') },
                ],
            },
        },
        {
            private: true,
            path: '/objects',
            component: Objects,
            exact: true,
            meta: {
                breadcrumb: [{ parent: 'Objects', label: t('Objects') }],
            },
        },
        {
            private: true,
            path: '/objects/new',
            component: NewObject,
            exact: true,
            meta: {
                breadcrumb: [{ parent: 'Objects', label: t('New Object') }],
            },
        },
        {
            private: true,
            path: '/first-dates/:id',
            component: Inquiry,
            exact: true,
            meta: {
                breadcrumb: [
                    { parent: 'Inquiry', label: t('Verkaufer Kommunikation') },
                ],
            },
        },
        {
            private: true,
            path: '/first-dates',
            component: FirstDatePage,
            exact: true,
            meta: {
                breadcrumb: [{ parent: 'Pages', label: t('First Dates') }],
            },
        },
        {
            private: true,
            path: '/matching',
            component: NewFirstDatePage,
            exact: true,
            meta: {
                breadcrumb: [{ parent: 'Pages', label: t('Matching agent') }],
            },
        },
        {
            private: true,
            path: '/agent/:id',
            component: AgentAssignmentPage,
            exact: true,
            meta: {
                breadcrumb: [
                    { parent: 'Pages', label: t('First date - dodela agentu') },
                ],
            },
        },
        {
            private: true,
            path: '/statistics',
            component: MyStatisticsPage,
            exact: true,
            meta: {
                breadcrumb: [{ parent: 'Pages', label: t('Statistics') }],
            },
        },

        {
            private: true,
            path: '/empty',
            component: EmptyPage,
            meta: {
                breadcrumb: [{ parent: 'Pages', label: t('Empty Page') }],
            },
        },
        {
            private: true,
            path: '/contacts',
            component: ContactsPage,
            exact: true,
            meta: {
                breadcrumb: [{ parent: 'Pages', label: t('Verkäufer') }],
            },
        },
        {
            private: true,
            path: '/contacts/new',
            component: NewContact,
            exact: true,
            meta: {
                breadcrumb: [{ parent: 'Contacts', label: t('New Contact') }],
            },
        },
        {
            private: true,
            path: '/contact/:id',
            component: ViewContact,
            exact: true,
            meta: {
                breadcrumb: [{ parent: 'Contacts', label: t('Contact view') }],
            },
        },
        {
            private: true,
            path: '/users',
            component: UsersPage,
            exact: true,
            meta: { breadcrumb: [{ parent: 'Pages', label: t('Users') }] },
        },
        {
            private: true,
            path: '/reports',
            component: ReportPage,
            exact: true,
            meta: {
                breadcrumb: [{ parent: 'Objects', label: t('Reports') }],
            },
        },
        {
            // inquiries
            private: true,
            path: '/inquiries',
            exact: true,
            component: MeineInteresentPage,
            meta: {
                breadcrumb: [{ parent: 'Pages', label: t('Inquiries') }],
            },
        },
        {
            private: true,
            path: '/calendar',
            exact: true,
            component: CalendarPage,
            meta: {
                breadcrumb: [{ parent: 'Pages', label: t('Calendar') }],
            },
        },
        {
            private: true,
            path: '/inquiries/:id',
            component: InteressentCommunication,
            exact: true,
            meta: {
                breadcrumb: [
                    {
                        parent: 'meinInteresentPage',
                        label: t('Interessent Communication'),
                    },
                ],
            },
        },
        {
            private: true,
            path: '/object/:id',
            component: ViewObject,
            exact: true,
            meta: {
                breadcrumb: [
                    {
                        parent: 'Objects',
                        label: t(
                            ` ${appState.singleView.id} ${appState.singleView.street}`,
                        ),
                    },
                ],
            },
        },
    ];

    useEffect(() => {
        if (staticMenuMobileActive) {
            blockBodyScroll();
        } else {
            unblockBodyScroll();
        }
    }, [staticMenuMobileActive]);

    useEffect(() => {
        changeStyleSheetUrl('layout-css', 'layout-' + colorScheme + '.css', 1);
        changeStyleSheetUrl('theme-css', 'theme-' + colorScheme + '.css', 1);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        {
            history.listen(() => {
                return tokenCheck();
            });
        }
    }, []);

    /*    const onInputStyleChange = (inputStyle) => {
        setInputStyle(inputStyle);
    };
*/
    /*    const changeMenuTheme = (name, logoColor, componentTheme) => {
        onMenuThemeChange(name);
        changeStyleSheetUrl('theme-css', componentTheme, 2);
        setComponentTheme(componentTheme);

        const appLogoLink = document.getElementById('app-logo');
        const appLogoUrl = `../public/assets/layout/images/logo-${
            logoColor === 'dark' ? 'dark' : 'white'
        }.svg`;

        if (appLogoLink) {
            //@ts-ignore
            appLogoLink.src = appLogoUrl;
        }
        setLogoColor(logoColor);
    };
*/
    /*
    const changeComponentTheme = (theme) => {
        setComponentTheme(theme);
        changeStyleSheetUrl('theme-css', theme, 3);
    };
*/
    /*
    const changeColorScheme = (e) => {
        setColorScheme(e.value);

        const scheme = e.value;
        changeStyleSheetUrl('layout-css', 'layout-' + scheme + '.css', 1);
        changeStyleSheetUrl('theme-css', 'theme-' + scheme + '.css', 1);
        changeLogo(scheme);
    };
*/
    const handleShowModal = () => {
        setNewInquiry(true);
        setShowFirstDateModal(!showFirstDateModal, (state) => {
            if (!state) {
                DomHandler.removeClass(document.body, 'p-overflow-hidden');
            }
        });
    };
    const handleShowInteressentModal = () => {
        setNewInquiry(true);
        setShowInteressentModal(!showInteressentModal, (state) => {
            if (!state) {
                DomHandler.removeClass(document.body, 'p-overflow-hidden');
            }
        });
    };
    const handleShowContactModal = () => {
        setNewInquiry(true);
        setShowContactModal(!showContactModal, (state) => {
            if (!state) {
                DomHandler.removeClass(document.body, 'p-overflow-hidden');
            }
        });
    };

    const changeStyleSheetUrl = (id, value, from) => {
        const element = document.getElementById(id);
        const urlTokens = element
            ? element.getAttribute('href').split('/')
            : [];

        if (from === 1) {
            // which function invoked this function
            urlTokens[urlTokens.length - 1] = value;
        } else if (from === 2) {
            // which function invoked this function
            if (value !== null) {
                urlTokens[urlTokens.length - 2] = value;
            }
        } else if (from === 3) {
            // which function invoked this function
            urlTokens[urlTokens.length - 2] = value;
        }

        const newURL = urlTokens.join('/');

        if (element) replaceLink(element, newURL);
    };
    /*
    const changeLogo = (scheme) => {
        const appLogoLink = document.getElementById('app-logo');
        const mobileLogoLink = document.getElementById('logo-mobile');
        const invoiceLogoLink = document.getElementById('invoice-logo');
        const footerLogoLink = document.getElementById('footer-logo');
        setLogoUrl(
            `../public/assets/layout/images/logo-${
                scheme === 'light' ? 'dark' : 'white'
            }.svg`,
        );

        if (appLogoLink) {
            //@ts-ignore
            appLogoLink.src = `../public/assets/layout/images/logo-${
                scheme === 'light' ? logoColor : 'white'
            }.svg`;
        }

        if (mobileLogoLink) {
            //@ts-ignore
            mobileLogoLink.src = logoUrl;
        }

        if (invoiceLogoLink) {
            //@ts-ignore
            invoiceLogoLink.src = logoUrl;
        }

        if (footerLogoLink) {
            //@ts-ignore
            footerLogoLink.src = logoUrl;
        }
    };
*/
    const replaceLink = (linkElement, href) => {
        if (isIE()) {
            linkElement.setAttribute('href', href);
        } else {
            const id = linkElement.getAttribute('id');
            const cloneLinkElement = linkElement.cloneNode(true);

            cloneLinkElement.setAttribute('href', href);
            cloneLinkElement.setAttribute('id', id + '-clone');

            linkElement.parentNode.insertBefore(
                cloneLinkElement,
                linkElement.nextSibling,
            );

            cloneLinkElement.addEventListener('load', () => {
                linkElement.remove();
                cloneLinkElement.setAttribute('id', id);
            });
        }
    };

    const isIE = () => {
        return /(MSIE|Trident\/|Edge\/)/i.test(window.navigator.userAgent);
    };

    const onRippleChange = (e) => {
        PrimeReact.ripple = e.value;
        setRipple(e.value);
    };

    const onDocumentClick = () => {
        if (!menuClick) {
            if (overlayMenuActive || staticMenuMobileActive) {
                hideOverlayMenu();
            }

            unblockBodyScroll();
        }

        if (configActive && !configClick) {
            setConfigActive(false);
        }

        configClick = false;
        menuClick = false;
    };

    /*
    const onMenuThemeChange = (name) => {
        setMenuTheme('layout-sidebar-' + name);
    };
*/
    /*
    const onMenuModeChange = (e) => {
        setMenuMode(e.value);
        if (e.value === 'static') {
            setStaticMenuDesktopInactive(false);
        }
    };
*/
    /*
    const onConfigClick = () => {
        configClick = true;
    };
*/
    /*
    const onConfigButtonClick = () => {
        setConfigActive((prevConfigActive) => !prevConfigActive);
        configClick = true;
    };
*/
    const hideOverlayMenu = () => {
        setOverlayMenuActive(false);
        setStaticMenuMobileActive(false);
        unblockBodyScroll();
    };

    const blockBodyScroll = () => {
        if (document.body.classList) {
            document.body.classList.add('blocked-scroll');
        } else {
            document.body.className += ' blocked-scroll';
        }
    };

    const onSelectAgents = () => {
        setTopbarUserMenuActive(
            (prevTopbarUserMenuActive) => !prevTopbarUserMenuActive,
        );
    };

    const unblockBodyScroll = () => {
        if (document.body.classList) {
            document.body.classList.remove('blocked-scroll');
        } else {
            document.body.className = document.body.className.replace(
                new RegExp(
                    '(^|\\b)' +
                        'blocked-scroll'.split(' ').join('|') +
                        '(\\b|$)',
                    'gi',
                ),
                ' ',
            );
        }
    };

    async function importInquiry(e) {
        const files = e.target.files;
        if (files.length <= 0) return;

        const response = await inquiriesService.import(files);
    }

    /*
    const isSlim = () => {
        return menuMode === 'slim';
    };

    const isOverlay = () => {
        return menuMode === 'overlay';
    };

    const isDesktop = () => {
        return window.innerWidth > 991;
    };
*/
    const containerClassName = classNames(
        'layout-wrapper',
        {
            'layout-overlay': menuMode === 'overlay',
            'layout-static': menuMode === 'static',
            'layout-slim': menuMode === 'slim',
            'layout-sidebar-dim': colorScheme === 'dim',
            'layout-sidebar-dark': colorScheme === 'dark',
            'layout-overlay-active': overlayMenuActive,
            'layout-mobile-active': staticMenuMobileActive,
            //'layout-static-inactive':
            //    staticMenuDesktopInactive && menuMode === 'static',
            'p-input-filled': inputStyle === 'filled',
            'p-ripple-disabled': !ripple,
        },
        colorScheme === 'light' ? menuTheme : '',
    );

    const inquiryImportInputRef = useRef<HTMLInputElement>();

    const onMenuitemClick = (event) => {
        if (event.item.label === t('Add new seller')) {
            return handleShowContactModal();
        }
        if (event.item.label === t('Add new first date')) {
            return handleShowModal();
        }
        if (event.item.label === t('Add new Inquiry')) {
            return handleShowInteressentModal();
        }
        if (event.item.label === t('Import Inquiry')) {
            if (inquiryImportInputRef.current) {
                inquiryImportInputRef.current.click();
            }
        }
        if (!event.item.items) {
            hideOverlayMenu();
        }
    };

    return (
        <>
            <input
                ref={inquiryImportInputRef}
                style={{ display: 'none' }}
                onChange={importInquiry}
                type="file"
                id="upload-input"
                accept=".xml"
                multiple={true}
            />
            <Toast ref={toast} position={'top-right'} />
            {showContactModal ? (
                <NewContactModal
                    getSellerId={(el) => {
                        return;
                    }}
                    contactRole={'Seller'}
                    show={handleShowContactModal}
                    toasts={toast}
                />
            ) : null}
            {showFirstDateModal && (
                <FirstDateFormModal toast={toast} showModal={handleShowModal} />
            )}
            {showInteressentModal ? (
                <NewInquiryFormModal
                    toast={toast}
                    showModal={handleShowInteressentModal}
                />
            ) : null}
            {/* {redirectDefault()} */}
            {routers.map((router, index) => {
                const RouteComponent = router.private ? PrivateRoute : Route;
                return (
                    <RouteComponent
                        key={`router${index}`}
                        path={router.path}
                        exact
                    >
                        <div
                            className={containerClassName}
                            data-theme={colorScheme}
                            onClick={onDocumentClick}
                        >
                            <div className="layout-content-wrapper">
                                <AppTopBar
                                    style={'z-index: 100; position: relative;'}
                                    currentRouter={router}
                                    routers={routers}
                                    onTopbarUserMenu={onSelectAgents}
                                    model={menu}
                                    menuMode={menuMode}
                                    onMenuitemClick={onMenuitemClick}
                                    componentContent={
                                        <div className="layout-content">
                                            <router.component></router.component>
                                        </div>
                                    }
                                ></AppTopBar>
                                {/*<div className="layout-content">
                                    <router.component></router.component>
                                </div>*/}
                            </div>
                            {/*<AppMenu
                                model={menu}
                                menuMode={menuMode}
                                active={menuActive}
                                mobileMenuActive={staticMenuMobileActive}
                                onMenuClick={onMenuClick}
                                onMenuitemClick={onMenuitemClick}
                                onRootMenuitemClick={onRootMenuitemClick}
                            ></AppMenu>*/}
                            {/*<AppConfig
                                configActive={configActive}
                                menuMode={menuMode}
                                onMenuModeChange={onMenuModeChange}
                                colorScheme={colorScheme}
                                changeColorScheme={changeColorScheme}
                                menuTheme={menuTheme}
                                changeMenuTheme={changeMenuTheme}
                                componentTheme={componentTheme}
                                changeComponentTheme={changeComponentTheme}
                                onConfigClick={onConfigClick}
                                onConfigButtonClick={onConfigButtonClick}
                                rippleActive={ripple}
                                onRippleChange={onRippleChange}
                                inputStyle={inputStyle}
                                onInputStyleChange={onInputStyleChange}
                        ></AppConfig>*/}
                            <div className="layout-mask modal-in"></div>
                        </div>
                    </RouteComponent>
                );
            })}
        </>
    );
};

export default withRouter(App);

import { Button } from 'primereact/button';
import { Card } from 'primereact/card';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dropdown } from 'primereact/dropdown';
import { ProgressSpinner } from 'primereact/progressspinner';
import React, { useState, useEffect } from 'react';
import { ActionMenu } from '../../components/actionMenu/ActionMenu';
import ObjectStatusBadge from '../../components/objects/objectStatusBadge/ObjectStatusBadge';
import { propertiesService } from '../../services/PropertiesService';
import './Objects.scss';
import { Link, useHistory } from 'react-router-dom';
import { userService } from '../../services/UsersService';
import { useTranslation } from 'react-i18next';
import { filesService } from '../../services/FilesService';
import dateFormat from 'dateformat';
import { Toast } from 'primereact/toast';
import noCoverPhoto from '../../../public/assets/images/no-cover-photo.png';
import { useApplicationContext } from '../../application/ApplicationContext';
import { Permissions } from '../../core/auth/Permissions';
import { useQuery } from '../../hooks/QueryHook';
import { Checkbox } from 'primereact/checkbox';
import { useLocation, withRouter } from 'react-router-dom';

export const Objects = () => {
    const [loading, setLoading] = useState(false);
    const [properties, setProperties] = useState([]);
    const [status, setStatus] = useState(0);
    const { t } = useTranslation();
    const toast = React.useRef(null);
    const [clickedStatus, setClickedStatus] = useState('');
    const { appState, setSingleViewInformation } = useApplicationContext();
    const query = useQuery();
    const location = useLocation();

    //// FILTER BAYER OBJECT

    let propertyIds: any = location.state;

    const filterBayerInq = properties?.filter(function (o1: any) {
        return propertyIds?.some(function (o2: any) {
            return o1.rootId == o2.propertyId;
        });
    });

    useEffect(() => {
        setLoading(true);
    }, []);

    //TEAM VIEW
    useEffect(() => {
        let isMounted = true;
        userService.refreshTokenCheck().then(() => {
            if (isMounted) {
                return fetchProperties(appState.teamView).then((result) => {
                    setProperties(result);
                    setLoading(false);
                });
            }
        });
        return () => {
            isMounted = false;
        };
    }, [status, appState.teamView]);

    //TEAM VIEW
    const fetchProperties = async (show) => {
        const response = await propertiesService.getMany(show);
        return response.data;
    };

    const statusBodyTemplate = (rowData) => {
        return (
            <ObjectStatusBadge
                type="new-table-with-text"
                status={`${t(rowData.status)}`}
            />
        );
    };

    const imageBodyTemplate = (rowData) => {
        return (
            <img
                title={rowData.id}
                className="cover-photo"
                alt={`cover-${rowData.rootId}-${rowData.id}`}
                src={
                    rowData.coverPhoto === null
                        ? noCoverPhoto
                        : filesService.getFilePath(rowData.coverPhoto.id)
                }
            />
        );
    };

    const handlerActiveStatus = (status) => {
        setClickedStatus(clickedStatus === status ? '' : status);
    };

    const history = useHistory();
    const viewAction = (rowData) => {
        history.push({
            pathname: `/object/${rowData.rootId}`,
            state: {
                data: properties.filter((el) => {
                    if (el.rootId === rowData.rootId) {
                        setSingleViewInformation(
                            rowData.rootId,
                            rowData.street,
                        );

                        return el;
                    }
                }),
            },
        });
    };

    const showAgent = (rowData) => {
        if (!rowData.employee) {
            return <div title={'NOT AVAILABLE'}>N/A</div>;
        }
        const fullName = rowData.employee?.name.split(' ');

        const initials =
            fullName.length > 1
                ? fullName?.shift().charAt(0) +
                  '.' +
                  fullName?.pop().charAt(0) +
                  '.'
                : rowData.employee?.name.charAt(0) + '.';
        return (
            <div title={rowData.employee?.name}>{initials.toUpperCase()}</div>
        );
    };

    const deleteActionBodyTemplate = (rowData) => {
        return (
            <Button
                type="button"
                icon="pi pi-trash"
                className="delete-action-button"
                onClick={() => {
                    propertiesService
                        .deleteOne(rowData.rootId)
                        .then((response) => setStatus(response.status))
                        .catch((err) => setStatus(err.response.status));
                }}
            />
        );
    };

    useEffect(() => {
        if (status === 200) {
            toast.current.show({
                severity: 'success',
                detail: t(`Property has been deleted successfully`),
                life: 3000,
            });
            setStatus(0);
        }
        if (status === 500) {
            toast.current.show({
                severity: 'error',
                detail: t(`internal server error`),
                life: 3000,
            });
            setStatus(0);
        }
    }, [status]);

    const statusPotentiell =
        properties.filter((el) => el.status === 'Potentiell') ?? [];

    const statusInVorbereitung =
        properties.filter((el) => el.status === 'In Vorbereitung') ?? [];

    const statusAktiv = properties.filter((el) => el.status === 'Aktiv') ?? [];

    const statusInaktiv =
        properties.filter((el) => el.status === 'Inactive') ?? [];

    const statusVerkauft =
        properties.filter((el) => el.status === 'VerkaufGH') ?? [];

    const statusAbgeschlossen =
        properties.filter((el) => el.status === 'Abgeschlossen') ?? [];

    const statusMandatNichtErhalten =
        properties.filter((el) => el.status === 'Mandat nicht erhalten') ?? [];

    const selectedUsers = (rowData) => {
        return <Checkbox checked={rowData.readyForExport ? true : false} />;
    };

    const priceColumn = (rowData) => {
        return rowData?.price?.netPurchasePrice
            ? `${rowData?.price?.netPurchasePrice?.toLocaleString()} EUR`
            : '';
    };

    const handleAdressFormat = (rowData) => {
        let objectAddressText = `ID ${rowData.rootId}, ${rowData.place} ${rowData.plz}, ${rowData.street} ${rowData.houseNumber}.`;

        return objectAddressText;
        // return `${rowData.street.slice(0, 8)} ${
        //     rowData.street.toString().length > 8 ? '...' : ''
        // }`;
    };

    const dateFormatCreatedAt = (rowData) => {
        return dateFormat(rowData.createdAt, 'dd.mm.yyyy.');
    };

    const dateFormatUpdatedAt = (rowData) => {
        return dateFormat(rowData.updatedAt, 'dd.mm.yyyy.');
    };

    const dateFormatExportedAt = (rowData) => {
        return dateFormat(rowData.exportedAt, 'dd.mm.yyyy.');
    };

    const agreementColumn = (rowData) => {
        return t(rowData.agreement);
    };

    const dateFormatAgreementMadeAt = (rowData) => {
        if (!rowData.firstDate) return 'n/a';
        if (rowData.firstDate.firstDateActivities.length <= 0) return 'n/a';

        const oldestObject = rowData.firstDate.firstDateActivities.reduce(
            (oldest, current) => {
                if (
                    current.type === 'Status Änderung' &&
                    current.systemComment.includes('Mandat erhalten') &&
                    (oldest === null ||
                        new Date(current.createdAt) <
                            new Date(oldest.createdAt))
                ) {
                    return current;
                }
                return oldest;
            },
            null,
        );

        return oldestObject === null
            ? 'n/a'
            : dateFormat(oldestObject.date, 'dd.mm.yyyy.');
    };

    if (loading) {
        return (
            <div className="center-spinner">
                <ProgressSpinner strokeWidth="5" animationDuration="1.5s" />
            </div>
        );
    } else {
        return (
            <div>
                <Toast ref={toast} position={'top-right'} />
                {/* <ActionMenu
                    menu={[
                        {
                            icon: 'pi pi-envelope',
                            label: t('Angebot'),
                            command: () => {}, // TODO: implement
                        },
                        {
                            icon: 'pi pi-check-square',
                            label: t('Aktivität'),
                            command: () => {}, // TODO: implement
                        },
                        {
                            icon: 'pi pi-plus',
                            label: t('Aufgabe'),
                            command: () => {}, // TODO: implement
                        },
                        {
                            icon: 'pi pi-clone',
                            label: t('Objekt duplizieren'),
                            command: () => {}, // TODO: implement
                        },
                        {
                            icon: 'pi pi-briefcase',
                            label: t('Abg. Nachweis'),
                            command: () => {}, // TODO: implement
                        },
                        {
                            icon: 'pi pi-print',
                            label: t('Drucken'),
                            command: () => {}, // TODO: implement
                        },
                    ]}
                    menuEnd={null}
                /> */}
                <div className="objects-wrapper">
                    <Card style={{ marginBottom: '20px' }}>
                        <div className="badge-legend-container">
                            <div
                                className={`status-button ${
                                    clickedStatus === 'Potentiell'
                                        ? 'cover'
                                        : ''
                                }`}
                                onClick={() =>
                                    handlerActiveStatus('Potentiell')
                                }
                            >
                                <ObjectStatusBadge
                                    type="legend"
                                    status={t('Potentiell')}
                                >
                                    {statusPotentiell.length.toString()}
                                </ObjectStatusBadge>
                            </div>
                            <div
                                className={`status-button ${
                                    clickedStatus === 'In Vorbereitung'
                                        ? 'cover'
                                        : ''
                                }`}
                                onClick={() =>
                                    handlerActiveStatus('In Vorbereitung')
                                }
                            >
                                <ObjectStatusBadge
                                    type="legend"
                                    status={t('In Vorbereitung')}
                                >
                                    {statusInVorbereitung.length.toString()}
                                </ObjectStatusBadge>
                            </div>
                            <div
                                className={`status-button ${
                                    clickedStatus === 'Aktiv' ? 'cover' : ''
                                }`}
                                onClick={() => handlerActiveStatus('Aktiv')}
                            >
                                <ObjectStatusBadge
                                    type="legend"
                                    status={t('Aktiv')}
                                >
                                    {statusAktiv.length.toString()}
                                </ObjectStatusBadge>
                            </div>
                            <div
                                className={`status-button ${
                                    clickedStatus === 'Inactive' ? 'cover' : ''
                                }`}
                                onClick={() => handlerActiveStatus('Inactive')}
                            >
                                <ObjectStatusBadge
                                    type="legend"
                                    status={t('Inactive')}
                                >
                                    {statusInaktiv.length.toString()}
                                </ObjectStatusBadge>
                            </div>
                            <div
                                className={`status-button ${
                                    clickedStatus === 'VerkaufGH'
                                        ? 'changed-cover'
                                        : ''
                                }`}
                                onClick={() => handlerActiveStatus('VerkaufGH')}
                            >
                                <ObjectStatusBadge
                                    type="legend"
                                    status={t('VerkaufGH')}
                                >
                                    {statusVerkauft.length.toString()}
                                </ObjectStatusBadge>
                            </div>
                            <div
                                className={`status-button ${
                                    clickedStatus === 'Abgeschlossen'
                                        ? 'cover'
                                        : ''
                                }`}
                                onClick={() =>
                                    handlerActiveStatus('Abgeschlossen')
                                }
                            >
                                <ObjectStatusBadge
                                    type="legend"
                                    status={t('Abgeschlossen')}
                                >
                                    {statusAbgeschlossen.length.toString()}
                                </ObjectStatusBadge>
                            </div>
                            <div
                                className={`status-button ${
                                    clickedStatus === 'Mandat nicht erhalten'
                                        ? 'cover'
                                        : ''
                                }`}
                                onClick={() =>
                                    handlerActiveStatus('Mandat nicht erhalten')
                                }
                            >
                                <ObjectStatusBadge
                                    type="legend-wide"
                                    status={t('Mandat nicht erhalten')}
                                >
                                    {statusMandatNichtErhalten.length.toString()}
                                </ObjectStatusBadge>
                            </div>
                            {clickedStatus !== '' && (
                                <button
                                    onClick={() => setClickedStatus('')}
                                    className="reset-status"
                                >
                                    <i
                                        className="pi pi-refresh"
                                        style={{ fontSize: '1em' }}
                                    ></i>
                                </button>
                            )}
                        </div>
                    </Card>

                    <Card className="no-padding">
                        {/* <div className="filter-container">
                            <Dropdown
                                className="filter-item"
                                placeholder={t('Under development')}
                            />
                            <Dropdown
                                className="filter-item"
                                placeholder={t('Under development')}
                            />
                            <Dropdown
                                className="filter-item"
                                placeholder={t('Under development')}
                            />
                        </div>
                        <div className="filter-container">
                            <Dropdown
                                className="filter-item"
                                placeholder={t('Under development')}
                            />
                            <Dropdown
                                className="filter-item"
                                placeholder={t('Under development')}
                            />
                            <Dropdown
                                className="filter-item"
                                placeholder={t('Under development')}
                            />
                        </div> */}
                        <div className="objects-datatable">
                            <DataTable
                                value={
                                    location.state
                                        ? filterBayerInq
                                        : properties.filter((el) => {
                                              if (query.has('buyerId')) {
                                                  return (
                                                      el.inquiries
                                                          .map((x) => x.id)
                                                          .includes(
                                                              parseInt(
                                                                  query.get(
                                                                      'buyerId',
                                                                  ),
                                                              ),
                                                          ) &&
                                                      (clickedStatus !== ''
                                                          ? el.status ===
                                                            clickedStatus
                                                          : true)
                                                  );
                                              }

                                              if (query.has('sellerId')) {
                                                  return (
                                                      el.seller.id ==
                                                          query.get(
                                                              'sellerId',
                                                          ) &&
                                                      (clickedStatus !== ''
                                                          ? el.status ===
                                                            clickedStatus
                                                          : true)
                                                  );
                                              }

                                              if (clickedStatus !== '') {
                                                  return (
                                                      el.status ===
                                                      clickedStatus
                                                  );
                                              }

                                              return true;
                                          })
                                }
                                className="p-datatable-lg"
                                paginator={true}
                                paginatorPosition="bottom"
                                paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                                currentPageReportTemplate={`{first} ${t(
                                    'bis',
                                )} {last} ${t('of')} {totalRecords}`}
                                emptyMessage={t('No objects found')}
                                sortField="rootId"
                                sortOrder={-1}
                                rows={50}
                                rowsPerPageOptions={[10, 20, 50, 100]}
                                selectionMode="single"
                                onSelectionChange={(e) => viewAction(e.value)}
                            >
                                <Column
                                    className="column-container"
                                    body={statusBodyTemplate}
                                    header={t('Status')}
                                    field={'status'}
                                />
                                <Column
                                    header={t('Export Freigabe')}
                                    className="column-container"
                                    body={selectedUsers}
                                />
                                <Column
                                    className="column-container"
                                    body={imageBodyTemplate}
                                    header={t('Naslovna slika')}
                                />
                                {/* <Column
                                    sortable
                                    className="column-container-four"
                                    field="rootId"
                                    header={t('ID')}
                                /> */}
                                <Column
                                    className="column-container"
                                    field={`${'category.name'}`}
                                    header={t('Tip Objekta')}
                                />
                                {/* <Column
                                    className="column-container"
                                    field="plz"
                                    header={t('PTT Broj')}
                                /> */}
                                <Column
                                    className="column-container"
                                    body={handleAdressFormat}
                                    header={t('Objektadresse')}
                                    style={{
                                        minWidth: '20rem',
                                    }}
                                />
                                <Column
                                    className="column-container"
                                    body={priceColumn}
                                    header={t('Cena')}
                                />
                                <Column
                                    className="column-container-five"
                                    body={(rowData) =>
                                        `${rowData?.seller?.firstName} ${rowData?.seller?.lastName}`
                                    }
                                    header={t('Prodavac')}
                                />
                                <Column
                                    className="column-container"
                                    body={agreementColumn}
                                    header={t('Vrsta ugovora')}
                                />
                                <Column
                                    className="column-container-third"
                                    body={dateFormatCreatedAt}
                                    header={t('Erstellt am')}
                                />
                                <Column
                                    className="column-container-six"
                                    body={dateFormatAgreementMadeAt}
                                    header={t('Mandat am')}
                                />
                                <Column
                                    className="column-container"
                                    body={dateFormatExportedAt}
                                    header={t('Export am')}
                                />
                                <Column
                                    className="column-container"
                                    body={dateFormatUpdatedAt}
                                    header={t('Aktualisiert am')}
                                />
                                <Column
                                    header={t('Agent')}
                                    body={showAgent}
                                    className="column-container"
                                />
                                {/*<Column*/}
                                {/*    body={deleteActionBodyTemplate}*/}
                                {/*    className="datatable-button-column"*/}
                                {/*    hidden={*/}
                                {/*        !userService.hasPermission(*/}
                                {/*            appState.currentUser,*/}
                                {/*            Permissions.PROPERTY_DELETE,*/}
                                {/*        )*/}
                                {/*    }*/}
                                {/*/>*/}
                            </DataTable>
                        </div>
                    </Card>
                </div>
            </div>
        );
    }
};

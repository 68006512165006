import { Dropdown } from 'primereact/dropdown';
import React, { useEffect, useState } from 'react';
import './NewFirstDatePage.scss';
import { useTranslation } from 'react-i18next';
import { DataTable } from 'primereact/datatable';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { Link, useLocation, useParams } from 'react-router-dom';
import { Card } from 'primereact/card';
import { userService } from '../../services/UsersService';
import { firstDateService } from '../../services/FirstDateService';
import ObjectStatusBadge from '../../components/objects/objectStatusBadge/ObjectStatusBadge';
import { Toast } from 'primereact/toast';
import { ProgressSpinner } from 'primereact/progressspinner';

const NewFirstDatePage = () => {
    const [loading, setLoading] = useState(false);
    const location = useLocation();
    const status: any = location?.state;
    const [data, setData] = useState(undefined);
    const { t } = useTranslation();
    const toast = React.useRef(null);

    useEffect(() => {
        setLoading(true);
    }, []);

    useEffect(() => {
        userService.refreshTokenCheck().then(() => {
            fetchData().then((result) => {
                setData(result);
                setLoading(false);
            });
        });
    }, []);

    useEffect(() => {
        if ((status !== null && status === 200) || status === 201) {
            toast.current.show({
                severity: 'success',
                detail: `First date assignment`,
                life: 3000,
            });
        }
    }, [status]);

    const fetchData = async () => {
        const response = await firstDateService.getUnassignedFirstDates();
        return response.data;
    };

    const paginatorLeft = (
        <Button type="button" icon="pi pi-refresh" className="p-button-text" />
    );
    const paginatorRight = (
        <Button type="button" icon="pi pi-cloud" className="p-button-text" />
    );

    const statusBodyTemplate = (rowData) => {
        const splitStatus = t(rowData.status).split(' ');
        return (
            <ObjectStatusBadge
                type="new-table-with-text"
                status={`${splitStatus.join('-')}`}
            />
        );
    };

    const acquisition = (rowData) => {
        return rowData.acquisition ? 'Yes' : 'No';
    };

    const addNewAgent = (rowData) => {
        return (
            <Link
                to={{
                    pathname: `/agent/${rowData.id}`,
                    state: { data: rowData },
                }}
            >
                <Button
                    type="button"
                    icon="pi pi-user-plus"
                    className="view-action-button"
                />
            </Link>
        );
    };

    if (loading) {
        return (
            <div className="center-spinner">
                <ProgressSpinner strokeWidth="5" animationDuration="1.5s" />
            </div>
        );
    } else {
        return (
            <>
                <Toast ref={toast} position={'top-right'} />
                <Card>
                    <div className="container-first-date-empty">
                        <div className="dropdown-container">
                            <Dropdown className="filter-item" />
                            <Dropdown className="filter-item" />
                            <Dropdown className="filter-item" />
                            <Dropdown
                                className="filter-item"
                                placeholder={t('Filter')}
                            />
                        </div>
                        <DataTable
                            value={data}
                            className="p-datatable-lg"
                            paginator={true}
                            paginatorPosition="bottom"
                            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                            currentPageReportTemplate={`{first} ${t(
                                'bis',
                            )} {last} ${t('of')} {totalRecords}`}
                            rows={20}
                            rowsPerPageOptions={[10, 20, 50]}
                            sortField="id"
                            sortOrder={-1}
                            paginatorLeft={paginatorLeft}
                            paginatorRight={paginatorRight}
                            scrollHeight="700px"
                        >
                            <Column
                                className="column-container-second"
                                body={statusBodyTemplate}
                                header={t('Status')}
                            />
                            <Column
                                sortable
                                className="column-container"
                                field="id"
                                header={t('ID')}
                            />
                            <Column
                                className="column-container"
                                body={acquisition}
                                header={t('Akviza')}
                            />
                            <Column
                                className="column-container"
                                field="seller.firstName"
                                header={t('Ime')}
                            />
                            <Column
                                className="column-container"
                                field="seller.city"
                                header={t('Lokacija')}
                            />
                            <Column
                                className="column-container"
                                field={`property.category.name`}
                                header={t('Tip')}
                            />
                            <Column
                                className="column-container"
                                field={'property.price.netPurchasePrice'}
                                header={t('Cena')}
                            />
                            {/*<Column*/}
                            {/*    body={addNewAgent}*/}
                            {/*    className="datatable-button-column"*/}
                            {/*/>*/}
                        </DataTable>
                    </div>
                </Card>
            </>
        );
    }
};

export default NewFirstDatePage;

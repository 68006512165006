import React from 'react';
import { Badge } from 'primereact/badge';
import './CardHeader.scss';

const CardHeaderWithBadge = (props) => {
    if (props.severity === 'info') {
        return (
            <div className="card-wrapper">
                <div className="card-text">
                    <h5>{props.title}</h5>
                    <h6>{props.status}</h6> 
                </div>
                <div className="card-badge">
                    <Badge className="rounded-badge gray-badge" value={props.value} severity={props.severity} size={props.size} />
                </div>
            </div>
        )
    } else {
        return (
            <div className="card-wrapper">
                <div className="card-text">
                    <h5>{props.title}</h5>
                    <h6>{props.status}</h6> 
                </div>
                <div className="card-badge">
                    <Badge className="rounded-badge" value={props.value} severity={props.severity} size={props.size} />
                </div>
            </div>
        )
    }
}
// 15  warning  xlarge

export default CardHeaderWithBadge;
import React, { Component } from 'react';
import { DomHandler, classNames } from 'primereact/utils';
import { Ripple } from 'primereact/ripple';
import './TabMenu.scss';

import { MenuItem } from 'primereact/menuitem/menuitem';

interface TabMenuTabChangeParams {
    originalEvent: React.SyntheticEvent;
    value: MenuItem;
    index: number;
}

export interface TabMenuProps {
    id?: string;
    model?: MenuItem[];
    activeIndex?: number;
    style?: object;
    className?: string;
    onTabChange?(e: TabMenuTabChangeParams): void;
}

export class TabMenu extends React.Component<TabMenuProps, any> {
    constructor(props) {
        super(props);

        if (!this.props.onTabChange) {
            this.state = {
                activeIndex: props.activeIndex,
                showDropdown: false,
            };
        }
    }

    itemClick(event, item, index) {
        if (index === 5) {
            this.setState((prevState) => ({
                showDropdown: !prevState?.showDropdown,
            }));
        } else {
            this.setState({
                showDropdown: false,
            });
        }

        if (item.disabled) {
            event.preventDefault();
            return;
        }

        if (!item.url) {
            event.preventDefault();
        }

        if (item.command) {
            item.command({
                originalEvent: event,
                item: item,
            });
        }

        if (this.props.onTabChange) {
            this.props.onTabChange({
                originalEvent: event,
                value: item,
                index,
            });
        } else {
            this.setState({
                activeIndex: index,
            });
        }
    }

    getActiveIndex() {
        return this.props.onTabChange
            ? this.props.activeIndex
            : this.state.activeIndex;
    }

    isSelected(index) {
        return index === (this.getActiveIndex() || 0);
    }

    updateInkBar() {
        const activeIndex = this.getActiveIndex();
        const tabHeader = this[`tab_${activeIndex}`];

        // @ts-ignore
        this.inkbar.style.width = DomHandler.getWidth(tabHeader) + 'px';
        // @ts-ignore
        this.inkbar.style.left =
            // @ts-ignore
            DomHandler.getOffset(tabHeader).left -
            // @ts-ignore
            DomHandler.getOffset(this.nav).left +
            'px';
    }

    componentDidMount() {
        this.updateInkBar();
    }

    componentDidUpdate() {
        this.updateInkBar();
    }

    renderMenuItem(item, index) {
        const active = this.isSelected(index);
        const className = classNames(
            'p-tabmenuitem',
            {
                'p-highlight': active,
                'p-disabled': item.disabled,
            },
            item.className,
        );
        const iconClassName = classNames('p-menuitem-icon', item.icon);
        const icon = item.icon && <span className={iconClassName}></span>;
        const label = item.label && (
            <span className="p-menuitem-text">{item.label}</span>
        );
        let content = (
            <a
                href={item.url || '#'}
                className="p-menuitem-link"
                target={item.target}
                onClick={(event) => this.itemClick(event, item, index)}
                role="presentation"
            >
                {label}
                {icon}
                <Ripple />
            </a>
        );

        if (item.template) {
            const defaultContentOptions = {
                onClick: (event) => this.itemClick(event, item, index),
                className: 'p-menuitem-link',
                labelClassName: 'p-menuitem-text',
                iconClassName,
                element: content,
                props: this.props,
                active,
                index,
            };
            // @ts-ignore
            content = ObjectUtils.getJSXElement(
                item.template,
                item,
                defaultContentOptions,
            );
        }

        return (
            <li
                ref={(el) => (this[`tab_${index}`] = el)}
                key={item.label + '_' + index}
                className={className}
                style={item.style}
                role="tab"
                aria-selected={active}
                aria-expanded={active}
                aria-disabled={item.disabled}
            >
                {content}
                <div className="submenu-dropdown">
                    {this.state?.showDropdown
                        ? item?.options?.map((item, i) => {
                              return (
                                  <div className="submenu" key={i}>
                                      <p className="submenu-item">{item}</p>
                                  </div>
                              );
                          })
                        : null}
                </div>
            </li>
        );
    }

    renderItems() {
        return this.props.model.map((item, index) => {
            return this.renderMenuItem(item, index);
        });
    }

    render() {
        if (this.props.model) {
            const className = classNames(
                'p-tabmenu p-component',
                this.props.className,
            );
            const items = this.renderItems();

            return (
                <div
                    id={this.props.id}
                    className={className}
                    style={this.props.style}
                >
                    <ul
                        // @ts-ignore
                        ref={(el) => (this.nav = el)}
                        className="p-tabmenu-nav p-reset"
                        role="tablist"
                    >
                        {items}
                        <li
                            // @ts-ignore
                            ref={(el) => (this.inkbar = el)}
                            className="p-tabmenu-ink-bar"
                        ></li>
                    </ul>
                </div>
            );
        }

        return null;
    }
}

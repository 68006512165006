import { useReducer, useEffect } from 'react';
import constate from 'constate';
import jwtDecode from 'jwt-decode';
import { AxiosError } from 'axios';
import { ApplicationState } from './ApplicationState';
import { ApplicationAction, UPDATE } from './ApplicationActions';
import { Config } from '../Config';
import { userService } from '../services/UsersService';
import { BaseService } from '../services/BaseService';
import { propertiesService } from '../services/PropertiesService';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { metaService } from '../services/MetaService';

// Initial state
const initialState: ApplicationState = {
    authenticated: null,
    authInProgress: false,
    loading: false,
    loadingSpinner: false,
    accessToken: Config.TEST_ACCESS_TOKEN,
    currentUser: null,
    loginAsUsername: null,
    passwordResetInProgress: false,
    passwordResetUsername: undefined,
    newEvent: [],
    singleView: {},
    showNewData: false,
    teamView: false,
    metadata: null,
    duplicateTransferFirstDate: true,
    duplicateTransferInquiries: true,
    deactivateOriginalProperty: true,
    newPropertyToInactive: true,
};

let applicationState = initialState;

// Reducer
const reducer = (
    state: ApplicationState,
    action: ApplicationAction,
): ApplicationState => {
    let newState = state;
    switch (action.type) {
        case UPDATE:
            newState = { ...state, ...action.payload };
    }
    applicationState = newState;
    return newState;
};

// Custom hook with state and functions
const useAplicationContextSpec = () => {
    const [appState, dispatch] = useReducer(reducer, initialState);
    const { t } = useTranslation();

    /**
     * Execute login
     * @param username username
     * @param password password
     */

    const login = async (username: string, password: string) => {
        dispatch({
            type: UPDATE,
            payload: {
                loading: true,
                authInProgress: true,
                authenticationError: undefined,
                //* If the user has previously failed to log in properly
                //* authenticationError should be reseted when he's attempting again
            },
        });

        try {
            const response = await userService.login(username, password);
            const jwtToken = response.data.access_token;

            if (!jwtToken) {
                dispatch({
                    type: UPDATE,
                    payload: {
                        loading: false,
                        authenticated: false,
                        authInProgress: true,
                        authenticationError: undefined,
                    },
                });
            } else {
                const jwtTokenData: any = jwtDecode(jwtToken);
                await authSetup(jwtToken);
                const loggedUser = await userService.getOne(
                    (jwtTokenData as any).userId,
                );
                dispatch({
                    type: UPDATE,
                    payload: {
                        loading: false,
                        authenticated: true,
                        authInProgress: false,
                        authenticationError: undefined,
                        // @ts-ignore
                        tokenExpiration: jwtTokenData.exp * 1000,
                        //@ts-ignore
                        currentUser: loggedUser.data
                            ? loggedUser.data
                            : {
                                  id: jwtTokenData.id,
                                  username: jwtTokenData.email,
                              },
                    },
                });
            }
        } catch (error) {
            console.error(error);
            const err: AxiosError = error;
            if (err.response && err.response.status === 401) {
                dispatch({
                    type: 'UPDATE',
                    payload: {
                        loading: false,
                        authenticated: false,
                        authInProgress: false,
                        tokenExpiration: undefined,
                        authenticationError: t('Username or password is wrong'),
                    },
                });
                return;
            } else {
                dispatch({
                    type: UPDATE,
                    payload: {
                        loading: false,
                        authenticated: false,
                        authInProgress: false,
                        tokenExpiration: undefined,
                        authenticationError: t('Unknown error'),
                    },
                });
            }
        }
    };

    const refreshTokenCheck = async () => {
        try {
            const response = await userService.refreshTokenCheck();
            if (response.status === 200 || response.status === 201) {
                dispatch({
                    type: UPDATE,
                    payload: {
                        accessToken: response.data,
                        loading: true,
                        authenticated: true,
                    },
                });
            }
        } catch (error) {
            const err: AxiosError = error;
            window.location.href = '/';
            dispatch({
                type: UPDATE,
                payload: {
                    loading: false,
                    currentUser: undefined,
                    authenticated: false,
                    authInProgress: false,
                    tokenExpiration: null,
                },
            });
        }
    };

    const getApplicationMetadata = async () => {
        try {
            const response = await metaService.getApplicationMetadata();
            if (response.status === 200) {
                dispatch({
                    type: UPDATE,
                    payload: {
                        metadata: response.data,
                    },
                });
            }
        } catch (error) {
            console.error(error);
        }
    };

    const tokenCheck = async () => {
        try {
            const response = await userService.tokenCheck();
            if (response.status === 200) {
                dispatch({
                    type: UPDATE,
                    payload: {
                        accessToken: response.data,
                        authenticated: true,
                    },
                });
            }
        } catch (error) {
            const err: AxiosError = error;
            if (err.response.status === 401) {
                refreshTokenCheck();
            } else {
                dispatch({
                    type: UPDATE,
                    payload: {
                        loading: false,
                        currentUser: undefined,
                        authenticated: false,
                        authInProgress: false,
                        tokenExpiration: null,
                    },
                });
            }
        }
    };

    const register = async (username: string, password: string) => {
        //TODO register new user service
        await userService.register(username, password);
    };

    const confirmEmail = async (email: string) => {
        //TODO confirm email service
        await userService.confirmEmail(email);
    };

    /**
     * Logout
     */
    const logout = async () => {
        // ? Read the jwt?
        try {
            const response = await userService.logout();
            if (response.data.success === 'true') {
                dispatch({
                    type: 'UPDATE',
                    payload: {
                        currentUser: null,
                        authenticated: false,
                        authInProgress: false,
                        tokenExpiration: null,
                        authenticationError: null,
                    },
                });
            }
            window.location.href = '/';
        } catch (error) {
            console.log(error);
        }
    };

    const setSingleViewInformation = async (id, street) => {
        dispatch({
            type: 'UPDATE',
            payload: {
                singleView: {
                    id: id,
                    street: street,
                },
            },
        });
    };

    const setShowMyTeam = async (show) => {
        dispatch({
            type: 'UPDATE',
            payload: {
                teamView: show,
            },
        });
    };

    const setNewInquiry = async (show) => {
        dispatch({
            type: 'UPDATE',
            payload: {
                showNewData: show,
            },
        });
    };

    const authSetup = async (jwtToken: string) => {
        await BaseService.lookupDataInitialFetch();
        userService.setAccessToken(jwtToken);
        propertiesService.setAccessToken(jwtToken);

        /* jwtToken - test
        {
            "username": "office@normasoft.net",
            "userId": 1,
            "userType": "Admin",
            "iat": 1625165005,
            "exp": 1668365005
        }
        */
        const cookie = document.cookie;
        const jwtTokenData: any = jwtDecode(jwtToken);
        let userId: any = jwtTokenData.userId;
        try {
            const response = await userService.getOne(userId);
            dispatch({
                type: UPDATE,
                payload: {
                    currentUser: response.data,
                    authenticated: true,
                },
            });
        } catch (error) {
            const err: AxiosError = error;

            dispatch({
                type: UPDATE,
                payload: {
                    currentUser: undefined,
                    authenticated: false,
                    authInProgress: false,
                    tokenExpiration: null,
                    authenticationError: null,
                },
            });
        }
    };

    const isAuthenticatedCheck = async () => {
        let cookie = document.cookie;
        let jwtToken = cookie.substring(cookie.indexOf('jwt=') + 4);
        if (cookie.includes('jwt=')) {
            const jwtTokenData: any = jwtDecode(jwtToken);
            await authSetup(jwtToken);
            const loggedUser = await userService.getOne(
                (jwtTokenData as any).userId,
            );
            dispatch({
                type: UPDATE,
                payload: {
                    loading: false,
                    authenticated: true,
                    authInProgress: false,
                    authenticationError: undefined,
                    tokenExpiration: jwtTokenData.exp * 1000,
                    //@ts-ignore
                    currentUser: loggedUser.data
                        ? loggedUser.data
                        : {
                              id: jwtTokenData.id,
                              username: jwtTokenData.email,
                          },
                },
            });
        } else {
            dispatch({
                type: 'UPDATE',
                payload: {
                    authenticated: false,
                },
            });
        }
    };

    const showPasswordReset = () => {
        dispatch({
            type: UPDATE,
            payload: {
                loading: false,
                passwordResetInProgress: true,
                authenticationError: undefined,
            },
        });
    };

    const requestPasswordReset = async (email: string) => {
        if (
            (appState.authenticated || appState.authInProgress) &&
            !appState.passwordResetInProgress
        )
            return;

        dispatch({
            type: 'UPDATE',
            payload: {
                loading: true,
            },
        });

        try {
            const response = await userService.forgotPassword(email);
            const inProgress =
                response.status === 200 || response.status === 201;

            dispatch({
                type: UPDATE,
                payload: {
                    loading: false,
                    passwordResetInProgress: inProgress,
                    passwordResetUsername: inProgress ? email : undefined,
                    authenticationError: inProgress
                        ? undefined
                        : t('Unknown user'),
                },
            });
        } catch (error) {
            const err: AxiosError = error;
            dispatch({
                type: UPDATE,
                payload: {
                    loading: false,
                    passwordResetInProgress: false,
                    passwordResetUsername: undefined,
                    authenticationError: t('Unknown user'),
                },
            });
        }
    };

    const resetPassword = async (token, password, confirmPassword) => {
        // if (!appState.passwordResetInProgress || !appState.passwordResetUsername) return;
        if (appState.passwordResetInProgress || appState.passwordResetUsername)
            return;

        dispatch({
            type: 'UPDATE',
            payload: {
                loading: true,
            },
        });

        try {
            const response = await userService.resetPassword(
                token,
                password,
                confirmPassword,
            );

            dispatch({
                type: UPDATE,
                payload: {
                    loading: false,
                    passwordResetInProgress: false,
                    passwordResetUsername: undefined,
                    authenticationError: undefined,
                },
            });
        } catch (error) {
            const err: AxiosError = error;
            dispatch({
                type: UPDATE,
                payload: {
                    loading: false,
                    authenticationError: t('Greška pri resetu šifre'),
                },
            });
        }
    };

    const addNewFirstDate = async (
        status: string,
        acquisition: boolean,
        appointmentDate: string,
        importantDate: string,
        propertyId: number,
        sellerId: number,
    ) => {
        dispatch({
            type: UPDATE,
            payload: {
                loading: true,
            },
        });

        try {
            const response = await userService.addNewFirstDate(
                status,
                acquisition,
                appointmentDate,
                importantDate,
                propertyId,
                sellerId,
            );

            dispatch({
                type: UPDATE,
                payload: {
                    loading: true,
                },
            });
        } catch (error) {
            const err: AxiosError = error;
            dispatch({
                type: UPDATE,
                payload: {
                    loading: false,
                    // authenticationError: 'Greška pri resetu šifre'
                },
            });
        }
    };

    const addNewUser = async (
        roleId: string,
        name: string,
        email: string,
        password: string,
        phoneNumber: string,
        smtp: {
            smtpUsername: string;
            smtpPassword: string;
            smtpHost: string;
            smtpPort: string;
            smtpEmail: string;
        },
        address: {
            addressStreet: string;
            addressHouseNumber: string;
            addressPostal: string;
            addressCity: string;
            addressCountry: string;
        },
        positionTitle: string,
        active: boolean,
        superiorId?: string,
        teamName?: string,
    ) => {
        dispatch({
            type: UPDATE,
            payload: {
                loading: true,
            },
        });

        try {
            const response = await userService.addNewUser(
                roleId,
                name,
                email,
                password,
                phoneNumber,
                smtp,
                address,
                positionTitle,
                active,
                superiorId,
                teamName,
            );

            dispatch({
                type: UPDATE,
                payload: {
                    loading: true,
                },
            });
        } catch (error) {
            const err: AxiosError = error;
            dispatch({
                type: UPDATE,
                payload: {
                    loading: false,
                    // authenticationError: 'Greška pri resetu šifre'
                },
            });
        }
    };

    const editSingleUser = async (
        roleId: string,
        name: string,
        email: string,
        password: string,
        phoneNumber: string,
        idUser: any,
        smtp: {
            smtpUsername: string;
            smtpPassword: string;
            smtpHost: string;
            smtpPort: string;
            smtpEmail: string;
        },
        address: {
            addressStreet: string;
            addressHouseNumber: string;
            addressPostal: string;
            addressCity: string;
            addressCountry: string;
        },
        positionTitle: string,
        active: boolean,
        superiorId?: string,
        teamName?: string,
    ) => {
        dispatch({
            type: UPDATE,
            payload: {
                loading: true,
            },
        });

        try {
            console.log(
                roleId,
                name,
                email,
                password,
                phoneNumber,
                idUser,
                smtp,
                address,
                positionTitle,
                active,
                superiorId,
                teamName,
            );
            const response = await userService.editSingleUser(
                roleId,
                name,
                email,
                password,
                phoneNumber,
                idUser,
                smtp,
                address,
                positionTitle,
                active,
                superiorId,
                teamName,
            );

            dispatch({
                type: UPDATE,
                payload: {
                    loading: true,
                },
            });
        } catch (error) {
            const err: AxiosError = error;
            dispatch({
                type: UPDATE,
                payload: {
                    loading: false,
                    // authenticationError: 'Greška pri resetu šifre'
                },
            });
        }
    };

    const addNewEvents = async (
        title: string,
        description: string,
        startDateAndTime: any,
        endDateAndTime: any,
        status: string,
        creatorId: number,
        contactId: number,
        propertyId: number,
    ) => {
        dispatch({
            type: UPDATE,
            payload: {
                loading: true,
            },
        });

        try {
            const response = await userService.addNewEvent(
                title,
                description,
                startDateAndTime,
                endDateAndTime,
                status,
                creatorId,
                contactId,
                propertyId,
            );

            dispatch({
                type: UPDATE,
                payload: {
                    newEvent: [...appState.newEvent, response.data],
                },
            });
        } catch (error) {
            const err: AxiosError = error;
            dispatch({
                type: UPDATE,
                payload: {
                    loading: false,
                    // authenticationError: 'Greška pri resetu šifre'
                },
            });
        }
    };

    const deleteCalendarEvent = async (id: number) => {
        dispatch({
            type: UPDATE,
            payload: {
                loading: true,
            },
        });

        try {
            const response = await userService.deleteCalendarEvent(id);
            dispatch({
                type: UPDATE,
                payload: {
                    newEvent: appState.newEvent.filter(
                        (el) => el.id !== response.data.id,
                    ),
                },
            });
        } catch (error) {
            const err: AxiosError = error;
            dispatch({
                type: UPDATE,
                payload: {
                    loading: false,
                },
            });
        }
    };

    const getInitialEvents = async (id: number, show) => {
        dispatch({
            type: UPDATE,
            payload: {
                loading: true,
            },
        });

        try {
            const response = await userService.getInitialEvents(id, show);
            dispatch({
                type: UPDATE,
                payload: {
                    newEvent: response.data.status !== 204 ? response.data : [],
                },
            });
        } catch (error) {
            const err: AxiosError = error;
            dispatch({
                type: UPDATE,
                payload: {
                    loading: false,
                },
            });
        }
    };

    const editNewEvents = async (
        title: string,
        status: string,
        startDateAndTime: string,
        endDateAndTime: string,
        creatorId: number,
        contactId: number,
        propertyId: number,
        description: string,
        id: number,
    ) => {
        dispatch({
            type: UPDATE,
            payload: {
                loading: true,
            },
        });

        try {
            const response = await userService.editNewEvent(
                title,
                status,
                startDateAndTime,
                endDateAndTime,
                creatorId,
                contactId,
                propertyId,
                description,
                id,
            );

            dispatch({
                type: UPDATE,
                payload: {
                    newEvent: appState.newEvent.map((el) =>
                        el.id === response.data.id ? response.data : el,
                    ),
                },
            });
        } catch (error) {
            const err: AxiosError = error;
            dispatch({
                type: UPDATE,
                payload: {
                    loading: false,
                },
            });
        }
    };

    const loginGoBack = () => {
        if (appState.authenticated) return;

        dispatch({
            type: UPDATE,
            payload: {
                loading: false,
                authenticated: false,
                authInProgress: false,
                tokenExpiration: undefined,
                passwordResetInProgress: false,
                passwordResetUsername: undefined,
                authenticationError: undefined,
            },
        });
    };

    const setLoadingSpinner = (state: boolean) => {
        dispatch({
            type: UPDATE,
            payload: {
                loadingSpinner: state,
            },
        });
    };

    const setDuplicateTransferFirstDate = (state: boolean) => {
        dispatch({
            type: UPDATE,
            payload: {
                duplicateTransferFirstDate: state,
            },
        });
    };
    const setDuplicateTransferInquiries = (state: boolean) => {
        dispatch({
            type: UPDATE,
            payload: {
                duplicateTransferInquiries: state,
            },
        });
    };

    const setDeactivateOriginalProperty = (state: boolean) => {
        dispatch({
            type: UPDATE,
            payload: {
                deactivateOriginalProperty: state,
            },
        });
    };

    const setNewPropertyToInactive = (state: boolean) => {
        dispatch({
            type: UPDATE,
            payload: {
                newPropertyToInactive: state,
            },
        });
    };

    return {
        appState,
        login,
        logout,
        register,
        confirmEmail,
        isAuthenticatedCheck,
        getApplicationMetadata,
        showPasswordReset,
        requestPasswordReset,
        resetPassword,
        loginGoBack,
        addNewEvents,
        deleteCalendarEvent,
        editNewEvents,
        tokenCheck,
        addNewFirstDate,
        addNewUser,
        editSingleUser,
        getInitialEvents,
        setSingleViewInformation,
        setNewInquiry,
        setShowMyTeam,
        setLoadingSpinner,
        setDuplicateTransferFirstDate,
        setDuplicateTransferInquiries,
        setDeactivateOriginalProperty,
        setNewPropertyToInactive,
    };
};

export const getApplicationState = () => applicationState;

// Share your custom hook
export const [ApplicationProvider, useApplicationContext] = constate(
    useAplicationContextSpec,
);

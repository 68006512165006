import axios, { AxiosInstance } from 'axios';
import { axiosDefaults } from './AxiosDefaults';
import { LookupDataRow } from './LookupDataRow';

export class BaseService {
    protected lookupDataMap: { [key: string]: LookupDataRow[] } = {};
    protected axiosInstance: AxiosInstance;
    protected accessToken?: string;git
    public static lookupDataPromises: Array<() => Promise<void>> = [];

    constructor() {
        this.axiosInstance = axios.create(axiosDefaults);
        this.axiosInstance.interceptors.request.use((request) => {
            if (this.accessToken) {
                request.headers.Authorization = 'Bearer ' + this.accessToken;
            }
            return request;
        });
    }

    public static lookupDataInitialFetch() {
        return Promise.all(
            BaseService.lookupDataPromises.map((promise) => promise()),
        );
    }

    public setAccessToken(accessToken?: string) {
        this.accessToken = accessToken;
    }
}

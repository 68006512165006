import {
    mdiCommentPlusOutline,
    mdiCurrencyEur,
    mdiHomeCityOutline,
    mdiStarShootingOutline,
} from '@mdi/js';
import { Card } from 'primereact/card';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dropdown } from 'primereact/dropdown';
import React, { useEffect, useState } from 'react';
import CardHeader from '../../components/card/CardHeader';
import { useTranslation } from 'react-i18next';
import './MyStatisticsPage.scss';
import { StatisticService } from '../../services/StatisticsService';
import { useApplicationContext } from '../../application/ApplicationContext';
import { ProgressSpinner } from 'primereact/progressspinner';
import moment from 'moment';

const MyStatisticsPage = () => {
    const [loading, setLoading] = useState(false);
    const { t } = useTranslation();
    const { appState, addNewEvents } = useApplicationContext();
    const [data, setData] = useState(null);

    useEffect(() => {
        setLoading(true);
    }, []);

    useEffect(() => {
        setLoading(true);
        fetchStatistics(appState.teamView)
            .then((data) => {
                setData(data);
                setLoading(false);
            })
            .catch((error) => {
                console.log(error);
            });
    }, [appState.teamView]);

    const fetchStatistics = async (params) => {
        const response = await StatisticService.getMany(params);
        return response.data;
    };

    const customDataFD = [
        {
            FD: t('Broj FD termina'),
            Month: `${data?.firstDates.monthly.numberOfFirstDateAppointments}`,
            YTD: `${data?.firstDates.yearlyToDate.numberOfFirstDateAppointments}`,
        },
        {
            FD: t('od toga preporuke'),
            Month: `${data?.firstDates.monthly.firstDateAppointmentsOfWhichRecommendations}`,
            YTD: `${data?.firstDates.yearlyToDate.firstDateAppointmentsOfWhichRecommendations}`,
        },
        {
            FD: t('Broj ugovora sa prodavcem'),
            Month: `${data?.firstDates.monthly.firstDateContractsForThisMonth}`,
            YTD: `${data?.firstDates.yearlyToDate.firstDateContractsForThisYear}`,
        },
        {
            FD: t('od toga po preporuci'),
            Month: `${data?.firstDates.monthly.firstDateRecommendationContractsForThisMonth}`,
            YTD: `${data?.firstDates.yearlyToDate.firstDateRecommendationContractsForThisYear}`,
        },
        {
            FD: t('FD pozitivni %'),
            Month: `${
                data?.firstDates.monthly.firstDatePositivePercentage
                    ? data?.firstDates?.monthly?.firstDatePositivePercentage?.toFixed(
                          2,
                      )
                    : 0
            } %`,
            YTD: `${
                data?.firstDates.yearlyToDate.firstDatePositivePercentage
                    ? data?.firstDates.yearlyToDate.firstDatePositivePercentage?.toFixed(
                          2,
                      )
                    : 0
            } %`,
        },
        {
            FD: t('FD u postupku = Statusi NEU, WV, Bestatigt, On hold'),
            Month: `${data?.firstDates.monthly.firstDateInTheProcessing}`,
            YTD: `${data?.firstDates.yearlyToDate.firstDateInTheProcessing}`,
        },
        {
            FD: t('FD u postupku %'),
            Month: `${
                data?.firstDates.monthly.firstDateInTheProcessingPercentage
                    ? data?.firstDates?.monthly?.firstDateInTheProcessingPercentage?.toFixed(
                          2,
                      )
                    : 0
            } %`,
            YTD: `${
                data?.firstDates.yearlyToDate.firstDateInTheProcessingPercentage
                    ? data?.firstDates?.yearlyToDate?.firstDateInTheProcessingPercentage?.toFixed(
                          2,
                      )
                    : 0
            } %`,
        },
        {
            FD: t('FD otkazani'),
            Month: `${data?.firstDates.monthly.firstDateCancelled}`,
            YTD: `${data?.firstDates.yearlyToDate.firstDateCancelled}`,
        },
        {
            FD: t('FD otkazani') + ' %',
            Month: `${
                data?.firstDates.monthly.firstDateCancelledPercentage
                    ? data?.firstDates?.monthly?.firstDateCancelledPercentage?.toFixed(
                          2,
                      )
                    : 0
            } %`,
            YTD: `${
                data?.firstDates.yearlyToDate.firstDateCancelledPercentage
                    ? data?.firstDates?.yearlyToDate?.firstDateCancelledPercentage?.toFixed(
                          2,
                      )
                    : 0
            } %`,
        },
    ];

    const customDataObject = [
        {
            FD: t('Broj objekata'),
            Month: `${data?.properties.monthly.numberOfProperties}`,
            YTD:
                `${data?.properties.yearlyToDate.numberOfProperties}` +
                t(' od toga') +
                '  ' +
                t('sa provizijom'),
        },
        {
            FD: (
                <div className="FD-object">
                    <p>{t('Broj objekata prema tipu ugovora')}</p>
                </div>
            ),
            Month: (
                <div>
                    <p>
                        {t('Standardni ugovor') +
                            ' ' +
                            `${data?.properties.monthly.numberOfPropertiesByContractType.standardContract}` +
                            ' ' +
                            `${
                                data?.properties?.monthly?.numberOfPropertiesByContractType?.standardContractPercentage?.toFixed(
                                    2,
                                ) ?? 0
                            } %`}
                    </p>{' '}
                    <p>
                        {t('Ekskluzivni ugovor') +
                            ' ' +
                            `${data?.properties.monthly.numberOfPropertiesByContractType.exclusiveContract}` +
                            ' ' +
                            `${
                                data?.properties?.monthly?.numberOfPropertiesByContractType?.exclusiveContractPercentage?.toFixed(
                                    2,
                                ) ?? 0
                            }  %`}
                    </p>{' '}
                    <p>
                        {t('Usmeni ugovor') +
                            ' ' +
                            `${data?.properties.monthly.numberOfPropertiesByContractType.verbalContract}` +
                            ' ' +
                            `${
                                data?.properties?.monthly?.numberOfPropertiesByContractType?.verbalContractPercentage?.toFixed(
                                    2,
                                ) ?? 0
                            }  %`}
                    </p>
                </div>
            ),
            YTD: (
                <div>
                    <p>
                        {t('Standardni ugovor') +
                            ' ' +
                            `${data?.properties.yearlyToDate.numberOfPropertiesByContractType.standardContract}` +
                            ' ' +
                            `${
                                data?.properties.yearlyToDate?.numberOfPropertiesByContractType?.standardContractPercentage?.toFixed(
                                    2,
                                ) ?? 0
                            } %`}
                    </p>{' '}
                    <p>
                        {t('Ekskluzivni ugovor') +
                            ' ' +
                            `${data?.properties.yearlyToDate.numberOfPropertiesByContractType.exclusiveContract}` +
                            ' ' +
                            `${
                                data?.properties.yearlyToDate?.numberOfPropertiesByContractType?.exclusiveContractPercentage?.toFixed(
                                    2,
                                ) ?? 0
                            } %`}
                    </p>{' '}
                    <p>
                        {t('Usmeni ugovor') +
                            ' ' +
                            `${data?.properties.yearlyToDate.numberOfPropertiesByContractType.verbalContract}` +
                            ' ' +
                            `${
                                data?.properties.yearlyToDate?.numberOfPropertiesByContractType?.verbalContractPercentage?.toFixed(
                                    2,
                                ) ?? 0
                            } %`}
                    </p>
                </div>
            ),
        },
        {
            FD: (
                <div className="FD-object">
                    <p>{t('Broj servis termina')}</p>
                </div>
            ),
            Month: (
                <div>
                    <p>{t('Tekuci mesec')}</p>{' '}
                    <p>
                        {data?.properties.monthly
                            .numberOfServiceAppointments === null
                            ? 0
                            : data?.properties.monthly
                                  .numberOfServiceAppointments}
                    </p>
                </div>
            ),
            YTD: (
                <div>
                    <p>{t('Od pocetka godine do danas')}</p>{' '}
                    <p>
                        {data?.properties.yearlyToDate
                            .numberOfServiceAppointments === null
                            ? 0
                            : data?.properties.yearlyToDate
                                  .numberOfServiceAppointments}
                    </p>
                </div>
            ),
        },
    ];

    const customInteressenten = [
        {
            FD: t('Broj interesenata inkl Matching'),
            Month: `${data?.inquiries.monthly.numberOfInquiriesIncludingMatching}`,
            YTD: `${data?.inquiries.yearlyToDate.numberOfInquiriesIncludingMatching}`,
        },
        {
            FD: t('od toga Matching interesenti'),
            Month: `${
                data?.inquiries.monthly.numberOfMatchedInquiries
                    ? data?.inquiries.monthly.numberOfMatchedInquiries
                    : 0
            }`,
            YTD: `${
                data?.inquiries.yearlyToDate.numberOfMatchedInquiries
                    ? data?.inquiries.yearlyToDate.numberOfMatchedInquiries
                    : 0
            }`,
        },
        {
            FD: t('Broj termina sa interesentima'),
            Month: `${
                data?.inquiries.monthly
                    .numberOfAppointmentsWithInterestedParties
                    ? data?.inquiries.monthly
                          .numberOfAppointmentsWithInterestedParties
                    : 0
            }`,
            YTD: `${
                data?.inquiries.monthly
                    .numberOfAppointmentsWithInterestedParties
                    ? data?.inquiries.monthly
                          .numberOfAppointmentsWithInterestedParties
                    : 0
            }`,
        },
        {
            FD: t('Broj termina u odnosu na broj upita'),
            Month: `${
                data?.inquiries.monthly
                    .numberOfAppointmentsInRelationToNumberOfInquiries
                    ? data?.inquiries.monthly.numberOfAppointmentsInRelationToNumberOfInquiries?.toFixed(
                          2,
                      )
                    : 0
            } %`,
            YTD: `${
                data?.inquiries.yearlyToDate
                    .numberOfAppointmentsInRelationToNumberOfInquiries
                    ? data?.inquiries.yearlyToDate.numberOfAppointmentsInRelationToNumberOfInquiries?.toFixed(
                          2,
                      )
                    : 0
            } %`,
        },
        {
            FD: t('Ukupan broj svih termina'),
            Month: data?.inquiries.monthly.totalNumberOfAppointments,
            YTD: data?.inquiries.monthly.totalNumberOfAppointments,
        },
    ];

    const customDataUmsatz = [
        {
            FD: t('Broj prodatkih nekretnina'),
            Month: `${data?.turnover.monthly.numberOfRealEstateSales}`,
            YTD: `${data?.turnover.yearlyToDate.numberOfRealEstateSales}`,
        },
        {
            FD: t('Vrednost prodatih nekretnina'),
            Month: `${data?.turnover?.monthly?.valueOfSoldRealEstates?.toLocaleString()} EUR`,
            YTD: `${data?.turnover.yearlyToDate.valueOfSoldRealEstates.toLocaleString()} EUR`,
        },
        {
            FD: t('Provizija ukupno'),
            Month: `${
                data?.turnover?.monthly?.totalCommission?.toLocaleString() ?? 0
            } EUR`,
            YTD: `${
                data?.turnover?.yearlyToDate?.totalCommission?.toLocaleString() ??
                0
            } EUR`,
        },
    ];

    const today = moment().format('DD.MM.YYYY');

    if (loading) {
        return (
            <div className="center-spinner">
                <ProgressSpinner strokeWidth="5" animationDuration="1.5s" />
            </div>
        );
    } else {
        return (
            <Card>
                <div className="meine-statistik">
                    <Card>
                        {/* <div className="dropdown-container">
                            <Dropdown className="filter-item" />
                            <Dropdown className="filter-item" />
                            <Dropdown className="filter-item" />
                            <Dropdown
                                className="filter-item"
                                placeholder="Filter"
                            />
                        </div> */}
                        <Card
                            className="meine-statistik-section card"
                            header={
                                <CardHeader
                                    title={t('FD')}
                                    icon={mdiStarShootingOutline}
                                />
                            }
                        >
                            <DataTable
                                value={customDataFD}
                                className="p-datatable-lg"
                            >
                                <Column className="fd" field="FD" header="" />
                                <Column
                                    className="month"
                                    field="Month"
                                    header={t('Month')}
                                />
                                <Column
                                    className="ytd"
                                    field="YTD"
                                    header="YTD"
                                />
                            </DataTable>
                        </Card>
                        <Card
                            className="meine-statistik-section card"
                            header={
                                <CardHeader
                                    title={t('Objekte')}
                                    icon={mdiHomeCityOutline}
                                />
                            }
                        >
                            <DataTable
                                value={customDataObject}
                                className="p-datatable-lg"
                            >
                                <Column className="fd" field="FD" header="" />
                                <Column
                                    className="month"
                                    field="Month"
                                    header={t('Month')}
                                />
                                <Column
                                    className="ytd"
                                    field="YTD"
                                    header="YTD"
                                />
                            </DataTable>
                        </Card>
                        <Card
                            className="meine-statistik-section card"
                            header={
                                <CardHeader
                                    title={t('Interessenten')}
                                    icon={mdiCommentPlusOutline}
                                />
                            }
                        >
                            <DataTable
                                value={customInteressenten}
                                className="p-datatable-lg"
                            >
                                <Column className="fd" field="FD" header="" />
                                <Column
                                    className="month"
                                    field="Month"
                                    header={t('Month')}
                                />
                                <Column
                                    className="ytd"
                                    field="YTD"
                                    header="YTD"
                                />
                            </DataTable>
                        </Card>
                        <Card
                            className="meine-statistik-section card"
                            header={
                                <CardHeader
                                    title={t('Umsatz')}
                                    icon={mdiCurrencyEur}
                                />
                            }
                        >
                            <DataTable
                                value={customDataUmsatz}
                                className="p-datatable-lg"
                            >
                                <Column className="fd" field="FD" header="" />
                                <Column
                                    className="month"
                                    field="Month"
                                    header={t('Month')}
                                />
                                <Column
                                    className="ytd"
                                    field="YTD"
                                    header="YTD"
                                />
                            </DataTable>
                        </Card>
                    </Card>
                </div>
            </Card>
        );
    }
};

export default MyStatisticsPage;

export class Config {
    /**
     * CSS class name of the DIV element in the HTML where application should be rendered
     */
    public static APP_CLASS_NAME = 'app-container';

    /**
     * Base URL of the API
     */
    public static ROOT_API_URL = process.env.API_URL;

    /**
     * Auth client id
     */
    public static AUTH_CLIENT_ID = 'web_app';

    /**
     * Auth client secret
     */
    public static AUTH_CLIENT_SECRET = 'GHCRM_Web_App_Secret';

    /**
     * Auth grant type
     */
    public static AUTH_GRANT_TYPE_PASS = 'password';

    public static TEST_ACCESS_TOKEN =
        'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VybmFtZSI6Im9mZmljZUBub3JtYXNvZnQubmV0IiwidXNlcklkIjoxLCJ1c2VyVHlwZSI6IkFkbWluIiwiaWF0IjoxNjI1MTY1MDA1LCJleHAiOjE2NjgzNjUwMDV9.7YMvwlAdbN4MKq0v-xhkOmYi3bgTeuJASDeIFXOLcLM';
}

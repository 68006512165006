import Icon from '@mdi/react';
import { Card } from 'primereact/card';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import React, { useEffect, useState } from 'react';
import CardHeader from '../../components/card/CardHeader';
import './AgentAssignmentPage.scss';
import { useTranslation } from 'react-i18next';
import { mdiFlag, mdiPhone, mdiEmailOutline, mdiPencilOutline } from '@mdi/js';
import { Button } from 'primereact/button';
import { Link, useParams, Redirect } from 'react-router-dom';
import { userService } from '../../services/UsersService';
import { dashboardService } from '../../services/DashboardService';
import { firstDateService } from '../../services/FirstDateService';
import { Toast } from 'primereact/toast';

const AgentAssignmentPage = () => {
    const [selectedObjects, setSelectedObjects] = React.useState(null);
    const [data, setData] = useState(undefined);
    const { t } = useTranslation();
    const { id } = useParams<{ id: string }>();
    const [status, setStatus] = useState(0);
    const toast = React.useRef(null);

    useEffect(() => {
        userService.refreshTokenCheck().then(() => {
            fetchData()
                .then((result) => setData(result))
                .catch((err) => setStatus(err.response.status));
        });
    }, []);

    const fetchData = async () => {
        const response = await firstDateService.getAgentMatchingList(id);
        return response.data;
    };

    const paginatorLeft = (
        <Button type="button" icon="pi pi-refresh" className="p-button-text" />
    );
    const paginatorRight = (
        <Button type="button" icon="pi pi-cloud" className="p-button-text" />
    );

    const assignAgent = async (agentId: string) => {
        const response = await firstDateService.assignAgent(id, agentId);

        setStatus(response.status);
    };

    const assignAgentButton = (rowData) => {
        return (
            <Button
                type="button"
                icon="pi pi-user-plus"
                className="view-action-button"
                onClick={() => assignAgent(rowData.id)}
            />
        );
    };

    return (
        <>
            {status === 200 ? (
                <Redirect
                    to={{
                        pathname: '/matching',
                        state: status,
                    }}
                />
            ) : null}
            <Toast ref={toast} position={'top-right'} />
            <div className="agent-assignment-page">
                <Card
                    className={'location-section contact-details'}
                    header={
                        <CardHeader
                            title={t('Kontakt podaci/Kontaktdaten')}
                            className={'card-header-background'}
                        />
                    }
                >
                    <div className="contact-section">
                        <div className="field">
                            <p>Gospodin Petar Petrovic</p>
                        </div>
                        <div className="field">{t('ID') + ` 1122334`}</div>
                        <div className="field">
                            <Icon path={mdiPhone} size={1} />
                            <p> +381 65 666 777 8</p>
                        </div>
                        <div className="field">
                            <Icon path={mdiEmailOutline} size={1} />
                            <p> petar.petrovic@goldenhome.at</p>
                        </div>
                        <div className="field">
                            <Icon path={mdiFlag} size={1} />
                            <p> Prodavac</p>
                        </div>
                    </div>
                </Card>
                <Card
                    className={'location-section propertys'}
                    header={
                        <CardHeader
                            title={t('Nekretnina')}
                            className={'card-header-background'}
                        />
                    }
                >
                    <div className="property-section">
                        <span className="property-image">
                            <img
                                src={`https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885__480.jpg`}
                                alt="property-img"
                            />
                        </span>
                        <div className="property-data">
                            <div className="property-address">
                                <p>
                                    CC 19872 - Eigenakquise - Erdbrustgasse
                                    82/5.1. Etage, 1160 Wien
                                </p>
                            </div>
                            <div className="field">
                                {`${t('CC ID:') + ` 12568`}`}
                            </div>
                            <div className="field">
                                {`${t('Cena') + `: 380.000`}`}
                            </div>
                            <div className="field">{`${
                                t('Broj soba') + ': 5'
                            }`}</div>
                            <div className="field">
                                {`${t('Stambena povrsina') + ': 200m'}`}
                            </div>
                        </div>
                    </div>
                </Card>
                <Card
                    className="location-section protokol"
                    header={
                        <CardHeader
                            title={t('Protokol/Status')}
                            className={'card-header-background'}
                        />
                    }
                >
                    <div className="status-data">
                        <div>
                            <div className="status-data-field">
                                {`${t('Datum :') + ` 12.10.2021.`}`}
                            </div>
                            <div>{t('Dodeljeno putem:') + ' Sistem'}</div>
                        </div>
                        <div>
                            <div className="status-data-field">
                                {`${t('Lorem ipsum :') + ' 11.10.2021.'}`}
                            </div>
                            <div>{`${
                                t('Lorem ipsum :') + ' 11.10.2021.'
                            }`}</div>
                        </div>
                        <div>
                            <div className="status-data-field">
                                {`${t('Status')}`}
                            </div>
                            <div>{`${t('Termin :') + ' 15.10.2021.'}`}</div>
                        </div>
                        <div>
                            <div className="status-data-field">
                                {`${t('Nezainteresovanost :')}`}
                            </div>
                        </div>
                        <div>
                            <div className="status-edit-icon">
                                {t('Edit')}
                                <span className="vertical-align">
                                    <Icon path={mdiPencilOutline} size={1} />
                                </span>
                            </div>
                        </div>
                    </div>
                </Card>
                <div className="location-section activity-section">
                    <DataTable
                        value={data}
                        className="p-datatable-lg"
                        paginator={true}
                        paginatorPosition="bottom"
                        paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                        currentPageReportTemplate={`{first} ${t(
                            'bis',
                        )} {last} ${t('of')} {totalRecords}`}
                        rows={20}
                        rowsPerPageOptions={[10, 20, 50]}
                        paginatorLeft={paginatorLeft}
                        paginatorRight={paginatorRight}
                        scrollHeight="700px"
                    >
                        <Column
                            field="name"
                            className="column-container-second"
                            header={t('Markel')}
                        />
                        <Column
                            field="id"
                            className="column-container-second"
                            header={t('Anzahl')}
                        />
                        <Column
                            field="percent"
                            className="column-container-second"
                            header={t('Enfolasauote')}
                        />
                        <Column
                            field="date"
                            className="column-container-second"
                            header={t('Calendar')}
                        />
                        <Column
                            body={assignAgentButton}
                            className="datatable-button-column"
                        />
                    </DataTable>
                </div>
            </div>
        </>
    );
};

export default AgentAssignmentPage;

import React, { useEffect, useRef, useState, useCallback } from 'react';
import './objectDataAndLinksForm.scss';
import { TabMenu } from 'primereact/tabmenu';
import { Card } from 'primereact/card';
import CardHeader from '../../card/CardHeader';
import ObjectStatusBadge from '../objectStatusBadge/ObjectStatusBadge';
import { useTranslation } from 'react-i18next';
import { filesService } from '../../../services/FilesService';
import { Util } from '../../../util/Util';
import { Button } from 'primereact/button';
import { useDropzone } from 'react-dropzone';
import LoadingImage from './LoadingImage';
import ImageModal from '../../../components/ImageModal/ImageModal';
import { useStateCallback } from '../../../hooks/StateCallbackHook';
import { DomHandler } from 'primereact/utils';

enum FileVisibility {
    Public = 1,
    Expose = 2,
    Private = 4,
}

interface ObjectDataAndLinksForm {
    fileName?: string;
    id?: number;
    public: boolean;
    expose: boolean;
    internal: boolean;
    visibility: FileVisibility;
    file: any;
    isCover: boolean;
}

const fileCategories = {
    0: 'images',
    1: 'plans',
    2: 'videos',
    3: 'documents',
    4: 'view360',
};

const ObjectDataAndLinksForm = ({
    dataAndLinksObject,
    updateDataAndLinksObject,
}) => {
    const [image, setImage] = useState(undefined);
    const [showImage, setShowImage] = useStateCallback(false);
    const [id, setId] = useState(null);
    const [allFiles, setAllFiles] = useState({ ...dataAndLinksObject });
    const [allFilesOriginal, setAllFilesOriginal] = useState({
        ...dataAndLinksObject,
    });
    const [activeIndex, setActiveIndex] = useState<number>(0);
    const [fileCategory, setFileCategory] = useState<string>(
        fileCategories[activeIndex],
    );
    const { t } = useTranslation();
    const optionsMenuRef = useRef(null);
    const [optionsMenuData, setOptionsMenuData] = useState(null);
    const [dragId, setDragId] = useState();
    const [dragAndDrop, setdragAndDrop] = useState([]);
    const { getRootProps, getInputProps } = useDropzone({
        noClick: true,
        onDrop: (acceptedFiles) => {
            setdragAndDrop(
                acceptedFiles.map((file) =>
                    Object.assign(file, {
                        preview: URL.createObjectURL(file),
                    }),
                ),
            );
        },
    });

    async function handleDragAndDrop() {
        const newFiles = { ...allFiles };
        dragAndDrop.forEach((file) => URL.revokeObjectURL(file.preview));
        const response = await filesService.upload(dragAndDrop, fileCategory);

        newFiles[fileCategory].push(
            ...response.data[fileCategory].map((x, i) => ({
                file: { ...x, order: x.id },
                isCover: false,
            })),
        );

        setAllFiles(newFiles);
        setAllFilesOriginal(newFiles);
        updateDataAndLinksObject(newFiles);
        setdragAndDrop([]);
    }

    useEffect(() => {
        if (dragAndDrop.length !== 0) {
            handleDragAndDrop();
        }
    }, [dragAndDrop]);

    const setAsCover = (image: string) => {
        if (fileCategory === 'images') {
            const newFiles = { ...allFiles };
            const file = newFiles[fileCategory].find(
                (x) => x.file.id === image,
            );

            newFiles[fileCategory] = newFiles[fileCategory].map((x) => {
                x.isCover = false;
                return x;
            });

            file.isCover = true;
            // tslint:disable-next-line:no-bitwise
            file.visibility = FileVisibility.Public | FileVisibility.Expose;

            newFiles[fileCategory].sort((x, y) => {
                return x.isCover ? -1 : y.isCover ? 1 : 0;
            });
            setAllFiles(newFiles);
            setAllFilesOriginal(newFiles);
            // extension: ".png"
            // id: 12
            // mimeType: "image/png"
            // name: "image006"
            // path: "upload\\d78e6fd58aea666eeae3cce11d3e1a55"
            // size: 283205
            // temporary: true
            // visibility: 4
        }
    };

    const optionsMenu = [
        {
            label: t('Set as cover'),
            command(event) {
                if (fileCategory === 'images') {
                    const newFiles = { ...allFiles };
                    const file = newFiles[fileCategory].find(
                        (x) => x.file.id === optionsMenuData.id,
                    );

                    newFiles[fileCategory] = newFiles[fileCategory].map((x) => {
                        x.isCover = false;
                        return x;
                    });
                    file.isCover = true;
                    newFiles[fileCategory].sort((x, y) => {
                        return x.isCover ? -1 : y.isCover ? 1 : 0;
                    });
                    setAllFiles(newFiles);
                    setAllFilesOriginal(newFiles);

                    // extension: ".png"
                    // id: 12
                    // mimeType: "image/png"
                    // name: "image006"
                    // path: "upload\\d78e6fd58aea666eeae3cce11d3e1a55"
                    // size: 283205
                    // temporary: true
                    // visibility: 4
                }

                setOptionsMenuData(null);
            },
        },
    ];

    useEffect(() => {
        setFileCategory(fileCategories[activeIndex]);
    }, [activeIndex]);

    const items = [
        {
            label: t('Slike / Bilder'),
            icon: 'pi pi-fw pi-plus',
            value: 0,
        },
        {
            label: t('Planovi / Plane'),
            icon: 'pi pi-fw pi-plus',
            value: 1,
        },
        {
            label: t('Video snimci / videos'),
            icon: 'pi pi-fw pi-plus',
            value: 2,
        },
        {
            label: t('Dokumenta / Dokumente'),
            icon: 'pi pi-fw pi-plus',
            value: 3,
        },
        {
            label: t('360 pogled / 360 Ansichten'),
            icon: 'pi pi-fw pi-plus',
            value: 4,
        },
    ];

    async function myUploader(e) {
        const files = e.target.files;
        const newFiles = { ...allFiles };
        if (files.length) {
            const response = await filesService.upload(files, fileCategory);

            newFiles[fileCategory].push(
                ...response.data[fileCategory].map((x, i) => ({
                    file: { ...x, order: x.id },
                    isCover: false,
                })),
            );

            setAllFiles(newFiles);
            setAllFilesOriginal(newFiles);
            updateDataAndLinksObject(newFiles);
        }
    }

    const handleDrag = (ev) => {
        setDragId(ev.target.id);
    };

    const handleDrop = (ev) => {
        const newFiles = { ...allFiles };
        const dragBox = allFiles[fileCategories[activeIndex]].find(
            (box) => box.file.id === Number(dragId),
        );
        const dropBox = allFiles[fileCategories[activeIndex]].find(
            (box) => box.file.id === Number(ev.currentTarget.id),
        );

        const dragBoxOrder = dragBox?.file.order;
        const dropBoxOrder = dropBox?.file.order;

        const direction = dragBoxOrder > dropBoxOrder;

        const newBoxState = allFiles[fileCategories[activeIndex]].map((box) => {
            if (box.file.id === Number(dragId)) {
                box.file.order = dropBoxOrder;
            } else if (
                direction &&
                box.file.order >= dropBoxOrder &&
                box.file.order < dragBoxOrder
            ) {
                box.file.order++;
            } else if (
                !direction &&
                box.file.order > dragBoxOrder &&
                box.file.order <= dropBoxOrder
            ) {
                box.file.order--;
            }
            return box;
        });

        if (newBoxState.length !== 0) {
            newFiles[fileCategory] = newBoxState;
            setAllFiles(newFiles);
            setAllFilesOriginal(newFiles);
        }
    };

    const clearFileData = () => {
        const newFiles = { ...allFiles };
        newFiles[fileCategory] = [];
        setAllFiles(newFiles);
        setAllFilesOriginal(newFiles);
    };

    const filePublic = (e: ObjectDataAndLinksForm) => {
        const copyAllFiles = { ...allFiles };

        const index = allFiles[fileCategory].findIndex(
            (x) => x.file.id === e.file.id,
        );

        const expose = Util.isFlagged(
            copyAllFiles[fileCategory][index].file.visibility,
            FileVisibility.Expose,
        );

        if (
            Util.isFlagged(
                copyAllFiles[fileCategory][index].file.visibility,
                FileVisibility.Public,
            )
        ) {
            copyAllFiles[fileCategory][index].file.visibility = expose
                ? FileVisibility.Expose
                : 0;
        } else {
            copyAllFiles[fileCategory][index].file.visibility =
                FileVisibility.Public | (expose ? FileVisibility.Expose : 0);
        }

        setAllFiles(copyAllFiles);
        setAllFilesOriginal(copyAllFiles);
    };

    const fileExpose = (e: ObjectDataAndLinksForm) => {
        const copyAllFiles = { ...allFiles };
        const index = allFiles[fileCategory].findIndex(
            (x) => x.file.id === e.file.id,
        );

        const _public = Util.isFlagged(
            copyAllFiles[fileCategory][index].file.visibility,
            FileVisibility.Public,
        );

        if (
            Util.isFlagged(
                copyAllFiles[fileCategory][index].file.visibility,
                FileVisibility.Expose,
            )
        ) {
            copyAllFiles[fileCategory][index].file.visibility = _public
                ? FileVisibility.Public
                : 0;
        } else {
            copyAllFiles[fileCategory][index].file.visibility =
                FileVisibility.Expose | (_public ? FileVisibility.Public : 0);
        }

        setAllFiles(copyAllFiles);
        setAllFilesOriginal(copyAllFiles);
    };

    const fileInternal = (e: ObjectDataAndLinksForm) => {
        const copyAllFiles = { ...allFiles };
        const index = allFiles[fileCategory].findIndex(
            (x) => x.file.id === e.file.id,
        );

        if (
            Util.isFlagged(
                copyAllFiles[fileCategory][index].file.visibility,
                FileVisibility.Private,
            )
        ) {
            copyAllFiles[fileCategory][index].file.visibility = 0;
        } else {
            copyAllFiles[fileCategory][index].file.visibility =
                FileVisibility.Private;
        }

        setAllFiles(copyAllFiles);
    };

    const handleImage = (index) => {
        setShowImage(true);
        setId(index);
        setImage(allFiles[fileCategories[activeIndex]][index].file);
    };

    const closeImageModal = () => {
        setShowImage(!showImage, (state) => {
            if (!state) {
                DomHandler.removeClass(document.body, 'p-overflow-hidden');
            }
        });
    };

    const handleLeftSlide = () => {
        const imageArray = [...allFiles[fileCategories[activeIndex]]];
        if (id === 0) {
            setImage(imageArray[imageArray.length - 1]?.file);
            setId(imageArray.length - 1);
        } else {
            setImage(imageArray[id - 1]?.file);
            setId(id - 1);
        }
    };

    const handleRightSlide = () => {
        const imageArray = [...allFiles[fileCategories[activeIndex]]];
        if (id === imageArray.length - 1) {
            setImage(imageArray[0]?.file);
            setId(0);
        } else {
            setImage(imageArray[id + 1]?.file);
            setId(id + 1);
        }
    };

    // const toggleOptionsMenu = (event, data) => {
    //     setOptionsMenuData(data);
    //     optionsMenuRef.current.toggle(event);
    // };

    const renderCardApp = (card: ObjectDataAndLinksForm) => {
        return (
            <div className="card-link-icon">
                <div>
                    <i
                        className={`pi pi-globe ${
                            Util.isFlagged(
                                card.file.visibility,
                                FileVisibility.Public,
                            )
                                ? 'globe'
                                : null
                        }`}
                        onClick={() => {
                            filePublic(card);
                        }}
                    />
                    <i
                        className={`pi pi-file ${
                            Util.isFlagged(
                                card.file.visibility,
                                FileVisibility.Expose,
                            )
                                ? 'expose'
                                : null
                        }`}
                        onClick={() => {
                            fileExpose(card);
                        }}
                    />
                    <i
                        className={`pi pi-lock ${
                            Util.isFlagged(
                                card.file.visibility,
                                FileVisibility.Private,
                            )
                                ? 'internal'
                                : null
                        }`}
                        onClick={() => {
                            fileInternal(card);
                        }}
                    />
                </div>
                {card.file.mimeType.startsWith('image') ? (
                    <div>
                        <Button
                            className="p-button-sm"
                            onClick={(event) => {
                                setAsCover(card.file.id);
                            }}
                        >
                            Set as cover
                        </Button>
                        {/*<Menu*/}
                        {/*    model={optionsMenu}*/}
                        {/*    popup*/}
                        {/*    ref={optionsMenuRef}*/}
                        {/*    id="options-menu"*/}
                        {/*/>*/}
                        {/*<i*/}
                        {/*    className="pi pi-cog"*/}
                        {/*    onClick={(event) =>*/}
                        {/*        toggleOptionsMenu(event, card.file)*/}
                        {/*    }*/}
                        {/*/>*/}
                    </div>
                ) : null}
            </div>
        );
    };

    const deleteFile = (e: ObjectDataAndLinksForm) => {
        const newFiles = { ...allFiles };
        const newSubFiles = allFiles[fileCategory].filter(
            (x) => x.file.id !== e.file.id,
        );
        newFiles[fileCategory] = [...newSubFiles];
        setAllFiles(newFiles);
        setAllFilesOriginal(newFiles);
        updateDataAndLinksObject(newFiles);
    };

    const renderDeleteButton = (card: ObjectDataAndLinksForm) => {
        return (
            <i
                className="pi pi-times"
                onClick={() => {
                    deleteFile(card);
                }}
            />
        );
    };

    const renderButtons = () => {
        return (
            <>
                <div
                    id={
                        allFiles[fileCategory]?.length === 0
                            ? 'no-upload-file'
                            : 'upload-file'
                    }
                >
                    {/* <button id="clear-btn" onClick={clearFileData}>
                        {t('Clear')}
                    </button> */}

                    <label className="upload" htmlFor="upload-file-input">
                        {t('Upload')}
                    </label>
                    {fileCategory === 'images' || fileCategory === 'plans' ? (
                        <input
                            onChange={myUploader}
                            type="file"
                            id="upload-file-input"
                            multiple
                            accept={'.bmp,.gif,.png,.wbmp,.jpg,.jpeg'}
                        />
                    ) : (
                        <input
                            onChange={myUploader}
                            type="file"
                            id="upload-file-input"
                            multiple
                        />
                    )}
                </div>
            </>
        );
    };

    const [activeFilter, setActiveFilter] = useState('');

    const showFilterImage = (type) => {
        switch (type) {
            case 'Portale':
                setActiveFilter('Portale');
                let copyData = { ...allFilesOriginal };
                let filterImage = copyData?.images?.filter(
                    (file) =>
                        file.file.visibility === 1 || file.file.visibility == 3,
                );
                copyData.images = filterImage;
                setAllFiles(copyData);
                break;
            case 'Expose':
                setActiveFilter('Expose');
                let copyDataExpose = { ...allFilesOriginal };
                let filterImageExpose = copyDataExpose?.images?.filter(
                    (file) =>
                        file.file.visibility === 2 || file.file.visibility == 3,
                );
                copyDataExpose.images = filterImageExpose;
                setAllFiles(copyDataExpose);
                break;
            case 'Intern':
                setActiveFilter('Intern');
                let copyDataIntern = { ...allFilesOriginal };
                let filterImageIntern = copyDataIntern?.images?.filter(
                    (file) => file.file.visibility === 4,
                );
                copyDataIntern.images = filterImageIntern;
                setAllFiles(copyDataIntern);
                break;
            case 'Loschen':
                setActiveFilter('');
                setAllFiles(allFilesOriginal);
                break;
            default: //
        }
    };

    return (
        <>
            {showImage && (
                <ImageModal
                    handleRightSlide={handleRightSlide}
                    handleLeftSlide={handleLeftSlide}
                    onHide={closeImageModal}
                    image={image}
                />
            )}
            <div id="data-and-links">
                <Card
                    className="location-section"
                    header={
                        <CardHeader
                            title={t(
                                'Documente werden exportiert / veroffentlicht"',
                            )}
                        />
                    }
                >
                    <div className="documente">
                        <span
                            onClick={() => {
                                showFilterImage('Portale');
                            }}
                            className={
                                activeFilter === 'Portale'
                                    ? 'active-filter'
                                    : 'filter-cursor'
                            }
                        >
                            <ObjectStatusBadge
                                type="legend"
                                status={t('Portale')}
                            />
                        </span>
                        <span
                            onClick={() => {
                                showFilterImage('Expose');
                            }}
                            className={
                                activeFilter === 'Expose'
                                    ? 'active-filter'
                                    : 'filter-cursor'
                            }
                        >
                            <ObjectStatusBadge
                                type="legend"
                                status={t('Expose')}
                            />
                        </span>
                        <span
                            onClick={() => {
                                showFilterImage('Intern');
                            }}
                            className={
                                activeFilter === 'Intern'
                                    ? 'active-filter'
                                    : 'filter-cursor'
                            }
                        >
                            {' '}
                            <ObjectStatusBadge
                                type="legend"
                                status={t('Intern')}
                            />
                        </span>
                        <span
                            onClick={() => {
                                showFilterImage('Loschen');
                            }}
                        >
                            {/* <ObjectStatusBadge
                                type="legend"
                                status={t('Loschen')}
                            /> */}
                            {activeFilter !== '' ? (
                                <button
                                    onClick={() => showFilterImage('Loschen')}
                                    className="reset-status"
                                >
                                    <i
                                        className="pi pi-refresh"
                                        style={{ fontSize: '1em' }}
                                    ></i>
                                </button>
                            ) : null}
                        </span>
                    </div>
                </Card>

                <div className="document-wrapper">
                    <TabMenu
                        model={items}
                        activeIndex={activeIndex}
                        onTabChange={(e) => setActiveIndex(e.index)}
                    />

                    {activeIndex < 0 || activeIndex >= items.length ? null : ( // image
                        <div
                            {...getRootProps({
                                className: 'dropzone',
                            })}
                            id="files-link"
                        >
                            {allFiles[fileCategories[activeIndex]]
                                ?.sort(
                                    (a, b) =>
                                        !b.isCover &&
                                        a.file.order - b.file.order,
                                )
                                .map((card: ObjectDataAndLinksForm, index) => {
                                    return (
                                        <>
                                            <div
                                                draggable={true}
                                                id={card.file.id}
                                                onDragOver={(ev) =>
                                                    ev.preventDefault()
                                                }
                                                onDragStart={handleDrag}
                                                onDrop={handleDrop}
                                                className={`p-card card-links ${
                                                    card.isCover ? 'cover' : ''
                                                }`}
                                            >
                                                <div className="img-card">
                                                    <div className="img-card-title">
                                                        <h6>
                                                            {card.file.name}
                                                            {
                                                                card.file
                                                                    .extension
                                                            }
                                                        </h6>
                                                        {renderDeleteButton(
                                                            card,
                                                        )}
                                                    </div>
                                                    <div
                                                        className="img-wrapper"
                                                        onClick={() =>
                                                            handleImage(index)
                                                        }
                                                    >
                                                        <LoadingImage
                                                            card={card}
                                                        />
                                                    </div>
                                                </div>
                                                {renderCardApp(card)}
                                            </div>
                                        </>
                                    );
                                })}
                        </div>
                    )}

                    <div id="update-clear-buttons">{renderButtons()}</div>
                </div>
            </div>
        </>
    );
};

export default ObjectDataAndLinksForm;

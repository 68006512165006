import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import resourcesToBackend from 'i18next-resources-to-backend';

i18n
    // .use(resourcesToBackend((language, namespace, callback) => {
    //     import(`./locales/${language}/${namespace}.json`)
    //         .then((resources) => {
    //             callback(null, resources);
    //         })
    //         .catch((error) => {
    //             callback(error, null);
    //         });
    // }))
    .use(initReactI18next)
    .init({
        nsSeparator: false,
        keySeparator: false,
        lng: 'de',
        fallbackLng: 'en',
        resources: {
            de: { translation: require('./locales/de.po') },
            en: { translation: require('./locales/en.po') },
            de_at: { translation: require('./locales/de_at.po') },
        },
    });

export default i18n;

export enum Permissions {
	USER_GET = 'user.get',
	USER_GET_ALL = 'user.get.all',
    USER_GET_LIST = 'user.get.list',
	USER_CREATE = 'user.create',
	USER_EDIT = 'user.edit',
	USER_DELETE = 'user.delete',
	STATISTICS_GET = 'statistics.get',
	ROLE_GET_ALL = 'role.get.all',
	ROLE_GET = 'role.get',
    ROLE_CREATE = 'role.create',
    ROLE_UPDATE = 'role.update',
    ROLE_DELETE = 'role.delete',
    PROPERTY_GET_ALL = 'property.get.all',
    PROPERTY_GET = 'property.get',
    PROPERTY_CREATE = 'property.create',
    PROPERTY_UPDATE = 'property.update',
    PROPERTY_DELETE = 'property.delete',
    PROPERTY_MARKETING = 'property.marketing',
    PROPERTY_AUTHORIZE = 'property.authorize',
    INQUIRY_GET_ALL = 'inquiry.get.all',
    INQUIRY_GET = 'inquiry.get',
    INQUIRY_CREATE = 'inquiry.create',
    INQUIRY_UPDATE = 'inquiry.update',
    INQUIRY_DELETE = 'inquiry.delete',
    INQUIRY_CREATE_ACTIVITY = 'inquiry.create.activity',
    FIRST_DATE_GET_ALL = 'firstDate.get.all',
    FIRST_DATE_GET = 'firstDate.get',
    FIRST_DATE_CREATE = 'firstDate.create',
    FIRST_DATE_UPDATE = 'firstDate.update',
    FIRST_DATE_DELETE = 'firstDate.delete',
    FIRST_DATE_CREATE_ACTIVITY = 'firstDate.create.activity',
    FIRST_DATE_GET_ALL_MATCHED_AGENTS = 'firstDate.get.all.matchedAgents',
    FIRST_DATE_GET_ALL_UNASSIGNED = 'firstDate.get.all.unassigned',
    FIRST_DATE_ASSIGN_AGENT = 'firstDate.assign.agent',
    FILE_UPLOAD = 'file.upload',
    EXPOSE_GENERATE = 'expose.generate',
    EXPOSE_GET = 'expose.get',
    EXPORT_GET_ALL = 'export.get.all',
    EXPORT_GET = 'export.get',
    EXPORT_CREATE = 'export.create',
    DASHBOARD_GET = 'dashboard.get',
    CONTACT_GET_ALL = 'contact.get.all',
    CONTACT_GET = 'contact.get',
    CONTACT_CREATE = 'contact.create',
    CONTACT_UPDATE = 'contact.update',
    CONTACT_DELETE = 'contact.delete',
    CALENDAR_GET_ALL = 'calendar.get.all',
    CALENDAR_GET = 'calendar.get',
    CALENDAR_GET_ALL_BY_USER = 'calendar.get.all.user',
    CALENDAR_CREATE = 'calendar.create',
    CALENDAR_UPDATE = 'calendar.update',
    CALENDAR_DELETE = 'calendar.delete',
	REPORT_GET = 'reports.get'
}

export enum EnergySources {
    AIR_SOURCE_HEAT_PUMP = 'Luftwärmepumpe',
    BIOENERGY = 'Bioenergie',
    LIGHT_NATURAL_GAS = 'Erdgas leicht',
    HEAVY_NATURAL_GAS = 'Erdgas schwer',
    GEOTHERMAL = 'Erdwärme',
    DISTRICT_HEATING = 'Fernwärme',
    STEAM_DISTRICT_HEATING = 'Fernwärme Dampf',
    LIQUID_GAS = 'Flüssigass',
    GAS = 'Gas',
    FUEL_OIL = 'Heizöl',
    WOOD = 'Holz',
    WOOD_CHIPS = 'Holz-Hackschnitzel',
    COAL = 'Kohle',
    COAL_KOKS = 'Kohle / Koks',
    PELLET = 'Pellet',
    SOLAR = 'Solar',
    LOCAL_HEATING = 'Nahwärme',
    WIND_ENERGY = 'Windenergie',
    WATER_ENERGY = 'Wasserenergie',
    ENVIRONMENTAL_HEAT = 'Umweltwärme',
    ELECTRICITY = 'Strom',
    HEAT_SUPPLY = 'Wärmelieferung',
    HEAT_PUMP = 'Wärmepumpe',
    KWK_FOSSIL = 'KWK fossil',
    KWK_ERNEURBAR = 'KWK erneuerbar',
    KWK_REGENERATIV = 'KWK regenerativ',
    KWK_BIO = 'KWK bio',
}

export const energySourcesOptions = [
    { label: 'Luftwärmepumpe', value: 'Luftwärmepumpe' },
    { label: 'Bioenergie', value: 'Bioenergie' },
    { label: 'Erdgas leicht', value: 'Erdgas leicht' },
    { label: 'Erdgas schwer', value: 'Erdgas schwer' },
    { label: 'Erdwärme', value: 'Erdwärme' },
    { label: 'Fernwärme', value: 'Fernwärme' },
    { label: 'Fernwärme Dampf', value: 'Fernwärme Dampf' },
    { label: 'Flüssigass', value: 'Flüssigass' },
    { label: 'Gas', value: 'Gas' },
    { label: 'Heizöl', value: 'Heizöl' },
    { label: 'Holz', value: 'Holz' },
    { label: 'Holz-Hackschnitzel', value: 'Holz-Hackschnitzel' },
    { label: 'Kohle', value: 'Kohle' },
    { label: 'Kohle / Koks', value: 'Kohle / Koks' },
    { label: 'Pellet', value: 'Pellet' },
    { label: 'Solar', value: 'Solar' },
    { label: 'Nahwärme', value: 'Nahwärme' },
    { label: 'Windenergie', value: 'Windenergie' },
    { label: 'Wasserenergie', value: 'Wasserenergie' },
    { label: 'Umweltwärme', value: 'Umweltwärme' },
    { label: 'Strom', value: 'Strom' },
    { label: 'Wärmelieferung', value: 'Wärmelieferung' },
    { label: 'Wärmepumpe', value: 'Wärmepumpe' },
    { label: 'KWK fossil', value: 'KWK fossil' },
    { label: 'KWK erneuerbar', value: 'KWK erneuerbar' },
    { label: 'KWK regenerativ', value: 'KWK regenerativ' },
    { label: 'KWK bio', value: 'KWK bio' },
]

export enum HeatingType {
    FLOOR_HEATING = 'Etagenheizung',
    FURNACE_HEATING = 'Ofenheizung',
    CENTRAL_HEATING = 'Zentralheizung',
    COMBINED_HEAT_AND_POWER_PLANT = 'Blockheizkraftwerk',
    ELECTRIC_HEATING = 'Elektro-Heizung',
    DISTRICT_HEATING = 'Fernwärme',
    UNDERFLOOR_HEATING = 'Fußbodenheizung',
    GAS_HEATER = 'Gas-Heizung',
    WOODEN_PELLET_HEATING = 'Holz-Pelletheizung',
    NIGHT_STORAGE_STOVES = 'Nachtspeicheröfen',
    OIL_HEATING = 'Öl-Heizung',
    SOLAR_HEATING = 'Solar-Heizung',
    HEAT_PUMP = 'Wärmepumpe',
}

export const heatingTypeOptions = [
    { label: 'Etagenheizung', value: 'Etagenheizung' },
    { label: 'Ofenheizung', value: 'Ofenheizung' },
    { label: 'Zentralheizung', value: 'Zentralheizung' },
    { label: 'Blockheizkraftwerk', value: 'Blockheizkraftwerk' },
    { label: 'Elektro-Heizung', value: 'Elektro-Heizung' },
    { label: 'Fernwärme', value: 'Fernwärme' },
    { label: 'Fußbodenheizung', value: 'Fußbodenheizung' },
    { label: 'Gas-Heizung', value: 'Gas-Heizung' },
    { label: 'Holz-Pelletheizung', value: 'Holz-Pelletheizung' },
    { label: 'Nachtspeicheröfen', value: 'Nachtspeicheröfen' },
    { label: 'Öl-Heizung', value: 'Öl-Heizung' },
    { label: 'Solar-Heizung', value: 'Solar-Heizung' },
    { label: 'Wärmepumpe', value: 'Wärmepumpe' },
]

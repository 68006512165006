import React, { useState, useEffect } from 'react';
import './CalendarFormModal.scss';
import CustomObjectInput from '../objects/customObjectInput/CustomObjectInput';
import { useForm } from 'react-hook-form';
import { contactsService } from '../../services/ContactsService';
import { propertiesService } from '../../services/PropertiesService';
import { useApplicationContext } from '../../application/ApplicationContext';
import { calendarEventStatus } from '../../models/CalendarFormModal';
import dateFormat from 'dateformat';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

const CalendarFormModal = ({
    showModal,
    calendarFormModalObject,
    updateCalendarFormModalObject,
    handleResetModal,
    toast,
}) => {
    const [searchChar, setSearchContact] = useState(null);
    const [contactList, setContactList] = useState([]);
    const [properties, setProperties] = useState([]);
    const { appState, addNewEvents } = useApplicationContext();
    const { t } = useTranslation();

    useEffect(() => {
        fetchContacts().then((result) => setContactList(result));
        fetchProperties().then((result) => setProperties(result));
    }, []);

    const fetchProperties = async () => {
        const response = await propertiesService.getMany();
        return response.data;
    };

    const fetchContacts = async () => {
        const response = await contactsService.getMany();
        return response.data;
    };
    const formatPropertyText = (property) => {
        let result = '';

        result += property.rootId;
        result += ', ';
        result += property.place;

        if (property.street) {
            result += ' ';
            result += property.street;
        }

        if (property.houseNumber) {
            result += ' ';
            result += property.houseNumber;
        }

        if (property.seller) {
            result += ', ';
            result += property.seller.firstName;
            result += ' ';
            result += property.seller.lastName;
        }

        return result;
    };

    const propertiesOptions = () => {
        const allProperties = properties.map((property) => ({
            label: formatPropertyText(property),
            value: property.id,
        }));
        return allProperties;
    };

    const formatContactText = (contact) => {
        let result = '';

        result += contact.id;
        result += '. ';

        if (contact.firstName) {
            result += ' ';
            result += contact.firstName;
        }

        if (contact.lastName) {
            result += ' ';
            result += contact.lastName;
        }

        if (contact.postalCode) {
            result += ', ';
            result += contact.postalCode;
        }

        if (contact.city) {
            result += ', ';
            result += contact.city;
        }

        if (contact.address) {
            result += ', ';
            result += contact.address;
        }

        return result;
    };

    const contactOptions = () => {
        const allContacts = contactList.map((contact) => ({
            label: formatContactText(contact),
            value: contact.id,
        }));
        return allContacts.sort((a: any, b: any) => {
            const aId = parseInt(a.label.split(',')[0]);
            const bId = parseInt(b.label.split(',')[0]);

            return aId - bId;
        });
    };

    const {
        control,
        formState: { errors, isValid },
        register,
    } = useForm({
        mode: 'all',
    });

    const handleCloseModal = () => {
        showModal();
        handleResetModal();
    };

    const handleButtonCheck = () => {
        addNewEvents(
            calendarFormModalObject.title,
            calendarFormModalObject.info,
            moment(calendarFormModalObject.taskValidFrom).format(),
            moment(calendarFormModalObject.taskExpiryDate).format(),
            calendarFormModalObject.activityType,
            Number(calendarFormModalObject.id),
            calendarFormModalObject.contact,
            calendarFormModalObject.Object,
        );
        showModal();
        handleResetModal();
        toast.current.show({
            severity: 'success',
            detail: t(`A new event has been successfully added`),
            life: 3000,
        });
    };

    const footer = (
        <div>
            <Button
                label={t('Speichern')}
                icon="pi pi-check"
                disabled={!isValid}
                onClick={handleButtonCheck}
            />
            {/* <Button
                label={t('Refresh')}
                className="p-button-secondary"
                icon="pi pi-refresh"
                onClick={handleResetModal}
            /> */}
        </div>
    );

    useEffect(() => {}, []);

    return (
        <Dialog
            header={t('New Task')}
            visible={true}
            style={{ width: '50vw' }}
            className="new-task-modal"
            footer={footer}
            blockScroll
            draggable={false}
            onHide={handleCloseModal}
        >
            <div className="modal-input column-span-2">
                <CustomObjectInput
                    type="text"
                    classes={['country-input']}
                    id="id-input"
                    label={t('Title')}
                    name="title"
                    register={register}
                    stateField={calendarFormModalObject.title}
                    setStateField={updateCalendarFormModalObject}
                    errors={errors}
                    control={control}
                    rules={{ required: t('This is required') }}
                />
            </div>
            <div className="modal-input column-span-2">
                <CustomObjectInput
                    type="dropdown"
                    classes={['country-input']}
                    id="activity-type-input"
                    label={t('Event type')}
                    name="activityType"
                    register={register}
                    dropdownOptions={[
                        {
                            label: 'First date',
                            value: calendarEventStatus.FIRST_DATE,
                        },
                        {
                            label: 'Interessent',
                            value: calendarEventStatus.Interessent,
                        },
                        // {
                        //     label: 'Anfrage',
                        //     value: calendarEventStatus.INQUIRY,
                        // },
                        {
                            label: 'Privat',
                            value: calendarEventStatus.PRIVATE,
                        },
                        {
                            label: 'Besichtigung',
                            value: calendarEventStatus.Besichtigung,
                        },
                        {
                            label: 'Zweitbesichtigung',
                            value: calendarEventStatus.Zweitbesichtigung,
                        },
                        {
                            label: 'Notar',
                            value: calendarEventStatus.Notar,
                        },
                        {
                            label: 'Servicetermin',
                            value: calendarEventStatus.Servicetermin,
                        },
                        {
                            label: 'Servicetelefonat',
                            value: calendarEventStatus.Servicetelefonat,
                        },
                        {
                            label: 'Büro',
                            value: calendarEventStatus.Buro,
                        },
                        {
                            label: 'Fototermin',
                            value: calendarEventStatus.Fototermin,
                        },
                        {
                            label: 'Sonstige',
                            value: calendarEventStatus.Sonstige,
                        },
                        {
                            label: 'Nachfassen',
                            value: calendarEventStatus.Nachfassen,
                        },
                        {
                            label: 'Telefonat',
                            value: calendarEventStatus.Telefonat,
                        },
                    ]}
                    stateField={calendarFormModalObject.activityType}
                    setStateField={updateCalendarFormModalObject}
                    errors={errors}
                    control={control}
                    rules={{ required: t('This is required') }}
                />
            </div>
            <div className="modal-input column-span-3">
                <CustomObjectInput
                    type="calendar"
                    classes={['valid-from-input', 'generic-input']}
                    id="Date-and-time-from-input"
                    label={t('Date and time from')}
                    name="taskValidFrom"
                    timeShow={true}
                    register={register}
                    stateField={calendarFormModalObject.taskValidFrom}
                    setStateField={updateCalendarFormModalObject}
                    errors={errors}
                    control={control}
                    rules={
                        calendarFormModalObject.taskValidFrom === null
                            ? { required: t('This is required') }
                            : ''
                    }
                />
            </div>
            <div className="modal-input column-span-3">
                <CustomObjectInput
                    type="calendar"
                    classes={['expiry-date-input', 'generic-input']}
                    id="Date-and-time-to-input"
                    label={t('Date and time to')}
                    name="taskExpiryDate"
                    timeShow={true}
                    register={register}
                    stateField={calendarFormModalObject.taskExpiryDate}
                    setStateField={updateCalendarFormModalObject}
                    errors={errors}
                    control={control}
                    rules={
                        calendarFormModalObject.taskExpiryDate === null
                            ? { required: t('This is required') }
                            : ''
                    }
                />
            </div>
            <div className="modal-input column-span-2">
                <CustomObjectInput
                    type="dropdown"
                    classes={['country-input']}
                    id="Object-input"
                    label={t('Object')}
                    name="Object"
                    filter={true}
                    register={register}
                    dropdownOptions={propertiesOptions()}
                    stateField={calendarFormModalObject.Object}
                    setStateField={updateCalendarFormModalObject}
                    errors={errors}
                    control={control}
                    // rules={{ required: t('This is required') }}
                />
            </div>
            <div className="modal-input column-span-2">
                <CustomObjectInput
                    type="dropdown"
                    classes={['country-input']}
                    id="contact-input"
                    label={t('Contact')}
                    name="contact"
                    filter={true}
                    register={register}
                    onFilter={(e) => setSearchContact(e.filter.length)}
                    emptyFilterMessage={'Search contacts...'}
                    dropdownOptions={
                        searchChar > 2
                            ? contactOptions()
                            : [{ label: 'Search contacts...', disabled: true }]
                    }
                    stateField={calendarFormModalObject.contact}
                    setStateField={updateCalendarFormModalObject}
                    errors={errors}
                    control={control}
                    // rules={{ required: t('This is required') }}
                />
            </div>
            <div className="modal-input column-span-2">
                <CustomObjectInput
                    type="accordion-textarea"
                    classes={['info-input']}
                    id="info-input"
                    label={t('Info')}
                    name="info"
                    register={register}
                    stateField={calendarFormModalObject.info}
                    setStateField={updateCalendarFormModalObject}
                    errors={errors}
                    control={control}
                    // rules={{ required: t('This is required') }}
                />
            </div>
        </Dialog>
    );
};

export default CalendarFormModal;

import React from 'react';
import { Menubar } from 'primereact/menubar';
import { Button } from 'primereact/button';
import './ActionMenu.scss';

export const ActionMenu = ({ menu, menuEnd }) => {
    return (
        <div className="menu-bar">
            <Menubar
                model={menu}
                end={menuEnd?.map((menu, index) => {
                    return (
                        <Button
                            key={index}
                            disabled={menu.disabled}
                            label={menu.label}
                            icon={menu?.icon}
                            className={`p-button-text button ${menu.className}`}
                        />
                    );
                })}
            />
        </div>
    );
};

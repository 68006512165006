import { Config } from '../Config';
import { CrudService } from './CrudService';
import { AxiosResponse } from "axios";
import { CreateQueryParams } from '@nestjsx/crud-request';
import { Inquiry } from '../models/Inquiry';
import { BaseService } from './BaseService';
import { axiosDefaults } from './AxiosDefaults';

export class ExposeService extends BaseService {
    protected getUrlPath(): string {
        return '/expose';
    }

    public getExposePath(id: number): string {
        return `${axiosDefaults.baseURL}${this.getUrlPath()}/${id}`;
    }
}

export const exposeService = new ExposeService();

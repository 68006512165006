export const propertyNameMap = (t) => ({
    'countryId': t('Zemlja'),
    'stateId': t('Savezna država'),
    'street': t('Ulica'),
    'houseNumber': t('Kućni broj'),
    'entrance':t('Ulaz'),
    'door':t('Vrata'),
    'plz':t('Poštanski broj'),
    'place':t('Mesto'),
    'sideOfTheWorld':t('Strana sveta'),
    'proximity':t('Blizina'),
    'keyInfo':t('Ključne informacije'),
    'floorNumber': t('Sprat'),
    'floorDescription': t('Sprat - Dodatni opis'),
    'floor': t('Broj spratova'),
    'numberOfAtticFloors': t('Broj potkrovlja'),
    'hasMezzanine': t('Medjusprat'),
    'publishTheAddressTemplate':  t('Objavite adresu sa sledećom tačnošću'),
    'hwb': t('HWB'),
    'class':t('Klasse'),
    'fgee': t('fGEE'),
    'fgeeClass': t('Klasse'),
    'energyCertificateDateOfExpiry': t('Važi od'),
    'automaticDisclaimer': t('Automaticher Disclaimer'),
    'employeeId': t('Supervisor'),
    'labelling': t('Označavanje'),
    'barrierFree': t('Bez prepreka'),
    'propertyTypeId':t('Tip nekretnine'),
    'isBuildingPlot': t('Građevinsko zemljište'),
    'floorPlan': t('Tlocrt'),
    'alsoVacationProperty': t('Prikladno u turističke svrhe'),
    'typeOfUseLiving': t('Stanovanje'),
    'typeOfUseBusiness': t('Poslovna'),
    'typeOfUseInvestment': t('Investicija'),
    'sellerId': t('Kontakt'),
    'mediationOrderType': t('Tip'),
    'agreement': t('Dogovor'),
    'validFrom': t('Gültig von'),
    'dateOfExpiry': t('Datum isteka'),
    'depositNumber': t('Broj priloška'),
    'cadastreNumber': t('Katastarska zajednica'),
    'lotNumber': t('Broj parcele'),
    'maximumRentalPeriodYears': t('Maximum rental period'),
    'unlimitedRentalPeriod': t('Unlimited rental period'),
    'acquisitionType': 'Source',
    'publishPrices': t('Publish prices'),
    'interestedPartyInput': t('Interested party'),
    'output1': t('Output'),
    'autofill': t('Autofill'),
    'surrenderInput': t('Surrender'),
    'note': t('Notes'),
    'pricePerSquare': t('m² - price from'),
    'price.priceType': t('Vermarktungsart'),
    'price.netPurchasePrice': t('Netto'),
    'price.purchasePriceTax': t('USt.'),
    'price.grossPurchasePrice': t('Brutto'),
    'price.netParkingCost': t('Optional'),
    'price.netRentCost': t('Rent costs'),
    'other.status': t('Status'),
    'other.houseCondition': t('Stanje kuće'),
    'other.formOfOwnership': t('Oblik svojine'),
    'other.furnishing': t('Opremanje'),
    'other.situationAssesment': t('Procena situacije'),
    'other.conditionAssesment': t('Procena stanja'),
    'other.noiseLevel': t('Nivo buke'),
    'other.development': t('Razvoj'),
    'other.relatable': t('Izvoz interfejsa (Exportschnittstellen)'),
    'other.constructionYear': t('Godina izgradnje'),
    'other.lastRenovationYear': t('Poslednje saniranje'),
    'other.lastGeneralRenovation': t('Poslednje renoviranje'),
    'other.keyInHand': t('Ključ u ruke'),
    'other.readyForCovering': t('Spreman za pokrivanje'),
     "surface.plotArea": t('Površina'),
     "surface.livingArea": t('Stambena površina'),
     "surface.usableArea": t('Korisna površina'),
     "surface.buildableArea": t('Površina dozv. za gradnju'),
     "surface.officeArea": t('Kancelarijski prostor'),
     "surface.salesArea": t('Prodajni prostor'),
     "surface.storageArea": t('Skladišni prostor'),
     "surface.hallheight": t('Visina hale'),
     "surface.freeArea": t('Slobodan prostor'),
     "surface.temporaryArea": t('Privremena površina'),
     "surface.weightedArea": t('Ponderisano područje'),
     "surface.atticArea": null,
     "surface.unfinishedAttic": t('Nedovršeno potkrovlje'),
     "surface.unfinishedAtticArea": t('Površina potkrovlja'),
     "surface.numberOfRooms": t('Broj soba'),
     "surface.hasHalfRoom": t('0.5 soba'),
     "surface.roomHeight": t('Visina sobe'),
     "surface.numberOfGardens": t('Broj vrtova'),
     "surface.gardenArea": t('Površina vrtova'),
     "surface.numberOfBasements": t('Broj podruma'),
     "surface.basementArea": t('Površina podruma'),
     "surface.numberOfBalconies": t('Broj balkona'),
     "surface.balconyArea": t('Površina balkona'),
     "surface.numberOfTerraces": t('Broj terasa'),
     "surface.terraceArea": t('Površina terasa'),
     "surface.numberOfLoggias": t('Broj lođa'),
     "surface.loggiasArea": t('Površina lođa'),
     "surface.numberOfGarages": t('Broj garaža'),
     "surface.garageArea": t('Površina garaža'),
     "surface.numberOfParkingSpaces": t('Broj parking mesta'),
     "surface.parkingArea": t('Površina parkinga'),
     "surface.numberOfBedrooms": null,
     "surface.numberOfLivingRooms": null,
     "surface.numberOfToilets": t('Broj toaleta'),
     "surface.numberOfBathrooms": t('Broj kupatila'),
     "surface.numberOfStorageRooms": t('Broj ostava'),
    "text.title": t(
        'Titel (wird exportiert / veröffentlicht) / Naslov (biće izvezen / objavljen)'),
    "text.descriptionForTheInternet": t(
        'Beschreibung Internet (wird exportiert / veröffentlicht) / Opis internet (izvozi se / objavljuje)'
    ),
    "text.location": t(
        'Lage (wird exportiert / veröffentlicht) / Lokacija (biće izvezena / objavljena)',
    ),
    "text.shortDescription": t('Schulen (Skole)'),
    "text.publicTransportDescription": t('Offentliche Verkehrsmittel (Javni prevoz)'),
    "text.description": t('Einkaufen (Kupovina)'),
    "text.otherInfoForTheInternet": t(
        'Sonstige Angaben Internet (wird exportiert / veröffentlicht) / Ostale informacije Internet  (izvozi se / objavljuje)',
    ),
    "text.text1": t(
        'Interne Beschreibung (wird nicht bei externen Freigaben angezeigt) / Interni opis (nije prikazan za spoljne deljene datoteke)',
    ),
    "text.text2": t(
        'Kurzbeschreibung Exposé (wird im Standard Exposé Auslage verwendet) / Kratki opis Expose (koristi se u standardnom eskpozeu) Prikaz',
    ),
    "text.text3": t('Beschreibung Exposé / Opis Expose'),
    "internal.saleDate": t('Verkauf am'),
    "internal.sellerId": t('Kaufer'),
    "internal.offerPrice": t('Preis it. Kaufanbot'),
    "internal.contractPrice": t('Preis lt. Kaufanbot'),
    "internal.absoluteSellerCommissionAmmount": t('Provision Verkaufer absolut'),
    "internal.invoicingDateSeller": t('Rechnung gestelit am'),
    "internal.sellerCashInflowDate": t('Zahlungseinggang Datum'),
    "internal.absoluteBuyerCommissionAmmount": t('Provision Kaufer absolut'),
    "internal.invoicingDateBuyer": t('Rechnung gestelit am'),
    "internal.buyerCashInflowDate": t('Zahlungseinggang Datum'),
    // "furnishing.constructionType": ,
    // "furnishing.roofShape": ,
    // "furnishing.expansionStage": ,
    // "furnishing.floor": ,
    // "furnishing.elevator": ,
    // "furnishing.heating": ,
    // "furnishing.heatingType": ,
    // "furnishing.ventilation": ,
    // "furnishing.energyType": ,
    // "furnishing.view": ,
    // "furnishing.balcony": ,
    // "furnishing.space": ,
    // "furnishing.window": ,
    // "furnishing.lighting": ,
    // "furnishing.electricEnergy": ,
    // "furnishing.tv": ,
    // "furnishing.toilet": ,
    // "furnishing.bathroom": ,
    // "furnishing.kitchen": ,
    // "furnishing.gastronomy": ,
    // "furnishing.parkingSpaceType": ,
    // "furnishing.extras": ,
    // "furnishing.services": ,
    // "furnishing.certificates":
});

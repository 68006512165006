import React from 'react';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { Card } from 'primereact/card';
import CardHeader from '../../card/CardHeader';
import './ObjectFurnishingForm.scss';
import FurnishingCheckbox from './furnishingCheckbox/FurnishingCheckbox';
import {
    furnishingFields,
    FurnishingField,
} from '../../../constants/ObjectConsts';
import * as _ from 'lodash';
import { useTranslation } from 'react-i18next';

const ObjectFurnishingForm = ({ furnishingObject, updateFurnishingObject }) => {
    const [furnishingState, setFurnishingState] = React.useState(
        [...Array(3).keys()].map((c) =>
            furnishingFields
                .map((x) => {
                    const furnishingField = furnishingObject[x.attribute];
                    x.fields = x.fields.map((y) => {
                        let fieldValue = furnishingField[y.attribute];
                        y.value = fieldValue;
                        return y;
                    });
                    return x;
                })
                .filter((el, i) => i % 3 === c),
        ),
    );
    const { t } = useTranslation();

    const updateFurnishingFieldState = (
        columnIndex: number,
        accordion: string,
        field: string,
        value: boolean,
    ) => {
        const furnishingStateClone = _.cloneDeep(furnishingState);

        const _accordion = furnishingStateClone[columnIndex].find(
            (x) => x.label === accordion,
        );

        if (_accordion) {
            const _field = _accordion.fields.find((x) => x.attribute === field);

            if (_field) {
                _field.value = value;
            }
        }

        setFurnishingState(furnishingStateClone);
        updateFurnishingObject(furnishingStateClone);
    };

    const formatAccordionHeader = (
        label: string,
        furnishingFields: FurnishingField[],
    ) => {
        let isCheckedAnyField: boolean = false;
        for (let field of furnishingFields) {
            if (field.value === true) {
                isCheckedAnyField = true;
            }
        }

        return (
            <div className="accordion-header-grid">
                <div>{label}</div>
                <div className="center">
                    {isCheckedAnyField ? (
                        <i className="pi pi-check green" />
                    ) : (
                        <i className="pi pi-times red" />
                    )}
                </div>
            </div>
        );
    };

    return (
        <div className="wrapper">
            <Card
                className="object-furnishing-wrapper"
                header={<CardHeader title={t('Opremanje')} />}
            >
                <div className="object-furnishing-container">
                    {furnishingState.map((column, index) => (
                        <div key={index} className="furnishing-column">
                            {column.map((accordion, index2) => (
                                <Accordion
                                    key={index2}
                                    className="furnishing-accordion margin"
                                >
                                    <AccordionTab
                                        header={formatAccordionHeader(
                                            accordion.label,
                                            accordion.fields,
                                        )}
                                    >
                                        <div className="checkbox-grid">
                                            {accordion.fields.map(
                                                (field, index3) => (
                                                    <FurnishingCheckbox
                                                        attribute={
                                                            field.attribute
                                                        }
                                                        key={index3}
                                                        label={field.label}
                                                        value={field.value}
                                                        onChange={(value) =>
                                                            updateFurnishingFieldState(
                                                                index,
                                                                accordion.label,
                                                                field.attribute,
                                                                value,
                                                            )
                                                        }
                                                    />
                                                ),
                                            )}
                                        </div>
                                    </AccordionTab>
                                </Accordion>
                            ))}
                        </div>
                    ))}
                </div>
            </Card>
        </div>
    );
};

export default ObjectFurnishingForm;

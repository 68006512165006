import { Card } from 'primereact/card';
import React, { useState, useEffect } from 'react';
import './ObjectInternForm.scss';
import CardHeader from '../../card/CardHeader';
import CustomObjectInput from '../customObjectInput/CustomObjectInput';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

const ObjectInternForm = ({
    realEstateObject,
    updateRealEstateObject,
    control,
    register,
    errors,
    contact,
}) => {
    const [searchContact, setSearchContact] = useState(null);

    useEffect(() => {
        if (realEstateObject?.internal?.sellerId !== null) {
            setSearchContact(3);
        }
    }, []);

    const { t } = useTranslation();
    return (
        <div className="intern-page">
            <div className="intern-inputs">
                <Card
                    className="intern"
                    header={<CardHeader title={t('Interno')} />}
                >
                    <div className="section-row-input column-span-1">
                        <div className="text-input-intern">
                            <label className="label">{t('Verkauf am')}</label>
                        </div>
                        <div className="modal-input">
                            <CustomObjectInput
                                type="calendar"
                                id="verkauf-am"
                                name="internal_saleDate"
                                dateType={'date'}
                                stateField={
                                    moment(
                                        realEstateObject.internal.saleDate,
                                    ).format('YYYY') === '1970'
                                        ? ''
                                        : realEstateObject.internal.saleDate
                                }
                                setStateField={updateRealEstateObject}
                                classes={['no-border-radius', 'generic-input']}
                                containerClassNames={[
                                    'p-inputgroup',
                                    'generic-input',
                                ]}
                                control={control}
                                errors={errors}
                            />
                        </div>
                    </div>
                    <div className="section-row-input column-span-1">
                        <div className="text-input-intern">
                            <label className="label">
                                {t('Provision Verkaufer absolut')}
                            </label>
                        </div>
                        <div className="modal-input">
                            <CustomObjectInput
                                type="number-with-addon"
                                addonLabel="%"
                                containerClassNames={[
                                    'p-inputgroup',
                                    'generic-input',
                                ]}
                                classes={['no-border-radius', 'generic-input']}
                                id="provision-verkaufer-absolut"
                                name="internal_absoluteSellerCommissionAmmount"
                                stateField={
                                    realEstateObject.internal
                                        .absoluteSellerCommissionAmmount
                                }
                                setStateField={updateRealEstateObject}
                                errors={errors}
                                control={control}
                                decimal={true}
                            />
                        </div>
                    </div>
                    <div className="section-row-input column-span-1">
                        <div className="text-input-intern">
                            <label className="label">
                                {t('Provision Kaufer absolut')}
                            </label>
                        </div>
                        <div className="modal-input">
                            <CustomObjectInput
                                type="number-with-addon"
                                addonLabel="%"
                                containerClassNames={[
                                    'p-inputgroup',
                                    'generic-input',
                                ]}
                                classes={['no-border-radius', 'generic-input']}
                                id="provision-kaufer-absolut"
                                name="internal_absoluteBuyerCommissionAmmount"
                                stateField={
                                    realEstateObject.internal
                                        .absoluteBuyerCommissionAmmount
                                }
                                setStateField={updateRealEstateObject}
                                errors={errors}
                                control={control}
                                decimal={true}
                            />
                        </div>
                    </div>
                    <div className="section-row-input column-span-1">
                        <div className="text-input-intern">
                            <label className="label">{t('Kaufer')}</label>
                        </div>
                        <div className="modal-input">
                            <CustomObjectInput
                                type="dropdown"
                                id="kaufer"
                                name="internal_sellerId"
                                stateField={realEstateObject.internal.sellerId}
                                filter={true}
                                onFilter={(e) =>
                                    setSearchContact(e.filter.length)
                                }
                                emptyFilterMessage={'Search contacts...'}
                                dropdownOptions={
                                    searchContact > 2
                                        ? contact.filter(
                                              (x) => x.role === 'Buyer',
                                          )
                                        : [
                                              {
                                                  label: 'Search contacts...',
                                                  disabled: true,
                                              },
                                          ]
                                }
                                classes={[
                                    'generic-input',
                                    'no-border-radius',
                                    'adjust-line-height',
                                ]}
                                setStateField={updateRealEstateObject}
                                control={control}
                                errors={errors}
                            />
                        </div>
                    </div>
                    <div className="section-row-input column-span-1">
                        <div className="text-input-intern">
                            <label className="label">
                                {t('Rechnung gestelit am')}
                            </label>
                        </div>
                        <div className="modal-input">
                            <CustomObjectInput
                                type="calendar"
                                id="rechnung-gestelit"
                                name="internal_invoicingDateSeller"
                                dateType={'date'}
                                stateField={
                                    moment(
                                        realEstateObject.internal
                                            .invoicingDateSeller,
                                    ).format('YYYY') === '1970'
                                        ? ''
                                        : realEstateObject.internal
                                              .invoicingDateSeller
                                }
                                setStateField={updateRealEstateObject}
                                classes={['generic-input', 'no-border-radius']}
                                containerClassNames={[
                                    'p-inputgroup',
                                    'calendar-input',
                                ]}
                                control={control}
                                errors={errors}
                            />
                        </div>
                    </div>

                    <div className="section-row-input column-span-1">
                        <div className="text-input-intern">
                            <label className="label">
                                {t('Rechnung gestelit am')}
                            </label>
                        </div>
                        <div className="modal-input">
                            <CustomObjectInput
                                type="calendar"
                                id="rechnung-gestelit-am"
                                name="internal_invoicingDateBuyer"
                                dateType={'date'}
                                stateField={
                                    moment(
                                        realEstateObject.internal
                                            .invoicingDateBuyer,
                                    ).format('YYYY') === '1970'
                                        ? ''
                                        : realEstateObject.internal
                                              .invoicingDateBuyer
                                }
                                setStateField={updateRealEstateObject}
                                classes={['generic-input', 'no-border-radius']}
                                control={control}
                                containerClassNames={[
                                    'p-inputgroup',
                                    'calendar-input',
                                ]}
                                errors={errors}
                            />
                        </div>
                    </div>
                    <div className="section-row-input column-span-1">
                        <div className="text-input-intern">
                            <label className="label">
                                {t('Preis it. Kaufanbot')}
                            </label>
                        </div>
                        <div className="modal-input">
                            <CustomObjectInput
                                type="number-with-addon"
                                id="preis-it-kaufanbot"
                                name="internal_offerPrice"
                                stateField={
                                    realEstateObject.internal.offerPrice
                                }
                                setStateField={updateRealEstateObject}
                                classes={['generic-input', 'no-border-radius']}
                                addonLabel="€"
                                control={control}
                                errors={errors}
                            />
                        </div>
                    </div>
                    <div className="section-row-input column-span-1">
                        <div className="text-input-intern">
                            <label className="label">
                                {t('Zahlungseinggang Datum')}
                            </label>
                        </div>
                        <div className="modal-input">
                            <CustomObjectInput
                                type="calendar"
                                id="zahlungseinggang-datum"
                                name="internal_sellerCashInflowDate"
                                dateType={'date'}
                                stateField={
                                    moment(
                                        realEstateObject.internal
                                            .sellerCashInflowDate,
                                    ).format('YYYY') === '1970'
                                        ? ''
                                        : realEstateObject.internal
                                              .sellerCashInflowDate
                                }
                                setStateField={updateRealEstateObject}
                                classes={['generic-input', 'no-border-radius']}
                                control={control}
                                containerClassNames={[
                                    'p-inputgroup',
                                    'calendar-input',
                                ]}
                                errors={errors}
                            />
                        </div>
                    </div>
                    <div className="section-row-input column-span-1">
                        <div className="text-input-intern">
                            <label className="label">
                                {t('Zahlungseinggang Datum')}
                            </label>
                        </div>
                        <div className="modal-input">
                            <CustomObjectInput
                                type="calendar"
                                id="zahlungseinggang"
                                name="internal_buyerCashInflowDate"
                                dateType={'date'}
                                stateField={
                                    moment(
                                        realEstateObject.internal
                                            .buyerCashInflowDate,
                                    ).format('YYYY') === '1970'
                                        ? ''
                                        : realEstateObject.internal
                                              .buyerCashInflowDate
                                }
                                setStateField={updateRealEstateObject}
                                classes={['generic-input', 'no-border-radius']}
                                control={control}
                                containerClassNames={[
                                    'p-inputgroup',
                                    'calendar-input',
                                ]}
                                errors={errors}
                            />
                        </div>
                    </div>
                </Card>
            </div>
        </div>
    );
};

export default ObjectInternForm;

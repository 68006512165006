import { t } from 'i18next';

export enum ContactType {
    PERSON = 'Person',
    PAIR = 'Pair',
    FAMILY = 'Family',
    COMPANY = 'Company',
}

export enum ContactRole {
    BUYER = 'Buyer',
    SELLER = 'Seller',
}

export const TypeOfContact = [
    { label: t('Person'), value: 'Person' },
    { label: t('Pair'), value: 'Pair' },
    { label: t('Family'), value: 'Family' },
    { label: t('Company'), value: 'Company' },
];

export const ContactRoleOptions = [
    { label: t('Buyer'), value: ContactRole.BUYER },
    { label: t('Seller'), value: ContactRole.SELLER },
];

export const ContactCategories = [
    { label: 'category 1', value: 1 },
    { label: 'category 2', value: 2 },
    { label: 'category 3', value: 3 },
];

export const SalutationType = [
    { label: t('Mr.'), value: 'Mr.' },
    { label: t('Mrs.'), value: 'Mrs.' },
    // { label: t('Miss.'), value: 'Miss.' },
];

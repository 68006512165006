module.exports = {
    "Username or password is wrong": "Benutzername oder Passwort ist falsch",
    "Unknown error": "Unbekannter Fehler",
    "Unknown user": "Unbekannter Benutzer",
    "Confirm email": "E-Mail Adresse bestätigen",
    "Password": "Passwort",
    "Users": "Benutzer",
    "User": "Benutzer",
    "Username": "Benutzername",
    "Send forgotten password": "Passwort erneut senden",
    "Reset password": "Passwort zurücksetzen",
    "Sign up": "Registrieren",
    "E-mail address": "E-Mail-Adresse",
    "Back": "Zurück",
    "Forgotten password?": "Passwort vergessen?",
    "CONTINUE": "FORTSETZEN",
    "Forgotten password": "Vergessenes Passwort",
    "Password must have min 8 character, 1 uppercase, 1 lowercase, 1 number and 1 special character": "Das Passwort muss mindestens 8 Zeichen, 1 Großbuchstaben, 1 Kleinbuchstaben, 1 Zahl und 1 Sonderzeichen haben",
    "password dont match!": "Passwort stimmt nicht überein!",
    "Password change successfully!": "Passwortänderung erfolgreich!",
    "Password reset": "Passwort zurücksetzen",
    "Repeat password": "Passwort wiederholen",
    "Reset password error": "Fehler bei Passwort Zurücksetzen",
    "Edit": "Bearbeiten",
    "Delete": "Löschen",
    "Edit Event": "Veranstaltung bearbeiten",
    "Title": "Titel",
    "This is required": "Diese Eingabe ist erforderlich",
    "Activity type": "Aktivität Art",
    "Date and time from": "Datum und Zeit von",
    "Date and time to": "Datum und Zeit bis",
    "Object": "Objekt",
    "Contact": "Kontakt",
    "Info": "Info",
    "Users page": "Benutzerseite",
    "Check": "Prüfen",
    "Refresh": "Aktualisierung",
    "New Task": "Neue Aufgabe",
    "Check your email": "Prüfen Sie Ihre E-Mails",
    "Ok": "Ok",
    "New Activity - ": "Neue Aktivität ",
    "Date": "Datum",
    "Time": "Zeit",
    "Comment": "Kommentar",
    "Enter a valid email": "Geben Sie eine gültige E-Mail-Adresse ein",
    "Address / location": "Adresse / Standort",
    "Zemlja": "Land",
    "Savezna država": "Bundesland",
    "Ulica": "Straße",
    "Kućni broj": "Hausnummer",
    "Ulaz": "Eingang",
    "Vrata": "Tür Nr.",
    "Poštanski broj": "PLZ",
    "Mesto": "Ort",
    "Strana sveta": "Ausrichtung",
    "Blizina": "In der Nähe",
    "Ključne informacije": "Information über Schlüsselhinterlegung",
    "Objavite adresu sa sledećom tačnošću": "Adresse mit folgender Genauigkeit veröffentlichen",
    "Greška pri resetu šifre": "Fehler beim Zurücksetzen des Passworts",
    "Nazad": "Zurück",
    "Prijavite se": "Anmelden",
    "E-mail addresa": "E-Mail-Addresse",
    "Zaboravljena lozinka?": "Haben Sie Ihr Passwort vergessen?",
    "NASTAVI": "FORTSETZEN",
    "Zaboravljena šifra": "Passwort vergessen?",
    "Resetovanje šifre": "Passwort zurücksetzen",
    "Ponovi lozinku": "Passwort wiederholen",
    "Površine / Flächen": "Fläche",
    "Površina": "Fläche",
    "Stambena površina": "Wohnfläche",
    "Korisna površina": "Nutzfläche",
    "Površina dozv. za gradnju": "Bebaubare Fläche",
    "Kancelarijski prostor": "Büroraum",
    "Prodajni prostor": "Verkaufsfläche",
    "Skladišni prostor": "Lagerraum",
    "Visina hale": "Hallenhöhe",
    "Slobodan prostor": "Freie Fläche",
    "Privremena površina": "Befristete Fläche",
    "Ponderisano područje": "Gewichteter Bereich",
    "Nedovršeno potkrovlje": "Rohdachboden",
    "Površina potkrovlja": "Dachbodenfläche",
    "Broj soba": "Zimmeranzahl",
    "0.5 soba": "0,5 Zimmer",
    "Visina sobe": "Raumhöhe",
    "Broj vrtova": "Gartenanzahl",
    "Površina vrtova": "Gartenfläche",
    "Broj podruma": "Kelleranzahl",
    "Površina podruma": "Kellerfläche",
    "Broj balkona": "Balkonanzahl",
    "Površina balkona": "Balkonfläche",
    "Broj terasa": "Terrassenanzahl",
    "Površina terasa": "Terrassenfläche",
    "Broj lođa": "Loggiaanzahl",
    "Površina lođa": "Loggiafläche",
    "Broj garaža": "Garagenanzahl",
    "Površina garaža": "Garagenfläche",
    "Broj parking mesta": "Parklätzeanzahl",
    "Površina parkinga": "Parkplatzfläche",
    "Broj toaleta": "Toiliettenanzahl",
    "Broj kupatila": "Badezimmeranzahl",
    "Broj ostava": "Abstellraumanzahl",
    "Spratnost / Etagen": "Etage",
    "Sprat": "Etage",
    "Sprat - Dodatni opis": "Etage (Zusatzbeschreibung)",
    "Broj spratova": "Gesamt Etagenanzahl",
    "Broj potkrovlja": "Dachgeschoßeanzahl",
    "Medjusprat": "Zwischengeschoss",
    "Energetski sertifikat / Enegieausweis": "Energieausweis",
    "HWB": "HWB",
    "Klasse": "Klasse",
    "fGEE": "fGEE",
    "Važi od": "Gültig ab",
    "Automaticher Disclaimer": "Automatischer Haftungsausschluss",
    "Supervisor": "Makler",
    "Opšte / Allgemein": "Allgemein",
    "Označavanje": "Kennzeichnung",
    "Bez prepreka": "Barrierefrei",
    "Tip nekretnine": "Objektart",
    "Građevinsko zemljište": "Baugrund",
    "Tlocrt": "Grundriss",
    "Prikladno u turističke svrhe": "Ferienimmobilie",
    "Vrste upotrebe objekata / Nutzungsart": "Nutzungsart",
    "Stanovanje": "Wohnen",
    "Poslovna": "Gewerbe",
    "Investicija": "Anlage",
    "Prodavac objekta / Objektabgeber": "Verkäufer",
    "Kontakt": "Kontakt",
    "Vlasnički udeo": "Eigentumsanteil",
    "Ukloniti": "Löschen",
    "Abgeber hinzufügen": "Verkäufer hinzufügen",
    "Neuen Abgeber anlegen": "Verkäufer erfassen",
    "Nalog za posredovanje": "Vermittlungsauftrag",
    "Tip": "Typ",
    "Dogovor": "Vereinbarung",
    "Datum isteka": "Gültig bis",
    "Zemljišne knjige": "Grundbuch",
    "Broj priloška": "Einlagezahl",
    "Katastarska zajednica": "Katastralgemeinde",
    "Broj parcele": "Parzellen Nr. ",
    "Miete / Kirija": "Miete",
    "Maximum rental period": "Maximale Mietdauer",
    "Unlimited rental period": "Unbefristete Mietdauer",
    "Waiver of termination": "Verzicht auf Kündigung",
    "Sonstiges / Drugo": "Sonstiges ",
    "Status": "Status",
    "Stanje kuće": "Hauszustand",
    "Godina izgradnje": "Baujahr",
    "Poslednje saniranje": "Letzte Sanierung",
    "Poslednje renoviranje": "Letzte Renovierung",
    "Oblik svojine": "Eigentumsform",
    "Opremanje": "Ausstattung",
    "Procena situacije": "Einschätzung der Lage",
    "Nivo buke": "Lärmbelastung ",
    "Razvoj": "Entwicklung",
    "Procena stanja": "Zustandsbewertung",
    "Ključ u ruke": "Schlüsselfertig",
    "Spreman za pokrivanje": "Belagsfertig",
    "Slike / Bilder": "Bilder",
    "Planovi / Plane": "Pläne",
    "Video snimci / videos": "Videos",
    "Dokumenta / Dokumente": "Dokumente",
    "360 pogled / 360 Ansichten": "360 Grad Ansicht",
    "Dateien & Links": "Dateien & Links",
    "Documente werden exportiert / veroffentlicht\"": "Dokumente werden exportiert / veröffentlicht",
    "Alle": "Alle",
    "Expose": "Expose",
    "Intern": "Intern",
    "Loschen": "Löschen",
    "Planovi/Plane": "Pläne",
    "Video snimci/videos": "Videos",
    "Dokumenta/Dokumente": "Dokumente",
    "360 pogled/360 Ansichten": "360 Grad Ansicht",
    "Opremljenost": "Ausstattung",
    "Interno": "Intern",
    "Verkauf am": "Verkauf am",
    "Provision Verkaufer absolut": "Provision Verkäufer Betrag",
    "Provision Kaufer absolut": "Provision Käufer Betrag",
    "Kaufer": "Käufer",
    "Rechnung gestelit am": "Rechnung erstellt am",
    "Preis it. Kaufanbot": "Preis lt. Kaufanbot",
    "Zahlungseinggang Datum": "Zahlungseinggang Datum",
    "Izvoz interfejsa (Exportschnittstellen)": "Exportschnittstellen",
    "Platforma": "Plattform",
    "Broj objavljenih nekretnina": "Anzahl exportierte Objekte",
    "Maksimalni broj nekretnina": "Maximalanzahl Objekte",
    "Vermarktungsart / Tip marketinga*": "Vermarktungsart  ",
    "Kupovina": "Eigentum",
    "Kirija": "Miete",
    "Zakup": "Mieten",
    "Preise / cene": "Preise  ",
    "Kaufpreis": "Kaufpreis",
    "Netto": "Netto",
    "USt.": "MwSt.",
    "Brutto": "Brutto",
    "Publish prices": "Preis auf Anfrage",
    "Mesecne naknade": "Monatliche Kosten",
    "Parking": "Parkplatz",
    "Optional": "Optional",
    "Garaža": "Garage",
    "Operativni troškovi": "Hausgeld",
    "Troškovi grejanja": "Heizkosten",
    "Vruća voda": "Warmwasser",
    "Troškovi hlađenja": "Kühlung",
    "Fond popravki": "Instandhaltungsfond",
    "Ostalo": "Andere",
    "Održavanje lifta": "Fahrstuhlwartung Kosten",
    "Total": "Gesamt",
    "Preise pro m² / Cena po m2": "Preis pro m² ",
    "m² - price from": "m² - Preis ab",
    "Purchase price / m²": "Kaufpreis / m²",
    "Operational costs / m²": "Hausgeld / m²",
    "Interested party": "Interessent",
    "Output": "Ergebniss",
    "Autofill": "Automatisches Ausfüllen",
    "Surrender": "Aufgeben",
    "Notes": "Kommentare",
    "Text": "Text",
    "Titel (wird exportiert / veröffentlicht) / Naslov (biće izvezen / objavljen)": "Titel (wird veröffentlicht)",
    "Beschreibung Internet (wird exportiert / veröffentlicht) / Opis internet (izvozi se / objavljuje)": "Beschreibung Internet (wird veröffentlicht)",
    "Lage (wird exportiert / veröffentlicht) / Lokacija (biće izvezena / objavljena)": "Lage (wird veröffentlicht)",
    "Schulen (Skole)": "Schulen  ",
    "Offentliche Verkehrsmittel (Javni prevoz)": "Offentliche Verkehrsmittel",
    "Einkaufen (Kupovina)": "Einkaufen",
    "Sonstige Angaben Internet (wird exportiert / veröffentlicht) / Ostale informacije Internet  (izvozi se / objavljuje)": "Sonstige Angaben Internet (wird veröffentlicht)",
    "Interne Beschreibung (wird nicht bei externen Freigaben angezeigt) / Interni opis (nije prikazan za spoljne deljene datoteke)": "Interne Beschreibung (wird nicht veröffentlicht)",
    "Kurzbeschreibung Exposé (wird im Standard Exposé Auslage verwendet) / Kratki opis Expose (koristi se u standardnom eskpozeu) Prikaz": "Kurzbeschreibung Exposé (wird im Standard Exposé Auslage verwendet)",
    "Beschreibung Exposé / Opis Expose": "Beschreibung Exposé",
    "Base data": "Eckdaten",
    "Prices": "Preise",
    "Furnishing": "Ausstattung",
    "Marketing": "Marketing",
    "Aufgaben & Aktivitäten": "Aufgaben & Aktivitäten",
    "Internal": "Intern",
    "Aufgaben & Aktivitaten": "Aufgaben & Aktivitäten",
    "Meine interessent": "Meine Interessenten",
    "Overdue": "Überfällig",
    "Name": "Name",
    "UNTOUCHED": "Neu",
    "ID": "ID",
    "Meine Objekte": "Meine Objekte",
    "Total portfolio volume 8.500.000 eur": "Objektbestand Volumen",
    "IN PROGRESS": "IN BEARBEITUNG",
    "Meine First Dates": "Meine First Dates",
    "In Bearbietung": "In Bearbietung",
    "Bestatigt": "Bestätigt",
    "Tel Wiedervorl": "Tel. Wiedervorlage",
    "On Hold": "On Hold",
    "Meine Servise Termine": "Meine Servicetermine",
    "This month": "Monat laufend",
    "Meine Preisduktion": "Meine Preisreduktion",
    "Target 5% from my real estate portfolio value": "Ziel: 5% vom Objektvolumen",
    "RESPONSE TIME": "ANTWORTZEIT",
    "Untouched to in Progress": "Zeitraum bis Erstbearbeitung",
    "Average Mon-Sut": "Durchschnitt Mo - Sa",
    "Meine First Datest": "Meine First Dates",
    "RESULTS": "RESULTAT",
    "This Months": "Monat laufend",
    "Last 3 Months": "Letzte 3 Monate",
    "Absage": "Absage",
    "Mandat erhalten": "Mandat erhalten",
    "List view": "Listenansicht",
    "Calendar view": "Kalenderansicht",
    "Please enter a new title for your event": "Bitte geben Sie einen neuen Titel für Ihre Veranstaltung ein",
    "Empty Page": "Leere Seite",
    "Use this page to start from scratch and place your custom content.": "Verwenden Sie diese Seite, um bei Null anzufangen und Ihre benutzerdefinierten Inhalte zu platzieren.",
    "Sunday": "Sonntag",
    "Monday": "Montag",
    "Tuesday": "Dienstag",
    "Wednesday": "Mittwoch",
    "Thursday": "Donnerstag",
    "Friday": "Freitag",
    "Saturday": "Samstag",
    "Untouched": "Neu ",
    "In Bearbeitung": "In Bearbeitung",
    "Calendar": "Kalender",
    "Neu": "Neu",
    "Wiedervorlage": "Wiedervorlage",
    "Abgesagt": "Abgesagt",
    "On-hold": "On Hold",
    "Mandat-Erhalten": "Mandat erhalten",
    "Nicht-Aufgenommen": "Nicht aufgenommen",
    "akviza": "Akquise",
    "Ime": "Name",
    "Lokacija": "Standort",
    "Cena": "Preis",
    "Termindatum": "Termindatum",
    "Importantdatum": "Wichtiges Datum",
    "Telefon": "Telefon",
    "Aktiv": "Aktiv",
    "Passiv": "Ausgehend",
    "NE": "Nicht erreicht",
    "Mail": "Mail",
    "Versendet": "Versendet",
    "Empfangen": "Empfangen",
    "Welocome": "Willkomen",
    "Personlich": "Persönlich",
    "Kalender": "Kalender",
    "Geshprachsnotiz": "Geschprächsnotiz",
    "FD Status": "FD Status",
    "Nicht erreichbar": "Nicht Erreicht",
    "Bestätigt": "Bestätigt",
    "Nicht aufgenommen": "Nicht aufgenommen",
    "Zurück an CC": "Zurück an CC",
    "Wiedervorlage/WV": "Wiedervorlage",
    "Kontakt podaci/Kontaktdaten": "Kontaktdaten",
    "Saglasnost za ponude: NE": "Zustimmung zu Angebotempfang",
    "Add mail": "E-Mail hinzufügen",
    "Prikazi kontakt": "Kontakt anzeigen",
    "Nekretnina": "Objekt",
    "Alle Objekte": "Alle Objekte",
    "Tip nekretnine: ": "Objektart",
    "Cena: ": "Preis",
    "Broj soba: ": "Zimmeranzahl",
    "Stambena povrsina ": "Wohnfläche",
    "Prikazi objekat": "Objekt anzeigen",
    "Protokol/Status": "Protokoll",
    "Dodeljeno: ": "Zugeteilt",
    "Dodeljeno putem: Sistem": "Zugeteilt vom System",
    "Prvo pregledavanje: ": "Erstmalig angesehen",
    "Poslednja aktivnost: ": "Letzte Aktivität: ",
    "Status: ": "Status",
    "Termin: ": "Termin",
    "Nezainteresovanost: ": "Kein Interesse",
    "Aktivnosti/Aktivitäten": "Aktivitäten",
    "Kommunikation": "Kommunikation",
    "Korisnik": "Benutzer",
    "Akcija": "Handlung",
    "Komentar": "Kommentar",
    "Erneuern": "Erneuern",
    "Duplizieren": "Duplizieren",
    "Drucken": "Drucken",
    "Mandat Art": "Mandatart",
    "Stambena povrsina": "Wohnfläche",
    "Angebot": "Angebot",
    "Aktivität": "Aktivität",
    "Aufgabe": "Aufgabe",
    "Objekt duplizieren": "Objekt duplizieren",
    "Abg. Nachweis": "Verkäufer Nachweis",
    "In Vorbereitung": "In Vorbereitung",
    "Inactive": "Inaktiv",
    "VerkaufGH": "Verkauft",
    "Abgeschlossen": "Abgeschlossen",
    "No objects found": "Keine Objekte gefunden",
    "Records per page": "Einträge pro Seite",
    "Naslovna slika": "Titelbild",
    "Tip Objekta": "Objekttyp",
    "PTT Broj": "PLZ",
    "Adresa": "Adresse",
    "Prodavac": "Verkäufer",
    "Vrsta ugovora": "Mandatart",
    "Ugovor sklopljen": "Mandat abgeschlossen",
    "Kreirana nek.": "Objekt erstellt",
    "Exportovano": "Exportiert",
    "Ažurirano": "Aktualisiert",
    "Kreirana nekretnina": "Objekt erstellt",
    "Dodeljeno": "Zugeteilt",
    "Prvo pregledavanje": "Erstmalig angesehen",
    "Poslednja aktivnost": "Letzte Aktivität",
    "Termin": "Termin",
    "Nezainteresovanost": "Kein Interesse",
    "Ubersicht": "Übersicht",
    "Preis": "Preis",
    "Ausstattung": "Ausstattung",
    "Texte": "Texte",
    "Multimedia": "Multimedia",
    "Freigaben": "Freigaben",
    "Interessenten": "Interessenten",
    "Matching": "Matching",
    "Abgeschlossesn": "Abgeschlossen",
    "Gesamt": "Gesamt",
    "Besichtigungen": "Besichtigungen",
    "Titelbild": "Titelbild",
    "Eckdaten (osnovni podaci)": "Eckdaten  ",
    "Vrsta upotrebe": "Nutzungsart",
    "Tip marketinga": "Vermarktungsart",
    "Povrsina": "Fläche",
    "Adresa / Prodavac": "Verkäufer Adresse",
    "broj": "Anzahl",
    "PTT broj": "PLZ",
    "Hard Facts": "Daten & Fakten",
    "objekat kreiran": "Objekt erstellt",
    "broj dana u prodaji": "Anzahl Tage im Verkauf",
    "broj redukcija cene": "Preisreduktion Anzahl",
    "ukupan iznos redukcija cene": "Preisreduktion Gesamtbetrag",
    "poslednja promena u objektu": "Letzte Objektbearbeitung",
    "poslednja promena cene": "Letzte Preisänderung",
    "poslednja promena oglasa": "Letzte Inseratbearbeitung",
    "datum poslednjeg sastanka": "Letztes PG Verkäufer",
    "Checkliste Information": "Checkliste",
    "Naslov": "Titel",
    "Opis interneta": "Beschreibung Internet",
    "Kompletna adresa": "Vollständige Adresse",
    "Tip objekta": "Objekttyp",
    "Checkliste Dokumente": "Checkliste Dokumente",
    "Plan stana": "Grundriss",
    "Vazi samo za AT verziju": "Nur gültig für AT Version",
    "Kupoprodajni ugovor (Vazi samo za AT verziju)": "Kaufvertrag (nur gültig für AT Version)",
    "Energetski sertifikat": "Energieausweis",
    "Akontacija zajednickih": "Vorauszahlung von gemeinsamen",
    "Obracun zajednickih troskova": "Berechnung gemeinsamer Kosten",
    "Protokol sastanka": "Protokoll Eigentümerversammlung",
    "Resenje o nacinu obracuna": "Entscheidung über die Berechnungsmethode",
    "Polje za interne informacije": "Interne Informationen",
    "Broj telefona": "Telefonnummer",
    "Makler / Team, Ime, broj tel. i mail adresa agenta": "Makler",
    "Mail adresa agenta": "Makler Mail Adresse",
    "Internet": "Internet",
    "Aktivitaten": "Aktivitäten",
    "Dashboard": "Dashboard",
    "First date": "First Date",
    "Meine Interesent": "Meine Interessenten",
    "Reports": "Berichte",
    "Objects": "Objekte",
    "New object": "Neues Objekt",
    "Contacts": "Kontakte",
    "Verkaufer Kommunikation": "Verkäufer Kommunikation",
    "My Objects": "Meine Objekte",
    "New Object": "Neues Objekt",
    "First Date": "First Date",
    "Single View": "Detailansicht",
    "Objekat kreiran": "Objekt erstellt",
    "Broj dana u prodaji": "Anzahl Tage im Verkauf",
    "Broj redukcija cene": "Preisreduktion Anzahl",
    "Ukupan iznos redukcija cene": "Preisreduktion Gesamtbetrag",
    "Poslednja promena u objektu": "Letzte Objektbearbeitung",
    "Poslednja promena cene": "Letzte Preisänderung",
    "Poslednja promena oglasa": "Letzte Inseratbearbeitung",
    "Datum poslednjeg sastanka": "Datum des letzten Treffens",
    "Broj": "Anzahl",
    "Activity": "Aktivität",
    "New Sale": "Neuer Verkauf",
    "Withdrawal Initiated": "Auszahlung eingeleitet",
    "Question Received": "Frage erhalten",
    "Comment Received": "Kommentar erhalten",
    "Quick Withdraw": "Schnell abheben",
    "Amount": "Betrag",
    "Confirm": "Bestätigen",
    "Shipment Tracking": "Sendungsverfolgung",
    "Track your ongoing shipments to customers": "Verfolgen Sie Ihre laufenden Sendungen an Kunden",
    "Search": "Suche",
    "New Order": "Neue Ordnung",
    "You have": "Sie haben",
    "new orders": "neue Bestellungen",
    "Withdrawn Completed": "Zurückgezogen abgeschlossen",
    "Funds are on their way": "Die Gelder sind unterwegs",
    "Monthly Reports": "Monatliche Reportagen",
    "New reports are ready": "Neue Berichte sind fertig",
    "Comments": "Bemerkungen",
    "new comments": "neue Kommentare",
    "Chargeback Request": "Rückbuchungsanfrage",
    "to review": "zu überprüfen",
    "Profile": "Profil",
    "Settings": "Einstellungen",
    "Inbox": "Posteingang",
    "Logout": "Ausloggen",
    "North": "Nord",
    "East": "Ost",
    "South": "Süd",
    "West": "West",
    "Don't export": "Nicht exportieren",
    "PLZ & Ort": "PLZ, Ort",
    "ZIP code, place & area": "PLZ, Ort, Gebiet",
    "ZIP code, city, street number, stairs, apartment number": "Postleitzahl, Ort, Hausnummer, Treppe, Wohnungsnummer",
    "Office": "Büro",
    "Retail trade": "Einzelhandel",
    "Commercial leisure property": "Gewerbliche Freizeitimmobilie",
    "Plot of land": "Grundstück",
    "House": "Haus",
    "Agriculture and Forestry": "Land-und Forstwirtschaft",
    "Other": "Sonstige",
    "Apartment": "Wohnung",
    "Room": "Zimmer",
    "Apartment building": "Zinshaus / Renditeobjekt",
    "Exclusive right to sell": "Exklusiv Mandat",
    "Co-exclusive right to sell": "Basic Mandat",
    "Simple mediation": "Basic Mandat",
    "Verbal": "Mündlich",
    "Written": "Schriftlich",
    "Written (both parties)": "Schriftlich (beide Parteien)",
    "Old contruction": "Altbau",
    "New contruction": "Neubau",
    "Object for demolition": "Abrissobjekt",
    "Dilapitated object": "Baufällig",
    "Load-bearing structure": "Entkernt",
    "First occupancy": "Erstbezug",
    "Maintained": "Gepflegt",
    "Modernized": "Modernisiert",
    "By arrangement": "Nach Vereinbarung",
    "As good as new": "Neuwertig",
    "Projected": "Projiziert",
    "In need of renovation": "Renovierungsbedürftig",
    "Shell construction": "Rohbau",
    "Partially renovated": "Teilrenoviert",
    "Partially refurbished": "Teilsaniert",
    "Fully renovated": "Vollrenoviert",
    "Fully refurbished": "Vollsaniert",
    "Building constructed on land not owned by the landowner": "Gebäude auf Pachtgrund",
    "Single owner": "Alleineigentum",
    "Co-ownership": "Miteigentum",
    "Co-ownership with usage rights": "Miteigentum mit Benutzungsregelung",
    "Home ownership": "Wohnungseigentum",
    "Home ownership planned": "Wohnungseigentum geplant",
    "Ownership of home in development": "Wohnungseigentum in Entstehung",
    "Building law": "Baurecht",
    "Bathroom": "Badezimmer",
    "Business facility": "Geschäftseinrichtung",
    "Kitchenette": "Kochnische",
    "Kitchen": "Küche",
    "Furnished": "Möbliert",
    "Unfurnished": "Unmöbliert",
    "Partially furnished": "Teilmöbliert",
    "Very good": "Sehr gut",
    "Good": "Gut",
    "Average": "Durchschnittlich",
    "Bad": "Schlecht",
    "Absolute silent location": "Absolute Ruhelage",
    "Possible silence impairment": "Beeinträchtigung gegeben",
    "Average silence impairment": "Durchschnittliche Beeinträchtigung",
    "Small silence impairment": "Geringe Beeiträchtigung",
    "Peaceful location": "Ruhelage",
    "Partially closed": "Teilerschlossen",
    "Undeveloped": "Unentschlossen",
    "Fully developed": "Vollerschlossen",
    "% of purchase price": "% vom Kaufpreis",
    "Centrally accessible": "Zentral begehbar",
    "Partly centrally accessible": "Teilweise zentral begehbar",
    "Circular walkable": "Begehbar ",
    "Loft": "Loft",
    "Bauweise / Konstrukcija": "Bauweise  ",
    "Prefabricated part": "Fertigteil",
    "Wooden": "Holz",
    "Solid": "Massiv",
    "Reinforced concrete": "Stahlbeton",
    "Brick": "Ziegel",
    "Boden / Pod": "Boden  ",
    "Floorboards": "Dielen",
    "Raised floor": "Doppelboden",
    "Screed": "Estrich",
    "Prefabricated parquet": "Fertigparkett",
    "Tiles": "Fließen",
    "Granite": "Granit",
    "Plastic floor": "Kunsstoffboden",
    "Laminate": "Laminat",
    "Linoleum": "Linoleum",
    "Marble": "Marmor",
    "Parquet": "Parkett",
    "Stone floor": "Steinboden",
    "Carpet": "Tepichboden",
    "Carpet tiles": "Tepichfließen",
    "Terracotta": "Terrakotta",
    "Verwarming / Grejanje": "Heizung",
    "Floor Heating": "Etagenheizung",
    "District Heating": "Fernwärme",
    "Underfloor Heating": "Fußbodenheizung",
    "Stove": "Heizofen",
    "Fireplace": "Kamin",
    "Central Heating": "Zentralheizung",
    "Heizungsart / Tip grejanja": "Heizungsart  ",
    "Alternative": "Alternativ",
    "Block type thermal power station": "Blockheizkraftwerk",
    "Electric": "Elektro",
    "Geothermal energy": "Erdwärme",
    "District heating": "Fernwärme",
    "Liquified gas": "Flüssiggas",
    "Gas": "Gas",
    "Coal": "Kohle",
    "Air heat pump": "Luftwärmepumpe",
    "Oil": "Heizöl",
    "Pellets": "Pellets",
    "Solar energy": "Solar",
    "Water electric": "Wasser-Elektro",
    "Ausblick / Pogled": "Ausblick  ",
    "Mountain view": "Bergblick",
    "Distant view": "Fernblick",
    "Green view": "Grünblick",
    "Sea view": "Meerblick",
    "Lake view": "Seeblick",
    "City view": "Stadtblick",
    "Fenster / Prozor": "Fenster",
    "External sun protection": "Außen Sonnenschutz",
    "Anti-glare protection": "Blendschutz",
    "Double or multiple glazing": "Doppelt oder Mehrfachverglasung",
    "Internal sun protection": "Innen Sonnenschutz",
    "Plastic windows": "Kunststofffenster",
    "Openable windows": "Zu öffnende Fenster",
    "Shutters": "Rollladen",
    "Soundproof windows": "Schallschutz",
    "Fernsehen / Televizija": "TV",
    "DVBT": "DVBT",
    "Cable or satellite TV": "Kabel / Satellit",
    "Küche / Kuhinja": "Küche  ",
    "Equipped kitchen": "Einbauküche",
    "Tea kitchen": "Teeküche",
    "Open kitchen": "Offene Küche",
    "Extras / Dodaci": "Extras",
    "Storage room": "Abstellraum",
    "Alarm system": "Alarmanlage",
    "Outdoor shower": "Außendusche",
    "Library": "Bibliothek",
    "Steam bath": "Dampfbad",
    "Bike room": "Fahrradraum",
    "Gym": "Fitnessraum",
    "Use of garden": "Gartennutzung",
    "Barbecue area": "Grillbereich",
    "Lifting platform": "Hebebühne",
    "Children playground": "Kinderspielplatz",
    "Crane": "Kran",
    "Ramp": "Rampe",
    "Wheelchair accessible": "Barrierefrei",
    "Sauna": "Sauna",
    "Lake access": "Seezugang",
    "Suitable for seniors": "Seniorengerecht",
    "Security camera": "Sicherheitskamera",
    "Sports facilities": "Sporteinrichtungen",
    "Swimming pool": "Schwimmbecken ",
    "Close to subway": "U-Bahn Nähe",
    "changingRooms": "Umkleideraum",
    "Underground car park": "Tiefgarage",
    "Units reception": "Rezeption / Empfang",
    "Washing drying room": "Wasch / Trockenraum",
    "Wine cellar": "Weinkeller",
    "Wellness Area": "Wellnessbereich",
    "Jacuzzi": "Whirlpool",
    "Conservatory": "Wintergarten",
    "Dachform / Oblik krova": "Dachform",
    "Flat roof": "Flachdach",
    "Half hip roof": "Krüppelwalmdach",
    "Mansard roof": "Mansarddach",
    "Monopitch roof": "Pultdach",
    "Pyramid roof": "Pyramidendach",
    "Gable roof": "Satteldach",
    "Fahrstuhl / Lift": "Fahrstuhl  ",
    "Freight elevator": "Lastenaufzug",
    "Elevator": "Personen Fahrstuhl",
    "Belüftung / Ventilacija": "Belüftung  ",
    "Fan coil unit": "Gebläsekonvektor (Fan Coil)",
    "Air conditioner": "Klimaanlage",
    "Controlled ventilation": "Kontrollierte Wohnraumbelüftung",
    "Cooling": "Kühlung",
    "Mechanical ventialtion": "Mechanische Be- und Entlüftung",
    "Balkon / Balkon": "Balkon",
    "North balcony": "Nordseitig",
    "North east balcony": "Nord-Ostseitig",
    "North west balcony": "Nord-Westseitig",
    "East balcony": "Ostseitig",
    "South east balcony": "Süd-Ostseitig",
    "South west balcony": "Süd-Westseitig",
    "West balcony": "Westseitig",
    "Beleuchtung / Osvetljenje": "Beleuchtung  ",
    "Suspended mirror louvre lights": "Abgehängte Spiegellamellenleuchten",
    "Lighting suitable for workplace": "Arbeitsplatzgerechte Beleuchtung",
    "Ceiling lights": "Deckenbeleuchtung",
    "Floor lamps": "Stehlampen",
    "WCs / Toaleti": "Toiletten",
    "Guest toilet": "Gäste WC",
    "Separate toilets": "Getrenntes WC",
    "Urinal": "Urinal / Pissoir",
    "Toilet": "Toilette / WC",
    "Gastronomie / Gastronomija": "Gastronomie  ",
    "On site bar": "Angeschlossene Bar",
    "On site hotel restaurant": "Angeschlossenes Hotelrestaurant",
    "Brewery connection": "Brauereianbindung",
    "Guest terrace": "Gästeterrasse",
    "Canteen or cafeteria": "Kantine / Cafeteria",
    "Services / Usluge": "Serviceleistungen",
    "Serviced apartments": "Betreutes Wohnen",
    "Catering": "Catering",
    "Shopping service": "Einkaufsservice",
    "Cleaning service": "Reinigungsservice",
    "Guards service": "Wachdienst",
    "Expansion stage / *": "Ausbaustufe",
    "Bare bone house": "Ausbauhaus",
    "Kit house": "Bausatzhaus",
    "Key in hand with baseplate": "Schlüsselfertig mit Grundplatte",
    "Key in hand with cellar": "Schlüsselfertig mit Keller",
    "Key in hand without baseplate": "Schlüssel in der Hand ohne Grundplatte",
    "Energietyp / Tip energije": "Energietyp  ",
    "House efficiency 40": "KfW Effizienzhaus 40",
    "House efficiency 55": "KfW Effizienzhaus 55",
    "House efficiency 60": "KfW Effizienzhaus 60",
    "House efficiency 70": "KfW Effizienzhaus 70",
    "Minergie construction": "Minergie Bauweise",
    "Minergie certified": "Minergie zertifiziert",
    "New building standard": "Neubaustandard",
    "Low energy house": "Niedrigenergiehaus",
    "Passive house": "Passivhaus",
    "Räume / Spaces": "Räume  ",
    "Double doors": "Flügeltüren",
    "Rooms can be changed": "Räume veränderbar",
    "Soundproof panels": "Schallschutz-Paneele",
    "Plaster": "Stuck",
    "Plaster board partitions": "Trennwände aus Gipskarton",
    "Glass partitions": "Trennwände aus Glas",
    "Student friendly apartment": "WG geeignet",
    "Elektrik / Električna energija": "Elektrik  ",
    "Floor boxes": "Bodendosen",
    "DVEDP cabling": "DV / EDV Verkabelung",
    "Fiber optic connection": "Glasfaseranschluss",
    "IT cabling Cat 5": "IT-Verkabelung Cat 5",
    "IT cabling Cat 6": "IT-Verkabelung Cat 6",
    "IT cabling Cat 7": "IT-Verkabelung Cat 7",
    "Cable ducts along parapet": "Kabelkanäle entlang Parapet",
    "Cable ducts along plaster": "Kabelkanäle Oberputz",
    "Flush mounted cable ducts": "Kabelkanäle Unterputz",
    "Bad / Kupatilo": "Bad",
    "Bathroom with window": "Bad mit Fenster",
    "Bathroom with toilet": "Bad mit WC",
    "Bathtub": "Badewanne",
    "Bidet": "Bidet",
    "Shower": "Dusche",
    "Stellplatzart / Vrsta parking mesta": "Autostellplatz",
    "Carport": "Carport",
    "Private garage": "Private Garage",
    "Shared garage": "Gemeinsame Garage",
    "Parking spot": "Parkplatz",
    "Duplex garage": "Doppelgarage ",
    "Urkunden / Sertifikati": "Urkunden  ",
    "Building permit": "Baugenehmigung",
    "Deed of ownership": "Besitzurkunde",
    "Approval decision": "Zulassungsbescheid",
    "Richard Jones has purchased a blue t-shirt for": "Richard Jones hat ein blaues T-Shirt für gekauft",
    "Your request for withdrawal of": "Ihr Antrag auf Widerruf von",
    "Jane Davis has posted a": "Jane Davis hat a gepostet",
    "new question": "neue Frage",
    "about your product": "über Ihr Produkt",
    "Claire Smith has upvoted your store along with a": "Claire Smith hat Ihren Shop zusammen mit a positiv bewertet",
    "comment": "Kommentar",
    "New": "Neu",
    "Days 01-45": "Tage 01-45",
    "Days 46-90": "Tage 46-90",
    "Days 91-180": "Tage 91-180",
    "Days >180": "Tage >180",
    "My First dataset": "Meine First Dates",
    "My Second dataset": "Mein zweiter Datensatz",
    "My Third dataset": "Mein dritter Datensatz",
    "Incoming phone call": "Telefon eingehend",
    "Outgoing phone call": "Telefon ausgehend",
    "Incoming mail": "Mail eingehend",
    "Outgoing mail": "Mail ausgehend",
    "Viewing scheduled": "Besichtigung vereinbart",
    "Status changed": "Status geändert",
    "Not implemented": "Nicht implementiert",
    "UNEXPECTED ERROR": "UNEXPECTED ERROR",
    "Filter": "Filter",
    "Login": "Anmeldung",
    "Already have an account": "Benutzer bereits vorhanden",
    "Full Name": "Vollständiger Name",
    "Email": "Email",
    "A problem": "Ein Problem",
    "Click here": "Hier klicken",
    "and let us help you": "und wir helfen gern!",
    "Access to your": "Zugriff auf Ihre",
    "Diamond": "Diamant",
    "Account": "Konto",
    "SAVE": "SPEICHERN",
    "Invalid badge type!": "Ungültiger Abzeichentyp!",
    "Interessent communication": "Interessent Kommunikation",
    "Interessent Communication": "Interessent Kommunikation",
    "PR": "PR",
    "Versand": "Versand",
    "Anzeigen": "Anzeigen",
    "Besichtigung": "Besichtigung",
    "Termin bestat": "bestätigen",
    "Schein Feedback": "Feedback",
    "Telefon Aktiv": "Telefon eingehend",
    "Telefon Passiv": "Telefon ausgehend",
    "Telefon NE": "Telefon NE",
    "Mail Aktiv": "Mail eingehend",
    "Mail Passiv": "Mail ausgehend",
    "RR": "RR",
    "RR Aktiv": "RR eingehend",
    "RR Passiv": "RR ausgehend",
    "Meine aktiven interesenten": "Meine aktiven interesenten",
    "interessent communication": "Interessent Kommunikation",
    "single view": "Detailansicht",
    "In-Bearbeitung": "In-Bearbeitung",
    "Interesse": "Interesse",
    "Kaufinteresse": "Kaufinteresse",
    "Kaufabsicht": "Kaufabsicht",
    "Verkauf-GHome": "Verkauf-GHome",
    "Alle-Interesnente": "Alle Interessenten",
    "ID Interesent": "ID Interesent",
    "E-mail": "E-mail",
    "Objekat ID": "Objekt ID",
    "Podaci o nekretnini": "Objektdaten",
    "Datum upita": "Anfragedatum",
    "Posl. promene": "Letzte Bearbeitung",
    "Bes. Termin": "Besichtigungstermin",
    "Kaufverhandlung": "Kaufverhandlung",
    "Meine Statistik": "Meine Statistik",
    "has been initiated": "wurde eingeleitet",
    "Broj FD termina": "Anzahl FD Termine",
    "od toga preporuke": "davon Empfehlungen",
    "Broj ugovora sa prodavcem": "Anzahl Mandate mit Verkäufer",
    "od toga po preporuci": "davon Empfehlungen",
    "FD pozitivni %": "FD positiv %",
    "FD u postupku = Statusi NEU, WV, Bestatigt, On hold": "FD in Bearbeitung = Status NEU, WV, Bestätigt, On-Hold",
    "FD u postupku %": "FD in Bearbeitung %",
    "FD otkazani": "FD abgesagt",
    "Broj objekata": "Anzahl Objekte",
    "od toga": "davon",
    "sa provizijom": "mit Provision",
    "Broj objekata prema tipu ugovora": "Anzahl Objekte nach Vertragsart",
    "Standardni ugovor": "Basic Mandat",
    "Ekskluzivni ugovor": "Exklusiv Mandat",
    "Usmeni ugovor": "Mündlicher Vertrag",
    "Broj servis termina": "Anzahl Servicetermine",
    "Tekuci mesec": "Monat laufend",
    "Od pocetka godine do danas": "Jahresanfang bis heute",
    "Broj interesenata inkl Matching": "Anzahl Interessenten inkl. Matching",
    "od toga Matching interesenti": "davon Matching Interessenten",
    "Broj termina sa interesentima": "Anzahl Besichtigungen",
    "Broj termina u odnosu na broj upita": "Besichtigungsquote",
    "Ukupan broj svih termina": "Gesamtzahl Termine",
    "Broj prodatkih nekretnina": "Anzahl Verkäufe",
    "Vrednost prodatih nekretnina": "Verkaufsvolumen",
    "Provizija ukupno": "Gesamtprovision",
    "FD": "FD",
    "Month": "Monat",
    "Objekte": "Objekte",
    "od pocetka godine do": "Jahresanfang bis heute",
    "od pocetka godine do danas": "Jahresanfang bis heute",
    "Umsatz": "Umsatz",
    "Aufschaltungen": "Aufschaltungen",
    "Kaufanbote": "Kaufanbote",
    "Ineressentenanfragen": "Ineressentenanfragen",
    "Besichtigungsquote": "Besichtigungsquote",
    "FD Anzahl": "FD Anzahl",
    "FD Quote": "FD Quote",
    "Objektbestand": "Objektbestand",
    "Provisionseingange": "Provisionseingänge",
    "Kundigungen": "Kündigungen",
    "Kundigungsquote": "Kündigungsquote",
    "Land AT": "Land AT",
    "Erstellt am": "Erstellt am",
    "Lorem": "Lorem",
    "Land De": "Land DE",
    "Land GB": "Land GB",
    "Clear": "Feld leeren",
    "Nur GH Web Site": "Nur GH Web Site",
    "Nur Immo Portale": "Nur Immo Portale",
    "God. izgradnje": "Baujahr",
    "Naslovna Slika": "Titelbild",
    "Opis Interneta": "Objektbeschreibung",
    "Kompletna Adresa": "Vollständige Adresse",
    "Plan Stana": "Grundriss",
    "Vazi samo za AT Verziju": "Gültig nur für AT-Version",
    "Kupoprodajni Ugovor (Vazi samo za AT Verziju)": "Kaufvertrag (gilt nur für AT-Version)",
    "Energ. Certif.": "Energieausweis",
    "Akontacija Zajednickih": "Vorauszahlung von Zajednicki",
    "Obracun Zajednickih troskova": "Berechnung der gemeinsamen Kosten",
    "Protokol Sastanka": "Protokoll Eigentümerversammlung",
    "Resenje O Nacinu Obracuna": "Entscheidung über die Berechnungsmethode",
    "Polje Za Interne Informacije": "Interne Informationen",
    "Broj Tel.": "Telefonnummer ",
    "Team Ime, Broj Tel. E-mail, Adresa agenta": "Team Name",
    "broj Tel.": "Telefonnummer ",
    "Mail Adresa Agenta": "Makler Mail Adresse",
    "sklopljen ugovor": "Mandat erhalten",
    "Potvdjen": "Bestätigt",
    "Email anbieten": "Email anbieten",
    "Suchprofil erstellen": "Suchprofil erstellen",
    "Aktivität erfassen": "Aktivität erfassen",
    "Agent": "Makler",
    "Allgemein (opšti podaci)": "Allgemein  ",
    "Kategorien / kategorija": "Kategorien  ",
    "Art (vrsta, tip)": "Art  ",
    "Betreuer id / Nadležno lice id": "Betreuer ID",
    "Adresse": "Adresse",
    "Straße / ulica": "Straße  ",
    "Land / država": "Land  ",
    "Personendaten/lični podaci": "Personendaten",
    "Anrede": "Anrede",
    "Titel / Präfix": "Titel / Präfix",
    "Suffix": "Suffix",
    "Vorname / Ime": "Vorname  ",
    "Nachname / prezime": "Nachname  ",
    "Geburtsdatum / datum rođenja": "Geburtsdatum  ",
    "Handy / mobilni telefon": "Mobile Nr. ",
    "Firma / kompanija": "Firma",
    "Position / položaj": "Position",
    "New First Date": "Neuer FD",
    "First date - dodela agentu": "FD Maklerzuteilung",
    "Retail": "Einzelhandel",
    "Hospitality": "Gastfreundschaft",
    "Plot": "Baugrundstück",
    "Industry": "Industrie",
    "Agriculture and forestry": "Land-und Forstwirtschaft",
    "Park": "Park",
    "CC ID:": "CC ID",
    "Cena:": "Preis",
    "Broj soba :": "Zimmeranzahl",
    "Datum :": "Datum",
    "Dodeljeno putem:": "Zugeteilt ",
    "Lorem ipsum :": "Lorem ipsum :",
    "Termin :": "Termin",
    "Nezainteresovanost :": "Kein Interesse",
    "Markel": "Makler",
    "Anzahl": "Anzahl",
    "Enfolasauote": "Enfolasauote",
    "Save": "Speichern",
    "Stambena povrsina :": "Wohnfläche",
    "Sun": "So",
    "Mon": "Mo",
    "Tue": "Di",
    "Wed": "Mi",
    "Thu": "Do",
    "Fri": "Fr",
    "Sat": "Sa",
    "Set as cover": "Titelbild",
    "Kaufanbot": "Kaufanbot",
    "NEU": "NEU",
    "AKTIV": "AKTIV",
    "Update": "Aktualisieren",
    "Austria": "Österreich",
    "Germany": "Deutschland",
    "Switzerland": "Schweiz",
    "Vienna": "Wien",
    "Lower Austria": "Niederösterreich",
    "Upper Austria": "Oberösterreich",
    "Tyrol": "Tirol",
    "Carinthia": "Kärnten",
    "Salzburg": "Salzburg",
    "Vorarlberg": "Vorarlberg",
    "Burgenland": "Burgenland",
    "Baden-Württemberg": "Baden-Württemberg",
    "Bavaria": "Bayern",
    "Berlin": "Berlin",
    "Hesse": "Hessen",
    "Lower Saxony": "Niedersachsen",
    "Mecklenburg-Western Pomerania": "Mecklenburg-Vorpommern",
    "North Rhine-Westphalia": "Nordrhein-Westfalen",
    "Rhineland-Palatinate": "Rheinland-Pfalz",
    "Saxony-Anhalt": "Sachsen-Anhalt",
    "Schleswig-Holstein": "Schleswig-Holstein",
    "Thuringia": "Thüringen",
    "Zürich": "Zürich",
    "Bern": "Bern",
    "Luzern": "Luzern",
    "Uri": "Uri",
    "Schwyz": "Schwyz",
    "Unterwalden": "Unterwalden",
    "Glarus": "Glarus",
    "Zug": "Zug",
    "Freiburg": "Freiburg",
    "Solothurn": "Solothurn",
    "Schaffhausen": "Schaffhausen",
    "Appenzell": "Appenzell",
    "Sankt Gallen": "Sankt Gallen",
    "Graubünden": "Graubünden",
    "Aargau": "Aargau",
    "Thurgau": "Thurgau",
    "Ticino": "Ticino",
    "Vaud": "Vaud",
    "Valais": "Valais",
    "Neuchâtel": "Neuchâtel",
    "Genève": "Genève",
    "Jura": "Jura",
    "Upload": "Upload",
    "Property has been saved successfully": "Objekt wurde erfolgreich gespeichert",
    "fill all required fields!": "Füllen Sie alle Pflichtfelder aus!",
    "internal server error": "Internal Server Error",
    "Property has been updated successfully": "Objekt wurde erfolgreich aktualisiert",
    "Acquisition": "Akquise",
    "Appointment date": "Termin",
    "Property": "Objekt",
    "Seller": "Verkäufer",
    "Added new user sucessfully": "Neuen Benutzer erfolgreich hinzugefügt",
    "invalid password!": "Ungültiges Passwort!",
    "Suggestions": "Anregungen",
    "At least one lowercase": "Mindestens ein Kleinbuchstabe",
    "At least one uppercase": "Mindestens ein Großbuchstabe",
    "At least one numeric": "Mindestens eine Ziffer",
    "At least one special character": "Mindestens ein Sonderzeichen",
    "New user": "Neuer Benutzer",
    "Role": "Benutzerstatus",
    "Akviza": "Akquise",
    "Importdatum": "Importdatum",
    "Mail aktive": "Mail ausgehend",
    "Mail passiv": "Mail eingehend",
    "The event has been successfully edited": "Die Veranstaltung wurde erfolgreich bearbeitet",
    "A new event has been successfully added": "Eine neue Veranstaltung wurde erfolgreich hinzugefügt",
    "Added a new first date": "Neues FD hinzugefügt",
    "Unreachable": "Nicht erreichbar",
    "No New Appointment": "Kein neuer Termin",
    "Der Verkäufer war nicht anwesend": "Der Verkäufer war nicht anwesend",
    "Der Makler hat den Vertrag nicht bekommen": "Der Makler hat den Vertrag nicht bekommen",
    "Der Makler will den Verkauf der Immobilie nicht übernehmen": "Der Makler will den Verkauf der Immobilie nicht übernehmen",
    "comment must have at least 5 words": "Kommentar muss mindestens 5 Wörter haben",
    "Vertragsart": "Vertragsart",
    "Erinnerung ?": "Erinnerung",
    "Reminder": "Erinnerung",
    "reminder": "Erinnerung",
    "Added a new inquiry": "Neue Anfrage hinzugefügt",
    "New Inquiry": "Interessent erfassen",
    "Message": "Nachricht",
    "Created at": "Erstellt am",
    "Expose preview": "Expose Vorschau",
    "Mail Aktive": "Mail ausgehend",
    "Termin gehalten": "Termin stattgefunden",
    "Neu Bold": "Neu Fett",
    "Verkauft GHome": "Verkauft",
    "Alle interesnente": "Alle Interessenten",
    "Without heating cost": "Ohne Heizkosten",
    "*Optionale Kosten können nicht an Plattformen übertragen werden": "*Optionale Kosten können nicht an Plattformen übertragen werden",
    "Yes": "Ja",
    "No": "Nein",
    "Activity has been saved successfully": "Aktivität wurde erfolgreich gespeichert",
    "Inquiry has been saved successfully": "Anfrage wurde erfolgreich gespeichert",
    "Purchase": "Eigentum",
    "Rent": "Mieten",
    "Lease": "Pacht",
    "Monthly": "Monatlich",
    "Bruto": "Brutto",
    "Rent costs": "Mietkosten",
    "Total for rent": "Gesamtmiete",
    "Erstellen": "Erstellen",
    "Neuer FD Termin": "Neuer FD Termin",
    "Inquiry status": "Anfragestatus",
    "interesent": "Interessent",
    "Nicht interessiert": "Kein Interesse",
    "Ein gebot": "Kaufanbot",
    "Angebot angenommen": "Angebot wurde angenommen",
    "Angebot wurde nicht angenommen": "Angebot wurde nicht angenommen",
    "First date has been deleted successfully": "FD wurde erfolgreich gelöscht",
    "Zweite termin gehalten": "Zweitbesichtigung hat stattgefunden",
    "Offer": "Angebot",
    "Result": "Ergebnis",
    "Inquiry has been deleted successfully": "Anfrage wurde erfolgreich gelöscht",
    "Fill all necessary fields": "Füllen Sie alle erforderlichen Felder aus",
    "Property has been deleted successfully": "Objekt wurde erfolgreich gelöscht",
    "User has been deleted successfully": "Benutzer wurde erfolgreich gelöscht",
    "First Dates": "First Date",
    "Add new first date": "First Date erfassen",
    "Matching agent": "Makler Matching",
    "Verkäufer": "Verkäufer",
    "Meine Verkäufer": "Meine Verkäufer",
    "Add new seller": "Verkäufer erfassen",
    " Objekt erfassen": " Objekt erfassen",
    "Inquiries": "Interessenten",
    "Add new Inquiry": "Interessent erfassen",
    "Statistik": "Statistik",
    "Statistics": "Statistik",
    "New Contact": "Neuer Kontakt",
    "Contact view": "Kontakt anzeigen",
    "Wartezeit": "Wartezeit",
    "Total portfolio volume: ": "Objektvolumen",
    "Buyer": "Käufer",
    "Steiermark": "Steiermark",
    "Gültig von": "Gültig von",
    "Vermarktungsart": "Vermarktungsart",
    "Preis lt. Kaufanbot": "Preis lt. Kaufanbot",
    "The event has been successfully deleted": "Die Veranstaltung wurde erfolgreich gelöscht",
    "Event type": "Ereignistyp",
    "Edited user sucessfully": "Benutzer Änderung erfolgreich abgeschlossen",
    "Edit User": "Benutzer bearbeiten",
    "Active": "Aktiv",
    "SMTP Username": "SMTP Benutzername",
    "SMTP Password": "SMTP Passwort",
    "SMTP Host": "SMTP Host",
    "SMTP Port": "SMTP Port",
    "SMTP Email": "SMTP E-Mail",
    "New contact has been saved successfully": "Neuer Kontakt wurde erfolgreich gespeichert",
    "Email you entered already exists": "E-Mail ist bereits vorhanden",
    "Fill the emails field in the correct format": "Füllen Sie das E-Mail-Feld im richtigen Format aus",
    "Add new contact": "Interessent erfassen",
    "Contact role": "Typ",
    "poštanski broj": "PLZ",
    "Emails": "Email",
    "choose exist contact": "Kontakt wählen",
    "Mobile": "Mobile",
    "Address": "Adresse",
    "City": "Stadt",
    "New contact": "Neuer Kontakt",
    "Contact has been successfully updated": "Kontakt wurde erfolgreich aktualisiert",
    "Contact has been successfully added": "Kontakt wurde erfolgreich hinzugefügt",
    "Contact has been deleted successfully": "Kontakt wurde erfolgreich gelöscht",
    "Internal server error": "Internal Server Error",
    "First name": "Vorname",
    "Last name": "Nachname",
    "Phone": "Telefon",
    "filter": "Filter",
    "Street:": "Straße",
    " od toga": "davon ",
    "New contact has been updated successfully": "Neue Kontakt wurde erfolgreich aktualisiert",
    "Source": "Quelle",
    "Provision": "Provision",
    "Kauf bei GH": "Kauf bei GH",
    "Prezime": "Nachname",
    "Br. Tel": "Telefonnummer",
    "Email adresa": "E-Mail Adresse",
    "Zemljisna dozvola (Vazi samo za AT Verziju)": "Land Permit (Gültig nur für AT-Version)",
    "Loft / Atelier": "Loft",
    " At least one lowercase": "Mindestens ein Kleinbuchstabe",
    " At least one uppercase": "Mindestens ein Großbuchstabe",
    "At least 8 characters": "Mindestens 8 Zeichen",
    "Phone number": "Tel. Nr. ",
    "Team Name": "Team Name",
    "Interesent": "Interessent",
    "User Activities": "Benutzer Aktivitäten",
    " At least one numeric": "Mindestens eine Ziffer",
    " At least 8 characters": "Mindestens 8 Zeichen",
    "Communications": "Kommunikation",
    "Tel. Inc.": "Tel. Eingehend",
    "Tel. Out": "Tel. Ausgehend",
    "Tel. NE": "Telefon NE",
    "Tel. RR": "Keine Tel.Nr.",
    "Mail Inc.": "Mail Eingehend",
    "Mail Out": "Mail ausgehend",
    "Servicetermin": "Servicetermin",
    "Inaktiv": "Inaktiv",
    "Conf. App.": "Terminbestätigung",
    "Print Visit. Form": "Besichtigungsschein Drucken",
    "Feedback to Seller": "Rückmeldung an Verkäufer",
    "Appoint. Held": "Termin stattgefunden",
    "App. Not held": "Termin nicht stattgefunden",
    "Sec. App.": "Zweitbesichtigung",
    "Import Inquiry": "Interessent import",
    "Aufschaltung": "Aufschaltung",
    "Kaufangebote": "Kaufangebote",
    "Interessentenanfragen": "Interessentenanfragen",
    "No data": "No Data",
    "Monthly only": "Monatlich",
    "Provisionseingänge": "Provisionseingänge",
    "Kündigungen": "Kündigungen",
    "Kündigungsquote": "Kündigungsquote",
    "Description": "Beschreibung",
    "Day": "Tag",
    "Land DE": "Land DE",
    "Export": "Export",
    "Team": "Team Name",
    "Property labeled for export": "Aufschaltungfreigabe",
    "Are you sure you want to label this property for export?": "Sind Sie sicher, dass Sie dieses Objekt zur Aufschaltung freigeben möchten?",
    "Warning": "Achtung",
    "Authorize Export": "Übertragungsfreigabe",
    "Energieausweistyp ": "Energieausweistyp ",
    "Bedarfsausweis ": "Bedarfsausweis ",
    "Verbrauchsausweis ": "Verbrauchsausweis ",
    "Heizungsart": "Heizungsart",
    "Energieträger": "Energieträger",
    "Untertyp": "Untertyp",
    "Grundbuchblatt": "Grundbuchblatt",
    "Gemarkung": "Gemarkung",
    "Flur": "Flur",
    "Flurstück": "Flurstück",
    "Hausgeld": "Hausgeld",
    "Nebenkosten": "Nebenkosten",
    "Energy certificate": "Energieausweis",
    "Makler": "Makler",
    "Tel. Nr.": "Telefonnummer",
    "There is no checklist for this type of property!": "Keine Checkliste für diesen Objekttyp gefunden",
    "Add New First Date": "Neuer FD",
    "Showing": "Anzeigen",
    "to": "zu",
    "of": "von",
    "today": "heute",
    "In Progress": "IN BEARBEITUNG",
    "Please select type": "Untertyp wählen",
    "Mr.": "Herr",
    "Mrs.": "Frau",
    "VAT": "MwSt.",
    "Mail RR": "Keine Tel.Nr.",
    "Person": "Person",
    "Pair": "Paar",
    "Family": "Familie",
    "Company": "Unternehmen",
    "Add New Inquiry": "Neu Anfrage ",
    "years": "Jahre",
    "months": "Monate"
};
import React, { useState, useRef, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Link, Redirect, useHistory, useLocation } from 'react-router-dom';
import { Card } from 'primereact/card';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { ActionMenu } from '../../components/actionMenu/ActionMenu';
import { ProgressSpinner } from 'primereact/progressspinner';
import CardHeader from '../../components/card/CardHeader';
import CustomObjectInput from '../../components/objects/customObjectInput/CustomObjectInput';
import {
    TypeOfContact,
    SalutationType,
    ContactRoleOptions,
} from '../../constants/ContactConsts';
import { userService } from '../../services/UsersService';
import { contactsService } from '../../services/ContactsService';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import './ViewContact.scss';
import PermissionContainer from '../../core/auth/PermissionContainer';
import { Permissions } from '../../core/auth/Permissions';
import { useApplicationContext } from '../../application/ApplicationContext';

export const ViewContact = () => {
    const { t } = useTranslation();
    const { id } = useParams<{ id: string }>();
    const [data, setData] = useState(undefined);
    const [contact, setContact] = useState(undefined);
    const [status, setStatus] = useState<number>(0);
    const [employeeList, setEmployeeList] = useState([]);
    const { appState } = useApplicationContext();
    const history = useHistory();
    let dataLocation = useLocation();

    const fetchContact = async () => {
        const response = await contactsService.getOne(id);
        return response.data;
    };

    const fetchUsers = async () => {
        const response = await userService.getMany();
        return response.data;
    };

    const employeeOptions = () => {
        const allEmployees = employeeList.map((employee) => ({
            label: employee.name,
            value: employee.id,
        }));
        return allEmployees;
    };

    useEffect(() => {
        userService.refreshTokenCheck().then(() => {
            fetchUsers().then((result) => setEmployeeList(result));
            fetchContact().then((result) => {
                setData(result);
            });
        });
    }, []);

    useEffect(() => {
        if (!data) return;

        let allEmails: string[] = [];
        for (const email of data.emails) {
            allEmails.push(email.email);
        }

        setContact({
            id: data.id,
            userId: data.userId,
            type: data.type,
            role: data.role,
            categoryId: data.categoryId,
            assigneeId: data.assigneeId,
            salutation: data.salutation,
            prefix: data.prefix,
            suffix: data.suffix,
            firstName: data.firstName,
            lastName: data.lastName,
            dateOfBirth: data.dateOfBirth,
            company: data.company,
            positionInCompany: data.positionInCompany,
            mobilePhone: data.mobilePhone,
            workPhone: data.workPhone,
            privatePhone: data.privatePhone,
            fax: data.fax,
            address: data.address,
            postalCode: data.postalCode,
            houseNumber: data.houseNumber,
            apartmentNumber: data.apartmentNumber,
            city: data.city,
            county: data.county,
            country: data.country,
            emails: allEmails,
        });
    }, [data]);

    const updateContact = (property, value) => {
        let contactChanged = { ...contact };
        contactChanged[property] = value;
        setContact((newContact) => ({ ...newContact, ...contactChanged }));
    };

    const toast = useRef(null);
    useEffect(() => {
        if (status === 200) {
            toast.current.show({
                severity: 'success',
                detail: t(`New contact has been updated successfully`),
                life: 3000,
            });
            setStatus(0);
        }
        if (status === 409) {
            toast.current.show({
                severity: 'error',
                detail: t(`Email address already in use`),
                life: 3000,
            });
            setStatus(0);
        }
        if (status === 500) {
            toast.current.show({
                severity: 'error',
                detail: t(`internal server error`),
                life: 3000,
            });
            setStatus(0);
        }
    }, [status]);

    const handleSubmit = () => {
        let correctChanges: boolean = true;
        for (const email of contact.emails) {
            if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,})+$/.test(email))
                correctChanges = false;
        }
        correctChanges
            ? contactsService
                  .updateOne(id, contact)
                  .then((response) => {
                      setStatus(response.status);

                      //@ts-ignore
                      if (dataLocation.state.inquiriesId) {
                          history.push({
                              pathname: `/inquiries/${
                                  //@ts-ignore
                                  dataLocation.state.inquiriesId
                              }`,
                          });
                          //@ts-ignore
                      } else if (dataLocation.state.firstDate) {
                          history.push({
                              pathname: `/first-dates/${
                                  //@ts-ignore
                                  dataLocation.state.firstDate
                              }`,
                          });
                      } else {
                          toast.current.show({
                              severity: 'success',
                              detail: t(
                                  `New contact has been updated successfully`,
                              ),
                              life: 3000,
                          });
                          history.push({
                              pathname: `/contacts`,
                              state: 'updated',
                          });
                      }
                  })
                  .catch((err) => setStatus(err.response.status))
            : toast.current.show({
                  severity: 'error',
                  detail: t(`Fill the emails field in the correct format`),
                  life: 3000,
              });
    };

    const {
        control,
        formState: { errors },
        register,
    } = useForm({
        mode: 'onBlur',
    });

    if (contact === undefined) {
        return (
            <div className="center-spinner">
                <ProgressSpinner strokeWidth="5" animationDuration="1.5s" />
            </div>
        );
    } else {
        return (
            <>
                <Toast ref={toast} position={'top-right'} />
                <ActionMenu
                    menu={[
                        {
                            icon: 'pi pi-envelope',
                            label: t('Email anbieten'),
                            command: () => {},
                        },
                        {
                            icon: 'pi pi-book',
                            label: t('Suchprofil erstellen'),
                            command: () => {},
                        },
                        {
                            icon: 'pi pi-plus',
                            label: t('Aktivität erfassen'),
                            command: () => {},
                        },
                        {
                            icon: 'pi pi-print',
                            label: t('Drucken'),
                            command: () => {},
                        },
                    ]}
                    menuEnd={[
                        {
                            icon: 'pi pi-user',
                            label: t('Agent'),
                            command: () => {},
                        },
                    ]}
                />
                <div className="view-contact-form-wrapper">
                    <div>
                        <Card
                            className="basic-section"
                            header={
                                <CardHeader
                                    title={t('Allgemein (opšti podaci)')}
                                    className="card-header-background"
                                />
                            }
                        >
                            <div className="section-row">
                                <CustomObjectInput
                                    type="dropdown"
                                    classes={['generic-input']}
                                    id="type-input"
                                    label={t('Art (vrsta, tip)')}
                                    name="type"
                                    stateField={contact.type}
                                    setStateField={updateContact}
                                    errors={errors}
                                    control={control}
                                    dropdownOptions={TypeOfContact}
                                />
                                <CustomObjectInput
                                    type="dropdown"
                                    classes={['generic-input']}
                                    id="assignee-id-input"
                                    label={t('Supervisor')}
                                    name="assigneeId"
                                    stateField={contact.assigneeId}
                                    setStateField={updateContact}
                                    dropdownOptions={employeeOptions()}
                                    errors={errors}
                                    control={control}
                                />
                            </div>
                            <div className="section-row">
                                <CustomObjectInput
                                    type="dropdown"
                                    classes={['generic-input']}
                                    id="type-input"
                                    label={t('Contact role') + ' *'}
                                    name="role"
                                    stateField={contact.role}
                                    setStateField={updateContact}
                                    errors={errors}
                                    control={control}
                                    rules={{ required: t('This is required') }}
                                    dropdownOptions={ContactRoleOptions}
                                />
                            </div>
                        </Card>
                        <Card
                            className="address-section"
                            header={
                                <CardHeader
                                    title={t('Adresse')}
                                    className="card-header-background"
                                />
                            }
                        >
                            <div className="section-row-three">
                                <CustomObjectInput
                                    type="text"
                                    classes={['generic-input']}
                                    id="address-input"
                                    label={t('Straße / ulica')}
                                    name="address"
                                    stateField={contact.address}
                                    setStateField={updateContact}
                                    errors={errors}
                                    control={control}
                                />

                                <CustomObjectInput
                                    type="text"
                                    classes={['generic-input']}
                                    id="postal-code-input"
                                    label={t('Haus Nr.')}
                                    name="houseNumber"
                                    stateField={contact.houseNumber}
                                    setStateField={updateContact}
                                    errors={errors}
                                    control={control}
                                    //rules={{ required: t('This is required') }}
                                />
                                <CustomObjectInput
                                    type="text"
                                    classes={['generic-input']}
                                    id="postal-code-input"
                                    label={t('Wohnungs Nr.')}
                                    name="apartmentNumber"
                                    stateField={contact.apartmentNumber}
                                    setStateField={updateContact}
                                    errors={errors}
                                    control={control}
                                    //rules={{ required: t('This is required') }}
                                />
                            </div>
                            <div className="section-row-three">
                                <CustomObjectInput
                                    type="text"
                                    classes={['generic-input']}
                                    id="postal-code-input"
                                    label={t('poštanski broj')}
                                    name="postalCode"
                                    stateField={contact.postalCode}
                                    setStateField={updateContact}
                                    errors={errors}
                                    control={control}
                                />
                                <CustomObjectInput
                                    type="text"
                                    classes={['generic-input']}
                                    id="city-input"
                                    label={t('Mesto')}
                                    name="city"
                                    stateField={contact.city}
                                    setStateField={updateContact}
                                    errors={errors}
                                    control={control}
                                />
                                <CustomObjectInput
                                    type="dropdown"
                                    classes={['generic-input']}
                                    id="country-input"
                                    label={t('Land / država')}
                                    name="country"
                                    stateField={contact.country}
                                    setStateField={updateContact}
                                    errors={errors}
                                    control={control}
                                    dropdownOptions={[
                                        t('Austria'),
                                        t('Germany'),
                                        t('Switzerland'),
                                    ]}
                                />
                            </div>
                        </Card>
                    </div>
                    <div>
                        <Card
                            className="personal-section"
                            header={
                                <CardHeader
                                    title={t('Personendaten/lični podaci')}
                                    className="card-header-background"
                                />
                            }
                        >
                            <div className="prefix-suffix-section-row">
                                <CustomObjectInput
                                    type="dropdown"
                                    classes={['generic-input']}
                                    id="salutation-input"
                                    label={t('Anrede')}
                                    name="salutation"
                                    stateField={contact.salutation}
                                    setStateField={updateContact}
                                    errors={errors}
                                    control={control}
                                    dropdownOptions={SalutationType}
                                />
                                <CustomObjectInput
                                    type="text"
                                    classes={['generic-input']}
                                    id="prefix-input"
                                    label={t('Titel / Präfix')}
                                    name="prefix"
                                    stateField={contact.prefix}
                                    setStateField={updateContact}
                                    errors={errors}
                                    control={control}
                                />
                                <CustomObjectInput
                                    type="text"
                                    classes={['generic-input']}
                                    id="suffix-input"
                                    label={t('Suffix')}
                                    name="suffix"
                                    stateField={contact.suffix}
                                    setStateField={updateContact}
                                    errors={errors}
                                    control={control}
                                />
                            </div>
                            <div className="section-row">
                                <CustomObjectInput
                                    type="text"
                                    classes={['generic-input']}
                                    id="first-name-input"
                                    label={t('Vorname / Ime') + ' *'}
                                    name="firstName"
                                    stateField={contact.firstName}
                                    setStateField={updateContact}
                                    errors={errors}
                                    control={control}
                                    rules={{ required: t('This is required') }}
                                />
                                <CustomObjectInput
                                    type="text"
                                    classes={['generic-input']}
                                    id="last-name-input"
                                    label={t('Nachname / prezime') + ' *'}
                                    name="lastName"
                                    stateField={contact.lastName}
                                    setStateField={updateContact}
                                    errors={errors}
                                    control={control}
                                    rules={{ required: t('This is required') }}
                                />
                            </div>
                            <div className="section-row">
                                {/* <CustomObjectInput
                                    type="calendar"
                                    classes={['generic-input']}
                                    id="date-of-birth-input"
                                    dateType={'date'}
                                    label={t('Geburtsdatum / datum rođenja')}
                                    name="dateOfBirth"
                                    maxDate={true}
                                    stateField={contact.dateOfBirth}
                                    setStateField={updateContact}
                                    errors={errors}
                                    control={control}
                                /> */}
                                <CustomObjectInput
                                    type="text"
                                    classes={['generic-input']}
                                    id="mobile-phone-input"
                                    label={t('Telefonnummer')}
                                    name="privatePhone"
                                    stateField={contact.privatePhone}
                                    setStateField={updateContact}
                                    errors={errors}
                                    control={control}
                                />
                            </div>
                            <div className="emails-section-row">
                                <CustomObjectInput
                                    type="chips"
                                    classes={['generic-input']}
                                    id="email-input"
                                    label={t('Emails') + ' *'}
                                    name="emails"
                                    stateField={contact.emails}
                                    setStateField={updateContact}
                                    errors={errors}
                                    control={control}
                                    rules={{
                                        required: t('This is required'),
                                        pattern:
                                            /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,})+$/,
                                    }}
                                />
                                <p>{t('Press enter to save E-mail') + ' *'}</p>
                            </div>
                            <div className="section-row">
                                <CustomObjectInput
                                    type="text"
                                    classes={['generic-input']}
                                    id="company-input"
                                    label={t('Firma / kompanija')}
                                    name="company"
                                    stateField={contact.company}
                                    setStateField={updateContact}
                                    errors={errors}
                                    control={control}
                                />
                                <CustomObjectInput
                                    type="text"
                                    classes={['generic-input']}
                                    id="position-in-company-input"
                                    label={t('Position / položaj')}
                                    name="positionInCompany"
                                    stateField={contact.positionInCompany}
                                    setStateField={updateContact}
                                    errors={errors}
                                    control={control}
                                />
                            </div>
                        </Card>
                    </div>
                </div>
                <PermissionContainer
                    hasPermission={Permissions.CONTACT_UPDATE}
                    currentUser={appState.currentUser}
                >
                    <Button
                        className="button-update"
                        label={t('Save')}
                        onClick={handleSubmit}
                    />
                </PermissionContainer>
                {/* {status === 200 && (
                    <Redirect
                        to={{
                            pathname: '/contacts',
                            state: 'updated',
                        }}
                    />
                )} */}
            </>
        );
    }
};

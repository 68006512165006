import React from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { useTranslation } from 'react-i18next';
import { useApplicationContext } from '../../application/ApplicationContext';
import 'flatpickr/dist/themes/material_blue.css';
import flatpickr from 'flatpickr';
import locale from 'flatpickr/dist/l10n/de';
import { Checkbox } from 'primereact/checkbox';
import { propertiesService } from '../../services/PropertiesService';

flatpickr.localize(locale.de);

const ConfirmDuplicateModal = ({ showModal, propertyId, toast }) => {
    const {
        appState,
        setDuplicateTransferFirstDate,
        setDuplicateTransferInquiries,
        setDeactivateOriginalProperty,
        setNewPropertyToInactive,
    } = useApplicationContext();
    const { t } = useTranslation();

    const footer = (
        <div>
            <Button
                label={t('Abbrechen')}
                className="p-confirm-dialog-reject p-button-text"
                onClick={() => {
                    showModal();
                    return;
                }}
            />
            <Button
                label={t('OK')}
                className="p-confirm-dialog-accept"
                onClick={() => {
                    propertiesService
                        .duplicate(
                            propertyId,
                            appState.duplicateTransferFirstDate,
                            appState.duplicateTransferInquiries,
                            appState.deactivateOriginalProperty,
                            appState.newPropertyToInactive,
                        )
                        .then((response) => {
                            toast.current.show({
                                severity: 'success',
                                detail: t('Eigenschaft erfolgreich dupliziert'),
                                life: 3000,
                            });
                            setDuplicateTransferFirstDate(true);
                            setDuplicateTransferInquiries(true);
                            setDeactivateOriginalProperty(true);
                            setNewPropertyToInactive(true);
                            showModal();
                        })
                        .catch((error) => {
                            console.error(error);
                            setDuplicateTransferFirstDate(true);
                            setDuplicateTransferInquiries(true);
                            setDeactivateOriginalProperty(true);
                            setNewPropertyToInactive(true);
                            showModal();
                        });
                    return;
                }}
            />
        </div>
    );

    return (
        <Dialog
            header={t('Duplizieren')}
            visible={true}
            style={{ width: '30vw' }}
            draggable={true}
            footer={footer}
            onHide={showModal}
        >
            <div>
                <p>Folgende Daten werden übernommen:</p>
                <div className={'p-inputgroup'} style={{ marginBottom: '8px' }}>
                    <Checkbox
                        id={'duplicate_transferFirstDate'}
                        checked={appState.duplicateTransferFirstDate}
                        onChange={(e) => {
                            console.log(e);
                            setDuplicateTransferFirstDate(e.checked);
                        }}
                    />
                    <label
                        htmlFor={'duplicate_transferFirstDate'}
                        style={{ marginLeft: '8px' }}
                    >
                        {t('Verkäufer Kommunikation')}
                    </label>
                </div>
                <div className={'p-inputgroup'} style={{ marginBottom: '8px' }}>
                    <Checkbox
                        id={'duplicate_transferInquiries'}
                        checked={appState.duplicateTransferInquiries}
                        onChange={(e) => {
                            console.log(e);
                            setDuplicateTransferInquiries(e.checked);
                        }}
                    />
                    <label
                        htmlFor={'duplicate_transferInquiries'}
                        style={{ marginLeft: '8px' }}
                    >
                        {t('Alle Interessenten')}
                    </label>
                </div>
                <div className={'p-inputgroup'} style={{ marginBottom: '8px' }}>
                    <Checkbox
                        id={'duplicate_deactivateOriginalProperty'}
                        checked={appState.deactivateOriginalProperty}
                        onChange={(e) => {
                            console.log(e);
                            setDeactivateOriginalProperty(e.checked);
                        }}
                    />
                    <label
                        htmlFor={'duplicate_deactivateOriginalProperty'}
                        style={{ marginLeft: '8px' }}
                    >
                        {t(
                            'Altes Objekt wird abgeschlossen; nicht von Portalen gelöscht',
                        )}
                    </label>
                </div>
                <div className={'p-inputgroup'}>
                    <Checkbox
                        id={'duplicate_newPropertyToInactive'}
                        checked={appState.newPropertyToInactive}
                        onChange={(e) => {
                            console.log(e);
                            setNewPropertyToInactive(e.checked);
                        }}
                    />
                    <label
                        htmlFor={'duplicate_newPropertyToInactive'}
                        style={{ marginLeft: '8px' }}
                    >
                        {t(
                            'Neues Objekt wird erstellt und erscheint bei Inaktiv',
                        )}
                    </label>
                </div>
            </div>
        </Dialog>
    );
};

export default ConfirmDuplicateModal;

import { Calendar } from 'primereact/calendar';
import { Checkbox } from 'primereact/checkbox';
import { Dropdown } from 'primereact/dropdown';
import { InputNumber } from 'primereact/inputnumber';
import { InputText } from 'primereact/inputtext';
import { Editor } from 'primereact/editor';
import { classNames } from 'primereact/utils';
import React from 'react';
import { Control, Controller } from 'react-hook-form';
import { InputTextarea } from 'primereact/inputtextarea';
import { Password } from 'primereact/password';
import { Chips } from 'primereact/chips';
import { Input } from 'reactstrap';
import 'bootstrap/dist/css/bootstrap.css';
import moment from 'moment';
import 'flatpickr/dist/themes/material_blue.css';
import Flatpickr from 'react-flatpickr';
import flatpickr from 'flatpickr';
import locale from 'flatpickr/dist/l10n/de';

import './customObject.scss';

flatpickr.localize(locale.de);

function parseNumber(input: number | string | undefined): number | null {
    console.log('parseNumber', input);

    if (typeof input === 'number') {
        return input;
    }

    if (input === undefined || input.trim() === '') {
        return null;
    }

    const num = Number(input);
    if (isNaN(num)) {
        return null;
    }

    if (num % 1 === 0) {
        return parseInt(input, 10);
    } else {
        return parseFloat(input);
    }
}

interface CustomObjectInputProps {
    type:
        | 'text'
        | 'textarea'
        | 'editor'
        | 'number'
        | 'calendar'
        | 'dropdown'
        | 'number-with-addon'
        | 'checkbox'
        | 'accordion-textarea'
        | 'password'
        | 'chips'
        | 'decimal';
    register?: any;
    decimal?: boolean;
    styles?: any;
    label?: string;
    labelClassNames?: string[];
    labelContainerClassNames?: string[];
    name: string;
    id: string;
    classes?: string[];
    addonLabel?: string;
    addonClassNames?: string[];
    addonContainerClassNames?: string[];
    containerClassNames?: string[];
    stateField?: any; //! defined type number | string | boolean throwing error
    control: Control;
    setStateField?: (field: any, value: any) => void;
    errors: any; //* React-hook-form - formState error object
    dropdownOptions?: any;
    dropdownOptionsGroupLabel?: string;
    dropdownOptionsGroupChildren?: string;
    dateFormat?: string;
    onlyTime?: boolean;
    timeShow?: boolean;
    placeholder?: string;
    suffix?: string;
    filter?: boolean;
    required?: boolean;
    disabled?: boolean;
    rules?: any;
    addLabel?: boolean;
    header?: any;
    footer?: any;
    dateType?: any;
    maxDate?: any;
    onFilter?: any;
    emptyFilterMessage?: any;
}

const CustomObjectInput: React.FunctionComponent<CustomObjectInputProps> = ({
    type,
    errors,
    name,
    id,
    label,
    control,
    rules,
    classes,
    stateField,
    setStateField,
    containerClassNames,
    labelContainerClassNames,
    addonContainerClassNames,
    decimal,
    disabled,
    suffix,
    filter,
    addonLabel,
    addonClassNames,
    labelClassNames,
    placeholder,
    dateFormat,
    onlyTime,
    timeShow,
    register,
    styles,
    dropdownOptions,
    dropdownOptionsGroupChildren,
    dropdownOptionsGroupLabel,
    addLabel,
    header,
    footer,
    dateType,
    maxDate,
    onFilter,
    emptyFilterMessage,
}) => {
    switch (type) {
        case 'text': {
            return (
                <div>
                    {label && (
                        <div className="text-input-label">
                            <label
                                className={classNames({
                                    'p-error': errors[name],
                                    'label-disabled': disabled,
                                })}
                                htmlFor={id}
                            >
                                {label}
                            </label>
                        </div>
                    )}
                    <Controller
                        name={`${name}`}
                        control={control}
                        rules={rules}
                        render={({ field, fieldState }) => (
                            <InputText
                                {...field}
                                id={id}
                                className={classNames(classes, {
                                    'p-invalid': fieldState.invalid,
                                    'p-required': rules?.required,
                                })}
                                value={
                                    stateField == null ? undefined : stateField
                                }
                                onChange={(e) => {
                                    field.onChange(e);
                                    setStateField(name, e.target.value);
                                }}
                                disabled={disabled}
                            />
                        )}
                    />
                </div>
            );
        }
        case 'number': {
            return (
                <div>
                    {label && (
                        <div className="text-input-label">
                            <label
                                className={classNames({
                                    'p-error': errors[name],
                                    'label-disabled': disabled,
                                })}
                                htmlFor={id}
                            >
                                {label}
                            </label>
                        </div>
                    )}
                    <div className={classNames(containerClassNames)}>
                        <Controller
                            name={`${name}`}
                            control={control}
                            rules={rules}
                            render={({ field, fieldState }) => (
                                <div
                                    className={classNames(containerClassNames)}
                                >
                                    <InputNumber
                                        {...field}
                                        inputId={id}
                                        className={classNames(classes, {
                                            'p-invalid': fieldState.invalid,
                                            'p-required': rules?.required,
                                        })}
                                        value={
                                            stateField == null
                                                ? undefined
                                                : stateField
                                        }
                                        onChange={(e) => {
                                            field.onChange(
                                                parseNumber(e.value),
                                            );
                                            setStateField(
                                                name,
                                                parseNumber(e.value),
                                            );
                                        }}
                                        minFractionDigits={decimal ? 2 : null}
                                        suffix={suffix}
                                        disabled={disabled}
                                    />
                                </div>
                            )}
                        />
                    </div>
                </div>
            );
        }
        case 'decimal': {
            return (
                <div>
                    {label && (
                        <div className="text-input-label">
                            <label
                                className={classNames({
                                    'p-error': errors[name],
                                    'label-disabled': disabled,
                                })}
                                htmlFor={id}
                            >
                                {label}
                            </label>
                        </div>
                    )}
                    <div className={classNames(containerClassNames)}>
                        <Controller
                            name={`${name}`}
                            control={control}
                            rules={rules}
                            render={({ field, fieldState }) => (
                                <div
                                    className={classNames(containerClassNames)}
                                >
                                    <InputNumber
                                        {...field}
                                        inputId={id}
                                        className={classNames(classes, {
                                            'p-invalid': fieldState.invalid,
                                            'p-required': rules?.required,
                                        })}
                                        step={parseNumber('0.01')}
                                        value={
                                            stateField == null
                                                ? undefined
                                                : stateField
                                        }
                                        onChange={(e) => {
                                            field.onChange(
                                                parseNumber(e.value),
                                            );
                                            setStateField(
                                                name,
                                                parseNumber(e.value),
                                            );
                                        }}
                                        minFractionDigits={2}
                                        suffix={suffix}
                                        disabled={disabled}
                                    />
                                </div>
                            )}
                        />
                    </div>
                </div>
            );
        }
        case 'number-with-addon': {
            return (
                <div className={classNames(containerClassNames)}>
                    {label && (
                        <div
                            className={classNames({
                                labelContainerClassNames,
                                'text-input-label': true,
                                'label-disabled': disabled,
                            })}
                        >
                            <label
                                className={classNames({
                                    'p-error': errors[name],
                                })}
                                htmlFor={id}
                            >
                                {label}
                            </label>
                        </div>
                    )}
                    <Controller
                        name={`${name}`}
                        control={control}
                        rules={!disabled ? rules : null}
                        render={({ field, fieldState }) => (
                            <div className="p-inputgroup generic-input">
                                <InputNumber
                                    {...field}
                                    inputId={id}
                                    className={classNames(classes, {
                                        'p-invalid': fieldState.invalid,
                                        'p-required': rules?.required,
                                    })}
                                    value={
                                        stateField == null
                                            ? undefined
                                            : stateField
                                    }
                                    onChange={(e) => {
                                        field.onChange(parseNumber(e.value));
                                        setStateField(
                                            name,
                                            parseNumber(e.value),
                                        );
                                    }}
                                    minFractionDigits={decimal ? 2 : null}
                                    suffix={suffix}
                                    disabled={disabled}
                                />
                                {addonContainerClassNames && (
                                    // <div
                                    //     className={classNames(
                                    //         addonContainerClassNames,
                                    //     )}
                                    // >
                                    <span
                                        className={classNames(
                                            addonClassNames,
                                            'p-inputgroup-addon',
                                        )}
                                    >
                                        {addonLabel}
                                    </span>
                                    // </div>
                                )}
                                {!addonContainerClassNames && (
                                    <span
                                        className={classNames(
                                            addonClassNames,
                                            'p-inputgroup-addon',
                                        )}
                                    >
                                        {addonLabel}
                                    </span>
                                )}
                            </div>
                        )}
                    />
                </div>
            );
        }
        case 'dropdown': {
            return (
                <div>
                    {label && (
                        <div className="text-input-label">
                            <label
                                className={classNames({
                                    'p-error': errors[name],
                                    'label-disabled': disabled,
                                })}
                                htmlFor={id}
                            >
                                {label}
                            </label>
                        </div>
                    )}
                    <Controller
                        name={`${name}`}
                        control={control}
                        rules={rules}
                        render={({ field, fieldState }) => (
                            <Dropdown
                                {...field}
                                inputId={id}
                                className={classNames(classes, {
                                    'p-invalid': fieldState.invalid,
                                    'p-required': rules?.required,
                                })}
                                value={
                                    stateField == null ? undefined : stateField
                                }
                                onChange={(e) => {
                                    field.onChange(e);
                                    setStateField(name, e.target.value);
                                }}
                                placeholder={placeholder}
                                options={dropdownOptions}
                                disabled={disabled}
                                filterInputAutoFocus={true}
                                onFilter={onFilter}
                                emptyFilterMessage={emptyFilterMessage}
                                filter={filter}
                                optionGroupLabel={dropdownOptionsGroupLabel}
                                optionGroupChildren={
                                    dropdownOptionsGroupChildren
                                }
                            />
                        )}
                    />
                </div>
            );
        }
        case 'calendar': {
            return (
                <div>
                    <div className="text-input-label">
                        <label
                            className={classNames({
                                'p-error': errors[name],
                                'label-disabled': disabled,
                            })}
                            htmlFor={id}
                        >
                            {label}
                        </label>
                    </div>
                    <Controller
                        name={`${name}`}
                        control={control}
                        rules={rules}
                        render={({ field, fieldState }) => {
                            let value = '';

                            if (!dateType)
                                value =
                                    moment(stateField).format(
                                        'YYYY-MM-DDTkk:mm',
                                    );
                            if (dateType === 'date') {
                                value = moment(stateField).format('YYYY-MM-DD');
                            }
                            if (dateType === 'time') {
                                value = stateField;
                            }

                            return (
                                <div className="p-inputgroup generic-input">
                                    {/* <Input
                                        {...field}
                                        max={
                                            maxDate
                                                ? new Date()
                                                      .toISOString()
                                                      .split('T')[0]
                                                : null
                                        }
                                        className={classNames(classes, {
                                            'is-invalid': fieldState.invalid,
                                        })}
                                        value={
                                            value !== 'Invalid date'
                                                ? value
                                                : ''
                                        }
                                        type={
                                            dateType
                                                ? dateType
                                                : 'datetime-local'
                                        }
                                        onChange={(e) => {
                                            console.log(
                                                'eee',
                                                name,
                                                e.target.value,
                                            );
                                            field.onChange(e);
                                            setStateField(name, e.target.value);
                                        }}
                                    /> */}

                                    <div className="data-and-time-flatpicker">
                                        <span className="p-button-icon p-c pi pi-calendar"></span>
                                        {dateType !== 'date' ? (
                                            <Flatpickr
                                                key={
                                                    disabled === undefined
                                                        ? ''
                                                        : disabled.toString()
                                                }
                                                options={{
                                                    enableTime: true,
                                                    time_24hr: true,
                                                    defaultDate:
                                                        value !== 'Invalid date'
                                                            ? value
                                                            : new Date(),
                                                    format: 'd.m.Y H:i',
                                                    altFormat: 'd.m.Y H:i',
                                                    altInput: true,
                                                    disabled: disabled,
                                                }}
                                                value={
                                                    value !== 'Invalid date'
                                                        ? value
                                                        : ''
                                                }
                                                onChange={([e]) => {
                                                    // field.onChange(e);
                                                    setStateField(name, e);
                                                }}
                                                disabled={disabled}
                                            />
                                        ) : (
                                            <Flatpickr
                                                key={
                                                    disabled === undefined
                                                        ? ''
                                                        : disabled.toString()
                                                }
                                                options={{
                                                    enableTime: false,
                                                    time_24hr: true,
                                                    defaultDate:
                                                        value !== 'Invalid date'
                                                            ? value
                                                            : new Date(),
                                                    format: 'd.m.Y',
                                                    altFormat: 'd.m.Y',
                                                    altInput: true,
                                                    disabled: disabled,
                                                }}
                                                // value={
                                                //     value !== 'Invalid date'
                                                //         ? value
                                                //         : ''
                                                // }
                                                onChange={([e]) => {
                                                    //field.onChange(e);
                                                    setStateField(name, e);
                                                }}
                                                disabled={disabled}
                                            />
                                        )}
                                    </div>
                                    {addLabel && (
                                        <span
                                            className={classNames(
                                                addonClassNames,
                                                'p-inputgroup-addon',
                                            )}
                                        >
                                            <i className="pi pi-calendar" />
                                        </span>
                                    )}
                                </div>
                            );
                        }}
                    />
                </div>
            );
        }
        case 'checkbox': {
            return (
                <div
                    className={classNames(
                        containerClassNames,
                        'checkbox-container',
                    )}
                >
                    <Controller
                        name={`${name}`}
                        control={control}
                        rules={rules}
                        render={({ field, fieldState }) => (
                            <div
                                className={classNames(classes, 'p-inputgroup')}
                            >
                                <Checkbox
                                    inputId={id}
                                    checked={stateField}
                                    onChange={(e) => {
                                        field.onChange(e);
                                        setStateField(name, e.checked);
                                    }}
                                />
                                <label
                                    className={classNames(labelClassNames)}
                                    htmlFor={id}
                                >
                                    {label}
                                </label>
                            </div>
                        )}
                    />
                </div>
            );
        }
        case 'editor': {
            return (
                <div>
                    <div className="text-input-label">
                        <label
                            className={classNames({
                                'p-error': errors[name],
                                'label-disabled': disabled,
                            })}
                            htmlFor={id}
                        >
                            {label}
                        </label>
                    </div>
                    <Editor
                        //{...register(name, { required: 'This is required' })}
                        id={id}
                        style={styles}
                        className={classNames(classes, {
                            'p-invalid': errors[name],
                            'p-required': rules?.required,
                        })}
                        value={
                            stateField == null
                                ? undefined
                                : stateField
                                      .split('\n')
                                      ?.map((el: any) => {
                                          return `<p>${el}</p>`;
                                      })
                                      .join('')
                                      .toString()
                        }
                        onTextChange={(e) => {
                            setStateField(name, e.textValue);
                        }}
                    />
                </div>
            );
        }
        case 'accordion-textarea': {
            return (
                <div>
                    {label && (
                        <div className="text-input-label">
                            <label
                                className={classNames({
                                    'p-error': errors[name],
                                    'label-disabled': disabled,
                                })}
                                htmlFor={id}
                            >
                                {label}
                            </label>
                        </div>
                    )}
                    <Controller
                        name={`${name}`}
                        control={control}
                        rules={rules}
                        render={({ field, fieldState }) => (
                            <InputTextarea
                                {...field}
                                id={id}
                                className={classNames(classes)}
                                value={
                                    stateField == null ? undefined : stateField
                                }
                                onChange={(e) => {
                                    field.onChange(e);
                                    setStateField(name, e.target.value);
                                }}
                            />
                        )}
                    />
                </div>
            );
        }
        case 'password': {
            return (
                <div>
                    {label && (
                        <div className="text-input-label">
                            <label
                                className={classNames({
                                    'p-error': errors[name],
                                    'label-disabled': disabled,
                                })}
                                htmlFor={id}
                            >
                                {label}
                            </label>
                        </div>
                    )}
                    <Controller
                        name={`${name}`}
                        control={control}
                        rules={rules}
                        render={({ field, fieldState }) => (
                            <Password
                                {...field}
                                header={header}
                                footer={footer}
                                id={id}
                                className={classNames(classes, {
                                    'p-invalid': errors[name],
                                    'p-required': rules?.required,
                                })}
                                value={
                                    stateField == null ? undefined : stateField
                                }
                                onChange={(e) => {
                                    field.onChange(e);
                                    setStateField(name, e.target.value);
                                }}
                            />
                        )}
                    />
                </div>
            );
        }
        case 'chips': {
            return (
                <div>
                    <div className="text-input-label">
                        <label
                            className={classNames({
                                'p-error': errors[name],
                                'label-disabled': disabled,
                            })}
                            htmlFor={id}
                        >
                            {label}
                        </label>
                    </div>
                    <Controller
                        name={`${name}`}
                        control={control}
                        rules={rules}
                        render={({ field, fieldState }) => (
                            <Chips
                                {...field}
                                id={id}
                                separator=","
                                className={classNames(classes, {
                                    'p-invalid': fieldState.invalid,
                                    'p-required': rules?.required,
                                })}
                                value={stateField}
                                onChange={(e) => {
                                    field.onChange(e);
                                    setStateField(name, e.value);
                                }}
                            />
                        )}
                    />
                </div>
            );
        }
    }
};

export default CustomObjectInput;

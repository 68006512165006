import React, { useState, useEffect, useRef } from 'react';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import WidgetOverviewBox from '../../components/widgetOverviewBox/widgetOverviewBox';
import './MeineInteresentPage.scss';
import ObjectStatusBadge from '../../components/objects/objectStatusBadge/ObjectStatusBadge';
import NewInquiryFormModal from '../../components/NewInquiryFormModal/NewInquiryFormModal';
import { Button } from 'primereact/button';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import { userService } from '../../services/UsersService';
import {
    InquiriesService,
    inquiriesService,
} from '../../services/InquiriesService';
import { Card } from 'primereact/card';
import { Toast } from 'primereact/toast';
import { ProgressSpinner } from 'primereact/progressspinner';
import moment from 'moment';
import { contactsService } from '../../services/ContactsService';
import { useQuery } from '../../hooks/QueryHook';
import { useApplicationContext } from '../../application/ApplicationContext';
import { InputText } from 'primereact/inputtext';
import { DomHandler } from 'primereact/utils';
import { useStateCallback } from '../../hooks/StateCallbackHook';

const MeineInteresentPage = (props) => {
    const [loading, setLoading] = useState(false);
    const location = useLocation();

    const { t } = useTranslation();
    const query = useQuery();
    const { appState, setNewInquiry } = useApplicationContext();

    const [date, setDate] = useState(undefined);
    const [inquiries, setInquiries] = useState([]);
    const [allInquiries, setAllInquiries] = useState([]);
    const [visible, setVisible] = useStateCallback(false);
    const [status, setStatus] = useState<number>(0);
    const [clickedStatus, setClickedStatus] = useState('');
    const [contactList, setContactList] = useState([]);
    const [importInquiryIndicator, setImportInqueryIndicator] = useState(false);
    const [resetSearchFilter, setResetSearchFilter] = useState(true);
    const [resetSearchButton, setResetSearchButton] = useState(true);

    //// CLICK DESHBOARD RETURN TO FIRST DATE

    useEffect(() => {
        //@ts-ignore
        handlerActiveStatus(location.state?.status ?? '');
    }, [location]);

    useEffect(() => {
        //@ts-ignore
        if (location.state?.status == undefined) {
            setClickedStatus('');
        }
    }, []);

    const statusNeu =
        allInquiries.filter(
            (el) => el.status === 'Neu' || el.status === 'Neu Bold',
        ) ?? [];

    const statusNeuOverdue =
        allInquiries.filter((inquiry) => {
            const start = moment(inquiry.createdAt);
            const end = moment();
            const totalMinutes = moment.duration(end.diff(start)).asMinutes();
            const hours = Math.floor(totalMinutes / 60);

            return (
                (inquiry.status === 'Neu Bold' || inquiry.status === 'Neu') &&
                hours >= 24
            );
        }) ?? [];

    const statusInBearbeitung =
        allInquiries.filter((el) => el.status === 'In Bearbeitung') ?? [];

    const statusBesichtigung =
        allInquiries.filter((el) => el.status === 'Besichtigung') ?? [];

    const statusKaufinteresse =
        allInquiries.filter((el) => el.status === 'Kaufinteresse') ?? [];

    const statusKaufabsicht =
        allInquiries.filter((el) => el.status === 'Kaufabsicht') ?? [];

    const statusInteresse =
        allInquiries.filter((el) => el.status === 'Interesse') ?? [];

    const statusReservierung =
        allInquiries.filter((el) => el.status === 'Reservierung') ?? [];

    const statusVerkauft =
        allInquiries.filter((el) => el.status === 'Verkauft') ?? [];

    const statusAbgeschlossen =
        allInquiries.filter((el) => el.status === 'Abgeschlossen') ?? [];

    const calendarDate =
        date !== undefined && moment(date).format('DD.MM.YYYY');

    useEffect(() => {
        setLoading(true);
    }, []);

    //TEAM VIEW
    useEffect(() => {
        userService.refreshTokenCheck().then(() => {
            fetchInquiries(appState.teamView).then((result) => {
                setResetSearchButton(true);
                /// Number(query.get('propertyId')),
                let data = result;
                if (props.rootId) {
                    const filterData = result.filter((el: any) => {
                        return el?.property?.rootId === props.rootId;
                    });
                    data = filterData;
                }
                setAllInquiries(data);
                setInquiries(data);
                setLoading(false);
            });
        });
        query.forEach(console.log);

        setNewInquiry(false);
    }, [
        visible,
        status,
        appState.showNewData,
        appState.teamView,
        importInquiryIndicator,
        resetSearchFilter,
    ]);

    //TEAM VIEW
    const fetchInquiries = async (show) => {
        const response = await inquiriesService.getMany(show);
        return response.data;
    };

    const toast = useRef(null);
    useEffect(() => {
        if (status === 201) {
            toast.current.show({
                severity: 'success',
                detail: t(`Inquiry has been saved successfully`),
                life: 3000,
            });
            setStatus(0);
        }
        if (status === 400) {
            toast.current.show({
                severity: 'error',
                detail: t(`fill all required fields!`),
                life: 3000,
            });
            setStatus(0);
        }
        if (status === 500) {
            toast.current.show({
                severity: 'error',
                detail: t(`internal server error`),
                life: 3000,
            });
            setStatus(0);
        }
    }, [status]);

    useEffect(() => {
        let isMounted = true;
        userService.refreshTokenCheck().then(() => {
            if (isMounted) {
                fetchContacts().then((result) => setContactList(result));
            }
        });
        return () => {
            isMounted = false;
        };
    }, []);

    const fetchContacts = async () => {
        const response = await contactsService.getMany();
        return response.data;
    };

    const formatContactText = (contact) => {
        let result = '';

        result += contact.id;
        result += '. ';

        if (contact?.firstName) {
            result += ' ';
            result += contact?.firstName;
        }

        if (contact?.lastName) {
            result += ' ';
            result += contact?.lastName;
        }

        if (contact?.postalCode) {
            result += ', ';
            result += contact?.postalCode;
        }

        if (contact?.city) {
            result += ', ';
            result += contact?.city;
        }

        if (contact?.address) {
            result += ', ';
            result += contact?.address;
        }

        return result;
    };

    const contactOptions = () => {
        const allContacts = contactList.map((contact) => ({
            label: formatContactText(contact),
            value: contact.id,
            role: contact.role,
        }));
        return allContacts.sort((a: any, b: any) => {
            const aId = parseInt(a.label.split(',')[0]);
            const bId = parseInt(b.label.split(',')[0]);

            return aId - bId;
        });
    };

    const showModal = () => {
        setVisible(!visible, (state) => {
            if (!state) {
                DomHandler.removeClass(document.body, 'p-overflow-hidden');
            }
        });
    };

    const paginatorLeft = (
        <Button type="button" icon="pi pi-refresh" className="p-button-text" />
    );
    const paginatorRight = (
        <Button type="button" icon="pi pi-cloud" className="p-button-text" />
    );

    const statusBodyTemplate = (rowData) => {
        const splitStatus = rowData.status.split(' ');

        return (
            <ObjectStatusBadge
                type="new-table-with-text"
                status={
                    rowData.status == 'Neu Bold'
                        ? 'Brandneu'
                        : t(rowData.status)
                }
            />
        );
    };

    const handlerActiveStatus = (status) => {
        setClickedStatus(clickedStatus === status ? '' : status);
    };

    const history = useHistory();
    const viewAction = (rowData) => {
        history.push({
            pathname: `/inquiries/${rowData.id}`,
            state: {
                data: rowData,
            },
        });
    };

    const deleteActionBodyTemplate = (rowData) => {
        return (
            <Button
                type="button"
                icon="pi pi-trash"
                className="delete-action-button"
                onClick={() => {
                    inquiriesService
                        .deleteOne(rowData.id)
                        .then((response) => setStatus(response.status))
                        .catch((err) => setStatus(err.response.status));
                }}
            />
        );
    };

    useEffect(() => {
        if (status === 200) {
            toast.current.show({
                severity: 'success',
                detail: t(`Inquiry has been deleted successfully`),
                life: 3000,
            });
            setStatus(0);
        }
        if (status === 500) {
            toast.current.show({
                severity: 'error',
                detail: t(`internal server error`),
                life: 3000,
            });
            setStatus(0);
        }
    }, [status]);

    const rowClass = (rowData) => {
        return {
            'row-bold': rowData.status === 'Neu Bold',
        };
    };

    const propertydata = (rowData) => {
        // const seller = contactOptions().filter(
        //     (seller) => seller.value === rowData?.property?.sellerId,
        // );
        // const street = `${rowData?.property?.street?.slice(0, 10)}
        // ${rowData?.property?.street?.toString()?.length > 10 ? '...' : ''}`;
        // return `${street ?? ''} ${rowData?.property?.houseNumber}`;
        let objectAddressText = `ID ${rowData.property?.rootId}, ${rowData.property?.place} ${rowData.property?.plz}, ${rowData.property.street} ${rowData.property.houseNumber}.`;
        return objectAddressText;
    };

    const showContact = (rowData) => {
        return rowData?.contact?.firstName + ' ' + rowData?.contact?.lastName;
    };

    const dateFormatDate = (rowData) => {
        return moment(rowData.createdAt).format('DD.MM.YYYY kk:mm');
    };

    const dateFormatUpdatedAt = (rowData) => {
        const latestDate = new Date(
            Math.max(
                ...rowData.inquiryActivities.map((obj) =>
                    new Date(obj.createdAt).getTime(),
                ),
            ),
        );
        return moment(latestDate).format('DD.MM.YYYY kk:mm');
    };

    const dateFormatAgreementMadeAt = (rowData) => {
        if (!rowData.appointmentDate) return 'n/a';

        return moment(rowData.appointmentDate).format('DD.MM.YYYY kk:mm');
    };

    const contactEmailBodyTemplate = (rowData) => {
        return <p>{rowData.contact?.emails[0]?.email}</p>;
    };

    async function importInquiry(e) {
        const files = e.target.files;
        if (files.length <= 0) return;

        const response = await inquiriesService.import(files);

        if (response) {
            setImportInqueryIndicator(!importInquiryIndicator);
        }
    }

    const showAgent = (rowData) => {
        if (!rowData?.property?.employee) {
            return <div title={'NOT AVAILABLE'}>N/A</div>;
        }

        const fullName = rowData?.property?.employee?.name.split(' ');

        const initials =
            fullName.length > 0
                ? fullName?.shift()?.charAt(0) +
                  '.' +
                  fullName?.pop()?.charAt(0) +
                  '.'
                : rowData?.property?.employee?.name?.charAt(0) + '.';
        return (
            <div title={rowData?.property?.employee?.name}>
                {initials.toUpperCase()}
            </div>
        );
    };

    const [searchParam, setSearchParam] = useState('');

    async function searchFunction() {
        setLoading(true);
        const inquire = await inquiriesService.searchInquire(searchParam);

        if (inquire) {
            setLoading(false);
            setInquiries(inquire.data);
        }
        setResetSearchButton(false);
    }

    if (loading) {
        return (
            <div className="center-spinner">
                <ProgressSpinner strokeWidth="5" animationDuration="1.5s" />
            </div>
        );
    } else {
        return (
            <>
                <Card style={{ marginBottom: '20px' }}>
                    <div className="dropdown-container">
                        <span className="p-input-icon-right filter-item">
                            <i className="pi pi-search" />
                            <InputText
                                className="filter-item"
                                // value="Under development"
                                placeholder="Suche nach Kontakt oder Straße..."
                                onChange={(e) => {
                                    setSearchParam(e.target.value);
                                }}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                        searchFunction();
                                    }
                                }}
                            />
                        </span>
                        <div className="filter-div">
                            <Button
                                className="filter"
                                label={t('Filter')}
                                onClick={() => {
                                    searchFunction();
                                }}
                            />
                            {resetSearchButton == false && (
                                <button
                                    onClick={() =>
                                        setResetSearchFilter(!resetSearchFilter)
                                    }
                                    className="reset-status"
                                >
                                    <i
                                        className="pi pi-refresh"
                                        style={{
                                            fontSize: '1em',
                                        }}
                                    ></i>
                                </button>
                            )}
                        </div>
                    </div>
                </Card>
                <Card style={{ marginBottom: '20px' }}>
                    <div className="badge-legend-container">
                        <div
                            className={`status-button ${
                                clickedStatus === 'Neu' ? 'cover' : ''
                            }`}
                            onClick={() => handlerActiveStatus('Neu')}
                        >
                            <ObjectStatusBadge type="legend" status={t('Neu')}>
                                {statusNeu.length.toString()}
                                <span style={{ margin: '0 3px' }}> / </span>
                                <span style={{ color: '#ff0000' }}>
                                    {statusNeuOverdue.length.toString()}
                                </span>
                            </ObjectStatusBadge>
                        </div>
                        <div
                            className={`status-button ${
                                clickedStatus === 'In Bearbeitung'
                                    ? 'cover'
                                    : ''
                            }`}
                            onClick={() =>
                                handlerActiveStatus('In Bearbeitung')
                            }
                        >
                            <ObjectStatusBadge
                                type="legend"
                                status={t('In Bearbeitung')}
                            >
                                {statusInBearbeitung.length.toString()}
                            </ObjectStatusBadge>
                        </div>
                        <div
                            className={`status-button ${
                                clickedStatus === 'Besichtigung' ? 'cover' : ''
                            }`}
                            onClick={() => handlerActiveStatus('Besichtigung')}
                        >
                            <ObjectStatusBadge
                                type="legend"
                                status={t('Besichtigung')}
                            >
                                {statusBesichtigung.length.toString()}
                            </ObjectStatusBadge>
                        </div>
                        <div
                            className={`status-button ${
                                clickedStatus === 'Interese' ? 'cover' : ''
                            }`}
                            onClick={() => handlerActiveStatus('Interese')}
                        >
                            <ObjectStatusBadge
                                type="legend"
                                status={t('Interesse')}
                            >
                                {statusInteresse.length.toString()}
                            </ObjectStatusBadge>
                        </div>
                        <div
                            className={`status-button ${
                                clickedStatus === 'Kaufinteresse'
                                    ? 'changed-cover'
                                    : ''
                            }`}
                            onClick={() => handlerActiveStatus('Kaufinteresse')}
                        >
                            <ObjectStatusBadge
                                type="legend-wide"
                                status={t('Kaufinteresse')}
                            >
                                {statusKaufinteresse.length.toString()}
                            </ObjectStatusBadge>
                        </div>
                        <div
                            className={`status-button ${
                                clickedStatus === 'Kaufabsicht' ? 'cover' : ''
                            }`}
                            onClick={() => handlerActiveStatus('Kaufabsicht')}
                        >
                            <ObjectStatusBadge
                                type="legend"
                                status={t('Kaufabsicht')}
                            >
                                {statusKaufabsicht.length.toString()}
                            </ObjectStatusBadge>
                        </div>
                        <div
                            className={`status-button ${
                                clickedStatus === 'Reservierung' ? 'cover' : ''
                            }`}
                            onClick={() => handlerActiveStatus('Reservierung')}
                        >
                            <ObjectStatusBadge
                                type="legend"
                                status={t('Reservierung')}
                            >
                                {statusReservierung.length.toString()}
                            </ObjectStatusBadge>
                        </div>
                        <div
                            className={`status-button ${
                                clickedStatus === 'Verkauft GHome'
                                    ? 'cover'
                                    : ''
                            }`}
                            onClick={() =>
                                handlerActiveStatus('Verkauft GHome')
                            }
                        >
                            <ObjectStatusBadge
                                type="legend-wide"
                                status={t('Verkauft GHome')}
                            >
                                {statusVerkauft.length.toString()}
                            </ObjectStatusBadge>
                        </div>
                        <div
                            className={`status-button ${
                                clickedStatus === 'Abgeschlosen' ? 'cover' : ''
                            }`}
                            onClick={() => handlerActiveStatus('Abgeschlosen')}
                        >
                            <ObjectStatusBadge
                                type="legend-color-change"
                                status={t('Abgeschlossen')}
                            >
                                {statusAbgeschlossen.length.toString()}
                            </ObjectStatusBadge>
                        </div>
                        {clickedStatus !== '' && (
                            <button
                                onClick={() => setClickedStatus('')}
                                className="reset-status"
                            >
                                <i
                                    className="pi pi-refresh"
                                    style={{ fontSize: '1em' }}
                                ></i>
                            </button>
                        )}
                    </div>
                </Card>
                <Card className={`no-padding`}>
                    <div className="maine-interesent-container">
                        <Toast ref={toast} position={'top-right'} />
                        {/*<div className="badge-box">*/}
                        {/*    <div>*/}
                        {/*        <label*/}
                        {/*            className="p-button p-component new-inquiry-button"*/}
                        {/*            htmlFor="upload-input"*/}
                        {/*        >*/}
                        {/*            <span className="p-button-label p-c">*/}
                        {/*                {t('Import Inquiry')}*/}
                        {/*            </span>*/}
                        {/*        </label>*/}
                        {/*        <input*/}
                        {/*            style={{ display: 'none' }}*/}
                        {/*            onChange={importInquiry}*/}
                        {/*            type="file"*/}
                        {/*            id="upload-input"*/}
                        {/*            accept=".xml"*/}
                        {/*            multiple={true}*/}
                        {/*        />*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                        {visible ? (
                            <NewInquiryFormModal
                                toast={toast}
                                showModal={showModal}
                            />
                        ) : null}
                        <DataTable
                            value={
                                calendarDate
                                    ? inquiries.filter((el) => {
                                          if (el.status === 'Interesse') {
                                              return (el.status = 'Interesse');
                                          }

                                          if (el.status === 'Abgeschlossen') {
                                              return (el.status =
                                                  'Abgeschlossen');
                                          }

                                          return (
                                              moment(el.createdAt).format(
                                                  'DD.MM.YYYY',
                                              ) === calendarDate
                                          );
                                      })
                                    : inquiries.filter((el) => {
                                          if (clickedStatus !== '') {
                                              if (clickedStatus === 'Neu') {
                                                  return (
                                                      el.status ===
                                                          clickedStatus ||
                                                      el.status === 'Neu Bold'
                                                  );
                                              }

                                              return (
                                                  el.status === clickedStatus
                                              );
                                          }

                                          if (el.status === 'Interesse') {
                                              return (el.status = 'Interese');
                                          }

                                          if (el.status === 'Abgeschlossen') {
                                              return (el.status =
                                                  'Abgeschlosen');
                                          }

                                          return el;
                                      })
                            }
                            className="p-datatable-lg"
                            paginator={true}
                            paginatorPosition="bottom"
                            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                            currentPageReportTemplate={`{first} ${t(
                                'bis',
                            )} {last} ${t('of')} {totalRecords}`}
                            rowClassName={rowClass}
                            sortField="id"
                            sortOrder={-1}
                            rows={50}
                            rowsPerPageOptions={[10, 20, 50, 100]}
                            paginatorLeft={paginatorLeft}
                            paginatorRight={paginatorRight}
                            selectionMode="single"
                            onSelectionChange={(e) => viewAction(e.value)}
                        >
                            <Column
                                className="column-container-second"
                                body={statusBodyTemplate}
                                header={t('Status')}
                                field={'status'}
                            />
                            <Column
                                sortable
                                className="column-container-four"
                                field="id"
                                header={t('ID')}
                            />
                            <Column
                                className="column-container-name"
                                body={showContact}
                                header={t('Ime')}
                            />
                            <Column
                                className="column-container"
                                field={'contact.privatePhone'}
                                header={t('Telefon')}
                            />
                            <Column
                                className="column-container"
                                body={contactEmailBodyTemplate}
                                header={t('E-mail')}
                            />
                            {/* <Column
                                className="column-container-second"
                                field="property.rootId"
                                header={t('Objekat ID')}
                            /> */}
                            <Column
                                className="column-container"
                                field={`property.state.name`}
                                body={propertydata}
                                header={t('Objektadresse')}
                                style={{
                                    minWidth: '20rem',
                                }}
                            />
                            <Column
                                className="column-container-third"
                                body={dateFormatDate}
                                header={t('Datum upita')}
                            />
                            <Column
                                className="column-container-third"
                                body={dateFormatUpdatedAt}
                                header={t('Letzte Bearb.')}
                            />
                            <Column
                                className="column-container"
                                body={dateFormatAgreementMadeAt}
                                header={t('Besichtigung am')}
                            />
                            <Column
                                header={t('Agent')}
                                body={showAgent}
                                className="column-container"
                            />
                            {/*<Column*/}
                            {/*    body={viewActionBodyTemplate}*/}
                            {/*    className="datatable-button-column"*/}
                            {/*/>*/}
                            {/*<Column*/}
                            {/*    body={deleteActionBodyTemplate}*/}
                            {/*    className="datatable-button-column"*/}
                            {/*/>*/}
                        </DataTable>
                    </div>
                    <Toast ref={toast} position={'top-right'} />
                </Card>
            </>
        );
    }
};

export default MeineInteresentPage;

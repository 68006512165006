import React, { useState, useEffect } from 'react';
import './UserFormModal.scss';
import CustomObjectInput from '../objects/customObjectInput/CustomObjectInput';
import { useForm } from 'react-hook-form';
import { useApplicationContext } from '../../application/ApplicationContext';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { useTranslation } from 'react-i18next';
import { INewUser } from '../../models/NewUser';
import { roleData } from './Roles';
import { Divider } from 'primereact/divider';
import { userService } from '../../services/UsersService';
import PermissionContainer from '../../core/auth/PermissionContainer';
import { Permissions } from '../../core/auth/Permissions';

const UserFormModal = ({ showModal, toast }) => {
    const { t } = useTranslation();
    const { appState, addNewUser } = useApplicationContext();
    const [employeeList, setEmployeeList] = useState([]);
    const [newUser, setNewUser] = useState<INewUser>({
        roleId: '',
        name: '',
        email: '',
        password: '',
        phoneNumber: '',
        smtpUsername: 'office@goldenhome.at',
        smtpPassword: '68Hif&@2399Tukbot!',
        smtpHost: 'pro.eu.turbo-smtp.com',
        smtpPort: '587',
        smtpEmail: '',
        active: false,
        superiorId: null,
        teamName: '',
        addressStreet: '',
        addressHouseNumber: '',
        addressPostal: '',
        addressCity: '',
        addressCountry: '',
        positionTitle: '',
    });

    const {
        control,
        formState: { errors, isValid },
        register,
    } = useForm({
        mode: 'all',
    });

    const regNumber = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$/;
    const lowercaseLetters = /(?=.*[a-z])/;
    const uppercaseLetters = /(?=.*[A-Z])/;
    const regCheckNumber = /(?=.*\d)/;

    const handleNewUser = (prop, value) => {
        setNewUser((FirstDateFormModalObject) => ({
            ...FirstDateFormModalObject,
            [prop]: value,
        }));
    };

    const handleResetModal = () => {
        setNewUser({
            roleId: '',
            name: '',
            email: '',
            password: '',
            phoneNumber: '',
            smtpUsername: 'office@goldenhome.at',
            smtpPassword: '68Hif&@2399Tukbot!',
            smtpHost: 'pro.eu.turbo-smtp.com',
            smtpPort: '587',
            smtpEmail: '',
            active: false,
            superiorId: null,
            teamName: '',
            addressStreet: '',
            addressHouseNumber: '',
            addressPostal: '',
            addressCity: '',
            addressCountry: '',
            positionTitle: '',
        });
    };

    const fetchUsers = async () => {
        const response = await userService.getMany();
        return response.data;
    };

    useEffect(() => {
        let isMounted = true;
        userService.refreshTokenCheck().then(() => {
            if (isMounted) {
                fetchUsers().then((result) => setEmployeeList(result));
            }
        });
        return () => {
            isMounted = false;
        };
    }, []);

    const employeeOptions = () => {
        const allEmployees = employeeList.map((employee) => ({
            label: employee.name,
            value: employee.id,
        }));
        return allEmployees;
    };

    const rolesOptions = () => {
        const allRoles = roleData.map((roleData) => ({
            label: roleData.name,
            value: roleData.id,
        }));
        return allRoles;
    };

    const handleSubmit = (e) => {
        e.preventDefault();
    };

    const handleButtonCheck = () => {
        if (regNumber.test(newUser.password)) {
            addNewUser(
                String(newUser.roleId),
                newUser.name,
                newUser.email,
                newUser.password,
                newUser.phoneNumber,
                {
                    smtpUsername: newUser.smtpUsername,
                    smtpPassword: newUser.smtpPassword,
                    smtpHost: newUser.smtpHost,
                    smtpPort: newUser.smtpPort,
                    smtpEmail: newUser.smtpEmail,
                },
                {
                    addressStreet: newUser.addressStreet,
                    addressHouseNumber: newUser.addressHouseNumber,
                    addressPostal: newUser.addressPostal,
                    addressCity: newUser.addressCity,
                    addressCountry: newUser.addressCountry,
                },
                newUser.positionTitle,
                newUser.active,
                String(newUser.superiorId),
                newUser.teamName,
            );
            showModal();
            handleResetModal();
            toast.current.show({
                severity: 'success',
                detail: t(`Added new user sucessfully`),
                life: 3000,
            });
        } else {
            toast.current.show({
                severity: 'error',
                detail: t(`invalid password!`),
                life: 3000,
            });
        }
    };
    const passwordHeader = <h6>Pick a password</h6>;

    const passwordFooter = (
        <>
            <Divider />
            <p className="mt-2">{t('Suggestions')}</p>
            <ul className="pl-2 ml-2 mt-0" style={{ lineHeight: '1.5' }}>
                <li>
                    {lowercaseLetters.test(newUser.password) ? (
                        <i className="pi pi-check green" />
                    ) : (
                        <i className="pi pi-times red" />
                    )}
                    {t(' At least one lowercase')}
                </li>
                <li>
                    {uppercaseLetters.test(newUser.password) ? (
                        <i className="pi pi-check green" />
                    ) : (
                        <i className="pi pi-times red" />
                    )}
                    {t(' At least one uppercase')}
                </li>
                <li>
                    {regCheckNumber.test(newUser.password) ? (
                        <i className="pi pi-check green" />
                    ) : (
                        <i className="pi pi-times red" />
                    )}
                    {t(' At least one numeric')}
                </li>
                <li>
                    {newUser.password.length > 7 ? (
                        <i className="pi pi-check green" />
                    ) : (
                        <i className="pi pi-times red" />
                    )}
                    {t(' At least 8 characters')}
                </li>
            </ul>
        </>
    );

    const footer = (
        <div className="footer-buttons">
            <Button
                label={t('Save')}
                icon="pi pi-check"
                disabled={!isValid}
                onClick={handleButtonCheck}
                type="submit"
                form="form"
            />
            {/* <Button
                label={t('Refresh')}
                className="p-button-secondary"
                icon="pi pi-refresh"
                onClick={handleResetModal}
            /> */}
        </div>
    );

    return (
        <>
            <Dialog
                header={t('New user')}
                visible={true}
                style={{ width: '50vw' }}
                className="user-modal"
                blockScroll
                footer={footer}
                draggable={false}
                onHide={showModal}
            >
                <form id="form" onSubmit={handleSubmit}>
                    <h5 className="header column-span-2">{`${t(
                        'Basic information',
                    )}`}</h5>
                    <hr className="horizontal-divider column-span-2" />
                    <div className="modal-input column-span-3">
                        <CustomObjectInput
                            type="text"
                            classes={['expiry-date-input', 'generic-input']}
                            id="Important date"
                            label={t('Name ') + '*'}
                            name="name"
                            register={register}
                            stateField={newUser.name}
                            setStateField={handleNewUser}
                            errors={errors}
                            control={control}
                            rules={{ required: t('This is required') }}
                        />
                    </div>
                    <div className="modal-input column-span-3">
                        <CustomObjectInput
                            type="password"
                            classes={['password', 'generic-input']}
                            id="password"
                            header={passwordHeader}
                            footer={passwordFooter}
                            label={t('Password ' + '*')}
                            name="password"
                            register={register}
                            stateField={newUser.password}
                            setStateField={handleNewUser}
                            errors={errors}
                            control={control}
                            rules={{ required: t('This is required') }}
                        />
                    </div>
                    <div className="modal-input column-span-3">
                        <CustomObjectInput
                            type="dropdown"
                            classes={['country-input']}
                            id="id-input"
                            label={t('Role ' + '*')}
                            name="roleId"
                            register={register}
                            dropdownOptions={rolesOptions()}
                            stateField={newUser.roleId}
                            setStateField={handleNewUser}
                            errors={errors}
                            control={control}
                            rules={{ required: t('This is required') }}
                        />
                    </div>
                    <div className="modal-input column-span-3">
                        <CustomObjectInput
                            type="text"
                            classes={['valid-from-input', 'generic-input']}
                            id="Date-and-time-from-input"
                            label={t('Email ' + '*')}
                            name="email"
                            register={register}
                            stateField={newUser.email}
                            setStateField={handleNewUser}
                            errors={errors}
                            control={control}
                            rules={{ required: t('This is required') }}
                        />
                    </div>
                    <div className="modal-input column-span-3">
                        <CustomObjectInput
                            type="text"
                            classes={['valid-from-input', 'generic-input']}
                            id="phone-number"
                            label={t('Phone number *')}
                            name="phoneNumber"
                            register={register}
                            stateField={newUser.phoneNumber}
                            setStateField={handleNewUser}
                            errors={errors}
                            control={control}
                            rules={{ required: t('This is required') }}
                        />
                    </div>
                    <div className="modal-input column-span-3">
                        <CustomObjectInput
                            type="text"
                            classes={['expiry-date-input', 'generic-input']}
                            id="Important date"
                            label={t('Title')}
                            name="title"
                            register={register}
                            stateField={newUser.positionTitle}
                            setStateField={handleNewUser}
                            errors={errors}
                            control={control}
                        />
                    </div>
                    <PermissionContainer
                        hasPermission={Permissions.USER_DELETE}
                        currentUser={appState.currentUser}
                    >
                        <div className="modal-input column-span-3">
                            <CustomObjectInput
                                type="checkbox"
                                classes={['checkbox']}
                                id="user-active"
                                label={t('Active')}
                                name="active"
                                stateField={newUser.active}
                                setStateField={handleNewUser}
                                errors={errors}
                                control={control}
                                // rules={{ required: t('This is required') }}
                            />
                        </div>
                    </PermissionContainer>
                    <h5 className="header column-span-2">{`${'Team'}`}</h5>
                    <hr className="horizontal-divider column-span-2" />
                    <div className="modal-input column-span-3">
                        <CustomObjectInput
                            type="dropdown"
                            classes={['country-input']}
                            id="supervisor-input"
                            label={t('Supervisor')}
                            name="superiorId"
                            stateField={newUser.superiorId}
                            setStateField={handleNewUser}
                            dropdownOptions={employeeOptions()}
                            errors={errors}
                            control={control}
                            // rules={{ required: t('This is required') }}
                        />
                    </div>
                    <h5 className="header column-span-2">{`${t('Email')}`}</h5>
                    <hr className="horizontal-divider column-span-2" />
                    <div className="modal-input column-span-3">
                        <CustomObjectInput
                            type="text"
                            classes={['valid-from-input', 'generic-input']}
                            id="smtp-username"
                            label={t('SMTP Username')}
                            name="smtpUsername"
                            register={register}
                            stateField={newUser.smtpUsername}
                            setStateField={handleNewUser}
                            errors={errors}
                            control={control}
                            // rules={{ required: t('This is required') }}
                        />
                    </div>
                    <div className="modal-input column-span-3">
                        <CustomObjectInput
                            type="password"
                            classes={['password', 'generic-input']}
                            id="smtp-password"
                            label={t('SMTP Password')}
                            name="smtpPassword"
                            register={register}
                            stateField={newUser.smtpPassword}
                            setStateField={handleNewUser}
                            errors={errors}
                            control={control}
                            // rules={{ required: t('This is required') }}
                        />
                    </div>

                    <div className="modal-input column-span-3">
                        <CustomObjectInput
                            type="text"
                            classes={['valid-from-input', 'generic-input']}
                            id="smtp-host"
                            label={t('SMTP Host')}
                            name="smtpHost"
                            register={register}
                            stateField={newUser.smtpHost}
                            setStateField={handleNewUser}
                            errors={errors}
                            control={control}
                            // rules={{ required: t('This is required') }}
                        />
                    </div>
                    <div className="modal-input column-span-3">
                        <CustomObjectInput
                            type="text"
                            classes={['valid-from-input', 'generic-input']}
                            id="smtp-port"
                            label={t('SMTP Port')}
                            name="smtpPort"
                            register={register}
                            stateField={newUser.smtpPort}
                            setStateField={handleNewUser}
                            errors={errors}
                            control={control}
                            // rules={{ required: t('This is required') }}
                        />
                    </div>
                    <div className="modal-input column-span-3">
                        <CustomObjectInput
                            type="text"
                            classes={['valid-from-input', 'generic-input']}
                            id="smtp-email"
                            label={t('SMTP Email')}
                            name="smtpEmail"
                            register={register}
                            stateField={newUser.smtpEmail}
                            setStateField={handleNewUser}
                            errors={errors}
                            control={control}
                            // rules={{ required: t('This is required') }}
                        />
                    </div>
                    <h5 className="header column-span-2">{`${t(
                        'Address Information',
                    )}`}</h5>
                    <hr className="horizontal-divider column-span-2" />
                    <div className="modal-input column-span-3">
                        <CustomObjectInput
                            type="text"
                            classes={['expiry-date-input', 'generic-input']}
                            id="Important date"
                            label={t('Street')}
                            name="street"
                            register={register}
                            stateField={newUser.addressStreet}
                            setStateField={handleNewUser}
                            errors={errors}
                            control={control}
                        />
                    </div>
                    <div className="modal-input column-span-3">
                        <CustomObjectInput
                            type="text"
                            classes={['expiry-date-input', 'generic-input']}
                            id="Important date"
                            label={t('House number')}
                            name="house-number"
                            register={register}
                            stateField={newUser.addressHouseNumber}
                            setStateField={handleNewUser}
                            errors={errors}
                            control={control}
                        />
                    </div>
                    <div className="modal-input column-span-3">
                        <CustomObjectInput
                            type="text"
                            classes={['expiry-date-input', 'generic-input']}
                            id="Important date"
                            label={t('Postal code')}
                            name="postal-code"
                            register={register}
                            stateField={newUser.addressPostal}
                            setStateField={handleNewUser}
                            errors={errors}
                            control={control}
                        />
                    </div>
                    <div className="modal-input column-span-3">
                        <CustomObjectInput
                            type="text"
                            classes={['expiry-date-input', 'generic-input']}
                            id="Important date"
                            label={t('City')}
                            name="city"
                            register={register}
                            stateField={newUser.addressCity}
                            setStateField={handleNewUser}
                            errors={errors}
                            control={control}
                        />
                    </div>
                    <div className="modal-input column-span-3">
                        <CustomObjectInput
                            type="text"
                            classes={['expiry-date-input', 'generic-input']}
                            id="Important date"
                            label={t('Country')}
                            name="country"
                            register={register}
                            stateField={newUser.addressCountry}
                            setStateField={handleNewUser}
                            errors={errors}
                            control={control}
                        />
                    </div>
                </form>
            </Dialog>
        </>
    );
};

export default UserFormModal;

import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import classNames from 'classnames';
import { CSSTransition } from 'react-transition-group';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import AppMenu from './AppMenu';
import AppBreadcrumb from './AppBreadcrumb';
import AppFooter from './AppFooter';
import { useApplicationContext } from './application/ApplicationContext';
import { userService } from './services/UsersService';
import './AppTopbar.scss';
import { useTranslation } from 'react-i18next';
import { ProgressSpinner } from 'primereact/progressspinner';

const AppTopbar = (props) => {
    const images = [
        '../public/assets/demo/images/avatar/stephenshaw.png',
        '../public/assets/demo/images/avatar/ivanmagalhaes.png',
        '../public/assets/demo/images/avatar/xuxuefeng.png',
        '../public/assets/demo/images/avatar/bernardodominic.png',
        '../public/assets/demo/images/avatar/amyelsner.png',
        '../public/assets/demo/images/flags/flag_placeholder.png',
    ];

    const amount = [
        {
            label: '*****24',
            value: { id: 1, name: '*****24', code: 'A1' },
        },
        {
            label: '*****75',
            value: { id: 2, name: '*****75', code: 'A2' },
        },
    ];

    let searchInputEl = null;

    const { appState, logout, setShowMyTeam } = useApplicationContext();
    const { t } = useTranslation();

    const [employeeList, setEmployeeList] = useState([]);
    const [selectedAmount, setSelectedAmount] = useState(amount[0].value);
    const [menuActive, setMenuActive] = useState<boolean>(false);
    const [searchActive, setSearchActive] = useState<boolean>(false);
    const [topbarUserMenuActive, setTopbarUserMenuActive] =
        useState<boolean>(false);
    const [topbarNotificationMenuActive, setTopbarNotificationMenuActive] =
        useState<boolean>(false);
    const [sidebarMenuActive, setSidebarMenuActive] = useState<boolean>(false);
    const [staticMenuDesktopInactive, setStaticMenuDesktopInactive] =
        useState(false);
    const [staticMenuMobileActive, setStaticMenuMobileActive] = useState(false);
    const [users, setUsers] = useState([]);
    const [activeTeamView, setActiveTeamView] = useState(false);

    const topbarClassName = classNames('layout-topbar', {
        'main-menu-active': appState.activeSidebar,
    });
    const menuButtonClassName = classNames('menu-button p-link', {
        'main-menu-inactive': appState.activeSidebar,
    });
    const footerClassName = classNames({
        'footer-menu-inactive': appState.activeSidebar === true,
    });
    const notificationsItemClassName = classNames('notifications-item', {
        'active-menuitem fade-in-up': topbarNotificationMenuActive,
    });
    const profileItemClassName = classNames('profile-item', {
        'active-menuitem fade-in-up': topbarUserMenuActive,
    });
    const sidebarClassName = classNames('layout-sidebar-right', {
        'layout-sidebar-right-active': sidebarMenuActive,
    });
    const appMainMenuClassName = classNames('layout-wrapper', {
        'layout-overlay': props.menuMode === 'overlay',
        'layout-static': props.menuMode === 'static',
        'layout-slim': props.menuMode === 'slim',
        'layout-mobile-active': staticMenuMobileActive,
        'layout-static-inactive':
            staticMenuDesktopInactive && props.menuMode === 'static',
    });
    const contentClassName = classNames({
        '': appState.activeSidebar === undefined,
        'main-menu-inactive': appState.activeSidebar === false,
        'main-menu-active': appState.activeSidebar === true,
    });

    const onEmployeeClick = () => {
        setShowMyTeam(!appState.teamView);
        props.onTopbarUserMenu();
    };

    const onInputKeydown = (event) => {
        const key = event.which;

        //escape, tab and enter
        if (key === 27 || key === 9 || key === 13) {
            setSearchActive(false);
        }
    };

    const onEnter = () => {
        if (searchInputEl) {
            searchInputEl.focus();
        }
    };

    const isDesktop = () => {
        return window.innerWidth > 991;
    };

    const onMenuClick = () => {
        if (isDesktop()) {
            setMenuActive(true);
            setStaticMenuDesktopInactive(
                (prevStaticMenuDesktopInactive) =>
                    !prevStaticMenuDesktopInactive,
            );
            appState.activeSidebar === true
                ? (appState.activeSidebar = undefined)
                : (appState.activeSidebar = true);
        } else {
            setStaticMenuMobileActive(
                (prevStaticMenuMobileActive) => !prevStaticMenuMobileActive,
            );
        }
    };

    const onRootMenuitemClick = () => {
        setMenuActive((prevMenuActive) => !prevMenuActive);
    };

    const initialsName = (fullName) => {
        const initials = fullName?.match(/\b\w/g) || [];
        const acronym = (
            (fullName?.slice(0, 2) || '') + (initials?.pop() || '')
        ).toUpperCase();
        return acronym;
    };

    return (
        <>
            <div className="topbar-wrapper-layout">
                <div className={topbarClassName}>
                    <div className="topbar-left">
                        <button
                            type="button"
                            className={menuButtonClassName}
                            onClick={onMenuClick}
                        >
                            <i className="pi pi-chevron-left"></i>
                        </button>
                        <span className="topbar-separator"></span>
                        <div
                            className="layout-breadcrumb viewname"
                            style={{ textTransform: 'uppercase' }}
                        >
                            <AppBreadcrumb
                                currentRouter={props.currentRouter}
                                routers={props.routers}
                            />
                        </div>
                        <img
                            id="logo-mobile"
                            className="mobile-logo"
                            src="../public/assets/layout/images/logo-dark.svg"
                            alt="diamond-layout"
                        />
                    </div>
                    <div className="topbar-right">
                        <ul className="topbar-menu">
                            {/*<li className="search-item">*/}
                            {/*    <button*/}
                            {/*        type="button"*/}
                            {/*        className="p-link"*/}
                            {/*        onClick={() => {*/}
                            {/*            setSearchActive(!searchActive);*/}
                            {/*            setTopbarNotificationMenuActive(false);*/}
                            {/*            setTopbarUserMenuActive(false);*/}
                            {/*            setSidebarMenuActive(false);*/}
                            {/*        }}*/}
                            {/*    >*/}
                            {/*        <i className="pi pi-search"></i>*/}
                            {/*    </button>*/}
                            {/*    <div className="layout-search">*/}
                            {/*        <CSSTransition*/}
                            {/*            classNames="search-container"*/}
                            {/*            timeout={{ enter: 400, exit: 400 }}*/}
                            {/*            in={searchActive}*/}
                            {/*            unmountOnExit*/}
                            {/*            onEnter={onEnter}*/}
                            {/*        >*/}
                            {/*            <div className="search-container">*/}
                            {/*                <i className="pi pi-search"></i>*/}
                            {/*                <InputText*/}
                            {/*                    ref={(el) =>*/}
                            {/*                        (searchInputEl =*/}
                            {/*                            ReactDOM.findDOMNode(*/}
                            {/*                                el,*/}
                            {/*                            ))*/}
                            {/*                    }*/}
                            {/*                    type="text"*/}
                            {/*                    name="search"*/}
                            {/*                    placeholder={t('Search')}*/}
                            {/*                    onKeyDown={onInputKeydown}*/}
                            {/*                />*/}
                            {/*            </div>*/}
                            {/*        </CSSTransition>*/}
                            {/*    </div>*/}
                            {/*</li>*/}

                            <li className={profileItemClassName}>
                                <button
                                    type="button"
                                    className="p-link"
                                    onClick={() => {
                                        setTopbarUserMenuActive(
                                            !topbarUserMenuActive,
                                        );
                                        setSearchActive(false);
                                        setTopbarNotificationMenuActive(false);
                                        setSidebarMenuActive(false);
                                    }}
                                >
                                    {!appState.teamView ? (
                                        <span className="initials-profile">
                                            <b>
                                                {initialsName(
                                                    appState.currentUser.name,
                                                )}
                                            </b>
                                        </span>
                                    ) : (
                                        <div className="profile">T</div>
                                    )}
                                    <span className="profile-name">
                                        <p>
                                            {appState.teamView
                                                ? 'My team'
                                                : appState.currentUser.name}
                                        </p>
                                    </span>
                                </button>
                                <ul
                                    className="profile-menu fade-in-up"
                                    onMouseLeave={() =>
                                        setTopbarUserMenuActive(false)
                                    }
                                >
                                    <div className="avatar-checkbox">
                                        <input
                                            type="checkbox"
                                            id={'profile-images'}
                                            onClick={onEmployeeClick}
                                        />
                                        <label htmlFor={'profile-images'}>
                                            <div className="avatar">T</div>
                                            <div className="checkmark">
                                                <i className="pi pi-check"></i>
                                            </div>
                                            <div className="avatar-name">
                                                {!appState.teamView
                                                    ? 'My team'
                                                    : appState.currentUser.name}
                                            </div>
                                        </label>
                                    </div>

                                    <hr />

                                    <li>
                                        <button
                                            type="button"
                                            className="p-link"
                                            onClick={(e: React.MouseEvent) =>
                                                logout()
                                            }
                                        >
                                            <i className="pi pi-power-off"></i>
                                            <span>{t('Logout')}</span>
                                        </button>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className={appMainMenuClassName}>
                    <AppMenu
                        model={props.model}
                        menuMode={props.menuMode}
                        active={menuActive}
                        mobileMenuActive={staticMenuMobileActive}
                        onMenuClick={props.onMenuClick}
                        onMenuitemClick={props.onMenuitemClick}
                        onRootMenuitemClick={onRootMenuitemClick}
                    ></AppMenu>
                </div>

                {appState.loadingSpinner ? (
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            position: 'fixed',
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 0,
                            background: 'rgba(0,0,0,0.5)',
                            zIndex: -1,
                        }}
                    >
                        <ProgressSpinner strokeWidth={'3'} />
                    </div>
                ) : null}
                <div style={{ zIndex: 0 }} className={contentClassName}>
                    {props.componentContent}
                </div>
            </div>
            <div className={footerClassName}>
                <AppFooter />
            </div>
        </>
    );
};

export default AppTopbar;

import * as React from 'react';
import { Toast } from 'primereact/toast';
import { useApplicationContext } from './ApplicationContext';
import LoginForm from '../components/login/LoginForm';
import ForgotPasswordForm from '../components/login/ForgetPasswordForm/ForgotPasswordForm';
import '../components/login/Login.scss';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export default function LoginView() {
    const { appState, showPasswordReset, loginGoBack } =
        useApplicationContext();
    const { t } = useTranslation();

    const renderFields = () => {
        if (
            !appState.authenticated &&
            appState.passwordResetInProgress &&
            !appState.passwordResetUsername
        ) {
            return (
                <>
                    <div className="password-form-container">
                        <ForgotPasswordForm />

                        <Link
                            onClick={(e: React.MouseEvent) => loginGoBack()}
                            className="close-button"
                            to={`/login`}
                        >
                            {t('Nazad')}
                        </Link>
                    </div>
                </>
            );
        } else {
            return <LoginForm />;
        }
    };

    return (
        <div className="login-page">
            <div id="login-logo-column" className="login-logo-column">
                <div className="login-logo-container">
                    <img
                        src="../../public/assets/layout/images/golden_home_logo_login.svg"
                        className="login-logo"
                        alt="golden-home-logo"
                    />
                </div>
            </div>
            {renderFields()}
        </div>
    );
}

import { Config } from '../Config';
import { CrudService } from './CrudService';
import { AxiosResponse } from "axios";
import { CreateQueryParams } from '@nestjsx/crud-request';
import { InquiryActivity } from '../models/InquiryActivity';
import { axiosDefaults } from './AxiosDefaults';

export class InquiryActivityService extends CrudService<InquiryActivity, 'InquiryActivity'> {

    protected getUrlPath(): string {
        return '/inquiries/activities';
    };

    public async getOne(id: string | number): Promise<AxiosResponse<InquiryActivity>> {
        const inquiryActivityResponse = await super.getOne(id);
        return new Promise((resolve: (inquiryActivity) => void, reject: () => void) => {resolve(inquiryActivityResponse)})
    };

    public async getMany(params?: CreateQueryParams): Promise<AxiosResponse<InquiryActivity[]>> {
        const inquiryActivitiesResponse = await super.getMany(params);
        return new Promise((resolve: (inquiryActivity) => void, reject: () => void) => {resolve(inquiryActivitiesResponse)})
    };

    public async createOne( inquiryActivity: InquiryActivity): Promise<AxiosResponse<InquiryActivity>> {
        let body:InquiryActivity = {...inquiryActivity};
        let newBody = await JSON.stringify(body);
        return this.axiosInstance.post(
            axiosDefaults.baseURL,
            newBody,
            {withCredentials: true, headers: {'Content-Type': 'application/json'}},
        );
    };

    public async updateOne(id: string, inquiryActivity: InquiryActivity): Promise<AxiosResponse<InquiryActivity>> {
        let body = {...inquiryActivity};
        let newBody = await JSON.stringify(body);
        return this.axiosInstance.post(
            axiosDefaults.baseURL + `/inquiries/${id}/activities`,
            newBody,
            {withCredentials: true, headers: {'Content-Type': 'application/json'}},
        );
    };

    public deleteOne(id: string | number): Promise<AxiosResponse<InquiryActivity>> {
        return this.axiosInstance.delete(axiosDefaults.baseURL + this.getUrlPath() + "/" + id);
    };
};

export const inquiryActivityService = new InquiryActivityService();

import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import CustomObjectInput from '../objects/customObjectInput/CustomObjectInput';
import './ExposeModal.scss';
import { useTranslation } from 'react-i18next';
import dateFormat from 'dateformat';
import { useApplicationContext } from '../../application/ApplicationContext';
import { exposeService } from '../../services/ExposeService';

export const ExposeModal = ({ showModal, visibleModal, propertyId }) => {
    const { t } = useTranslation();

    return (
        <>
            <Dialog
                header={t('Expose preview')}
                visible={visibleModal}
                onHide={showModal}
                resizable={true}
            >
                <embed
                    src={exposeService.getExposePath(propertyId)}
                    style={{
                        height: '100%',
                        width: '100%',
                        minWidth: '800px',
                        minHeight: '600px',
                    }}
                />
            </Dialog>
        </>
    );
};

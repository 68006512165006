import React, { useEffect, useState, useCallback } from 'react';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import WidgetOverviewBox from '../../components/widgetOverviewBox/widgetOverviewBox';
import './FirstDatePage.scss';
import ObjectStatusBadge from '../../components/objects/objectStatusBadge/ObjectStatusBadge';
import { Button } from 'primereact/button';
import { Card } from 'primereact/card';
import { useTranslation } from 'react-i18next';
import { userService } from '../../services/UsersService';
import { firstDateService } from '../../services/FirstDateService';
import dateFormat from 'dateformat';
import { useHistory, useLocation } from 'react-router-dom';
import FirstDateFormModal from '../../components/FirstDateFormModal/FirstDateFormModal';
import { Toast } from 'primereact/toast';
import moment from 'moment';
import { useApplicationContext } from '../../application/ApplicationContext';
import { InputText } from 'primereact/inputtext';
import PermissionContainer from '../../core/auth/PermissionContainer';
import { Permissions } from '../../core/auth/Permissions';
import { DomHandler } from 'primereact/utils';
import { useStateCallback } from '../../hooks/StateCallbackHook';
import { ProgressSpinner } from 'primereact/progressspinner';
import { inquiriesService } from '../../services/InquiriesService';

const FirstDatePage = () => {
    const [loading, setLoading] = useState(false);
    const { t } = useTranslation();
    const [firstDates, setFirstDates] = useState([]);
    const [allFirstDates, setAllFirstDates] = useState([]);
    const [status, setStatus] = useState(0);
    const [visible, setVisible] = useStateCallback(false);
    const toast = React.useRef(null);
    const [clickedStatus, setClickedStatus] = useState('');
    const { appState, setNewInquiry } = useApplicationContext();
    const location = useLocation();
    const [resetSearchFilter, setResetSearchFilter] = useState(true);
    const [resetSearchButton, setResetSearchButton] = useState(true);

    //// CLICK DESHBOARD RETURN TO FIRST DATE

    useEffect(() => {
        //@ts-ignore
        handlerActiveStatus(location.state?.status ?? '');
    }, [location]);

    useEffect(() => {
        //@ts-ignore
        if (location.state?.status == undefined) {
            setClickedStatus('');
        }
    }, []);

    const statusOnHold =
        allFirstDates.filter((el) => el.status === 'On Hold') ?? [];

    const statusBestatigt =
        allFirstDates.filter((el) => el.status === 'Bestätigt') ?? [];

    const statusNeu =
        allFirstDates.filter(
            (el) => el.status === 'Neu' || el.status === 'Neu bold',
        ) ?? [];

    const statusNeuOverdue =
        allFirstDates.filter((el) => {
            const start = moment(el.createdAt);
            const end = moment();
            const totalMinutes = moment.duration(end.diff(start)).asMinutes();
            const hours = Math.floor(totalMinutes / 60);

            return (
                (el.status === 'Neu bold' || el.status === 'Neu') && hours >= 24
            );
        }) ?? [];

    const statusWiedervorlage =
        allFirstDates.filter((el) => el.status === 'Wiedervorlage') ?? [];

    const statusAbgesagt =
        allFirstDates.filter((el) => el.status === 'Abgesagt') ?? [];

    const statusMandatErhalten =
        allFirstDates.filter((el) => el.status === 'Mandat erhalten') ?? [];

    const statusNichtAufgenommen =
        allFirstDates.filter((el) => el.status === 'Nicht aufgenommen') ?? [];

    const statusZuruckAnCC =
        allFirstDates.filter((el) => el.status === 'Zurück an CC') ?? [];

    useEffect(() => {
        setLoading(true);
    }, []);

    //TEAM VIEW
    useEffect(() => {
        userService.refreshTokenCheck().then(() => {
            fetchFirstDates(appState.teamView).then((result) => {
                setResetSearchButton(true);
                setFirstDates(result);
                setAllFirstDates(result);
                setLoading(false);
            });
        });
        setNewInquiry(false);
    }, [
        visible,
        status,
        appState.showNewData,
        appState.teamView,
        resetSearchFilter,
    ]);

    //TEAM VIEW
    const fetchFirstDates = async (show) => {
        const response = await firstDateService.getMany(show);
        return response.data;
    };

    const showModal = () => {
        setVisible(!visible, (state) => {
            if (!state) {
                DomHandler.removeClass(document.body, 'p-overflow-hidden');
            }
        });
    };

    const paginatorLeft = (
        <Button type="button" icon="pi pi-refresh" className="p-button-text" />
    );
    const paginatorRight = (
        <Button type="button" icon="pi pi-cloud" className="p-button-text" />
    );

    const statusBodyTemplate = (rowData) => {
        const splitStatus = t(
            rowData.status === 'Neu bold' ? 'Brandneu' : rowData.status,
        );
        return (
            <ObjectStatusBadge
                type="new-table-with-text"
                status={`${t(splitStatus)}`}
            />
        );
    };

    const idBodyTemplate = (rowData) => {
        return rowData.property.rootId;
    };

    const handlerActiveStatus = (status) => {
        setClickedStatus(clickedStatus === status ? '' : status);
    };

    const history = useHistory();
    const viewAction = (rowData) => {
        history.push({
            pathname: `/first-dates/${rowData.id}`,
            state: {
                data: rowData,
            },
        });
    };

    const deleteActionBodyTemplate = (rowData) => {
        return (
            <Button
                type="button"
                icon="pi pi-trash"
                className="delete-action-button"
                onClick={() => {
                    firstDateService
                        .deleteOne(rowData.id)
                        .then((response) => setStatus(response.status))
                        .catch((err) => setStatus(err.response.status));
                }}
            />
        );
    };

    const propertydata = (rowData) => {
        let objectAddressText = `ID ${rowData.property?.rootId}, ${rowData.property?.place} ${rowData.property?.plz}, ${rowData.property.street} ${rowData.property.houseNumber}.`;
        return objectAddressText;
    };

    useEffect(() => {
        if (status === 200) {
            toast.current.show({
                severity: 'success',
                detail: t(`First date has been deleted successfully`),
                life: 3000,
            });
            setStatus(0);
        }
        if (status === 500) {
            toast.current.show({
                severity: 'error',
                detail: t(`internal server error`),
                life: 3000,
            });
            setStatus(0);
        }
    }, [status]);

    const acquisition = (rowData) => {
        return rowData.acquisition ? t('Yes') : t('No');
    };

    const priceColumn = (rowData) => {
        return rowData?.property?.price?.netPurchasePrice
            ? `${rowData?.property?.price?.netPurchasePrice?.toLocaleString()} EUR`
            : '';
    };

    const showSeller = (rowData) => {
        return rowData.seller?.firstName + ' ' + rowData.seller?.lastName;
    };

    const createdAtDateFormat = (rowData) => {
        return dateFormat(rowData.createdAt, 'dd.mm.yyyy.');
    };

    const dateFormatUpdatedAt = (rowData) => {
        const latestDate = new Date(
            Math.max(
                ...rowData.firstDateActivities.map((obj) =>
                    new Date(obj.createdAt).getTime(),
                ),
            ),
        );
        return moment(latestDate).format('DD.MM.YYYY kk:mm');
    };

    const dateFormatAgreementMadeAt = (rowData) => {
        return moment(rowData.appointmentDate).format('DD.MM.YYYY kk:mm');
    };

    const rowClass = (rowData) => {
        return {
            'row-bold': rowData.status === 'Neu bold',
        };
    };

    const showAgent = (rowData) => {
        if (!rowData.property.employee) {
            return <div title={'NOT AVAILABLE'}>N/A</div>;
        }

        const fullName = rowData.property.employee?.name.split(' ');

        const initials =
            fullName.length > 1
                ? fullName?.shift().charAt(0) +
                  '.' +
                  fullName?.pop().charAt(0) +
                  '.'
                : rowData.property.employee?.name.charAt(0) + '.';
        return (
            <div title={rowData.property.employee?.name}>
                {initials.toUpperCase()}
            </div>
        );
    };

    const [searchParam, setSearchParam] = useState('');

    async function searchFunction() {
        setLoading(true);
        const firstDates = await firstDateService.searchFirstDates(searchParam);

        if (firstDates) {
            setLoading(false);
            setFirstDates(firstDates.data);
        }
        setResetSearchButton(false);
    }

    if (loading) {
        return (
            <div className="center-spinner">
                <ProgressSpinner strokeWidth="5" animationDuration="1.5s" />
            </div>
        );
    } else {
        return (
            <>
                <Toast ref={toast} position={'top-right'} />
                <Card style={{ marginBottom: '20px' }}>
                    <div className="dropdown-container">
                        <span className="p-input-icon-right filter-item">
                            <i className="pi pi-search" />
                            <InputText
                                className="filter-item"
                                // value="Under development"
                                placeholder="Suche nach Kontakt oder Straße..."
                                onChange={(e) => {
                                    setSearchParam(e.target.value);
                                }}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                        searchFunction();
                                    }
                                }}
                            />
                        </span>
                        <div className="filter-div">
                            <Button
                                className="filter"
                                label={t('Filter')}
                                onClick={() => {
                                    searchFunction();
                                }}
                            />
                            {resetSearchButton == false && (
                                <button
                                    onClick={() =>
                                        setResetSearchFilter(!resetSearchFilter)
                                    }
                                    className="reset-status"
                                >
                                    <i
                                        className="pi pi-refresh"
                                        style={{
                                            fontSize: '1em',
                                        }}
                                    ></i>
                                </button>
                            )}
                        </div>
                    </div>
                </Card>
                <Card style={{ marginBottom: '20px' }}>
                    <div className="badge-box">
                        <div className="badge-legend-container">
                            <div
                                className={`status-button ${
                                    clickedStatus === 'Neu' ? 'cover' : ''
                                }`}
                                onClick={() => handlerActiveStatus('Neu')}
                            >
                                <ObjectStatusBadge
                                    type="legend"
                                    status={t('Neu')}
                                >
                                    {statusNeu.length.toString()}
                                    <span style={{ margin: '0 3px' }}> / </span>
                                    <span style={{ color: '#ff0000' }}>
                                        {statusNeuOverdue.length.toString()}
                                    </span>
                                </ObjectStatusBadge>
                            </div>
                            <div
                                className={`status-button ${
                                    clickedStatus === 'Wiedervorlage'
                                        ? 'cover'
                                        : ''
                                }`}
                                onClick={() =>
                                    handlerActiveStatus('Wiedervorlage')
                                }
                            >
                                <ObjectStatusBadge
                                    type="legend"
                                    status={t('Wiedervorlage')}
                                >
                                    {statusWiedervorlage.length.toString()}
                                </ObjectStatusBadge>
                            </div>
                            <div
                                className={`status-button ${
                                    clickedStatus === 'Bestätigt' ? 'cover' : ''
                                }`}
                                onClick={() => handlerActiveStatus('Bestätigt')}
                            >
                                <ObjectStatusBadge
                                    type="legend"
                                    status={t('Bestätigt')}
                                >
                                    {statusBestatigt.length.toString()}
                                </ObjectStatusBadge>
                            </div>
                            <div
                                className={`status-button ${
                                    clickedStatus === 'Abgesagt' ? 'cover' : ''
                                }`}
                                onClick={() => handlerActiveStatus('Abgesagt')}
                            >
                                <ObjectStatusBadge
                                    type="legend"
                                    status={t('Abgesagt')}
                                >
                                    {statusAbgesagt.length.toString()}
                                </ObjectStatusBadge>
                            </div>
                            <div
                                className={`status-button ${
                                    clickedStatus === 'On Hold' ? 'cover' : ''
                                }`}
                                onClick={() => handlerActiveStatus('On Hold')}
                            >
                                <ObjectStatusBadge
                                    type="legend"
                                    status={t('On-hold')}
                                >
                                    {statusOnHold.length.toString()}
                                </ObjectStatusBadge>
                            </div>
                            <div
                                className={`status-button ${
                                    clickedStatus === 'Mandat erhalten'
                                        ? 'cover'
                                        : ''
                                }`}
                                onClick={() =>
                                    handlerActiveStatus('Mandat erhalten')
                                }
                            >
                                <ObjectStatusBadge
                                    type="legend-wide"
                                    status={t('Mandat-Erhalten')}
                                >
                                    {statusMandatErhalten.length.toString()}
                                </ObjectStatusBadge>
                            </div>
                            <div
                                className={`status-button ${
                                    clickedStatus === 'Nicht aufgenommen'
                                        ? 'cover'
                                        : ''
                                }`}
                                onClick={() =>
                                    handlerActiveStatus('Nicht aufgenommen')
                                }
                            >
                                <ObjectStatusBadge
                                    type="legend-wide"
                                    status={t('Nicht-Aufgenommen')}
                                >
                                    {statusNichtAufgenommen.length.toString()}
                                </ObjectStatusBadge>
                            </div>
                            <div
                                className={`status-button ${
                                    clickedStatus === 'Zurück an CC'
                                        ? 'cover'
                                        : ''
                                }`}
                                onClick={() =>
                                    handlerActiveStatus('Zurück an CC')
                                }
                            >
                                <ObjectStatusBadge
                                    type="legend"
                                    status={t('Zurück an CC')}
                                >
                                    {statusZuruckAnCC.length.toString()}
                                </ObjectStatusBadge>
                            </div>
                            {clickedStatus !== '' && (
                                <button
                                    onClick={() => setClickedStatus('')}
                                    className="reset-status"
                                >
                                    <i
                                        className="pi pi-refresh"
                                        style={{ fontSize: '1em' }}
                                    ></i>
                                </button>
                            )}
                        </div>
                    </div>
                </Card>
                <Card className={`no-padding`}>
                    <div className="main-container-first-date">
                        {visible ? (
                            <FirstDateFormModal
                                toast={toast}
                                showModal={showModal}
                            />
                        ) : null}

                        <DataTable
                            value={firstDates.filter((el) => {
                                if (clickedStatus !== '') {
                                    if (clickedStatus === 'Neu') {
                                        return (
                                            el.status === clickedStatus ||
                                            el.status === 'Neu bold'
                                        );
                                    }

                                    return el.status === clickedStatus;
                                }

                                return true;
                            })}
                            className="p-datatable-lg"
                            paginator={true}
                            paginatorPosition="bottom"
                            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                            currentPageReportTemplate={`{first} ${t(
                                'bis',
                            )} {last} ${t('of')} {totalRecords}`}
                            rows={50}
                            rowsPerPageOptions={[10, 20, 50, 100]}
                            sortField="id"
                            sortOrder={-1}
                            rowClassName={rowClass}
                            paginatorLeft={paginatorLeft}
                            paginatorRight={paginatorRight}
                            scrollHeight="700px"
                            selectionMode="single"
                            onSelectionChange={(e) => viewAction(e.value)}
                        >
                            <Column
                                className="column-container-second"
                                body={statusBodyTemplate}
                                field={'status'}
                                header={t('Status')}
                            />
                            <Column
                                sortable
                                className="column-container-second"
                                body={idBodyTemplate}
                                header={t('ID')}
                            />
                            <Column
                                className="column-container-second"
                                body={acquisition}
                                header={t('Akviza')}
                            />
                            <Column
                                className="column-container-name "
                                body={showSeller}
                                header={t('Ime')}
                            />
                            <Column
                                className="column-container"
                                field="property.place"
                                //header={t('Lokacija')}
                                body={propertydata}
                                header={t('Objektadresse')}
                                style={{
                                    minWidth: '20rem',
                                }}
                            />
                            <Column
                                className="column-container-third"
                                field={`property.category.name`}
                                header={t('Tip')}
                            />
                            <Column
                                className="column-container-third"
                                body={priceColumn}
                                header={t('Cena')}
                            />
                            <Column
                                className="column-container"
                                body={createdAtDateFormat}
                                header={t('Created at')}
                            />
                            <Column
                                className="column-container"
                                body={dateFormatAgreementMadeAt}
                                header={t('Termindatum')}
                            />
                            <Column
                                className="column-container"
                                body={dateFormatUpdatedAt}
                                field="createdAt"
                                header={t('Letzte Bearb.')}
                            />
                            <Column
                                header={t('Agent')}
                                body={showAgent}
                                className="column-container"
                            />
                            {/*<Column*/}
                            {/*    body={deleteActionBodyTemplate}*/}
                            {/*    className="datatable-button-column"*/}
                            {/*    hidden={*/}
                            {/*        !userService.hasPermission(*/}
                            {/*            appState.currentUser,*/}
                            {/*            Permissions.PROPERTY_DELETE,*/}
                            {/*        )*/}
                            {/*    }*/}
                            {/*/>*/}
                        </DataTable>
                    </div>
                </Card>
            </>
        );
    }
};

export default FirstDatePage;

import React = require('react');
import {User} from "../../models/User";
import {ReactNode} from "react";
import {userService} from "../../services/UsersService"

export interface PermissionContainerProps {
	hasPermission?: string;
	hasAnyPermission?: string[];
	hasAllPermissions?: string[];
	currentUser: User,
	children?: React.ReactElement
}

const PermissionContainer = (p: PermissionContainerProps) => {

    const currentUser = p.currentUser
    if (!currentUser) return null
    if(currentUser.isAdmin
        || (p.hasPermission != null && userService.hasPermission(currentUser, p.hasPermission))
        || (p.hasAnyPermission != null && userService.hasAnyPermission(currentUser, p.hasAnyPermission))
        || (p.hasAllPermissions != null && userService.hasAllPermissions(currentUser, p.hasAllPermissions))
    ){
        return p.children
    }
    return null;
}

export default PermissionContainer

export interface FirstDateActivity {
    type: TypeOfFirstDateActivity;
    date?: Date;
    comment?: string;
}

export enum TypeOfFirstDateActivity {
    TELEPHONE_ACTIVE = 'Tel. Out',
    TELEPHONE_PASSIVE = 'Tel. Inc.',
    TELEPHONE_NE = 'Tel. NE',
    TELEPHONE_RR = 'Keine Tel. Nr. - Mail',
    MAIL = 'Mail',
    MAIL_SENT = 'Versendet',
    MAIL_RECEIVED = 'Empfangen',
    MAIL_WELCOME = 'Welocome',
    MAIL_ACTIVE = 'Mail Out',
    MAIL_PASSIVE = 'Mail Inc.',
    CONFIRM_APPOINTMENT = 'Termin bestat',
    MOCK_FEEDBACK = 'Schein Feedback',
    PERSONALLY = 'Personlich',
    CALENDAR = 'Kalender',
    CONVERSATION_NOTE = 'Geshprachsnotiz',
    COMMENT = 'Comment',
    SERVICE_APPOINTMENT = 'Servicetermin',
    FD_NEW = 'Neu',
    FD_FOLLOW_UP = 'Wiedervorlage',
    FD_APPROVED = 'Bestätigt',
    FD_CALLED_OFF = 'Abgesagt',
    FD_ON_HOLD = 'On Hold',
    FD_RECEIVED_A_MANDATE = 'Mandat erhalten',
    FD_NOT_RECORDED = 'Nicht aufgenommen',
    FD_BACK_TO_CC = 'Zurück an CC',
    FD_INACTIVE = 'Inaktiv',
    FD_CLOSED = 'Abgeschlossen',
}

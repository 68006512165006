import React from 'react';

const AppFooter = () => {

    return (
        <div className="layout-footer">
            <div className="footer-logo-container">
                <img id="footer-logo" src="../public/assets/layout/images/logo-footer.png" alt="diamond-layout" />
            </div>
            <span className="copyright">&#169; GOLDEN HOME REAL ESTATE</span>
        </div>
    );
}

export default AppFooter;

import { Config } from '../Config';
import { CrudService } from './CrudService';
import { AxiosResponse } from "axios";
import { CreateQueryParams } from '@nestjsx/crud-request';
import { FirstDateActivity } from '../models/FirstDateActivity';
import { axiosDefaults } from './AxiosDefaults';

export class FirstDateActivityService extends CrudService<FirstDateActivity, 'FirstDateActivity'> {

    protected getUrlPath(): string {
        return '/first-dates/activities';
    };

    public async getOne(id: string | number): Promise<AxiosResponse<FirstDateActivity>> {
        const inquiryActivityResponse = await super.getOne(id);
        return new Promise((resolve: (inquiryActivity) => void, reject: () => void) => {resolve(inquiryActivityResponse)})
    };

    public async getMany(params?: CreateQueryParams): Promise<AxiosResponse<FirstDateActivity[]>> {
        const inquiryActivitiesResponse = await super.getMany(params);
        return new Promise((resolve: (inquiryActivity) => void, reject: () => void) => {resolve(inquiryActivitiesResponse)})
    };

    public async createOne( inquiryActivity: FirstDateActivity): Promise<AxiosResponse<FirstDateActivity>> {
        let body:FirstDateActivity = {...inquiryActivity};
        let newBody = await JSON.stringify(body);
        return this.axiosInstance.post(
            axiosDefaults.baseURL + this.getUrlPath() ,
            newBody,
            {withCredentials: true, headers: {'Content-Type': 'application/json'}},
        );
    };

    public async updateOne(id: string, inquiryActivity: FirstDateActivity): Promise<AxiosResponse<FirstDateActivity>> {
        let body = {...inquiryActivity};
        let newBody = await JSON.stringify(body);
        return this.axiosInstance.post(
            axiosDefaults.baseURL + `/first-dates/${id}/activities`,
            newBody,
            {withCredentials: true, headers: {'Content-Type': 'application/json'}},
        );
    };

    public deleteOne(id: string | number): Promise<AxiosResponse<FirstDateActivity>> {
        return this.axiosInstance.delete(axiosDefaults.baseURL + this.getUrlPath() + "/" + id);
    };
};

export const firstDateActivityService = new FirstDateActivityService();

import React, { CSSProperties, useEffect, useState } from 'react';
import { DateSelectArg } from '@fullcalendar/core';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import multiMonthPlugin from '@fullcalendar/multimonth';
import './CalendarPage.scss';
import { Dropdown } from 'primereact/dropdown';
import CalendarFormModal from '../../components/CalendarFormModal/CalendarFormModal';
import { TabMenu } from 'primereact/tabmenu';
import {
    ICalendarFormModal,
    IEditCalendarFormModal,
} from '../../models/CalendarFormModal';
import { useApplicationContext } from '../../application/ApplicationContext';
import CalendarEventModal from '../../components/CalendarEventModal/CalendarEventModal';
import dateFormat from 'dateformat';
import { Card } from 'primereact/card';
import { useTranslation } from 'react-i18next';
import { Toast } from 'primereact/toast';
import { useLocation } from 'react-router-dom';
import moment from 'moment';
import PermissionContainer from '../../core/auth/PermissionContainer';
import { Permissions } from '../../core/auth/Permissions';
import { DomHandler } from 'primereact/utils';
import { useStateCallback } from '../../hooks/StateCallbackHook';
import { ProgressSpinner } from 'primereact/progressspinner';
import deLocale from '@fullcalendar/core/locales/de';

const Calendar = () => {
    const { t } = useTranslation();

    const {
        appState,
        deleteCalendarEvent,
        addNewEvents,
        editNewEvents,
        getInitialEvents,
    } = useApplicationContext();

    const location = useLocation();
    // @ts-ignore
    const dateId: any = location?.state?.dataId;
    const [visible, setVisible] = useStateCallback(false);
    const [eventvisible, setEventVisible] = useStateCallback(false);
    const [singleEvent, setSingleEvent] = useState([]);
    const [events, setEvents] = useState([]);
    const [idCalendar, setIdCalendar] = useState(dateId);
    const toast = React.useRef(null);
    const [loading, setLoading] = useState(false);

    const [initialStartDate, setInitialStartDate] = useState(
        // @ts-ignore
        location?.state?.selectDataWidget,
    );

    const [calendarFormModalObject, setCalendarFormModalObject] =
        useState<ICalendarFormModal>({
            title: '',
            activityType: '',
            taskValidFrom: null,
            taskExpiryDate: null,
            id: Number(appState.currentUser.id),
            Object: null,
            contact: null,
            info: '',
        });

    const [editcalendarFormModalObject, setEditCalendarFormModalObject] =
        useState<IEditCalendarFormModal>({
            title: '',
            activityType: '',
            editValidFrom: '',
            editExpiryDate: '',
            id: Number(appState.currentUser.id),
            Object: null,
            contact: null,
            info: '',
        });

    useEffect(() => {
        if (events.length !== 0 && appState.newEvent !== null)
            if (idCalendar !== undefined && idCalendar !== null) {
                return handleEventClick({ id: idCalendar });
            }
    }, [appState.newEvent, events]);

    useEffect(() => {
        if (appState.newEvent !== null) {
            console.log('new event', appState.newEvent);
            setEvents(appState.newEvent);
        }
    }, [appState.newEvent]);

    useEffect(() => {
        setLoading(true);
    }, []);

    // TEAM VIEW
    useEffect(() => {
        getInitialEvents(
            Number(appState.currentUser.id),
            appState.teamView,
        ).then(() => setLoading(false));
    }, [eventvisible, appState.teamView]);

    const showModal = () => {
        setVisible(!visible, (state) => {
            if (!state) {
                DomHandler.removeClass(document.body, 'p-overflow-hidden');
            }
        });
    };

    const showEvent = () => {
        handleResetEditCalendarFormModal();
        setEventVisible(!eventvisible, (state) => {
            if (!state) {
                DomHandler.removeClass(document.body, 'p-overflow-hidden');
            }
        });
    };

    const handleCalendarForm = (prop, value) => {
        setCalendarFormModalObject((calendarFormModalObjectArg) => ({
            ...calendarFormModalObjectArg,
            [prop]: value,
        }));
    };

    const handleEditCalendarForm = (prop, value) => {
        setEditCalendarFormModalObject((editcalendarFormModalObjectArg) => ({
            ...editcalendarFormModalObjectArg,
            [prop]: value,
        }));
    };

    const deleteEvent = (id) => {
        deleteCalendarEvent(id);
        showEvent();
    };

    const editEvent = (idEvent) => {
        editNewEvents(
            editcalendarFormModalObject.title,
            editcalendarFormModalObject.activityType,
            moment(editcalendarFormModalObject.editValidFrom).format(),
            moment(editcalendarFormModalObject.editExpiryDate).format(),
            editcalendarFormModalObject.id,
            editcalendarFormModalObject.contact,
            editcalendarFormModalObject.Object,
            editcalendarFormModalObject.info,
            idEvent,
        );

        showEvent();
    };

    const handleEventMove = (props): boolean => {
        const calendarEvent = props.event._def.extendedProps.data;

        editNewEvents(
            calendarEvent.title,
            calendarEvent.status,
            moment(props.event.start).format(),
            moment(props.event.end).format(),
            calendarEvent.creatorId,
            calendarEvent.contactId,
            calendarEvent.propertyId,
            calendarEvent.description,
            calendarEvent.id,
        );

        return true;
    };

    const handleEventClick = (props) => {
        const singleEventA = props?.event?._def.extendedProps.defId;
        const id = props?.id;

        const filterEvent = events.filter((el) => {
            return el.id === singleEventA || el.id === id;
        });

        setSingleEvent(filterEvent);
        setEditCalendarFormModalObject((editcalendarFormModalObjectArg) => ({
            ...editcalendarFormModalObjectArg,
            title: filterEvent[0]?.title,
            activityType: filterEvent[0]?.status,
            editValidFrom: moment(filterEvent[0]?.startDateAndTime).format(),
            editExpiryDate: moment(filterEvent[0]?.endDateAndTime).format(),
            Object: filterEvent[0]?.property?.rootId,
            contact: filterEvent[0]?.contactId
                ? filterEvent[0]?.contactId
                : filterEvent[0]?.contact?.id,
            info: filterEvent[0]?.description,
        }));

        setEventVisible(eventvisible ? false : true);

        if (id === undefined) {
            setIdCalendar(null);
        }
    };

    const [selectInfo, setSelectInfo] = useState<any>();

    const handleDateSelect = (dateSelectArg: DateSelectArg) => {
        setSelectInfo(dateSelectArg);

        setCalendarFormModalObject({
            ...calendarFormModalObject,
            taskValidFrom: moment(dateSelectArg.start).format(),
            taskExpiryDate: moment(dateSelectArg.end).format(),
        });

        setVisible(true);
        return false;
    };

    ///// Add default 30 minutes

    useEffect(() => {
        if (selectInfo?.allDay === true) {
            const addedTime = moment(calendarFormModalObject.taskValidFrom)
                .add(30, 'minutes')
                .toDate();
            const time = moment(addedTime).format('HH:mm:ss');
            const dateCurrent = moment(
                calendarFormModalObject.taskExpiryDate,
            ).format('DD MM YYYY');
            const dateTime = moment(
                dateCurrent + ' ' + time,
                'DD/MM/YYYY HH:mm',
            ).toDate();

            setCalendarFormModalObject({
                ...calendarFormModalObject,
                taskExpiryDate: moment(dateTime).format(),
            });
        }
    }, [calendarFormModalObject.taskValidFrom]);

    ////

    const handleResetCalendarFormModal = () => {
        setCalendarFormModalObject({
            title: '',
            activityType: '',
            taskValidFrom: null,
            taskExpiryDate: null,
            id: Number(appState.currentUser.id),
            Object: null,
            contact: null,
            info: '',
        });
    };

    const handleResetEditCalendarFormModal = () => {
        setEditCalendarFormModalObject({
            title: '',
            activityType: '',
            editValidFrom: null,
            editExpiryDate: null,
            id: Number(appState.currentUser.id),
            Object: null,
            contact: null,
            info: '',
        });
    };

    const initialsName = (fullName) => {
        const initials = fullName?.match(/\b\w/g) || [];
        const acronym = (
            (fullName?.slice(0, 2) || '') + (initials?.pop() || '')
        ).toUpperCase();

        return acronym;
    };

    const cutString = (str: string, length: number): string => {
        return str.length > length
            ? str.slice(0, Math.min(length, str.lastIndexOf(' ')))
            : str;
    };

    const renderEvent = (eventInfo) => {
        const calendarEvent = eventInfo.event._def.extendedProps.data;

        const style: CSSProperties = {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'flex-start',
            color: '#000000',
            background: calendarEvent?.color ?? '#A5D4FA',
            width: '100%',
            padding: '2px 2px',
            borderRadius: '3px',
            boxShadow: '0 0 1px 1px #BABABA',
            height: '100%',
            overflow: 'hidden',
        };

        const initialsStyle: CSSProperties = {
            display: 'flex',
            letterSpacing: '1px',
            flexDirection: 'row',
            alignItems: 'center',
            background: 'white',
            color: 'black',
            fontWeight: 900,
            fontSize: '10px',
            padding: '2px 3px 0',
            borderRadius: '2px',
            boxShadow: 'inset 0 0 1px 1px #BABABA',
            verticalAlign: 'middle',
            marginTop: '-1px',
        };

        const eventNameStyle: CSSProperties = {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            color: '#000000',
            fontSize: '12px',
            marginLeft: '5px',
        };

        return calendarEvent ? (
            <div style={style}>
                <span style={initialsStyle}>
                    {initialsName(calendarEvent.creator?.name)}
                </span>
                <span style={eventNameStyle}>
                    {cutString(calendarEvent.title, 25)}
                </span>
            </div>
        ) : null;
    };

    if (loading) {
        return (
            <div className="center-spinner">
                <ProgressSpinner strokeWidth="5" animationDuration="1.5s" />
            </div>
        );
    } else {
        return (
            <>
                <Toast ref={toast} position={'top-right'} />
                <div className="calendar-page">
                    {/* <PermissionContainer
                        hasPermission={Permissions.CALENDAR_CREATE}
                        currentUser={appState.currentUser}
                    >
                        <div className="button-box">
                            <button
                                onClick={showModal}
                                className="new-task-button"
                            >
                                {t('New Task')}
                            </button>
                        </div>
                    </PermissionContainer> */}
                    {visible ? (
                        <CalendarFormModal
                            toast={toast}
                            calendarFormModalObject={calendarFormModalObject}
                            handleResetModal={handleResetCalendarFormModal}
                            updateCalendarFormModalObject={handleCalendarForm}
                            showModal={showModal}
                        />
                    ) : null}
                    {eventvisible ? (
                        <CalendarEventModal
                            toast={toast}
                            showEvent={showEvent}
                            event={singleEvent}
                            deleteEvent={deleteEvent}
                            editcalendarFormModalObject={
                                editcalendarFormModalObject
                            }
                            editCalendarForm={handleEditCalendarForm}
                            editEvent={editEvent}
                        />
                    ) : null}
                    <div className="calendar-page-main">
                        <Card>
                            {/* <div className="dropdown-container">
                                <Dropdown className="filter-item" />
                                <Dropdown className="filter-item" />
                                <Dropdown className="filter-item" />
                                <Dropdown
                                    className="filter-item"
                                    placeholder={t('Filter')}
                                />
                            </div> */}
                            <FullCalendar
                                plugins={[
                                    dayGridPlugin,
                                    timeGridPlugin,
                                    multiMonthPlugin,
                                    interactionPlugin,
                                ]}
                                locale={deLocale}
                                headerToolbar={{
                                    left: `prev,next today`,
                                    center: 'title',
                                    right: 'dayGridMonth,timeGridWeek,timeGridDay,multiMonthYear',
                                }}
                                initialView="timeGridWeek"
                                firstDay={1}
                                editable={true}
                                showNonCurrentDates={false}
                                selectable={true}
                                selectMirror={true}
                                dayMaxEvents={true}
                                weekends={true}
                                select={handleDateSelect}
                                initialDate={initialStartDate} //// set initial date with dasheboard
                                events={events?.map((el) => {
                                    return {
                                        data: el,
                                        title: el.title,
                                        start: new Date(el.startDateAndTime),
                                        end: new Date(el.endDateAndTime),
                                        defId: el.id,
                                    };
                                })}
                                eventClick={(e) => handleEventClick(e)}
                                eventDrop={(e) => handleEventMove(e)}
                                eventContent={renderEvent}
                            />
                        </Card>
                    </div>
                </div>
            </>
        );
    }
};

// fc-event fc-event-start fc-event-end fc-event-past fc-daygrid-event fc-daygrid-dot-event

export default Calendar;

import * as React from 'react';
import { Route, Redirect, RouteProps, Link } from 'react-router-dom';
import { useApplicationContext } from '../ApplicationContext';

interface PrivateRouteProps extends RouteProps {
    // tslint:disable-next-line:no-any
}

const PrivateRoute = (props: PrivateRouteProps) => {
    const { children, ...rest } = props;
    const { appState } = useApplicationContext();

    if (appState.authenticated) {
        return <Route {...rest}>{children}</Route>;
    } else {
        return (
            <Link
                to={{
                    pathname: '/login',
                    state: { from: props.location },
                }}
            />
        );
    }
};

export default PrivateRoute;

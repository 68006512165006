import * as React from 'react';
import { ApplicationProvider } from './ApplicationContext';
import Application from './Application';
import Loader from '../components/Loader';

function ApplicationWrapper(props) {
    return (
        <ApplicationProvider>
            <Loader />
            <Application>{props.children}</Application>
        </ApplicationProvider>
    );
}

export default ApplicationWrapper;

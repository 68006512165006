import { Config } from '../Config';
import { CrudService } from './CrudService';
import { AxiosResponse } from 'axios';
import { CreateQueryParams } from '@nestjsx/crud-request';
import { RealEstateObject, PropertyDto } from '../models/RealEstateObject';
import { axiosDefaults } from './AxiosDefaults';
import { useApplicationContext } from '../application/ApplicationContext';

export class PropertiesService extends CrudService<
    RealEstateObject,
    'RealEstateObject'
> {
    protected getUrlPath(): string {
        return '/properties';
    }

    public async getOne(
        id: string | number,
    ): Promise<AxiosResponse<RealEstateObject>> {
        const realEstateObjectResponse = await super.getOne(id);
        return new Promise(
            (resolve: (realEstateObject) => void, reject: () => void) => {
                resolve(realEstateObjectResponse);
            },
        );
    }

    public async getMany(
        params?: CreateQueryParams,
    ): Promise<AxiosResponse<RealEstateObject[]>> {
        const realEstateObjectsResponse = await super.getMany(params);
        return new Promise(
            (resolve: (realEstateObject) => void, reject: () => void) => {
                resolve(realEstateObjectsResponse);
            },
        );
    }

    public async createOne(
        realEstateObject: RealEstateObject,
    ): Promise<AxiosResponse<RealEstateObject>> {
        const newPropertyDtoObject: PropertyDto =
            this.createPropertyDtoFromRealEstateObject(realEstateObject);
        const newBody = JSON.stringify(newPropertyDtoObject);
        return this.axiosInstance.post(
            axiosDefaults.baseURL + this.getUrlPath(),
            newBody,
            {
                withCredentials: true,
                headers: { 'Content-Type': 'application/json' },
            },
        );
    }

    public async updateOne(
        id: string,
        realEstateObject: RealEstateObject,
    ): Promise<AxiosResponse<RealEstateObject>> {
        // TODO: create realEstateObject and propertyDto to update
        const body =
            this.createPropertyDtoFromRealEstateObject(realEstateObject);
        const newBody = JSON.stringify(body);
        return this.axiosInstance.put(
            axiosDefaults.baseURL + `/properties/${id}`,
            newBody,
            {
                withCredentials: true,
                headers: { 'Content-Type': 'application/json' },
            },
        );
    }

    public deleteOne(
        id: string | number,
    ): Promise<AxiosResponse<RealEstateObject>> {
        return this.axiosInstance.delete(
            axiosDefaults.baseURL + this.getUrlPath() + '/' + id,
        );
    }

    public duplicate(
        id: string | number,
        transferFirstDate: boolean = true,
        transferInquiries: boolean = true,
        deactivateOriginal: boolean = true,
        markNewAsInactive: boolean = true,
    ): Promise<AxiosResponse<RealEstateObject>> {
        return this.axiosInstance.post(
            axiosDefaults.baseURL + this.getUrlPath() + '/' + id + '/duplicate',
            {
                transferFirstDate,
                transferInquiries,
                deactivateOriginal,
                markNewAsInactive,
            },
        );
    }

    public createPropertyDtoFromRealEstateObject(
        newRealEstateObject: RealEstateObject,
    ): PropertyDto {
        const newPropertyDtoObject: PropertyDto = new PropertyDto();

        // propertySurfaceDto
        newPropertyDtoObject.surface = {
            ...newPropertyDtoObject.surface,
            ...newRealEstateObject.surface,
        };
        // propetryPriceDto
        newPropertyDtoObject.price = {
            ...newPropertyDtoObject.price,
            ...newRealEstateObject.price,
        };
        // propertyTextDto
        newPropertyDtoObject.text = {
            ...newPropertyDtoObject.text,
            ...newRealEstateObject.text,
        };
        // propertyOtherDto
        newPropertyDtoObject.other = {
            ...newPropertyDtoObject.other,
            ...newRealEstateObject.other,
        };
        // propertyFurnishingDto
        newPropertyDtoObject.furnishing = {
            ...newPropertyDtoObject.furnishing,
            ...newRealEstateObject.furnishing,
        };
        // propertyInternalDto
        newPropertyDtoObject.internal = {
            ...newPropertyDtoObject.internal,
            ...newRealEstateObject.internal,
        };
        // main propertyDto
        newPropertyDtoObject.acquisitionType =
            newRealEstateObject.acquisitionType;
        // newPropertyDtoObject.countryId = newRealEstateObject.countryId;
        newPropertyDtoObject.stateId = newRealEstateObject.stateId;
        newPropertyDtoObject.readyForExport =
            newRealEstateObject.readyForExport;

        if (newRealEstateObject.existingContact) {
            newPropertyDtoObject.seller = {
                id: newRealEstateObject.sellerId,
            };
        } else {
            newPropertyDtoObject.seller = {
                ...newRealEstateObject.seller,
                emails: newRealEstateObject.seller.emails.map((email) => ({
                    email,
                })),
                role: 'SELLER',
            };
        }

        newPropertyDtoObject.longitude = newRealEstateObject.longitude;
        newPropertyDtoObject.latitude = newRealEstateObject.latitude;
        newPropertyDtoObject.street = newRealEstateObject.street;
        newPropertyDtoObject.houseNumber = newRealEstateObject.houseNumber;
        newPropertyDtoObject.entrance = newRealEstateObject.entrance;
        newPropertyDtoObject.door = newRealEstateObject.door;
        newPropertyDtoObject.plz = newRealEstateObject.plz;
        newPropertyDtoObject.place = newRealEstateObject.place;
        newPropertyDtoObject.sideOfTheWorld =
            newRealEstateObject.sideOfTheWorld;
        newPropertyDtoObject.proximity = newRealEstateObject.proximity;
        newPropertyDtoObject.keyInfo = newRealEstateObject.keyInfo;
        newPropertyDtoObject.publishTheAddressTemplate =
            newRealEstateObject.publishTheAddressTemplate;
        newPropertyDtoObject.floor = newRealEstateObject.floor;
        newPropertyDtoObject.floorDescription =
            newRealEstateObject.floorDescription;
        newPropertyDtoObject.floorNumber = newRealEstateObject.floorNumber;
        newPropertyDtoObject.numberOfAtticFloors =
            newRealEstateObject.numberOfAtticFloors;
        newPropertyDtoObject.hasMezzanine = newRealEstateObject.hasMezzanine;
        newPropertyDtoObject.energyCertificateAvailable =
            newRealEstateObject.energyCertificateAvailable;
        newPropertyDtoObject.hwb = newRealEstateObject.hwb;
        newPropertyDtoObject.class = newRealEstateObject.class;
        newPropertyDtoObject.fgee = newRealEstateObject.fgee;
        newPropertyDtoObject.fgeeClass = newRealEstateObject.fgeeClass;
        newPropertyDtoObject.energyCertificateDateOfExpiry =
            newRealEstateObject.energyCertificateDateOfExpiry;
        newPropertyDtoObject.energyCertificateYear =
            newRealEstateObject.energyCertificateYear;
        newPropertyDtoObject.automaticDisclaimer =
            newRealEstateObject.automaticDisclaimer;
        newPropertyDtoObject.employeeId = newRealEstateObject.employeeId;
        newPropertyDtoObject.barrierFree = newRealEstateObject.barrierFree;
        newPropertyDtoObject.note = newRealEstateObject.note;
        newPropertyDtoObject.labelling = newRealEstateObject.labelling;
        newPropertyDtoObject.propertyTypeId =
            newRealEstateObject.propertyTypeId;
        newPropertyDtoObject.propertySubtypeId =
            newRealEstateObject.propertySubtypeId;
        newPropertyDtoObject.propertyType = newRealEstateObject.propertyType;
        newPropertyDtoObject.floorPlan = newRealEstateObject.floorPlan;
        newPropertyDtoObject.isBuildingPlot =
            newRealEstateObject.isBuildingPlot;
        newPropertyDtoObject.alsoVacationProperty =
            newRealEstateObject.alsoVacationProperty;
        newPropertyDtoObject.typeOfUseLiving =
            newRealEstateObject.typeOfUseLiving;
        newPropertyDtoObject.typeOfUseBusiness =
            newRealEstateObject.typeOfUseBusiness;
        newPropertyDtoObject.typeOfUseInvestment =
            newRealEstateObject.typeOfUseInvestment;
        newPropertyDtoObject.agreement = newRealEstateObject.agreement;
        newPropertyDtoObject.mediationOrderType =
            newRealEstateObject.mediationOrderType;
        newPropertyDtoObject.validFrom = newRealEstateObject.validFrom;
        newPropertyDtoObject.landRegisterSheet =
            newRealEstateObject.landRegisterSheet;
        newPropertyDtoObject.district = newRealEstateObject.district;
        newPropertyDtoObject.corridor = newRealEstateObject.corridor;
        newPropertyDtoObject.parcel = newRealEstateObject.parcel;
        newPropertyDtoObject.energyCertificateValidFrom =
            newRealEstateObject.energyCertificateValidFrom;
        newPropertyDtoObject.energyCertificateType =
            newRealEstateObject.energyCertificateType;
        newPropertyDtoObject.requirementCertificate =
            newRealEstateObject.requirementCertificate;
        newPropertyDtoObject.consumptionCertificate =
            newRealEstateObject.consumptionCertificate;
        newPropertyDtoObject.heatingType = newRealEstateObject.heatingType;
        newPropertyDtoObject.energySources = newRealEstateObject.energySources;
        newPropertyDtoObject.dateOfExpiry = newRealEstateObject.dateOfExpiry;
        newPropertyDtoObject.depositNumber = newRealEstateObject.depositNumber;
        newPropertyDtoObject.cadastreNumber =
            newRealEstateObject.cadastreNumber;
        newPropertyDtoObject.lotNumber = newRealEstateObject.lotNumber;
        newPropertyDtoObject.maximumRentalPeriodYears =
            newRealEstateObject.maximumRentalPeriodYears;
        newPropertyDtoObject.maximumRentalPeriodMonths =
            newRealEstateObject.maximumRentalPeriodMonths;
        newPropertyDtoObject.unlimitedRentalPeriod =
            newRealEstateObject.unlimitedRentalPeriod;
        newPropertyDtoObject.waiverOfTerminationYears =
            newRealEstateObject.waiverOfTerminationYears;
        newPropertyDtoObject.waiverOfTerminationMonths =
            newRealEstateObject.waiverOfTerminationMonths;
        newPropertyDtoObject.keyInfo = newRealEstateObject.keyInfo;
        newPropertyDtoObject.sourceLink = newRealEstateObject.sourceLink;

        newPropertyDtoObject.files = {
            images: [],
            plans: [],
            videos: [],
            documents: [],
            view360: [],
        };

        newPropertyDtoObject.exportState = newRealEstateObject.exportState;

        for (const fileCategory of Object.keys(newRealEstateObject.files)) {
            for (const file of newRealEstateObject.files[fileCategory]) {
                newPropertyDtoObject.files[fileCategory].push({
                    id: file.file.id,
                    order: file.file.order,
                    visibility:
                        file.file.visibility === 0 ? 4 : file.file.visibility,
                    isCover: file.isCover,
                });
            }
        }

        return newPropertyDtoObject;
    }
}

export const propertiesService = new PropertiesService();

import React, { useState, useRef, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Redirect } from 'react-router-dom';
import { Card } from 'primereact/card';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { ActionMenu } from '../../components/actionMenu/ActionMenu';
import CardHeader from '../../components/card/CardHeader';
import CustomObjectInput from '../../components/objects/customObjectInput/CustomObjectInput';
import {
    TypeOfContact,
    SalutationType,
    ContactRoleOptions,
} from '../../constants/ContactConsts';
import { contactsService } from '../../services/ContactsService';
import { userService } from '../../services/UsersService';
import { useTranslation } from 'react-i18next';
import './NewContact.scss';

export const NewContact = () => {
    const { t } = useTranslation();
    const [status, setStatus] = useState<number>(0);
    const [employeeList, setEmployeeList] = useState([]);

    const [newContact, setNewContact] = useState({
        id: null,
        userId: null,
        type: null,
        role: null,
        categoryId: 1,
        assigneeId: null,
        salutation: null,
        prefix: null,
        suffix: null,
        firstName: null,
        lastName: null,
        dateOfBirth: null,
        company: null,
        positionInCompany: null,
        mobilePhone: null,
        workPhone: null,
        privatePhone: null,
        fax: null,
        address: null,
        postalCode: null,
        city: null,
        county: null,
        country: null,
        emails: [],
    });

    const fetchUsers = async () => {
        const response = await userService.getMany();
        return response.data;
    };

    const employeeOptions = () => {
        let allEmployees = [];
        for (const employee of employeeList) {
            allEmployees.push({
                label: employee.name,
                value: employee.id,
            });
        }
        return allEmployees;
    };

    useEffect(() => {
        userService.refreshTokenCheck().then(() => {
            fetchUsers().then((result) => {
                setEmployeeList(result);
            });
        });
    }, []);

    const updateContact = (property, value) => {
        let newContactChanged = { ...newContact };
        newContactChanged[property] = value;
        setNewContact((newContact) => ({
            ...newContact,
            ...newContactChanged,
        }));
    };

    const toast = useRef(null);
    useEffect(() => {
        if (status === 201) {
            toast.current.show({
                severity: 'success',
                detail: t(`New contact has been saved successfully`),
                life: 3000,
            });
            setStatus(0);
        }
        if (status === 400) {
            toast.current.show({
                severity: 'error',
                detail: t(`Email you entered already exists`),
                life: 3000,
            });
            setStatus(0);
        }
        if (status === 500) {
            toast.current.show({
                severity: 'error',
                detail: t(`internal server error`),
                life: 3000,
            });
            setStatus(0);
        }
    }, [status]);

    const onSubmit = () => {
        let correctChanges: boolean = true;
        for (const email of newContact.emails) {
            if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,})+$/.test(email))
                correctChanges = false;
        }
        correctChanges
            ? contactsService
                  .createOne(newContact)
                  .then((response) => {
                      setStatus(response.status);
                  })
                  .catch((err) => setStatus(err.response.status))
            : toast.current.show({
                  severity: 'error',
                  detail: t(`Fill the emails field in the correct format`),
                  life: 3000,
              });
    };

    const onError = () => {
        toast.current.show({
            severity: 'error',
            detail: t(`Fill all necessary fields`),
            life: 3000,
        });
    };

    const {
        control,
        formState: { errors },
        handleSubmit,
        register,
    } = useForm({
        mode: 'onBlur',
    });

    return (
        <>
            <Toast ref={toast} position={'top-right'} />
            <ActionMenu
                menu={[
                    {
                        icon: 'pi pi-envelope',
                        label: t('Email anbieten'),
                        command: () => {},
                    },
                    {
                        icon: 'pi pi-book',
                        label: t('Suchprofil erstellen'),
                        command: () => {},
                    },
                    {
                        icon: 'pi pi-plus',
                        label: t('Aktivität erfassen'),
                        command: () => {},
                    },
                    {
                        icon: 'pi pi-print',
                        label: t('Drucken'),
                        command: () => {},
                    },
                ]}
                menuEnd={[
                    {
                        icon: 'pi pi-user',
                        label: t('Agent'),
                        command: () => {},
                    },
                ]}
            />

            <div className="new-contact-form-wrapper">
                <div>
                    <Card
                        className="basic-section"
                        header={
                            <CardHeader
                                title={t('Allgemein (opšti podaci)')}
                                className="card-header-background"
                            />
                        }
                    >
                        <div className="section-row">
                            {/* <CustomObjectInput
                                type="dropdown"
                                classes={[
                                    'generic-input'
                                ]}
                                id="category-input"
                                label={t('Kategorien / kategorija')+' *'}
                                name="categoryId"
                                stateField={newContact.categoryId}
                                setStateField={updateContact}
                                errors={errors}
                                control={control}
                                rules={{ required: t('This is required') }}
                                dropdownOptions={ContactCategories}
                            /> */}
                            <CustomObjectInput
                                type="dropdown"
                                classes={['generic-input']}
                                id="type-input"
                                label={t('Art (vrsta, tip)')}
                                name="type"
                                stateField={newContact.type}
                                setStateField={updateContact}
                                errors={errors}
                                control={control}
                                //rules={{ required: t('This is required') }}
                                dropdownOptions={TypeOfContact}
                            />
                            <CustomObjectInput
                                type="dropdown"
                                classes={['generic-input']}
                                id="assignee-id-input"
                                label={t('Supervisor') + ' *'}
                                name="assigneeId"
                                stateField={newContact.assigneeId}
                                setStateField={updateContact}
                                dropdownOptions={employeeOptions}
                                errors={errors}
                                control={control}
                                rules={{ required: t('This is required') }}
                            />
                        </div>
                        <div className="section-row">
                            <CustomObjectInput
                                type="dropdown"
                                classes={['generic-input']}
                                id="type-input"
                                label={t('Contact role') + ' *'}
                                name="role"
                                stateField={newContact.role}
                                setStateField={updateContact}
                                errors={errors}
                                control={control}
                                rules={{ required: t('This is required') }}
                                dropdownOptions={ContactRoleOptions}
                            />
                        </div>
                    </Card>
                    <Card
                        className="address-section"
                        header={
                            <CardHeader
                                title={t('Adresse')}
                                className="card-header-background"
                            />
                        }
                    >
                        <div className="section-row">
                            <CustomObjectInput
                                type="text"
                                classes={['generic-input']}
                                id="address-input"
                                label={t('Straße / ulica')}
                                name="address"
                                stateField={newContact.address}
                                setStateField={updateContact}
                                errors={errors}
                                control={control}
                                //rules={{ required: t('This is required') }}
                            />
                            <CustomObjectInput
                                type="text"
                                classes={['generic-input']}
                                id="postal-code-input"
                                label={t('poštanski broj')}
                                name="postalCode"
                                stateField={newContact.postalCode}
                                setStateField={updateContact}
                                errors={errors}
                                control={control}
                                //rules={{ required: t('This is required') }}
                            />
                            <CustomObjectInput
                                type="text"
                                classes={['generic-input']}
                                id="city-input"
                                label={t('Mesto')}
                                name="city"
                                stateField={newContact.city}
                                setStateField={updateContact}
                                errors={errors}
                                control={control}
                                //rules={{ required: t('This is required') }}
                            />
                            <CustomObjectInput
                                type="dropdown"
                                classes={['generic-input']}
                                id="country-input"
                                label={t('Land / država')}
                                name="country"
                                stateField={newContact.country}
                                setStateField={updateContact}
                                errors={errors}
                                control={control}
                                //rules={{ required: t('This is required') }}
                                dropdownOptions={[
                                    t('Austria'),
                                    t('Germany'),
                                    t('Switzerland'),
                                ]}
                            />
                        </div>
                    </Card>
                </div>
                <div>
                    <Card
                        className="personal-section"
                        header={
                            <CardHeader
                                title={t('Personendaten/lični podaci')}
                                className="card-header-background"
                            />
                        }
                    >
                        <div className="prefix-suffix-section-row">
                            <CustomObjectInput
                                type="dropdown"
                                classes={['generic-input']}
                                id="salutation-input"
                                label={t('Anrede')}
                                name="salutation"
                                stateField={newContact.salutation}
                                setStateField={updateContact}
                                errors={errors}
                                control={control}
                                //rules={{ required: t('This is required') }}
                                dropdownOptions={SalutationType}
                            />
                            <CustomObjectInput
                                type="text"
                                classes={['generic-input']}
                                id="prefix-input"
                                label={t('Titel / Präfix')}
                                name="prefix"
                                stateField={newContact.prefix}
                                setStateField={updateContact}
                                errors={errors}
                                control={control}
                                //rules={{ required: t('This is required') }}
                            />
                            <CustomObjectInput
                                type="text"
                                classes={['generic-input']}
                                id="suffix-input"
                                label={t('Suffix')}
                                name="suffix"
                                stateField={newContact.suffix}
                                setStateField={updateContact}
                                errors={errors}
                                control={control}
                                //rules={{ required: t('This is required') }}
                            />
                        </div>
                        <div className="section-row">
                            <CustomObjectInput
                                type="text"
                                classes={['generic-input']}
                                id="first-name-input"
                                label={t('Vorname / Ime') + ' *'}
                                name="firstName"
                                stateField={newContact.firstName}
                                setStateField={updateContact}
                                errors={errors}
                                control={control}
                                rules={{ required: t('This is required') }}
                            />
                            <CustomObjectInput
                                type="text"
                                classes={['generic-input']}
                                id="last-name-input"
                                label={t('Nachname / prezime') + ' *'}
                                name="lastName"
                                stateField={newContact.lastName}
                                setStateField={updateContact}
                                errors={errors}
                                control={control}
                                rules={{ required: t('This is required') }}
                            />
                        </div>
                        <div className="section-row">
                            {/* <CustomObjectInput
                                type="calendar"
                                classes={['generic-input']}
                                id="date-of-birth-input"
                                label={t('Geburtsdatum / datum rođenja')}
                                name="dateOfBirth"
                                dateType={'date'}
                                dateFormat={'dd/MM/yy'}
                                stateField={newContact.dateOfBirth}
                                setStateField={updateContact}
                                errors={errors}
                                control={control}
                                //rules={{ required: t('This is required') }}
                            /> */}
                            <CustomObjectInput
                                type="text"
                                classes={['generic-input']}
                                id="mobile-phone-input"
                                label={t('Telefonnummer')}
                                name="privatePhone"
                                stateField={newContact.privatePhone}
                                setStateField={updateContact}
                                errors={errors}
                                control={control}
                                //rules={{ required: t('This is required') }}
                            />
                        </div>
                        <div className="emails-section-row">
                            <CustomObjectInput
                                type="chips"
                                classes={['generic-input']}
                                id="email-input"
                                label={t('Emails') + ' *'}
                                name="emails"
                                stateField={newContact.emails}
                                setStateField={updateContact}
                                errors={errors}
                                control={control}
                                rules={{
                                    required: t('This is required'),
                                    pattern:
                                        /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,})+$/,
                                }}
                            />
                        </div>
                        <div className="section-row">
                            <CustomObjectInput
                                type="text"
                                classes={['generic-input']}
                                id="company-input"
                                label={t('Firma / kompanija')}
                                name="company"
                                stateField={newContact.company}
                                setStateField={updateContact}
                                errors={errors}
                                control={control}
                                //rules={{ required: t('This is required') }}
                            />
                            <CustomObjectInput
                                type="text"
                                classes={['generic-input']}
                                id="position-in-company-input"
                                label={t('Position / položaj')}
                                name="positionInCompany"
                                stateField={newContact.positionInCompany}
                                setStateField={updateContact}
                                errors={errors}
                                control={control}
                                //rules={{ required: t('This is required') }}
                            />
                        </div>
                    </Card>
                </div>
            </div>
            <Button
                className="button-save"
                label={t('SAVE')}
                onClick={handleSubmit(onSubmit, onError)}
            ></Button>
            {status === 201 && (
                <Redirect
                    to={{
                        pathname: '/contacts',
                        state: status,
                    }}
                />
            )}
        </>
    );
};

import React, { useEffect, useState } from 'react';
import { useLocation, withRouter } from 'react-router-dom';

const AppBreadcrumb = (props) => {
    const [nameObject, setNameObject] = useState<any>();
    const location = useLocation();
    const pathname = location.pathname;

    let name = pathname.replace('/', '');

    if (props.currentRouter) {
        name = props.currentRouter
            ? props.currentRouter.meta.breadcrumb[0].label
            : name;
    }

    useEffect(() => {
        if (props?.location?.state?.data) {
            let plzObject = props?.location?.state?.data[0]?.plz;
            let idObject = props?.location?.state?.data[0]?.rootId;
            let placeObject = props?.location?.state?.data[0]?.place;
            let streetObject = props?.location?.state?.data[0]?.street;
            let houseNumber = props?.location?.state?.data[0]?.houseNumber;
            let stringObject = `ID ${idObject}, PLZ ${plzObject} ${placeObject}, ${streetObject} ${houseNumber}`; ///street br
            if (plzObject !== undefined) {
                setNameObject(stringObject);
            }
        }
    }, []);

    return <span>{` ${nameObject !== undefined ? nameObject : name}`}</span>;
};

export default withRouter(AppBreadcrumb);

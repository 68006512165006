import { Config } from '../Config';
import { CrudService } from './CrudService';
import { AxiosResponse } from "axios";
import { CreateQueryParams } from '@nestjsx/crud-request';
import { FirstDate } from '../models/FirstDate';
import { User } from '../models/User';
import { axiosDefaults } from './AxiosDefaults';
import {Inquiry} from "../models/Inquiry";

export class FirstDateService extends CrudService<FirstDate, 'FirstDate'> {

    protected getUrlPath(): string {
        return '/first-dates';
    };

    public async getOne(id: string | number): Promise<AxiosResponse<FirstDate>> {
        const inquiryResponse = await super.getOne(id);
        return new Promise((resolve: (inquiry) => void, reject: () => void) => {resolve(inquiryResponse)})
    };

    public async getMany(params?: CreateQueryParams): Promise<AxiosResponse<FirstDate[]>> {
        const inquiriesResponse = await super.getMany(params);
        return new Promise((resolve: (inquiry) => void, reject: () => void) => {resolve(inquiriesResponse)})
    };

    public async createOne(FirstDate: FirstDate): Promise<AxiosResponse<FirstDate>> {
        let body/*:FirstDate*/ = {/*TODO*/};
        let newBody = await JSON.stringify(body);
        return this.axiosInstance.post(
            axiosDefaults.baseURL + this.getUrlPath(),
            newBody,
            {withCredentials: true, headers: {'Content-Type': 'application/json'}},
        );
    };

    public async updateOne(id: string, status: FirstDate): Promise<AxiosResponse<FirstDate>> {
        let body = {status};
        let newBody = await JSON.stringify(body);
        return this.axiosInstance.put(
            axiosDefaults.baseURL + this.getUrlPath() + '/' + id,
            newBody,
            {withCredentials: true, headers: {'Content-Type': 'application/json'}},
        );
    };

    public deleteOne(id: string | number): Promise<AxiosResponse<FirstDate>> {
        return this.axiosInstance.delete(axiosDefaults.baseURL + this.getUrlPath() + "/" + id);
    };

    public async getUnassignedFirstDates(): Promise<AxiosResponse<FirstDate[]>> {
        return this.axiosInstance.get(
            axiosDefaults.baseURL + this.getUrlPath() + '/unassigned',
            {withCredentials: true, headers: {'Content-Type': 'application/json'}},
        );
    };

    public async getAgentMatchingList(id: string): Promise<AxiosResponse<User[]>> {
        return this.axiosInstance.get(
            axiosDefaults.baseURL + this.getUrlPath() + '/' + id + '/agents',
            {withCredentials: true, headers: {'Content-Type': 'application/json'}},
        );
    };

    public async assignAgent(id: string, agentId: string): Promise<AxiosResponse<FirstDate>> {
        return this.axiosInstance.put(
            axiosDefaults.baseURL + this.getUrlPath() + '/' + id + '/assign/' + agentId,
            {},
            {withCredentials: true, headers: {'Content-Type': 'application/json'}},
        );
    };

    public async searchFirstDates(
        keywords: string,
    ): Promise<AxiosResponse<FirstDate[]>> {
        let data = {
            keywords: keywords,
        };
        return await this.axiosInstance.post(
            axiosDefaults.baseURL + this.getUrlPath() + '/search',
            data,
        );
    }
}

export const firstDateService = new FirstDateService();

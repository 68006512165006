import { Card } from 'primereact/card';
import { Checkbox } from 'primereact/checkbox';
import { InputNumber } from 'primereact/inputnumber';
import { Divider } from 'primereact/divider';
import React, { useState } from 'react';
import CardHeader from '../../card/CardHeader';
import './ObjectPricesForm.scss';
import { InputText } from 'primereact/inputtext';
import { priceCalculationOptions } from '../../../constants/ObjectConsts';
import { Dropdown } from 'primereact/dropdown';
import { InputTextarea } from 'primereact/inputtextarea';
import CustomObjectInput from '../customObjectInput/CustomObjectInput';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { RadioButton } from 'primereact/radiobutton';
import { useApplicationContext } from '../../../application/ApplicationContext';

const ObjectPricesForm = ({
    realEstateObject,
    updateRealEstateObject,
    control,
    errors,
    handleUpdatePrice,
}) => {
    const { t } = useTranslation();

    const { appState } = useApplicationContext();

    const categories = [
        { name: t('Purchase'), key: '1' },
        { name: t('Rent'), key: '2' },
        { name: t('Lease'), key: '3' },
    ];

    const active = categories.filter(
        (el) => el.name === realEstateObject.price.priceType,
    );

    const [selectedCategory, setSelectedCategory] = useState(
        active.length === 0 ? categories[0] : active[0],
    );

    return (
        <div className="object-prices-form-wrapper">
            <div className="object-price-form-column">
                <Card
                    className="object-price-section marketing-section"
                    header={
                        <CardHeader
                            title={t('Vermarktungsart / Tip marketinga*')}
                        />
                    }
                >
                    <div className="section-row">
                        {categories.map((category) => {
                            return (
                                <div
                                    key={category.key}
                                    className="field-radiobutton"
                                >
                                    <RadioButton
                                        inputId={category.key}
                                        name="price_priceType"
                                        value={category}
                                        onChange={(e) => {
                                            setSelectedCategory(e.value);
                                            handleUpdatePrice(e.value.name);
                                        }}
                                        checked={
                                            selectedCategory.key ===
                                            category.key
                                        }
                                        disabled={category.key === 'R'}
                                    />
                                    <label
                                        className="radio-button-label"
                                        htmlFor={category.key}
                                    >
                                        {category.name}
                                    </label>
                                </div>
                            );
                        })}
                    </div>
                </Card>
                {selectedCategory.key === '1' ? ( //purchase
                    <Card
                        className="object-price-section prices-section"
                        header={<CardHeader title={t('Preise / cene')} />}
                    >
                        <div className="section-row">
                            <div className="text-input-label generic-input centered-item">
                                <label htmlFor="purchase-price-net">
                                    {t('Kaufpreis')}
                                </label>
                            </div>
                            <div className="generic-input column-span-2">
                                <CustomObjectInput
                                    type="decimal"
                                    containerClassNames={[
                                        'p-inputgroup',
                                        'generic-input',
                                    ]}
                                    classes={['purchase-price-net']}
                                    id="purchase-price-net"
                                    label={t('Netto')}
                                    name="price_netPurchasePrice"
                                    stateField={
                                        realEstateObject.price.netPurchasePrice
                                    }
                                    setStateField={updateRealEstateObject}
                                    errors={errors}
                                    control={control}
                                    // rules={{ required: t('This is required') }}
                                    decimal={true}
                                />
                            </div>
                            <div className="generic-input column-span-2">
                                <CustomObjectInput
                                    type="number-with-addon"
                                    addonLabel="%"
                                    classes={['no-border-radius']}
                                    containerClassNames={['no-border-radius']}
                                    id="ust-input"
                                    label={t('USt.')}
                                    name="price_purchasePriceTax"
                                    stateField={
                                        realEstateObject.price.purchasePriceTax
                                    }
                                    setStateField={updateRealEstateObject}
                                    errors={errors}
                                    control={control}
                                    //rules={{ required: t('This is required') }}
                                    decimal={true}
                                />
                            </div>
                            <div className="generic-input column-span-2">
                                <CustomObjectInput
                                    type="decimal"
                                    containerClassNames={[
                                        'p-inputgroup',
                                        'generic-input',
                                    ]}
                                    classes={['purchase-price-gross']}
                                    id="purchase-price-gross"
                                    label={t('Brutto')}
                                    name="price_grossPurchasePrice"
                                    stateField={
                                        realEstateObject.price
                                            .grossPurchasePrice
                                    }
                                    setStateField={updateRealEstateObject}
                                    errors={errors}
                                    control={control}
                                    disabled={true}
                                    //rules={{ required: t('This is required') }}
                                    decimal={true}
                                />
                            </div>
                            <CustomObjectInput
                                type="checkbox"
                                containerClassNames={[
                                    'generic-input',
                                    'column-span-2',
                                ]}
                                classes={['checkbox-middle']}
                                id="publish-prices"
                                label={t('Publish prices')}
                                name="price_publishPrices"
                                stateField={
                                    realEstateObject.price.publishPrices
                                }
                                setStateField={updateRealEstateObject}
                                errors={errors}
                                control={control}
                                //rules={{ required: t('This is required') }}
                            />
                        </div>
                    </Card>
                ) : (
                    //rent or pacht
                    <Card
                        className="object-price-section prices-rent-section"
                        header={<CardHeader title={t('Preise / cene')} />}
                    >
                        <div className="section-row">
                            <CustomObjectInput
                                type="checkbox"
                                containerClassNames={[
                                    'generic-input',
                                    'column-span-2',
                                ]}
                                classes={['checkbox-middle']}
                                id="publish-prices"
                                label={t('Publish prices')}
                                name="price_publishPrices"
                                stateField={
                                    realEstateObject.price.publishPrices
                                }
                                setStateField={updateRealEstateObject}
                                errors={errors}
                                control={control}
                                //rules={{ required: t('This is required') }}
                            />
                        </div>
                        <div className="section-row">
                            <div className="centered-item">
                                <label htmlFor="rent-costs-input">
                                    {t('')}
                                </label>
                            </div>
                            <div className="centered-item">
                                <label htmlFor="rent-costs-input">
                                    {t('Monthly')}
                                </label>
                            </div>
                            <div className="centered-item">
                                <label htmlFor="rent-costs-input">
                                    {t('USt.')}
                                </label>
                            </div>
                            <div className="last-column centered-item last-item">
                                <label htmlFor="rent-costs-input">
                                    {t('Bruto')}
                                </label>
                            </div>
                        </div>
                        <hr className="horizontal-divider" />
                        <div className="section-row">
                            <div className="text-input-label-custom generic-input centered-item">
                                <label htmlFor="rent-costs-input">
                                    {t('Rent costs')}
                                </label>
                            </div>
                            <CustomObjectInput
                                type="decimal"
                                containerClassNames={[
                                    'generic-input',
                                    'p-inputgroup',
                                ]}
                                classes={[
                                    'generic-input',
                                    'monthly-prices-input',
                                ]}
                                id="rent-costs-input"
                                name="price_netRentCost"
                                stateField={realEstateObject.price.netRentCost}
                                setStateField={updateRealEstateObject}
                                errors={errors}
                                control={control}
                                //rules={{ required: t('This is required') }}
                            />
                            <CustomObjectInput
                                type="number-with-addon"
                                addonLabel="%"
                                containerClassNames={[
                                    'p-inputgroup',
                                    'generic-input',
                                ]}
                                classes={['no-border-radius', 'generic-input']}
                                id="rent-costs-vat-input"
                                name="price_rentCostTax"
                                stateField={realEstateObject.price.rentCostTax}
                                setStateField={updateRealEstateObject}
                                errors={errors}
                                control={control}
                                //rules={{ required: t('This is required') }}
                                decimal={true}
                            />
                            <div className="last-column centered-item last-item">
                                <h6 className="no-margin">
                                    {realEstateObject.price.netRentCost === null
                                        ? 0
                                        : realEstateObject.price
                                              .grossRentCost}{' '}
                                    EUR
                                </h6>
                            </div>
                        </div>
                        <hr className="horizontal-divider" />
                        <div className="section-row">
                            <div className="text-input-label-custom generic-input centered-item">
                                <label htmlFor="operational-costs-input">
                                    {t('Operativni troškovi')}
                                </label>
                            </div>
                            <CustomObjectInput
                                type="decimal"
                                containerClassNames={[
                                    'generic-input',
                                    'p-inputgroup',
                                ]}
                                classes={[
                                    'generic-input',
                                    'monthly-prices-input',
                                ]}
                                id="operational-costs-input"
                                name="price_netOperatingCost"
                                stateField={
                                    realEstateObject.price.netOperatingCost
                                }
                                setStateField={updateRealEstateObject}
                                errors={errors}
                                control={control}
                                //rules={{ required: t('This is required') }}
                            />
                            <CustomObjectInput
                                type="number-with-addon"
                                addonLabel="%"
                                containerClassNames={[
                                    'p-inputgroup',
                                    'generic-input',
                                ]}
                                classes={['no-border-radius', 'generic-input']}
                                id="operational-costs-vat-input"
                                name="price_operatingCostTax"
                                stateField={
                                    realEstateObject.price.operatingCostTax
                                }
                                setStateField={updateRealEstateObject}
                                errors={errors}
                                control={control}
                                //rules={{ required: t('This is required') }}
                                decimal={true}
                            />
                            <div className="last-column centered-item last-item">
                                <h6 className="no-margin">
                                    {realEstateObject.price.netOperatingCost ===
                                        null ||
                                    realEstateObject.operationalCostOptional ===
                                        true
                                        ? 0
                                        : realEstateObject.price
                                              .grossOperatingCost}{' '}
                                    EUR
                                </h6>
                            </div>
                        </div>
                        <div className="section-row">
                            <div className="text-input-label-monthly-prices generic-input centered-item">
                                <label htmlFor="heating-costs-input">
                                    {t('Troškovi grejanja')}
                                </label>
                            </div>
                            <CustomObjectInput
                                type="decimal"
                                containerClassNames={[
                                    'generic-input',
                                    'p-inputgroup',
                                ]}
                                classes={[
                                    'generic-input',
                                    'monthly-prices-input',
                                ]}
                                id="heating-costs-input"
                                name="price_netHeatingCost"
                                stateField={
                                    realEstateObject.price.netHeatingCost
                                }
                                setStateField={updateRealEstateObject}
                                errors={errors}
                                control={control}
                                //rules={{ required: t('This is required') }}
                            />
                            <CustomObjectInput
                                type="number-with-addon"
                                addonLabel="%"
                                containerClassNames={[
                                    'p-inputgroup',
                                    'generic-input',
                                ]}
                                classes={['no-border-radius', 'generic-input']}
                                id="heating-vat-input"
                                name="price_heatingCostTax"
                                stateField={
                                    realEstateObject.price.heatingCostTax
                                }
                                setStateField={updateRealEstateObject}
                                errors={errors}
                                control={control}
                                //rules={{ required: t('This is required') }}
                                decimal={true}
                            />
                            <div className="last-column centered-item last-item">
                                <h6 className="no-margin">
                                    {realEstateObject.price.netHeatingCost ===
                                    null
                                        ? 0
                                        : realEstateObject.price
                                              .grossHeatingCost}{' '}
                                    EUR
                                </h6>
                            </div>
                        </div>
                        <div className="section-row">
                            <div className="text-input-label-monthly-prices generic-input centered-item">
                                <label htmlFor="other-costs-input">
                                    {t('Ostalo')}
                                </label>
                            </div>
                            <CustomObjectInput
                                type="decimal"
                                containerClassNames={[
                                    'generic-input',
                                    'p-inputgroup',
                                ]}
                                classes={[
                                    'generic-input',
                                    'monthly-prices-input',
                                ]}
                                id="other-costs-input"
                                name="price_netOtherCost"
                                stateField={realEstateObject.price.netOtherCost}
                                setStateField={updateRealEstateObject}
                                errors={errors}
                                control={control}
                                //rules={{ required: t('This is required') }}
                            />
                            <CustomObjectInput
                                type="number-with-addon"
                                addonLabel="%"
                                containerClassNames={[
                                    'p-inputgroup',
                                    'generic-input',
                                ]}
                                classes={['no-border-radius', 'generic-input']}
                                id="other-vat-input"
                                name="price_otherCostTax"
                                stateField={realEstateObject.price.otherCostTax}
                                setStateField={updateRealEstateObject}
                                errors={errors}
                                control={control}
                                //rules={{ required: t('This is required') }}
                                decimal={true}
                            />
                            <div className="generic-input last-column centered-item last-item">
                                <h6 className="no-margin">
                                    {realEstateObject.price.netOtherCost ===
                                    null
                                        ? 0
                                        : realEstateObject.price.grossOtherCost}
                                    EUR
                                </h6>
                            </div>
                        </div>
                        <hr className="horizontal-divider" />
                        <div className="section-row">
                            <div className="text-input-label-monthly-prices generic-input centered-item">
                                <label htmlFor="other-costs-input">
                                    {t('Total for rent')}
                                </label>
                            </div>
                            <div className="generic-input centered-item last-item">
                                <h6 className="no-margin">
                                    {Number(
                                        realEstateObject.price.netRentCost ?? 0,
                                    ) +
                                        Number(
                                            realEstateObject.price
                                                .netOperatingCost ?? 0,
                                        ) +
                                        Number(
                                            realEstateObject.price
                                                .netHeatingCost ?? 0,
                                        ) +
                                        Number(
                                            realEstateObject.price
                                                .netOtherCost ?? 0,
                                        )}
                                    EUR
                                </h6>
                            </div>
                            <div className="generic-input centered-item last-item">
                                <h6 className="no-margin">
                                    {(Number(
                                        realEstateObject.price.netRentCost ?? 0,
                                    ) *
                                        realEstateObject.price.rentCostTax) /
                                        100 +
                                        (Number(
                                            realEstateObject.price
                                                .netOperatingCost ?? 0,
                                        ) *
                                            realEstateObject.price
                                                .operatingCostTax) /
                                            100 +
                                        (Number(
                                            realEstateObject.price
                                                .netHeatingCost ?? 0,
                                        ) *
                                            realEstateObject.price
                                                .heatingCostTax) /
                                            100 +
                                        (Number(
                                            realEstateObject.price
                                                .netOtherCost ?? 0,
                                        ) *
                                            realEstateObject.price
                                                .otherCostTax) /
                                            100}
                                    EUR
                                </h6>
                            </div>
                            <div className="generic-input last-column centered-item last-item">
                                <h6 className="no-margin">
                                    {Number(
                                        realEstateObject.price.grossRentCost ??
                                            0,
                                    ) +
                                        Number(
                                            realEstateObject.price
                                                .grossOperatingCost ?? 0,
                                        ) +
                                        Number(
                                            realEstateObject.price
                                                .grossHeatingCost ?? 0,
                                        ) +
                                        Number(
                                            realEstateObject.price
                                                .grossOtherCost ?? 0,
                                        )}
                                    EUR
                                </h6>
                            </div>
                        </div>
                        <hr className="horizontal-divider" />
                        <div className="section-row">
                            <div className="text-input-label-monthly-prices generic-input centered-item">
                                <label htmlFor="other-costs-input">
                                    {t('Without heating cost')}
                                </label>
                            </div>
                            <div className="generic-input centered-item last-item">
                                <h6 className="no-margin">
                                    {Number(
                                        realEstateObject.price.netRentCost ?? 0,
                                    ) +
                                        Number(
                                            realEstateObject.price
                                                .netOperatingCost ?? 0,
                                        ) +
                                        Number(
                                            realEstateObject.price
                                                .netOtherCost ?? 0,
                                        )}
                                    EUR
                                </h6>
                            </div>
                            <div className="generic-input centered-item last-item">
                                <h6 className="no-margin">
                                    {(Number(
                                        realEstateObject.price.netRentCost ?? 0,
                                    ) *
                                        realEstateObject.price.rentCostTax) /
                                        100 +
                                        (Number(
                                            realEstateObject.price
                                                .netOperatingCost ?? 0,
                                        ) *
                                            realEstateObject.price
                                                .operatingCostTax) /
                                            100 +
                                        (Number(
                                            realEstateObject.price
                                                .netOtherCost ?? 0,
                                        ) *
                                            realEstateObject.price
                                                .otherCostTax) /
                                            100}
                                    EUR
                                </h6>
                            </div>
                            <div className="generic-input last-column centered-item last-item">
                                <h6 className="no-margin">
                                    {Number(
                                        realEstateObject.price.grossRentCost ??
                                            0,
                                    ) +
                                        Number(
                                            realEstateObject.price
                                                .grossOperatingCost ?? 0,
                                        ) +
                                        Number(
                                            realEstateObject.price
                                                .grossOtherCost ?? 0,
                                        )}
                                    EUR
                                </h6>
                            </div>
                        </div>
                        <div>
                            {t(
                                '*Optionale Kosten können nicht an Plattformen übertragen werden',
                            )}
                        </div>
                    </Card>
                )}
                {selectedCategory.key === '1' ? ( //purchase
                    <Card
                        className="object-price-section monthly-fees-section"
                        header={<CardHeader title={t('Mesecne naknade')} />}
                    >
                        <div className="section-row">
                            <div className="text-input-label-monthly-prices generic-input centered-item">
                                <label htmlFor="parking-input">
                                    {t('Parking')}
                                </label>
                            </div>
                            <CustomObjectInput
                                type="decimal"
                                containerClassNames={[
                                    'generic-input',
                                    'p-inputgroup',
                                ]}
                                classes={[
                                    'generic-input',
                                    'monthly-prices-input',
                                ]}
                                id="parking-input"
                                name="price_netParkingCost"
                                stateField={
                                    realEstateObject.price.netParkingCost
                                }
                                setStateField={updateRealEstateObject}
                                errors={errors}
                                control={control}
                                //rules={{ required: t('This is required') }}
                                disabled={realEstateObject.parkingOptional}
                            />
                            <CustomObjectInput
                                type="number-with-addon"
                                addonLabel="%"
                                containerClassNames={[
                                    'p-inputgroup',
                                    'generic-input',
                                ]}
                                classes={['no-border-radius', 'generic-input']}
                                id="parking-vat-input"
                                name="price_parkingCostTax"
                                stateField={
                                    realEstateObject.price.parkingCostTax
                                }
                                setStateField={updateRealEstateObject}
                                errors={errors}
                                control={control}
                                //rules={{ required: t('This is required') }}
                                decimal={true}
                                disabled={realEstateObject.parkingOptional}
                            />
                            <CustomObjectInput
                                type="checkbox"
                                containerClassNames={[
                                    'checkbox-container',
                                    'generic-input',
                                    'no-margin',
                                    'column-span-2',
                                ]}
                                classes={[
                                    'p-inputgroup',
                                    'checkbox-middle',
                                    'generic-input',
                                    'no-margin',
                                ]}
                                id="parking-optional"
                                label={t('Optional')}
                                name="parkingOptional"
                                stateField={realEstateObject.parkingOptional}
                                setStateField={updateRealEstateObject}
                                errors={errors}
                                control={control}
                                //rules={{ required: t('This is required') }}
                            />
                            <div className="last-column generic-input centered-item last-item">
                                <h6 className="no-margin bruto-value">
                                    {realEstateObject.price.netParkingCost ===
                                        null ||
                                    realEstateObject.parkingOptional === true
                                        ? 0
                                        : realEstateObject.price
                                              .grossParkingCost}{' '}
                                    EUR
                                </h6>
                            </div>
                        </div>
                        <div className="section-row">
                            <div className="text-input-label-monthly-prices generic-input centered-item">
                                <label htmlFor="garage-input">
                                    {t('Garaža')}
                                </label>
                            </div>
                            <CustomObjectInput
                                type="decimal"
                                containerClassNames={[
                                    'generic-input',
                                    'p-inputgroup',
                                ]}
                                classes={[
                                    'generic-input',
                                    'monthly-prices-input',
                                ]}
                                id="garage-input"
                                name="price_netGarageCost"
                                stateField={
                                    realEstateObject.price.netGarageCost
                                }
                                setStateField={updateRealEstateObject}
                                errors={errors}
                                control={control}
                                //rules={{ required: t('This is required') }}
                                disabled={realEstateObject.garageOptional}
                            />
                            <CustomObjectInput
                                type="number-with-addon"
                                addonLabel="%"
                                containerClassNames={[
                                    'p-inputgroup',
                                    'generic-input',
                                ]}
                                classes={['no-border-radius', 'generic-input']}
                                id="garage-vat-input"
                                name="price_garageCostTax"
                                stateField={
                                    realEstateObject.price.garageCostTax
                                }
                                setStateField={updateRealEstateObject}
                                errors={errors}
                                control={control}
                                //rules={{ required: t('This is required') }}
                                decimal={true}
                                disabled={realEstateObject.garageOptional}
                            />
                            <CustomObjectInput
                                type="checkbox"
                                containerClassNames={[
                                    'checkbox-container',
                                    'generic-input',
                                    'no-margin',
                                    'column-span-2',
                                ]}
                                classes={[
                                    'p-inputgroup',
                                    'checkbox-middle',
                                    'generic-input',
                                    'no-margin',
                                ]}
                                id="garage-optional"
                                label={t('Optional')}
                                name="garageOptional"
                                stateField={realEstateObject.garageOptional}
                                setStateField={updateRealEstateObject}
                                errors={errors}
                                control={control}
                                //rules={{ required: t('This is required') }}
                            />
                            <div className="last-column generic-input centered-item last-item">
                                <h6 className="no-margin">
                                    {realEstateObject.price.netGarageCost ===
                                        null ||
                                    realEstateObject.garageOptional === true
                                        ? 0
                                        : realEstateObject.price
                                              .grossGarageCost}{' '}
                                    EUR
                                </h6>
                            </div>
                        </div>
                        <div className="section-row">
                            <div className="text-input-label-custom generic-input centered-item">
                                <label htmlFor="operational-costs-input">
                                    {appState.metadata?.country === 'at'
                                        ? t('Operativni troškovi')
                                        : appState.metadata?.country === 'de'
                                        ? t('Hausgeld')
                                        : null}
                                </label>
                            </div>
                            <CustomObjectInput
                                type="decimal"
                                containerClassNames={[
                                    'generic-input',
                                    'p-inputgroup',
                                ]}
                                classes={[
                                    'generic-input',
                                    'monthly-prices-input',
                                ]}
                                id="operational-costs-input"
                                name="price_netOperatingCost"
                                stateField={
                                    realEstateObject.price.netOperatingCost
                                }
                                setStateField={updateRealEstateObject}
                                errors={errors}
                                control={control}
                                //rules={{ required: t('This is required') }}
                                disabled={
                                    realEstateObject.operationalCostOptional
                                }
                            />
                            <CustomObjectInput
                                type="number-with-addon"
                                addonLabel="%"
                                containerClassNames={[
                                    'p-inputgroup',
                                    'generic-input',
                                ]}
                                classes={['no-border-radius', 'generic-input']}
                                id="operational-costs-vat-input"
                                name="price_operatingCostTax"
                                stateField={
                                    realEstateObject.price.operatingCostTax
                                }
                                setStateField={updateRealEstateObject}
                                errors={errors}
                                control={control}
                                //rules={{ required: t('This is required') }}
                                decimal={true}
                                disabled={
                                    realEstateObject.operationalCostOptional
                                }
                            />
                            <CustomObjectInput
                                type="checkbox"
                                containerClassNames={[
                                    'checkbox-container',
                                    'generic-input',
                                    'no-margin',
                                    'column-span-2',
                                ]}
                                classes={[
                                    'p-inputgroup',
                                    'checkbox-middle',
                                    'generic-input',
                                    'no-margin',
                                ]}
                                id="operational-costs-optional"
                                label={t('Optional')}
                                name="operationalCostOptional"
                                stateField={
                                    realEstateObject.operationalCostOptional
                                }
                                setStateField={updateRealEstateObject}
                                errors={errors}
                                control={control}
                                //rules={{ required: t('This is required') }}
                            />
                            <div className="last-column centered-item last-item">
                                <h6 className="no-margin">
                                    {realEstateObject.price.netOperatingCost ===
                                        null ||
                                    realEstateObject.operationalCostOptional ===
                                        true
                                        ? 0
                                        : realEstateObject.price
                                              .grossOperatingCost}{' '}
                                    EUR
                                </h6>
                            </div>
                        </div>
                        {appState.metadata?.country === 'de' && (
                            <div className="section-row">
                                <div className="text-input-label-custom generic-input centered-item">
                                    <label htmlFor="operational-costs-input">
                                        {t('Nebenkosten')}
                                    </label>
                                </div>
                                <CustomObjectInput
                                    type="decimal"
                                    containerClassNames={[
                                        'generic-input',
                                        'p-inputgroup',
                                    ]}
                                    classes={[
                                        'generic-input',
                                        'monthly-prices-input',
                                    ]}
                                    id="extra-costs-input"
                                    name="price_netExtraCost"
                                    stateField={
                                        realEstateObject.price.netExtraCost
                                    }
                                    setStateField={updateRealEstateObject}
                                    errors={errors}
                                    control={control}
                                    //rules={{ required: t('This is required') }}
                                />
                                <CustomObjectInput
                                    type="number-with-addon"
                                    addonLabel="%"
                                    containerClassNames={[
                                        'p-inputgroup',
                                        'generic-input',
                                    ]}
                                    classes={[
                                        'no-border-radius',
                                        'generic-input',
                                    ]}
                                    id="extra-costs-vat-input"
                                    name="price_extraCostTax"
                                    stateField={
                                        realEstateObject.price.extraCostTax
                                    }
                                    setStateField={updateRealEstateObject}
                                    errors={errors}
                                    control={control}
                                    //rules={{ required: t('This is required') }}
                                    decimal={true}
                                />
                                <div className="last-column centered-item last-item">
                                    <h6 className="no-margin">
                                        {realEstateObject.price.netExtraCost ===
                                            null ||
                                        realEstateObject.extraCostTax === true
                                            ? 0
                                            : realEstateObject.price
                                                  .grossExtraCost}{' '}
                                        EUR
                                    </h6>
                                </div>
                            </div>
                        )}
                        <div className="section-row">
                            <div className="text-input-label-monthly-prices generic-input centered-item">
                                <label htmlFor="heating-costs-input">
                                    {t('Troškovi grejanja')}
                                </label>
                            </div>
                            <CustomObjectInput
                                type="decimal"
                                containerClassNames={[
                                    'generic-input',
                                    'p-inputgroup',
                                ]}
                                classes={[
                                    'generic-input',
                                    'monthly-prices-input',
                                ]}
                                id="heating-costs-input"
                                name="price_netHeatingCost"
                                stateField={
                                    realEstateObject.price.netHeatingCost
                                }
                                setStateField={updateRealEstateObject}
                                errors={errors}
                                control={control}
                                //rules={{ required: t('This is required') }}
                            />
                            <CustomObjectInput
                                type="number-with-addon"
                                addonLabel="%"
                                containerClassNames={[
                                    'p-inputgroup',
                                    'generic-input',
                                ]}
                                classes={['no-border-radius', 'generic-input']}
                                id="heating-vat-input"
                                name="price_heatingCostTax"
                                stateField={
                                    realEstateObject.price.heatingCostTax
                                }
                                setStateField={updateRealEstateObject}
                                errors={errors}
                                control={control}
                                //rules={{ required: t('This is required') }}
                                decimal={true}
                            />
                            <div className="last-column centered-item last-item">
                                <h6 className="no-margin">
                                    {realEstateObject.price.netHeatingCost ===
                                    null
                                        ? 0
                                        : realEstateObject.price
                                              .grossHeatingCost}{' '}
                                    EUR
                                </h6>
                            </div>
                        </div>
                        <div className="section-row">
                            <div className="text-input-label-monthly-prices generic-input centered-item">
                                <label htmlFor="hot-water-costs-input">
                                    {t('Vruća voda')}
                                </label>
                            </div>
                            <CustomObjectInput
                                type="decimal"
                                containerClassNames={[
                                    'generic-input',
                                    'p-inputgroup',
                                ]}
                                classes={[
                                    'generic-input',
                                    'monthly-prices-input',
                                ]}
                                id="hot-water-costs-input"
                                name="price_netWarmWaterCost"
                                stateField={
                                    realEstateObject.price.netWarmWaterCost
                                }
                                setStateField={updateRealEstateObject}
                                errors={errors}
                                control={control}
                                //rules={{ required: t('This is required') }}
                            />
                            <CustomObjectInput
                                type="number-with-addon"
                                addonLabel="%"
                                containerClassNames={[
                                    'p-inputgroup',
                                    'generic-input',
                                ]}
                                classes={['no-border-radius', 'generic-input']}
                                id="hot-water-vat-input"
                                name="price_warmWaterCostTax"
                                stateField={
                                    realEstateObject.price.warmWaterCostTax
                                }
                                setStateField={updateRealEstateObject}
                                errors={errors}
                                control={control}
                                //rules={{ required: t('This is required') }}
                                decimal={true}
                            />
                            <div className="last-column centered-item last-item">
                                <h6 className="no-margin">
                                    {realEstateObject.price.netWarmWaterCost ===
                                    null
                                        ? 0
                                        : realEstateObject.price
                                              .grossWarmWaterCost}{' '}
                                    EUR
                                </h6>
                            </div>
                        </div>
                        <div className="section-row">
                            <div className="text-input-label-monthly-prices generic-input centered-item">
                                <label htmlFor="cooling-costs-input">
                                    {t('Troškovi hlađenja')}
                                </label>
                            </div>
                            <CustomObjectInput
                                type="decimal"
                                containerClassNames={[
                                    'generic-input',
                                    'p-inputgroup',
                                ]}
                                classes={[
                                    'generic-input',
                                    'monthly-prices-input',
                                ]}
                                id="cooling-costs-input"
                                name="price_netCoolingCost"
                                stateField={
                                    realEstateObject.price.netCoolingCost
                                }
                                setStateField={updateRealEstateObject}
                                errors={errors}
                                control={control}
                                //rules={{ required: t('This is required') }}
                            />
                            <CustomObjectInput
                                type="number-with-addon"
                                addonLabel="%"
                                containerClassNames={[
                                    'p-inputgroup',
                                    'generic-input',
                                ]}
                                classes={['no-border-radius', 'generic-input']}
                                id="cooling-vat-input"
                                name="price_coolingCostTax"
                                stateField={
                                    realEstateObject.price.coolingCostTax
                                }
                                setStateField={updateRealEstateObject}
                                errors={errors}
                                control={control}
                                //rules={{ required: t('This is required') }}
                                decimal={true}
                            />
                            <div className="last-column centered-item last-item">
                                <h6 className="no-margin">
                                    {realEstateObject.price.netCoolingCost ===
                                    null
                                        ? 0
                                        : realEstateObject.price
                                              .grossCoolingCost}{' '}
                                    EUR
                                </h6>
                            </div>
                        </div>
                        <div className="section-row">
                            <div className="text-input-label-monthly-prices generic-input centered-item">
                                <label htmlFor="repair-costs-input">
                                    {t('Fond popravki')}
                                </label>
                            </div>
                            <CustomObjectInput
                                type="decimal"
                                containerClassNames={[
                                    'generic-input',
                                    'p-inputgroup',
                                ]}
                                classes={[
                                    'generic-input',
                                    'monthly-prices-input',
                                ]}
                                id="repair-costs-input"
                                name="price_netMaintenanceCost"
                                stateField={
                                    realEstateObject.price.netMaintenanceCost
                                }
                                setStateField={updateRealEstateObject}
                                errors={errors}
                                control={control}
                                //rules={{ required: t('This is required') }}
                            />
                            <CustomObjectInput
                                type="number-with-addon"
                                addonLabel="%"
                                containerClassNames={[
                                    'p-inputgroup',
                                    'generic-input',
                                ]}
                                classes={['no-border-radius', 'generic-input']}
                                id="repair-vat-input"
                                name="price_maintenanceCostTax"
                                stateField={
                                    realEstateObject.price.maintenanceCostTax
                                }
                                setStateField={updateRealEstateObject}
                                errors={errors}
                                control={control}
                                //rules={{ required: t('This is required') }}
                                decimal={true}
                            />
                            <div className="last-column centered-item last-item">
                                <h6 className="no-margin">
                                    {realEstateObject.price
                                        .netMaintenanceCost === null
                                        ? 0
                                        : realEstateObject.price
                                              .grossMaintenanceCost}{' '}
                                    EUR
                                </h6>
                            </div>
                        </div>
                        <div className="section-row">
                            <div className="text-input-label-monthly-prices generic-input centered-item">
                                <label htmlFor="other-costs-input">
                                    {t('Ostalo')}
                                </label>
                            </div>
                            <CustomObjectInput
                                type="decimal"
                                containerClassNames={[
                                    'generic-input',
                                    'p-inputgroup',
                                ]}
                                classes={[
                                    'generic-input',
                                    'monthly-prices-input',
                                ]}
                                id="other-costs-input"
                                name="price_netOtherCost"
                                stateField={realEstateObject.price.netOtherCost}
                                setStateField={updateRealEstateObject}
                                errors={errors}
                                control={control}
                                //rules={{ required: t('This is required') }}
                            />
                            <CustomObjectInput
                                type="number-with-addon"
                                addonLabel="%"
                                containerClassNames={[
                                    'p-inputgroup',
                                    'generic-input',
                                ]}
                                classes={['no-border-radius', 'generic-input']}
                                id="other-vat-input"
                                name="price_otherCostTax"
                                stateField={realEstateObject.price.otherCostTax}
                                setStateField={updateRealEstateObject}
                                errors={errors}
                                control={control}
                                //rules={{ required: t('This is required') }}
                                decimal={true}
                            />
                            <div className="generic-input last-column centered-item last-item">
                                <h6 className="no-margin">
                                    {realEstateObject.price.netOtherCost ===
                                    null
                                        ? 0
                                        : realEstateObject.price
                                              .grossOtherCost}{' '}
                                    EUR
                                </h6>
                            </div>
                        </div>
                        <div className="section-row">
                            <div className="text-input-label-monthly-prices generic-input centered-item">
                                <label htmlFor="elevator-costs-input">
                                    {t('Održavanje lifta')}
                                </label>
                            </div>
                            <CustomObjectInput
                                type="decimal"
                                containerClassNames={[
                                    'generic-input',
                                    'p-inputgroup',
                                ]}
                                classes={[
                                    'generic-input',
                                    'monthly-prices-input',
                                ]}
                                id="elevator-costs-input"
                                name="price_netElevatorCost"
                                stateField={
                                    realEstateObject.price.netElevatorCost
                                }
                                setStateField={updateRealEstateObject}
                                errors={errors}
                                control={control}
                                //rules={{ required: t('This is required') }}
                                disabled={realEstateObject.elevatorCostOptional}
                            />
                            <CustomObjectInput
                                type="number-with-addon"
                                addonLabel="%"
                                containerClassNames={[
                                    'p-inputgroup',
                                    'generic-input',
                                ]}
                                classes={['no-border-radius', 'generic-input']}
                                id="elevator-vat-input"
                                name="price_elevatorCostTax"
                                stateField={
                                    realEstateObject.price.elevatorCostTax
                                }
                                setStateField={updateRealEstateObject}
                                errors={errors}
                                control={control}
                                //rules={{ required: t('This is required') }}
                                decimal={true}
                                disabled={realEstateObject.elevatorCostOptional}
                            />
                            <CustomObjectInput
                                type="checkbox"
                                containerClassNames={[
                                    'checkbox-container',
                                    'generic-input',
                                    'no-margin',
                                    'column-span-2',
                                ]}
                                classes={[
                                    'p-inputgroup',
                                    'checkbox-middle',
                                    'generic-input',
                                    'no-margin',
                                ]}
                                id="elevator-costs-optional"
                                label={t('Optional')}
                                name="elevatorCostOptional"
                                stateField={
                                    realEstateObject.elevatorCostOptional
                                }
                                setStateField={updateRealEstateObject}
                                errors={errors}
                                control={control}
                                //rules={{ required: t('This is required') }}
                            />
                            <div className="last-column centered-item last-item">
                                <h6 className="no-margin">
                                    {realEstateObject.price.netElevatorCost ===
                                        null ||
                                    realEstateObject.elevatorCostOptional ===
                                        true
                                        ? 0
                                        : realEstateObject.price
                                              .grossElevatorCost}{' '}
                                    EUR
                                </h6>
                            </div>
                        </div>
                        <Divider />
                        <div className="section-row">
                            <div className="text-container generic-input">
                                <h5>{t('Total')}</h5>
                            </div>
                            <div className="generic-input last-column centered-item last-item">
                                <h5 className="generic-input">
                                    {(realEstateObject.price.totalCost === null
                                        ? 0
                                        : (realEstateObject.parkingOptional ===
                                          true
                                              ? 0
                                              : Number(
                                                    realEstateObject.price
                                                        .grossParkingCost ?? 0,
                                                )) +
                                          (realEstateObject.garageOptional ===
                                          true
                                              ? 0
                                              : Number(
                                                    realEstateObject.price
                                                        .grossGarageCost ?? 0,
                                                )) +
                                          (realEstateObject.operationalCostOptional ===
                                          true
                                              ? 0
                                              : Number(
                                                    realEstateObject.price
                                                        .grossOperatingCost ??
                                                        0,
                                                )) +
                                          Number(
                                              realEstateObject.price
                                                  .grossHeatingCost ?? 0,
                                          ) +
                                          Number(
                                              realEstateObject.price
                                                  .grossExtraCost ?? 0,
                                          ) +
                                          Number(
                                              realEstateObject.price
                                                  .grossWarmWaterCost ?? 0,
                                          ) +
                                          Number(
                                              realEstateObject.price
                                                  .grossCoolingCost ?? 0,
                                          ) +
                                          Number(
                                              realEstateObject.price
                                                  .grossMaintenanceCost ?? 0,
                                          ) +
                                          Number(
                                              realEstateObject.price
                                                  .grossOtherCost ?? 0,
                                          ) +
                                          (realEstateObject.elevatorCostOptional ===
                                          true
                                              ? 0
                                              : Number(
                                                    realEstateObject.price
                                                        .grossElevatorCost ?? 0,
                                                ))
                                    ).toFixed(2)}{' '}
                                    EUR
                                </h5>
                            </div>
                        </div>
                    </Card>
                ) : (
                    <></>
                )}
            </div>
            <div className="object-price-form-column">
                {/*<Card*/}
                {/*    className="object-price-section price-per-m2-section-1"*/}
                {/*    header={*/}
                {/*        <CardHeader title={t('Preise pro m² / Cena po m2')} />*/}
                {/*    }*/}
                {/*>*/}
                {/*    <div className="section-row">*/}
                {/*        <CustomObjectInput*/}
                {/*            type="checkbox"*/}
                {/*            containerClassNames={[*/}
                {/*                'checkbox-container',*/}
                {/*                'centered-item',*/}
                {/*                'no-margin',*/}
                {/*                'prices-per-m2',*/}
                {/*            ]}*/}
                {/*            classes={[*/}
                {/*                'p-inputgroup',*/}
                {/*                'checkbox-middle',*/}
                {/*                'centered-item',*/}
                {/*                'no-margin',*/}
                {/*            ]}*/}
                {/*            id="price-per-square"*/}
                {/*            label={t('m² - price from')}*/}
                {/*            labelClassNames={['bolder']}*/}
                {/*            name="pricePerSquare"*/}
                {/*            stateField={realEstateObject.pricePerSquare}*/}
                {/*            setStateField={updateRealEstateObject}*/}
                {/*            errors={errors}*/}
                {/*            control={control}*/}
                {/*            // rules={{ required: t('This is required') }}*/}
                {/*        />*/}
                {/*        <CustomObjectInput*/}
                {/*            type="number-with-addon"*/}
                {/*            id="izracunati" // ? Change with clarification of the field*/}
                {/*            name="izracunati" // ? Change with clarification of the field*/}
                {/*            classes={['generic-input', 'no-border-radius']}*/}
                {/*            containerClassNames={[*/}
                {/*                'p-inputgroup',*/}
                {/*                'input-margin',*/}
                {/*                'column-span-2',*/}
                {/*                'generic-input',*/}
                {/*            ]}*/}
                {/*            // addonLabel="izracunati"*/}
                {/*            control={control}*/}
                {/*            // rules={{ required: 'This is required' }}*/}
                {/*            errors={errors}*/}
                {/*        />*/}
                {/*    </div>*/}
                {/*    <div className="section-row">*/}
                {/*        <div className="text-input-label-monthly-prices generic-input centered-item">*/}
                {/*            <label htmlFor="purchase-price-squared-meter">*/}
                {/*                {t('Purchase price / m²')}*/}
                {/*            </label>*/}
                {/*        </div>*/}
                {/*        <CustomObjectInput*/}
                {/*            type="number-with-addon"*/}
                {/*            id="purchase-price-squared-meter"*/}
                {/*            name="price_purchasePricePerSquareMeter"*/}
                {/*            classes={['generic-input', 'no-border-radius']}*/}
                {/*            containerClassNames={[*/}
                {/*                'p-inputgroup',*/}
                {/*                'column-span-2',*/}
                {/*                'generic-input',*/}
                {/*            ]}*/}
                {/*            addonLabel="€"*/}
                {/*            stateField={*/}
                {/*                realEstateObject.price*/}
                {/*                    .purchasePricePerSquareMeter*/}
                {/*            }*/}
                {/*            setStateField={updateRealEstateObject}*/}
                {/*            control={control}*/}
                {/*            //rules={{ required: t('This is required') }}*/}
                {/*            errors={errors}*/}
                {/*        />*/}
                {/*    </div>*/}
                {/*    <div className="section-row">*/}
                {/*        <div className="text-input-label-monthly-prices generic-input centered-item">*/}
                {/*            <label htmlFor="operational-costs-squared-meter">*/}
                {/*                {t('Operational costs / m²')}*/}
                {/*            </label>*/}
                {/*        </div>*/}
                {/*        <CustomObjectInput*/}
                {/*            type="number-with-addon"*/}
                {/*            id="operational-price-squared-meter"*/}
                {/*            name="price_operatingCostsPerSquareMeter"*/}
                {/*            classes={['generic-input', 'no-border-radius']}*/}
                {/*            containerClassNames={[*/}
                {/*                'p-inputgroup',*/}
                {/*                'column-span-2',*/}
                {/*                'generic-input',*/}
                {/*            ]}*/}
                {/*            addonLabel="€"*/}
                {/*            stateField={*/}
                {/*                realEstateObject.price*/}
                {/*                    .operatingCostsPerSquareMeter*/}
                {/*            }*/}
                {/*            setStateField={updateRealEstateObject}*/}
                {/*            control={control}*/}
                {/*            //rules={{ required: t('This is required') }}*/}
                {/*            errors={errors}*/}
                {/*        />*/}
                {/*    </div>*/}
                {/*</Card>*/}
                <Card
                    className="object-price-section price-per-m2-section-2"
                    header={<CardHeader title={t('Provision')} />}
                >
                    <div className="section-row">
                        <div className="text-input-label-monthly-prices generic-input centered-item">
                            <label htmlFor="interested-party-input">
                                {t('Interested party')}
                            </label>
                        </div>
                        <CustomObjectInput
                            type="decimal"
                            id="interested-party-input"
                            name="price_interessentProvision"
                            classes={[
                                'generic-input',
                                'price-percentage-input',
                            ]}
                            stateField={
                                realEstateObject.price.interessentProvision
                            }
                            setStateField={updateRealEstateObject}
                            control={control}
                            //rules={{ required: t('This is required') }}
                            errors={errors}
                        />
                        <CustomObjectInput
                            type="dropdown"
                            id="price-calculation"
                            name="price_interessentProvisionType"
                            classes={['generic-input']}
                            stateField={
                                realEstateObject.price
                                    .interessentProvisionType == null
                                    ? priceCalculationOptions[0].value
                                    : realEstateObject.price
                                          .interessentProvisionType
                            }
                            setStateField={updateRealEstateObject}
                            dropdownOptions={priceCalculationOptions}
                            control={control}
                            //rules={{ required: t('This is required') }}
                            errors={errors}
                        />
                        <div className="p-inputgroup input-margin vat-percentage-container generic-input">
                            <CustomObjectInput
                                type="number-with-addon"
                                addonLabel={`${t('VAT')}`}
                                classes={['no-border-radius']}
                                containerClassNames={['no-border-radius']}
                                id="ust1-input"
                                suffix="%"
                                name="price_interessentProvisionTax"
                                stateField={
                                    realEstateObject.price
                                        .interessentProvisionTax
                                }
                                setStateField={updateRealEstateObject}
                                errors={errors}
                                control={control}
                                //rules={{ required: t('This is required') }}
                                decimal={true}
                            />
                        </div>
                    </div>
                    <div className="section-row">
                        <div className="text-input-label-monthly-prices generic-input centered-item">
                            <label htmlFor="output-1">{t('Darstellung')}</label>
                        </div>
                        <div className="column-span-2">
                            <CustomObjectInput
                                type="text"
                                id="output-1"
                                name="price_derstellung"
                                containerClassNames={[
                                    'generic-input',
                                    'column-span-2',
                                ]}
                                classes={['generic-input']}
                                disabled={
                                    realEstateObject.price.derstellungAutomatic
                                }
                                stateField={realEstateObject.price.derstellung}
                                setStateField={updateRealEstateObject}
                                control={control}
                                //rules={{ required: t('This is required') }}
                                errors={errors}
                            />
                        </div>
                        <div>
                            <CustomObjectInput
                                type="checkbox"
                                containerClassNames={[
                                    'checkbox-container',
                                    'prices-per-m2',
                                ]}
                                classes={[
                                    'p-inputgroup',
                                    'checkbox-middle',
                                    'no-margin',
                                ]}
                                id="autofill"
                                label={t('Autofill')}
                                name="price_derstellungAutomatic"
                                stateField={
                                    realEstateObject.price.derstellungAutomatic
                                }
                                setStateField={updateRealEstateObject}
                                errors={errors}
                                control={control}
                                //rules={{ required: t('This is required') }}
                            />
                        </div>
                    </div>
                    <div className="section-row">
                        <div className="text-input-label-monthly-prices generic-input centered-item">
                            <label htmlFor="surrender-input">
                                {t('Verkäufer')}
                            </label>
                        </div>
                        <CustomObjectInput
                            type="decimal"
                            id="surrender-input"
                            name="price_verkauferProvision"
                            classes={[
                                'generic-input',
                                'price-percentage-input',
                            ]}
                            stateField={
                                realEstateObject.price.verkauferProvision
                            }
                            setStateField={updateRealEstateObject}
                            control={control}
                            errors={errors}
                        />
                        <CustomObjectInput
                            type="dropdown"
                            id="price-calculation-input"
                            name="price_verkauferProvisionType"
                            classes={['generic-input']}
                            stateField={
                                realEstateObject.price.verkauferProvisionType ==
                                null
                                    ? priceCalculationOptions[0].value
                                    : realEstateObject.price
                                          .verkauferProvisionType
                            }
                            setStateField={updateRealEstateObject}
                            dropdownOptions={priceCalculationOptions}
                            control={control}
                            //rules={{ required: t('This is required') }}
                            errors={errors}
                        />
                        <div className="p-inputgroup input-margin vat-percentage-container generic-input">
                            <CustomObjectInput
                                type="number-with-addon"
                                addonLabel={`${t('VAT')}`}
                                classes={['no-border-radius']}
                                containerClassNames={['no-border-radius']}
                                id="vat1"
                                suffix="%"
                                name="price_verkauferProvisionTax"
                                stateField={
                                    realEstateObject.price.verkauferProvisionTax
                                }
                                setStateField={updateRealEstateObject}
                                errors={errors}
                                control={control}
                                //rules={{ required: t('This is required') }}
                                decimal={true}
                            />
                        </div>
                    </div>
                    <div className="section-row">
                        <div className="text-input-label-monthly-prices generic-input centered-item">
                            <label htmlFor="output-2">{t('Ergebnis')}</label>
                        </div>
                        <div className="column-span-2">
                            <CustomObjectInput
                                type="decimal"
                                id="output-2"
                                name="price_ergebnis"
                                containerClassNames={[
                                    'generic-input',
                                    'column-span-2',
                                ]}
                                classes={[
                                    'generic-input',
                                    'price-percentage-input',
                                ]}
                                stateField={realEstateObject.price.ergebnis}
                                setStateField={updateRealEstateObject}
                                control={control}
                                //rules={{ required: t('This is required') }}
                                errors={errors}
                                decimal={true}
                            />
                            <label>
                                {t(
                                    'Wird nicth bei externen Freigaben angezeigt',
                                )}
                            </label>
                        </div>
                    </div>
                </Card>
                <Card
                    className="object-price-section"
                    header={<CardHeader title={t('Notes')} />}
                >
                    <div className="section-row">
                        <div className="generic-input">
                            <CustomObjectInput
                                type="accordion-textarea"
                                id="note"
                                classes={['generic-input']}
                                name="note"
                                stateField={realEstateObject.note}
                                setStateField={updateRealEstateObject}
                                errors={errors}
                                control={control}
                                //rules={{ required: t('This is required') }}
                            />
                        </div>
                    </div>
                </Card>
            </div>
        </div>
    );
};

export default ObjectPricesForm;

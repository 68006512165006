import React, { useState, useEffect } from 'react';
import './EditUserModal.scss';
import CustomObjectInput from '../objects/customObjectInput/CustomObjectInput';
import { useForm } from 'react-hook-form';
import { useApplicationContext } from '../../application/ApplicationContext';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { useTranslation } from 'react-i18next';
import { INewUser } from '../../models/NewUser';
import { roleData } from '../UserFormModal/Roles';
import { Divider } from 'primereact/divider';
import { userService } from '../../services/UsersService';
import PermissionContainer from '../../core/auth/PermissionContainer';
import { Permissions } from '../../core/auth/Permissions';

const EditUserModal = ({ showModal, toast, singleUser }) => {
    const { t } = useTranslation();
    const { appState, editSingleUser } = useApplicationContext();
    const [employeeList, setEmployeeList] = useState([]);
    const [editUser, setEditUser] = useState<INewUser>({
        roleId: singleUser.roleId,
        name: singleUser.name,
        email: singleUser.email,
        password: singleUser.password,
        phoneNumber: singleUser.phoneNumber,
        smtpUsername: singleUser.smtpUsername,
        smtpPassword: singleUser.smtpPassword,
        smtpHost: singleUser.smtpHost,
        smtpPort: singleUser.smtpPort,
        smtpEmail: singleUser.smtpEmail,
        active: singleUser.active,
        superiorId: singleUser.superior?.id === undefined ? null : singleUser.superior?.id,
        teamName: singleUser.teamName,
        addressStreet: singleUser.addressStreet,
        addressHouseNumber: singleUser.addressHouseNumber,
        addressPostal: singleUser.addressPostal,
        addressCity: singleUser.addressCity,
        addressCountry: singleUser.addressCountry,
        positionTitle: singleUser.positionTitle,
    });

    const {
        control,
        formState: { errors, isValid },
        register,
        trigger,
    } = useForm({
        mode: 'all',
        reValidateMode: 'onChange',
        defaultValues: editUser,
    });

    useEffect(() => {
        trigger('phoneNumber');
    }, [trigger, editUser]);

    const lowercaseLetters = /(?=.*[a-z])/;
    const uppercaseLetters = /(?=.*[A-Z])/;
    const regCheckNumber = /(?=.*\d)/;

    const handleNewUser = (prop, value) => {
        setEditUser((FirstDateFormModalObject) => ({
            ...FirstDateFormModalObject,
            [prop]: value,
        }));
    };

    const handleResetModal = () => {
        setEditUser({
            roleId: '',
            name: '',
            email: '',
            password: '',
            phoneNumber: '',
            smtpUsername: '',
            smtpPassword: '',
            smtpHost: '',
            smtpPort: '',
            smtpEmail: '',
            active: false,
            superiorId: null,
            teamName: '',
            addressStreet: '',
            addressHouseNumber: '',
            addressPostal: '',
            addressCity: '',
            addressCountry: '',
            positionTitle: '',
        });
    };

    const fetchUsers = async () => {
        const response = await userService.getMany();
        return response.data;
    };

    useEffect(() => {
        let isMounted = true;
        userService.refreshTokenCheck().then(() => {
            if (isMounted) {
                fetchUsers().then((result) => setEmployeeList(result));
            }
        });
        return () => {
            isMounted = false;
        };
    }, []);

    const employeeOptions = () => {
        const allEmployees = employeeList.map((employee) => ({
            label: employee.name,
            value: employee.id,
        }));
        return allEmployees;
    };

    const rolesOptions = () => {
        const allRoles = roleData.map((roleData) => ({
            label: roleData.name,
            value: roleData.id,
        }));
        return allRoles;
    };

    const handleSubmit = (e) => {
        e.preventDefault();
    };

    const handleButtonCheck = () => {
        editSingleUser(
            String(editUser.roleId),
            editUser.name,
            editUser.email,
            editUser.password,
            editUser.phoneNumber,
            singleUser.id,
            {
                smtpUsername: editUser.smtpUsername,
                smtpPassword: editUser.smtpPassword,
                smtpHost: editUser.smtpHost,
                smtpPort: editUser.smtpPort,
                smtpEmail: editUser.smtpEmail,
            },
            {
                addressStreet: editUser.addressStreet,
                addressHouseNumber: editUser.addressHouseNumber,
                addressPostal: editUser.addressPostal,
                addressCity: editUser.addressCity,
                addressCountry: editUser.addressCountry,
            },
            editUser.positionTitle,
            editUser.active,
            String(editUser.superiorId),
            editUser.teamName,
        );
        showModal();
        handleResetModal();
        toast.current.show({
            severity: 'success',
            detail: t(`Edited user sucessfully`),
            life: 3000,
        });
    };
    const passwordHeader = <h6>Pick a password</h6>;

    const passwordFooter = (
        <>
            <Divider />
            <p className="mt-2">{t('Suggestions')}</p>
            <ul className="pl-2 ml-2 mt-0" style={{ lineHeight: '1.5' }}>
                <li>
                    {lowercaseLetters.test(editUser.password) ? (
                        <i className="pi pi-check green" />
                    ) : (
                        <i className="pi pi-times red" />
                    )}
                    {t(' At least one lowercase')}
                </li>
                <li>
                    {uppercaseLetters.test(editUser.password) ? (
                        <i className="pi pi-check green" />
                    ) : (
                        <i className="pi pi-times red" />
                    )}
                    {t(' At least one uppercase')}
                </li>
                <li>
                    {regCheckNumber.test(editUser.password) ? (
                        <i className="pi pi-check green" />
                    ) : (
                        <i className="pi pi-times red" />
                    )}
                    {t('At least one numeric')}
                </li>
                <li>
                    {editUser.password?.length > 7 ? (
                        <i className="pi pi-check green" />
                    ) : (
                        <i className="pi pi-times red" />
                    )}
                    {t('At least 8 characters')}
                </li>
            </ul>
        </>
    );

    const footer = (
        <PermissionContainer
            hasPermission={Permissions.USER_EDIT}
            currentUser={appState.currentUser}
        >
            <div className="footer-buttons">
                <Button
                    label={t('Save')}
                    //className="p-button-success"
                    icon="pi pi-check"
                    disabled={!isValid}
                    onClick={handleButtonCheck}
                    type="submit"
                    form="form"
                />

                {/* <Button
                    label={t('Refresh')}
                    className="p-button-secondary"
                    icon="pi pi-refresh"
                    onClick={handleResetModal}
                /> */}
            </div>
        </PermissionContainer>
    );

    return (
        <>
            <Dialog
                header={t('Edit User')}
                visible={true}
                style={{ width: '50vw' }}
                className="user-modal"
                blockScroll
                footer={footer}
                draggable={false}
                onHide={showModal}
            >
                <form id="form" onSubmit={handleSubmit}>
                    <h5 className="header column-span-2">{`${t(
                        'Basic information',
                    )}`}</h5>
                    <hr className="horizontal-divider column-span-2" />
                    <div className="modal-input column-span-3">
                        <CustomObjectInput
                            type="text"
                            classes={['expiry-date-input', 'generic-input']}
                            id="Important date"
                            label={t('Name')}
                            name="name"
                            register={register}
                            stateField={editUser.name}
                            setStateField={handleNewUser}
                            errors={errors}
                            control={control}
                            // rules={{ required: t('This is required') }}
                        />
                    </div>
                    <div className="modal-input column-span-3">
                        <CustomObjectInput
                            type="password"
                            classes={['password', 'generic-input']}
                            id="password"
                            header={passwordHeader}
                            footer={passwordFooter}
                            label={t('Password ')}
                            name="password"
                            register={register}
                            stateField={editUser.password}
                            setStateField={handleNewUser}
                            errors={errors}
                            control={control}
                            // rules={{ required: t('This is required') }}
                        />
                    </div>
                    <div className="modal-input column-span-3">
                        <CustomObjectInput
                            type="dropdown"
                            classes={['country-input']}
                            id="id-input"
                            label={t('Role')}
                            name="roleId"
                            register={register}
                            dropdownOptions={rolesOptions()}
                            stateField={editUser.roleId}
                            setStateField={handleNewUser}
                            errors={errors}
                            control={control}
                            // rules={{ required: t('This is required') }}
                        />
                    </div>
                    <div className="modal-input column-span-3">
                        <CustomObjectInput
                            type="text"
                            classes={['valid-from-input', 'generic-input']}
                            id="Date-and-time-from-input"
                            label={t('Email')}
                            name="email"
                            register={register}
                            stateField={editUser.email}
                            setStateField={handleNewUser}
                            errors={errors}
                            control={control}
                            // rules={{ required: t('This is required') }}
                        />
                    </div>

                    <div className="modal-input column-span-3">
                        <CustomObjectInput
                            type="text"
                            classes={['valid-from-input', 'generic-input']}
                            id="phone-number"
                            label={t('Phone number')}
                            name="phoneNumber"
                            register={register}
                            stateField={editUser.phoneNumber}
                            setStateField={handleNewUser}
                            errors={errors}
                            control={control}
                            rules={{ required: t('This is required') }}
                        />
                    </div>

                    <div className="modal-input column-span-3">
                        <CustomObjectInput
                            type="text"
                            classes={['valid-from-input', 'generic-input']}
                            id="title"
                            label={t('Title')}
                            name="positionTitle"
                            register={register}
                            stateField={editUser.positionTitle}
                            setStateField={handleNewUser}
                            errors={errors}
                            control={control}
                        />
                    </div>

                    <PermissionContainer
                        hasPermission={Permissions.USER_DELETE}
                        currentUser={appState.currentUser}
                    >
                        <div className="modal-input column-span-3">
                            <CustomObjectInput
                                type="checkbox"
                                addonLabel="m²"
                                classes={['checkbox']}
                                id="unfinished-attic"
                                label={t('Active')}
                                name="active"
                                stateField={editUser.active}
                                setStateField={handleNewUser}
                                errors={errors}
                                control={control}
                                // rules={{ required: t('This is required') }}
                            />
                        </div>
                    </PermissionContainer>

                    <h5 className="header column-span-2">{`${t('Team')}`}</h5>
                    <hr className="horizontal-divider column-span-2" />
                    <div className="modal-input column-span-3">
                        <CustomObjectInput
                            type="dropdown"
                            classes={['country-input']}
                            id="supervisor-input"
                            label={t('Supervisor')}
                            name="superiorId"
                            stateField={editUser.superiorId}
                            setStateField={handleNewUser}
                            dropdownOptions={employeeOptions()}
                            errors={errors}
                            control={control}
                            // rules={{ required: t('This is required') }}
                        />
                    </div>
                    <div className="modal-input column-span-3">
                        <CustomObjectInput
                            type="text"
                            classes={['valid-from-input', 'generic-input']}
                            id="team-input"
                            label={t('Team Name')}
                            name="teamName"
                            register={register}
                            stateField={editUser.teamName}
                            setStateField={handleNewUser}
                            errors={errors}
                            control={control}
                            // rules={{ required: t('This is required') }}
                        />
                    </div>

                    <h5 className="header column-span-2">{`${t('Email')}`}</h5>
                    <hr className="horizontal-divider column-span-2" />
                    <div className="modal-input column-span-3">
                        <CustomObjectInput
                            type="text"
                            classes={['valid-from-input', 'generic-input']}
                            id="smtp-username"
                            label={t('SMTP Username')}
                            name="smtpUsername"
                            register={register}
                            stateField={editUser.smtpUsername}
                            setStateField={handleNewUser}
                            errors={errors}
                            control={control}
                            // rules={{ required: t('This is required') }}
                        />
                    </div>
                    <div className="modal-input column-span-3">
                        <CustomObjectInput
                            type="password"
                            classes={['password', 'generic-input']}
                            id="smtp-password"
                            label={t('SMTP Password')}
                            name="smtpPassword"
                            register={register}
                            stateField={editUser.smtpPassword}
                            setStateField={handleNewUser}
                            errors={errors}
                            control={control}
                            // rules={{ required: t('This is required') }}
                        />
                    </div>

                    <div className="modal-input column-span-3">
                        <CustomObjectInput
                            type="text"
                            classes={['valid-from-input', 'generic-input']}
                            id="smtp-host"
                            label={t('SMTP Host')}
                            name="smtpHost"
                            register={register}
                            stateField={editUser.smtpHost}
                            setStateField={handleNewUser}
                            errors={errors}
                            control={control}
                            // rules={{ required: t('This is required') }}
                        />
                    </div>
                    <div className="modal-input column-span-3">
                        <CustomObjectInput
                            type="text"
                            classes={['valid-from-input', 'generic-input']}
                            id="smtp-port"
                            label={t('SMTP Port')}
                            name="smtpPort"
                            register={register}
                            stateField={editUser.smtpPort}
                            setStateField={handleNewUser}
                            errors={errors}
                            control={control}
                            // rules={{ required: t('This is required') }}
                        />
                    </div>
                    <div className="modal-input column-span-3">
                        <CustomObjectInput
                            type="text"
                            classes={['valid-from-input', 'generic-input']}
                            id="smtp-email"
                            label={t('SMTP Email')}
                            name="smtpEmail"
                            register={register}
                            stateField={editUser.smtpEmail}
                            setStateField={handleNewUser}
                            errors={errors}
                            control={control}
                            // rules={{ required: t('This is required') }}
                        />
                    </div>

                    <h5 className="header column-span-2">{`${t(
                        'Address Information',
                    )}`}</h5>
                    <hr className="horizontal-divider column-span-2" />
                    <div className="modal-input column-span-3">
                        <CustomObjectInput
                            type="text"
                            classes={['valid-from-input', 'generic-input']}
                            id="street"
                            label={t('Street')}
                            name="addressStreet"
                            register={register}
                            stateField={editUser.addressStreet}
                            setStateField={handleNewUser}
                            errors={errors}
                            control={control}
                        />
                    </div>
                    <div className="modal-input column-span-3">
                        <CustomObjectInput
                            type="text"
                            classes={['valid-from-input', 'generic-input']}
                            id="house-number"
                            label={t('House number')}
                            name="addressHouseNumber"
                            register={register}
                            stateField={editUser.addressHouseNumber}
                            setStateField={handleNewUser}
                            errors={errors}
                            control={control}
                        />
                    </div>
                    <div className="modal-input column-span-3">
                        <CustomObjectInput
                            type="text"
                            classes={['valid-from-input', 'generic-input']}
                            id="postal"
                            label={t('Postal code')}
                            name="addressPostal"
                            register={register}
                            stateField={editUser.addressPostal}
                            setStateField={handleNewUser}
                            errors={errors}
                            control={control}
                        />
                    </div>
                    <div className="modal-input column-span-3">
                        <CustomObjectInput
                            type="text"
                            classes={['valid-from-input', 'generic-input']}
                            id="city"
                            label={t('City')}
                            name="addressCity"
                            register={register}
                            stateField={editUser.addressCity}
                            setStateField={handleNewUser}
                            errors={errors}
                            control={control}
                        />
                    </div>
                    <div className="modal-input column-span-3">
                        <CustomObjectInput
                            type="text"
                            classes={['valid-from-input', 'generic-input']}
                            id="country"
                            label={t('Country')}
                            name="addressCountry"
                            register={register}
                            stateField={editUser.addressCountry}
                            setStateField={handleNewUser}
                            errors={errors}
                            control={control}
                        />
                    </div>
                </form>
            </Dialog>
        </>
    );
};

export default EditUserModal;

import { Config } from '../Config';
import { CrudService } from './CrudService';
import { AxiosResponse } from 'axios';
import { CreateQueryParams } from '@nestjsx/crud-request';
import { Inquiry } from '../models/Inquiry';
import { INewInquiryFormModal } from '../models/NewInquiryFormModal';
import { axiosDefaults } from './AxiosDefaults';

export class InquiriesService extends CrudService<Inquiry, 'Inquiry'> {
    protected getUrlPath(): string {
        return '/inquiries';
    }

    public async getOne(id: string | number): Promise<AxiosResponse<Inquiry>> {
        const inquiryResponse = await super.getOne(id);
        return new Promise((resolve: (inquiry) => void, reject: () => void) => {
            resolve(inquiryResponse);
        });
    }

    public async getMany(
        params?: CreateQueryParams,
    ): Promise<AxiosResponse<Inquiry[]>> {
        const inquiriesResponse = await super.getMany(params);
        return new Promise((resolve: (inquiry) => void, reject: () => void) => {
            resolve(inquiriesResponse);
        });
    }

    public async createOne(
        inquiry: INewInquiryFormModal,
    ): Promise<AxiosResponse<Inquiry>> {
        if (inquiry.contact.emails.length !== 0) {
            delete inquiry.contactId;
        } else {
            delete inquiry.contact;
        }

        return this.axiosInstance.post(
            axiosDefaults.baseURL + this.getUrlPath(),
            inquiry,
            {
                withCredentials: true,
                headers: { 'Content-Type': 'application/json' },
            },
        );
    }

    public async import(fileList: FileList): Promise<AxiosResponse> {
        const files = Array.from(fileList);
        const formData = new FormData();

        for (const file of files) {
            formData.append('xml', file, file.name);
        }

        return this.axiosInstance.post(
            this.getUrlPath() + '/import',
            formData,
            {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            },
        );
    }

    public async updateOne(
        id: string,
        status: Inquiry,
    ): Promise<AxiosResponse<Inquiry>> {
        let body = { status };
        let newBody = await JSON.stringify(body);
        return this.axiosInstance.put(
            axiosDefaults.baseURL + this.getUrlPath() + '/' + id,
            newBody,
            {
                withCredentials: true,
                headers: { 'Content-Type': 'application/json' },
            },
        );
    }

    // public async updateOne(id: string, inquiry: Inquiry): Promise<AxiosResponse<Inquiry>> {
    //     let body = {/*TODO*/};
    //     let newBody = await JSON.stringify(body);
    //     return this.axiosInstance.post(
    //         axiosDefaults.baseURL + this.getUrlPath(),
    //         newBody,
    //         {withCredentials: true, headers: {'Content-Type': 'application/json'}},
    //     );
    // };

    public deleteOne(id: string | number): Promise<AxiosResponse<Inquiry>> {
        return this.axiosInstance.delete(
            axiosDefaults.baseURL + this.getUrlPath() + '/' + id,
        );
    }

    public async searchInquire(
        keywords: string,
    ): Promise<AxiosResponse<Inquiry[]>> {
        let data = {
            keywords: keywords,
        };
        return await this.axiosInstance.post(
            axiosDefaults.baseURL + this.getUrlPath() + '/search',
            data,
        );
    }
}

export const inquiriesService = new InquiriesService();
